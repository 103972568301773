import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  findNodeHandle,
  KeyboardAvoidingView,
  LayoutChangeEvent,
  LayoutRectangle,
  NativeMethods,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView as RNScrollView,
  ScrollViewProps,
} from "react-native";
import EventEmitter from "events";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import styled from "styled-components/native";
import { ENV } from "@app/config/env";
import { assignValueToRef } from "@app/util/useRefSynchronizer";

type ScrollToComponentOptions = {
  /**
   * @default false
   */
  animated?: boolean;
  /**
   * @default center
   */
  componentAnchor?: "top" | "center" | "bottom";
  /**
   * @default center
   */
  scrollViewAnchor?: "top" | "center" | "bottom";
  /**
   * @default 0
   */
  offsetY?: number;
};

export interface QKScrollViewController {
  parentController: QKScrollViewController | null;
  scrollToComponent: (
    componentRef: RefObject<NativeMethods>,
    options?: ScrollToComponentOptions
  ) => void;
  scrollBy: (offset: number, animated?: boolean) => void;
  scrollTo: ScrollViewRef["scrollTo"];
  scrollToEnd: ScrollViewRef["scrollToEnd"];
  scrollToStart: (animated?: boolean) => void;
  setScrollEnabled: (enabled: boolean) => void;
  isScrollEnabled: () => boolean;
  isKeyboardAvoidViewEnabled: () => boolean;
  getScrollOffset: () => number;
  getNodeHandle: () => number | null;
  getLayout: () => LayoutRectangle | undefined;
  setKeyboardShouldPersistTaps: (
    value: ScrollViewProps["keyboardShouldPersistTaps"]
  ) => void;
  setKeyboardDismissMode: (
    value: ScrollViewProps["keyboardDismissMode"]
  ) => void;
  addScrollOffsetListener: (listener: (offset: number) => void) => void;
  removeScrollOffsetListener: (listener: (offset: number) => void) => void;
  nativeView?: ScrollViewRef;
}

function throwOrComplain<T extends Record<string, unknown>>(object: T): T {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (typeof value === "function") {
        return [
          key,
          (...args: unknown[]) => {
            if (ENV.throwGuardrailErrors) {
              throw new Error(
                `ScrollViewController used outside QKScrollView! Received call to \`${key}\``
              );
            } else {
              console.warn(
                `ScrollViewController used outside QKScrollView! Received call to \`${key}\``
              );
              return value(...args);
            }
          },
        ];
      }
      return [key, value];
    })
  ) as T;
}

const defaultValue = throwOrComplain({
  parentController: null,
  scrollToComponent: () => undefined,
  isKeyboardAvoidViewEnabled: () => true,
  scrollBy: () => undefined,
  scrollTo: () => undefined,
  scrollToEnd: () => undefined,
  scrollToStart: () => undefined,
  setScrollEnabled: () => undefined,
  isScrollEnabled: () => true,
  getScrollOffset: () => 0,
  getNodeHandle: () => null,
  getLayout: () => undefined,
  setKeyboardShouldPersistTaps: () => undefined,
  setKeyboardDismissMode: () => undefined,
  addScrollOffsetListener: () => undefined,
  removeScrollOffsetListener: () => undefined,
  nativeView: undefined,
});
export const ScrollViewController =
  React.createContext<QKScrollViewController>(defaultValue);

type ExpectedScrollViewController<
  T extends { okIfNotAvailable: boolean } | undefined
> = T extends { okIfNotAvailable: true }
  ? QKScrollViewController | undefined
  : QKScrollViewController;
export const useScrollViewController = <
  T extends { okIfNotAvailable: boolean } | undefined
>(
  options?: T
): ExpectedScrollViewController<T> => {
  const context = React.useContext(ScrollViewController);
  if (context === defaultValue) {
    if (options?.okIfNotAvailable) {
      return undefined as ExpectedScrollViewController<T>;
    }

    if (ENV.throwGuardrailErrors) {
      throw new Error(
        "useScrollViewController must be used within a ScrollViewController"
      );
    } else {
      console.warn(
        `useScrollViewController must be used within a ScrollViewController`
      );
    }
  }
  return context;
};

// The RNScrollView type is missing the NativeMethods, but they are actually present.
type ScrollViewRef = RNScrollView &
  Omit<NativeMethods, "focus" | "blur" | "refs">;

type QKScrollViewProps = Omit<ScrollViewProps, "ref"> & {
  keyboardAvoidingViewProps?: KeyboardAvoidingView["props"];
};
const QKScrollView = React.forwardRef<
  QKScrollViewController,
  QKScrollViewProps
>((props, parentRef) => {
  const scrollEventEmitter = useRef(new EventEmitter()).current;
  const scrollViewRef = useRef<ScrollViewRef>();
  const scrollViewLayoutRef = useRef<LayoutRectangle>();
  const scrollOffsetRef = useRef({ x: 0, y: 0 });
  const [scrollEnabled, setScrollEnabled, scrollEnabledRef] =
    useStateWithRef(true);

  const getScrollOffset = () => scrollOffsetRef.current.y;
  const getLayout = () => scrollViewLayoutRef.current;

  const contentHeightRef = useRef<number>();

  const getNodeHandle = useCallback(() => {
    return scrollViewRef.current ? findNodeHandle(scrollViewRef.current) : null;
  }, []);

  const scrollToComponent = useCallback(
    (
      componentRef: RefObject<NativeMethods>,
      options?: ScrollToComponentOptions
    ) => {
      const {
        animated = false,
        scrollViewAnchor = "center",
        componentAnchor = "center",
        offsetY = 0,
      } = options ?? {};

      const scrollView = scrollViewRef.current;
      const component = componentRef.current;

      if (!component) {
        console.warn("Failed to scroll to component. Component ref is null.");
        return;
      }
      if (!scrollView) {
        console.warn("Failed to scroll to component. ScrollView ref is null.");
        return;
      }

      // special case for web should be removed once fixed in react-native-web
      // see: https://github.com/necolas/react-native-web/issues/2109
      const scrollViewContentComponent =
        Platform.OS === "web"
          ? scrollView.getInnerViewNode()
          : findNodeHandle(scrollView);

      if (!scrollViewContentComponent) {
        console.warn(
          "Failed to scroll to component. Could not find node handle for ScrollView."
        );
        return;
      }

      component?.measureLayout(
        scrollViewContentComponent,
        (_left: number, top: number, _width: number, height: number) => {
          if (height === 0) {
            console.warn(
              "Component reported height of 0. Likely `scrollToComponent` was called too early. Try calling in the `onLayout` of the component passed in."
            );
          }

          scrollView.measure(
            (_x, _y, _scrollViewWidth, scrollViewHeight, _pageX, _pageY) => {
              const componentAnchorOffset =
                componentAnchor === "top"
                  ? top
                  : componentAnchor === "bottom"
                  ? top + height
                  : top + height / 2;

              const scrollViewAnchorOffset =
                scrollViewAnchor === "top"
                  ? getScrollOffset()
                  : scrollViewAnchor === "bottom"
                  ? getScrollOffset() + scrollViewHeight
                  : getScrollOffset() + scrollViewHeight / 2;

              const yDiff =
                componentAnchorOffset + offsetY - scrollViewAnchorOffset;

              const scrollTo = {
                x: scrollOffsetRef.current.x,
                y: getScrollOffset() + yDiff,
                animated,
              };

              scrollView?.scrollTo(scrollTo);
            }
          );
        },
        () => {
          console.error("ScrollViewController: measureLayout failed");
        }
      );
    },
    []
  );

  const scrollBy = useCallback((amount: number) => {
    scrollViewRef.current?.scrollTo({
      x: scrollOffsetRef.current.x,
      y: scrollOffsetRef.current.y + amount,
      animated: false,
    });
  }, []);

  const scrollTo: ScrollViewRef["scrollTo"] = useCallback((...args) => {
    scrollViewRef.current?.scrollTo(...args);
  }, []);

  const scrollToEnd: ScrollViewRef["scrollToEnd"] = useCallback((...args) => {
    scrollViewRef.current?.scrollToEnd(...args);
  }, []);

  const scrollToStart = useCallback(
    (animated?: boolean) => {
      scrollViewRef.current?.scrollTo({
        x: props.horizontal ? 0 : scrollOffsetRef.current.x,
        y: !props.horizontal ? 0 : scrollOffsetRef.current.y,
        animated,
      });
    },
    [props.horizontal]
  );

  const isScrollEnabled = useCallback(() => {
    return scrollEnabledRef.current;
  }, [scrollEnabledRef]);

  const [keyboardDismissMode, setKeyboardDismissMode] = useState<
    ScrollViewProps["keyboardDismissMode"]
  >(
    "keyboardDismissMode" in props
      ? props.keyboardDismissMode
      : Platform.OS === "web"
      ? "none"
      : "on-drag"
  );

  useEffect(() => {
    // if incoming prop changes, we should not ignore it.
    setKeyboardDismissMode(props.keyboardDismissMode);
  }, [props.keyboardDismissMode]);
  const [keyboardShouldPersistTaps, setKeyboardShouldPersistTaps] = useState<
    ScrollViewProps["keyboardShouldPersistTaps"]
  >(
    "keyboardShouldPersistTaps" in props
      ? props.keyboardShouldPersistTaps
      : "handled"
  );
  useEffect(() => {
    // if incoming prop changes, we should not ignore it.
    setKeyboardShouldPersistTaps(props.keyboardShouldPersistTaps);
  }, [props.keyboardShouldPersistTaps]);
  const [
    keyboardAvoidViewEnabled,
    setKeyboardAvoidViewEnabled,
    keyboardAvoidViewEnabledRef,
  ] = useStateWithRef<boolean>(
    "keyboardAvoidingViewProps" in props &&
      "enabled" in (props.keyboardAvoidingViewProps ?? {})
      ? props.keyboardAvoidingViewProps!.enabled ?? true
      : true
  );
  useEffect(() => {
    // if incoming prop changes, we should not ignore it.
    setKeyboardAvoidViewEnabled(
      "keyboardAvoidingViewProps" in props &&
        "enabled" in (props.keyboardAvoidingViewProps ?? {})
        ? props.keyboardAvoidingViewProps!.enabled ?? true
        : true
    );
    // Only re-evaluate when tracked prop changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyboardAvoidingViewProps?.enabled]);

  const isKeyboardAvoidViewEnabled = useCallback(() => {
    return keyboardAvoidViewEnabledRef.current;
  }, [keyboardAvoidViewEnabledRef]);

  let parentController: QKScrollViewController | null =
    React.useContext(ScrollViewController);
  parentController =
    parentController === defaultValue ? null : parentController;

  const controller: QKScrollViewController = useMemo(
    () => ({
      parentController,
      scrollToComponent,
      scrollBy,
      scrollTo,
      scrollToEnd,
      scrollToStart,
      setScrollEnabled,
      isScrollEnabled,
      setKeyboardDismissMode,
      setKeyboardShouldPersistTaps,
      getScrollOffset,
      getLayout,
      getNodeHandle,
      isKeyboardAvoidViewEnabled,
      addScrollOffsetListener: (listener: (offset: number) => void) => {
        scrollEventEmitter.addListener("scroll", listener);
      },
      removeScrollOffsetListener: (listener: (offset: number) => void) => {
        scrollEventEmitter.removeListener("scroll", listener);
      },
      get nativeView() {
        return scrollViewRef.current;
      },
    }),
    [
      getNodeHandle,
      isKeyboardAvoidViewEnabled,
      isScrollEnabled,
      parentController,
      scrollTo,
      scrollBy,
      scrollEventEmitter,
      scrollToComponent,
      scrollToEnd,
      scrollToStart,
      setScrollEnabled,
    ]
  );

  const {
    onScroll: parentOnScroll,
    onContentSizeChange: parentOnContentSizeChange,
    onLayout: parentOnLayout,
  } = props;

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrollOffsetRef.current = event.nativeEvent.contentOffset;
      scrollEventEmitter.emit("scroll", event.nativeEvent.contentOffset.y);
      parentOnScroll?.(event);
    },
    [scrollEventEmitter, parentOnScroll]
  );

  useEffect(
    () => assignValueToRef(parentRef, controller),
    [parentRef, controller]
  );

  const captureScrollViewRef = useCallback((ref: RNScrollView | null) => {
    if (ref) {
      scrollViewRef.current = ref as ScrollViewRef;
    }
  }, []);

  const [
    keyboardVerticalOffset,
    setKeyboardVerticalOffset,
    keyboardVerticalOffsetRef,
  ] = useStateWithRef(0);
  const updateKeyboardVerticalOffset = useCallback(() => {
    scrollViewRef.current?.measure(
      (_x, _y, _scrollViewWidth, _scrollViewHeight, _pageX, pageY) => {
        if (pageY !== keyboardVerticalOffsetRef.current) {
          setKeyboardVerticalOffset(pageY);
        }
      }
    );
  }, [keyboardVerticalOffsetRef, setKeyboardVerticalOffset]);
  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      scrollViewLayoutRef.current = event.nativeEvent.layout;
      parentOnLayout?.(event);
      updateKeyboardVerticalOffset();
    },
    [parentOnLayout, updateKeyboardVerticalOffset]
  );
  const onContentSizeChange = useCallback(
    (width: number, height: number) => {
      contentHeightRef.current = height;
      parentOnContentSizeChange?.(width, height);
    },
    [parentOnContentSizeChange]
  );

  const { children, keyboardAvoidingViewProps, ...restProps } = props;
  return (
    <ScrollViewController.Provider value={controller}>
      <StyledKeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        {...keyboardAvoidingViewProps}
        enabled={
          keyboardAvoidViewEnabled &&
          !parentController?.isKeyboardAvoidViewEnabled()
        }
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <StyledScrollView
          scrollEventThrottle={100}
          showsVerticalScrollIndicator={false}
          {...restProps}
          keyboardDismissMode={keyboardDismissMode}
          keyboardShouldPersistTaps={keyboardShouldPersistTaps}
          scrollEnabled={
            scrollEnabled &&
            ("scrollEnabled" in restProps ? restProps.scrollEnabled : true)
          }
          onScroll={onScroll}
          ref={captureScrollViewRef}
          onLayout={onLayout}
          onContentSizeChange={onContentSizeChange}
        >
          {children}
        </StyledScrollView>
      </StyledKeyboardAvoidingView>
    </ScrollViewController.Provider>
  );
});
QKScrollView.displayName = "QKScrollView";

const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView)`
  flex: 1;
  flex-basis: auto;
`;
const StyledScrollView = styled(RNScrollView)`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  flex-basis: auto;
`;

export default QKScrollView;
