import { DateTimePicker } from "@app/components/questkit/dateTimePicker";
import { ItemRenderData } from "@app/types/itemRenderData";
import React, { useCallback } from "react";
import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";

const createNewItem = (
  item: ItemRenderData,
  newValue: string | null
): ItemRenderData => ({
  ...item,
  data: {
    ...item.data,
    value: newValue,
  },
  version: item.version + 1,
});

interface DateTimeInputContainerProps extends ItemBaseProps {
  time?: boolean;
}

export const DateTimeInputItem: React.FC<DateTimeInputContainerProps> = (
  props
) => {
  const value = props.item.data?.value;

  const { time, onItemChange, onItemValidate, item, editMode } = props;
  const onDone = useCallback(
    (date: string | null) => {
      if (!time) {
        date = date ? date.replace(/\//g, "-") : null;
      }
      onItemChange?.(createNewItem(item, date));
      onItemValidate?.();
    },
    [time, item, onItemChange, onItemValidate]
  );

  const titleFill = props.time ? "Select a time & date" : "Select a date";
  const titleEdit = props.time
    ? "Date & time will be selected here"
    : "Date will be selected here";
  const title = editMode ? titleEdit : titleFill;

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <DateTimePicker
          onDone={onDone}
          readOnly={props.readOnly}
          title={title}
          time={props.time}
          value={value}
          accessibilityLabelledBy={`item-name-label-${props.item.prototype.id}`}
        />
      }
    />
  );
};
