import { useMemo, useRef } from "react";
import { QuestPrototypeDetail } from "@questmate/openapi-spec";
import { fetchQuestPrototype } from "@app/util/client/requests/quests";
import { useRequest } from "@app/util/client/requests";
import { QMApiError } from "@app/util/client";
import { SWRConfiguration } from "swr";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

export type UseQuestPrototypeReturn = {
  questPrototype: QuestPrototypeDetail | undefined;
  isLoading: boolean;
  hasLoaded: boolean;
  error: QMApiError | undefined;
  refresh: () => Promise<void>;
};

export const useQuestPrototype = (
  questPrototypeId: string | undefined,
  swrConfiguration?: SWRConfiguration
): UseQuestPrototypeReturn => {
  const {
    data: questPrototype,
    isValidating: isLoading,
    error: error,
    refresh,
  } = useRequest(fetchQuestPrototype(questPrototypeId), swrConfiguration);

  const prevHasLoadedRef = useRef(false);
  const hasLoaded = useMemo(() => {
    if (prevHasLoadedRef.current) {
      return true;
    } else if (!!questPrototype) {
      prevHasLoadedRef.current = true;
      return true;
    }
    return false;
  }, [questPrototype]);

  return useIsEqualMemo({
    questPrototype,
    isLoading,
    hasLoaded,
    error,
    refresh,
  });
};
