import { useMemo, useRef, useState } from "react";
import { useInterval } from "@app/util/useInterval";
import { isAfter, isBefore } from "date-fns";
import { useEffectOnce } from "@app/util/useEffectOnce";

/**
 * This hook provides comparison functions to determine if a date is in the past or future.
 * The hook will check every second if the result of the comparison has changed, and if so,
 * trigger a re-render by incrementing a counter.
 */
export const useCurrentTimeComparison = (): UseDateComparisonResult => {
  const trackedComparisonsRef = useRef<TrackedDateComparison[]>([]);
  // clear the array on every render as we don't want to keep stale comparisons around and
  // current comparisons will be added again each render
  trackedComparisonsRef.current.splice(0, trackedComparisonsRef.current.length);

  // counter just used as a mechanism to trigger a re-render
  const [, setCounter] = useState(0);
  const { start } = useInterval(() => {
    for (const trackedComparison of trackedComparisonsRef.current) {
      const liveResult =
        trackedComparison.comparison === "IS_IN_PAST"
          ? isAfter(new Date(), trackedComparison.date)
          : isBefore(new Date(), trackedComparison.date);
      if (liveResult !== trackedComparison.lastResultReturned) {
        setCounter((c) => c + 1); // trigger a re-render if the result changed
        return;
      }
    }
  }, 1000);
  useEffectOnce(start);

  return useMemo(
    () => ({
      isInPast: (date: Date) => {
        const result = isAfter(new Date(), date);
        trackedComparisonsRef.current.push({
          comparison: "IS_IN_PAST",
          date,
          lastResultReturned: result,
        });
        return result;
      },
      isInFuture: (date: Date) => {
        const result = isBefore(new Date(), date);
        trackedComparisonsRef.current.push({
          comparison: "IS_IN_FUTURE",
          date,
          lastResultReturned: result,
        });
        return result;
      },
    }),
    []
  );
};

type UseDateComparisonResult = {
  isInPast: (date: Date) => boolean;
  isInFuture: (date: Date) => boolean;
};

type TrackedDateComparison = {
  comparison: "IS_IN_PAST" | "IS_IN_FUTURE";
  date: Date;
  lastResultReturned: boolean;
};
