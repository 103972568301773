import { v4 as uuidv4 } from "uuid";

export const uuid = (): string => {
  return uuidv4({
    rng: function mathRNG() {
      const rnds = new Array<number>(16);
      for (let i = 0, r; i < 16; i++) {
        if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        rnds[i] = (r >>> ((i & 0x03) << 3)) & 0xff;
      }
      return rnds;
    },
  });
};
