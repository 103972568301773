import React, { useCallback, useState } from "react";
import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { apiRequest, QMApiError } from "@app/util/client";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { addSessionToken } from "@app/store/publicQuestAuth";
import { useAppDispatch, useAppSelector } from "@app/store";
import { GetPublicQuestResponse } from "@questmate/openapi-spec";
import { usePromise } from "@app/util/usePromise";
import { sentry } from "@app/util/sentry";
import { cleanAxiosError } from "@questmate/common";
import {
  minimalPublicQuestDataLoaded,
  selectAllQuestStartTriggers,
} from "@app/store/cache/questStartTriggers";
import { InvalidAuthDomainView } from "@app/components/screen/InvalidAuthDomainView";
import isEqual from "react-fast-compare";

function fetchPublicQuest(questPublicId: string) {
  return apiRequest<GetPublicQuestResponse>(
    "get",
    `/publicQuests/${questPublicId}`
  );
}

export const QuestPublicAssignmentScreen: React.FC = () => {
  const navigation = useAppNavigation<"PublicAssignment">();
  const route = useAppRoute<"PublicAssignment">();
  const { id: questPublicId } = route.params;
  const dispatch = useAppDispatch();
  const { execute, error, isLoading } = usePromise(fetchPublicQuest);
  const [responseResult, setResponseResult] = useState<
    null | GetPublicQuestResponse["result"] | "UNKNOWN"
  >(null);

  const requestNewPublicQuestSessionToken = useCallback(() => {
    setResponseResult(null);
    execute(questPublicId)
      .then((response) => {
        if (response?.result === "OK") {
          if (response.publicQuestSessionToken) {
            dispatch(
              addSessionToken({
                questInstanceId: response.questInstanceId,
                sessionToken: response.publicQuestSessionToken,
              })
            );
          }
          navigation.replace("QuestInstance", {
            id: response.questInstanceId,
            publicId: questPublicId,
          });
        } else if (response?.result === "AUTHENTICATION_REQUIRED") {
          dispatch(minimalPublicQuestDataLoaded(response.startTrigger));
          const routes = navigation.getState().routes;
          const currentRoute = routes[routes.length - 1];
          if (currentRoute.name === "PublicAssignment") {
            navigation.navigateToLoginThenRedirectBackHere();
          }
        } else if (response?.result === "INVALID_AUTH_DOMAIN") {
          dispatch(minimalPublicQuestDataLoaded(response.startTrigger));
        } else {
          console.error(
            "Unexpected response when loading Public Quest: ",
            response
          );
          sentry.captureException(
            new Error("Unexpected response when loading Public Quest"),
            { extra: { data: response } }
          );
        }
        setResponseResult(response?.result ?? "UNKNOWN");
      })
      .catch((error: QMApiError) => {
        setResponseResult("UNKNOWN");

        console.error("Error loading Public Quest: ", error);
        sentry.captureException(new Error("Error loading Public Quest"), {
          extra: { errorResponse: cleanAxiosError(error) },
        });
      });
  }, [execute, dispatch, navigation, questPublicId]);

  useEffectOnce(() => {
    requestNewPublicQuestSessionToken();
  });

  const allowedAuthDomains = useAppSelector((state) => {
    return selectAllQuestStartTriggers(state).find(
      (trigger) => trigger.config?.publicId === questPublicId
    )?.config?.authDomains;
  }, isEqual);

  if (responseResult === "INVALID_AUTH_DOMAIN") {
    return (
      <StyledView>
        <InvalidAuthDomainView
          allowedAuthDomains={allowedAuthDomains}
          reload={requestNewPublicQuestSessionToken}
        />
      </StyledView>
    );
  } else if ((responseResult !== null && responseResult !== "OK") || error) {
    return (
      <StyledView>
        <PlaceholderView
          text="Oops, that didn't quite work."
          actions={[
            {
              type: "primary",
              text: "Reload",
              loading: isLoading,
              onPress: requestNewPublicQuestSessionToken,
            },
          ]}
        />
      </StyledView>
    );
  } else {
    return (
      <StyledView>
        <Loader />
      </StyledView>
    );
  }
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
