import { TimeAmount, TimeAmountUnit } from "@app/types/time";

const timeUnitInSeconds = {
  // Don't change order, as convertToTimeAmount depends on it
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86400,
  week: 604800,
  month: 2628000,
  year: 31536000,
};

const convertToSeconds = (timeAmount: TimeAmount | null): number | null => {
  if (timeAmount === null || timeAmount === undefined) {
    return null;
  }

  return timeAmount.value * timeUnitInSeconds[timeAmount.unit];
};

type ConvertToTimeAmountResult<T extends number | null | undefined> =
  T extends number ? TimeAmount : null;
const convertToTimeAmount = <T extends number | null | undefined>(
  value: T
): ConvertToTimeAmountResult<T> => {
  if (value === null || value === undefined) {
    return null as ConvertToTimeAmountResult<T>;
  }
  const units = Object.keys(timeUnitInSeconds).reverse() as TimeAmountUnit[];
  for (const unit of units) {
    if (value % timeUnitInSeconds[unit] === 0) {
      return {
        value: value / timeUnitInSeconds[unit],
        unit,
      } as ConvertToTimeAmountResult<T>;
    }
  }
  throw new Error(`Unable to identify time unit for value: ${value}.`);
};

export { convertToSeconds, convertToTimeAmount };
