import React, { useEffect, useRef } from "react";
import { Animated, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import { Text } from "@app/components/questkit/text";

interface ProgressBarProps {
  count: number;
  total: number;
  style?: StyleProp<ViewStyle>;
  customHeight?: number;
  animateOnInit?: boolean;
  icon?: IconIdentifier;
}

interface AnimatedProgressProps {
  count: number;
  total: number;
  customHeight: number;
  animateOnInit?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  count,
  total,
  style,
  icon = "checkmark",
  animateOnInit = true,
  customHeight = 40,
}) => {
  return (
    <ProgressBarContainer style={style} accessibilityRole={"progressbar"}>
      <ProgressBarWrapper customHeight={customHeight}>
        <AnimatedProgress
          count={count}
          total={total}
          customHeight={customHeight}
          animateOnInit={animateOnInit}
        />
      </ProgressBarWrapper>
      <Summary customHeight={customHeight}>
        <Icon icon={icon} container={{ height: customHeight, width: 32 }} />
        <StyledText size="small">
          {count} / {total}
        </StyledText>
      </Summary>
    </ProgressBarContainer>
  );
};

const AnimatedProgress = ({
  count,
  total,
  customHeight,
  animateOnInit,
}: AnimatedProgressProps) => {
  const width = total < 1 ? 0 : (100 / total) * count;

  const animatedWidth = useRef(
    new Animated.Value(animateOnInit ? 0 : width)
  ).current;

  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      if (!animateOnInit) {
        // Do not initiate an Animated.timing on first render.
        return;
      }
    }
    Animated.timing(animatedWidth, {
      toValue: width,
      duration: 1000,
      delay: 350,
      useNativeDriver: false,
      isInteraction: false,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Progress
      customHeight={customHeight}
      hasNoProgress={count === 0}
      style={{
        width: animatedWidth.interpolate({
          inputRange: [0, 100],
          outputRange: ["0%", "100%"],
        }),
      }}
    />
  );
};

const Summary = styled.View<{ customHeight: number }>`
  flex-direction: row;
  align-items: center;
  height: ${({ customHeight }) => `${customHeight}px`};
  border-radius: ${({ customHeight }) => `${customHeight / 2}px`};
  background-color: white;
`;
const ProgressBarContainer = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Progress = styled(Animated.View)<{
  customHeight: number;
  hasNoProgress: boolean;
}>`
  height: ${({ customHeight }) => `${customHeight}px`};
  ${({ hasNoProgress, customHeight }) =>
    hasNoProgress ? "" : `min-width: ${customHeight}px`};
  border-radius: ${({ customHeight }) => `${customHeight / 2}px`};
  overflow: hidden;
  background-color: ${({ theme }) => theme.progressBar.fill};
`;

const StyledText = styled(Text)`
  margin-right: 14px;
`;

const ProgressBarWrapper = styled.View<{ customHeight: number }>`
  height: ${({ customHeight }) => `${customHeight}px`};
  border-radius: ${({ customHeight }) => `${customHeight / 2}px`};
  background-color: ${({ theme }) => theme.progressBar.background};
  flex: 1;
  overflow: hidden;
`;
