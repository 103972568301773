import * as React from "react";
import styled from "styled-components/native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useClockTick } from "@app/util/Clock";
import { colors } from "@app/themes/Colors";

interface DueDateBadgeProps {
  dueAt: Date | null;
  remindAt?: Date | null;
  alertAt?: Date | null;
}
export const DueDateBadge: React.FC<DueDateBadgeProps> = ({
  dueAt,
  remindAt,
  alertAt,
}) => {
  const [color, setColor, colorRef] = useStateWithRef(() =>
    getColor(Date.now(), dueAt, remindAt, alertAt)
  );

  useClockTick((now) => {
    const newColor = getColor(now, dueAt, remindAt, alertAt);
    if (newColor !== colorRef.current) {
      setColor(newColor);
    }
  });

  return !color ? null : <Badge color={color} />;
};

function getColor(
  now: number,
  dueAt: Date | null,
  remindAt?: Date | null,
  alertAt?: Date | null
): string | undefined {
  if (!dueAt) {
    return undefined;
  } else if (dueAt.getTime() < now) {
    return colors.red500;
  } else if (alertAt && alertAt.getTime() < now) {
    return colors.red500;
  } else if (remindAt && remindAt.getTime() < now) {
    return colors.yellow800;
  }
}

interface BadgeProps {
  color: string;
}
export const Badge = styled.View<BadgeProps>`
  background-color: ${({ color }) => color};
  width: 8px;
  height: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid white;
`;
