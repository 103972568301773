import React, { useCallback, useRef } from "react";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import { ItemRenderData } from "@app/types/itemRenderData";
import styled, { useTheme } from "styled-components/native";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";
import {
  findNodeHandle,
  GestureResponderEvent,
  LayoutRectangle,
  Platform,
  View,
} from "react-native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useScrollViewController } from "@app/components/questkit/ScrollView";
import { useTimeout } from "@app/util/useTimeout";

const changeRating = (
  item: ItemRenderData,
  newRating: number
): ItemRenderData => ({
  ...item,
  data: {
    value: newRating,
  },
  version: item.version + 1,
});

export const RatingItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange } = props;
  const itemRef = useRef<ItemRenderData>();
  itemRef.current = item;

  const onChange = useCallback(
    (newRating: number) => {
      onItemChange?.(changeRating(itemRef.current!, newRating));
    },
    [onItemChange]
  );
  const theme = useTheme();
  const filledColor = props.editMode
    ? theme.interaction.neutralStrong
    : theme.interaction.primary;
  const unfilledBorderColor = theme.interaction.neutralMedium;
  const unfilledColor = "transparent";

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <RatingScale
          steps={1}
          rating={item.data.value}
          onChange={onChange}
          readOnly={props.editMode || props.readOnly}
          filledColor={filledColor}
          unfilledBorderColor={unfilledBorderColor}
          unfilledColor={unfilledColor}
        />
      }
    />
  );
};

type RatingComponentProps = {
  rating: number | undefined;
  onChange: (newRating: number) => void;
  min?: number;
  max?: number;
  steps?: number;
  filledColor?: string;
  unfilledColor?: string;
  unfilledBorderColor?: string;
  /**
   * Size of the rating icon in pixels
   */
  iconSize?: number;
  readOnly?: boolean;
};
export const RatingScale: React.FC<RatingComponentProps> = ({
  rating: _rating,
  onChange,
  filledColor,
  unfilledColor,
  unfilledBorderColor,
  min = 1,
  max = 5,
  steps = 1,
  iconSize = 40,
  readOnly = false,
}) => {
  const [rating, setRating, ratingRef] = useStateWithRef(_rating);
  const ratingIntervalComponentRefs = useRef<View[]>([]);
  const positionToRatingMapRef = useRef<[number, number][]>([]);

  const recalculatePositionToRatingMap = useCallback(
    (ratingIntervalComponentLayouts: LayoutRectangle[]) => {
      const newMap: [number, number][] = [];
      for (let i = 0; i < ratingIntervalComponentLayouts.length; i++) {
        const layout = ratingIntervalComponentLayouts[i];
        for (let j = 0; j < steps; j++) {
          const rating = i + j / steps;
          newMap.push([layout.x + (j / steps) * layout.width, rating]);
        }
      }
      newMap.push([Infinity, ratingIntervalComponentLayouts.length]);
      positionToRatingMapRef.current = newMap;
    },
    [steps]
  );
  const setRatingFromPosition = useCallback(
    (locationX: number) => {
      for (let i = 0; i < positionToRatingMapRef.current.length; i++) {
        const [x, unboundedRating] = positionToRatingMapRef.current[i];
        if (locationX <= x) {
          const newRating = Math.max(min, Math.min(unboundedRating, max));
          if (newRating !== ratingRef.current) {
            setRating(newRating);
          }
          return;
        }
      }
    },
    [max, min, ratingRef, setRating]
  );

  const ratingComponentContainerRef = useRef<View>(null);
  const onLayout = useCallback(() => {
    void Promise.all<LayoutRectangle>(
      ratingIntervalComponentRefs.current.map(
        (ref) =>
          new Promise((resolve) =>
            ref.measureLayout(
              findNodeHandle(ratingComponentContainerRef.current)!,
              (x: number, y: number, width: number, height: number) => {
                resolve({
                  x,
                  y,
                  width,
                  height,
                });
              },
              () => undefined
            )
          )
      )
    ).then((layouts) => {
      recalculatePositionToRatingMap(layouts);
    });
  }, [recalculatePositionToRatingMap]);

  const { setScrollEnabled, isScrollEnabled } = useScrollViewController();
  const initialScrollEnabledRef = useRef(isScrollEnabled());

  const startResponderTimestampRef = useRef<number | undefined>(undefined);
  const lastLocationXRef = useRef<number | undefined>(undefined);

  const { start: updateRatingIfNotScrolling, stop: cancelAsyncRatingUpdate } =
    useTimeout(() => {
      if (lastLocationXRef.current) {
        setRatingFromPosition(lastLocationXRef.current);
      }
    }, 150);

  const onResponderStart = useCallback(
    (e: GestureResponderEvent) => {
      e.preventDefault();
      initialScrollEnabledRef.current = isScrollEnabled();
      startResponderTimestampRef.current =
        Platform.OS === "ios" ? Date.now() : e.nativeEvent.timestamp;
      lastLocationXRef.current = e.nativeEvent.locationX;
      updateRatingIfNotScrolling();
    },
    [isScrollEnabled, updateRatingIfNotScrolling]
  );

  const onResponderMove = useCallback(
    (e: GestureResponderEvent) => {
      const now = Platform.OS === "ios" ? Date.now() : e.nativeEvent.timestamp;
      const diff = now - (startResponderTimestampRef.current ?? now);
      lastLocationXRef.current = e.nativeEvent.locationX;
      if (diff > 150) {
        if (isScrollEnabled()) {
          setScrollEnabled(false);
        }
        cancelAsyncRatingUpdate();
        setRatingFromPosition(e.nativeEvent.locationX);
      }
    },
    [
      cancelAsyncRatingUpdate,
      isScrollEnabled,
      setRatingFromPosition,
      setScrollEnabled,
    ]
  );

  const onResponderEnd = useCallback(
    (e: GestureResponderEvent) => {
      // when view is scrolled our responder is terminated and onResponderEnd is not called
      setScrollEnabled(initialScrollEnabledRef.current);
      cancelAsyncRatingUpdate();
      setRatingFromPosition(e.nativeEvent.locationX);
      onChange(ratingRef.current);
    },
    [
      cancelAsyncRatingUpdate,
      onChange,
      ratingRef,
      setRatingFromPosition,
      setScrollEnabled,
    ]
  );

  return (
    <RatingComponentContainer
      ref={ratingComponentContainerRef}
      onLayout={onLayout}
      readOnly={readOnly}
      onStartShouldSetResponder={readOnly ? falseFn : trueFn}
      onMoveShouldSetResponder={readOnly ? falseFn : trueFn}
      // when view is scrolled our responder is terminated, we should not update the rating in this case.
      onResponderTerminate={cancelAsyncRatingUpdate}
      onResponderStart={onResponderStart}
      onResponderMove={onResponderMove}
      onResponderEnd={onResponderEnd}
      accessibilityRole={"adjustable"}
      accessibilityValue={{ min, max, now: rating }}
    >
      {Array.from({ length: max }, (_, i) => i).map((value) => {
        const percentFilled = Math.min(Math.max(0, (rating ?? 0) - value), 1);
        return (
          <RatingIntervalIcon
            key={value}
            ref={(ref) => {
              if (ref) {
                ratingIntervalComponentRefs.current[value] = ref;
              }
            }}
            size={iconSize}
            percentFilled={percentFilled}
            filledColor={filledColor!}
            unfilledColor={unfilledColor!}
            unfilledBorderColor={unfilledBorderColor!}
          />
        );
      })}
    </RatingComponentContainer>
  );
};
const trueFn = () => true;
const falseFn = () => false;

type RatingIntervalIconProps = {
  /**
   * A number between 0 and 1 representing the percentage of the icon that should be filled.
   */
  percentFilled: number;
  filledColor: string;
  unfilledColor: string;
  unfilledBorderColor: string;
  size: number;
};
export const RatingIntervalIcon = React.memo(
  React.forwardRef<View, RatingIntervalIconProps>(
    // eslint-disable-next-line react/prop-types
    (
      { percentFilled, filledColor, unfilledColor, unfilledBorderColor, size },
      ref
    ) => {
      return (
        <RatingIntervalIconContainer
          ref={ref}
          pointerEvents={"none"}
          iconSize={size}
        >
          <StarRatingIcon
            height={size}
            percentFilled={percentFilled}
            filledColor={filledColor}
            unfilledColor={unfilledColor}
            unfilledBorderColor={unfilledBorderColor}
          />
        </RatingIntervalIconContainer>
      );
    }
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.percentFilled === nextProps.percentFilled &&
      prevProps.filledColor === nextProps.filledColor &&
      prevProps.unfilledColor === nextProps.unfilledColor &&
      prevProps.unfilledBorderColor === nextProps.unfilledBorderColor
    );
  }
);
RatingIntervalIcon.displayName = "RatingIntervalIcon";

type StarRatingIconProps = {
  percentFilled: number;
  filledColor: string;
  unfilledColor: string;
  unfilledBorderColor: string;
  height: number;
};
export const StarRatingIcon: React.FC<StarRatingIconProps> = ({
  percentFilled,
  filledColor,
  unfilledColor,
  unfilledBorderColor,
  height,
}) => {
  /**
   * Path from: https://uxwing.com/star-symbol-icon
   *
   * Web & Native have different syntax for defining a clipPath.
   * Check react-native-svg to see if there are any updates to this...
   */
  const starFilledInPath =
    "M64.42,2,80.13,38.7,120,42.26a3.2,3.2,0,0,1,1.82,5.62h0L91.64,74.18l8.9,39A3.19,3.19,0,0,1,98.12,117a3.27,3.27,0,0,1-2.46-.46L61.41,96.1,27.07,116.64a3.18,3.18,0,0,1-4.38-1.09,3.14,3.14,0,0,1-.37-2.38h0l8.91-39L1.09,47.88a3.24,3.24,0,0,1-.32-4.52,3.32,3.32,0,0,1,2.29-1l39.72-3.56L58.49,2a3.24,3.24,0,0,1,5.93,0Z";
  return (
    <Svg viewBox="0 0 122.88 117.42" height={height}>
      {/*  Star Outline from https://uxwing.com/star-line-yellow-icon# */}
      <Path
        fill={unfilledBorderColor}
        id="star-outline"
        d="M66.71 3.55L81.1 37.26l36.58 3.28v-.01c1.55.13 2.91.89 3.85 2.01a5.663 5.663 0 011.32 4.13v.01a5.673 5.673 0 01-1.69 3.57c-.12.13-.25.25-.39.36L93.25 74.64l8.19 35.83c.35 1.53.05 3.06-.73 4.29a5.652 5.652 0 01-3.54 2.52l-.14.03c-.71.14-1.43.15-2.12.02v.01c-.75-.13-1.47-.42-2.11-.84l-.05-.03-31.3-18.71-31.55 18.86a5.664 5.664 0 01-7.79-1.96c-.38-.64-.62-1.33-.73-2.02-.1-.63-.09-1.27.02-1.89.02-.13.04-.27.08-.4l8.16-35.7c-9.24-8.07-18.74-16.1-27.83-24.3l-.08-.08a5.64 5.64 0 01-1.72-3.7c-.1-1.45.36-2.93 1.4-4.12l.12-.13.08-.08a5.668 5.668 0 013.77-1.72h.06l36.34-3.26 14.44-33.8c.61-1.44 1.76-2.5 3.11-3.05 1.35-.54 2.9-.57 4.34.04.69.29 1.3.71 1.8 1.22.53.53.94 1.15 1.22 1.82l.02.06zm10.19 37.2L61.85 5.51a.42.42 0 00-.09-.14.42.42 0 00-.14-.09.427.427 0 00-.35 0c-.1.04-.19.12-.24.24L45.98 40.75c-.37.86-1.18 1.49-2.18 1.58l-37.9 3.4c-.08.01-.16.02-.24.02-.06 0-.13.02-.18.05-.03.01-.05.03-.07.05l-.1.12c-.05.08-.07.17-.06.26.01.09.04.18.09.25.06.05.13.11.19.17l28.63 25c.77.61 1.17 1.62.94 2.65l-8.51 37.22-.03.14c-.01.06-.02.12-.01.17a.454.454 0 00.33.36c.12.03.24.02.34-.04l32.85-19.64c.8-.5 1.85-.54 2.72-.02L95.43 112c.08.04.16.09.24.14.05.03.1.05.16.06v.01c.04.01.09.01.14 0l.04-.01c.12-.03.22-.1.28-.2.06-.09.08-.21.05-.33L87.8 74.28a2.6 2.6 0 01.83-2.55l28.86-25.2c.04-.03.07-.08.1-.13.02-.04.03-.1.04-.17a.497.497 0 00-.09-.33.48.48 0 00-.3-.15v-.01c-.01 0-.03 0-.03-.01l-37.97-3.41c-1-.01-1.93-.6-2.34-1.57z"
      />
      {Platform.OS !== "web" ? (
        <Defs>
          <ClipPath id={"star-filled-in"}>
            <Path d={starFilledInPath} />
          </ClipPath>
        </Defs>
      ) : null}
      <G
        clipPath={
          Platform.OS === "web"
            ? `path('${starFilledInPath}')`
            : "#star-filled-in"
        }
        style={{
          // fixes bug with Safari when zooming in where the clipPath is zoomed greater than the rest of the SVG elements
          zoom: "reset",
        }}
      >
        <Rect
          fill={unfilledColor}
          x={percentFilled * 100 + "%"}
          y="0"
          width={(1 - percentFilled) * 100 + "%"}
          height="100%"
        />
        <Rect
          fill={filledColor}
          x="0"
          y="0"
          width={percentFilled * 100 + "%"}
          height="100%"
        />
      </G>
    </Svg>
  );
};

const RatingIntervalIconContainer = styled.View<{ iconSize: number }>`
  height: ${({ iconSize }) => iconSize}px;
  width: ${({ iconSize }) => iconSize}px;
  margin-right: 10px;
`;
const RatingComponentContainer = styled.View<{ readOnly: boolean }>`
  flex-direction: row;
  align-items: center;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
  ${({ readOnly }) =>
    Platform.OS === "web" && !readOnly ? "cursor: pointer;" : ""}
  height: 40px;
`;
