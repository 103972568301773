import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { KeyboardAvoidingView, Platform, Pressable } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import QuestRunOptionsDialog from "@app/components/modal/questRunOptionsDialog";
import Loader from "@app/components/animated/loader";
import {
  EditLevel,
  QuestView,
  SubmitButtonBehaviour,
} from "@app/components/screen/quest/common/questView";
import { useQuestInstance } from "@app/util/client/hooks/useQuestInstance";
import SafeAreaView from "react-native-safe-area-view";
import { StackActions } from "@react-navigation/native";
import { updateQuests } from "@app/util/cacheMod";
import { ReviewDialog } from "@app/components/modal/reviewDialog";
import { getButtonAndMessageBehaviors } from "@app/components/screen/quest/questInstanceView";
import { getQuestCompletionNavigationConfig } from "@app/util/client/questCompletionNavigation";
import { RunQuestViewContextProvider } from "@app/quest/run/RunQuestViewContextProvider";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { QuestInstanceDetail } from "@questmate/openapi-spec";
import QKScrollView, {
  useScrollViewController,
} from "@app/components/questkit/ScrollView";
import { QuestInfo } from "@app/components/screen/quest/QuestInfo";
import { selectLoggedInUserId } from "@app/store/auth";
import { useAppSelector } from "@app/store";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { AccessDeniedView } from "@app/components/screen/AccessDeniedView";
import { selectLoggedInUser, selectUserById } from "@app/store/cache/users";
import { selectAssignmentsWithAssigneeByQuestInstanceId } from "@app/store/cache/assignments";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import { useRequest } from "@app/util/client/requests";
import {
  fetchQuestInstance,
  restartQuestInstance,
} from "@app/util/client/requests/quests";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import Icon from "@app/components/icon";
import { sentry } from "@app/util/sentry";
import { cleanAxiosError } from "@questmate/common";
import { RestartQuestRunButton } from "./RestartQuestRunButton";

export const AdminQuestRunScreen: React.FC = () => {
  const navigation = useAppNavigation<"AdminQuestRun">();
  const route = useAppRoute<"AdminQuestRun">();
  const { questInstanceId, loginId } = route.params;
  const isLoggedInUserDataLoaded = useAppSelector(
    (state) =>
      selectLoggedInUser(state)?.email || selectLoggedInUser(state)?.phone
  );

  const {
    data: questInstance,
    isValidating: questInstanceLoading,
    error: questInstanceError,
    refresh: refreshQuestInstance,
  } = useRequest(fetchQuestInstance(questInstanceId), {
    revalidateOnFocus: false,
  });

  // TODO: Allow showing this screen immediately if all necessary Quest Instance data is already available in the cache.
  //       Even when this is the case, we may still want to show an error afterwards if there is an update,
  //       e.g. They were allowed to see it before, but now it's a 403.
  //       Not sure if we have a scenario that would cause this, but it's worth considering.

  const instanceRoles = questInstance?.requestingUser?.instanceRoles;
  const userHasAccessButIsNotAnAdmin =
    Array.isArray(instanceRoles) &&
    instanceRoles.length > 0 &&
    !instanceRoles.includes("OWNER");

  useEffect(() => {
    if (userHasAccessButIsNotAnAdmin) {
      // Redirect them to the QuestInstance screen instead as they should not see the admin UI.
      navigation.replace("QuestInstance", { id: questInstanceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasAccessButIsNotAnAdmin]);
  const hasLoadedRef = useRef(false);
  hasLoadedRef.current = hasLoadedRef.current || !!questInstance;

  if (userHasAccessButIsNotAnAdmin || !hasLoadedRef.current || !questInstance) {
    if (questInstanceError && isLoggedInUserDataLoaded) {
      console.error(
        "Error received while loading Quest instance",
        questInstanceError
      );
      const userDoesNotHavePermission = questInstanceError.status === 403;
      return (
        <ViewContainer>
          {userDoesNotHavePermission ? (
            <AccessDeniedView reload={refreshQuestInstance} loginId={loginId} />
          ) : (
            <PlaceholderView
              text="Oops, that didn't quite work."
              actions={[
                {
                  type: "primary",
                  text: "Reload",
                  loading: questInstanceLoading,
                  onPress: refreshQuestInstance,
                },
              ]}
            />
          )}
        </ViewContainer>
      );
    }
    return (
      <ViewContainer>
        <Loader />
      </ViewContainer>
    );
  }
  return (
    <StyledKeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <StyledScrollView
        keyboardShouldPersistTaps="always"
        keyboardAvoidingViewProps={{ enabled: false }}
      >
        <SafeAreaView
          style={{ flex: 1 }}
          forceInset={{ top: "always", bottom: "never" }}
        >
          <RunQuestViewContextProvider
            questInstanceId={questInstanceId}
            allowedAuthStrategies={["AUTH0"]}
          >
            <AdminQuestRunView />
          </RunQuestViewContextProvider>
        </SafeAreaView>
        {Platform.OS === "ios" && <BottomEdge />}
      </StyledScrollView>
    </StyledKeyboardAvoidingView>
  );
};

const AdminQuestRunView: React.FC = () => {
  const navigation = useAppNavigation<"AdminQuestRun">();
  const [showQuestRunOptionsModal, setShowQuestRunOptionsModal] =
    useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { scrollToEnd } = useScrollViewController();
  const { questInstanceId, recentlySubmittedByUser } = useQuestViewContext([
    "RUN",
  ]);

  const submissionBehavior = useAppSelector(
    (state) =>
      selectQuestInstanceById(state, questInstanceId)?.submissionBehavior
  );
  const [showRestartButton, setShowRestartButton] = useState(false);
  const restartButton = useMemo(() => {
    if (
      !showRestartButton ||
      !submissionBehavior ||
      submissionBehavior.type !== "RESTART"
    ) {
      return null;
    }
    return (
      <RestartQuestRunButton restartSubmissionBehavior={submissionBehavior} />
    );
  }, [showRestartButton, submissionBehavior]);

  const onSuccess = useCallback(
    (updatedQuestInstance: QuestInstanceDetail) => {
      void updateQuests();

      setTimeout(() => scrollToEnd?.(), 0);

      if (
        updatedQuestInstance.submissionBehavior.type === "RESTART" &&
        !updatedQuestInstance.submissionBehavior.nextInstanceId
      ) {
        setShowRestartButton(true);
        // perform restart
        void restartQuestInstance(updatedQuestInstance.id).catch((error) => {
          setShowRestartButton(false);
          sentry.captureException(
            new Error("Failed to restart Quest after submission"),
            { contexts: { Details: { response: cleanAxiosError(error) } } }
          );
        });
      } else {
        // do previous
        const instanceCompleted = updatedQuestInstance.status === "COMPLETED";

        if (instanceCompleted) {
          const defaultNavigationAction = StackActions.pop();

          const navigationConfig = getQuestCompletionNavigationConfig(
            updatedQuestInstance,
            defaultNavigationAction
          );

          if (navigationConfig.navigationAction) {
            const navigationTimeout = setTimeout(() => {
              navigation.dispatch(navigationConfig.navigationAction!);
            }, navigationConfig.navigationDelay);
            return () => {
              // if the user navigates away before the timeout triggers
              // we should cancel the timeout to not navigate
              clearTimeout(navigationTimeout);
            };
          }
        }
      }
    },
    [navigation, scrollToEnd]
  );

  const {
    completionActions,
    items,
    onItemChange,
    onItemValidate,
    onSubmit,
    onReview,
    submitQuestLoading,
  } = useQuestInstance(questInstanceId);

  const userId = useAppSelector(selectLoggedInUserId);
  const assignments = useAppSelector((state) =>
    selectAssignmentsWithAssigneeByQuestInstanceId(state, questInstanceId)
  );
  const submitButtonTitle = useMemo(() => {
    if (assignments.length === 1 && assignments[0]) {
      if (assignments[0].assignee?.id === userId) {
        return `Submit for myself`;
      } else {
        return `Submit for ${assignments[0].assignee?.displayName}`;
      }
    } else if (
      assignments.length > 1 &&
      assignments.every(({ assignee }) => assignee?.id !== userId)
    ) {
      return "Submit for assignees";
    } else {
      return "Submit";
    }
  }, [assignments, userId]);

  const questInstance = useAppSelector((state) =>
    selectQuestInstanceById(state, questInstanceId)
  );
  const startedByUser = useAppSelector((state) => {
    const startedByUserId = selectQuestInstanceById(
      state,
      questInstanceId
    )?.startedByUserId;
    if (!startedByUserId) {
      return;
    }
    return selectUserById(state, startedByUserId);
  });

  const { submitButtonBehavior, reviewButtonBehavior, statusMessageBehavior } =
    getButtonAndMessageBehaviors(
      questInstance?.status,
      questInstance?.requestingUser,
      false
    );
  // Assignment dialog state
  // const [showAssignmentsModal, setShowAssignmentsModal] = useState(false);
  // const assigneePickerRef = useRef<UserPickerController>(null);
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderIcon
          activeOpacity={0.8}
          onPress={() => {
            setShowQuestRunOptionsModal(true);
          }}
        >
          <Icon icon={"gear"} />
        </HeaderIcon>
      ),
    });
  });

  // const {
  //   isLoading: loadingQuestPrototype,
  //   error: questPrototypeError,
  //   refresh: reloadQuestPrototype,
  //   hasLoaded,
  // } = useQuestPrototype(questPrototypeId, { refreshInterval: 10000 });

  // const [saveAssigneeLoading, setSaveAssigneeLoading] = useState(false);
  // const [saveAssigneeSuccess, setSaveAssigneeSuccess] = useState(false);

  return (
    <>
      <Header
        questName={questInstance.name}
        onPress={() => setShowQuestRunOptionsModal(true)}
      />
      <QuestInfoWrapper>
        <QuestInfo
          ownerName={startedByUser?.displayName}
          dueAt={questInstance?.dueAt || null}
          ownerAvatarUrl={startedByUser?.avatarSmallUrl}
        />
      </QuestInfoWrapper>
      <QuestBackground>
        {!questInstance ? null : (
          <MediaContext.Provider
            value={{
              uploadContextType: "questInstance",
              uploadContextId: questInstance.id,
              contexts: [
                {
                  id: questInstance.id,
                  type: "questInstance",
                },
                ...(questInstance.prototypeId
                  ? [
                      {
                        id: questInstance.prototypeId,
                        type: "questPrototype" as const,
                      },
                    ]
                  : []),
              ],
            }}
          >
            <QuestView
              introText={questInstance.introText}
              items={items}
              onItemChange={onItemChange}
              onItemValidate={onItemValidate}
              completionActions={completionActions}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              loading={submitQuestLoading}
              completed={questInstance?.status === "COMPLETED"}
              restartButton={recentlySubmittedByUser ? restartButton : null}
              canEditIntroText={false}
              itemsEditLevel={
                questInstance?.status === "COMPLETED"
                  ? EditLevel.Readonly
                  : EditLevel.Interactive
              }
              completionActionsEditLevel={EditLevel.Interactive}
              onReviewStart={() => {
                setShowReviewModal(true);
              }}
              reviewButtonBehavior={reviewButtonBehavior}
              statusMessageBehavior={statusMessageBehavior}
              submitButtonBehaviour={
                recentlySubmittedByUser && restartButton
                  ? SubmitButtonBehaviour.Hidden
                  : submitButtonBehavior
              }
              submitButtonTitle={submitButtonTitle}
              slideMode={false}
              renderKey={questInstance.id}
            />

            <QKModal
              showModal={showReviewModal}
              setShowModal={setShowReviewModal}
              title="Review"
            >
              <ReviewDialog
                questInstanceId={questInstance.id}
                onSuccess={(reviewResult) => {
                  onReview(reviewResult);
                  setTimeout(
                    () => setShowReviewModal(false),
                    MODAL_CLOSE_DELAY
                  );
                }}
              />
            </QKModal>
          </MediaContext.Provider>
        )}
      </QuestBackground>

      <QKModal
        showModal={showQuestRunOptionsModal}
        setShowModal={setShowQuestRunOptionsModal}
        title="Run Options"
      >
        <QuestRunOptionsDialog
          questInstanceId={questInstance.id}
          setShowOptionsModal={setShowQuestRunOptionsModal}
        />
      </QKModal>

      {/*<QKModal*/}
      {/*  showModal={showAssignmentsModal}*/}
      {/*  setShowModal={setShowAssignmentsModal}*/}
      {/*  title="Assignments"*/}
      {/*>*/}
      {/*  <OptionsDialogWrapper>*/}
      {/*    <QKScrollView keyboardShouldPersistTaps="always">*/}
      {/*      <View onStartShouldSetResponder={() => true}>*/}
      {/*        <AssigneePicker*/}
      {/*          ref={assigneePickerRef}*/}
      {/*          startConfigurationId={startConfigurationId!}*/}
      {/*        />*/}
      {/*      </View>*/}
      {/*    </QKScrollView>*/}
      {/*    <ActionPanel>*/}
      {/*      <StyledOptionsDialogButton*/}
      {/*        onPress={() => {*/}
      {/*          if (assigneePickerRef.current) {*/}
      {/*            setSaveAssigneeLoading(true);*/}
      {/*            assigneePickerRef.current*/}
      {/*              .flush()*/}
      {/*              .then((assignees) => {*/}
      {/*                return updateQuestAssignees(*/}
      {/*                  questPrototypeId,*/}
      {/*                  assignments.map(({ id, assignee }) => ({*/}
      {/*                    id,*/}
      {/*                    assigneeId: assignee.id,*/}
      {/*                  })),*/}
      {/*                  assignees*/}
      {/*                ).then((result) => {*/}
      {/*                  // TODO: Show snackbar message for assignments removed*/}
      {/*                  const { assignmentsAdded } = result;*/}
      {/*                  if (assignmentsAdded.length > 0) {*/}
      {/*                    // Show snackbar message for invitations sent*/}
      {/*                    snackbarContext.sendMessage(*/}
      {/*                      createInviteNotificationsString(assignmentsAdded),*/}
      {/*                      SnackbarSeverity.NOTICE,*/}
      {/*                      5000*/}
      {/*                    );*/}
      {/*                  }*/}

      {/*                  result.errors.forEach((error) => {*/}
      {/*                    console.log(error);*/}
      {/*                    snackbarContext.sendMessage(*/}
      {/*                      `Could not ${*/}
      {/*                        error.action === "ADD" ? "add" : "remove"*/}
      {/*                      } assignee.`,*/}
      {/*                      SnackbarSeverity.WARNING,*/}
      {/*                      5000*/}
      {/*                    );*/}
      {/*                  });*/}

      {/*                  if (result.errors.length === 0) {*/}
      {/*                    setSaveAssigneeSuccess(true);*/}
      {/*                    setTimeout(*/}
      {/*                      () => setShowAssignmentsModal(false),*/}
      {/*                      MODAL_CLOSE_DELAY*/}
      {/*                    );*/}
      {/*                  }*/}
      {/*                });*/}
      {/*              })*/}
      {/*              .catch((error) => {*/}
      {/*                const sentryEventId = sentry.captureException(error, {*/}
      {/*                  extra: {*/}
      {/*                    message: `Couldn't add assignees, please try again.`,*/}
      {/*                  },*/}
      {/*                });*/}
      {/*                console.log(error, "\nsentryEventId:", sentryEventId);*/}
      {/*                snackbarContext.sendMessage(*/}
      {/*                  `Couldn't add assignees, please try again.`,*/}
      {/*                  SnackbarSeverity.WARNING*/}
      {/*                );*/}
      {/*              })*/}
      {/*              .finally(() => {*/}
      {/*                setSaveAssigneeLoading(false);*/}
      {/*              });*/}
      {/*          }*/}
      {/*        }}*/}
      {/*        success={saveAssigneeSuccess}*/}
      {/*        loading={saveAssigneeLoading}*/}
      {/*        title="Save Assignments"*/}
      {/*      />*/}
      {/*    </ActionPanel>*/}
      {/*  </OptionsDialogWrapper>*/}
      {/*</QKModal>*/}
    </>
  );
};

let paddingTop;

switch (Platform.OS) {
  case "ios":
    paddingTop = 11;
    break;
  case "android":
    paddingTop = 16;
    break;
  case "web":
    paddingTop = 20;
    break;
  default:
    paddingTop = 20;
}

// const Responses = styled(Animated.View)`
//   background-color: ${({ theme }) => theme.actionBar.background};
//   padding-vertical: 20px;
//   padding-horizontal: 10px;
// `;

const QuestBackground = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex-grow: 1;
  padding-bottom: 16px;
`;

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingTop: paddingTop,
    flexGrow: 1,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const Header = ({
  questName,
  onPress,
}: {
  questName: string;
  onPress: () => void;
}) => {
  return (
    <TitlePressable
      focusable={false}
      onPress={onPress}
      // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
      tabIndex={-1}
    >
      <TitleText size="mediumBold">{questName}</TitleText>
    </TitlePressable>
  );
};

const TitlePressable = styled(Pressable)`
  margin-horizontal: 64px;
`;

const TitleText = styled(Text)`
  text-align: center;
`;

// const StyledResultDropdown = styled(DropdownWithModal)`
//   margin-bottom: 10px;
//   background-color: ${({ theme }) => theme.background};
// `;
//
// const StyledAssignmentButton = styled(Button)`
//   margin-bottom: 40px;
//   width: 221px;
//   align-self: center;
// `;
//
// const LoaderContainer = styled.View`
//   align-items: center;
//   justify-content: center;
//   flex: 1;
// `;

const ViewContainer = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const BottomEdge = styled.View`
  background-color: ${({ theme }) => theme.actionBar.background};
  height: 1000px;
  position: absolute;
  bottom: -1000px;
  left: 0;
  right: 0;
`;

// interface AdminQuestInstanceViewProps {
//   questPrototypeId: string;
// }
// const AdminQuestInstanceView: React.FC<AdminQuestInstanceViewProps> = ({
//   questPrototypeId,
// }) => {
//   const navigation = useAppNavigation();
//   const [showReviewModal, setShowReviewModal] = useState(false);
//   const { scrollToEnd } = useScrollViewController();
//   const { questInstanceId } = useQuestViewContext(false);
//
//   const questPrototype = useAppSelector(
//     (state) => selectAdminQuestRunData(state, questPrototypeId),
//     isEqual
//   )!;
//
//   const { mode } = questPrototype;
//
//   const onSuccess = useCallback(
//     (updatedQuestInstance: QuestInstanceDetail) => {
//       setTimeout(() => scrollToEnd?.(), 0);
//
//       const instanceCompleted = updatedQuestInstance.status === "COMPLETED";
//       void updateQuests();
//
//       if (instanceCompleted) {
//         const defaultNavigationAction =
//           mode === "SHARED_INSTANCE" ? StackActions.pop() : undefined;
//
//         const navigationConfig = getQuestCompletionNavigationConfig(
//           updatedQuestInstance,
//           defaultNavigationAction
//         );
//
//         if (navigationConfig.navigationAction) {
//           const navigationTimeout = setTimeout(() => {
//             navigation.dispatch(navigationConfig.navigationAction!);
//           }, navigationConfig.navigationDelay);
//           return () => {
//             // if the user navigates away before the timeout triggers
//             // we should cancel the timeout to not navigate
//             clearTimeout(navigationTimeout);
//           };
//         }
//       }
//     },
//     [navigation, mode, scrollToEnd]
//   );
//
//   const {
//     rewards,
//     items,
//     onItemChange,
//     onItemValidate,
//     onSubmit,
//     onReview,
//     completeQuestLoading,
//     SWR: {
//       data: questInstanceData,
//       isValidating: questInstanceLoading,
//       refresh: refreshQuestInstance,
//     },
//   } = useQuestInstance(questInstanceId);
//
//   const userId = useAppSelector(selectLoggedInUserId);
//   const submitButtonTitle = useMemo(() => {
//     const questInstanceAssignments = questPrototype.assignments.filter(
//       (assignment) => assignment.formInstanceId === questInstanceId
//     );
//     if (questInstanceAssignments.length === 1) {
//       if (questInstanceAssignments[0].assignee.id !== userId) {
//         return `Submit for ${questInstanceAssignments[0].assignee.displayName}`;
//       } else if (mode === "INDIVIDUAL_INSTANCE") {
//         return `Submit for myself`;
//       } else {
//         return "Submit";
//       }
//     } else if (
//       questInstanceAssignments.length > 1 &&
//       questInstanceAssignments.every(({ assignee }) => assignee.id !== userId)
//     ) {
//       return "Submit for assignees";
//     } else {
//       return "Submit";
//     }
//   }, [mode, questInstanceId, questPrototype.assignments, userId]);
//
//   if (!questInstanceData) {
//     if (questInstanceLoading) {
//       return (
//         <LoaderContainer>
//           <Loader />
//         </LoaderContainer>
//       );
//     } else {
//       return (
//         <PlaceholderView
//           text="Oops, that didn't quite work."
//           actions={[
//             {
//               type: "primary",
//               text: "Reload",
//               loading: questInstanceLoading,
//               onPress: refreshQuestInstance,
//             },
//           ]}
//         />
//       );
//     }
//   }
//
//   const { submitButtonBehavior, reviewButtonBehavior, statusMessageBehavior } =
//     getButtonAndMessageBehaviors(
//       questInstanceData.status,
//       questInstanceData.requestingUser,
//       false
//     );
//
//   return (
//     <MediaContext.Provider
//       value={{
//         uploadContextType: "questInstance",
//         uploadContextId: questInstanceData.id,
//         contexts: [
//           {
//             id: questInstanceData.id,
//             type: "questInstance",
//           },
//           {
//             id: questPrototype.id,
//             type: "questPrototype",
//           },
//         ],
//       }}
//     >
//       <QuestView
//         introText={questInstanceData.introText}
//         items={items}
//         onItemChange={onItemChange}
//         onItemValidate={onItemValidate}
//         rewards={rewards}
//         onSubmit={onSubmit}
//         onSuccess={onSuccess}
//         loading={completeQuestLoading}
//         completed={questInstanceData?.status === "COMPLETED"}
//         canEditIntroText={false}
//         showSectionHeaders={false}
//         itemsEditLevel={
//           questInstanceData?.status === "COMPLETED"
//             ? EditLevel.Readonly
//             : EditLevel.Interactive
//         }
//         rewardsEditLevel={EditLevel.Interactive}
//         onReviewStart={() => {
//           setShowReviewModal(true);
//         }}
//         reviewButtonBehavior={reviewButtonBehavior}
//         statusMessageBehavior={statusMessageBehavior}
//         submitButtonBehaviour={submitButtonBehavior}
//         submitButtonTitle={submitButtonTitle}
//         slideMode={false}
//         renderKey={questInstanceData.id}
//       />
//
//       <QKModal
//         showModal={showReviewModal}
//         setShowModal={setShowReviewModal}
//         title="Review"
//       >
//         <ReviewDialog
//           questInstanceId={questInstanceData.id}
//           onSuccess={(reviewResult) => {
//             onReview(reviewResult);
//             setTimeout(() => setShowReviewModal(false), MODAL_CLOSE_DELAY);
//           }}
//         />
//       </QKModal>
//     </MediaContext.Provider>
//   );
// };

const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView)`
  flex: 1;
  background-color: ${({ theme }) => theme.actionBar.background};
`;

// Action panel

// const ActionPanel = styled.View`
//   padding-top: 10px;
// `;
//
// const StyledOptionsDialogButton = styled(Button)`
//   width: 100%;
//   align-self: center;
//   margin-bottom: 24px;
// `;
//
// const OptionsDialogWrapper = styled.View`
//   padding-horizontal: 20px;
//   padding-vertical: 10px;
//   flex-shrink: 1;
// `;

const QuestInfoWrapper = styled.View`
  margin-top: 25px;
  margin-bottom: 40px;
`;

// const selectAdminQuestRunData = createSelector(
//   [
//     (state, questInstanceId: string) =>
//       selectQuestInstanceById(state, questInstanceId),
//     (state, questInstanceId: string) =>
//       selectAssignmentsWithAssigneeByQuestInstanceId(state, questInstanceId),
//     (state) => selectAllUsersById(state),
//   ],
//   (instance, assignments, usersById) => {
//     if (!instance) {
//       return undefined;
//     }
//     return {
//       ...instance,
//       assignments,
//       ...(instance.startedByUserId
//         ? { startedByUser: usersById[instance.startedByUserId] }
//         : {}),
//     };
//   }
// );
