import { StackActionType } from "@react-navigation/routers/src/StackRouter";
import { StackActions } from "@react-navigation/native";
import { Action } from "@react-navigation/routers/src/CommonActions";
import { QuestInstanceDetail } from "@questmate/openapi-spec";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";

export type QuestCompletionNavigationConfig =
  | Record<string, never>
  | {
      navigationAction?: StackActionType | Action;
      navigationDelay: number;
    };

export function getQuestCompletionNavigationConfig(
  updatedQuestInstance: QuestInstanceDetail,
  defaultNavigationAction?: StackActionType | Action
): QuestCompletionNavigationConfig {
  const userIsLoggedIn = Boolean(getLoggedInUserId());
  if (!userIsLoggedIn) {
    return {};
  }

  const rewards = updatedQuestInstance.prototype.rewards;

  const tremendousRewards = rewards.filter(({ type }) =>
    type.startsWith("TREMENDOUS")
  );
  const createQuestActions = rewards.filter(
    ({ type }) => type === "CREATE_QUEST"
  );
  const questScriptActions = rewards.filter(
    ({ type }) => type === "RUN_QUEST_SCRIPT"
  );
  const customItemCompletionActions =
    updatedQuestInstance.prototype.items.filter(
      ({ type, isCompletionAction }) =>
        type === "CustomV2" && isCompletionAction
    );

  let shouldNavigate = true;
  if (tremendousRewards.length > 0 || questScriptActions.length > 0) {
    shouldNavigate = false;
  } else if (createQuestActions.length > 1) {
    // do not navigate if more than one create Quest action
    shouldNavigate = false;
  } else if (customItemCompletionActions.length > 0) {
    // do not navigate if there are custom completion actions
    shouldNavigate = false;
  }

  if (shouldNavigate) {
    let navigationAction = defaultNavigationAction;
    let navigationDelay = 2000;

    if (createQuestActions.length === 1) {
      navigationAction = StackActions.replace("CreateQuestLoading", {
        questInstanceId: updatedQuestInstance.id,
        createQuestActionId: createQuestActions[0].id,
      });
      navigationDelay = 500; // time for user to watch submit button animation
    }

    return {
      navigationAction,
      navigationDelay,
    };
  } else {
    return {};
  }
}
