import React, { useCallback, useContext, useEffect } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import TextInput from "@app/components/questkit/textInput";
import styled from "styled-components/native";
import { useFocusableRef } from "@app/util/focus";
import YoutubePlayer, {
  PLAYER_STATES,
  YoutubeIframeProps,
  YoutubeIframeRef,
} from "react-native-youtube-iframe";
import { useIsFocused } from "@react-navigation/native";
import { ContentSlideContext } from "@app/components/questkit/ContentSlider";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import { ENV } from "@app/config/env";

const changeUrl = (item: ItemRenderData, url: string): ItemRenderData => ({
  ...item,
  data: {
    ...item.data,
    videoUrl: url,
  },
  version: item.version + 1,
});

const changePlayedAtLeastOnce = (
  item: ItemRenderData,
  playedAtLeastOnce: boolean
): ItemRenderData => ({
  ...item,
  data: {
    ...item.data,
    playedAtLeastOnce,
  },
  version: item.version + 1,
});

export const VideoItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, editMode, readOnly, onItemValidate } = props;
  const url = item.data.videoUrl;
  let videoId = null;
  if (url) {
    const videoIdMatch = url.match(
      /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    );
    if (videoIdMatch && videoIdMatch[1]) {
      videoId = videoIdMatch[1];
    }
  }

  const playedAtLeastOnce = item.data.playedAtLeastOnce;

  const focusRefCallback = useFocusableRef(item.prototype.id);

  const onChangeUrl = useCallback(
    (url: string) => {
      onItemChange?.(changeUrl(item, url));
    },
    [onItemChange, item]
  );

  const onChangePlayedAtLeastOnce = useCallback(
    (playedAtLeastOnce: boolean) => {
      if (!editMode) {
        onItemChange?.(changePlayedAtLeastOnce(item, playedAtLeastOnce));
      }
    },
    [onItemChange, editMode, item]
  );

  const youtubeRef = React.useRef<YoutubeIframeRef>(null);

  const isFocusedScreen = useIsFocused();
  const contentSlideContext = useContext(ContentSlideContext);

  const shouldPauseVideo =
    !isFocusedScreen || (contentSlideContext && !contentSlideContext.isFocused);
  useEffect(() => {
    if (shouldPauseVideo) {
      if (youtubeRef.current?.pauseVideo) {
        youtubeRef.current?.pauseVideo();
      }
    }
  }, [shouldPauseVideo]);

  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={
        <CheckboxToggle
          checked={item.data.playedAtLeastOnce}
          action={() => {
            youtubeRef.current?.playVideo();
          }}
          accessibilityLabelledBy={`item-name-label-${item.prototype.id}`}
          readOnly={readOnly}
        />
      }
      blockNode={
        <>
          {editMode ? (
            <StyledTextInput
              ref={focusRefCallback}
              value={url}
              onChangeText={onChangeUrl}
              leftIcon="link"
              placeholder={"https://www.youtube.com/watch?v=xxxxxxx"}
              editable={true}
            />
          ) : null}
          {videoId ? (
            <VideoContainer>
              <YoutubePlayer
                ref={youtubeRef}
                key={videoId}
                height={300}
                initialPlayerParams={
                  [
                    "modestbranding",
                  ] as YoutubeIframeProps["initialPlayerParams"]
                }
                onChangeState={(event) => {
                  if (event === PLAYER_STATES.PLAYING) {
                    if (!playedAtLeastOnce) {
                      onChangePlayedAtLeastOnce(true);
                      onItemValidate?.();
                    }
                  }
                }}
                videoId={videoId}
                baseUrlOverride={`${ENV.appBaseUrl}/static/react-native-youtube-iframe.html`}
              />
            </VideoContainer>
          ) : null}
        </>
      }
    />
  );
};

const StyledTextInput = styled(TextInput)`
  margin-bottom: 10px;
`;

const VideoContainer = styled.View`
  aspect-ratio: 1.7777;
  overflow: hidden;
`;
