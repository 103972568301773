import {
  QuestscriptExecutionListItem,
  RestrictedQuestscriptExecutionListItem,
} from "@questmate/openapi-spec";
import { store } from "@app/store";
import {
  questscriptExecutionLoaded,
  questscriptExecutionsLoaded,
} from "@app/store/cache/questscriptExecutions";
import { apiRequest } from "@app/util/client";
import { uuid } from "@app/util/uuid";
import { createRequest, noopRequest } from "@app/util/client/requests/index";

export const fetchQuestscriptExecutions = (
  triggerType: string,
  triggerId: string,
  publicQuestSessionToken?: string
) => {
  if (!triggerType || !triggerId) {
    return noopRequest([]);
  }
  const params = new URLSearchParams({});
  params.append("triggerType", triggerType);
  params.append("triggerId", triggerId);

  return createRequest<
    (QuestscriptExecutionListItem | RestrictedQuestscriptExecutionListItem)[]
  >(
    "get",
    `/questscript/executions?${params.toString()}`,
    undefined,
    publicQuestSessionToken
  )((response) => {
    store.dispatch(questscriptExecutionsLoaded(response));
  });
};

export const fetchQuestscriptExecution = (
  executionId: string,
  publicQuestSessionToken?: string
): Promise<
  QuestscriptExecutionListItem | RestrictedQuestscriptExecutionListItem
> => {
  return apiRequest<
    QuestscriptExecutionListItem | RestrictedQuestscriptExecutionListItem
  >(
    "GET",
    `/questscript/executions/${executionId}`,
    undefined,
    publicQuestSessionToken
  ).then((response) => {
    store.dispatch(questscriptExecutionLoaded(response));
    return response;
  });
};

export const queueItemRunEvent = async (
  questInstanceId: string,
  compoundItemPrototypeId: string
): Promise<{ executionId: string }> => {
  return apiRequest(
    "POST",
    `/questInstances/${questInstanceId}/item/${encodeURIComponent(
      questInstanceId + "|" + compoundItemPrototypeId
    )}/custom`,
    {
      events: [
        {
          id: uuid(),
          type: "ITEM_RUN_FIRED",
        },
      ],
    }
  );
};
