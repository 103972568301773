import {
  AssignmentListItem,
  AssignmentOnQuest,
  QuestInstanceDetail,
  QuestInstanceListItem,
  QuestInstanceStatus,
  QuestPrototypeDetail,
  RestartSubmissionBehavior,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  assignmentAdded,
  assignmentsLoaded,
} from "@app/store/cache/assignments";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { isTruthy, UnionToIntersection } from "@questmate/common";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import isEqual from "react-fast-compare";
import { sentry } from "@app/util/sentry";
import {
  selectItemInstanceByComboId,
  selectItemInstanceWithPrototype,
} from "@app/store/cache/itemInstances";
import { selectRewardInstanceByComboId } from "@app/store/cache/rewardInstances";

type QuestInstance = Omit<
  UnionToIntersection<APIQuestInstance>,
  | "itemInstances"
  | "rewardInstances"
  | "prototype"
  | "submittedByUser"
  | "startedByUser"
  | "quest"
  | "startConfiguration"
  | "assignments"
> & {
  questId?: string;
  prototypeId?: string;
  submittedByUserId?: string | null;
  startedByUserId?: string;
  startConfigurationId?: string;
  assignmentIds?: string[];
  /**
   * Note these are just the prototype IDs, still need to concat with Quest instance id to get full id
   */
  itemInstanceIds?: string[];
  /**
   * Note these are just the prototype IDs, still need to concat with Quest instance id to get full id
   */
  rewardInstanceIds?: string[];
};

const questInstanceAdapter = createEntityAdapter<QuestInstance>({
  // sortComparer: (a, b) => {
  //   // Keep the list of IDs sorted by `startedAt` DESC to avoid having to sort the list on each re-render of the Runs screen
  //   if (a === b) {
  //     return 0;
  //   } else if (!a.startedAt) {
  //     return 1;
  //   } else if (!b.startedAt) {
  //     return -1;
  //   }
  //
  //   return b > a ? -1 : 1;
  //   // return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
  // },
});

export const {
  selectById: selectQuestInstanceById,
  selectAll: selectAllQuestInstances,
  selectIds: selectAllQuestInstanceIds,
  selectEntities: selectAllQuestInstancesById,
} = questInstanceAdapter.getSelectors<AppState>(
  (state) => state.cache.questInstances
);

// This is not a particularly meaningful type, it's just that we do not have a good way to denormalize data from the
// cache so this type contains what is convenient to retrieve while ensuring we are loading all the fields that will
// possibly be used while filling out a Quest run.
export type RunViewQuestData = Exclude<
  ReturnType<typeof selectRunViewQuestDataById>,
  undefined
>;

export const selectRunViewQuestDataById = createSelector(
  [
    (state) => state,
    (state, questInstanceId) => selectQuestInstanceById(state, questInstanceId),
  ],
  (state, questInstance) => {
    if (
      !questInstance ||
      !questInstance.itemInstanceIds ||
      !questInstance.rewardInstanceIds
    ) {
      const error = new Error(
        "Unexpected Error: Unable to select Editable Quest Instance Details, missing data in cache."
      );
      sentry.captureException(error, { extra: { questInstance } });
      throw error;
    }

    return {
      ...questInstance,
      itemInstancesById: questInstance.itemInstanceIds.reduce(
        (acc, prototypeId) => {
          const itemInstanceId = `${questInstance.id}|${prototypeId}`;
          const itemInstance = selectItemInstanceWithPrototype(
            state,
            questInstance.id,
            prototypeId
          );
          if (!itemInstance) {
            const error = new Error(
              "Unexpected Error: Unable to select Editable Quest Instance Details, missing ItemInstance in cache."
            );
            sentry.captureException(error, {
              extra: { questInstance, itemInstanceId, itemInstance },
            });
            throw error;
          }
          acc[prototypeId] = itemInstance;
          return acc;
        },
        {} as Record<
          string,
          Exclude<ReturnType<typeof selectItemInstanceByComboId>, undefined>
        >
      ),
      rewardInstancesById: questInstance.rewardInstanceIds.reduce(
        (acc, prototypeId) => {
          const rewardInstanceId = `${questInstance.id}|${prototypeId}`;

          const rewardInstance = selectRewardInstanceByComboId(
            state,
            rewardInstanceId
          );
          if (!rewardInstance) {
            const error = new Error(
              "Unexpected Error: Unable to select Editable Quest Instance Details, missing RewardInstance in cache."
            );
            sentry.captureException(error, {
              extra: {
                questInstance,
                rewardInstanceId,
                rewardInstance,
              },
            });
            throw error;
          }
          acc[prototypeId] = rewardInstance;
          return acc;
        },
        {} as Record<
          string,
          Exclude<ReturnType<typeof selectRewardInstanceByComboId>, undefined>
        >
      ),
    };
  }
);

export const selectQuestInstanceRoles = createSelector(
  [selectQuestInstanceById],
  (questInstance) => questInstance?.requestingUser?.instanceRoles ?? []
);
export const selectQuestInstancesOnQuestPrototype = createSelector(
  [selectAllQuestInstancesById, (_state, questPrototypeId) => questPrototypeId],
  (questInstances, questPrototypeId) =>
    Object.fromEntries(
      Object.entries(questInstances ?? {}).filter(
        ([_id, questInstance]) =>
          questInstance?.prototypeId === questPrototypeId
      )
    )
);

export const selectAllQuestInstancesOnQuest = createSelector(
  [selectAllQuestInstances, (_state, questId) => questId],
  (questInstances, questId) =>
    questInstances.filter((questInstance) => questInstance?.questId === questId)
);
export const selectAllQuestInstanceIdsOnQuest = createSelector(
  [
    selectAllQuestInstanceIds,
    selectAllQuestInstancesById,
    (_state, questId) => questId,
  ],
  (sortedIds, questInstancesById, questId) =>
    sortedIds.filter(
      (id) => questInstancesById[id]?.questId === questId
    ) as string[],
  {
    memoizeOptions: { resultEqualityCheck: isEqual },
  }
);

const slice = createSlice({
  name: "cache/questInstances",
  initialState: questInstanceAdapter.getInitialState(),
  reducers: {
    questInstanceLoaded: (
      state,
      action: PayloadAction<QuestInstanceDetail>
    ) => {
      questInstanceAdapter.upsertOne(state, mapQuestInstance(action.payload));
    },
    questInstanceListLoaded: (
      state,
      action: PayloadAction<QuestInstanceListItem[]>
    ) => {
      questInstanceAdapter.upsertMany(
        state,
        action.payload.map((run) => mapQuestInstance(run))
      );
    },
    setNextInstanceId: (
      state,
      action: PayloadAction<{
        initialInstanceId: string;
        nextInstanceId: string;
      }>
    ) => {
      questInstanceAdapter.updateOne(state, {
        id: action.payload.initialInstanceId,
        changes: {
          submissionBehavior: {
            ...(state.entities[action.payload.initialInstanceId]
              ?.submissionBehavior as RestartSubmissionBehavior),
            nextInstanceId: action.payload.nextInstanceId,
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogout, (state) => questInstanceAdapter.removeAll(state))

      .addCase(assignmentAdded, (state, action) => {
        const instance = action.payload.formInstance;
        if (!instance?.id) {
          return;
        }

        return questInstanceAdapter.upsertOne(
          state,
          mapQuestInstance({
            // new assignments that already have a QuestInstance will be in the "OPEN" status,
            // adding this information helps us keep track of which assignments the user has open
            status: "OPEN",
            prototype: {
              id: action.payload.formPrototype!
                .id /* FP should always be present when assignment on instance. */,
            },
            ...instance,
          })
        );
      })
      .addCase(assignmentsLoaded, (state, action) =>
        questInstanceAdapter.upsertMany(
          state,
          action.payload.assignments
            .filter((assignment) => Boolean(assignment?.formInstance?.id))
            .map((assignment) => ({
              prototype: { id: assignment.formPrototype?.id },
              assignments: [assignment],
              ...assignment.formInstance!,
            }))
            .map(mapQuestInstance)
        )
      )
      .addCase(questPrototypeLoaded, (state, action) => {
        questInstanceAdapter.upsertMany(
          state,
          action.payload.assignments
            .filter((assignment) => Boolean(assignment?.formInstance?.id))
            .map((assignment) => ({
              prototype: { id: action.payload.id },
              assignments: [assignment],
              ...assignment.formInstance!,
            }))
            .map(mapQuestInstance)
        );
        if (action.payload?.sharedInstance?.id) {
          questInstanceAdapter.upsertOne(
            state,
            mapQuestInstance({
              prototype: { id: action.payload.id },
              ...action.payload?.sharedInstance,
            })
          );
        }
      });
  },
});

const reducer = slice.reducer;
export default reducer;

export const {
  questInstanceLoaded,
  questInstanceListLoaded,
  setNextInstanceId,
} = slice.actions;

type APIQuestInstance =
  | (QuestPrototypeDetail["sharedInstance"] & { prototype?: { id: string } })
  | (AssignmentListItem["formInstance"] & { prototype?: { id: string } })
  | (AssignmentOnQuest["formInstance"] & {
      status: QuestInstanceStatus;
      prototype?: { id: string };
    })
  | QuestInstanceListItem
  | QuestInstanceDetail;

const mapQuestInstance = createDataMapper<APIQuestInstance, QuestInstance>()(
  [
    "id",
    "status",
    "archived",
    "name",
    "introText",
    "initialized",
    "requestingUser",
    "completedAt",
    "startedAt",
    "dueAt",
    "remindAt",
    "alertAt",
    "slideMode",
    "submissionBehavior",
    "allowOverdueSubmissions",
    "parentFormInstanceId",
    "parentItemPrototypeId",
  ],
  {
    prototype: (prototype) => ({
      prototypeId: prototype?.id,
    }),
    submittedByUser: (submittedByUser) => ({
      submittedByUserId: submittedByUser?.id ?? null,
    }),
    startedByUser: (startedByUser) => ({
      startedByUserId: startedByUser?.id,
    }),
    quest: (quest) => ({
      questId: quest?.id,
    }),
    startConfiguration: (startConfiguration) => ({
      startConfigurationId: startConfiguration?.id,
    }),
    assignments: (assignments) => ({
      assignmentIds: assignments.map(({ id }) => id!),
    }),
    itemInstances: (itemInstances) => ({
      itemInstanceIds: itemInstances
        .map(({ prototype }) => prototype?.id)
        .filter(isTruthy),
    }),
    rewardInstances: (rewardInstances) => ({
      rewardInstanceIds: rewardInstances
        .map(({ prototype }) => prototype?.id)
        .filter(isTruthy),
    }),
  }
);
