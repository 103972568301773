import { Quests } from "@questmate/openapi-spec";
import { apiRequest } from "@app/util/client";
import { store } from "@app/store";
import { questStartTriggerLoaded } from "@app/store/cache/questStartTriggers";

export const updateQuestStartTrigger = async (
  questPrototypeId: string,
  questStartTriggerId: string,
  options: Quests.TriggersPartialUpdate.RequestBody
): Promise<Quests.TriggersPartialUpdate.ResponseBody> => {
  return apiRequest<Quests.TriggersPartialUpdate.ResponseBody>(
    "patch",
    `/quests/${questPrototypeId}/triggers/${questStartTriggerId}`,
    options
  ).then((response) => {
    store.dispatch(
      questStartTriggerLoaded({ startTrigger: response, questPrototypeId })
    );
    return response;
  });
};
