import { apiRequest, QMApiError } from "@app/util/client";
import { useCallback, useMemo, useRef } from "react";
import {
  SuccessFieldResponse,
  UpdateQuestPrototypeFieldResponse,
} from "@app/quest/edit/saveQuestPrototype";
import {
  CompletionActionRenderData,
  ItemTypesThatCanBeCompletionActions,
} from "@app/types/completionActionRenderData";
import { EditLevel } from "@app/components/screen/quest/common/questView";
import { useFocusController } from "@app/util/focus";
import { uuid } from "@app/util/uuid";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import {
  adjustPositions,
  ensureNoGapsInPositions,
  PartialItemData,
} from "@app/quest/edit/items";
import {
  ItemDetail,
  ItemInfo,
  JsonObject,
  QuestPrototypeDetail,
  RewardDetail,
  RewardType,
  UpdateRewardsRequest,
} from "@questmate/openapi-spec";
import {
  EditableQuestPrototypeDetails,
  questPrototypeLoaded,
} from "@app/store/cache/questPrototypes";
import { store } from "@app/store";
import { sentry } from "@app/util/sentry";
import { CachedItemPrototype } from "@app/store/cache/itemPrototypes";
import { CachedRewardPrototype } from "@app/store/cache/rewardPrototypes";
import _ from "lodash";
import isEqual from "react-fast-compare";

const mapCachedRewardToServerReward = (
  reward: EditableQuestPrototypeDetails["rewardsById"][string]
): UpdateRewardsRequest["rewards"][number] => {
  return {
    id: reward.id,
    type: reward.type,
    position: reward.position,
    config: reward.config,
  };
};

export const updateRewards = async (
  questPrototypeId: string,
  { rewardsById }: Pick<EditableQuestPrototypeDetails, "rewardsById">
): Promise<UpdateQuestPrototypeFieldResponse> => {
  return apiRequest<QuestPrototypeDetail>(
    "patch",
    `/quests/${questPrototypeId}/rewards`,
    {
      rewards: Object.values(rewardsById).map(mapCachedRewardToServerReward),
    }
  )
    .then((updatedQuestPrototype) => {
      store.dispatch(questPrototypeLoaded(updatedQuestPrototype));
      return {
        success: true,
        updatedQuestPrototype,
      } as SuccessFieldResponse;
    })
    .catch((error) => {
      console.warn(`Failed to save rewards:`, error);
      if (!(error instanceof QMApiError) || error.status >= 500) {
        sentry.captureException(error);
      }
      // TODO: Ensure `e.errors.path` uses IDs not indices. express-openapi-validator uses indices,
      //       but it is also best we do not expose those messages directly in the UI...
      return {
        success: false,
        error,
      };
    });
};

type PartialRewardData = Partial<Omit<RewardDetail, "id">>;

type PartialSupportedItemData = Omit<PartialItemData, "type"> & {
  type?: ItemTypesThatCanBeCompletionActions;
};
export type PartialCompletionActionData =
  | PartialRewardData
  | PartialSupportedItemData;

interface UseCompletionActionsResult {
  completionActions: CompletionActionRenderData[];
  onCompletionActionAdded: (
    position: number,
    data?: PartialCompletionActionData
  ) => void;
  onCompletionActionChange: (
    completionAction: CompletionActionRenderData
  ) => void;
  onCompletionActionDelete: (prototypeId: string) => void;
  onCompletionActionReorder: (oldPosition: number, newPosition: number) => void;
  onCompletionActionTouched: (
    completionAction: CompletionActionRenderData
  ) => void;
  onCompletionActionValidationContextChange: (
    completionAction: CompletionActionRenderData,
    validationContext: Record<string, unknown>
  ) => void;
  editLevel: EditLevel;
}

export const useCompletionActions = (): UseCompletionActionsResult => {
  const {
    questPrototypeId,
    addChange,
    useQuestPrototypeWithChanges,
    useScopedValidationErrors,
    touchedMap,
    setFieldTouched,
  } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const questPrototype = useQuestPrototypeWithChanges();
  const focusController = useFocusController();

  const filterCompletionActions = (
    questPrototype: EditableQuestPrototypeDetails
  ) => {
    const completionActionItems = Object.values(
      questPrototype.itemsById
    ).filter(({ isCompletionAction }) => isCompletionAction);
    return [
      ...Object.values(questPrototype.rewardsById),
      ...completionActionItems,
    ];
  };

  const createEmptyReward = useCallback(
    (position: number, rewardData: PartialRewardData) => {
      const rewardPrototypeId = [questPrototypeId, uuid()].join(":");
      return _.defaults(rewardData, {
        id: rewardPrototypeId,
        position,
        type: "TEXT" as RewardType,
        config: {},
      });
    },
    [questPrototypeId]
  );

  const createEmptyItem = useCallback(
    // TODO: dedupe from items.ts
    (position: number, itemData: PartialItemData) => {
      const itemPrototypeId = [questPrototypeId, uuid()].join(":");
      return _.defaults(itemData, {
        id: itemPrototypeId,
        position,
        name: "",
        infos: [
          {
            id: `${itemPrototypeId}:${uuid()}`,
            icon: "info",
            text: "",
            link: "",
          },
        ] as ItemInfo[],
        required: true,
        personalData: false,
        type: "Checkbox" as const,
        icon: "item",
        defaults: {},
        isCompletionAction: true,
        customScript: null,
        referenceSlug: "",
      });
    },
    [questPrototypeId]
  );

  // CompletionAction Event Handlers
  const onCompletionActionChange = useCallback(
    (completionAction: CompletionActionRenderData) => {
      addChange((draft) => {
        const rewardCompletionAction =
          draft.rewardsById[completionAction.prototype.id];
        const itemCompletionAction =
          draft.itemsById[completionAction.prototype.id];

        if (isItemRenderData(completionAction)) {
          const updatedFields = {
            name: completionAction.prototype.name || "",
            type: completionAction.prototype.type,
            defaults: completionAction.data,
            customScript: completionAction.prototype.customScript || null,
          };

          if (rewardCompletionAction) {
            // was a reward, is now a completion action item
            const itemPrototypeId = [questPrototypeId, uuid()].join(":");
            const newItem = {
              id: itemPrototypeId,
              position: completionAction.prototype.position,
              infos: [],
              required: false,
              isCompletionAction: true,
              referenceSlug: "",
              ...updatedFields,
            };
            draft.itemsById[newItem.id] = newItem;
            delete draft.rewardsById[rewardCompletionAction.id];
          } else if (itemCompletionAction) {
            draft.itemsById[itemCompletionAction.id] = {
              ...itemCompletionAction,
              ...updatedFields,
            };
          } else {
            throw new Error(
              "Cannot find Completion Action in Rewards or Items!"
            );
          }
        } else {
          const updatedFields = {
            type: completionAction.prototype.type as RewardType,
            config: completionAction.data,
          };
          if (itemCompletionAction) {
            // was an item, is now a completion action reward
            const newReward = {
              id: [questPrototypeId, uuid()].join(":"),
              position: completionAction.prototype.position,
              ...updatedFields,
              config: {},
            };
            draft.rewardsById[newReward.id] = newReward;
            delete draft.itemsById[itemCompletionAction.id];
          } else if (rewardCompletionAction) {
            if (
              rewardCompletionAction.type !== completionAction.prototype.type
            ) {
              // reset touched status when user changes the reward type so error messages will not show until they make a change
              setFieldTouched(
                `rewards[${completionAction.prototype.id}]`,
                false
              );
            }
            draft.rewardsById[rewardCompletionAction.id] = {
              ...rewardCompletionAction,
              ...updatedFields,
            };
          } else {
            throw new Error(
              "Cannot find Completion Action in rewards or Items!"
            );
          }
        }
      });
    },
    [addChange, questPrototypeId, setFieldTouched]
  );

  const onCompletionActionAdded = useCallback(
    (newPosition: number, data: PartialCompletionActionData = {}) => {
      Analytics.trackEvent("Add Completion Action", { type: data.type });

      if (isPartialItemData(data)) {
        // add item
        const newItem = createEmptyItem(newPosition, data);
        addChange((draft) => {
          const completionActions = filterCompletionActions(draft);
          ensureNoGapsInPositions(completionActions);
          for (const completionAction of completionActions) {
            if (completionAction.position >= newPosition) {
              completionAction.position += 1;
            }
          }
          draft.itemsById[newItem.id] = newItem;
          draft.itemIds = Object.values(draft.itemsById)
            .sort(({ position: a }, { position: b }) => b - a)
            .map(({ id }) => id);
        });
        focusController.focus(newItem.id);
      } else {
        // add reward

        const newReward = createEmptyReward(newPosition, data);
        addChange((draft) => {
          const completionActions = filterCompletionActions(draft);
          ensureNoGapsInPositions(completionActions);
          for (const completionAction of completionActions) {
            if (completionAction.position >= newPosition) {
              completionAction.position += 1;
            }
          }
          draft.rewardsById[newReward.id] = newReward;
          draft.rewardIds = Object.values(draft.rewardsById)
            .sort(({ position: a }, { position: b }) => b - a)
            .map(({ id }) => id);
        });
        focusController.focus(newReward.id);
      }
    },
    [addChange, createEmptyItem, createEmptyReward, focusController]
  );

  const onCompletionActionDelete = useCallback(
    (prototypeId: string) => {
      Analytics.trackEvent("Delete Completion Action");

      addChange((draft) => {
        delete draft.rewardsById[prototypeId];
        delete draft.itemsById[prototypeId];

        const completionActions = filterCompletionActions(draft);
        ensureNoGapsInPositions(completionActions);

        draft.rewardIds = Object.values(draft.rewardsById)
          .sort(({ position: a }, { position: b }) => b - a)
          .map(({ id }) => id);
        draft.itemIds = Object.values(draft.itemsById)
          .sort(({ position: a }, { position: b }) => b - a)
          .map(({ id }) => id);
      });
    },
    [addChange]
  );

  const onCompletionActionReorder = useCallback(
    (oldPosition: number, newPosition: number) => {
      if (oldPosition === newPosition) {
        return;
      }
      Analytics.trackEvent("Reorder Completion Action");
      addChange((draft) => {
        const completionActions = filterCompletionActions(draft);
        ensureNoGapsInPositions(completionActions);
        const currentPositions = completionActions.map(
          ({ position }) => position
        );
        const newPositions = adjustPositions(
          currentPositions,
          oldPosition,
          newPosition
        );

        completionActions.forEach((action, index) => {
          action.position = newPositions[index];
        });
      });
    },
    [addChange]
  );

  const onCompletionActionValidationContextChange = useCallback(() => {
    // TODO: REMOVE
  }, []);

  const onCompletionActionTouched = useCallback(
    (completionAction: CompletionActionRenderData) => {
      // TODO: Migrate this to a system that supports item validation contexts as well.
      setFieldTouched(`rewards[${completionAction.prototype.id}]`, true);
    },
    [setFieldTouched]
  );

  const rewardValidationErrors = useScopedValidationErrors(["rewards"]);

  const previousCompletionActionViewModelsRef = useRef<
    CompletionActionRenderData[]
  >([]);
  const completionActionViewModels = useMemo(
    (): CompletionActionRenderData[] => {
      const updatedViewModels = (
        questPrototype ? filterCompletionActions(questPrototype) : []
      )
        .map((rewardOrItem) => {
          const errorsForThisReward = rewardValidationErrors
            .filter((error) => error.path[0] === rewardOrItem.id)
            .map((error) => error.message);
          const commonData = {
            version: 0, // version is not yet used in edit mode
            errors: errorsForThisReward,
            isTouched: touchedMap[`rewards[${rewardOrItem.id}]`],
          };
          if (isItemDetail(rewardOrItem)) {
            return {
              ...commonData,
              prototype: {
                id: rewardOrItem.id,
                name: rewardOrItem.name,
                type: rewardOrItem.type as ItemTypesThatCanBeCompletionActions,
                config: rewardOrItem.defaults,
                position: rewardOrItem.position,
                customScript: rewardOrItem.customScript,
              },
              data: rewardOrItem.defaults,
            };
          } else {
            return {
              ...commonData,
              prototype: {
                id: rewardOrItem.id,
                type: rewardOrItem.type,
                config: rewardOrItem.config as JsonObject,
                position: rewardOrItem.position,
              },
              data: rewardOrItem.config,
            };
          }
        })
        .sort((a, b) => {
          if (a.prototype.position === b.prototype.position) {
            return a.prototype.id.localeCompare(b.prototype.id);
          }
          return a.prototype.position - b.prototype.position;
        });

      if (
        isEqual(
          previousCompletionActionViewModelsRef.current,
          updatedViewModels
        )
      ) {
        return previousCompletionActionViewModelsRef.current;
      } else {
        previousCompletionActionViewModelsRef.current = updatedViewModels;
        return updatedViewModels;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      questPrototype?.itemsById,
      questPrototype?.rewardsById,
      rewardValidationErrors,
      touchedMap,
    ]
  );

  return {
    completionActions: completionActionViewModels,
    onCompletionActionAdded,
    onCompletionActionDelete,
    onCompletionActionReorder,
    onCompletionActionChange,
    onCompletionActionTouched,
    onCompletionActionValidationContextChange,
    editLevel: EditLevel.Editable,
  };
};

const isItemDetail = (
  item: CachedItemPrototype<ItemDetail> | CachedRewardPrototype<RewardDetail>
): item is CachedItemPrototype<ItemDetail> =>
  Boolean((item as CachedItemPrototype<ItemDetail>).isCompletionAction);

const itemTypesThatCanBeCompletionActions: ItemTypesThatCanBeCompletionActions[] =
  ["CustomV2"];
export const isItemRenderData = (
  item: CompletionActionRenderData
): item is CompletionActionRenderData<ItemTypesThatCanBeCompletionActions> =>
  itemTypesThatCanBeCompletionActions.includes(
    item.prototype.type as ItemTypesThatCanBeCompletionActions
  );

const isPartialItemData = (
  data: PartialCompletionActionData
): data is PartialSupportedItemData =>
  Boolean(
    data?.type &&
      itemTypesThatCanBeCompletionActions.includes(
        data.type as ItemTypesThatCanBeCompletionActions
      )
  );
