import * as React from "react";
import styled from "styled-components/native";
import { RevealText } from "@app/components/questkit/revealText";
import { Content, ItemCard, ItemInfoWrapper } from "@app/components/item";
import {
  Header,
  ItemTypeDropdown,
  ItemTypeText,
  Separator,
} from "@app/components/item/itemRowHeader";
import ItemInfo from "@app/components/item/itemInfo";

type IntroTextProps = {
  onIntroTextChange?: (text: string) => void;
  text: string | undefined;
  editable: boolean;
};
const infos = [
  {
    id: "intro-text-static-info",
    text: "Start your Quest with a proper greeting!",
    icon: "info",
    link: "",
  },
  {
    id: "intro-text-static-info-2",
    text: "Optional.",
    icon: "info",
    link: "",
  },
];
export const IntroText: React.FC<IntroTextProps> = ({
  onIntroTextChange,
  text,
  editable,
}) => {
  // const questViewContext = useQuestViewContext();
  return editable ? (
    <CardContainer>
      <ItemCard
        editMode={true}
        isDragged={false}
        itemHasError={false}
        disabled={true}
      >
        <Header>
          <ItemTypeDropdown
            disabled={true}
            // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
            tabIndex={-1}
          >
            <ItemTypeText size="small">Welcome Text</ItemTypeText>
          </ItemTypeDropdown>
        </Header>
        <Separator />
        <RevealText
          onRevealTextChange={onIntroTextChange}
          text={text || ""}
          placeholder="Welcome Text"
          editable={true}
          style={{
            paddingTop: 20,
          }}
        />
        <Content editMode={true}>
          <ItemInfoWrapper editMode={true}>
            <ItemInfo
              editMode={false}
              itemPrototypeId={"intro-text"}
              infos={infos}
            />
          </ItemInfoWrapper>
        </Content>
      </ItemCard>
    </CardContainer>
  ) : (
    <RevealText
      // onRevealTextChange={onIntroTextChange}
      text={text || ""}
      placeholder="Welcome Text"
      editable={false}
      style={{
        paddingVertical: 80,
      }}
    />
  );
};

const CardContainer = styled.View`
  margin-vertical: 40px;
`;
