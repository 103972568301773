import { Platform, StyleProp, StyleSheet, ViewStyle } from "react-native";
import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import Icon, { IconIdentifier } from "@app/components/icon";
import BasePressable from "@app/components/questkit/BasePressable";

type TriggerCardProps = {
  title: string;
  icon: IconIdentifier;
  style?: StyleProp<ViewStyle>;
  startChangingQuestType: () => void;
};
export const StartTriggerCard: React.FC<
  PropsWithChildren<TriggerCardProps>
> = ({ title, icon, style, children, startChangingQuestType }) => {
  return (
    <Card style={[style]}>
      <HeaderLine>
        <QuestTypeDropdown
          onPress={startChangingQuestType}
          testID="quest-start-trigger-dropdown"
        >
          <StyledIcon icon={icon} />
          <QuestTypeText size="smallMedium">{title}</QuestTypeText>
          <StyledIcon icon="chevron-down" />
        </QuestTypeDropdown>
      </HeaderLine>
      <HorizontalDivider />
      <Content>{children}</Content>
    </Card>
  );
};

const Card = styled.View`
  align-self: center;
  width: 100%;
  max-width: 540px;
  border-radius: 16px;
  padding: 24px;
  margin: 24px 24px 16px;
  background-color: ${({ theme }) => theme.card.background};
`;

const HorizontalDivider = styled.View`
  height: ${StyleSheet.hairlineWidth}px;
  background-color: ${({ theme }) => theme.textInput.normal.border};
  margin-vertical: 16px;
`;

const QuestTypeText = styled(Text)`
  color: ${({ theme }) => theme.button.highlight.text};
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.button.highlight.text};
`;
const QuestTypeDropdown = styled(BasePressable)`
  flex-direction: row;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 48px;
  background-color: ${({ theme }) => theme.button.highlight.background};
  flex-shrink: 1;
`;

const HeaderLine = styled.View`
  flex-direction: row;
`;

const Content = styled.View`
  padding-top: 20px;
`;

type StartTriggerSummaryProps = {
  icon: IconIdentifier;
  text: string;
  secondaryIcons?: IconIdentifier[];
};
export const StartTriggerSummary: React.FC<StartTriggerSummaryProps> = ({
  icon,
  text,
  secondaryIcons = [],
}) => {
  return (
    <StartTriggerSummaryContainer>
      <Icon icon={icon} container={"COLLAPSED"} />
      <StyledText size="medium">{text}</StyledText>
      {secondaryIcons.length === 0 ? <SecondaryIconsFiller /> : null}
    </StartTriggerSummaryContainer>
  );
};

const StyledText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const SecondaryIconsFiller = styled.View`
  width: 8px;
`;
const StartTriggerSummaryContainer = styled.View`
  background-color: ${({ theme }) => theme.tile.standard.background.normal};
  padding: 8px;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  border-radius: 40px;
  align-items: center;
  align-self: center;
  margin-top: 20px;
`;
