import React, { useCallback, useRef } from "react";
import type { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import type { CompletionActionComponentProps as CompletionActionBaseProps } from "@app/components/completionAction";
import { SlackChannelPicker } from "@app/components/completionAction/components/slackChannelPicker";
import RewardContainer from "@app/components/completionAction/components/RewardContainer";

const changeChannel = (
  item: CompletionActionRenderData,
  channelId: string
): CompletionActionRenderData => ({
  ...item,
  data: {
    channelId,
  },
  version: item.version + 1,
});

export const PostSlackMessageReward: React.FC<CompletionActionBaseProps> = ({
  item,
  onCompletionActionChange,
  onCompletionActionValidationContextChange,
  onCompletionActionTouched: _onCompletionActionTouched,
  editMode,
}) => {
  const itemRef = useRef<CompletionActionRenderData>(item);
  itemRef.current = item;

  if (!editMode) {
    return <></>;
  }

  const onChangeChannelId = useCallback(
    (channelId: string) =>
      onCompletionActionChange(changeChannel(itemRef.current, channelId)),
    [onCompletionActionChange]
  );

  const onChangeAppLinkStatus = useCallback(
    (appLinked: boolean) => {
      onCompletionActionValidationContextChange?.({
        appLinked,
      });
    },
    [onCompletionActionValidationContextChange]
  );
  const onCompletionActionTouched = useCallback(() => {
    _onCompletionActionTouched?.();
  }, [_onCompletionActionTouched]);

  return (
    // eslint-disable-next-line deprecation/deprecation
    <RewardContainer
      blockNode={
        <SlackChannelPicker
          channelId={item.data.channelId}
          onChangeChannelId={onChangeChannelId}
          onChangeAppLinkStatus={onChangeAppLinkStatus}
          onTouched={onCompletionActionTouched}
        />
      }
      title="Post Slack Message"
    />
  );
};
