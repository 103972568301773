import * as React from "react";
import { QKTextProps, Text } from "@app/components/questkit/text";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useClockTick } from "@app/util/Clock";

interface RelativeDateTextProps extends QKTextProps {
  date: Date;
}

export const RelativeDateText: React.FC<RelativeDateTextProps> = ({
  date,
  ...textProps
}) => {
  const relativeDateText = useRelativeDateText(date);

  return <Text {...textProps}>{relativeDateText}</Text>;
};

export function useRelativeDateText(date: Date | null | undefined): string {
  const [relativeDateText, setRelativeDateText, relativeDateTextRef] =
    useStateWithRef<string>(() => getRelativeTextForDate(date, Date.now()));

  useClockTick((now) => {
    const relativeTextForDate = getRelativeTextForDate(date, now);
    if (relativeTextForDate !== relativeDateTextRef.current) {
      setRelativeDateText(relativeTextForDate);
    }
  });
  return relativeDateText;
}

export function getRelativeTextForDate(
  date: Date | null | undefined,
  now: number
): string {
  if (!date || !date.getTime || isNaN(date.getTime())) {
    console.error("Invalid date passed to getRelativeTextForDate", date);
    return "";
  }

  const diff = now - date.getTime();
  const inFuture = diff < 0;
  const diffSeconds = Math.abs(diff) / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffWeeks = diffDays / 7;
  const diffMonths = diffDays / 30;
  const diffYears = diffDays / 365;

  let unitText: string;
  if (diffSeconds < 60) {
    const seconds = Math.floor(diffSeconds);
    unitText = `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  } else if (diffMinutes < 60) {
    const minutes = Math.floor(diffMinutes);
    unitText = `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else if (diffHours < 24) {
    const hours = Math.floor(diffHours);
    unitText = `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (diffDays < 7) {
    const days = Math.floor(diffDays);
    unitText = `${days} ${days === 1 ? "day" : "days"}`;
  } else if (diffWeeks < 4 || Math.floor(diffMonths) === 0) {
    const weeks = Math.floor(diffWeeks);
    unitText = `${weeks} ${weeks === 1 ? "week" : "weeks"}`;
  } else if (diffMonths < 12 || Math.floor(diffYears) === 0) {
    const months = Math.floor(diffMonths);
    unitText = `${months} ${months === 1 ? "month" : "months"}`;
  } else {
    const years = Math.floor(diffYears);
    unitText = `${years} ${years === 1 ? "year" : "years"}`;
  }
  return inFuture ? `in ${unitText}` : `${unitText} ago`;
}
