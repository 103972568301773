import React from "react";
import {
  DropdownOption,
  DropdownStyle,
  DropdownWithModal,
} from "@app/components/questkit/dropdownWithModal";
import { getTimeZones } from "@vvo/tzdb";

interface TimezonePickerProps {
  timezone: string;
  onChangeTimezone: (timezone: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

const timezoneOptions = getTimeZones().map((timezone) => {
  return {
    icon: "location-pin",
    name: timezone.currentTimeFormat,
    value: timezone.name,
  } as DropdownOption;
});
export const TimezonePicker: React.FC<TimezonePickerProps> = (props) => {
  return (
    <DropdownWithModal
      options={timezoneOptions}
      loadingOptions={false}
      selectedOption={props.timezone}
      setSelectedOption={props.onChangeTimezone}
      dropdownStyle={DropdownStyle.STANDARD}
      placeholderIcon="location-pin"
      optionNoun="Timezone"
      optionPluralNoun="Timezones"
      disabled={props.disabled}
      readOnly={props.readOnly}
      style={{ width: "100%" }}
    />
  );
};
