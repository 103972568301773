import React, { useCallback, useEffect, useRef } from "react";
import { Platform } from "react-native";
import type { ItemRenderData } from "@app/types/itemRenderData";

import * as Haptics from "expo-haptics";
import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import styled from "styled-components/native";
import Icon from "@app/components/icon";

const toggleItem = (item: ItemRenderData): ItemRenderData => ({
  ...item,
  data: {
    checked: !item.data?.checked,
  },
  version: item.version + 1,
});

type CheckboxToggleProps =
  | {
      checked: boolean;
      readOnly?: boolean;
      accessibilityLabelledBy?: string;
      accessibilityHint?: string;
      action: () => void;
    }
  | {
      checked: boolean;
      readOnly: true;
      accessibilityLabelledBy?: string;
      accessibilityHint?: string;
    };

export const CheckboxItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, setItemAction } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const action = useCallback(() => {
    if (Platform.OS === "ios") {
      void Haptics.selectionAsync();
    }
    onItemChange?.(toggleItem(itemRef.current));
    onItemValidate?.();
  }, [onItemValidate, onItemChange]);
  useEffect(() => setItemAction?.(action), [action, setItemAction]);

  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={
        <CheckboxToggle
          action={action}
          readOnly={props.readOnly}
          checked={props.item.data.checked}
          accessibilityLabelledBy={`item-name-label-${props.item.prototype.id}`}
        />
      }
    />
  );
};

export const CheckboxToggle: React.FC<CheckboxToggleProps> = (props) => {
  const { checked, readOnly, accessibilityLabelledBy, accessibilityHint } =
    props;
  const onPress = useCallback(() => {
    if (!readOnly) {
      props.action?.();
    }
  }, [props, readOnly]);

  return (
    <CheckboxToggleContainer
      checked={checked}
      disabled={readOnly}
      onPress={onPress}
      testID="checkbox-toggle"
      accessibilityRole={"checkbox"}
      accessibilityState={{ checked }}
      accessibilityLabelledBy={accessibilityLabelledBy}
      accessibilityHint={accessibilityHint}
    >
      <Icon icon="checkmark" inverted disabled={!checked} />
    </CheckboxToggleContainer>
  );
};

const CheckboxToggleContainer = styled.Pressable<
  Pick<CheckboxToggleProps, "checked">
>`
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  border-radius: 16px;
  border-color: ${({ theme, checked }) =>
    checked ? theme.interaction.primary : theme.interaction.neutralStrong};
  border-width: 1px;
  ${({ checked, theme }) =>
    checked ? `background-color: ${theme.interaction.primary};` : ""};
`;
