import { createRequest, noopRequest } from "@app/util/client/requests/index";
import { Quests } from "@questmate/openapi-spec";
import { store } from "@app/store";
import {
  scheduledQuestStartDeleted,
  scheduledQuestStartListLoaded,
} from "@app/store/cache/scheduledQuestStarts";
import { apiRequest } from "@app/util/client";
import { questInstanceListLoaded } from "@app/store/cache/questInstances";

export const fetchScheduledQuestStarts = (questId: string | undefined) => {
  if (!questId) {
    return noopRequest();
  }
  return createRequest<Quests.ScheduledStartsDetail.ResponseBody>(
    "get",
    `/quests/${questId}/scheduled-starts`
  )((response) => {
    store.dispatch(
      scheduledQuestStartListLoaded({ questId, scheduledQuestStarts: response })
    );
  });
};

export const deleteScheduledQuestStart = (
  questId: string,
  scheduledQuestStartId: string
) => {
  return apiRequest<Quests.DeleteScheduledQuestStart.ResponseBody>(
    "delete",
    `/quests/${questId}/scheduled-starts/${scheduledQuestStartId}`
  ).then((response) => {
    store.dispatch(
      scheduledQuestStartDeleted({
        questId,
        deletedScheduledQuestStartId: response.id,
      })
    );
    return response;
  });
};

export const startScheduledQuestStart = (
  questId: string,
  scheduledQuestStartId: string
) => {
  return apiRequest<Quests.StartScheduledQuest.ResponseBody>(
    "post",
    `/quests/${questId}/scheduled-starts/${scheduledQuestStartId}/start`
  ).then((response) => {
    store.dispatch(
      scheduledQuestStartDeleted({
        questId,
        deletedScheduledQuestStartId: scheduledQuestStartId,
      })
    );
    store.dispatch(questInstanceListLoaded(response.data));
    return response;
  });
};
