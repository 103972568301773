import React, {
  Children,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Keyboard,
  LayoutChangeEvent,
  Platform,
  View,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import QKScrollView, { QKScrollViewController } from "./ScrollView";

type ContentSliderRef = {
  navigateToNextSlide: () => void;
};

export const ContentSlideContext = React.createContext<{
  isFocused: boolean;
}>({
  isFocused: false,
});

type ContentSliderProps = PropsWithChildren<{
  maxSlideIndex?: number;
  indexConfig: {
    title?: string;
    hideNavigation?: boolean;
    usesKeyboard?: boolean;
  }[];
  onCurrentSlideIndexChange: (currentSlideIndex: number) => void;
  containerStyle: ViewStyle;
  validateBeforeNext?: (currentSlideIndex: number) => boolean;
  onFirstViewOfSlide?: (currentSlideIndex: number) => void;
}>;

export const ContentSlider = React.forwardRef<
  ContentSliderRef,
  ContentSliderProps
>(
  (
    {
      children: _children,
      maxSlideIndex,
      indexConfig = [],
      validateBeforeNext,
      onCurrentSlideIndexChange,
      onFirstViewOfSlide,
      containerStyle = {},
    },
    forwardedRef
  ) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const scrollViewControllerRef = useRef<QKScrollViewController>(null);
    const [userScrollingDisabled, setUserScrollingDisabled] = useState(false);
    const visitedSlides = useRef(new Set<number>([0])).current;
    const reEnableScrollingTimeoutRef = useRef<NodeJS.Timeout>();

    const children = useMemo(() => {
      return Children.toArray(_children).filter(isValidElement);
    }, [_children]);

    const childrenCount = children.length;
    const childrenCountRef = useRef(childrenCount);
    childrenCountRef.current = childrenCount;

    const navigateToNextSlide = () => {
      // Don't proceed if current slide/item fails validation
      if (validateBeforeNext && !validateBeforeNext(currentSlideIndex)) {
        return;
      }
      const nextItemIndex = currentSlideIndex + 1;
      if (nextItemIndex < childrenCount) {
        navigateToSlide(nextItemIndex);
        if (!indexConfig[nextItemIndex].usesKeyboard) {
          Keyboard.dismiss();
        }
      }
    };

    const navigateToSlide = (index: number) => {
      const container = scrollViewControllerRef.current;
      if (!!container?.scrollTo && childrenCount > 0) {
        // prevent user from interrupting the programmatic scroll
        // fixes a bug on iOS native, but causes a bug on Chrome
        setUserScrollingDisabled(Platform.OS !== "web");
        if (reEnableScrollingTimeoutRef.current) {
          clearTimeout(reEnableScrollingTimeoutRef.current);
          reEnableScrollingTimeoutRef.current = undefined;
        }
        reEnableScrollingTimeoutRef.current = setTimeout(() => {
          setUserScrollingDisabled(false);
          reEnableScrollingTimeoutRef.current = undefined;
        }, 800);

        setTimeout(() => {
          container.scrollTo({
            x: index * containerWidth,
            animated: true,
          });
        }, 0);
      }
    };

    useImperativeHandle(forwardedRef, () => ({
      navigateToNextSlide,
    }));

    useEffect(() => {
      if (onCurrentSlideIndexChange) {
        onCurrentSlideIndexChange(currentSlideIndex);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlideIndex]);

    const viewHasInitialized = containerWidth !== 0;

    return (
      <ItemCardContainer
        onLayout={({ nativeEvent }: LayoutChangeEvent) =>
          setContainerWidth(nativeEvent.layout.width)
        }
      >
        <QKScrollView
          style={{ flex: 1 }}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          ref={scrollViewControllerRef}
          pagingEnabled={viewHasInitialized} // needed for Web touch scrolling
          snapToInterval={viewHasInitialized ? containerWidth : undefined}
          scrollEnabled={!userScrollingDisabled}
          decelerationRate="fast"
          scrollEventThrottle={16}
          onMomentumScrollEnd={() => {
            setUserScrollingDisabled(false);
          }}
          onScroll={(e) => {
            if (!viewHasInitialized) {
              return;
            }

            const x = e.nativeEvent.contentOffset.x;
            const newSlideIndex = Math.floor(
              (x + containerWidth / 2) / containerWidth
            );

            if (newSlideIndex !== currentSlideIndex) {
              setCurrentSlideIndex(newSlideIndex);
              if (!indexConfig[newSlideIndex].usesKeyboard) {
                Keyboard.dismiss();
              }
            }

            const offByOneSafariInputFocusAutoScrollBug = 1;
            const fullyInViewItemIndex = Math.floor(
              (x + offByOneSafariInputFocusAutoScrollBug) / containerWidth
            );
            if (!visitedSlides.has(fullyInViewItemIndex)) {
              visitedSlides.add(fullyInViewItemIndex);
              onFirstViewOfSlide?.(fullyInViewItemIndex);
            }
          }}
        >
          {Children.map(children, (child, index) => {
            const notPastMaxSlide =
              typeof maxSlideIndex === "number" ? maxSlideIndex >= index : true;
            const shouldShow = notPastMaxSlide && containerWidth;

            return (
              <View
                style={[
                  shouldShow ? undefined : { display: "none" },
                  {
                    minWidth: containerWidth,
                    width: containerWidth,
                    flex: 1,
                    justifyContent: "center",
                  },
                  containerStyle,
                ]}
              >
                <ContentSlideContext.Provider
                  value={{
                    isFocused: currentSlideIndex === index,
                  }}
                >
                  {child}
                </ContentSlideContext.Provider>
              </View>
            );
          })}
          {maxSlideIndex !== undefined && Platform.OS === "web" && (
            <OverflowAnchorFix />
          )}
        </QKScrollView>
      </ItemCardContainer>
    );
  }
);
ContentSlider.displayName = "ContentSlider";

/**
 * This is a hack to fix a bug on Chrome & Safari where when a new slide becomes available to go to the view teleports
 * to the last slide available. This is because the browser scroll view gets anchored to the bottom (right) of the
 * scroll view and assumes you want to stay there when new content is appended. There is a newer css attribute called
 * `overflow-anchor` that fixes this, but it is not supported on Safari yet. (https://bugs.webkit.org/show_bug.cgi?id=171099)
 * Also there will need to be support on react-native-web to pass it through.
 *
 * This fixes it because it adds a 1px wide view that buffers the scroll view from the right edge of the screen.
 * So the browser does not consider it as anchored to the bottom (right) of the scroll view.
 *
 * In theory the bug could still exhibit itself in Chrome or other browsers that implement decimal pixels because they
 * are rounded in RN and after many slides the rounding may cause the scroll view to be anchored to the bottom (right)
 * of the scroll view even with the extra 1px buffer.
 */
const OverflowAnchorFix = styled.View`
  width: 1px;
`;

const ItemCardContainer = styled.View`
  padding-vertical: 14px;
  flex-grow: 1;
`;
