import { combineReducers } from "@reduxjs/toolkit";
import questPrototypes from "@app/store/cache/questPrototypes";
import assignments from "@app/store/cache/assignments";
import users from "@app/store/cache/users";
import quests from "@app/store/cache/quests";
import workspaces from "@app/store/cache/workspaces";
import workspaceMemberships from "@app/store/cache/workspaceMemberships";
import questInstances from "@app/store/cache/questInstances";
import questscriptExecutions from "@app/store/cache/questscriptExecutions";
import itemPrototypes from "@app/store/cache/itemPrototypes";
import rewardInstances from "@app/store/cache/rewardInstances";
import rewardPrototypes from "@app/store/cache/rewardPrototypes";
import reviewers from "@app/store/cache/reviewers";
import teams from "@app/store/cache/teams";
import teamMemberships from "@app/store/cache/teamMemberships";
import questStartTriggers from "@app/store/cache/questStartTriggers";
import questStartConfigurations from "@app/store/cache/questStartConfigurations";
import itemInstances from "@app/store/cache/itemInstances";
import questInstanceViews from "@app/store/cache/questInstanceViews";
import scheduledQuestStarts from "@app/store/cache/scheduledQuestStarts";
import appAuths from "@app/store/cache/appAuths";

export const cacheReducer = combineReducers({
  appAuths,
  assignments,
  itemInstances,
  itemPrototypes,
  questInstanceViews,
  questInstances,
  questPrototypes,
  questStartTriggers,
  questStartConfigurations,
  quests,
  questscriptExecutions,
  reviewers,
  rewardInstances,
  rewardPrototypes,
  scheduledQuestStarts,
  teams,
  teamMemberships,
  users,
  workspaceMemberships,
  workspaces,
});
