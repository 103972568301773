import { useMemo, useRef } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { sentry } from "@app/util/sentry";

/**
 * useInterval hook to manage a setTimeout in a React component lifecycle.
 *  - helper functions to start and stop the timeout
 *  - the timeout is cleared on unmount
 *  - the timeout callback can be updated any time and the latest version will be used when the timeout is called
 * @param fn
 * @param ms
 */

type UseIntervalResult<T extends unknown[]> = {
  start: (...args: T) => void;
  stop: () => void;
};

export const useInterval = <T extends unknown[]>(
  fn: (...args: T) => void,
  ms: number
): UseIntervalResult<T> => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(fn);
  callbackRef.current = fn;

  const handler = useMemo(
    () => ({
      start(...startArgs: T) {
        intervalRef.current = setInterval(() => {
          try {
            intervalRef.current = undefined;
            callbackRef.current(...startArgs);
          } catch (e) {
            console.warn(
              "Unhandled Exception thrown from useInterval callback",
              e
            );
            sentry.captureException(e, {
              extra: {
                startArgs,
              },
            });
            throw e;
          }
        }, ms);
      },
      stop() {
        if (intervalRef.current) {
          clearTimeout(intervalRef.current);
          intervalRef.current = undefined;
        }
      },
    }),
    [ms]
  );
  useEffectOnce(() => () => handler.stop());

  return handler;
};
