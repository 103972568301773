import React, { useCallback, useMemo, useState } from "react";
import { HeaderBackButtonProps } from "@react-navigation/elements";
import { View } from "react-native";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import Icon from "@app/components/icon";
import { createLink } from "@app/util/link.utils";

export const HiddenOpenDebugScreenButton: React.FC<
  HeaderBackButtonProps
> = () => {
  const [showDebugIcon, setShowDebugIcon] = useState(false);

  const hideButton = useCallback(() => setShowDebugIcon(false), []);
  const navigate = useMemo(
    () => createLink({ screen: "AppDebugInformation" }),
    []
  );
  const showButton = useCallback(() => setShowDebugIcon(true), []);

  return (
    <View>
      <HeaderIcon
        onLongPress={showDebugIcon ? hideButton : showButton}
        onPress={showDebugIcon ? navigate : undefined}
      >
        <Icon icon={showDebugIcon ? "bug" : "NONE"} size={32} />
      </HeaderIcon>
    </View>
  );
};
