import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
export function useStateFunction<T extends Function>(
  initialValue?: T
): [T | undefined, (newValue: T) => void] {
  const [funcVal, _setFuncVal] = useState<T | undefined>(initialValue);

  // Setting a function on state has unintended behavior due to the manipulation function that
  // useState is expecting to receive. So we can just add a manipulation function that always
  // returns the desired new state (the updated function) to get around the behavior.
  const setFunc = useCallback(
    (updatedFunc: T) => _setFuncVal(() => updatedFunc),
    [_setFuncVal]
  );

  return [funcVal, setFunc];
}
