import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";

interface InfoTextProps {
  text: string | React.ReactElement;
  style?: StyleProp<ViewStyle>;
  infoType?: "info" | "warning";
}
export const InfoText: React.FC<InfoTextProps> = ({
  infoType = "info",
  text,
  style,
}) => {
  return (
    <InfoTextSection style={style}>
      <ItemInfoIcon icon={"info"} infoType={infoType} />
      <StyledInfoText $warning={infoType === "warning"} size="medium">
        {text}
      </StyledInfoText>
    </InfoTextSection>
  );
};

const InfoTextSection = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

const StyledInfoText = styled(Text)`
  margin-right: 12px;
`;
