import React, { PropsWithChildren } from "react";

import { useColorScheme } from "react-native";
import { ThemeProvider as SCThemeProvider } from "styled-components/native";
import { darkTheme, lightTheme } from "@app/themes";

interface ThemeProviderProps {
  forcedColorScheme?: "dark" | "light";
}

export const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = ({
  forcedColorScheme,
  children,
}) => {
  let colorScheme = useColorScheme();

  if (forcedColorScheme) {
    colorScheme = forcedColorScheme;
  }

  return (
    <SCThemeProvider theme={colorScheme === "dark" ? darkTheme : lightTheme}>
      {children}
    </SCThemeProvider>
  );
};

export default ThemeProvider;
