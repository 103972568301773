import React, { ErrorInfo, ReactElement } from "react";
import { sentry } from "@app/util/sentry";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { NativeModules, Platform } from "react-native";
import * as Updates from "expo-updates";

type ErrorBoundaryState = { hasError: boolean };

export class TopLevelErrorBoundary extends React.Component<
  {
    children: ReactElement | null;
  },
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    sentry.captureException(error, {
      extra: {
        errorBoundary: "TopLevelErrorBoundary",
        errorInfo,
      },
    });
    console.error("Error caught by TopLevelErrorBoundary: ", error);
  }

  static getDerivedStateFromError(): Partial<ErrorBoundaryState> | null {
    return { hasError: true };
  }

  render(): ReactElement | null {
    const { children } = this.props;

    if (this.state.hasError) {
      return (
        <PlaceholderView
          text="Uh oh, something went wrong."
          actions={[
            {
              type: "primary",
              text: "Reload",
              loading: false,
              onPress: () => {
                if (Platform.OS === "web") {
                  window.location.reload();
                } else if (__DEV__) {
                  // cannot use Updates module in dev mode
                  NativeModules.DevSettings.reload();
                } else {
                  Updates.reloadAsync().catch((e) => {
                    console.error("Error reloading app: ", e);
                  });
                }
              },
            },
          ]}
        />
      );
    } else {
      return children;
    }
  }
}
