import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";

import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components/native";
import Switch from "@app/components/questkit/switch";

export const SwitchItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, setItemAction } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const onSwitch = useCallback(() => {
    onItemChange?.({
      ...itemRef.current,
      data: {
        switchOn: !itemRef.current.data.switchOn,
      },
      version: itemRef.current.version + 1,
    });
    onItemValidate?.();
  }, [onItemChange, onItemValidate]);
  useEffect(() => setItemAction?.(onSwitch), [onSwitch, setItemAction]);

  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={
        <StyledSwitch
          readOnly={props.readOnly}
          onSwitch={onSwitch}
          switchOn={props.item.data.switchOn}
          switchStyle="USER"
          accessibilityLabelledBy={`item-name-label-${props.item.prototype.id}`}
        />
      }
    />
  );
};

const StyledSwitch = styled(Switch)`
  margin-top: 3.8px;
`;
