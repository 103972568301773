import {
  QuestInstanceDetail,
  QuestPrototypeDetail,
} from "@questmate/openapi-spec";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { questInstanceLoaded } from "@app/store/cache/questInstances";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";

export type CachedRewardPrototype<T extends APIRewardPrototype> = Omit<
  T,
  "subquest" | "icon" | "personalData"
> & {
  subquestId?: string;
};
export type RewardPrototype = CachedRewardPrototype<
  UnionToIntersection<APIRewardPrototype>
>;

const rewardPrototypeAdapter = createEntityAdapter<RewardPrototype>({});

export const {
  selectById: selectRewardPrototypeById,
  selectEntities: selectAllRewardPrototypesById,
} = rewardPrototypeAdapter.getSelectors<AppState>(
  (state) => state.cache.rewardPrototypes
);

const slice = createSlice({
  name: "cache/rewardPrototypes",
  initialState: rewardPrototypeAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) =>
        rewardPrototypeAdapter.setMany(
          state,
          action.payload.rewards.map(mapRewardPrototype)
        )
      )
      .addCase(questInstanceLoaded, (state, action) => {
        const rewards = action.payload.prototype.rewards;
        if (rewards) {
          rewardPrototypeAdapter.upsertMany(
            state,
            rewards.map(mapRewardPrototype)
          );
        }
      })
      .addCase(userLogout, (state) => rewardPrototypeAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

type APIRewardPrototype =
  | QuestPrototypeDetail["rewards"][number]
  | QuestInstanceDetail["prototype"]["rewards"][number];

const mapRewardPrototype = createDataMapper<
  APIRewardPrototype,
  RewardPrototype
>()(["id", "type", "config", "position"], {});
