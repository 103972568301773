import React, { useEffect } from "react";

import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { QuestManageView } from "@app/components/screen/quest/QuestManageView";

import PlaceholderView from "@app/components/screen/PlaceholderView";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { ManageQuestViewContextProvider } from "@app/quest/edit/ManageQuestViewContextProvider";
import { useQuestContext } from "@app/quest/QuestContext";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import Icon from "@app/components/icon";
import { createLink } from "@app/util/link.utils";
import { OpenSideBarButton } from "@app/navigation/components/OpenSideBarButton";
import { ScreenContainer } from "@app/screens/ScreenContainer";

export const QuestTemplateScreen: React.FC = () => {
  const {
    questId,
    currentQuestPrototypeId: rootQuestPrototypeId,
    advancedMode,
  } = useQuestContext();
  const { questPrototypeId: questPrototypeIdFromRoute } =
    useAppRoute<"QuestTemplate">().params || {};

  const questPrototypeId = questPrototypeIdFromRoute ?? rootQuestPrototypeId;
  const { questPrototype, error, isLoading, refresh } =
    useQuestPrototype(questPrototypeId);
  const navigation = useAppNavigation<"QuestTemplate">();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        questPrototypeIdFromRoute &&
        questPrototypeIdFromRoute !== rootQuestPrototypeId ? (
          <HeaderIcon
            onPress={createLink({
              screen: "Quest",
              params: {
                questId,
                screen: "QuestTemplate",
                params: { questPrototypeId: rootQuestPrototypeId },
              },
            })}
          >
            <Icon icon="chevron-left" size={32} />
          </HeaderIcon>
        ) : (
          <OpenSideBarButton />
        ),
    });
  }, [navigation, questPrototypeIdFromRoute, rootQuestPrototypeId, questId]);

  if (!questPrototype) {
    if (error) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: isLoading,
                onPress: refresh,
              },
            ]}
          />
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <ScreenContainer>
      <ManageQuestViewContextProvider
        questId={questId}
        questPrototypeId={questPrototypeId}
        advancedMode={advancedMode}
      >
        <QuestManageView viewMode={"READ_ONLY"} />
      </ManageQuestViewContextProvider>
    </ScreenContainer>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
