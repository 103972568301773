import React from "react";
import { ViewProps, ViewStyle } from "react-native";
import styled from "styled-components/native";
import Icon from "@app/components/icon";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

interface AmountProps {
  amount: number;
  onChangeAmount: (newAmount: number) => void;
  onBlur?: () => void;
  style?: ViewStyle;
  readOnly?: boolean;
  minAmount?: number;
  maxAmount?: number;
  accessibilityLabelledBy?: ViewProps["accessibilityLabelledBy"];
}

const validateNumber = (text: string): string => {
  let newAmount = text.replace(/\D+/, "");
  if (newAmount === "") {
    newAmount = "0";
  }
  return newAmount;
};

const Amount: React.FC<AmountProps> = ({
  amount,
  onChangeAmount,
  onBlur,
  style,
  readOnly,
  minAmount = 0,
  maxAmount = 999,
  accessibilityLabelledBy,
}) => {
  const stringAmount = amount.toString();

  const onPressLeft = () => {
    let newAmount: number;
    if (amount <= minAmount) {
      newAmount = minAmount;
    } else {
      newAmount = amount - 1;
    }
    onChangeAmount(newAmount);
  };

  const onPressRight = () => {
    let newAmount: number;
    if (amount >= maxAmount) {
      newAmount = maxAmount;
    } else {
      newAmount = amount + 1;
    }

    onChangeAmount(newAmount);
  };

  const onChangeText = (text: string) => {
    onChangeAmount(parseInt(validateNumber(text)));
  };

  return (
    <AmountContainer style={style}>
      <PressableOpacity
        activeOpacity={0.8}
        onPress={onPressLeft}
        disabled={readOnly}
        accessibilityHint={"Decrease amount"}
      >
        <StyledIcon
          icon="chevron-down"
          inverted={true}
          disabled={readOnly || amount <= minAmount}
        />
      </PressableOpacity>
      <StyledTextInput
        value={stringAmount ? stringAmount : "0"}
        onChangeText={onChangeText}
        editable={!readOnly}
        maxLength={3}
        onBlur={onBlur}
        accessibilityLabelledBy={accessibilityLabelledBy}
      />
      <PressableOpacity
        activeOpacity={0.8}
        onPress={onPressRight}
        disabled={readOnly}
        accessibilityHint={"Increase amount"}
      >
        <StyledIcon
          icon="chevron-up"
          inverted={true}
          disabled={readOnly || amount >= maxAmount}
        />
      </PressableOpacity>
    </AmountContainer>
  );
};

const StyledTextInput = styled.TextInput`
  color: ${({ theme }) => theme.textInput.normal.text};
  font-family: CentraNo2-Book;
  text-align: center;
  width: 100%;
  flex: 1;
`;

const StyledIcon = styled(Icon)`
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.textInput.normal.placeholder
      : theme.textInput.normal.text};
`;

const AmountContainer = styled.View`
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.textInput.normal.background};
  border-color: ${({ theme }) => theme.textInput.normal.border};
  border-width: 1px;
  width: 120px;
  flex-direction: row;
  justify-content: space-between;
`;

export default Amount;
