import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  SafeAreaView,
  useWindowDimensions,
  View,
} from "react-native";

import styled, { ThemeContext } from "styled-components/native";
import Loader from "@app/components/animated/loader";
import Text from "@app/components/questkit/text";
import {
  AddEntry,
  MetaInfoEntryData,
  QuestEntry,
} from "@app/components/screen/home/questSelector/entry";

import PlaceholderView from "@app/components/screen/PlaceholderView";
import { FadeInView } from "@app/components/animated/fadeInView";
import ShowMoreButton from "@app/components/questkit/showMoreButton";
import ensurePushPermissions from "@app/util/ensurePushNotifications";
import { setSubsequentStart } from "@app/store/main";
import { store, useAppSelector } from "@app/store";
import { QuestStack } from "@app/components/screen/home/questSelector/stack";
import { getRewardText } from "@app/util/rewards";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import {
  AssignmentListItem,
  RecentQuestsListItem,
} from "@questmate/openapi-spec";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { LinkText } from "@app/components/LinkText";
import { fetchRecentQuests } from "@app/util/client/requests/quests";
import { fetchAssignments } from "@app/util/client/requests/assignments";
import QKScrollView from "@app/components/questkit/ScrollView";
import { createLink, OnLinkPress, useLink } from "@app/util/link.utils";
import BasePressable from "@app/components/questkit/BasePressable";
import QKModal from "@app/components/modal";
import CreateQuest from "@app/components/modal/createTemplate";
import isEqual from "react-fast-compare";
import { useRequest } from "@app/util/client/requests";
import { useScreenHasBeenFocusedAtLeastOnce } from "@app/util/useScreenHasBeenFocusedAtLeastOnce";

export const HomeScreen = () => {
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();
  return hasBeenFocused ? <HomeScreenView /> : null;
};

const HomeScreenView: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const [refreshRequested, setRefreshRequested] = useState(false);

  const subsequentStart = useAppSelector((state) => state.main.subsequentStart);
  useEffectOnce(() => {
    setTimeout(() => {
      if (!subsequentStart) {
        void ensurePushPermissions();
        store.dispatch(setSubsequentStart(true));
      }
    }, 5000);
  });

  const {
    data: assignmentsData,
    error: assignmentsError,
    refresh: assignmentsRefresh,
    isValidating: assignmentsIsValidating,
  } = useRequest(fetchAssignments(["OPEN", "IN_REVIEW"]));

  const {
    data: questsData,
    error: questsError,
    refresh: questsRefresh,
    isValidating: questsIsValidating,
  } = useRequest(fetchRecentQuests(8));

  if (!assignmentsData || !questsData) {
    if (assignmentsError || questsError) {
      return (
        <StyledSafeAreaView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: assignmentsIsValidating || questsIsValidating,
                onPress: () => {
                  if (assignmentsError) {
                    void assignmentsRefresh();
                  }
                  if (questsError) {
                    void questsRefresh();
                  }
                },
              },
            ]}
            key={"placeholderViewError"}
          />
        </StyledSafeAreaView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <HomeScreenContainer>
      <FadeInView style={{ flex: 1 }}>
        <QKScrollView
          showsVerticalScrollIndicator={true}
          contentInsetAdjustmentBehavior="automatic"
          refreshControl={
            <RefreshControl
              refreshing={refreshRequested}
              tintColor={themeContext.inactive}
              onRefresh={async () => {
                setRefreshRequested(true);
                await assignmentsRefresh();
                await questsRefresh();
                setRefreshRequested(false);
              }}
            />
          }
        >
          <SafeAreaView>
            <ContentWrapper>
              <AssignmentsList assignments={assignmentsData} />
              <RecentQuestsList quests={questsData} />
            </ContentWrapper>
          </SafeAreaView>
        </QKScrollView>
      </FadeInView>
    </HomeScreenContainer>
  );
};

const ContentWrapper = styled.View`
  padding-top: 36px;
`;
const HomeScreenContainer = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;
const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const StyledSafeAreaView = styled.SafeAreaView`
  flex: 1;
`;

interface HorizontalListProps<T> {
  items: T[];
  initialNumToRender: number;
  renderItem: ListRenderItem<T>;
  title: string;
  onItemAdd?: OnLinkPress | (() => void) | undefined;
  addItemTitle?: string;
  onSeeMore?: OnLinkPress | undefined;
  placeholder?: React.ReactElement;
  large?: boolean;
  testID?: string;
}
const HorizontalList = <T,>({
  items = [],
  initialNumToRender,
  renderItem,
  title,
  onSeeMore,
  onItemAdd,
  addItemTitle,
  placeholder,
  large,
  testID,
}: HorizontalListProps<T>) => {
  const [animation, setAnimation] = useState(false);
  const navigation = useAppNavigation();

  useEffectOnce(() =>
    navigation.addListener("focus", () => {
      if (Platform.OS === "web") {
        setAnimation(false);
      }
    })
  );

  const onScrollHandler = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    const offset =
      nativeEvent.contentSize.width -
      (nativeEvent.contentOffset.x + nativeEvent.layoutMeasurement.width);

    if (!animation && offset <= 10) {
      setAnimation(items.length > 0);
    }
    if (animation && offset > 16) {
      setAnimation(false);
    }
  };

  return (
    <View testID={testID}>
      <HeaderWrapper onPress={onSeeMore} disabled={!onSeeMore}>
        <Text numberOfLines={2} size="large">
          {title}
        </Text>
      </HeaderWrapper>

      {items.length === 0 && placeholder ? (
        <QuestListPlaceholder>{placeholder}</QuestListPlaceholder>
      ) : (
        <>
          <QuestSelectorTable
            onScroll={onScrollHandler}
            hasSeeMore={Boolean(onSeeMore)}
            renderItem={renderItem}
            ListHeaderComponent={
              addItemTitle && onItemAdd ? (
                <AddEntry title={addItemTitle} onPress={onItemAdd} />
              ) : null
            }
            data={items}
            initialNumToRender={initialNumToRender}
            keyboardShouldPersistTaps="handled"
          />
        </>
      )}
      {onSeeMore && (
        <ShowMoreButton
          count={items.length}
          onPress={onSeeMore}
          animation={animation}
          large={large}
        />
      )}
    </View>
  );
};

const QuestSelectorTable = styled.FlatList.attrs<{ hasSeeMore: boolean }>(
  ({ hasSeeMore }) => ({
    horizontal: true,
    showsHorizontalScrollIndicator: false,
    contentContainerStyle: {
      paddingLeft: 20,
      paddingRight: hasSeeMore ? 107 : 20,
      marginBottom: 49,
    },
  })
)<{ hasSeeMore: boolean }>`
  flex-direction: row;
`;

const QuestListPlaceholder = styled.View`
  background-color: ${({ theme }) => theme.card.background};
  min-height: 162px;
  margin-horizontal: 20px;
  border-radius: 8px;
  margin-bottom: 49px;
  padding: 10px;
`;

const HeaderWrapper = styled(BasePressable)`
  background-color: transparent;
  margin-bottom: 19px;
  margin-left: 29px;
  margin-right: 101px;
  min-height: 34px;
`;

const RecentQuestCard: React.FC<{
  item: RecentQuestsListItem;
}> = ({ item: quest }) => {
  const lastUsedTab = useAppSelector(
    (state) => state.ui.questLastTab[quest.id]
  );
  const onPress = useLink({
    screen: "Quest",
    params: {
      questId: quest.id,
      screen: lastUsedTab ?? "QuestRuns",
      params: {},
    },
  });

  const metaInfo = useMemo(
    (): MetaInfoEntryData[] =>
      quest.openRunsCount > 0
        ? [{ text: `${quest.openRunsCount}`, icon: "play" }]
        : [],
    [quest.openRunsCount]
  );
  return (
    <QuestEntry
      key={quest.id}
      title={quest.currentFormPrototype?.name || ""}
      metaInfo={metaInfo}
      onPress={onPress}
      tileType="standard"
    />
  );
};
type QuestsListProps = {
  quests: RecentQuestsListItem[];
};
const RecentQuestsList: React.FC<QuestsListProps> = React.memo(({ quests }) => {
  const { width } = useWindowDimensions();
  const initialNumToRender = useMemo(
    () => Math.ceil(width / (162 + 10) + 2),
    [width]
  );
  const [showCreateQuestModal, setShowCreateQuestModal] = useState(false);
  const onItemAdd = useCallback(() => setShowCreateQuestModal(true), []);

  const onSeeMore = useLink({ screen: "QuestList" });
  const renderItem = useCallback(({ item }: { item: RecentQuestsListItem }) => {
    return <RecentQuestCard item={item} />;
  }, []);

  return (
    <>
      <QKModal
        showModal={showCreateQuestModal}
        setShowModal={setShowCreateQuestModal}
        title="New Quest"
      >
        <CreateQuest
          createButtonText="Create Quest"
          setShowModal={setShowCreateQuestModal}
        />
      </QKModal>
      <HorizontalList<RecentQuestsListItem>
        testID="my-quests"
        items={quests}
        initialNumToRender={initialNumToRender}
        renderItem={renderItem}
        onSeeMore={onSeeMore}
        title="My Quests"
        addItemTitle="Create Quest"
        onItemAdd={onItemAdd}
      />
    </>
  );
}, isEqual);
RecentQuestsList.displayName = "RecentQuestsList";

type AssignmentStackCardProps = {
  assignmentStack: AssignmentStackData;
};
const AssignmentStackCard: React.FC<AssignmentStackCardProps> = React.memo(
  ({ assignmentStack: { key, name, assignments } }) => {
    const onPress = useLink({
      screen: "Assignments",
      params: {
        filters: {
          [key]: {
            id: key,
            name: name,
            type: "template",
          },
        },
      },
    });
    const onPressEntryBuilder = useCallback((id: string) => {
      return createLink({ screen: "QuestInstance", params: { id } });
    }, []);
    return (
      <QuestStack
        key={key}
        name={name}
        stackData={assignments}
        onPress={onPress}
        onPressEntryBuilder={onPressEntryBuilder}
      />
    );
  },
  isEqual
);
AssignmentStackCard.displayName = "AssignmentStack";

type AssignmentEntryCardProps = {
  assignment: AssignmentListItem;
};
const AssignmentEntryCard: React.FC<AssignmentEntryCardProps> = React.memo(
  ({ assignment }) => {
    const rewardText = getRewardText(assignment.formPrototype.rewards);

    const metaInfo = [] as MetaInfoEntryData[];

    if (rewardText) {
      metaInfo.push({
        text: rewardText,
        icon: "trophy",
      });
    }

    const onPress = useLink({
      screen: "QuestInstance",
      params: {
        id: assignment.formInstance.id,
      },
    });
    return (
      <QuestEntry
        key={assignment.id}
        title={assignment.formPrototype.name || ""}
        metaInfo={metaInfo}
        onPress={onPress}
        tileType="assignment"
      />
    );
  },
  isEqual
);
AssignmentEntryCard.displayName = "AssignmentEntry";

export interface AssignmentStackData {
  assignments: AssignmentListItem[];
  name: string;
  key: string;
}

type AssignmentsListProps = {
  assignments: AssignmentListItem[];
};
const AssignmentsList: React.FC<AssignmentsListProps> = React.memo(
  ({ assignments }) => {
    const { width } = useWindowDimensions();
    const initialNumToRender = useMemo(
      () => Math.ceil(width / (162 + 10) + 2),
      [width]
    );
    // Group assignments
    const assignmentsByQuestId = useMemo(() => {
      const assignmentQuestIds: string[] = [];
      const assignmentsByQuestId = [] as AssignmentStackData[];

      assignments.forEach((assignment) => {
        const index = assignmentQuestIds.indexOf(
          assignment.formPrototype.quest.id
        );
        if (index === -1) {
          assignmentQuestIds.push(assignment.formPrototype.quest.id);
          assignmentsByQuestId.push({
            assignments: [assignment],
            name:
              assignment.formPrototype.quest.currentFormPrototype.name || "",
            key: assignment.formPrototype.quest.id,
          });
        } else {
          // Subsequent occurence of Quest with given template id
          assignmentsByQuestId[index].assignments.push(assignment);
        }
      });
      return assignmentsByQuestId;
    }, [assignments]);

    const onSeeMore = useLink({
      screen: "Assignments",
    });

    const renderItem = useCallback(
      ({ item }: { item: AssignmentStackData }) => {
        if (item.assignments?.length === 1) {
          return (
            <AssignmentEntryCard
              key={item.assignments[0].id}
              assignment={item.assignments[0]}
            />
          );
        } else {
          return <AssignmentStackCard key={item.key} assignmentStack={item} />;
        }
      },
      []
    );

    return (
      <HorizontalList<AssignmentStackData>
        testID="my-assignments"
        items={assignmentsByQuestId}
        initialNumToRender={initialNumToRender}
        large={true}
        renderItem={renderItem}
        placeholder={
          <View
            style={{
              justifyContent: "center",
              flex: 1,
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Text
                size="large"
                style={{
                  marginBottom: 20,
                }}
              >
                Inbox Zero! 🥳
              </Text>
              <LinkText
                to={{
                  screen: "Assignments",
                  params: { filters: {} },
                }}
              >
                Show completed Quests
              </LinkText>
            </View>
          </View>
        }
        title="My assignments"
        onSeeMore={onSeeMore}
      />
    );
  },
  isEqual
);
AssignmentsList.displayName = "AssignmentsList";
