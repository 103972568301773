import Icon, { IconIdentifier } from "@app/components/icon";
import * as React from "react";
import Text from "@app/components/questkit/text";
import styled from "styled-components/native";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { AnimatedGradient } from "@app/quest/edit/AnimatedGradient";
import { useHover } from "@app/util/useHover";
import { Platform, StyleProp, ViewStyle } from "react-native";

type AddEntryCardProps = {
  text: string;
  icons: IconIdentifier[];
  onPress: () => void;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};
export const AddEntryCard: React.FC<AddEntryCardProps> = ({
  icons,
  onPress,
  text,
  testID,
  style,
}) => {
  const { hoverProps, isHovered } = useHover();
  return (
    <AddEntryCardContainer
      {...hoverProps}
      onPress={onPress}
      style={style}
      testID={testID}
    >
      {Platform.OS === "web" ? (
        <>
          <AnimatedBorderGradient
            isHovered={isHovered}
            speed={1000}
            paused={!isHovered}
          />
          <GradientCover />
        </>
      ) : null}
      <AddEntryButton>
        <Icon icon="plus" container="COLLAPSED" />
        <Text size="small">{text}</Text>
      </AddEntryButton>
      {!Array.isArray(icons) || icons.length === 0 ? null : (
        <IconGroup>
          {icons.map((icon, index) => (
            <IconContainer key={`icon-${index}`}>
              <StyledIcon icon={icon} />
            </IconContainer>
          ))}
        </IconGroup>
      )}
    </AddEntryCardContainer>
  );
};

const GradientCover = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.background};
  border-radius: 14px;
`;
const AnimatedBorderGradient = styled(AnimatedGradient)<{ isHovered: boolean }>`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const IconContainer = styled.View`
  background-color: ${({ theme }) => theme.interaction.neutralLight};
  border-radius: 20px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon).attrs({ container: "COLLAPSED" })`
  color: ${({ theme }) => theme.primary};
`;

const IconGroup = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 400px;
  max-height: 96px;
  overflow: hidden;
  gap: 16px;
  margin-top: 32px;
`;

const AddEntryButton = styled.View`
  height: 32px;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.interaction.neutralLight};
  border-radius: 16px;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const AddEntryCardContainer = styled(PressableOpacity).attrs({
  activeOpacity: 0.8,
})`
  position: relative;
  align-self: center;
  width: 100%;
  max-width: 540px;
  padding: 24px;
  margin: 0 24px 16px;
  align-items: center;
  border-radius: 16px;
  border: 2px dashed ${({ theme }) => theme.textInput.normal.border};
`;
