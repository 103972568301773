import { CustomItemManageView } from "@app/components/item/components/custom/edit/CustomItemManageView";
import { ItemBaseProps } from "@app/components/item/components/itemContainer";
import { uuid } from "@app/util/uuid";
import React, { useCallback, useEffect, useRef } from "react";
import { apiRequest, QMApiError } from "@app/util/client";
import { CustomItemV2Response } from "@app/components/item/components/custom/types";
import * as CustomItem from "@questmate/questscript";
import { RenderViewEvent } from "@questmate/questscript";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import { useV2View } from "@app/components/item/components/custom/v2/useV2View";
import { ItemRenderData } from "@app/types/itemRenderData";
import isEquivalent from "@app/components/item/components/custom/isEquivalent";
import {
  CustomItemPrototypeV2Request,
  ItemDetail,
} from "@questmate/openapi-spec";
import { usePromise } from "@app/util/usePromise";
import { fetchLibraryQuestScript } from "@app/util/client/requests/library";
import * as Localization from "expo-localization";

type CustomItemV2EditViewProps = Pick<
  ItemBaseProps,
  "item" | "onItemChange"
> & {
  viewMode: "EDIT" | "READ_ONLY";
  inlineNodePortalName: string;
};

export const CustomItemV2ManageView: React.FC<CustomItemV2EditViewProps> = ({
  item,
  onItemChange,
  viewMode,
  inlineNodePortalName,
}) => {
  const itemRef = useRef(item);
  itemRef.current = item;

  const runViewPreviewStateRef = useRef({});
  const nextDebugCommandsRef = useRef<DebugCommandHandledByApi[]>([]);

  const renderConfigView = (events: CustomItem.CustomItemEvent[]) => {
    const script = scriptRef.current;
    if (!script || script.trim() === "" || script.startsWith("SCRIPT_REF:")) {
      return Promise.resolve(undefined);
    }

    events = [
      ...events,
      {
        id: uuid(),
        type: "RENDER_VIEW",
        data: {
          viewId: "ITEM_CONFIG_VIEW",
        },
      },
    ];
    if (script.includes("enableDebugMode()")) {
      // if they want debug mode on in the script, we will also debug log the events
      debugPrintEvents(events);
    }

    const debugCommands = nextDebugCommandsRef.current;
    nextDebugCommandsRef.current = [];

    return executeCustom(itemRef.current.prototype.id, {
      // TODO: Save user timezone (allow editing and initialize on login).
      //       Then use that timezone instead of their current timezone.
      timezone: Localization.getCalendars()?.[0]?.timeZone,
      locale: Localization.getLocales()?.[0]?.languageTag,
      script: script,
      events,
      ...(debugCommands.length > 0 ? { debugCommands } : {}),
    });
  };

  const renderRunViewPreview = (events: CustomItem.CustomItemEvent[]) => {
    const script = scriptRef.current;
    if (!script || script.trim() === "" || script.startsWith("SCRIPT_REF:")) {
      return Promise.resolve(undefined);
    }
    events = [
      ...events,
      {
        id: uuid(),
        type: "RENDER_VIEW",
        data: {
          viewId: "ITEM_RUN_VIEW",
        },
      },
    ];

    if (script.includes("enableDebugMode()")) {
      // if they want debug mode on in the script, we will also debug log the events
      debugPrintEvents(events);
    }

    return executeCustom(itemRef.current.prototype.id, {
      // TODO: Save user timezone (allow editing and initialize on login).
      //       Then use that timezone instead of their current timezone.
      timezone: Localization.getCalendars()?.[0]?.timeZone,
      locale: Localization.getLocales()?.[0]?.languageTag,
      script: script,
      runPreviewState: runViewPreviewStateRef.current,
      events,
    }).then((res) => {
      if (res?.status === "ok") {
        runViewPreviewStateRef.current = res.value?.state?.RUN_DATA ?? {};
      }
      return res;
    });
  };

  const configView = useV2View(renderConfigView, "ITEM_CONFIG_VIEW");
  const runViewPreview = useV2View(renderRunViewPreview, "ITEM_RUN_VIEW", true);

  const currentConfigViewState =
    configView.rawResponse?.value?.state?.CONFIG_DATA;
  const prevConfigViewStateRef = useRef(currentConfigViewState);
  useEffect(() => {
    // check if config state has changed after it has completed loading
    const prevConfigViewState = prevConfigViewStateRef.current;
    if (
      !configView.isLoading &&
      !isEquivalent(prevConfigViewState, currentConfigViewState)
    ) {
      prevConfigViewStateRef.current = currentConfigViewState;

      void runViewPreview.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configView.isLoading]);

  const [script, setScript, scriptRef] = useSyncedState(
    (itemRef.current.prototype as ItemDetail).customScript || "",
    {
      onLocalChange: (script: string) => {
        onItemChange?.(
          updatedItem(itemRef.current, {
            customScript: script,
          })
        );
      },
      onParentChange: () => {
        void configView.reload().then(() => runViewPreview.reload());
      },
    }
  );

  const { execute: fetchLibraryScript, isLoading: isLoadingScriptContent } =
    usePromise((scriptWithId: string) => {
      const scriptId = scriptWithId.replace("SCRIPT_REF:", "")?.trim();
      if (!scriptId) {
        return Promise.resolve();
      }
      return fetchLibraryQuestScript(scriptId)
        .execute()
        .then((response) => {
          if (scriptRef.current === scriptWithId) {
            // Ensure the script did not change while fetching.
            setScript(response.script);
            void configView.reload().then(() => runViewPreview.reload());
          }
        })
        .catch((e) => {
          console.error("Failed to fetch library script", e);
        });
    });

  useEffect(() => {
    if (script.startsWith("SCRIPT_REF:")) {
      void fetchLibraryScript(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [script]);

  const runViewPreviewRef = useRef(runViewPreview);
  runViewPreviewRef.current = runViewPreview;
  const configViewRef = useRef(configView);
  configViewRef.current = configView;
  const onDebugCommand = useCallback((command: CustomItemDebugCommand) => {
    switch (command) {
      case "CLEAR_RUN_PREVIEW_STATE": {
        runViewPreviewStateRef.current = {};
        void runViewPreviewRef.current.reload(true);
        break;
      }
      default:
        nextDebugCommandsRef.current.push(command);
        void configViewRef.current
          .reload(true)
          .then(() => runViewPreviewRef.current.reload(true));
        break;
    }
  }, []);

  return (
    <CustomItemManageView
      viewMode={viewMode}
      script={script}
      onChangeScript={setScript}
      requestedApps={configView?.rawResponse?.requestedApps || []}
      configView={configView}
      runViewPreview={runViewPreview}
      onDebugCommand={onDebugCommand}
      isLoadingScriptContent={isLoadingScriptContent}
      inlineNodePortalName={inlineNodePortalName}
      isCompletionAction={item.prototype.isCompletionAction}
    />
  );
};

async function executeCustom(
  itemId: string,
  body: unknown,
  notFoundRetryCount = 0
) {
  const questId = itemId.split(":")[0];
  return new Promise<CustomItemV2Response>((resolve, reject) => {
    apiRequest<CustomItemV2Response>(
      "POST",
      `/quests/${questId}/items/${itemId}/custom`,
      body
    )
      .then(resolve)
      .catch((error: QMApiError) => {
        if (error.status === 404) {
          // Item may be recently created and not yet persisted.
          if (notFoundRetryCount >= 5) {
            reject(error);
          } else {
            setTimeout(
              () => {
                executeCustom(itemId, body, notFoundRetryCount + 1)
                  .then(resolve)
                  .catch(reject);
              },
              Math.max(
                1000,
                // Wait a bit longer on subsequent retries.
                notFoundRetryCount * 1000
              )
            );
          }
        }
      });
  });
}

function updatedItem(
  currentItem: ItemRenderData,
  updatedPrototype: Partial<ItemRenderData<ItemDetail>["prototype"]>
) {
  return {
    ...currentItem,
    prototype: {
      ...currentItem.prototype,
      ...updatedPrototype,
    },
    version: currentItem.version + 1,
  };
}

function debugPrintEvents(events: CustomItem.CustomItemEvent[]) {
  events = [...events];
  const renderEventIndex = events.findIndex((e) => e.type === "RENDER_VIEW");
  const viewName = (events[renderEventIndex] as RenderViewEvent)?.data?.viewId;

  events.splice(renderEventIndex, 1);

  console.log(
    `${viewName} re-rendering with ${events.length} additional events:`
  );
  events.forEach((event, index) => {
    let logLine = `  [${index}] ${event.type} `;
    switch (event.type) {
      case "HANDLER_FIRED":
        logLine += `${event.data.handlerId} payload: ${event.data.payload}`;
        break;
      case "DATA_REQUESTED":
        logLine += `${event.data.dataSourceId} ${
          event.data.restartPaging ? "restarting paging" : ""
        }`;
        break;
    }
    console.log(logLine);
  });
  console.log("--------------------------------------------------------");
}

type DebugCommandHandledByApi = Exclude<
  CustomItemPrototypeV2Request["debugCommands"],
  undefined
>[number];
type DebugCommandHandledByApp = "CLEAR_RUN_PREVIEW_STATE";
export type CustomItemDebugCommand =
  | DebugCommandHandledByApi
  | DebugCommandHandledByApp;
