import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Platform, View } from "react-native";
import * as Haptics from "expo-haptics";

import styled from "styled-components/native";
import ItemRowHeader from "./itemRowHeader";
import ItemInfo from "./itemInfo";
import { DragHandlers } from "@app/components/questkit/dragPanel";

import Text from "@app/components/questkit/text";
import { CheckboxItem } from "./components/checkbox";
import { CustomItem, CustomItemV2 } from "./components/custom";
import { TextInputItem } from "./components/text";
import { LocationItem } from "@app/components/item/components/location";
import { MultiLineTextInputItem } from "@app/components/item/components/multiLineText";
import { EmailItem } from "@app/components/item/components/email";
import { SwitchItem } from "@app/components/item/components/switch";
import { useFocusController } from "@app/util/focus";
import { DateTimeInputItem } from "./components/date";
import { FileUploadItem } from "./components/fileUpload";
import { PlaceholderItem } from "@app/components/item/components/placeholder";

import equal from "react-fast-compare";
import isEqual from "react-fast-compare";
import { MultiSelectItem } from "./components/multiSelect";
import { validateItem } from "@questmate/common";
import { SubquestItem } from "@app/components/item/components/subquest";
import { AmountInputItem } from "@app/components/item/components/amount";
import { ItemBaseProps } from "@app/components/item/components/itemContainer";
import { useStateFunction } from "@app/util/useStateFunction";
import { Analytics } from "@app/analytics";
import { UserPickerItem } from "./components/userPicker";
import { ScheduleItem } from "@app/components/item/components/schedule";
import { PostSlackMessageItem } from "@app/components/item/components/postSlackMessageItem";
import { SignatureItem } from "@app/components/item/components/signature";
import { ItemInfo as ItemInfoType, ItemType } from "@questmate/openapi-spec";
import { ItemContextProvider } from "@app/components/item/ItemContextProvider";
import { uuid } from "@app/util/uuid";
import { RatingItem } from "@app/components/item/components/rating/RatingItem";
import { VideoItem } from "@app/components/item/components/video";
import { Shadow } from "react-native-shadow-2";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";
import { itemTypeViewDataMap } from "@app/components/modal/itemOptionsDialog";
import BasePressable from "@app/components/questkit/BasePressable";
import { useQuestViewContext } from "@app/quest/QuestViewContext";

export interface ItemProps extends Omit<ItemBaseProps, "onItemValidate"> {
  onShowItemOptions?: () => void;
  onItemValidate?: (itemId: string) => void;
  dragHandlers?: DragHandlers;
  position: number;
  isDragged?: boolean;
}

interface ContentProps {
  editMode: boolean;
}

interface StyledViewProps {
  isDragged: boolean;
  editMode: boolean;
  itemHasError?: boolean;
}

function doNothing(): void {
  /* do nothing */
}

const getItemComponentByType = (props: ItemBaseProps) => {
  switch (props.item.prototype.type) {
    case "Amount":
      return <AmountInputItem {...props} />;
    case "Checkbox":
      return <CheckboxItem {...props} />;
    case "Checklist":
      return <MultiSelectItem singleChoice={false} {...props} />;
    case "Custom":
      return <CustomItem {...props} />;
    case "CustomV2":
      return <CustomItemV2 {...props} />;
    case "Date":
      return <DateTimeInputItem time={false} {...props} />;
    case "DateTime":
      return <DateTimeInputItem time={true} {...props} />;
    case "Email":
      return <EmailItem {...props} />;
    case "FileUpload":
      return <FileUploadItem {...props} />;
    case "Location":
      return <LocationItem {...props} />;
    case "MultiLineText":
      return <MultiLineTextInputItem {...props} />;
    case "MultiSelect":
      return <MultiSelectItem {...props} />;
    case "Rating":
      return <RatingItem {...props} />;
    case "Schedule":
      return <ScheduleItem {...props} />;
    case "Signature":
      return <SignatureItem {...props} />;
    case "SingleSelect":
      return <MultiSelectItem singleChoice={true} {...props} />;
    case "SlackMessage":
      return <PostSlackMessageItem {...props} />;
    case "Subquest":
      return <SubquestItem {...props} />;
    case "Switch":
      return <SwitchItem {...props} />;
    case "Text":
      return <TextInputItem {...props} />;
    case "UserPicker":
      return <UserPickerItem {...props} />;
    case "Video":
      return <VideoItem {...props} />;
    default:
      return <PlaceholderItem {...props} />;
  }
};

const Item: React.FC<ItemProps> = ({
  item,
  onItemChange: _onItemChange,
  onItemAppend,
  onItemDelete,
  onItemValidate: _onItemValidate,
  onComplete: _onComplete,
  dragHandlers,
  editMode,
  slideMode,
  readOnly,
  isDragged,
  onShowItemOptions,
  position,
}) => {
  const itemRef = useRef(item);
  itemRef.current = item;
  const itemInfos = useMemo(
    () => item.prototype.infos ?? [],
    [item.prototype.infos]
  );
  const focusController = useFocusController();
  const [itemAction, setItemAction] = useStateFunction<() => void>();
  const questViewContext = useQuestViewContext();

  const onItemChange = readOnly ? doNothing : _onItemChange;

  const onItemValidate = useCallback(() => {
    // Only validating when working with a quest instance
    if (_onItemValidate) {
      _onItemValidate(item.prototype.id);
    }
  }, [item?.prototype?.id, _onItemValidate]);

  const onComplete = useCallback(() => {
    // Used for slide mode only for now...
    if (
      validateItem({
        data: item.data,
        required: item.prototype.required,
        type: item.prototype.type,
      }).valid
    ) {
      _onComplete && _onComplete();
    }
  }, [_onComplete, item.data, item.prototype.required, item.prototype.type]);

  const updateItemInfos = useCallback(
    (newItemInfos: ItemInfoType[]) => {
      onItemChange?.({
        ...itemRef.current,
        prototype: {
          ...itemRef.current.prototype,
          infos: newItemInfos,
        },
      });
    },
    [onItemChange]
  );

  const createItemInfo = useCallback(
    () => ({
      id: `${itemRef.current.prototype.id}:${uuid()}`,
      icon: "info",
      text: "",
      link: "",
    }),
    []
  );

  useEffect(() => {
    // Add an empty ItemInfo if there are no item infos shown
    // helps user understand what they can do here.
    if (!editMode) {
      return;
    }

    const newInfos = [...itemInfos];
    if (newInfos.length === 0) {
      newInfos.push(createItemInfo());
    }
    if (!isEqual(newInfos, itemInfos)) {
      updateItemInfos(newInfos);
    }
  }, [editMode, createItemInfo, updateItemInfos, itemInfos]);

  const onItemInfosChange = useCallback(
    (index: number, itemInfo: ItemInfoType) => {
      const newInfos = [...itemRef.current.prototype.infos];
      newInfos.splice(index, 1, itemInfo);
      const emptyItems = newInfos.filter(({ text }) => text === "");
      if (emptyItems.length === 0) {
        newInfos.push(createItemInfo());
      } else if (itemInfo.text === "" && newInfos[index + 1]?.text === "") {
        focusController.focus(newInfos[index + 1].id);
        newInfos.splice(index, 1);
      }
      updateItemInfos(newInfos);
    },
    [updateItemInfos, createItemInfo, focusController]
  );

  const onItemInfosAppend = useCallback(
    (index: number) => {
      Analytics.trackEvent("Add Item Info");

      const newInfos = [...itemRef.current.prototype.infos];

      const currentItemIsEmpty = newInfos[index].text === "";
      const newInfo = createItemInfo();
      newInfos.splice(
        currentItemIsEmpty ? index : index + 1,
        currentItemIsEmpty ? 1 : 0,
        newInfo
      );

      // ensure there never two empty infos in a row
      for (let i = newInfos.length - 1; i > 0; i--) {
        if (newInfos[i].text === "" && newInfos[i - 1]?.text === "") {
          newInfos.splice(i, 1);
        }
      }

      focusController.focus(newInfo.id);
      updateItemInfos(newInfos);
    },
    [createItemInfo, focusController, updateItemInfos]
  );

  const onItemInfosDelete = useCallback(
    (index: number) => {
      Analytics.trackEvent("Delete Item Info");
      const newInfos = [...itemRef.current.prototype.infos];

      newInfos.splice(index, 1);
      const emptyItems = newInfos.filter(({ text }) => text === "");
      if (emptyItems.length === 0) {
        newInfos.push(createItemInfo());
      }

      focusController.focus(
        newInfos[index - 1]?.id ? newInfos[index - 1].id : newInfos[0].id
      );

      updateItemInfos(newInfos);
    },
    [createItemInfo, focusController, updateItemInfos]
  );

  const onItemInfosReorder = useCallback(
    (oldIndex: number, newIndex: number) => {
      Analytics.trackEvent("Reorder Item Info");
      const newInfos = [...itemRef.current.prototype.infos];
      const [infoItem] = newInfos.splice(oldIndex, 1);
      newInfos.splice(newIndex, 0, infoItem);
      if (newInfos[newInfos.length - 1].text !== "") {
        newInfos.push(createItemInfo());
      }

      // ensure there never two empty infos in a row
      for (let i = newInfos.length - 1; i > 0; i--) {
        if (newInfos[i].text === "" && newInfos[i - 1]?.text === "") {
          newInfos.splice(i, 1);
        }
      }
      updateItemInfos(newInfos);
    },
    [createItemInfo, updateItemInfos]
  );

  const itemTypeSpecificComponent = getItemComponentByType({
    item,
    setItemAction,
    onItemChange,
    onItemAppend,
    onItemDelete,
    onItemValidate,
    onComplete,
    editMode,
    readOnly: readOnly || editMode,
    slideMode,
  });

  const onLongPress = useCallback(() => {
    if (editMode && !readOnly) {
      if (Platform.OS === "ios") {
        void Haptics.impactAsync();
      }
      dragHandlers?.onDragStart();
    }
  }, [editMode, readOnly, dragHandlers]);

  const onPressOut = useCallback(() => {
    if (editMode && !readOnly) {
      dragHandlers?.onDragHandleRelease();
    }
  }, [editMode, readOnly, dragHandlers]);

  const onPress = useMemo(() => {
    if (!editMode && !readOnly) {
      return itemAction;
    }
  }, [editMode, readOnly, itemAction]);
  const setItemRequired = useCallback(
    (required: boolean) => {
      onItemChange?.({
        ...itemRef.current,
        prototype: {
          ...itemRef.current.prototype,
          required,
        },
      });
    },
    [onItemChange]
  );

  const errorMessage =
    // eslint-disable-next-line deprecation/deprecation
    item.validateResult?.error?.message ?? item.errors?.[0]?.message;
  const itemHasError =
    questViewContext.viewContext === "RUN" ||
    questViewContext.touchedMap[`items[${item.prototype.id}]`]
      ? !!errorMessage
      : false;

  if (slideMode) {
    return (
      <ItemContextProvider
        editMode={editMode}
        prototypeId={item.prototype.id}
        position={item.prototype.position}
      >
        <StyledPressable
          testID={`${editMode ? "edit" : "fill"}-item-container`}
          onLongPress={onLongPress}
          onPressOut={onPressOut}
          onPress={onPress}
          disabled={readOnly}
          focusable={false}
          // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
          tabIndex={-1}
        >
          {editMode && (
            <ItemRowHeader
              typeName={`${
                itemTypeViewDataMap[item.prototype.type as ItemType].label ??
                "Unknown"
              }`}
              isRequired={item.prototype.required}
              setRequired={setItemRequired}
              onShowItemOptions={onShowItemOptions}
              onDelete={onItemDelete}
              dragHandlers={dragHandlers}
            />
          )}
          <Content editMode={editMode}>
            {itemTypeSpecificComponent}
            {itemHasError && (
              <ErrorWrapper>
                <ErrorIcon icon="info" />
                <Text size="medium" $warning>
                  {errorMessage}
                </Text>
              </ErrorWrapper>
            )}
            <ItemInfoWrapper editMode={editMode}>
              <ItemInfo
                editMode={false}
                itemPrototypeId={item.prototype.id}
                infos={itemInfos}
              />
            </ItemInfoWrapper>
          </Content>
        </StyledPressable>
      </ItemContextProvider>
    );
  }

  return (
    <ItemContextProvider
      editMode={editMode}
      prototypeId={item.prototype.id}
      position={item.prototype.position}
    >
      <StyledPressable
        onLongPress={onLongPress}
        onPressOut={onPressOut}
        onPress={onPress}
        testID={`${editMode ? "edit" : "fill"}-item-container`}
        focusable={false}
        // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
        tabIndex={-1}
      >
        <ItemCard
          editMode={editMode}
          isDragged={!!isDragged}
          itemHasError={itemHasError}
        >
          {editMode ? (
            <ItemRowHeader
              typeName={
                itemTypeViewDataMap[item.prototype.type as ItemType].label
              }
              onShowItemOptions={onShowItemOptions}
              isRequired={item.prototype.required}
              setRequired={setItemRequired}
              onDelete={onItemDelete}
              dragHandlers={dragHandlers}
            />
          ) : (
            <View
              style={{
                position: "absolute",
                top: -50,
                left: -20,
                height: 40,
                width: 40,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ItemNumberText size="small" itemHasError={itemHasError}>
                {position + 1}
              </ItemNumberText>
            </View>
          )}
          <Content editMode={editMode}>
            {itemTypeSpecificComponent}
            {itemHasError && (
              <ErrorWrapper>
                <ErrorIcon icon="info" />
                <Text size="medium" $warning>
                  {errorMessage}
                </Text>
              </ErrorWrapper>
            )}
            <ItemInfoWrapper editMode={editMode}>
              <ItemInfo
                editMode={editMode}
                onItemInfosChange={onItemInfosChange}
                onItemInfosSubmit={onItemInfosAppend}
                onItemInfosDelete={onItemInfosDelete}
                onItemInfosReorder={onItemInfosReorder}
                itemPrototypeId={item.prototype.id}
                infos={itemInfos}
              />
            </ItemInfoWrapper>
          </Content>
        </ItemCard>
      </StyledPressable>
    </ItemContextProvider>
  );
};

export const ItemCard = styled(Shadow).attrs<StyledViewProps>(
  ({ theme, isDragged }) => ({
    stretch: false,
    startColor: theme.background,
    distance: isDragged ? 5 : 0, // using disabled causes a flicker when drag starts
    paintInside: true,
  })
)<StyledViewProps>`
  ${({ theme, editMode, itemHasError }) => {
    return editMode
      ? `
        border-radius: 20px;
        background-color: ${theme.card.background};
        border-color: ${itemHasError ? theme.warning : theme.card.background};
        overflow: hidden;
    `
      : `
      margin-bottom: 40px;
      border-left-width: 1px;
      border-left-color: ${itemHasError ? theme.warning : theme.primary};
      padding-left: 15px;
      margin-left: 15px;
      margin-right: 15px;
    `;
  }}
  max-width: 540px;
  align-self: center;
  width: 100%;
`;

export const Content = styled.View<ContentProps>`
  ${({ editMode }) =>
    editMode
      ? `
      padding-horizontal: 20px;
      padding-vertical: 20px;
    `
      : ""}
`;

const StyledPressable = styled(BasePressable)`
  ${({ onPress }) =>
    !onPress && Platform.OS === "web" ? `cursor: default` : ``}
`;

export default React.memo(Item, (prev, next) => {
  return (
    equal(prev.item, next.item) &&
    prev.position === next.position &&
    prev.editMode === next.editMode &&
    prev.readOnly === next.readOnly &&
    prev.dragHandlers?.onDragStart === next.dragHandlers?.onDragStart &&
    prev.isDragged === next.isDragged
  );
});

export const ItemInfoWrapper = styled.View<{ editMode: boolean }>`
  ${({ editMode, theme }) => {
    return (
      editMode &&
      `
    border-top-width: 1px;
    border-top-color: ${theme.textInput.normal.border};
    margin-top: 20px;
    padding-top: 10px;
  `
    );
  }}
`;

const ErrorWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const ErrorIcon = styled(ItemInfoIcon)`
  color: ${({ theme }) => theme.warning};
`;

const ItemNumberText = styled(Text)<{ itemHasError?: boolean }>`
  color: ${({ theme, itemHasError }) =>
    itemHasError ? theme.warning : theme.primary};
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
