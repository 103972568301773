import { Text } from "@app/components/questkit/text";
import { QMStackParamList } from "@app/navigation/QMNavigator";
import { StyleProp, ViewStyle } from "react-native";
import React, { useCallback, useMemo } from "react";
import { Route, useNavigationState } from "@react-navigation/native";
import { NavigationState } from "@react-navigation/routers/src/types";
import styled from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import { DeepPartial } from "@questmate/common";
import BasePressable from "@app/components/questkit/BasePressable";
import { createLink } from "@app/util/link.utils";
import isEqual from "react-fast-compare";

type SideBarItemProps<
  SCREEN extends keyof QMStackParamList,
  SCREEN_PARAMS extends QMStackParamList[SCREEN]
> = {
  label: string;
  onPress?: () => void;
  screenName?: SCREEN;
  screenParams?: SCREEN_PARAMS;
  getSameScreenDifferentiatorValue?: (
    screenParams?: DeepPartial<SCREEN_PARAMS>
  ) => unknown;
  disableActiveHighlight?: boolean;
  componentLeft?: React.ReactElement | React.FC<ActiveHoverProps>;
  componentRight?: React.ReactElement | React.FC<ActiveHoverProps>;
  style?: StyleProp<ViewStyle>;
  isActiveOverride?: boolean;
};

const _SideBarItem = <
  SCREEN extends keyof QMStackParamList,
  SCREEN_PARAMS extends QMStackParamList[SCREEN]
>({
  label,
  onPress,
  screenName,
  screenParams,
  getSameScreenDifferentiatorValue,
  componentLeft,
  componentRight,
  disableActiveHighlight,
  isActiveOverride,
  style,
}: SideBarItemProps<SCREEN, SCREEN_PARAMS>) => {
  const [index, routes] = useNavigationState<
    QMStackParamList,
    [number?, NavigationState<QMStackParamList>["routes"]?]
  >((state) => [state?.index, state?.routes]);

  const isActiveItem = useMemo(() => {
    if (isActiveOverride !== undefined) return isActiveOverride;

    const activeRoute =
      Array.isArray(routes) && index !== undefined
        ? routes[index]
        : ({
            name: "Home",
          } as Route<"Home">);

    if (screenName && activeRoute) {
      const screenMatches = screenName === activeRoute.name;

      return (
        screenMatches &&
        (getSameScreenDifferentiatorValue
          ? isEqual(
              getSameScreenDifferentiatorValue(
                activeRoute.params as DeepPartial<SCREEN_PARAMS>
              ),
              getSameScreenDifferentiatorValue(
                screenParams as DeepPartial<SCREEN_PARAMS>
              )
            )
          : true)
      );
    } else {
      return false;
    }
  }, [
    getSameScreenDifferentiatorValue,
    index,
    isActiveOverride,
    routes,
    screenName,
    screenParams,
  ]);

  const onPressHandler = useMemo(() => {
    if (screenName) {
      return createLink({
        screen: screenName,
        params: screenParams,
      });
    } else if (onPress) {
      return onPress;
    }
  }, [onPress, screenName, screenParams]);

  return (
    <SideBarItemRow
      label={label}
      onPressHandler={onPressHandler}
      ComponentLeft={componentLeft}
      ComponentRight={componentRight}
      isActiveItem={isActiveItem}
      disableActiveHighlight={disableActiveHighlight}
      style={style}
    />
  );
};

// hack to get around React.memo not working with generic prop types
export const SideBarItem = React.memo(
  _SideBarItem,
  isEqual
) as typeof _SideBarItem;
(
  SideBarItem as {
    displayName?: string | undefined;
  }
).displayName = "SideBarItem";
const SideBarItemRowPressable = styled(BasePressable)<{
  isActive?: boolean;
  isHovered?: boolean;
}>`
  ${({ theme, isActive, isHovered }) =>
    `
    background-color: ${
      isActive
        ? theme.sideBar.item.active.background
        : isHovered
        ? theme.sideBar.item.hover.background
        : theme.sideBar.item.inactive.background
    }
    `};
  border-radius: 20px;
  flex-direction: row;
  height: 40px;
  align-items: center;
`;

interface SideBarItemRowProps {
  label?: string;
  onPressHandler: (() => void) | undefined;
  ComponentLeft?: React.ReactElement | React.FC<ActiveHoverProps>;
  ComponentRight?: React.ReactElement | React.FC<ActiveHoverProps>;
  isActiveItem: boolean;
  disableActiveHighlight?: boolean;
  style?: StyleProp<ViewStyle>;
}
const _SideBarItemRow: React.FC<SideBarItemRowProps> = ({
  onPressHandler,
  label,
  ComponentLeft,
  ComponentRight,
  isActiveItem,
  disableActiveHighlight = false,
  style,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <SideBarItemRowPressable
      onPress={onPressHandler}
      isHovered={isHovered && !disableActiveHighlight}
      isActive={isActiveItem && !disableActiveHighlight}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      accessibilityRole={"menuitem"}
    >
      {ComponentLeft ? (
        <SideBarItemComponentContainer>
          {isElement(ComponentLeft) ? (
            ComponentLeft
          ) : (
            <ComponentLeft
              isHovered={isHovered && !disableActiveHighlight}
              isActive={isActiveItem && !disableActiveHighlight}
            />
          )}
        </SideBarItemComponentContainer>
      ) : (
        <SideBarItemComponentPlaceholder />
      )}

      {!label ? null : (
        <SideBarItemText
          isHovered={isHovered && !disableActiveHighlight}
          isActive={isActiveItem && !disableActiveHighlight}
          numberOfLines={1}
        >
          {label}
        </SideBarItemText>
      )}
      {ComponentRight ? (
        <SideBarItemComponentContainer>
          {isElement(ComponentRight) ? (
            ComponentRight
          ) : (
            <ComponentRight
              isHovered={isHovered && !disableActiveHighlight}
              isActive={isActiveItem && !disableActiveHighlight}
            />
          )}
        </SideBarItemComponentContainer>
      ) : (
        <SideBarItemComponentPlaceholder />
      )}
    </SideBarItemRowPressable>
  );
};
const SideBarItemRow = React.memo(_SideBarItemRow, isEqual);

const SideBarItemText = styled(Text)<{
  isActive: boolean;
  isHovered: boolean;
}>`
  color: ${({ isActive, isHovered, theme }) =>
    isActive
      ? theme.sideBar.item.active.text
      : isHovered
      ? theme.sideBar.item.hover.text
      : theme.sideBar.item.inactive.text};
  flex: 1;
`;
const SideBarItemComponentContainer = styled.View`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const SideBarItemComponentPlaceholder = styled.View`
  width: 17px;
`;

type ActiveHoverProps = {
  isHovered: boolean;
  isActive: boolean;
};
const _SideBarItemIcon = styled(Icon)<ActiveHoverProps>`
  color: ${({ theme, isActive, isHovered }) =>
    isActive
      ? theme.sideBar.item.active.text
      : isHovered
      ? theme.sideBar.item.hover.text
      : theme.sideBar.item.inactive.text};
`;

export function createSideBarItemRowIcon(
  iconName: IconIdentifier
): React.FC<ActiveHoverProps> {
  const SideBarItemIcon = React.memo((props: ActiveHoverProps) => (
    <_SideBarItemIcon
      icon={iconName}
      size={24}
      isHovered={props.isHovered}
      isActive={props.isActive}
    />
  ));
  SideBarItemIcon.displayName = "SideBarItemIcon";
  return SideBarItemIcon;
}

const isElement = React.isValidElement<
  // hack to use `any` without explicitly using `any`
  React.ReactElement extends React.ReactElement<infer P> ? P : never
>;
