import {
  QuestscriptExecutionListItem,
  RestrictedQuestscriptExecutionListItem,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { compareAsc } from "date-fns";

export type QuestscriptExecution = Omit<
  UnionToIntersection<APIQuestscriptExecution>,
  "runAsUser"
> & {
  runAsUserId: string;
};

const questscriptExecutionAdapter = createEntityAdapter<QuestscriptExecution>({
  sortComparer: (a, b) =>
    compareAsc(new Date(a.startedAt), new Date(b.startedAt)),
});

export const {
  selectById: selectQuestscriptExecutionById,
  selectIds: selectQuestscriptExecutionIds,
  selectEntities: selectAllQuestscriptExecutionsById,
} = questscriptExecutionAdapter.getSelectors<AppState>(
  (state) => state.cache.questscriptExecutions
);

export const selectAllQuestscriptExecutionsSorted = createSelector(
  [selectQuestscriptExecutionIds, selectAllQuestscriptExecutionsById],
  (sortedExecutionIds, questscriptExecutions) =>
    sortedExecutionIds.map((id) => questscriptExecutions[id]!)
);

export const selectQuestscriptExecutionsByTrigger = createSelector(
  [
    selectAllQuestscriptExecutionsSorted,
    (_state, triggerType) => triggerType,
    (_state, _triggerType, triggerIdentifier) => triggerIdentifier,
  ],
  (questscriptExecutions, triggerType, triggerIdentifier) =>
    questscriptExecutions.filter(
      (execution) =>
        execution.triggerType === triggerType &&
        execution.triggerIdentifier === triggerIdentifier
    )
);

export const selectLatestQuestscriptExecution = createSelector(
  [selectQuestscriptExecutionsByTrigger],
  (executions) => executions[executions.length - 1]
);

const slice = createSlice({
  name: "cache/questscriptExecutions",
  initialState: questscriptExecutionAdapter.getInitialState(),
  reducers: {
    questscriptExecutionsLoaded: (
      state,
      action: PayloadAction<
        (
          | QuestscriptExecutionListItem
          | RestrictedQuestscriptExecutionListItem
        )[]
      >
    ) =>
      questscriptExecutionAdapter.upsertMany(
        state,
        action.payload.map((quest) => mapQuestscriptExecution(quest))
      ),
    questscriptExecutionLoaded: (
      state,
      action: PayloadAction<
        QuestscriptExecutionListItem | RestrictedQuestscriptExecutionListItem
      >
    ) =>
      questscriptExecutionAdapter.upsertOne(
        state,
        mapQuestscriptExecution(action.payload)
      ),
  },
});

const reducer = slice.reducer;
export default reducer;

export const { questscriptExecutionsLoaded, questscriptExecutionLoaded } =
  slice.actions;

type APIQuestscriptExecution =
  | QuestscriptExecutionListItem
  | RestrictedQuestscriptExecutionListItem;

const mapQuestscriptExecution = createDataMapper<
  APIQuestscriptExecution,
  QuestscriptExecution
>()(
  [
    "id",
    "status",
    "startedAt",
    "completedAt",
    "value",
    "durationMs",
    "exception",
    "logs",
    "triggerType",
    "triggerIdentifier",
  ],
  {
    runAsUser: ({ id }) => (id ? { runAsUserId: id } : {}),
  }
);
