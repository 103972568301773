import React, { PropsWithChildren } from "react";
import { useSideBarContext } from "@app/navigation/sideBar/SideBarProvider";
import styled from "styled-components/native";
import Animated, {
  interpolate,
  useAnimatedStyle,
} from "react-native-reanimated";

export const SideBarSafeArea: React.FC<PropsWithChildren> = ({ children }) => {
  const { paddingAnimation, sideBarWidth } = useSideBarContext();
  const safeAreaStyle = useAnimatedStyle(() => {
    return {
      paddingLeft: interpolate(
        paddingAnimation.value,
        [0, 1],
        [0, sideBarWidth]
      ),
    };
  }, [sideBarWidth]);
  return (
    <SideBarSafeAreaContainer style={safeAreaStyle}>
      {children}
    </SideBarSafeAreaContainer>
  );
};
const SideBarSafeAreaContainer = styled(Animated.View)`
  flex: 1;
`;
