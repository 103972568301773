import React, { useCallback, useRef } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import TextInput from "@app/components/questkit/textInput";
import styled from "styled-components/native";
import { useFocusableRef } from "@app/util/focus";

const changeEmail = (item: ItemRenderData, email: string): ItemRenderData => ({
  ...item,
  data: {
    value: email,
  },
  version: item.version + 1,
});

export const EmailItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, onComplete, editMode, readOnly } =
    props;
  const email = item.data.value;
  const focusRefCallback = useFocusableRef(item.prototype.id);
  const itemRef = useRef(item);
  itemRef.current = item;

  const onChangeEmail = useCallback(
    (email: string) => {
      onItemChange?.(changeEmail(itemRef.current, email));
    },
    [onItemChange]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <StyledTextInput
          ref={focusRefCallback}
          value={email}
          onChangeText={onChangeEmail}
          onBlur={onItemValidate}
          onSubmitEditing={onComplete}
          autoComplete="email"
          inputMode="email"
          leftIcon="mail"
          placeholder={
            editMode ? "Email address will be written here." : "john@doe.com"
          }
          editable={!readOnly}
          accessibilityLabelledBy={`item-name-label-${item.prototype.id}`}
        />
      }
    />
  );
};

const StyledTextInput = styled(TextInput)``;
