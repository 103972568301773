import _, { DebouncedFunc } from "lodash";

type DebounceParameters = Parameters<typeof _.debounce>;

/*
 * This class brings the key functionality to lodash debounce.
 * AwesomeDebouncePromise has the key functionality, but does not support {leading: true, trailing: false}
 * as an option like Lodash does. In the context of sending Analytics events, this is
 * necessary to maintain the order of events.
 */
export class KeyedDebouncer<T extends DebounceParameters[0]> {
  private debounceFnMap: Record<string, DebouncedFunc<T>> = {};

  constructor(
    private readonly functionToDebounce: T,
    private readonly waitTime: DebounceParameters[1],
    private readonly options: DebounceParameters[2]
  ) {}

  get(debounceKey: string): DebouncedFunc<T> {
    if (this.debounceFnMap[debounceKey]) {
      return this.debounceFnMap[debounceKey];
    }

    const debounceFn = _.debounce(
      this.functionToDebounce,
      this.waitTime,
      this.options
    );
    this.debounceFnMap[debounceKey] = debounceFn;
    return debounceFn;
  }
}
