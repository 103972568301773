import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  Platform,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import { ButtonType } from "@app/themes/QuestmateTheme";
import BasePressable, {
  extendOnPress,
  OnPress,
  PressEvent,
} from "@app/components/questkit/BasePressable";
import { colors } from "@app/themes/Colors";
import Icon from "@app/components/icon";
import Button from "@app/components/questkit/button";
import { TouchBoundary } from "@app/util/TouchBoundary";

export interface SplitButtonAction {
  title: string;
  buttonType: ButtonType;
  onPress: OnPress | ((e: PressEvent) => Promise<unknown>);
  testID?: string;
  key?: string;
}

export interface SplitButtonProps {
  testID?: string;
  actions: SplitButtonAction[];
  style?: StyleProp<ViewStyle>;
}
export const SplitButton: React.FC<SplitButtonProps> = ({
  testID,
  actions: _actions,
  style,
}) => {
  const closeSecondaryActions = useCallback(() => {
    if (Platform.OS === "web") {
      // iOS has bad behavior for this right now. Better to disable
      setShowSecondaryActions(false);
    }
  }, []);
  const [loadingActionKey, setLoadingActionKey] = useState<string | null>(null);
  const [successActionKey, setSuccessActionKey] = useState<string | null>(null);
  const [lastFocusedActionKey, setLastFocusedActionKey] = useState<
    string | null
  >(null);

  const focusedActionKey = loadingActionKey || successActionKey;

  const focusAnimation = useRef(new Animated.Value(0)).current;

  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    } else if (focusedActionKey !== null) {
      setLastFocusedActionKey(focusedActionKey);
      Animated.timing(focusAnimation, {
        toValue: 2,
        duration: 300,
        useNativeDriver: Platform.OS !== "web",
      }).start();
    } else {
      Animated.timing(focusAnimation, {
        toValue: 0,
        duration: 300,
        useNativeDriver: Platform.OS !== "web",
      }).start(({ finished }) => {
        if (finished) {
          setLastFocusedActionKey(null);
        }
      });
    }
  }, [focusAnimation, focusedActionKey]);

  const actions = useMemo(
    () =>
      _actions.map((action, index) => {
        const actionKey = action.key ?? index.toString();
        action = { ...action, key: actionKey };
        if (action.onPress && "linkProps" in action.onPress) {
          // Close secondary actions before navigating when a link is pressed.
          return {
            ...action,
            onPress: extendOnPress(action.onPress, closeSecondaryActions),
          };
        } else if (action.onPress && typeof action.onPress === "function") {
          return {
            ...action,
            onPress: (e: PressEvent) => {
              const result = action.onPress!(e);
              if (
                !!result &&
                "then" in result &&
                "catch" in result &&
                "finally" in result
              ) {
                setLoadingActionKey(actionKey);
                result
                  .then((response) => {
                    if (
                      !!response &&
                      typeof response === "object" &&
                      "success" in response &&
                      !response.success
                    ) {
                      return;
                    }
                    setSuccessActionKey(actionKey);

                    // action.success = true;
                  })
                  .catch(() => {
                    // action.success = false;
                  })
                  .finally(() => {
                    if (index === 0) {
                      closeSecondaryActions();
                    }
                    setLoadingActionKey(null);
                  });
              } else {
                closeSecondaryActions();
              }
            },
          };
        }
        return action;
      }),
    [_actions, closeSecondaryActions]
  );

  const [showSecondaryActions, setShowSecondaryActions] = useState(false);
  const toggleSecondaryActions = useCallback(() => {
    setShowSecondaryActions((show) => !show);
  }, []);

  const primaryAction = actions[0];
  const secondaryActions = actions.slice(1);
  const hasSecondaryActions = secondaryActions.length > 0;
  return !primaryAction ? null : (
    <ButtonWrap
      testID={testID}
      onTouchOutside={closeSecondaryActions}
      style={[style]}
    >
      <AnimatedButtonWrap
        style={{
          opacity: focusAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [
              1,
              (focusedActionKey || lastFocusedActionKey) === primaryAction.key
                ? 1
                : 0.00001,
            ],
          }),
        }}
      >
        <Button
          testID={primaryAction.testID ?? `${primaryAction.title} button`}
          // icon={"play"}
          onPress={primaryAction.onPress}
          buttonType={primaryAction.buttonType}
          disabled={loadingActionKey !== null}
          title={primaryAction.title}
          loading={loadingActionKey === primaryAction.key}
          success={successActionKey === primaryAction.key}
        />
      </AnimatedButtonWrap>
      {hasSecondaryActions ? (
        <SecondaryActionsToggleSection
          style={{
            opacity: focusAnimation.interpolate({
              inputRange: [0, 0.3],
              outputRange: [1, 0.00001],
            }),
          }}
        >
          <VerticalDivider />
          <SecondaryActionsToggle
            testID={`${testID || "split-button"}-secondary-actions-toggle`}
            onPress={toggleSecondaryActions}
            disabled={loadingActionKey !== null}
            accessibilityRole={"spinbutton"}
          >
            <ToggleIcon
              icon={showSecondaryActions ? "chevron-up" : "chevron-down"}
            />
          </SecondaryActionsToggle>
        </SecondaryActionsToggleSection>
      ) : null}
      {showSecondaryActions ? (
        <SecondaryActionsSection>
          {secondaryActions.reverse().map((action, index) => (
            <AnimatedButtonWrap
              key={action.key}
              testID={"secondary-action-button-container"}
              style={{
                marginBottom: 10,
                opacity: focusAnimation.interpolate({
                  inputRange: [0, 1],
                  outputRange: [
                    1,
                    (focusedActionKey || lastFocusedActionKey) === action.key
                      ? 1
                      : 0.00001,
                  ],
                }),
                transform: [
                  {
                    translateY: focusAnimation.interpolate({
                      inputRange: [0, 2],
                      outputRange: [
                        0,
                        (focusedActionKey || lastFocusedActionKey) ===
                        action.key
                          ? (secondaryActions.length - index) *
                            (buttonHeight + 10)
                          : 0,
                      ],
                    }),
                  },
                ],
              }}
            >
              <Button
                testID={action.testID ?? `${action.title} button`}
                onPress={action.onPress}
                buttonType={action.buttonType}
                title={action.title}
                disabled={loadingActionKey !== null}
                loading={loadingActionKey === action.key}
                success={successActionKey === action.key}
              />
            </AnimatedButtonWrap>
          ))}
        </SecondaryActionsSection>
      ) : null}
    </ButtonWrap>
  );
};

const buttonHeight = 40;

const SecondaryActionsSection = styled.View`
  position: absolute;
  right: 0;
  left: 0;
  bottom: ${buttonHeight}px;
`;

const AnimatedButtonWrap = styled(Animated.View)`
  width: 100%;
`;

const SecondaryActionsToggle = styled(BasePressable)`
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: center;
`;
const ToggleIcon = styled(Icon)`
  color: ${colors.d5_darkPrimary};
`;

const VerticalDivider = styled.View`
  height: ${buttonHeight - 16}px;
  border-left-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${() => colors.green800};
  margin-top: 8px;
`;
const SecondaryActionsToggleSection = styled(Animated.View)`
  position: absolute;
  flex-direction: row;
  height: ${buttonHeight}px;
  justify-content: center;
  width: 41px;
  top: -1px;
  right: -1px;
`;

const ButtonWrap = styled(TouchBoundary)`
  width: 100%;
  min-width: 100px;
  height: ${buttonHeight}px;
  align-items: center;
  align-self: center;
  position: relative;
`;
