import { Asset } from "expo-asset";
import * as Font from "expo-font";

async function loadResources(): Promise<void> {
  await Promise.all([
    Font.loadAsync({
      // QuestmateIcon is also loaded where we `createIconSet`. Putting it here ensures
      // it is loaded before we dismiss the splash screen.
      QuestmateIcon: require("@assets/fonts/QuestmateIcon.ttf"),
      "CentraNo2-Bold": require("@assets/fonts/CentraNo2-Bold.otf"),
      "CentraNo2-Book": require("@assets/fonts/CentraNo2-Book.otf"),
      "CentraNo2-Medium": require("@assets/fonts/CentraNo2-Medium.otf"),
    }),
    ...[
      require("@assets/icon.png"),
      require("@assets/icons/logo_purple.png"),
      require("@assets/icons/logo_white.png"),
      require("@assets/images/placeholder/quest_dark.png"),
      require("@assets/images/placeholder/quest_light.png"),
    ].map((image) => Asset.fromModule(image).downloadAsync()),
  ]);
}

export default loadResources;
