import jexl from "jexl";
import Ast from "jexl/Ast";
import {
  QuestDataContextKey,
  QuestDataContextKeyList,
} from "@questmate/questscript";

export function identifyKnownExpression(expression: string) {
  try {
    const exp = jexl.createExpression(expression);
    const abstractSyntaxTree = exp._getAst();

    const parts = convertTreeToOrderedListOfParts(abstractSyntaxTree);

    return {
      parts,
    };
  } catch (error) {
    console.error("Error identifying expression", error);
    return null;
  }
}

// TODO: Connect these to the context type in api/questscript.
type DataType = "value" | "string" | "unknown";
type ContextDataExpressionPart = {
  type: "contextData";
  identifier: QuestDataContextKey;
  dataType: DataType;
};
type ItemExpressionPart = {
  type: "item";
  identifier: { id: string } | { position: number };
  dataType: DataType;
};
type OperationExpressionPart = {
  type: "operation";
  name: string;
};
type IdentifiersExpressionPart = {
  type: "identifiers";
  identifiers: string[];
};
export type ExpressionPart =
  | ItemExpressionPart
  | ContextDataExpressionPart
  | OperationExpressionPart
  | IdentifiersExpressionPart;

function getDataTypeFromNextExpressionPart(parts: ExpressionPart[]) {
  let nextPart: ExpressionPart | null = parts[0];
  let dataType: ItemExpressionPart["dataType"] = "unknown";
  if (nextPart?.type === "identifiers" && nextPart.identifiers.length > 0) {
    if (
      nextPart.identifiers.length === 1 &&
      nextPart.identifiers[0] === "stringValue"
    ) {
      dataType = "string";
      nextPart = null;
    } else if (
      nextPart.identifiers[0] === "rawValue" ||
      nextPart.identifiers[0] === "data"
    ) {
      dataType = nextPart.identifiers[0] === "rawValue" ? "value" : "unknown";
      if (nextPart.identifiers.length === 1) {
        nextPart = null;
      } else {
        nextPart = {
          type: "identifiers",
          identifiers: nextPart.identifiers.slice(1),
        };
      }
    }
  }
  return { nextPart, dataType };
}

function convertTreeToOrderedListOfParts(
  abstractSyntaxTree: Ast | undefined,
  parts: ExpressionPart[] = []
) {
  if (!abstractSyntaxTree) {
    return parts;
  }

  let remainingTree = undefined;
  mainSwitch: switch (abstractSyntaxTree.type) {
    case "Identifier": {
      if (
        QuestDataContextKeyList.includes(
          abstractSyntaxTree.value as QuestDataContextKey
        )
      ) {
        const { nextPart, dataType } = getDataTypeFromNextExpressionPart(parts);
        parts = [
          {
            type: "contextData",
            identifier: abstractSyntaxTree.value as QuestDataContextKey,
            dataType,
          },
          ...(nextPart ? [nextPart] : []),
          ...parts.slice(1),
        ];
      } else {
        let identifiers: string[];
        ({ identifiers, remainingTree } =
          constructFullIdentifier(abstractSyntaxTree));
        parts = [
          {
            type: "identifiers",
            identifiers,
          },
          ...parts,
        ];
      }
      break;
    }
    case "FilterExpression": {
      const isItemSubject =
        abstractSyntaxTree.subject.type === "Identifier" &&
        abstractSyntaxTree.subject.value === "items";
      if (isItemSubject) {
        const { nextPart, dataType } = getDataTypeFromNextExpressionPart(parts);
        parts = [
          {
            type: "item",
            identifier:
              abstractSyntaxTree.expr.type === "Literal"
                ? typeof abstractSyntaxTree.expr.value === "number"
                  ? { position: abstractSyntaxTree.expr.value }
                  : typeof abstractSyntaxTree.expr.value === "string"
                  ? { id: abstractSyntaxTree.expr.value }
                  : { id: "unknown" }
                : { id: "unknown" },
            dataType,
          },
          ...(nextPart ? [nextPart] : []),
          ...parts.slice(1),
        ];
      } else {
        remainingTree = abstractSyntaxTree.subject;
        parts = [
          {
            type: "operation",
            name: "FilterExpression",
          },
          ...parts,
        ];
      }
      break;
    }
    default: {
      let name;
      switch (abstractSyntaxTree.type) {
        case "FunctionCall": {
          name = abstractSyntaxTree.name;
          if (abstractSyntaxTree.pool === "transforms") {
            remainingTree = abstractSyntaxTree.args[0];
          }
          break;
        }
        case "BinaryExpression": {
          name = abstractSyntaxTree.operator;
          remainingTree = abstractSyntaxTree.left;
          break;
        }
        case "ConditionalExpression": {
          name = "ternary";
          remainingTree = abstractSyntaxTree.test;
          break;
        }
        default:
          break mainSwitch;
      }
      parts = [
        {
          type: "operation",
          name,
        },
        ...parts,
      ];
      break;
    }
  }

  return convertTreeToOrderedListOfParts(remainingTree, parts);
}
function constructFullIdentifier(
  abstractSyntaxTree: Ast | undefined,
  identifiers: string[] = []
) {
  if (
    abstractSyntaxTree?.type !== "Identifier" ||
    QuestDataContextKeyList.includes(
      abstractSyntaxTree.value as QuestDataContextKey
    )
  ) {
    return { identifiers, remainingTree: abstractSyntaxTree };
  }

  return constructFullIdentifier(abstractSyntaxTree.from, [
    abstractSyntaxTree.value,
    ...identifiers,
  ]);
}

export type PillContext = {
  items: { prototype: { id: string; name: string } }[];
};

export function labelForExpressionParts(
  pillContext: PillContext,
  expressionParts: ExpressionPart[]
): { label: string; dataType: DataType } {
  let label = "";
  let dataType: DataType = "unknown";

  for (let i = 0; i < expressionParts.length; i++) {
    const part = expressionParts[i];

    switch (part.type) {
      case "item": {
        dataType = part.dataType;
        const matchingItem = pillContext.items.find((item, index) => {
          if ("id" in part.identifier) {
            return item.prototype.id.split(":")[1] === part.identifier.id;
          } else if ("position" in part.identifier) {
            return index === part.identifier.position;
          }
          return false;
        });
        if (matchingItem) {
          label += matchingItem.prototype.name;
        } else {
          label += "Unknown Item";
        }
        break;
      }
      case "contextData": {
        dataType = part.dataType;
        switch (part.identifier) {
          case "runName":
            label += "Quest Run Name";
            break;
          case "completedAt":
            label += "Quest Completion Time";
            break;
          case "submittedBy":
            {
              label += `Submitter`;
              if (part.dataType === "string") {
                label += " Name";
              } else if (part.dataType === "value") {
                dataType = "string";
                label += " User ID";
              }
            }
            break;
          default: {
            (function (_partExhaustiveCheck: never) {
              label += "Unknown Context Data";
            })(part);
            break;
          }
        }
        break;
      }
      case "operation": {
        // Stop building label and exit early.
        return { label, dataType: "unknown" as const };
      }

      case "identifiers": {
        label += (i > 0 ? " -> " : "") + part.identifiers.join(" -> ");
        break;
      }
    }
  }

  return { label, dataType };
}
