import {
  QuestStartTriggerDetail,
  Reviewer as ReviewerResponse,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { selectAllUsers, User } from "@app/store/cache/users";
import { AppState } from "@app/store";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { userLogout } from "@app/store/auth";
import { UnionToIntersection } from "@questmate/common";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { questStartTriggerLoaded } from "@app/store/cache/questStartTriggers";
import isEqual from "react-fast-compare";
import { scheduledQuestStartListLoaded } from "@app/store/cache/scheduledQuestStarts";

type Reviewer = Omit<
  UnionToIntersection<APIReviewer>,
  "user" | "startConfiguration"
> & {
  userId: string;
  startConfigurationId: string;
};

const reviewersAdapter = createEntityAdapter<Reviewer>();

export const { selectById: selectReviewerById, selectAll: selectAllReviewers } =
  reviewersAdapter.getSelectors<AppState>((state) => state.cache.reviewers);

export const selectReviewersWithUserByStartConfigurationId = createSelector(
  [
    (state, startConfigurationId) =>
      selectQuestStartConfigurationById(state, startConfigurationId),
    selectAllReviewers,
    (state) => selectAllUsers(state),
  ],
  (startConfiguration, reviewers, users) => {
    return startConfiguration?.reviewerIds
      ?.map((id) => {
        const reviewer = reviewers.find((a) => a.id === id);
        if (!reviewer) {
          return undefined;
        }
        return {
          ...reviewer,
          user: users.find((u) => u.id === reviewer.userId)!,
        };
      })
      .filter((u): u is Reviewer & { user: User } => u !== undefined);
  }
);

export const selectReviewersForStartConfigurationById = createSelector(
  [(_state, startConfigurationId) => startConfigurationId, selectAllReviewers],
  (startConfigurationId, reviewers) => {
    return reviewers
      .filter((a) => a.startConfigurationId === startConfigurationId)
      .reduce((acc, reviewer) => {
        acc[reviewer.id] = reviewer;
        return acc;
      }, {} as Record<string, typeof reviewers[number]>);
  },
  { memoizeOptions: { resultEqualityCheck: isEqual } }
);
const slice = createSlice({
  name: "cache/reviewers",
  initialState: reviewersAdapter.getInitialState(),
  reducers: {
    reviewersAdded: (
      state,
      action: PayloadAction<{
        reviewers: ReviewerResponse[];
      }>
    ) =>
      reviewersAdapter.addMany(
        state,
        action.payload.reviewers.map((r) => mapReviewer(r))
      ),
    reviewerRemoved: (state, action: PayloadAction<ReviewerResponse>) =>
      reviewersAdapter.removeOne(state, action.payload.id),
  },
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) =>
        reviewersAdapter.upsertMany(
          state,
          action.payload.startTriggers.flatMap(({ startConfiguration }) =>
            startConfiguration.reviewers.map((reviewer) =>
              mapReviewer({
                ...reviewer,
                startConfiguration,
              })
            )
          )
        )
      )
      .addCase(questStartTriggerLoaded, (state, action) => {
        const startConfiguration =
          action.payload.startTrigger.startConfiguration;
        reviewersAdapter.upsertMany(
          state,
          startConfiguration.reviewers.map((reviewer) =>
            mapReviewer({
              ...reviewer,
              startConfiguration,
            })
          )
        );
      })
      .addCase(scheduledQuestStartListLoaded, (state, action) => {
        const { scheduledQuestStarts } = action.payload;
        reviewersAdapter.upsertMany(
          state,
          scheduledQuestStarts.flatMap((start) =>
            start.startConfiguration.reviewers.map((reviewer) =>
              mapReviewer({
                ...reviewer,
                startConfiguration: start.startConfiguration,
              })
            )
          )
        );
      })
      .addCase(userLogout, (state) => reviewersAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

export const { reviewersAdded, reviewerRemoved } = slice.actions;

type APIReviewer =
  | (QuestStartTriggerDetail["startConfiguration"]["reviewers"][number] & {
      startConfiguration: { id: string };
    })
  | ReviewerResponse;

const mapReviewer = createDataMapper<APIReviewer, Reviewer>()(["id"], {
  user: ({ id }) => ({ userId: id }),
  startConfiguration: ({ id }) => ({ startConfigurationId: id }),
});
