import { Quests, ScheduledQuestStartListItem } from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection, isNotUndefined } from "@questmate/common";
import { selectQuestById } from "@app/store/cache/quests";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { selectAssignmentsForStartConfigurationById } from "@app/store/cache/assignments";
import { selectReviewersForStartConfigurationById } from "@app/store/cache/reviewers";

type ScheduledQuestStart = Omit<
  UnionToIntersection<APIScheduledQuestStart>,
  "startConfiguration" | "quest"
> & {
  startConfigurationId: string;
  questId: string;
  deleted?: boolean;
};

const scheduledQuestStartAdapter = createEntityAdapter<ScheduledQuestStart>();

export const {
  selectById: selectScheduledQuestStartById,
  selectEntities: selectAllScheduledQuestStartsById,
  selectAll: selectAllScheduledQuestStarts,
} = scheduledQuestStartAdapter.getSelectors<AppState>(
  (state) => state.cache.scheduledQuestStarts
);

export const selectScheduledQuestStartsByQuestId = createSelector(
  [
    (_state, templateId) => templateId,
    selectAllScheduledQuestStarts,
    (_state, _templateId, includeDeleted = false) => includeDeleted,
  ],
  (questId, allScheduledQuestStarts, includeDeleted) => {
    return allScheduledQuestStarts
      .filter(
        (start) =>
          start?.questId === questId && (includeDeleted || !start?.deleted)
      )
      .sort((a, b) => {
        if (b.startAt < a.startAt) {
          return -1;
        }
        if (b.startAt > a.startAt) {
          return 1;
        }
        if (b.id < a.id) {
          return -1;
        }
        if (b.id > a.id) {
          return 1;
        }
        return 0;
      });
  }
);
export const selectScheduledQuestStartNameById = createSelector(
  [
    (state, scheduledQuestStartId) =>
      selectScheduledQuestStartById(state, scheduledQuestStartId)?.runName,
    (state, scheduledQuestStartId) => {
      const scheduledQuestStart = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      );
      if (!scheduledQuestStart?.questId) {
        return;
      }
      const currentQuestPrototypeId = selectQuestById(
        state,
        scheduledQuestStart.questId
      )?.currentQuestPrototypeId;
      if (!currentQuestPrototypeId) {
        return;
      }
      return selectQuestPrototypeById(state, currentQuestPrototypeId)?.name;
    },
  ],
  (scheduledStartRunName, questName) => {
    return scheduledStartRunName ?? questName ?? "";
  }
);
export const selectAssignmentUserIdsForScheduledQuestStart = createSelector(
  [
    (state, scheduledQuestStartId) => {
      const startConfigurationId = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      )?.startConfigurationId;
      if (!startConfigurationId) {
        return;
      }
      return selectQuestStartConfigurationById(state, startConfigurationId)
        ?.assignmentIds;
    },
    (state, scheduledQuestStartId) => {
      const startConfigurationId = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      )?.startConfigurationId;
      if (!startConfigurationId) {
        return {};
      }
      return selectAssignmentsForStartConfigurationById(
        state,
        startConfigurationId
      );
    },
  ],
  (assignmentIds, assignmentsById) => {
    if (!Array.isArray(assignmentIds)) {
      return;
    }
    return assignmentIds
      .map((assignmentId) => assignmentsById[assignmentId]?.assigneeId)
      .filter(isNotUndefined);
  }
);
export const selectReviewerUserIdsForScheduledQuestStart = createSelector(
  [
    (state, scheduledQuestStartId) => {
      const startConfigurationId = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      )?.startConfigurationId;
      if (!startConfigurationId) {
        return;
      }
      return selectQuestStartConfigurationById(state, startConfigurationId)
        ?.reviewerIds;
    },
    (state, scheduledQuestStartId) => {
      const startConfigurationId = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      )?.startConfigurationId;
      if (!startConfigurationId) {
        return {};
      }
      return selectReviewersForStartConfigurationById(
        state,
        startConfigurationId
      );
    },
  ],
  (reviewerIds, reviewersById) => {
    if (!Array.isArray(reviewerIds)) {
      return;
    }
    return reviewerIds
      .map((reviewerId) => reviewersById[reviewerId]?.userId)
      .filter(isNotUndefined);
  }
);

const slice = createSlice({
  name: "cache/scheduledQuestStarts",
  initialState: scheduledQuestStartAdapter.getInitialState(),
  reducers: {
    scheduledQuestStartListLoaded: (
      state,
      action: PayloadAction<{
        questId: string;
        scheduledQuestStarts: Quests.ScheduledStartsDetail.ResponseBody;
      }>
    ) => {
      scheduledQuestStartAdapter.upsertMany(
        state,
        action.payload.scheduledQuestStarts.map((start) =>
          mapScheduledQuestStart({
            ...start,
            quest: { id: action.payload.questId },
          })
        )
      );
    },
    scheduledQuestStartDeleted: (
      state,
      action: PayloadAction<{
        questId: string;
        deletedScheduledQuestStartId: string;
      }>
    ) => {
      scheduledQuestStartAdapter.updateOne(state, {
        id: action.payload.deletedScheduledQuestStartId,
        changes: {
          deleted: true,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) =>
      scheduledQuestStartAdapter.removeAll(state)
    );
  },
});

const reducer = slice.reducer;
export default reducer;

export const { scheduledQuestStartListLoaded, scheduledQuestStartDeleted } =
  slice.actions;

type APIScheduledQuestStart =
  | ScheduledQuestStartListItem & { quest: { id: string } };

const mapScheduledQuestStart = createDataMapper<
  APIScheduledQuestStart,
  ScheduledQuestStart
>()(["id", "createdAt", "startAt", "runExternalId", "runName"], {
  startConfiguration: (startConfiguration) =>
    startConfiguration.id
      ? { startConfigurationId: startConfiguration.id! }
      : {},
  quest: (quest) => (quest.id ? { questId: quest.id! } : {}),
});
