import React, { useMemo } from "react";
import { useAppSelector } from "@app/store";
import { selectLoggedInUser } from "@app/store/cache/users";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import linkingConfig from "@app/navigation/linkingConfig";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { logOut } from "@app/screens/LogoutScreen";
import { Text } from "@app/components/questkit/text";
import styled from "styled-components/native";
import isEqual from "react-fast-compare";

type InvalidAuthDomainProps = {
  allowedAuthDomains?: string[];
  reload: () => void;
};
export const InvalidAuthDomainView: React.FC<InvalidAuthDomainProps> = ({
  allowedAuthDomains,
  reload,
}) => {
  const loggedInUser = useAppSelector(selectLoggedInUser, isEqual);
  const navigation = useAppNavigation();
  const rootState = navigation.getParent()?.getState() ?? navigation.getState();

  const pathBackToHere = useMemo(() => {
    const pathFromState = linkingConfig.getPathFromState(rootState);

    const urlWithoutLoginId = new URL(pathFromState, "https://www.example.com");
    urlWithoutLoginId.searchParams.delete("loginId");
    return urlWithoutLoginId.pathname + urlWithoutLoginId.search;
  }, [rootState]);

  return (
    <PlaceholderView
      testID="invalid-auth-domain-view"
      text="Access Denied 😬"
      additionalInfo={
        <>
          {Array.isArray(allowedAuthDomains) ? (
            <AllowedDomainListContainer>
              <CenteredText size="medium">
                This Quest is limited to users logged into Questmate with an
                email address from{" "}
                {allowedAuthDomains.length === 1
                  ? `the following domain:`
                  : `one of the following domains:`}
              </CenteredText>
              {allowedAuthDomains.map((domain, index) => (
                <Text key={index} size="mediumBold">
                  {domain}
                </Text>
              ))}
            </AllowedDomainListContainer>
          ) : null}
          <CenteredText size="medium">
            You are currently logged in as:
          </CenteredText>
          <Text size="mediumBold">
            {loggedInUser?.email || loggedInUser?.phone}
          </Text>
        </>
      }
      actions={[
        {
          type: "primary" as const,
          text: "Sign in with another account",
          onPress: () => {
            logOut({
              reason: "Access Denied. Switching to another account.",
              screenName: "Login",
              screenParams: {
                loginId: undefined,
                url: pathBackToHere,
              },
            });
          },
        },
        {
          type: "secondary",
          text: "Try again",
          onPress: reload,
        },
      ]}
    />
  );
};

const AllowedDomainListContainer = styled.View`
  margin-bottom: 20px;
  align-items: center;
  max-width: 500px;
`;
const CenteredText = styled(Text)`
  text-align: center;
`;
