import { TemplateListItem } from "@questmate/openapi-spec";

export const groupTemplatesByWorkspace = (
  templates: TemplateListItem[]
): {
  templatesByWorkspaceId: { [key: string]: TemplateListItem[] };
  workspaces: TemplateListItem["workspace"][];
} => {
  const templatesByWorkspaceId = {
    private: [],
  } as { [key: string]: TemplateListItem[] };
  const workspaces = [{ name: "Personal", id: "private" }] as Exclude<
    TemplateListItem["workspace"],
    null
  >[];

  templates.forEach((template) => {
    // Add workspace to workspaces[] if not existing yet
    if (template.workspace) {
      if (
        template.workspace?.id &&
        !workspaces.find(({ id }) => id === template.workspace!.id)
      ) {
        workspaces.push(template.workspace);
      }
    }

    // Sort in template into templatesByWorkspaceId
    const workspaceId = template.workspace ? template.workspace.id : "private";
    if (templatesByWorkspaceId[workspaceId]) {
      templatesByWorkspaceId[workspaceId].push(template);
    } else {
      templatesByWorkspaceId[workspaceId] = [template];
    }
  });

  workspaces.sort((a, b) => a.name.localeCompare(b.name, undefined, {}));

  return {
    templatesByWorkspaceId,
    workspaces,
  };
};
