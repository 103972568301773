import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from "react";
import styled, { DefaultTheme, ThemeProps } from "styled-components/native";
import BaseTextInput, { BaseTextInputProps } from "@app/components/textInput";
import { Platform, TextInput as RNTextInput } from "react-native";

export interface QKTextProps {
  size?: keyof typeof TEXT_SIZES;
  $warning?: boolean;
  $inactive?: boolean;
  $inverted?: boolean;
  $underlined?: boolean;
  numberOfLines?: number;
}

export interface QKTextInputProps extends QKTextProps, BaseTextInputProps {
  $editable?: boolean;
}

export const TEXT_SIZES = Object.freeze({
  small: {
    size: 16,
    family: "CentraNo2-Book",
  },
  smallBold: {
    size: 16,
    family: "CentraNo2-Bold",
  },
  smallMedium: {
    size: 16,
    family: "CentraNo2-Medium",
  },
  medium: {
    size: 18,
    family: "CentraNo2-Book",
  },
  mediumBold: {
    size: 18,
    family: "CentraNo2-Medium",
  },
  mediumLargeBold: {
    size: 20,
    family: "CentraNo2-Medium",
  },
  large: {
    size: 26,
    family: "CentraNo2-Medium",
  },
  xlarge: {
    size: 50,
    family: "CentraNo2-Bold",
  },
});

export const LINE_HEIGHT_RATIO = 1.2;
const genStyle = (props: QKTextProps & ThemeProps<DefaultTheme>) => {
  const color = colorForProps(props);
  const size = props.size ?? "medium";
  return `
    color: ${color};
    font-size: ${TEXT_SIZES[size].size}px;
    font-family: ${TEXT_SIZES[size].family};
    line-height: ${TEXT_SIZES[size].size * LINE_HEIGHT_RATIO}px;
    margin: 0px;
    padding: 0px;
    ${
      props.numberOfLines === 1 && Platform.OS === "web"
        ? "white-space: nowrap;"
        : ""
    }
    `;
};

function colorForProps(props: QKTextProps & ThemeProps<DefaultTheme>) {
  return props.$inactive
    ? props.theme.inactive
    : props.$warning
    ? props.theme.warning
    : props.$inverted
    ? props.theme.background
    : props.theme.primary;
}

export const Text = styled.Text<QKTextProps>`
  ${(props) => genStyle(props)}
  ${(props) =>
    props.$underlined
      ? `text-decoration: underline;
         text-decoration-color: ${colorForProps(props)};`
      : ""}
`;
export const TextInput: ForwardRefExoticComponent<
  PropsWithoutRef<QKTextInputProps> & RefAttributes<RNTextInput>
> = styled(BaseTextInput).attrs<QKTextInputProps, QKTextInputProps>(
  ({ theme, placeholderTextColor, selectionColor }) => ({
    placeholderTextColor: placeholderTextColor ?? theme.inactive,
    selectionColor: selectionColor ?? theme.primary,
  })
)<QKTextInputProps>`
  ${(props) => genStyle(props)}
  ${Platform.OS === "web" && `outline-style: none;`}
`;

export default Text;
