import React, { useCallback } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  PressableProps,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import styled from "styled-components/native";

import { Text, TextInput } from "@app/components/questkit/text";
import Icon, { IconIdentifier, isIconIdentifier } from "@app/components/icon";
import { useFocusableRef } from "@app/util/focus";
import BasePressable from "@app/components/questkit/BasePressable";
import { OnLinkPress } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

export interface ListItemProps extends PressableProps {
  actionIcon?: IconIdentifier;
  actionLoading?: boolean;
  actionComponent?: React.ReactElement;
  autoFocus?: boolean;
  editable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  icon?: IconIdentifier | React.ReactElement;
  onActionIconClick?: () => void;
  onPress?: (() => void) | OnLinkPress;
  onTextChange?: (text: string) => void;
  onKeyPress?: (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => void;
  onSubmitEditing?: () => void;
  text: string | React.ReactElement;
  id?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  actionIcon,
  actionLoading,
  actionComponent,
  autoFocus,
  editable,
  icon,
  onActionIconClick,
  onKeyPress,
  onPress,
  onSubmitEditing,
  onTextChange,
  text,
  id,
  placeholder,
  disabled = false,
  ...props
}) => {
  const textInputRef = id ? useFocusableRef(id) : null;

  const onChangeTextHandler = useCallback(
    (text: string) => {
      onTextChange?.(text);
    },
    [onTextChange]
  );

  return (
    <ListItemEntry
      focusable={false}
      {...props}
      onPress={onPress}
      cursor={disabled || !onPress ? "default" : "pointer"}
      disabled={disabled}
      // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
      tabIndex={-1}
    >
      {isIconIdentifier(icon) ? (
        <Icon icon={icon} />
      ) : typeof icon === "object" ? (
        <View
          style={{
            height: 32,
            justifyContent: "center",
          }}
        >
          {icon}
        </View>
      ) : null}
      {!editable ? (
        <ListItemTextWrap>
          <Text size="medium">{text}</Text>
        </ListItemTextWrap>
      ) : (
        <ListItemTextWrap>
          <TextInput
            ref={textInputRef}
            value={
              text as string /* TODO: Refactor to make the types represent this... */
            }
            placeholder={placeholder || "Item"}
            onChangeText={onChangeTextHandler}
            onSubmitEditing={onSubmitEditing}
            autoFocus={autoFocus}
            onKeyPress={onKeyPress}
          />
        </ListItemTextWrap>
      )}
      {actionIcon ? (
        <PressableOpacity
          activeOpacity={0.8}
          onPress={onActionIconClick}
          disabled={disabled || actionLoading}
        >
          <Icon icon={actionIcon} size={24} />
        </PressableOpacity>
      ) : null}
      {actionComponent ? actionComponent : null}
    </ListItemEntry>
  );
};

const ListItemEntry = styled(BasePressable)<{ cursor: string }>`
  flex-direction: row;
  margin-vertical: 5px;
  ${() => (Platform.OS === "web" ? "user-select: none;" : "")}
  ${({ cursor }) =>
    Platform.OS === "web" && cursor ? `cursor: ${cursor};` : ""}
`;

const ListItemTextWrap = styled.View`
  margin-right: 12px;
  flex: 1;
  justify-content: center;
`;

export default ListItem;
