import React, { useMemo, useRef } from "react";
import { UserPicker, UserPickerController } from "./UserList/UserPicker";
import { AppState, useAppSelector } from "@app/store";
import { selectAssignmentById } from "@app/store/cache/assignments";
import { StandardUserEntry } from "@app/components/questkit/UserList/StandardUserEntry";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import isEqual from "react-fast-compare";
import { useRefSynchronizer } from "@app/util/useRefSynchronizer";
import { UserListSyncKey } from "@app/components/questkit/UserList/UserList.controller";

export type AssigneePickerProps = {
  startConfigurationId: string;
  noInitialAssignees?: boolean;
  disabled?: boolean;
  onChangeAssignees?: (userIds: string[]) => void;
  onInputTextChange?: (text: string) => void;
  synchronizationKey?: UserListSyncKey;
};
const assigneePickerSelector =
  (startConfigurationId: string) => (state: AppState) => {
    const startConfiguration = selectQuestStartConfigurationById(
      state,
      startConfigurationId
    )!;

    return {
      assignments: (startConfiguration?.assignmentIds || [])
        .map((id) => selectAssignmentById(state, id)!)
        .filter(
          // filter out anonymous assignees
          ({ assigneeId }) =>
            !state.cache.users.entities[assigneeId]!.isAnonymous
        ),
    };
  };

export const AssigneePicker = React.forwardRef<
  UserPickerController,
  AssigneePickerProps
>(
  (
    {
      startConfigurationId,
      noInitialAssignees,
      disabled,
      onChangeAssignees,
      onInputTextChange,
      synchronizationKey,
    },
    parentRef
  ) => {
    const { assignments } = useAppSelector(
      assigneePickerSelector(startConfigurationId),
      isEqual
    );

    const userPickerRef = useRef<UserPickerController>(null);
    const synchronizedRef = useRefSynchronizer(parentRef, userPickerRef);

    const assignees = useMemo(
      () =>
        noInitialAssignees
          ? []
          : assignments.map(({ assigneeId }) => ({ userId: assigneeId })),
      [assignments, noInitialAssignees]
    );
    return (
      <UserPicker
        users={assignees}
        disabled={disabled}
        ref={synchronizedRef}
        onChange={(users) => onChangeAssignees?.(users)}
        onInputTextChange={onInputTextChange}
        renderUserEntry={StandardUserEntry}
        synchronizationKey={synchronizationKey}
      />
    );
  }
);
AssigneePicker.displayName = "AssigneePicker";
