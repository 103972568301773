import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components/native";
import TextInput from "@app/components/questkit/textInput";
import Text from "@app/components/questkit/text";
import Button from "@app/components/questkit/button";
import { TimeInputField } from "@app/components/timeInput/TimeInputField";
import { UnionOfArrayElements } from "@app/types";
import { DayOfTheWeekRange, HourRange, SixtyRange } from "cron-parser";
import { WeekdayPicker } from "@app/components/scheduler/WeekdayPicker";
import { TimezonePicker } from "@app/components/scheduler/TimezonePicker";
import {
  buildUpdatedCronString,
  CronString,
  parseCronFields,
} from "@app/components/scheduler/CronUtils";
import { Analytics } from "@app/analytics";
import { Platform, StyleProp, ViewStyle } from "react-native";
import isEqual from "react-fast-compare";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

interface SchedulerInputProps {
  schedule: CronString;
  timezone: string;
  onChange: (newSchedule: CronString | null, timezone: string | null) => void;
  scheduleDisabled?: boolean;
  timezoneDisabled?: boolean;
  readOnly?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const DEFAULT_INITIAL_SCHEDULE = "0 9 * * *";
const scheduleUITypes = ["daily", "weekly", "custom"] as const;
export type ScheduleUI = UnionOfArrayElements<typeof scheduleUITypes>;

export const SchedulerInput: React.FC<SchedulerInputProps> = (props) => {
  const {
    schedule: parentSchedule,
    timezone: parentTimezone,
    onChange,
    scheduleDisabled = false,
    timezoneDisabled = false,
    readOnly = false,
    style,
  } = props;
  const [schedule, setSchedule, scheduleRef] = useSyncedState(parentSchedule, {
    ignoreParentChange: (parentSchedule): boolean => {
      /**
       * Ignore incoming schedule change if it's the same as the last reported schedule.
       * Internally we keep the last edited schedule string, but we report a masked version
       * of the schedule based on which view the user currently has selected.
       * This allows the user to toggle between views without losing their custom cron schedule.
       */
      return parentSchedule === lastReportedScheduleRef.current;
    },
    onParentChange: (parentSchedule) => {
      setCurrentScheduleUI(determineBestUIToShow(parentSchedule));
    },
    onLocalChange: () => reportChange(),
  });
  const [timezone, setTimezone, timezoneRef] = useSyncedState(parentTimezone, {
    onLocalChange: () => reportChange(),
  });
  const cronFields = useMemo(() => parseCronFields(schedule), [schedule]);
  const [currentScheduleUI, setCurrentScheduleUI, currentScheduleUIRef] =
    useStateWithRef<ScheduleUI>(() => determineBestUIToShow(schedule));
  const lastReportedScheduleRef = useRef<string | null>(schedule);
  const reportChange = useCallback(() => {
    const maskedSchedule = adjustCronStringForCurrentUI(
      scheduleRef.current,
      currentScheduleUIRef.current
    );

    lastReportedScheduleRef.current = maskedSchedule;
    onChange(maskedSchedule, timezoneRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const toggleScheduleUI = useCallback(() => {
    if (!cronFields.valid) {
      setSchedule(DEFAULT_INITIAL_SCHEDULE);
    }

    const nextIndex = scheduleUITypes.indexOf(currentScheduleUI) + 1;
    const nextScheduleUI =
      scheduleUITypes[nextIndex >= scheduleUITypes.length ? 0 : nextIndex];

    Analytics.trackEvent("Set Quest Schedule Mode", {
      mode: nextScheduleUI,
    });
    setCurrentScheduleUI(nextScheduleUI);
    reportChange();
  }, [
    cronFields.valid,
    currentScheduleUI,
    setCurrentScheduleUI,
    reportChange,
    setSchedule,
  ]);

  const onTimeChange = useCallback(
    (hour: HourRange, minute: SixtyRange) => {
      Analytics.trackEvent("Set Quest Schedule Time");
      setSchedule(
        buildUpdatedCronString(cronFields, {
          minute: [minute],
          hour: [hour],
        }) || DEFAULT_INITIAL_SCHEDULE
      );
    },
    [setSchedule, cronFields]
  );
  const onWeekdayChange = useCallback(
    (dayOfWeek: DayOfTheWeekRange[]) => {
      Analytics.trackEvent("Set Quest Schedule Weekdays");
      setSchedule(
        buildUpdatedCronString(cronFields, {
          dayOfWeek,
        }) || DEFAULT_INITIAL_SCHEDULE
      );
    },
    [setSchedule, cronFields]
  );

  return (
    <SchedulerCard style={style}>
      <Row>
        <BasicText>Repeats</BasicText>
        <Button
          disabled={scheduleDisabled || readOnly}
          buttonType={"primary"}
          style={{ width: 103 }}
          title={currentScheduleUI}
          onPress={toggleScheduleUI}
        />
      </Row>
      {(currentScheduleUI === "daily" || currentScheduleUI === "weekly") && (
        <Row>
          <BasicText>At</BasicText>
          <WideInputWrapper>
            <TimeInputField
              hour={cronFields.hour[0]}
              minute={cronFields.minute[0]}
              onTimeChange={onTimeChange}
              disabled={scheduleDisabled || readOnly}
            />
          </WideInputWrapper>
        </Row>
      )}
      {currentScheduleUI === "weekly" && (
        <Row>
          <WeekdayPicker
            dayOfWeek={cronFields.dayOfWeek}
            onChange={onWeekdayChange}
            disabled={scheduleDisabled || readOnly}
          />
        </Row>
      )}
      {currentScheduleUI === "custom" && (
        <Row>
          <Text>Cron</Text>
          <CronStringInput
            schedule={schedule}
            onChange={setSchedule}
            disabled={scheduleDisabled || readOnly}
          />
        </Row>
      )}
      <Row>
        <TimezonePicker
          timezone={timezone}
          onChangeTimezone={setTimezone}
          disabled={timezoneDisabled}
          readOnly={readOnly}
        />
      </Row>
    </SchedulerCard>
  );
};

const BasicText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

interface CronScheduleProps {
  schedule: CronString;
  onChange: (schedule: CronString) => void;
  disabled: boolean;
}
const CronStringInput: React.FC<CronScheduleProps> = (props) => {
  return (
    <WideInputWrapper>
      <TextInput
        onChangeText={props.onChange}
        value={props.schedule}
        placeholder={"0 7 * * 1-5"}
        {...(Platform.OS === "ios"
          ? { keyboardType: "numbers-and-punctuation" }
          : {})}
        returnKeyType="done"
        editable={!props.disabled}
      />
    </WideInputWrapper>
  );
};

const SchedulerCard = styled.View`
  background-color: ${({ theme }) => theme.card.background};
  border-radius: 20px;
  padding: 10px 10px 2px;
`;

const Row = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  min-height: 40px;
`;

const WideInputWrapper = styled.View`
  width: 206px;
  height: 40px;
`;

const determineBestUIToShow = (schedule: string) => {
  const fields = parseCronFields(schedule);
  if (fields.valid) {
    const defaultFields = parseCronFields("* * * * *");
    const everyMonth = isEqual(fields.month, defaultFields.month);
    const everyDayOfMonth = isEqual(
      fields.dayOfMonth,
      defaultFields.dayOfMonth
    );
    const everyDayOfWeek = isEqual(fields.dayOfWeek, defaultFields.dayOfWeek);

    const moreThanOneHour = fields.hour.length !== 1;
    const moreThanOneMinute = fields.minute.length !== 1;

    if (
      !everyMonth ||
      !everyDayOfMonth ||
      moreThanOneHour ||
      moreThanOneMinute
    ) {
      return "custom";
    } else if (!everyDayOfWeek) {
      return "weekly";
    } else {
      return "daily";
    }
  } else {
    return "daily";
  }
};

function adjustCronStringForCurrentUI(
  schedule: string,
  currentScheduleUI: string
): string | null {
  const currentFields = parseCronFields(schedule);
  if (!currentFields.valid) {
    return null;
  }
  const defaults = parseCronFields("* * * * *");

  let maskedCronString;
  switch (currentScheduleUI) {
    case "daily":
      maskedCronString = buildUpdatedCronString(defaults, {
        minute: [currentFields.minute[0]],
        hour: [currentFields.hour[0]],
      });
      break;
    case "weekly":
      maskedCronString = buildUpdatedCronString(defaults, {
        minute: [currentFields.minute[0]],
        hour: [currentFields.hour[0]],
        dayOfWeek: currentFields.dayOfWeek,
      });
      break;
    default:
      maskedCronString = buildUpdatedCronString(currentFields, {});
  }
  return maskedCronString;
}
