import React, { useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import QKModal from "@app/components/modal";

interface TextInputModalProps {
  initialValue: string;
  headerTitle: string;
  submitButtonText: string;
  onSetValue: (value: string) => void;
  showTextInputModal: boolean;
  placeholder: string;
  setShowTextInputModal: (showOptionsModal: boolean) => void;
}

const TextInputModal: React.FC<TextInputModalProps> = ({
  showTextInputModal,
  setShowTextInputModal,
  initialValue,
  headerTitle,
  submitButtonText,
  onSetValue,
  placeholder,
}) => {
  const [value, setValue] = useState(initialValue || "");

  return (
    <QKModal
      showModal={showTextInputModal}
      setShowModal={setShowTextInputModal}
      title="Item Info Link"
    >
      <TextInputModalWrapper>
        <HeaderText icon={"link"} title={headerTitle} />
        <StyledTextInput
          autoFocus={true}
          onChangeText={(value: string) => setValue(value)}
          value={value}
          placeholder={placeholder}
        />
        <StyledTextInputModalButton
          onPress={() => {
            onSetValue(value);
            setShowTextInputModal(false);
          }}
          title={submitButtonText}
        />
      </TextInputModalWrapper>
    </QKModal>
  );
};

const StyledTextInputModalButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-bottom: 24px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 14px;
`;

const TextInputModalWrapper = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 10px;
`;

export default TextInputModal;
