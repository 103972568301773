import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import {
  TeamDetails,
  TeamListItem,
  TeamMembershipListItem,
  TeamMembershipRole,
} from "@questmate/openapi-spec";
import { selectLoggedInUserId, userLogout } from "@app/store/auth";
import { teamLoaded, teamsLoaded } from "@app/store/cache/teams";
import { createDataMapper } from "@app/store/cache/DataMapper";

type TeamMembership = {
  id?: string;
  role?: TeamMembershipRole;
  userId: string;
  teamId: string;
};

const teamMembershipAdapter = createEntityAdapter<TeamMembership, string>({
  selectId: (teamMembership) =>
    `${teamMembership.userId}-${teamMembership.teamId}`,
});

export const { selectAll: selectAllTeamMemberships } =
  teamMembershipAdapter.getSelectors<AppState>(
    (state) => state.cache.teamMemberships
  );
export const selectUsersTeamIds = createSelector(
  [selectAllTeamMemberships, (state) => selectLoggedInUserId(state)],
  (memberships, loggedInUserId) => {
    return memberships
      .filter(({ userId }) => userId === loggedInUserId)
      .map(({ teamId }) => teamId);
  }
);

export const selectTeamMembershipsForTeam = createSelector(
  [selectAllTeamMemberships, (_state, teamId) => teamId],
  (allMemberships, teamIdToMatch) => {
    return allMemberships.filter(({ teamId }) => teamId === teamIdToMatch);
  }
);

const slice = createSlice({
  name: "cache/teamMemberships",
  initialState: teamMembershipAdapter.getInitialState(),
  reducers: {
    teamMemberLoaded: (
      state,
      action: PayloadAction<{
        teamId: string;
        membership: TeamMembershipListItem;
      }>
    ) => {
      teamMembershipAdapter.upsertOne(
        state,
        mapTeamMembership({
          ...action.payload.membership,
          teamId: action.payload.teamId,
        })
      );
    },
    teamMemberAdded: (
      state,
      action: PayloadAction<{
        teamId: string;
        membership: TeamMembershipListItem;
      }>
    ) => {
      teamMembershipAdapter.upsertOne(
        state,
        mapTeamMembership({
          ...action.payload.membership,
          teamId: action.payload.teamId,
        })
      );
    },
    teamMemberDeleted: (
      state,
      action: PayloadAction<{
        teamId: string;
        membershipId: string;
      }>
    ) => {
      const membershipToDelete = Object.values(state.entities).find(
        (membership: TeamMembership) => {
          return (
            membership.teamId === action.payload.teamId &&
            membership.id === action.payload.membershipId
          );
        }
      );
      if (membershipToDelete) {
        teamMembershipAdapter.removeOne(
          state,
          `${membershipToDelete.userId}-${membershipToDelete.teamId}`
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) =>
      teamMembershipAdapter.removeAll(state)
    );
    builder.addCase(
      teamsLoaded,
      (
        state,
        action: PayloadAction<{
          userId: string;
          teams: TeamListItem[];
        }>
      ) => {
        const { userId, teams } = action.payload;
        teamMembershipAdapter.upsertMany(
          state,
          teams.map((team) => ({
            teamId: team.id,
            userId,
          }))
        );
      }
    );
    builder.addCase(teamLoaded, (state, action: PayloadAction<TeamDetails>) =>
      teamMembershipAdapter.upsertMany(
        state,
        action.payload.memberships
          .map((membership) => ({
            ...membership,
            teamId: action.payload.id,
          }))
          .map(mapTeamMembership)
      )
    );
  },
});

const reducer = slice.reducer;
export default reducer;

type MinimalTeamMembershipData = {
  user: { id: string };
  teamId: string;
};
type APITeamMembership =
  | (TeamMembershipListItem & { teamId: string })
  | MinimalTeamMembershipData;
const mapTeamMembership = createDataMapper<APITeamMembership, TeamMembership>()(
  ["id", "role", "teamId"],
  {
    user: ({ id }) => (id ? { userId: id } : {}),
  }
);

export const { teamMemberLoaded, teamMemberAdded, teamMemberDeleted } =
  slice.actions;
