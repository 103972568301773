import { store } from "@app/store";
import { reviewerRemoved, reviewersAdded } from "@app/store/cache/reviewers";
import { Reviewer } from "@questmate/openapi-spec";
import { apiRequest } from "@app/util/client";

export const addReviewers = (
  questPrototypeId: string,
  identifiers: string[] = [],
  userIds: string[] = []
): Promise<Reviewer[]> =>
  apiRequest<Reviewer[]>("post", `/quests/${questPrototypeId}/reviewers`, {
    identifiers,
    userIds,
  }).then((response) => {
    store.dispatch(reviewersAdded({ reviewers: response }));
    return response;
  });

export const removeReviewer = (
  questPrototypeId: string,
  reviewerId: string
): Promise<Reviewer> =>
  apiRequest<Reviewer>(
    "delete",
    `/quests/${questPrototypeId}/reviewers/${reviewerId}`
  ).then((response) => {
    store.dispatch(reviewerRemoved(response));
    return response;
  });

type UpdateReviewersResult<T extends { userId: string; id: string }> = {
  errors: {
    action: "ADD" | "REMOVE";
    userId: string;
    error: unknown;
  }[];
  reviewersAdded: Reviewer[];
  reviewersRemoved: T[];
};

export const updateQuestReviewers = async <
  T extends { userId: string; id: string }
>(
  questPrototypeId: string,
  existingReviewers: T[],
  desiredReviewers: { id: string }[]
): Promise<UpdateReviewersResult<T>> => {
  const result: UpdateReviewersResult<T> = {
    errors: [],
    reviewersAdded: [],
    reviewersRemoved: [],
  };
  const reviewersToDelete = existingReviewers.filter((existingReviewer) => {
    return !desiredReviewers.find(({ id }) => id === existingReviewer.userId);
  });
  const promises: Promise<unknown>[] = [];
  for (const assignment of reviewersToDelete) {
    promises.push(
      removeReviewer(questPrototypeId, assignment.id)
        .then(() => {
          result.reviewersRemoved.push(assignment);
        })
        .catch((error) => {
          result.errors.push({
            action: "REMOVE",
            userId: assignment.userId,
            error,
          });
        })
    );
  }

  const assigneesToAdd = desiredReviewers.filter(({ id }) => {
    return !existingReviewers.find(
      (existingReviewer) => existingReviewer.userId === id
    );
  });

  if (assigneesToAdd.length > 0) {
    promises.push(
      addReviewers(
        questPrototypeId,
        [],
        assigneesToAdd.map(({ id }) => id)
      )
        .then((newReviewers) => {
          result.reviewersAdded.push(...newReviewers);
        })
        .catch((error) => {
          result.errors.push(
            ...assigneesToAdd.map(({ id }) => ({
              action: "ADD" as const,
              userId: id,
              error,
            }))
          );
        })
    );
  }

  await Promise.all(promises);

  return result;
};
