import { MediaToken } from "@app/components/questkit/mediaSlider";
import { store } from "@app/store";
import { apiRequest } from "@app/util/client";
import React, { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import Semaphore from "semaphore-async-await";
import { MediaContextType } from "./MediaContext";

type ITokenContext = {
  getToken: (
    type: TokenType,
    contextType: MediaContextType,
    contextId: string
  ) => Promise<MediaToken | undefined>;
};

const TokenContext = React.createContext<ITokenContext>({
  getToken: () => Promise.resolve(undefined),
});

interface TokenCacheEntry {
  tokenData: MediaToken;
  validUntil: number;
}

interface TokenCache {
  [key: string]: TokenCacheEntry;
}

type TokenType = "MEDIA";

const TokenProvider: React.FC<PropsWithChildren> = (props) => {
  const tokenCache = useRef<TokenCache>({}).current;
  const getTokenLock = useRef(new Semaphore(1)).current;

  const getToken = useCallback(
    async (
      type: TokenType,
      contextType: MediaContextType,
      contextId: string
    ): Promise<MediaToken | undefined> => {
      await getTokenLock.acquire();

      const cacheKey = [type, contextType, contextId].join(":");
      const timestamp = Math.floor(Date.now() / 1000);

      if (
        tokenCache[cacheKey] &&
        tokenCache[cacheKey].validUntil > timestamp + 300
      ) {
        await getTokenLock.release();
        return tokenCache[cacheKey].tokenData;
      }

      let tokenData: MediaToken[];

      if (type === "MEDIA") {
        try {
          tokenData = await apiRequest<MediaToken[]>(
            "post",
            "/tokens/media",
            {
              contexts: [{ id: contextId, type: contextType }],
            },
            contextType === "questInstance"
              ? store.getState().publicQuestAuth.sessions[contextId]
              : undefined
          );
        } catch (e) {
          await getTokenLock.release();
          throw e;
        }

        if (tokenData && tokenData.length === 1) {
          tokenCache[cacheKey] = {
            tokenData: tokenData[0],
            validUntil: timestamp + tokenData[0].duration,
          };
          await getTokenLock.release();
          return tokenData[0];
        } else {
          await getTokenLock.release();
        }
      } else {
        await getTokenLock.release();
      }
    },
    [getTokenLock, tokenCache]
  );

  const tokenContext = useMemo((): ITokenContext => {
    return {
      getToken,
    };
  }, [getToken]);

  return (
    <TokenContext.Provider value={tokenContext}>
      {props.children}
    </TokenContext.Provider>
  );
};

export { TokenContext, TokenProvider };
