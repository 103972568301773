import React, { useContext, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import { Boundary } from "@app/components/screen/boundary";
import Constants from "expo-constants";
import Text from "@app/components/questkit/text";
import QKScrollView from "@app/components/questkit/ScrollView";
import * as Application from "expo-application";
import { ApplicationReleaseType } from "expo-application";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { NativeModules, Platform } from "react-native";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { usePromise } from "@app/util/usePromise";
import * as Updates from "expo-updates";
import { useAppDispatch, useAppSelector } from "@app/store";
import { setUpdateAvailable } from "@app/store/main";
import { sentry } from "@app/util/sentry";
import Button from "@app/components/questkit/button";
import { ENV } from "@app/config/env";
import { InformationListItem } from "@app/components/questkit/InformationListItem";

const isNative = Platform.OS !== "web";

export const AppDebugInfo: React.FC = () => {
  const [iosData, setIosData] = useState<{ releaseType?: string }>({});
  const [androidData, setAndroidData] = useState<{ lastUpdateDate?: Date }>({});
  const [nativeData, setNativeData] = useState<{
    installDate?: Date;
  }>({});
  useEffectOnce(() => {
    if (Platform.OS === "ios") {
      void Application.getIosApplicationReleaseTypeAsync().then((res) => {
        setIosData((data) => ({
          ...data,
          releaseType: ApplicationReleaseType[res],
        }));
      });
    } else if (Platform.OS === "android") {
      void Application.getLastUpdateTimeAsync().then((res) => {
        setAndroidData((data) => ({
          ...data,
          lastUpdateDate: res,
        }));
      });
    }
    if (isNative) {
      void Application.getInstallationTimeAsync().then((res) => {
        setNativeData((data) => ({
          ...data,
          installDate: res,
        }));
      });
    }
  });

  const updateChannel =
    Constants.expoConfig?.updates?.requestHeaders?.["expo-channel-name"] ??
    "n/a";

  return (
    <AppDebugInfoWrapper>
      <SafeAreaView edges={["bottom"]}>
        <Boundary>
          <ListView>
            <InformationListItem
              label="App Version"
              value={Constants.expoConfig?.version}
            />
            {isNative && (
              <>
                <InformationListItem
                  label="Native Version"
                  value={Application.nativeBuildVersion}
                />
                <InformationListItem
                  label="Runtime Version"
                  value={
                    typeof Constants.expoConfig?.runtimeVersion === "string"
                      ? Constants.expoConfig?.runtimeVersion
                      : JSON.stringify(Constants.expoConfig?.runtimeVersion)
                  }
                />
              </>
            )}

            {Platform.OS === "ios" && (
              <InformationListItem
                label="iOS Release Type"
                value={iosData.releaseType}
              />
            )}

            <InformationListItem
              label="SDK Version"
              value={Constants.expoConfig?.sdkVersion}
            />
            {isNative && (
              <>
                <InformationListItem
                  label="App Installed At"
                  value={nativeData.installDate?.toLocaleString()}
                />
                {Platform.OS === "android" && (
                  <InformationListItem
                    label="Last Updated At"
                    value={androidData.lastUpdateDate?.toLocaleString()}
                  />
                )}
              </>
            )}

            <InformationListItem
              label="EAS Updates Channel"
              value={updateChannel}
            />
            <InformationListItem label="API" value={ENV.apiBaseUrl} />
            <InformationListItem label="Auth0" value={ENV.auth0BaseUrl} />
            {isNative ? (
              <>
                <Spacer />
                {__DEV__ ? (
                  <Button
                    title="Reload Bundle"
                    onPress={() => NativeModules.DevSettings.reload()}
                  />
                ) : (
                  <AppUpdater />
                )}
              </>
            ) : null}
            {__DEV__ ? (
              <>
                <Spacer />
                <Text size="mediumLargeBold">Environment</Text>
                <Text>{JSON.stringify(ENV, null, 2)}</Text>
              </>
            ) : null}
          </ListView>
        </Boundary>
      </SafeAreaView>
    </AppDebugInfoWrapper>
  );
};

const ListView = styled.View`
  width: 100%;
`;

const AppDebugInfoWrapper = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingHorizontal: 20,
    flexGrow: 1,
    justifyContent: "center",
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

export const AppUpdater: React.FC = () => {
  const snackbar = useContext(SnackbarContext);
  const dispatch = useAppDispatch();
  const updateAvailable = useAppSelector((state) => state.main.updateAvailable);
  const [isUpdateCheckComplete, setIsUpdateCheckComplete] = useState(false);

  const { execute: checkForNewVersion, isLoading: isCheckingForNewVersion } =
    usePromise(() =>
      Updates.checkForUpdateAsync()
        .then((update) => {
          if (update.isAvailable) {
            snackbar.sendMessage("New version available. Downloading...");
            return Updates.fetchUpdateAsync().then((_update) => {
              dispatch(setUpdateAvailable(true));
              snackbar.sendMessage("New version ready to install");
              setIsUpdateCheckComplete(true);
            });
          } else {
            dispatch(setUpdateAvailable(false));
            snackbar.sendMessage("You have the latest version available");
            setIsUpdateCheckComplete(true);
          }
        })
        .catch((e) => {
          console.error("Error checking for new version", e);
          sentry.captureException(e, {
            extra: {
              message: "Failed to manually check for new app version",
              manifest: Updates.manifest,
              updateId: Updates.updateId,
              channel: Updates.channel,
              releaseChannel: Updates.releaseChannel,
              runtimeVersion: Updates.runtimeVersion,
            },
          });
          snackbar.sendMessage(
            "Error checking for new version",
            SnackbarSeverity.WARNING
          );
        })
    );
  return isUpdateCheckComplete && updateAvailable ? (
    <Button
      title="Install Update"
      buttonType="action"
      onPress={() => Updates.reloadAsync()}
    />
  ) : (
    <Button
      title="Check for new version"
      buttonType="action"
      onPress={checkForNewVersion}
      loading={isCheckingForNewVersion}
      success={isUpdateCheckComplete}
    />
  );
};

const Spacer = styled.View`
  height: 20px;
`;
