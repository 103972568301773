import { mutate } from "swr";

export const updateQuests = async (): Promise<void> => {
  await mutate(["get", "/quests"]);
  await mutate(["get", "/quests?include_archived=true"]);
};

export const updateTemplates = async (): Promise<void> => {
  await mutate(["get", "/templates"]);
  await mutate(["get", "/templates?include_archived=true"]);
};
