import styled from "styled-components/native";
import { IconIdentifier } from "@app/components/icon";
import { PressableProps } from "react-native";
import { QuestmateTheme } from "@app/themes/QuestmateTheme";
import * as React from "react";
import BasePressable from "@app/components/questkit/BasePressable";
import QKIcon from "@app/components/questkit/icon";

interface CircularButtonProps extends PressableProps {
  buttonTheme: keyof QuestmateTheme["button"];
  icon: IconIdentifier;
}

export const CircularButton: React.FC<CircularButtonProps> = ({
  buttonTheme,
  icon,
  ...pressableProps
}) => {
  return (
    <StyledPressable {...pressableProps}>
      <StyledQKIcon themeType={buttonTheme} name={icon} />
    </StyledPressable>
  );
};

const StyledQKIcon = styled(QKIcon)`
  margin: 0;
`;
const StyledPressable = styled(BasePressable)`
  cursor: pointer;
`;
