import { useDeferredValue, useMemo, useRef } from "react";
import { isNotUndefined } from "@questmate/common";
import { createSelector, lruMemoize, weakMapMemoize } from "@reduxjs/toolkit";
import isEqual from "react-fast-compare";

type SearchableItem = {
  name: string;
  value: string | number | null;
  searchTerms?: string[];
};

export type SearchTerm =
  /**
   * Allows partial match of any item in the array
   */
  | string[]
  /**
   * Requires exact match of the search string
   */
  | {
      exact: string;
    };

const whitespaceSplitter = /\s+/g;
export const createSearchTerm = (
  term: string | number | null | undefined,
  strategy: "EXACT" | "LOOSE" = "LOOSE"
): SearchTerm | undefined => {
  if (term === undefined || term === null) {
    return;
  }
  const trimmedTerm = String(term).trim();
  if (!trimmedTerm) {
    return;
  }

  if (strategy === "EXACT") {
    return { exact: trimmedTerm };
  } else {
    return trimmedTerm.toLowerCase().split(whitespaceSplitter);
  }
};

export const createSearchFilter = (searchText: string) => {
  const trimmedSearchText = (searchText || "").trim();
  const searchTextTokens = trimmedSearchText
    .toLowerCase()
    .split(whitespaceSplitter);

  return (searchableTerms: (SearchTerm | undefined)[]): boolean => {
    return (
      searchTextTokens.length === 0 ||
      // Partial Matches
      searchTextTokens.every((searchToken) =>
        searchableTerms.some((term) => {
          if (!term || !Array.isArray(term)) {
            // Skip exact or undefined terms
            return false;
          }
          return term.some((token) => token.includes(searchToken));
        })
      ) ||
      // Exact Matches
      searchableTerms.some(
        (term) => !!term && "exact" in term && term.exact === trimmedSearchText
      )
    );
  };
};

export const createSearchScorer = (searchText: string) => {
  const trimmedSearchText = (searchText || "").trim();
  const searchTextTokens = trimmedSearchText
    .toLowerCase()
    .split(whitespaceSplitter);

  if (searchTextTokens.length === 0) {
    // If no search tokens, then all scores are 1
    return () => 1;
  }

  return (searchableTerms: (SearchTerm | undefined)[]): number => {
    // Zero means no match to search terms (filter out).
    let score = 0;
    const matchedSearchTokenIndices = new Set<number>();
    for (let i = 0; i < searchableTerms.length; i++) {
      if (Array.isArray(searchableTerms[i])) {
        for (
          let searchTokenIndex = 0;
          searchTokenIndex < searchTextTokens.length;
          searchTokenIndex++
        ) {
          for (
            let termIndex = 0;
            termIndex < (searchableTerms[i] as string[]).length;
            termIndex++
          ) {
            const index = (searchableTerms[i] as string[])[termIndex].indexOf(
              searchTextTokens[searchTokenIndex]
            );
            if (index !== -1) {
              matchedSearchTokenIndices.add(searchTokenIndex);
              score +=
                (searchTextTokens[searchTokenIndex].length *
                  searchTextTokens[searchTokenIndex].length) /
                (searchableTerms[i] as string[])[termIndex].length;
              if (index === 0) {
                score += 5;
              }
              if (searchTokenIndex === termIndex) {
                score += 2;
              }
            }
          }
        }
      } else if (
        (searchableTerms[i] as { exact: string } | undefined)?.exact ===
        trimmedSearchText
      ) {
        // Return early if exact match is found
        return 1000;
      }
    }

    const matchedAllSearchTokens =
      searchTextTokens.length === matchedSearchTokenIndices.size;
    return matchedAllSearchTokens ? score : 0;
  };
};

const EMPTY_ARRAY: string[] = [];
export const useSearchResults = (
  items: SearchableItem[],
  _searchText: string
) => {
  const _stableSearchText = useMemo(
    () => _searchText?.trim() ?? "",
    [_searchText]
  );
  const searchText = useDeferredValue(_stableSearchText);

  /**
   * This selector is used to avoid recalculating the search terms for an item.
   * It has some resistance to the item equality changing as long as the name,
   * value and search terms are the same,
   * it should be able to re-use the previous search terms.
   */
  const selectSearchTermsForItem = useMemo(() => {
    const selectStableSearchStrings = createSelector(
      (item: SearchableItem) =>
        !item.searchTerms || item.searchTerms.length === 0
          ? EMPTY_ARRAY
          : item.searchTerms,
      (searchTerms) => searchTerms,
      {
        memoize: lruMemoize,
        memoizeOptions: {
          resultEqualityCheck: isEqual,
          maxSize: 5000,
        },
      }
    );

    return createSelector(
      [
        (item: SearchableItem) => item.value,
        (item: SearchableItem) => item.name,
        selectStableSearchStrings, // Weak map needs equality checkable inputs
      ],
      (value, name, searchStrings) => {
        return [
          createSearchTerm(name),
          ...searchStrings.map((term) => createSearchTerm(term)),
          createSearchTerm(value, "EXACT"),
        ].filter(isNotUndefined);
      },
      {
        memoize: weakMapMemoize,
      }
    );
  }, []);

  const prevSearchTextRef = useRef(searchText);
  const prevItemsRef = useRef(items);
  const prevFilteredItemsRef = useRef<SearchableItem[] | null>(null);
  const result = useMemo(() => {
    const prevSearchText = prevSearchTextRef.current;
    prevSearchTextRef.current = searchText;
    const prevItems = prevItemsRef.current;
    prevItemsRef.current = items;

    if (!searchText) {
      // Skip filter if no search text is provided
      return items;
    }

    let data = items;
    if (
      prevItems === items &&
      prevSearchText &&
      searchText.startsWith(prevSearchText) &&
      prevFilteredItemsRef.current !== null
    ) {
      /**
       * If the full set of runs data hasn't changed and the last search text is a substring of the current
       * search text, then we can continue from the last set of filtered sections. This is helpful for users with
       * large amounts of Quest Runs since the search is performed purely on the front-end.
       * This will ensure the filter is built up as they type instead of fully recalculated on each character they press.
       */

      data = prevFilteredItemsRef.current;
    }

    const getSearchScore = createSearchScorer(searchText);
    return data
      .map((item): [typeof item, number] => [
        item,
        getSearchScore(selectSearchTermsForItem(item)),
      ])
      .filter(([, score]) => score > 0)
      .sort((a, b) =>
        b[1] === a[1]
          ? a[0].name.localeCompare(b[0].name, undefined, {})
          : b[1] - a[1]
      )
      .map(([item]) => item);
  }, [items, searchText, selectSearchTermsForItem]);
  prevFilteredItemsRef.current = result;

  return result;
};
