import React, { useContext } from "react";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";
import { ThemeContext } from "styled-components/native";

interface QKActivityIndicatorProps extends ActivityIndicatorProps {
  disabled?: boolean;
}

export const QKActivityIndicator: React.FC<QKActivityIndicatorProps> = (
  props
) => {
  const theme = useContext(ThemeContext);

  return (
    <ActivityIndicator
      {...props}
      color={
        props.color
          ? props.color
          : props.disabled
          ? theme.background
          : theme.primary
      }
    />
  );
};
