import EventEmitter from "events";
import { useRef } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";

/**
 * Inspired by: https://stackoverflow.com/a/53892053/2703729
 */
class ClockEmitter extends EventEmitter {
  private timeoutId: NodeJS.Timeout | null = null;

  constructor() {
    super();
    this.setMaxListeners(Infinity); // Many runs may be listening.
    this.nextTick();
  }

  private nextTick() {
    /**
     * Use `requestAnimationFrame` to save energy when Questmate is not active.
     */
    requestAnimationFrame(() => {
      const timeAtWholeSecond = 1000 * Math.floor(Date.now() / 1000 + 0.1);
      this.emit("tick", timeAtWholeSecond);
      if (this.eventNames().length > 0) {
        const nextWholeSecond = timeAtWholeSecond + 1000;
        this.timeoutId = setTimeout(
          () => this.nextTick(),
          nextWholeSecond - Date.now()
        );
      } else {
        this.timeoutId = null;
      }
    });
  }

  private startOrStopAccordingly() {
    const hasListeners = this.eventNames().length > 0;
    if (hasListeners && !this.timeoutId) {
      this.nextTick();
    } else if (!hasListeners && this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  on(...args: Parameters<EventEmitter["on"]>) {
    const returnValue = super.on(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }

  off(...args: Parameters<EventEmitter["off"]>) {
    const returnValue = super.off(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }

  once(...args: Parameters<EventEmitter["once"]>) {
    const returnValue = super.once(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }

  addListener(...args: Parameters<EventEmitter["addListener"]>) {
    const returnValue = super.addListener(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }

  removeListener(...args: Parameters<EventEmitter["removeListener"]>) {
    const returnValue = super.removeListener(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }

  removeAllListeners(...args: Parameters<EventEmitter["removeAllListeners"]>) {
    const returnValue = super.removeAllListeners(...args);
    this.startOrStopAccordingly();
    return returnValue;
  }
}

/**
 * The Clock emits a 'tick' event every whole second.
 * - Accounts for drift over time by using `setTimeout` instead of `setInterval`.
 * - Automatically starts and stops emitting, according to the number of listeners.
 * - Makes use of `requestAnimationFrame` to save energy when app is not active.
 */
export const Clock = new ClockEmitter();

/**
 * Calls the provided `tickHandler` every whole second.
 * - Accounts for drift over time.
 * - Optimized to avoid unnecessary timers when no listeners or the app is not active.
 */
export const useClockTick = (tickHandler: (now: number) => void) => {
  const callbackRef = useRef(tickHandler);
  callbackRef.current = tickHandler;
  useEffectOnce(() => {
    const listener = (now: number) => {
      callbackRef.current?.(now);
    };

    Clock.on("tick", listener);
    return () => {
      Clock.off("tick", listener);
    };
  });
};
