import React, { useMemo } from "react";
import Text from "@app/components/questkit/text";

import styled from "styled-components/native";
import { AssignmentStackData } from "@app/screens/home";
import Icon from "@app/components/icon";
import { View, StyleSheet } from "react-native";
import BasePressable from "@app/components/questkit/BasePressable";
import { OnLinkPress } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { HoverProps, useHover } from "@app/util/useHover";

interface QuestStackProps {
  name: string;
  stackData: AssignmentStackData["assignments"];
  onPress: OnLinkPress | (() => void) | undefined;
  onPressEntryBuilder: (id: string) => OnLinkPress | undefined;
}

interface QuestEntryData {
  name: string;
  id: string;
}

interface QuestEntryProps {
  data: QuestEntryData;
  onPressEntryBuilder: (entryId: string) => OnLinkPress | undefined;
}

interface QuestEntriesProps {
  data: QuestEntryData[];
  onPressEntryBuilder: (entryId: string) => OnLinkPress | undefined;
}

export const QuestStack: React.FC<QuestStackProps> = (props) => {
  const { name, onPress, onPressEntryBuilder, stackData } = props;

  const questEntriesData = stackData.slice(0, 3).map((entry) => ({
    name: entry.formPrototype.name,
    id: entry.formInstance.id,
  }));

  return (
    <QuestStackWrapper>
      <QuestEntries
        data={questEntriesData}
        onPressEntryBuilder={onPressEntryBuilder}
      />
      <QuestMeta activeOpacity={0.8} onPress={onPress}>
        <QuestSelectorTop>
          <StyledText size="smallBold" numberOfLines={2}>
            {name}
          </StyledText>
        </QuestSelectorTop>
        <ShowAll>
          <ShowAllText>
            <StyledText size="small" numberOfLines={1}>
              Show all
            </StyledText>
          </ShowAllText>
          <ShowAllCount>
            <StyledText
              size="small"
              numberOfLines={1}
              style={{ marginRight: -6 }}
            >
              {stackData.length}
            </StyledText>
            <Icon icon="chevron-right" />
          </ShowAllCount>
        </ShowAll>
      </QuestMeta>
    </QuestStackWrapper>
  );
};

const QuestEntries: React.FC<QuestEntriesProps> = ({
  data,
  onPressEntryBuilder,
}) => {
  const { hoverProps } = useHover();
  return (
    <QuestEntriesContainer {...hoverProps}>
      {data.map((entryData, idx) => (
        <View key={entryData.id}>
          <QuestEntry
            data={entryData}
            onPressEntryBuilder={onPressEntryBuilder}
          />
          {idx !== 2 && <QuestEntrySeparator />}
        </View>
      ))}
    </QuestEntriesContainer>
  );
};

const QuestEntry: React.FC<QuestEntryProps> = ({
  data,
  onPressEntryBuilder,
}) => {
  const onPress = useMemo(
    () => onPressEntryBuilder(data.id),
    [data.id, onPressEntryBuilder]
  );
  return (
    <QuestEntryWrapper onPress={onPress}>
      <QuestEntryText size="small" numberOfLines={2}>
        {data.name}
      </QuestEntryText>
    </QuestEntryWrapper>
  );
};

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.tile.assignment.text};
`;
const QuestEntryWrapper = styled(BasePressable)`
  height: 54px;
  justify-content: center;
  padding-horizontal: 8px;
`;

const QuestEntrySeparator = styled.View`
  height: ${StyleSheet.hairlineWidth}px;
  background-color: ${({ theme }) => theme.primary};
  margin-horizontal: 8px;
`;

const QuestEntryText = styled(Text)`
  color: ${({ theme }) => theme.tile.assignment.text};
`;

const QuestEntriesContainer = styled.View<HoverProps>`
  width: 334px;
  height: 162px;
  border-radius: 16px;
  ${({ theme }) => {
    const border = theme.tile.assignment.border;

    return border
      ? `
      border-width: ${StyleSheet.hairlineWidth}px;
      border-color: ${border};
    `
      : "";
  }}
  background-color: ${({ theme, isHovered }) =>
    theme.tile.assignment.background[isHovered ? "hover" : "normal"]};
  transition: background-color 0.15s ease-in-out;
  margin-bottom: 12px;
`;

const QuestStackWrapper = styled.View`
  flex-direction: column;
  margin-right: 11px;
  width: 334px;
`;

const QuestSelectorTop = styled.View`
  flex-direction: row;
  align-content: space-between;
  margin-bottom: 14px;
  height: 40px;
  align-items: center;
`;

const QuestMeta = styled(PressableOpacity)`
  height: 72px;
  margin-left: 6px;
`;

const ShowAll = styled.View`
  height: 18px;
  flex-direction: row;
`;

const ShowAllText = styled.View`
  flex: 1;
`;

const ShowAllCount = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
