import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled, { useTheme } from "styled-components/native";
import { Boundary } from "@app/components/screen/boundary";
import TextInput from "@app/components/questkit/textInput";
import Loader from "@app/components/animated/loader";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import {
  UserPicker,
  UserPickerController,
} from "@app/components/questkit/UserList/UserPicker";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import {
  addTeamMember,
  deleteTeamMember,
  fetchTeam,
  patchTeamMember,
  updateTeam,
} from "@app/util/client/requests/teams";
import QKScrollView from "@app/components/questkit/ScrollView";
import { selectTeamWithMemberships } from "@app/store/cache/teams";
import { useAppSelector } from "@app/store";
import { useRequest } from "@app/util/client/requests";
import { UserPickerEntryProps } from "@app/components/questkit/UserList/StandardUserEntry";
import {
  MemberEditModalProps,
  MemberUserEntry,
} from "@app/teams/MemberUserEntry";
import { usePromise } from "@app/util/usePromise";
import HeaderText from "@app/components/questkit/headerText";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { PlaceholderHeaderIcon } from "@app/navigation/components/HeaderIcon";
import { LetterIcon } from "@app/components/questkit/LetterIcon";
import { TeamMembershipRole, Teams } from "@questmate/openapi-spec";
import { EditMemberModal } from "@app/screens/settings/workspace";
import { sentry } from "@app/util/sentry";
import isEqual from "react-fast-compare";

export const TeamSettingsScreen: React.FC = () => {
  const navigation = useAppNavigation();
  const teamId = useAppRoute<"Team">().params?.teamId;
  const theme = useTheme();

  const team = useAppSelector(
    (state) => selectTeamWithMemberships(state, teamId),
    isEqual
  );
  const memberships = team?.memberships;
  const membershipsRef = useRef(memberships);
  membershipsRef.current = memberships;
  const cachedTeamName = team?.name;
  const [teamName, setTeamName, teamNameRef] = useStateWithRef(
    cachedTeamName || ""
  );
  useEffect(() => {
    if (cachedTeamName && cachedTeamName !== teamNameRef.current) {
      setTeamName(cachedTeamName || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedTeamName]);

  const { isValidating, refresh: refreshTeam } = useRequest(fetchTeam(teamId));

  const userPickerRef = useRef<UserPickerController>(null);

  const snackbarContext = useContext(SnackbarContext);

  const onAddTeamMember = useCallback(
    async (userId: string) => {
      return addTeamMember(teamId, userId).catch((error) => {
        let snackbarMessage: string;
        switch (error?.message) {
          case "NO_ACTIVE_SUBSCRIPTION":
            snackbarMessage =
              "Team must have an active subscription to add members.";
            break;
          case "Must be Admin or Owner to add team members.":
            snackbarMessage = "You do not have permission to add members.";
            break;
          default:
            snackbarMessage = "Could not add member.";
        }

        snackbarContext.sendMessage(snackbarMessage, SnackbarSeverity.WARNING);
        userPickerRef.current?.remove([{ userId }]);
      });
    },
    [snackbarContext, teamId]
  );

  const onDeleteTeamMember = useCallback(
    (memberUserId: string) => {
      const membershipId = membershipsRef.current?.find(
        (membership) => membership?.userId === memberUserId
      )?.id;
      if (!membershipId) {
        // This should not happen, but is technically possible.
        sentry.captureMessage(
          "Failed to delete team member: Could not find membershipId for memberUserId",
          {
            extra: { teamId, memberUserId },
          }
        );
        snackbarContext.sendMessage(
          "Failed to delete team member",
          SnackbarSeverity.WARNING
        );
        void refreshTeam();
        return Promise.resolve({ success: false });
      }
      return deleteTeamMember(teamId, membershipId)
        .then(() => ({ success: true }))
        .catch((error) => {
          let snackbarMessage: string;
          switch (error?.message) {
            case "You cannot remove yourself from a Team..":
              snackbarMessage = "You may not delete yourself from a Team.";
              break;
            case "Must be Admin or Owner to delete team members.":
              snackbarMessage = "You do not have permission to delete members.";
              break;
            case "You cannot remove the owner of a Team.":
              snackbarMessage = "You may not delete the owner of a Team.";
              break;
            default:
              snackbarMessage = "Couldn't delete member.";
          }

          snackbarContext.sendMessage(
            snackbarMessage,
            SnackbarSeverity.WARNING
          );
          userPickerRef.current?.add([{ userId: memberUserId }]);
          return { success: false };
        });
    },
    [refreshTeam, snackbarContext, teamId]
  );

  const updateTeamMember = useCallback(
    (
      memberUserId: string,
      fields: Teams.MembershipsPartialUpdate.RequestBody
    ) => {
      const membershipId = membershipsRef.current?.find(
        (membership) => membership?.userId === memberUserId
      )?.id;
      if (!membershipId) {
        // This should not happen, but is technically possible.
        sentry.captureMessage(
          "Failed to update team member: Could not find membershipId for memberUserId",
          {
            extra: { teamId, memberUserId },
          }
        );
        snackbarContext.sendMessage(
          "Failed to update team member",
          SnackbarSeverity.WARNING
        );
        void refreshTeam();
        return Promise.resolve({ success: false });
      }
      return patchTeamMember(teamId, membershipId, fields)
        .then(() => ({ success: true }))
        .catch((error) => {
          let snackbarMessage: string;
          switch (error?.message) {
            case "Transferring team ownership not yet supported.":
              snackbarMessage =
                "Transferring team ownership not yet supported.";
              break;
            case "You cannot remove the owner of a team.":
              snackbarMessage = "You may not edit the owner of a team.";
              break;
            case "Must be Admin to edit team members.":
              snackbarMessage = "You do not have permission to edit members.";
              break;
            default:
              snackbarMessage = "Could not save changes.";
          }

          snackbarContext.sendMessage(
            snackbarMessage,
            SnackbarSeverity.WARNING
          );
          void refreshTeam();
          return { success: false };
        });
    },
    [refreshTeam, snackbarContext, teamId]
  );

  const { execute: saveTeam, isLoading: isSaving } = usePromise(async () => {
    const newTeamName = (teamName || "")?.trim();
    if (!newTeamName) {
      snackbarContext.sendMessage(
        "Team name cannot be empty.",
        SnackbarSeverity.WARNING
      );
      setTeamName(cachedTeamName!);
      return;
    }

    if (teamName === cachedTeamName || newTeamName === cachedTeamName) {
      return;
    }

    return updateTeam(teamId, {
      name: newTeamName,
    })
      .catch((error) => {
        let snackbarMessage: string;
        switch (error?.message) {
          case "You do not have permission to edit this team.":
            snackbarMessage = "You do not have permission to edit the team.";
            break;
          default:
            snackbarMessage = "Failed to update the team.";
        }

        snackbarContext.sendMessage(snackbarMessage, SnackbarSeverity.WARNING);
        setTeamName(cachedTeamName!);
      })
      .finally(refreshTeam);
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: "Team",
      headerRight: PlaceholderHeaderIcon,
    });
  }, [navigation]);

  const onChangeMembers = useCallback(
    (userIds: string[]) => {
      const existingMemberships = membershipsRef.current;
      if (!Array.isArray(existingMemberships)) {
        return;
      }
      const previousMembersUserIds = existingMemberships.map(
        (member) => member.user.id
      );
      const membersToDelete = existingMemberships.filter(
        (member) => !userIds.includes(member.user.id)
      );
      const userIdsToAdd = userIds.filter(
        (userId) => !previousMembersUserIds.includes(userId)
      );

      membersToDelete.forEach((member) => onDeleteTeamMember(member.user.id));
      userIdsToAdd.forEach((userId) => onAddTeamMember(userId));
    },
    [onDeleteTeamMember, onAddTeamMember]
  );

  const teamMembers = useMemo(
    () =>
      memberships?.map((membership) => {
        return {
          userId: membership.user.id,
        };
      }),
    [memberships]
  );

  const renderEditModal = useCallback(
    (props: MemberEditModalProps) => {
      const updateMember = useCallback(
        (userId: string, role: string | undefined) =>
          updateTeamMember(userId, {
            role: role as TeamMembershipRole,
          }),
        []
      );
      return (
        <EditMemberModal
          {...props}
          deleteMember={onDeleteTeamMember}
          updateMember={updateMember}
          roles={TeamRoles}
        />
      );
    },
    [onDeleteTeamMember, updateTeamMember]
  );

  const renderMemberEntry = useCallback(
    (props: UserPickerEntryProps) => {
      const userId =
        props.entry.status === "LOOKING_UP_USER"
          ? undefined
          : props.entry.userId;
      const role = memberships?.find(
        (member) => member.userId === userId
      )?.role;
      return (
        <MemberUserEntry
          {...props}
          role={role}
          renderEditModal={renderEditModal}
        />
      );
    },
    [memberships, renderEditModal]
  );

  if (!team) {
    if (isValidating) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    } else {
      return (
        <>
          <PlaceholderView
            text="Couldn't load your team :("
            actions={[
              {
                type: "primary",
                text: "Retry",
                loading: isValidating,
                onPress: refreshTeam,
              },
            ]}
          />
        </>
      );
    }
  }

  return (
    <TeamSettingsScreenWrapper>
      <SafeAreaView edges={["bottom"]}>
        <TeamIcon size={140} letter={cachedTeamName!} />
        <Boundary>
          <HeaderText icon="group" title="Team Name" />
          <TeamNameInput
            placeholder="Acme Inc."
            rightIcon={(isFocused: boolean) =>
              isSaving ? (
                <StyledActivityIndicator
                  color={isFocused ? theme.background : theme.primary}
                />
              ) : null
            }
            value={teamName}
            onChangeText={setTeamName}
            onBlur={saveTeam}
            blurOnSubmit={true}
          />
          {teamMembers === undefined ? (
            <InlineLoaderContainer>
              <Loader />
            </InlineLoaderContainer>
          ) : (
            <>
              <UserPicker
                title="Members"
                ref={userPickerRef}
                onChange={onChangeMembers}
                users={teamMembers}
                renderUserEntry={renderMemberEntry}
              />
            </>
          )}
        </Boundary>
      </SafeAreaView>
    </TeamSettingsScreenWrapper>
  );
};

const TeamSettingsScreenWrapper = styled(QKScrollView).attrs({
  contentContainerStyle: {
    padding: 20,
  },
})`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;

const TeamNameInput = styled(TextInput)`
  margin-bottom: 10px;
`;

const TeamIcon = styled(LetterIcon)`
  align-self: center;
  margin-top: 25px;
  margin-bottom: 40px;
`;

const LoaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;
const InlineLoaderContainer = styled(LoaderContainer)`
  margin-top: 40px;
`;

const StyledActivityIndicator = styled.ActivityIndicator`
  height: 40px;
  width: 40px;
  align-self: center;
`;

const TeamRoles: Record<TeamMembershipRole, string> = {
  OWNER: "Owner",
  ADMIN: "Admin",
  MEMBER: "Member",
};
