import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components/native";
import { InfoText } from "@app/components/questkit/InfoText";
import { QRCode } from "@app/components/QRCode";
import Button from "@app/components/questkit/button";
import { downloadQRCodeImage } from "@app/components/QRCodeDownloader";

type QrCodeModalProps = {
  filenamePrefix: string;
  url: string;
  onDownload?: () => void;
};
export const QrCodeModalView: React.FC<QrCodeModalProps> = ({
  filenamePrefix,
  url,
  onDownload,
}) => {
  const downloadQRCode = useCallback(() => {
    downloadQRCodeImage(url, `${filenamePrefix} QR Code`);
    onDownload?.();
  }, [url, filenamePrefix, onDownload]);

  return (
    <QrCodeScrollView>
      {url ? (
        <>
          <StyledQRCode size={300} value={url} />
          <InfoText text="Print this code to allow users to complete the Quest from their own device." />
          <DownloadButton onPress={downloadQRCode} title="Download QR Code" />
        </>
      ) : null}
    </QrCodeScrollView>
  );
};

const QrCodeScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 12,
    alignItems: "center",
  },
})``;

const StyledQRCode = styled(QRCode)`
  width: 100%;
`;

const DownloadButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 15px;
`;
