import { DefaultTheme } from "styled-components";
import { BackButton } from "@app/navigation/components/BackButton";
import { PlaceholderHeaderIcon } from "@app/navigation/components/HeaderIcon";
import { StackNavigationOptions } from "@react-navigation/stack";

export const getDefaultScreenOptions = (
  themeContext: DefaultTheme,
  userIsLoggedIn: boolean
): StackNavigationOptions => {
  const common: StackNavigationOptions = {
    freezeOnBlur: true,
    cardStyle: { flex: 1 },
    headerTitleStyle: {
      fontSize: 18,
      fontFamily: "CentraNo2-Medium",
      color: themeContext.primary,
    },
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: themeContext.background,
      elevation: 0,
      shadowOpacity: 0,
      borderBottomWidth: 0,
    },
    headerLeftContainerStyle: {
      marginLeft: 4,
    },
    headerRightContainerStyle: {
      marginRight: 4,
    },
  };

  return userIsLoggedIn
    ? {
        ...common,
        headerLeft: BackButton,
        headerRight: PlaceholderHeaderIcon,
      }
    : {
        ...common,
        headerLeft: PlaceholderHeaderIcon,
        headerRight: PlaceholderHeaderIcon,
        headerShown: false,
      };
};
