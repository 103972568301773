import { useSWRNativeRevalidate } from "@nandorojo/swr-react-native";
import { Middleware } from "swr";

export const swrNativeMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    // const navigation = useNavigation();

    const swr = useSWRNext(key, fetcher, {
      ...config,
      // TODO: Re-introduce when the following issue is resolved:
      //       https://github.com/vercel/swr/issues/2333
      // isPaused() {
      //   // Do not override if explicitly configured
      //   if (config.isPaused !== undefined) {
      //     return config.isPaused();
      //   }
      //
      //   return !config.refreshWhenHidden && !navigation.isFocused();
      // },
    });

    useSWRNativeRevalidate({
      mutate: swr.mutate,
      revalidateOnFocus: config.revalidateOnFocus,
      revalidateOnReconnect: config.revalidateOnReconnect,
      focusThrottleInterval: config.focusThrottleInterval,
    });

    return swr;
  };
};
