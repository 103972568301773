import EventEmitter from "events";
import { Animated, LayoutRectangle, Platform } from "react-native";
import { IDragDropListController } from "@app/components/dragdrop/DragDropListControllerProvider";

export interface DragDropListViewModel<T> {
  activeIndex: number;
  activeItem: {
    item: T;
    index: number;
    layout: LayoutRectangle;
    offset: Animated.ValueXY;
  };
  itemsInList: {
    item: T;
    index: number;
    layout: LayoutRectangle;
    offset: Animated.ValueXY;
  }[];
  listHeight: number;
}

export class DragDropListController<T> extends EventEmitter {
  private selectedItemIndex = -1;
  private selectedItemNewIndex = -1;
  private selectedItemOffsetY = 0;
  private isActivelyDragging = false;
  private initialScrollOffset = 0;
  private currentScrollOffset = 0;
  private scrollViewController?: IDragDropListController;
  private selectedItemOffset = new Animated.ValueXY();
  private items: { item: T; layout: LayoutRectangle }[] = [];
  private offsets: Animated.ValueXY[] = [];
  private scrollListener = (offset: number) => this.scroll(offset);

  setItems(items: { item: T; layout: LayoutRectangle }[]): void {
    this.items = items;
  }

  setScrollViewController(scrollViewController: IDragDropListController): void {
    this.scrollViewController = scrollViewController;
  }

  isDragActive(): boolean {
    return this.selectedItemIndex !== -1 && this.isActivelyDragging;
  }

  isItemSelectedToDragDrop(): boolean {
    return this.selectedItemIndex !== -1;
  }

  selectItem(index: number): void {
    this.selectedItemIndex = index;
    this.selectedItemNewIndex = index;
    this.offsets = this.items.map(() => new Animated.ValueXY());
    this.selectedItemOffset.setValue({ x: 0, y: 0 });
    this.selectedItemOffsetY = 0;
  }

  maybeDeselectItem(): void {
    if (!this.isActivelyDragging) {
      this.selectedItemIndex = -1;
      this.selectedItemNewIndex = -1;
    }
  }

  startDragDrop(): void {
    this.isActivelyDragging = true;

    this.initialScrollOffset = this.scrollViewController!.getScrollOffset();
    this.currentScrollOffset = this.initialScrollOffset;
    this.scrollViewController!.addScrollOffsetListener(this.scrollListener);

    this.emit("start");
  }

  move(_x: number, y: number): void {
    const offset = this.currentScrollOffset - this.initialScrollOffset;
    this.updatePos(y + offset);
  }

  stopDragDrop(): void {
    const activeIdx = this.selectedItemIndex;
    const newActiveIdx = this.selectedItemNewIndex;

    this.isActivelyDragging = false;
    this.maybeDeselectItem();

    this.scrollViewController!.removeScrollOffsetListener(this.scrollListener);

    this.emit("stop", activeIdx, newActiveIdx);
  }

  getViewModel(): DragDropListViewModel<T> {
    return {
      activeIndex: this.selectedItemIndex,
      activeItem: {
        item: this.items[this.selectedItemIndex].item,
        index: this.selectedItemIndex,
        layout: this.items[this.selectedItemIndex].layout,
        offset: this.selectedItemOffset,
      },
      itemsInList: this.items.map(({ item, layout }, index) => {
        return {
          item,
          index,
          layout,
          offset: this.offsets[index],
        };
      }),
      listHeight: this.items.reduce((h, { layout }) => h + layout.height, 0),
    };
  }

  private scroll(offset: number) {
    const previousOffset = this.currentScrollOffset;
    this.currentScrollOffset = offset;
    this.updatePos(this.selectedItemOffsetY + (offset - previousOffset));
  }

  private updatePos(y: number) {
    if (this.selectedItemIndex === -1) {
      return;
    }
    const activeItemLayout = this.items[this.selectedItemIndex].layout;
    const minY = -activeItemLayout.y;
    const maxY =
      this.getBottomY() - activeItemLayout.y - activeItemLayout.height;
    const clippedY = Math.min(maxY, Math.max(minY, y));
    const newActiveIdx = this.calculateNewActiveIndex(clippedY);
    if (this.selectedItemNewIndex !== newActiveIdx) {
      this.selectedItemNewIndex = newActiveIdx;
      this.onNewActiveIdx();
    }
    this.selectedItemOffsetY = clippedY;
    this.selectedItemOffset.setValue({ x: 0, y: clippedY });
  }

  private getBottomY(): number {
    if (this.items.length === 0) {
      return 0;
    }

    const lastItemLayout = this.items[this.items.length - 1].layout;
    return lastItemLayout ? lastItemLayout.y + lastItemLayout.height : 0;
  }

  private calculateNewActiveIndex(activeDY: number): number {
    if (this.selectedItemIndex === -1) {
      return -1;
    }

    const activeItemLayout = this.items[this.selectedItemIndex].layout;
    const activeTopY = activeItemLayout.y + activeDY;
    const activeBotY = activeTopY + activeItemLayout.height;

    for (let i = 0; i < this.selectedItemIndex; i++) {
      const itemLayout = this.items[i].layout;
      const midY = itemLayout.y + itemLayout.height / 2;
      if (activeTopY < midY) {
        return i;
      }
    }

    for (let i = this.items.length - 1; i > this.selectedItemIndex; i--) {
      const itemLayout = this.items[i].layout;
      const midY = itemLayout.y + itemLayout.height / 2;
      if (activeBotY > midY) {
        return i;
      }
    }

    return this.selectedItemIndex;
  }

  private onNewActiveIdx(): void {
    const activeItemLayout = this.items[this.selectedItemIndex].layout;
    const modifyStartIdx = Math.min(
      this.selectedItemIndex,
      this.selectedItemNewIndex
    );
    const modifyEndIdx =
      modifyStartIdx +
      Math.abs(this.selectedItemIndex - this.selectedItemNewIndex);
    const vector = this.selectedItemNewIndex < this.selectedItemIndex ? 1 : -1;

    // Update offsets for all non-active items
    let totalDisplacement = 0;
    for (let i = 0; i < this.items.length; i++) {
      let y = 0;
      if (i >= modifyStartIdx && i <= modifyEndIdx) {
        if (i !== this.selectedItemIndex) {
          y = vector * activeItemLayout.height;
          totalDisplacement += this.items[i].layout.height;
        }
      }
      Animated.spring(this.offsets[i], {
        toValue: { x: 0, y },
        useNativeDriver: Platform.OS !== "web",
        isInteraction: true,
      }).start();
    }

    // Update offset for active (placeholder) item
    this.offsets[this.selectedItemIndex].setValue({
      x: 0,
      y: -vector * totalDisplacement,
    });
  }
}
