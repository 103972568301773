import * as React from "react";
import { useCallback, useRef, useState } from "react";
import {
  LayoutChangeEvent,
  LayoutRectangle,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";
import Svg, { G, Path, Text as SvgText } from "react-native-svg";
import styled, { useTheme } from "styled-components/native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { useScrollViewController } from "@app/components/questkit/ScrollView";
import { CircularButton } from "@app/components/questkit/CircularButton";
import { DrawingCanvas } from "@app/components/item/components/signature/DrawingCanvas";

interface SignaturePadProps {
  paths?: string[];
  onChange?: (svgPaths: string[]) => void;
  onDone?: (svgPaths: string[]) => void;
  containerStyle?: StyleProp<ViewStyle>;
  width?: `${number}%` | number;
  height?: `${number}%` | number;
  strokeColor?: string;
  strokeWidth?: number;
}

const SignaturePad: React.FC<SignaturePadProps> = (props) => {
  const {
    containerStyle = {},
    width = "100%",
    height = 200,
    paths: initialPaths,
    onDone = () => undefined,
    onChange = () => undefined,
  } = props;
  const scrollViewController = useScrollViewController();
  const theme = useTheme();

  const [paths, setPaths, pathsRef] = useStateWithRef<string[]>(
    initialPaths ?? []
  );
  const [signatureLayout, setSignatureLayout] = useState<LayoutRectangle>();
  const shouldScrollToComponent = useRef(true);
  const onSignatureContainerLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setSignatureLayout(e.nativeEvent.layout);
      if (signatureContainerRef.current && shouldScrollToComponent.current) {
        shouldScrollToComponent.current = false;
        scrollViewController.scrollToComponent(signatureContainerRef, {
          animated: true,
          componentAnchor: "center",
          scrollViewAnchor: "center",
        });
      }
    },
    [scrollViewController]
  );
  const signatureContainerRef = useRef<View>(null);
  useEffectOnce(() => {
    scrollViewController.setScrollEnabled(false);
    scrollViewController.parentController?.setScrollEnabled(false);
    return () => {
      scrollViewController.setScrollEnabled(true);
      scrollViewController.parentController?.setScrollEnabled(true);
    };
  });

  const onPressDone = useCallback(() => {
    onDone(pathsRef.current);
  }, [onDone, pathsRef]);

  const onPressReset = useCallback(() => {
    setPaths([]);
  }, [setPaths]);

  const onPathAdd = useCallback(
    (newPath: string) => {
      const newPaths = [...pathsRef.current, newPath];
      setPaths(newPaths);
      onChange(newPaths);
    },
    [onChange, pathsRef, setPaths]
  );

  return (
    <SignatureContainer
      ref={signatureContainerRef}
      onLayout={onSignatureContainerLayout}
      style={[containerStyle, { width, height }]}
    >
      {signatureLayout ? (
        <UnderlaySvg width={width} height={height}>
          <G>
            <Path
              d={`M ${16}, ${signatureLayout.height - 56} H ${
                signatureLayout.width - 16
              }`}
              stroke={theme.primary}
              strokeWidth={1}
              fill="none"
            />
            <SvgText
              x={signatureLayout.width * 0.5}
              y={signatureLayout.height - 24}
              fontSize={16}
              textAnchor={"middle"}
              fontFamily={"CentraNo2-Book"}
              fill={theme.primary}
            >
              Sign Above
            </SvgText>
          </G>
        </UnderlaySvg>
      ) : null}
      <DrawingCanvas
        width={width}
        height={height}
        paths={paths}
        onPathAdd={onPathAdd}
      />
      <ResetButton
        onPress={onPressReset}
        icon={"trash"}
        buttonTheme={"warning"}
      />
      <SaveButton
        onPress={onPressDone}
        icon={"checkmark"}
        buttonTheme={"action"}
      />
    </SignatureContainer>
  );
};

const SignatureContainer = styled.View`
  cursor: crosshair;
  height: 100%;
`;

export default SignaturePad;

const ResetButton = styled(CircularButton)`
  position: absolute;
  left: 12px;
  bottom: 12px;
`;

const SaveButton = styled(CircularButton)`
  position: absolute;
  right: 12px;
  bottom: 12px;
`;

const UnderlaySvg = styled(Svg)`
  position: absolute;
  user-select: none;
`;
