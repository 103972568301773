import { ComponentDefinition } from "@questmate/questscript";
import { ValidIconName } from "@app/components/icon";
import {
  ButtonComponentModel,
  type CardComponentModel,
  type CheckableComponentModel,
  DropdownComponentModel,
  ItemPickerComponentModel,
  LongAnswerComponentModel,
  NavigationActionComponentModel,
  QuestDataPickerComponentModel,
  ShortAnswerComponentModel,
  SwitchComponentModel,
  TextComponentModel,
  CopyTextComponentModel,
} from "@app/components/item/components/custom/types";

type StaticDropdownComponentModel = Pick<
  DropdownComponentModel,
  | "title"
  | "id"
  | "type"
  | "optionNoun"
  | "optionPluralNoun"
  | "placeholderIcon"
  | "autoFocus"
>;

type StaticSwitchComponentModel = Pick<
  SwitchComponentModel,
  "title" | "id" | "type"
>;

type StaticButtonComponentModel = Pick<
  ButtonComponentModel,
  "title" | "id" | "type" | "buttonLabel"
>;

type StaticNavigationActionComponentModel = NavigationActionComponentModel;

type StaticItemPickerComponentModel = Pick<
  ItemPickerComponentModel,
  "title" | "id" | "type"
>;
type StaticQuestDataPickerComponentModel = Pick<
  QuestDataPickerComponentModel,
  "title" | "id" | "type"
>;

type StaticShortAnswerComponentModel = Pick<
  ShortAnswerComponentModel,
  "title" | "id" | "type" | "placeholder" | "secure"
>;

type StaticLongAnswerComponentModel = Pick<
  LongAnswerComponentModel,
  "title" | "id" | "type" | "placeholder"
>;

type StaticTextComponentModel = Pick<
  TextComponentModel,
  "title" | "id" | "type" | "content" | "contentColor"
>;

type StaticCopyTextComponentModel = Pick<
  CopyTextComponentModel,
  "title" | "id" | "type" | "content"
>;

type StaticCheckableComponentModel = Pick<
  CheckableComponentModel,
  "title" | "id" | "type"
>;

type StaticCardComponentModel = Pick<
  CardComponentModel,
  "title" | "id" | "type"
>;

type ComponentTypeToStaticComponentModel = {
  dropdown: StaticDropdownComponentModel;
  switch: StaticSwitchComponentModel;
  button: StaticButtonComponentModel;
  text: StaticTextComponentModel;
  CopyText: StaticCopyTextComponentModel;
  ItemPicker: StaticItemPickerComponentModel;
  QuestDataPicker: StaticQuestDataPickerComponentModel;
  NavigationAction: StaticNavigationActionComponentModel;
  ShortAnswer: StaticShortAnswerComponentModel;
  LongAnswer: StaticLongAnswerComponentModel;
  Checkable: StaticCheckableComponentModel;
  Card: StaticCardComponentModel;
};

type StaticComponentModel<T extends keyof ComponentTypeToStaticComponentModel> =
  ComponentTypeToStaticComponentModel[T];

/**
 * Maps the static properties of a component definition to a component model
 * Note: used by both V1 & V2 views
 */
export function mapStaticProps<T extends ComponentDefinition>(
  component: T
): StaticComponentModel<T["type"]> {
  switch (component.type) {
    case "dropdown":
      return {
        id: component.id,
        title: component.title,
        type: "dropdown",
        optionNoun: component.optionNoun ?? "Record",
        optionPluralNoun: component.optionPluralNoun ?? "Records",
        placeholderIcon: (component.placeholderIcon as ValidIconName) ?? "item",
        autoFocus: component.autoFocus ?? false,
      } as StaticComponentModel<T["type"]>;
    case "button":
      return {
        id: component.id,
        title: component.title,
        type: "button",
        buttonLabel: component.buttonLabel,
      } as StaticComponentModel<T["type"]>;
    case "switch":
      return {
        id: component.id,
        title: component.title,
        type: "switch",
      } as StaticComponentModel<T["type"]>;
    case "ItemPicker":
      return {
        id: component.id,
        title: component.title,
        type: "ItemPicker",
      } as StaticComponentModel<T["type"]>;
    case "QuestDataPicker":
      return {
        id: component.id,
        title: component.title,
        type: "QuestDataPicker",
      } as StaticComponentModel<T["type"]>;
    case "NavigationAction":
      return {
        id: component.id,
        title: component.title,
        type: "NavigationAction",
        labelText: component.labelText,
        to: component.to,
        disabled: component.disabled,
        newTabOnWeb: component.newTabOnWeb === true,
        autoRedirectDelay:
          typeof component.autoRedirectDelay === "number" &&
          component.autoRedirectDelay >= 1 // 1 second
            ? component.autoRedirectDelay
            : undefined,
      } as StaticComponentModel<T["type"]>;
    case "text":
      return {
        id: component.id,
        title: component.title,
        type: "text",
        content: component.content,
        contentColor: component.contentColor,
      } as StaticComponentModel<T["type"]>;
    case "CopyText":
      return {
        id: component.id,
        title: component.title,
        type: "CopyText",
        content: component.content,
      } as StaticComponentModel<T["type"]>;
    case "ShortAnswer":
      return {
        id: component.id,
        title: component.title,
        placeholder: component.placeholder,
        secure: component.secure ?? false,
        type: "ShortAnswer",
      } as StaticComponentModel<T["type"]>;
    case "LongAnswer":
      return {
        id: component.id,
        title: component.title,
        placeholder: component.placeholder,
        type: "LongAnswer",
      } as StaticComponentModel<T["type"]>;
    case "Checkable":
      return {
        id: component.id,
        title: component.title,
        type: "Checkable",
      } as StaticComponentModel<T["type"]>;
    case "Card":
      return {
        id: component.id,
        title: component.title,
        type: "Card",
      } as StaticComponentModel<T["type"]>;
    default:
      throw createErrorForMissingComponentType(component);
  }
}

/**
 * Throws an error for an unknown component type.
 * Specifying the param as `never` ensures that a type error will occur if a new component type is added
 * without updating this function.
 */
function createErrorForMissingComponentType(component: never) {
  return new Error(
    `Unknown component type: ${(component as { type: string }).type}`
  );
}
