import React, { PropsWithChildren, useCallback } from "react";
import styled from "styled-components/native";
import { Platform, Pressable } from "react-native";
import { useSideBarContext } from "@app/navigation/sideBar/SideBarProvider";
import { SideBarContent } from "@app/navigation/sideBar/SideBarContent";
import { useAppSelector } from "@app/store";
import { selectUserIsLoggedIn } from "@app/store/auth";
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
} from "react-native-reanimated";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

const OVERLAY_OPACITY = 0.5;
export const SideBar: React.FC<PropsWithChildren> = () => {
  const {
    drawerSlideAnimation,
    overlayOpacityAnimation,
    sideBarWidth,
    closeDrawer,
  } = useSideBarContext();

  const [isOverlayShown, setIsOverlayShown, isOverlayShownRef] =
    useStateWithRef(overlayOpacityAnimation.value === 1);

  const onNewOpacityValue = useCallback(
    (value: number) => {
      const newOverlayIsShown = value === 1;
      if (newOverlayIsShown !== isOverlayShownRef.current) {
        setIsOverlayShown(newOverlayIsShown);
      }
    },
    [setIsOverlayShown, isOverlayShownRef]
  );

  useAnimatedReaction(
    // Reduce reactions to 10 times per animation as they are costly to send over JS in realtime.
    () => Math.round(10 * overlayOpacityAnimation.value) / 10,
    (value) => {
      runOnJS(onNewOpacityValue)(value);
    },
    [onNewOpacityValue]
  );

  const userIsLoggedIn = useAppSelector(selectUserIsLoggedIn);

  const overlayStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        overlayOpacityAnimation.value,
        [0, 1],
        [0, OVERLAY_OPACITY]
      ),
    };
  }, []);
  const drawerStyle = useAnimatedStyle(() => {
    return {
      left: interpolate(drawerSlideAnimation.value, [0, 1], [-sideBarWidth, 0]),
      ...(Platform.OS === "web"
        ? {
            visibility: drawerSlideAnimation.value > 0 ? "visible" : "hidden",
          }
        : {}),
    };
  }, [sideBarWidth]);

  return (
    <>
      <DrawerOpenOverlay
        onPress={closeDrawer}
        style={overlayStyle}
        pointerEvents={isOverlayShown ? "auto" : "none"}
      />
      <DrawerContainer width={sideBarWidth} style={drawerStyle}>
        {userIsLoggedIn && <SideBarContent />}
      </DrawerContainer>
    </>
  );
};

const DrawerOpenOverlay = styled(Animated.createAnimatedComponent(Pressable))`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  ${Platform.OS === "web" ? `cursor: default` : ``}
`;

const DrawerContainer = styled(Animated.View)<{ width: number }>`
  position: absolute;
  height: 100%;
  width: ${({ width }) => width}px;
`;
