import React, { useRef } from "react";
import styled from "styled-components/native";
import { fetchAssignments } from "@app/util/client/requests/assignments";
import { useAppSelector } from "@app/store";
import { selectOpenAndInReviewUserAssignments } from "@app/store/cache/assignments";
import {
  createSideBarItemRowIcon,
  SideBarItem,
} from "@app/navigation/sideBar/items/SideBarItem";
import { Text } from "@app/components/questkit/text";
import isEqual from "react-fast-compare";
import { useRequest } from "@app/util/client/requests";

const AssignmentCounter: React.FC<{
  isHovered: boolean;
  isActive: boolean;
}> = ({ isHovered, isActive }) => {
  useRequest(fetchAssignments(["OPEN", "IN_REVIEW"]));
  const openAssignments = useAppSelector(
    selectOpenAndInReviewUserAssignments,
    isEqual
  );

  if (!openAssignments || openAssignments.length === 0) return null;

  return (
    <AssignmentCounterContainer isHovered={isHovered} isActive={isActive}>
      <AssignmentCounterText
        size={"small"}
        isHovered={isHovered}
        isActive={isActive}
      >
        {openAssignments.length}
      </AssignmentCounterText>
    </AssignmentCounterContainer>
  );
};

const AssignmentCounterText = styled(Text)<{
  isHovered: boolean;
  isActive: boolean;
}>`
  color: ${({ theme }) => theme.sideBar.assignmentCounter.text};
  align-self: center;
  padding-horizontal: 8px;
`;
const AssignmentCounterContainer = styled.View<{
  isHovered: boolean;
  isActive: boolean;
}>`
  align-content: center;
  justify-content: center;
  border-radius: 16px;
  min-width: 40px;
  height: 26px;
  overflow: hidden;
  margin-right: 20px;
  background-color: ${({ theme }) =>
    theme.sideBar.assignmentCounter.background};
`;

const WorkIcon = createSideBarItemRowIcon("assignment");

export const SideBarAssignmentsItem: React.FC = () => {
  const screenParams = useRef({ filters: {} }).current;
  return (
    <SideBarItem
      label={"My assignments"}
      screenName={"Assignments"}
      screenParams={screenParams}
      componentLeft={WorkIcon}
      componentRight={AssignmentCounter}
    />
  );
};
