import { SupportedPollerType, PollerState } from "@app/store/polling";
import { useAppDispatch, store } from "@app/store";
import { useMemo, useCallback, useRef } from "react";
import isEqual from "react-fast-compare";
import { usePromiseKeeper } from "@app/context/PromiseKeeper";

export type PromisePollerFactory<I, O> = (
  identifier: string,
  pollingParameters: I
) => Promise<O>;

type Poller<I, O> = {
  getState: (identifier: string) => PollerState | undefined;
  isPolling: (identifier: string, pollingParameters: I) => boolean;
  poll: (identifier: string, pollingParameters: I) => Promise<O>;
};
export function usePoller<I, O>(
  pollerType: SupportedPollerType,
  promisePollerFactory: PromisePollerFactory<I, O>
): Poller<I, O> {
  const dispatch = useAppDispatch();
  const promiseKeeper = usePromiseKeeper();

  const getPollingState = useCallback(
    (identifier: string) => {
      // We use the getState() function here instead of `useAppSelector` because the latter can result in stale state between re-renders
      return store.getState().polling.pollers[pollerType][identifier];
    },
    [pollerType]
  );
  const promisePollerFactoryRef = useRef(promisePollerFactory);
  promisePollerFactoryRef.current = promisePollerFactory;

  return useMemo((): Poller<I, O> => {
    return {
      getState: getPollingState,
      isPolling(identifier: string, pollingParameters: I) {
        const state = getPollingState(identifier);
        return (
          state &&
          state.status === "POLLING" &&
          isEqual(state.pollingParameters, pollingParameters)
        );
      },
      poll(identifier: string, pollingParameters: I) {
        const promisePollerFactory = promisePollerFactoryRef.current;
        let promiseId: string;
        if (this.isPolling(identifier, pollingParameters)) {
          promiseId = getPollingState(identifier).promiseId;
        } else {
          const pollingPromise = promisePollerFactory(
            identifier,
            pollingParameters
          ).finally(() => {
            dispatch({
              type: "polling/finished",
              payload: {
                pollerType,
                identifier,
                pollingParameters,
              },
            });
          });
          promiseId = promiseKeeper.addPromise(pollingPromise);
          dispatch({
            type: "polling/started",
            payload: {
              pollerType,
              pollerId: identifier,
              promiseId,
              pollingParameters,
            },
          });
        }

        return promiseKeeper.getPromise<O>(promiseId)!;
      },
    };
  }, [getPollingState, promiseKeeper, dispatch, pollerType]);
}
