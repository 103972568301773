import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import Button from "@app/components/questkit/button";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";

interface LinkAppPromptProps {
  buttonText: string;
  infoText: string;
  link: () => void;
  loading: boolean;
  userCanLink?: boolean;
}

export const LinkAppPrompt: React.FC<LinkAppPromptProps> = ({
  link,
  loading,
  userCanLink = true,
  buttonText,
  infoText,
}) => {
  return (
    <>
      {userCanLink ? (
        <LinkButton title={buttonText} onPress={link} loading={loading} />
      ) : null}
      <InfoTextSection>
        {userCanLink ? <ItemInfoIcon icon={"info"} /> : <Emoji>⚠️</Emoji>}
        <Text size="medium">{infoText}</Text>
      </InfoTextSection>
    </>
  );
};

const InfoTextSection = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LinkButton = styled(Button)`
  margin-bottom: 20px;
`;

const Emoji = styled(Text)`
  margin-left: 5px;
  margin-right: 11px;
`;
