import React from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { Platform } from "react-native";
import Icon, { IconIdentifier } from "@app/components/icon";
import BasePressable from "@app/components/questkit/BasePressable";
import { OnLinkPress } from "@app/util/link.utils";

export const TabBarItem: React.FC<{
  title: string;
  titleIcon?: IconIdentifier;
  active: boolean;
  onPress: OnLinkPress | (() => void);
  testID?: string;
}> = ({ title, titleIcon, active, onPress, testID }) => {
  return (
    <TabBarItemWrapper active={active} onPress={onPress} testID={testID}>
      {titleIcon ? (
        <IconWrapper>
          <Icon size={24} icon={titleIcon} disabled={!active} />
        </IconWrapper>
      ) : null}
      <TabBarItemText size={"small"} active={active}>
        {title}
      </TabBarItemText>
    </TabBarItemWrapper>
  );
};

export const IconWrapper = styled.View`
  width: 32px;
  height: 19px;
  justify-content: center;
  align-items: center;
`;
export const TabBar = styled.View`
  flex-direction: row;
  padding-bottom: 20px;
`;

const TabBarItemWrapper = styled(BasePressable)<{ active: boolean }>`
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme, active }) =>
    active ? theme.primary : theme.inactive};
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const TabBarItemText = styled(Text)<{ active: boolean }>`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
  color: ${({ theme, active }) => (active ? theme.primary : theme.inactive)};
`;
