import { AppId } from "@questmate/openapi-spec";
import React, { useMemo } from "react";
import { useRequest } from "@app/util/client/requests";
import { fetchLibraryApp } from "@app/util/client/requests/library";
import { createLink } from "@app/util/link.utils";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";
import { Text } from "@app/components/questkit/text";
import Icon, { NO_ICON } from "@app/components/icon";
import styled from "styled-components/native";
import BasePressable from "@app/components/questkit/BasePressable";

type ClickableAppLabelProps = {
  appId: AppId;
};
export const ClickableAppLabel: React.FC<ClickableAppLabelProps> = ({
  appId,
}) => {
  const { data: app } = useRequest(fetchLibraryApp(appId));
  const onPress = useMemo(() => {
    if (!app) {
      return;
    }
    return createLink(app.infoPageUrl, { newTabOnWeb: true });
  }, [app]);
  if (!app) {
    return <QKActivityIndicator />;
  }
  return (
    <ClickableAppLabelContainer onPress={onPress}>
      <Icon icon={app.logoIconUrl ? { url: app.logoIconUrl } : NO_ICON} />
      <Text>{app.name}</Text>
    </ClickableAppLabelContainer>
  );
};

const ClickableAppLabelContainer = styled(BasePressable)`
  flex-direction: row;
  align-items: center;
`;
