import React, { useCallback, useContext } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { ItemRenderData } from "@app/types/itemRenderData";
import { Platform, Pressable, View } from "react-native";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import TextInput from "@app/components/questkit/textInput";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import {
  ItemDetail,
  ItemType,
  QuestStartTriggerType,
} from "@questmate/openapi-spec";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import { IconIdentifier } from "@app/components/icon";
import { OnItemChangeHandler } from "@app/util/client/hooks/useQuestInstance";
import { createSubItem } from "@app/components/item/components/multiSelect";

interface ItemOptionsDialogProps {
  item: ItemRenderData;
  parentQuestType: QuestStartTriggerType | null;
  onItemChange: OnItemChangeHandler;
  onModalClose: () => void;
}

type ItemTypeViewData = {
  value: ItemType;
  label: string;
  icon: IconIdentifier;
  searchTerms: string[];
  isAdvanced?: boolean;
  initialConfig?: Partial<Pick<ItemDetail, "name" | "defaults">>;
};
export const itemTypeViewDataMap: Record<ItemType, ItemTypeViewData> = {
  Text: {
    value: "Text",
    label: "Short Answer",
    icon: "text",
    searchTerms: ["name", "text"],
    initialConfig: {
      // name: "Short Answer",
    },
  },
  MultiLineText: {
    value: "MultiLineText",
    label: "Long Answer",
    icon: "paragraph",
    searchTerms: ["feedback", "description", "text", "explanation", "address"],
    initialConfig: {
      // name: "Long Answer",
    },
  },
  Email: {
    value: "Email",
    label: "Email",
    icon: "mail",
    searchTerms: ["text", "e-mail", "address"],
    initialConfig: {
      // name: "Email",
    },
  },
  Amount: {
    value: "Amount",
    label: "Amount",
    icon: "numbers",
    searchTerms: ["value", "price", "count", "score"],
    initialConfig: {
      // name: "Amount",
    },
  },
  Checkbox: {
    value: "Checkbox",
    label: "Checkbox",
    icon: "checkmark-encircled",
    searchTerms: ["list", "todo", "task"],
  },
  Checklist: {
    value: "Checklist",
    label: "Checklist",
    icon: "checklist",
    searchTerms: ["list", "todo", "task"],
    initialConfig: {
      // name: "Checklist",
      defaults: {
        // items: ["Task A", "Task B", "Task C", ""].map(createSubItem),
        items: [""].map(createSubItem),
      },
    },
  },
  SingleSelect: {
    value: "SingleSelect",
    label: "Single Select",
    icon: "underline-checkmark",
    searchTerms: ["radio", "option", "choice", "choose"],
    initialConfig: {
      // name: "Select an option",
      defaults: {
        // items: ["Option A", "Option B", "Option C", ""].map(createSubItem),
        items: [""].map(createSubItem),
      },
    },
  },
  MultiSelect: {
    value: "MultiSelect",
    label: "Multi Select",
    icon: "double-tick",
    searchTerms: ["option", "choice", "choose"],
    initialConfig: {
      // name: "Select options",
      defaults: {
        // items: ["Option A", "Option B", "Option C", ""].map(createSubItem),
        items: [""].map(createSubItem),
      },
    },
  },
  Rating: {
    value: "Rating",
    label: "Rating",
    icon: "half-filled-star",
    searchTerms: ["feedback", "star", "score"],
    initialConfig: {
      // name: "Set rating",
    },
  },
  Date: {
    value: "Date",
    label: "Date",
    icon: "date",
    searchTerms: [
      "birthday",
      "birthdate",
      "anniversary",
      "due",
      "day",
      "when",
      "booking",
    ],
    initialConfig: {
      // name: "Date",
    },
  },
  DateTime: {
    value: "DateTime",
    label: "Date & Time",
    icon: "date-time",
    searchTerms: ["due", "when", "booking", "schedule"],
    initialConfig: {
      // name: "Date & time",
    },
  },
  FileUpload: {
    value: "FileUpload",
    label: "File Upload",
    icon: "upload-file",
    searchTerms: [
      "picture",
      "photo",
      "image",
      "video",
      "audio",
      "document",
      "zip",
      "folder",
      "pdf",
    ],
    initialConfig: {
      // name: "File Upload",
    },
  },
  Subquest: {
    value: "Subquest",
    label: "Subquest",
    icon: "switch",
    searchTerms: ["group", "subtask"],
    initialConfig: {
      // name: "Subquest",
    },
  },
  Signature: {
    value: "Signature",
    label: "Signature",
    icon: "signature",
    searchTerms: [
      "authorize",
      "authorization",
      "permission",
      "consent",
      "agreement",
      "contract",
      "sign",
      "review",
    ],
    initialConfig: {
      // name: "Signature",
    },
  },
  CustomV2: {
    value: "CustomV2",
    label: "Custom",
    icon: "terminal-prompt",
    searchTerms: ["script", "code"],
    initialConfig: {
      // name: "Custom Item",
    },
  },
  Video: {
    value: "Video",
    label: "Watch video",
    icon: "play-square",
    searchTerms: ["youtube"],
    initialConfig: {
      // name: "Video",
    },
  },

  Custom: {
    isAdvanced: true,
    value: "Custom",
    label: "Custom V1 (deprecated)",
    icon: "terminal-prompt",
    searchTerms: [],
  },
  Location: {
    isAdvanced: true,
    value: "Location",
    label: "Location",
    icon: "location-pin",
    searchTerms: [],
  }, // missing in design
  Switch: {
    isAdvanced: true,
    value: "Switch",
    label: "Switch",
    icon: "turn-off",
    searchTerms: [],
  }, // missing in design
  UserPicker: {
    isAdvanced: true,
    value: "UserPicker",
    label: "User Picker",
    icon: "group",
    searchTerms: [],
  }, // missing in design
  SlackMessage: {
    isAdvanced: true,
    value: "SlackMessage",
    label: "Slack Message",
    icon: "sms",
    searchTerms: [],
  }, // missing in design
  Schedule: {
    isAdvanced: true,
    value: "Schedule",
    label: "Schedule",
    icon: "calendar",
    searchTerms: [],
  }, // missing in design
};
export const itemTypeViewDataList = Object.values(
  itemTypeViewDataMap
) as ItemTypeViewData[];

const MULTI_SELECT_ITEM_TYPES = ["Checklist", "MultiSelect", "SingleSelect"];

const ItemOptionsDialog: React.FC<ItemOptionsDialogProps> = ({
  item,
  parentQuestType,
  onItemChange,
  onModalClose,
}) => {
  const { advancedMode } = useQuestViewContext(["MANAGE"]);
  const snackbarContext = useContext(SnackbarContext);

  const onItemTypeSelect = useCallback(
    (itemLabel: string) => {
      const itemType = itemTypeViewDataList.find(
        (itemType) => itemType.label === itemLabel
      )!;

      if (
        (parentQuestType === "KIOSK" || parentQuestType === "PUBLIC") &&
        itemType.value === "Subquest"
      ) {
        snackbarContext.sendMessage(
          "Cannot add Subquests to a public Quest",
          SnackbarSeverity.WARNING
        );
        return;
      }
      Analytics.trackEvent("Select Item Type", {
        itemType: itemType.value,
      });

      let keepData = false;

      if (
        MULTI_SELECT_ITEM_TYPES.includes(itemType.value) &&
        MULTI_SELECT_ITEM_TYPES.includes(item.prototype.type)
      ) {
        keepData = true;
      }

      onItemChange({
        ...item,
        data: keepData
          ? {
              ...item.data,
            }
          : {},
        prototype: {
          ...item.prototype,
          type: itemType.value,
          name:
            item.prototype.name ===
              itemTypeViewDataMap[item.prototype.type].initialConfig?.name ||
            item.prototype.name === ""
              ? itemType.initialConfig?.name || ""
              : item.prototype.name,
        },
      });

      onModalClose();
    },
    [item, onItemChange, onModalClose, parentQuestType, snackbarContext]
  );

  const onItemReferenceSlugChange = useCallback(
    (referenceSlug: string) => {
      Analytics.trackEventDebounced(
        item.prototype.id,
        "Set Item Reference Slug"
      );
      onItemChange({
        ...item,
        prototype: {
          ...item.prototype,
          referenceSlug,
        },
      });
    },
    [item, onItemChange]
  );

  const subquestId = item?.prototype.subquestId;

  return (
    <ItemOptionsDialogScrollView keyboardShouldPersistTaps="always">
      <View onStartShouldSetResponder={() => true}>
        {item?.prototype.type === "Subquest" && subquestId ? (
          <>
            <HeaderText size="large">Subquest Options</HeaderText>
            {/*<SectionSpacer />*/}
            {/*<HeaderText size="large">Reviewers</HeaderText>*/}
            {/*<ReviewerPicker questPrototypeId={subquestId} autoSave={true} />*/}
          </>
        ) : (
          <>
            <HeaderText size="large">Change item type</HeaderText>
            {itemTypeViewDataList
              .filter(({ isAdvanced }) => !isAdvanced || advancedMode)
              .map(({ value, label }) => (
                <ItemTypeOption
                  key={value}
                  onPress={onItemTypeSelect}
                  label={label}
                  inactive={item?.prototype.type !== value}
                />
              ))}
          </>
        )}
        <SectionSpacer />
        <HeaderText size="large">Reference Slug (optional)</HeaderText>
        <TextInput
          value={item?.prototype.referenceSlug || undefined}
          onChangeText={onItemReferenceSlugChange}
          placeholder="A unique name used to refer to this item in QuestScripts"
        />
      </View>
    </ItemOptionsDialogScrollView>
  );
};

const ItemTypeOption = ({
  onPress,
  label,
  inactive,
}: {
  onPress: (label: string) => void;
  label: string;
  inactive?: boolean;
}) => {
  const localOnPress = useCallback(() => onPress(label), [label, onPress]);
  return (
    <ItemTypeOptionPressable onPress={localOnPress}>
      <ItemTypeOptionText nativeID={`item-${label}`}>
        {label}
      </ItemTypeOptionText>
      <CheckboxToggle
        checked={!inactive}
        readOnly={false}
        action={localOnPress}
        accessibilityLabelledBy={`item-${label}`}
      />
    </ItemTypeOptionPressable>
  );
};

const ItemTypeOptionText = styled(Text)`
  ${() => (Platform.OS === "web" ? "user-select: none;" : "")}
`;
const ItemTypeOptionPressable = styled(Pressable)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ItemOptionsDialogScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 16,
  },
})``;

const HeaderText = styled(Text)`
  margin-bottom: 16px;
  ${() => (Platform.OS === "web" ? "user-select: none;" : "")}
`;

const SectionSpacer = styled.View`
  height: 30px;
`;

export default ItemOptionsDialog;
