import React, { useCallback } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";

import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import Button from "@app/components/questkit/button";

import * as Location from "expo-location";
import MapView, { Marker } from "react-native-maps";
import styled from "styled-components/native";

import { Platform } from "react-native";

import Text from "@app/components/questkit/text";
import { ENV } from "@app/config/env";

const changeLocation = (
  item: ItemRenderData,
  location: Location.LocationObject | null
): ItemRenderData => ({
  ...item,
  data: {
    location,
  },
  version: item.version + 1,
});

export const LocationItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange } = props;
  const location = item.data.location as Location.LocationObject;

  const onChangeLocation = useCallback(
    (location: Location.LocationObject | null) => {
      onItemChange?.(changeLocation(item, location));
    },
    [onItemChange, item]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <>
          <MapContainer>
            {location ? (
              <StyledMapView
                provider={
                  // We tried using PROVIDER_GOOGLE, but it only works on web
                  // if we use the string "google" explicitly :shrug:
                  Platform.OS === "web" ? "google" : undefined
                }
                initialRegion={{
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude,
                  latitudeDelta: 0.005,
                  longitudeDelta: 0.005,
                }}
                minZoomLevel={15}
                maxZoomLevel={15}
                zoomControlEnabled={false}
                zoomEnabled={false}
                scrollEnabled={false}
                pitchEnabled={false}
                rotateEnabled={false}
                followsUserLocation={false}
                showsUserLocation={false}
                showsMyLocationButton={false}
                showsCompass={false}
                showsScale={false}
                showsTraffic={false}
                toolbarEnabled={false}
                zoomTapEnabled={false}
                scrollDuringRotateOrZoomEnabled={false}
                camera={{
                  center: {
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                  },
                  heading: 90,
                  pitch: 5,
                  // Only on iOS MapKit, in meters. The property is ignored by Google Maps.
                  altitude: 300,
                  // Only when using Google Maps.
                  zoom: 15,
                }}
                googleMapsApiKey={ENV.googleMapsApiKey}
              >
                <Marker
                  coordinate={{
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                  }}
                />
              </StyledMapView>
            ) : (
              <MessageContainer>
                <Text>Check in to see location on map</Text>
              </MessageContainer>
            )}
          </MapContainer>
          {!location ? (
            <Button
              title="Check in"
              onPress={async () => {
                const { status } =
                  await Location.requestForegroundPermissionsAsync();
                if (status !== Location.PermissionStatus.GRANTED) {
                  return;
                } else {
                  const location = await Location.getCurrentPositionAsync({});
                  onChangeLocation(location);
                }
              }}
            />
          ) : null}
          {location ? (
            <Button
              title="Undo check-in"
              onPress={() => {
                onChangeLocation(null);
              }}
            />
          ) : null}
        </>
      }
    />
  );
};

const StyledMapView = styled(MapView)<{
  // todo: remove this when react-native-maps includes this prop in the types
  googleMapsApiKey: string;
}>`
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.View`
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.cardInCard};
`;

const MessageContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;
