import isEqual from "react-fast-compare";

/**
 * Check for object equality while ignoring key order and keys with undefined values.
 * JSON.stringify/JSON.parse will remove keys with undefined values and do not preserve key order.
 * This function will ignore those differences.
 */
export default function isEquivalent(
  value1: unknown,
  value2: unknown
): boolean {
  if (
    typeof value1 === "object" &&
    typeof value2 === "object" &&
    value1 !== null &&
    value2 !== null &&
    !Array.isArray(value1) &&
    !Array.isArray(value2)
  ) {
    const object1 = value1 as Record<string, unknown>;
    const object2 = value2 as Record<string, unknown>;
    const keys1 = Object.keys(object1).filter(
      (key) => object1[key] !== undefined
    );
    const keys2 = Object.keys(object2).filter(
      (key) => object2[key] !== undefined
    );
    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every((key) => isEquivalent(object1[key], object2[key]));
  }

  return isEqual(value1, value2);
}
