import * as React from "react";
import { useCallback, useRef } from "react";
import { View, ViewProps } from "react-native";
import { useEffectOnce } from "@app/util/useEffectOnce";
import {
  TouchDetector,
  TouchDetectorTouchEvent,
} from "@app/util/TouchDetector";
import { GestureResponderEvent } from "react-native/Libraries/Types/CoreEventTypes";

interface TouchBoundaryProps extends ViewProps {
  onTouchOutside: () => void;
}
export const TouchBoundary = React.forwardRef<View, TouchBoundaryProps>(
  ({ onTouchOutside, ...viewProps }, ref) => {
    const onTouchOutsideRef = useRef(onTouchOutside);
    onTouchOutsideRef.current = onTouchOutside;

    const lastTouchTimestampRef = useRef<number | null>(null);
    const lastTouchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const onTouchOutsideBoundary = useCallback(() => {
      lastTouchTimestampRef.current = null;
      lastTouchTimeoutRef.current = null;
      onTouchOutsideRef.current();
    }, []);

    const onTouchInsideBoundary = useCallback(() => {
      if (lastTouchTimeoutRef.current) {
        clearTimeout(lastTouchTimeoutRef.current);
      }
      lastTouchTimestampRef.current = null;
      lastTouchTimeoutRef.current = null;
    }, []);

    useEffectOnce(() => {
      const onTouch = ({ timestamp }: TouchDetectorTouchEvent) => {
        lastTouchTimestampRef.current = timestamp;
        lastTouchTimeoutRef.current = setTimeout(
          () => onTouchOutsideBoundary(),
          0
        );
      };
      TouchDetector.on("touch", onTouch);
      return () => {
        TouchDetector.off("touch", onTouch);
      };
    });

    const parentOnStartShouldSetResponderCapture =
      viewProps.onStartShouldSetResponderCapture;
    const onStartShouldSetResponderCapture = useCallback(
      (e: GestureResponderEvent) => {
        if (
          lastTouchTimestampRef.current &&
          lastTouchTimestampRef.current === e.nativeEvent?.timestamp
        ) {
          onTouchInsideBoundary();
        }
        return parentOnStartShouldSetResponderCapture?.(e) ?? false;
      },
      [onTouchInsideBoundary, parentOnStartShouldSetResponderCapture]
    );
    return (
      <View
        {...viewProps}
        ref={ref}
        onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
      />
    );
  }
);
TouchBoundary.displayName = "TouchBoundary";
