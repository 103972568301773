import { sentry } from "@app/util/sentry";
import React, { useContext } from "react";
import { ENV } from "@app/config/env";

export type LoginAppState = {
  url?: string;
  identifier?: string;
  priorLogin?: {
    accessToken: string;
    refreshToken: string;
  };
};
export type IAuth0Context = {
  openAuth0UniversalLogin: (options: {
    appState: LoginAppState;
    identifierHint?: string;
    connection?: string;
  }) => void;
  isAuthenticationResultAvailable: boolean;
  getUniversalLoginResult: () => Promise<
    UniversalLoginError | UniversalLoginSuccess
  >;
  logoutAuth0: (redirectPath?: string) => Promise<void>;
};
export type UniversalLoginError = {
  success: false;
  error: unknown;
};
export type UniversalLoginSuccess = {
  success: true;
  accessToken: string;
  refreshToken: string;
  userAttributes?: { email?: string };
  savedAppState?: LoginAppState;
};

export const Auth0Context = React.createContext<IAuth0Context>({
  openAuth0UniversalLogin: () => undefined,
  logoutAuth0: () => Promise.resolve(undefined),
  isAuthenticationResultAvailable: false,
  getUniversalLoginResult: () => {
    throw new Error("Auth0 Provider not yet initialized.");
  },
});

export const useAuth0Context = (): IAuth0Context => {
  const auth0ContextContext = useContext(Auth0Context);
  if (!auth0ContextContext) {
    throw new Error(
      "Attempted to use Auth0Context outside of a Auth0Context Provider"
    );
  }

  return auth0ContextContext;
};

/**
 * Most of our logout functionality is outside the React render cycle, so to avoid a larger refactor
 * we can use a global ref to the logout function. This is set in the Auth0Provider for web/native.
 */
export const Auth0LogoutGlobalRef: {
  current: IAuth0Context["logoutAuth0"];
} = {
  // @ts-expect-error this field is not on the type so that is not used directly
  _current: undefined,
  set current(value: IAuth0Context["logoutAuth0"]) {
    this._current = value;
  },
  get current() {
    if (!this._current) {
      const message =
        "Attempted to access Auth0 Logout function before it was set.";
      console.error(message);
      const error = new Error(message);
      sentry.captureException(error);
      if (ENV.throwGuardrailErrors) {
        throw error;
      }
    }
    return this._current;
  },
};
