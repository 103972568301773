import axios, { Method } from "axios";
import { sentry } from "@app/util/sentry";
import { cleanAxiosError } from "@questmate/common";
import { ENV } from "@app/config/env";

const { auth0ClientId, auth0BaseUrl, auth0Audience, auth0Scope } = ENV;

type OTPError = {
  success: false;
  errorCode: "PUBLIC_SIGNUP_DISABLED" | "UNKNOWN";
  error: unknown;
};

type OTPSuccess = {
  success: true;
};

type OTPResult = OTPSuccess | OTPError;

export const sendOneTimePassword = (
  connection: "sms" | "email",
  username: string
): Promise<OTPResult> => {
  const startData = {
    client_id: auth0ClientId,
    send: "code",
    connection,
    ...(connection === "sms"
      ? { phone_number: username }
      : { email: username }),
  };

  return axios
    .request({
      method: "POST" as Method,
      url: `${auth0BaseUrl}/passwordless/start`,
      headers: { "content-type": "application/json" },
      data: startData,
    })
    .then(() => {
      return {
        success: true,
      } as const;
    })
    .catch((error) => {
      // See other possible error codes here:
      // https://auth0.com/docs/api/authentication#post-passwordless-start
      const publicSignupsDisabled =
        error?.response?.data?.error_description ===
        "Public signup is disabled";

      if (!publicSignupsDisabled) {
        sentry.captureException(
          new Error("Unknown error response when requesting a one-time code."),
          { extra: { connection, originalError: cleanAxiosError(error) } }
        );
      }

      return {
        success: false,
        errorCode: publicSignupsDisabled ? "PUBLIC_SIGNUP_DISABLED" : "UNKNOWN",
        error: error?.response?.data,
      };
    });
};

type AuthenticationError = {
  success: false;
  errorCode:
    | "UNKNOWN"
    | "VERIFICATION_CODE_EXPIRED"
    | "INCORRECT_VERIFICATION_CODE";
  error: unknown;
};
type AuthenticationSuccess = {
  success: true;
  accessToken: string;
  refreshToken: string;
};
type AuthenticationResult = AuthenticationSuccess | AuthenticationError;

export const authenticateUserUsingOTP = (
  realm: "sms" | "email",
  username: string,
  otp: string
): Promise<AuthenticationResult> => {
  return axios
    .request({
      method: "POST" as Method,
      url: `${auth0BaseUrl}/oauth/token`,
      headers: { "content-type": "application/json" },
      data: {
        grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
        client_id: auth0ClientId,
        audience: auth0Audience,
        scope: auth0Scope,
        username,
        otp,
        realm,
      },
    })
    .then((response) => {
      return {
        success: true,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      } as AuthenticationSuccess;
    })
    .catch((error) => {
      let errorCode: AuthenticationError["errorCode"] = "UNKNOWN";

      if (
        error?.response?.data?.error_description ===
        "The verification code has expired. Please try to login again."
      ) {
        errorCode = "VERIFICATION_CODE_EXPIRED";
      } else if (error?.response?.data?.error === "invalid_grant") {
        errorCode = "INCORRECT_VERIFICATION_CODE";
      }

      if (errorCode === "UNKNOWN") {
        sentry.captureException(
          new Error(
            "Unknown error response when authenticating a user via OTP."
          ),
          { extra: { realm, originalError: cleanAxiosError(error) } }
        );
      }

      return {
        success: false,
        errorCode,
        error: error?.response?.data,
      };
    });
};
