import {
  PublicQuestAuthenticationRequiredResponse,
  Quests,
  QuestStartTriggerDetail,
  QuestStartTriggerType,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { selectAssignmentById } from "@app/store/cache/assignments";
import { selectUserById } from "@app/store/cache/users";
import { selectReviewerById } from "@app/store/cache/reviewers";
import { sentry } from "@app/util/sentry";
import { isNotUndefined } from "@questmate/common";

type QuestStartTrigger = Omit<
  UnionToIntersection<APIQuestStartTrigger>,
  "startConfiguration"
> & {
  startConfigurationId?: string;
  questPrototypeId: string;
};

const questStartTriggerAdapter = createEntityAdapter<QuestStartTrigger, string>(
  {
    selectId: (questStartTrigger) =>
      `${questStartTrigger.questPrototypeId}:${questStartTrigger.id}`,
  }
);

type StartTriggerWithConfig<T extends QuestStartTrigger> = T extends {
  type?: infer TYPE;
}
  ? TYPE extends QuestStartTriggerType
    ? {
        config: StartTriggerConfigByType<TYPE>;
      } & Omit<T, "config">
    : never
  : never;

type StartTriggerConfigByType<T extends QuestStartTriggerType> =
  T extends "PUBLIC"
    ? {
        publicSubmissionsRequireAuth: boolean;
        publicId: string;
        publicUrl: string;
      }
    : T extends "KIOSK"
    ? { publicId: string; publicUrl: string }
    : T extends "SCHEDULE"
    ? {
        runSchedule: string | null;
        runScheduleTimezone: string | null;
        runNextAt: string | null;
      }
    : T extends "BASIC"
    ? Record<string, never>
    : never;

export const selectQuestStartTriggerEditFields = createSelector(
  [
    (state) => state,
    (state, questPrototypeId, startTriggerId) =>
      selectQuestStartTriggerByComboId(
        state,
        `${questPrototypeId}:${startTriggerId}`
      ),
  ],
  (state, startTrigger) => {
    if (!startTrigger) return undefined;
    let startConfiguration;
    if (startTrigger.startConfigurationId) {
      startConfiguration = selectQuestStartConfigurationById(
        state,
        startTrigger.startConfigurationId
      );
      if (startConfiguration) {
        startConfiguration = {
          ...startConfiguration,
          assignments: startConfiguration.assignmentIds
            ?.map((assignmentId) => {
              const assignment = selectAssignmentById(state, assignmentId);
              if (!assignment) {
                return undefined;
              }
              return {
                ...assignment,
                assignee: selectUserById(state, assignment.assigneeId),
              };
            })
            .filter(isNotUndefined),
          reviewers: startConfiguration.reviewerIds
            ?.map((reviewerId) => {
              const reviewer = selectReviewerById(state, reviewerId);
              if (!reviewer) {
                return undefined;
              }
              return {
                ...reviewer,
                user: selectUserById(state, reviewer.userId),
              };
            })
            .filter(isNotUndefined),
        };
      }
    }
    if (!startConfiguration) {
      const error = new Error(
        "Unexpected: Start Configuration not found in cache."
      );
      sentry.captureException(error, {
        extra: {
          startTrigger,
          startConfigurationsInCache: state.cache.questStartConfigurations,
        },
      });
    }
    const startTriggerEditData = {
      ...startTrigger,
      startConfiguration: startConfiguration,
    };

    return startTriggerEditData as StartTriggerWithConfig<
      typeof startTriggerEditData
    >;
  }
);

export type StartTriggerEditFieldsForType<T extends QuestStartTriggerType> =
  Exclude<
    ReturnType<typeof selectQuestStartTriggerEditFields>,
    undefined
  > extends infer ST
    ? ST extends { type?: T }
      ? ST
      : never
    : never;

export const {
  selectById: selectQuestStartTriggerByComboId,
  selectEntities: selectAllQuestStartTriggersByComboId,
  selectAll: selectAllQuestStartTriggers,
} = questStartTriggerAdapter.getSelectors<AppState>(
  (state) => state.cache.questStartTriggers
);

const slice = createSlice({
  name: "cache/questStartTriggers",
  initialState: questStartTriggerAdapter.getInitialState(),
  reducers: {
    questStartTriggerLoaded: (
      state,
      action: PayloadAction<{
        startTrigger: Quests.TriggersPartialUpdate.ResponseBody;
        questPrototypeId: string;
      }>
    ) => {
      questStartTriggerAdapter.setOne(
        state,
        mapQuestStartTrigger({
          ...action.payload.startTrigger,
          questPrototypeId: action.payload.questPrototypeId,
        })
      );
    },
    minimalPublicQuestDataLoaded: (
      state,
      action: PayloadAction<
        PublicQuestAuthenticationRequiredResponse["startTrigger"]
      >
    ) => {
      questStartTriggerAdapter.upsertOne(
        state,
        mapQuestStartTrigger(action.payload)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) => {
        const questPrototypeId = action.payload.id;
        return questStartTriggerAdapter.upsertMany(
          state,
          action.payload.startTriggers.map((trigger) =>
            mapQuestStartTrigger({
              ...trigger,
              questPrototypeId,
            })
          )
        );
      })
      .addCase(userLogout, (state) =>
        questStartTriggerAdapter.removeAll(state)
      );
  },
});

const reducer = slice.reducer;
export default reducer;

export const { questStartTriggerLoaded, minimalPublicQuestDataLoaded } =
  slice.actions;

type APIQuestStartTrigger =
  | PublicQuestAuthenticationRequiredResponse["startTrigger"]
  | (QuestStartTriggerDetail & {
      questPrototypeId: string;
    });

const mapQuestStartTrigger = createDataMapper<
  APIQuestStartTrigger,
  QuestStartTrigger
>()(["id", "type", "config", "enabled", "questPrototypeId"], {
  startConfiguration: (startConfiguration) =>
    startConfiguration.id
      ? { startConfigurationId: startConfiguration.id! }
      : {},
  formPrototype: (formPrototype) =>
    formPrototype?.id ? { questPrototypeId: formPrototype.id } : {},
});
