import type { ReactNode } from "react";
import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit/text";
import BasePressable from "@app/components/questkit/BasePressable";

interface RewardContainerProps {
  title: string;
  inlineNode?: ReactNode;
  blockNode?: ReactNode;
  onHeaderPress?: () => void;
}

/**
 * @deprecated Use ItemContainer going forward
 */
const RewardContainer: React.FC<RewardContainerProps> = ({
  title,
  blockNode,
  inlineNode,
  onHeaderPress,
}) => {
  return (
    <RewardContentContainer>
      <HeaderRow onPress={onHeaderPress}>
        <HeaderText size="large">{title}</HeaderText>
        {inlineNode}
      </HeaderRow>
      {blockNode ? <BlockNodeWrap>{blockNode}</BlockNodeWrap> : null}
    </RewardContentContainer>
  );
};

export const RewardContentContainer = styled.View`
  margin-left: 20px;
  margin-right: 20px;
`;

const BlockNodeWrap = styled.View`
  margin-top: 16px;
`;

export const HeaderRow = styled(BasePressable)`
  flex-direction: row;
  cursor: ${({ onPress }) => (onPress ? "pointer" : "default")};
`;

export const HeaderText = styled(Text)`
  margin-right: 12px;
  flex: 1;
`;

// eslint-disable-next-line deprecation/deprecation
export default RewardContainer;
