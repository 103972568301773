import { store } from "@app/store";
import {
  assignmentAdded,
  assignmentRemoved,
  assignmentsLoaded,
} from "@app/store/cache/assignments";
import {
  AssignmentListItem,
  AssignmentOnQuest,
  QuestInstanceStatus,
} from "@questmate/openapi-spec";
import { apiRequest } from "@app/util/client";
import { createRequest } from "@app/util/client/requests/index";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";

export const addAssignees = (
  questPrototypeId: string,
  identifiers: string[] = [],
  userIds: string[] = []
): Promise<AssignmentOnQuest[]> =>
  apiRequest<AssignmentOnQuest[]>(
    "post",
    `/quests/${questPrototypeId}/assignments`,
    {
      identifiers,
      userIds,
    }
  ).then((response) => {
    response.forEach((assignment) => {
      store.dispatch(assignmentAdded(assignment));
    });
    return response;
  });

export const removeAssignment = (
  questPrototypeId: string,
  assignmentId: string
): Promise<AssignmentOnQuest> =>
  apiRequest<AssignmentOnQuest>(
    "delete",
    `/quests/${questPrototypeId}/assignments/${assignmentId}`
  ).then((response) => {
    store.dispatch(assignmentRemoved(response));
    return response;
  });

export const fetchAssignments = (statusesToInclude: QuestInstanceStatus[]) => {
  const params = new URLSearchParams({});
  (statusesToInclude || []).map((status) => {
    params.append("status[]", status);
  });
  return createRequest<AssignmentListItem[]>(
    "get",
    `/assignments?${params.toString()}`
  )((response) => {
    store.dispatch(
      assignmentsLoaded({
        assignments: response,
        assigneeId: getLoggedInUserId()!,
      })
    );
  });
};

type UpdateAssigneesResult<T extends { assigneeId: string; id: string }> = {
  errors: {
    action: "ADD" | "REMOVE";
    userId: string;
    error: unknown;
  }[];
  assignmentsAdded: AssignmentOnQuest[];
  assignmentsRemoved: T[];
};

export const updateQuestAssignees = async <
  T extends { assigneeId: string; id: string }
>(
  questPrototypeId: string,
  existingAssignments: T[],
  desiredAssignees: { id: string }[]
): Promise<UpdateAssigneesResult<T>> => {
  const result: UpdateAssigneesResult<T> = {
    errors: [],
    assignmentsAdded: [],
    assignmentsRemoved: [],
  };
  const assignmentsToDelete = existingAssignments.filter(
    (existingAssignment) => {
      return !desiredAssignees.find(
        ({ id }) => id === existingAssignment.assigneeId
      );
    }
  );
  const promises: Promise<unknown>[] = [];
  for (const assignment of assignmentsToDelete) {
    promises.push(
      removeAssignment(questPrototypeId, assignment.id)
        .then(() => {
          result.assignmentsRemoved.push(assignment);
        })
        .catch((error) => {
          result.errors.push({
            action: "REMOVE",
            userId: assignment.assigneeId,
            error,
          });
        })
    );
  }

  const assigneesToAdd = desiredAssignees.filter(({ id }) => {
    return !existingAssignments.find(
      (existingAssignment) => existingAssignment.assigneeId === id
    );
  });

  if (assigneesToAdd.length > 0) {
    promises.push(
      addAssignees(
        questPrototypeId,
        [],
        assigneesToAdd.map(({ id }) => id)
      )
        .then((newAssignments) => {
          result.assignmentsAdded.push(...newAssignments);
        })
        .catch((error) => {
          result.errors.push(
            ...assigneesToAdd.map(({ id }) => ({
              action: "ADD" as const,
              userId: id,
              error,
            }))
          );
        })
    );
  }

  await Promise.all(promises);

  return result;
};
