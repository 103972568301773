import QKModal, { ModalDisplayMode } from "@app/components/modal";
import React, { ReactNode, useState } from "react";
import Button from "@app/components/questkit/button";
import styled from "styled-components/native";
import { CustomItemQuestScriptResponse } from "@app/components/item/components/custom/types";
import { TabBar, TabBarItem } from "@app/components/questkit/TabBar";
import { CodeEditor } from "@app/components/item/components/custom/edit/CodeEditor";
import { Platform } from "react-native";

type CustomComponentEditorProps = {
  script: string;
  onChangeScript: (script: string) => void;
  onScriptSaveTriggered: (explicitSave: boolean) => void;
  sideContent?: ReactNode;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  configViewResponse?: CustomItemQuestScriptResponse;
  runViewResponse?: CustomItemQuestScriptResponse;
};
export const CustomItemModalEditor: React.FC<CustomComponentEditorProps> = ({
  script,
  onChangeScript,
  onScriptSaveTriggered,
  sideContent,
  showModal,
  setShowModal,
  configViewResponse,
  runViewResponse,
}) => {
  const [editorTab, setEditorTab] = useState<
    "script" | "configViewResponse" | "runViewResponse" | "views"
  >(Platform.OS === "web" ? "script" : "views");

  return (
    <QKModal
      showModal={showModal}
      setShowModal={setShowModal}
      title={"Quest Script Editor"}
      displayMode={ModalDisplayMode.FULL_SCREEN}
    >
      <ModalContainer>
        <MainContent>
          <EditorWrapper>
            <TabBar>
              {Platform.OS !== "web" && (
                <TabBarItem
                  title={`Views`}
                  active={editorTab === "views"}
                  onPress={() => setEditorTab("views")}
                />
              )}
              <TabBarItem
                title={"Script"}
                active={editorTab === "script"}
                onPress={() => setEditorTab("script")}
              />
              <TabBarItem
                title={`Config Response${
                  configViewResponse && configViewResponse.status !== "ok"
                    ? " ⚠️"
                    : ""
                }`}
                active={editorTab === "configViewResponse"}
                onPress={() => setEditorTab("configViewResponse")}
              />
              <TabBarItem
                title={`Run Response${
                  runViewResponse && runViewResponse.status !== "ok"
                    ? " ⚠️"
                    : ""
                }`}
                active={editorTab === "runViewResponse"}
                onPress={() => setEditorTab("runViewResponse")}
              />
            </TabBar>
            <CodeWrapper show={editorTab === "configViewResponse"}>
              <CodeEditor
                value={formatResponse(configViewResponse)}
                readOnly={true}
                autoFocus={false}
              />
            </CodeWrapper>
            <CodeWrapper show={editorTab === "runViewResponse"}>
              <CodeEditor
                value={formatResponse(runViewResponse)}
                readOnly={true}
                autoFocus={false}
              />
            </CodeWrapper>
            <CodeWrapper show={editorTab === "script"}>
              <CodeEditor
                value={script}
                onChange={onChangeScript}
                onSaveTriggered={onScriptSaveTriggered}
                readOnly={false}
                autoFocus={true}
              />
            </CodeWrapper>

            {editorTab === "views" ? sideContent : null}
          </EditorWrapper>
          {Platform.OS === "web" ? (
            <SideContentWrapper>{sideContent}</SideContentWrapper>
          ) : null}
        </MainContent>
        <DoneButton title={"Done"} onPress={() => setShowModal(false)} />
      </ModalContainer>
    </QKModal>
  );
};

const ModalContainer = styled.View`
  padding: 20px;
  height: 100%;
`;

const MainContent = styled.View`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 20px;
`;

const EditorWrapper = styled.View`
  display: flex;
  height: 100%;
  width: ${() => (Platform.OS === "web" ? "60%" : "100%")};
`;

const CodeWrapper = styled.View<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  padding-bottom: 50px;
  height: 100%;
`;

const DoneButton = styled(Button)`
  flex-grow: 0;
`;

const SideContentWrapper = styled.View`
  display: flex;
  padding-horizontal: 40px;
  width: 40%;
`;

function formatResponse(response?: CustomItemQuestScriptResponse) {
  if (!response) {
    return "No response yet.";
  }

  return JSON.stringify(response, null, 2);
}
