import React, { useCallback, useEffect, useRef } from "react";

import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { apiRequest } from "@app/util/client";
import { useFocusableRef } from "@app/util/focus";
import { InlineErrorWithRetry } from "@app/components/item/components/custom/InlineErrorWithRetry";
import {
  CustomItemComponents,
  CustomItemInlineComponent,
} from "@app/components/item/components/custom/ComponentsRenderer";
import { CustomItemV2Response } from "@app/components/item/components/custom/types";
import * as CustomItem from "@questmate/questscript";
import { uuid } from "@app/util/uuid";
import { ItemBaseProps } from "@app/components/item/components/itemContainer";
import { useV2View } from "@app/components/item/components/custom/v2/useV2View";
import EventEmitter from "events";
import isEqual from "react-fast-compare";
import { Portal } from "@gorhom/portal";
import * as Localization from "expo-localization";

type CustomItemRunViewProps = Pick<
  ItemBaseProps,
  "item" | "readOnly" | "onItemChange"
> & {
  itemRunEventEmitter?: ItemRunEventEmitter;
  inlineNodePortalName: string;
};

export const CustomItemV2RunView: React.FC<CustomItemRunViewProps> = (
  props
) => {
  const { item, readOnly, onItemChange } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const questContext = useQuestViewContext(["RUN"]);
  const focusRefCallback = useFocusableRef(props.item.prototype.id);

  const previousItemDataRef = useRef<unknown>(null);

  const renderRunView = useCallback(
    (events: CustomItem.CustomItemEvent[]) => {
      return executeCustom(
        questContext.questInstanceId,
        itemRef.current.prototype.id,
        {
          // TODO: Save user timezone (allow editing and initialize on login).
          //       Then use that timezone instead of their current timezone.
          timezone: Localization.getCalendars()?.[0]?.timeZone,
          locale: Localization.getLocales()?.[0]?.languageTag,
          events: [
            ...events,
            {
              id: uuid(),
              type: "RENDER_VIEW",
              data: {
                viewId: "ITEM_RUN_VIEW",
              },
            },
          ],
        },
        questContext.publicQuestSessionToken
      ).then((response) => {
        const itemData = response?.value?.state?.RUN_DATA;
        if (itemData && !isEqual(itemData, previousItemDataRef.current)) {
          const isInitializationOfRunData =
            previousItemDataRef.current === null;
          previousItemDataRef.current = itemData;
          onItemChange?.(
            {
              ...itemRef.current,
              data: itemData,
              version: itemRef.current.version + 1,
            },
            {
              skipSave: true, // Save is performed by custom endpoint.
              ...(isInitializationOfRunData
                ? { skipActivityReport: true }
                : {}),
            }
          );
        }
        return response;
      });
    },
    [
      onItemChange,
      questContext.publicQuestSessionToken,
      questContext.questInstanceId,
    ]
  );

  const view = useV2View(renderRunView, "ITEM_RUN_VIEW");
  const viewRef = useRef(view);
  viewRef.current = view;

  const itemRunEventEmitter = props.itemRunEventEmitter;
  useEffect(() => {
    if (itemRunEventEmitter) {
      const onItemRunCompleted = (_data: unknown) => {
        void viewRef.current.reload();
      };
      itemRunEventEmitter.on("ItemRunCompleted", onItemRunCompleted);
      return () => {
        itemRunEventEmitter.off("ItemRunCompleted", onItemRunCompleted);
      };
    }
  }, [itemRunEventEmitter]);

  return view?.rawResponse && view.rawResponse.status !== "ok" ? (
    <InlineErrorWithRetry
      message="Error retrieving item."
      isLoading={view.isLoading}
      onRetry={view.reload}
    />
  ) : view.isRegistered === undefined || view.isRegistered ? (
    <>
      {view.inlineComponent ? (
        <Portal hostName={props.inlineNodePortalName}>
          <CustomItemInlineComponent
            component={view.inlineComponent}
            readOnly={readOnly}
            isLoading={view.isLoading}
          />
        </Portal>
      ) : null}
      <CustomItemComponents
        autoFocusRef={focusRefCallback}
        components={view.components}
        isLoading={view.isLoading}
        hasError={view.hasError}
        readOnly={!!readOnly}
        onErrorRetry={view.reload}
      />
    </>
  ) : null;
};

async function executeCustom(
  questInstanceId: string,
  itemPrototypeId: string,
  body: unknown,
  publicSessionToken?: string
) {
  return apiRequest<CustomItemV2Response>(
    "POST",
    `/questInstances/${questInstanceId}/item/${questInstanceId}%7C${itemPrototypeId}/custom`,
    body,
    publicSessionToken
  );
}

interface ItemRunEventEmitter {
  on(
    eventName: "ItemRunCompleted",
    handler: (status: "OK" | "FAILED") => void
  ): this;
  off(
    eventName: "ItemRunCompleted",
    handler: (status: "OK" | "FAILED") => void
  ): this;
}

export class CustomItemEventReporter
  extends EventEmitter
  implements ItemRunEventEmitter
{
  constructor() {
    super();
  }

  reportItemRunEventAsCompleted(status: "OK" | "FAILED"): void {
    this.emit("ItemRunCompleted", status);
  }
}
