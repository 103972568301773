import React, {
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FlatList,
  ListRenderItem,
  Platform,
  StyleProp,
  TextInput,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import QKModal from "@app/components/modal";
import { Dropdown } from "@app/components/questkit/dropdown";
import Text from "@app/components/questkit/text";
import QKTextInput from "@app/components/questkit/textInput";
import formatIdentifier from "@app/util/formatIdentifier";
import { useFocusableRef } from "@app/util/focus";
import { useSearchResults } from "@app/components/questkit/searchFilter";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import { useEffectOnce } from "@app/util/useEffectOnce";
import isEqual from "react-fast-compare";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

type DropdownOptionValue = string | number | null;
type UnselectedOptionValue = undefined;
export type DropdownValue = DropdownOptionValue | UnselectedOptionValue;

export interface DropdownOption {
  icon?: IconIdentifier;
  name: string;
  value: DropdownOptionValue;
  searchTerms?: string[];
}

interface SharedProps {
  options: DropdownOption[];
  style?: StyleProp<ViewStyle>;
  selectedOption: DropdownValue;
  setSelectedOption: (selectedOption: DropdownValue) => void;
  onSearchTextChange?: (searchText: string) => void;
  optionNoun: string;
  optionPluralNoun: string;
  loadingOptions: boolean;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  disabled?: boolean;
  readOnly?: boolean;
  allowNullAsValidValue?: boolean;
}

interface PagerProps extends SharedProps {
  dropdownStyle: DropdownStyle.PAGER;
}
interface StandardProps extends SharedProps {
  dropdownStyle: DropdownStyle.STANDARD;
  placeholderIcon?: IconIdentifier;
}

type DropdownWithModalProps = PagerProps | StandardProps;

export enum DropdownStyle {
  STANDARD,
  PAGER,
}

export const DropdownWithModal: React.FC<DropdownWithModalProps> = (props) => {
  const {
    autoFocusRef,
    options: _options,
    loadingOptions,
    selectedOption,
    setSelectedOption,
    onSearchTextChange,
    optionNoun,
    optionPluralNoun,
    dropdownStyle,
    style,
    disabled,
    readOnly,
    allowNullAsValidValue,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const modalSearchInputRef = useRef<TextInput>(null);
  useImperativeHandle(
    autoFocusRef,
    () => ({
      focus: () => {
        setShowModal(true);
        setImmediate(() => {
          if (modalSearchInputRef.current) {
            modalSearchInputRef.current.focus();
          }
        });
      },
      blur: () => {
        console.info("blur not yet implemented for dropdown");
      },
    }),
    []
  );

  const anOptionIsSelected =
    selectedOption !== undefined &&
    (allowNullAsValidValue || selectedOption !== null);

  const options = useIsEqualMemo(_options);

  const keyExtractor = useCallback(
    (option: DropdownOption) =>
      typeof option.value === "string"
        ? option.value
        : option.value === null
        ? "__NULL__"
        : `"${option.value}"`,
    []
  );

  let option = useMemo(() => {
    return selectedOption === undefined
      ? undefined
      : options.find(({ value }) => value === selectedOption);
  }, [options, selectedOption]);
  if (!option) {
    let placeholderText: string;
    if (loadingOptions && (!options || options.length === 0)) {
      placeholderText = `Loading ${optionPluralNoun.toLowerCase()}...`;
    } else if (anOptionIsSelected) {
      placeholderText = `Selected ${optionNoun.toLowerCase()} not available`;
    } else if (options?.length === 0) {
      placeholderText = `No ${optionPluralNoun.toLowerCase()} available.`;
    } else {
      placeholderText = `Select ${optionNoun.toLowerCase()}`;
    }
    option = {
      name: placeholderText,
      value: undefined as unknown as string,
      icon:
        props.dropdownStyle === DropdownStyle.STANDARD
          ? props.placeholderIcon
          : undefined,
    };
  }

  const optionsRef = useRef(options);
  optionsRef.current = options;
  const optionRef = useRef(option);
  optionRef.current = option;

  const setOptionToTheLeft = useCallback(() => {
    const currentOptions = optionsRef.current;
    const index = currentOptions.indexOf(optionRef.current!);
    if (index !== -1) {
      if (index === 0) {
        setSelectedOption(currentOptions[currentOptions.length - 1].value);
      } else {
        setSelectedOption(currentOptions[index - 1].value);
      }
    }
  }, [setSelectedOption]);

  const setOptionToTheRight = useCallback(() => {
    const currentOptions = optionsRef.current;
    const index = currentOptions.indexOf(optionRef.current!);
    if (index !== -1) {
      if (index === currentOptions.length - 1) {
        setSelectedOption(currentOptions[0].value);
      } else {
        setSelectedOption(currentOptions[index + 1].value);
      }
    }
  }, [setSelectedOption]);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);

  const removeSelection = useCallback(() => {
    setSelectedOption(undefined);
  }, [setSelectedOption]);

  const onSelectOption = useCallback(
    (option: DropdownOption) => {
      setSelectedOption(option.value);
      setShowModal(false);
    },
    [setSelectedOption]
  );
  const optionName = option?.name;
  const label = useMemo(
    () => (optionName ? formatIdentifier(optionName) : ""),
    [optionName]
  );
  return (
    <>
      <Dropdown
        value={label}
        valueIsPlaceholder={!anOptionIsSelected}
        onPress={options?.length > 0 ? openModal : undefined}
        style={style}
        textAlignment={
          dropdownStyle === DropdownStyle.PAGER ? "center" : "left"
        }
        showIconBorder={dropdownStyle === DropdownStyle.PAGER}
        leftIcon={
          dropdownStyle === DropdownStyle.PAGER
            ? "chevron-left"
            : option?.icon || undefined
        }
        rightIcon={
          dropdownStyle === DropdownStyle.PAGER
            ? "chevron-right"
            : anOptionIsSelected && !disabled && !readOnly
            ? "close"
            : undefined
        }
        onPressLeftIcon={
          dropdownStyle === DropdownStyle.PAGER ? setOptionToTheLeft : undefined
        }
        onPressRightIcon={
          dropdownStyle === DropdownStyle.PAGER
            ? setOptionToTheRight
            : anOptionIsSelected && !disabled && !readOnly
            ? removeSelection
            : undefined
        }
        disabled={disabled}
        readOnly={readOnly}
      />

      <QKModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={`Select ${optionNoun}`}
      >
        <ModalCard
          selectedOption={selectedOption}
          onSelectOption={onSelectOption}
          onSearchTextChange={onSearchTextChange}
          options={options}
          searchInputRef={modalSearchInputRef}
          keyExtractor={keyExtractor}
        />
      </QKModal>
    </>
  );
};

interface ModalCardProps {
  selectedOption: DropdownValue;
  onSelectOption: (value: DropdownOption) => void;
  onSearchTextChange?: (text: string) => void;
  options: DropdownOption[];
  keyExtractor?: (option: DropdownOption) => string;
  searchInputRef: Ref<TextInput>;
  initialRenderCount?: number;
}

export const ModalCard = ({
  onSelectOption,
  onSearchTextChange,
  options,
  selectedOption,
  searchInputRef,
  initialRenderCount,
  keyExtractor,
}: ModalCardProps) => {
  const [optionSearchText, setOptionSearchText, optionSearchTextRef] =
    useSyncedState("", {
      onLocalChange: onSearchTextChange,
    });
  useEffectOnce(() => {
    return () => {
      if (optionSearchTextRef.current.length > 0) {
        // Inform upstream listeners that the previous search text reported has been cleared.
        onSearchTextChange?.("");
      }
    };
  });

  const listRef = useRef<FlatList<unknown>>(null);

  const filteredOptions = useSearchResults(options, optionSearchText);

  const selectedOptionIndex = useMemo(
    () => filteredOptions.findIndex(({ value }) => value === selectedOption),
    [filteredOptions, selectedOption]
  );

  const scrollToSelectedOption = useCallback(() => {
    if (selectedOptionIndex >= 0) {
      listRef.current?.scrollToIndex({
        index: selectedOptionIndex,
        animated: false,
        viewPosition: 0.5,
      });
    }
  }, [listRef, selectedOptionIndex]);

  const renderOptionItem: ListRenderItem<DropdownOption> = useCallback(
    (props) => {
      return (
        <StandardEntryRenderer
          option={props.item}
          onSelectOption={onSelectOption}
          isSelectedOption={props.item.value === selectedOption}
        />
      );
    },
    [onSelectOption, selectedOption]
  );

  const onScrollToIndexFailed = useCallback(
    (info: {
      index: number;
      highestMeasuredFrameIndex: number;
      averageItemLength: number;
    }) => {
      console.error("Failed to scroll option into view.", info);
    },
    []
  );

  const leftIcon = useCallback(() => <Icon icon="search" />, []);
  return (
    <>
      <List
        ref={listRef}
        onLayout={scrollToSelectedOption}
        initialNumToRender={initialRenderCount ?? selectedOptionIndex + 10}
        onScrollToIndexFailed={onScrollToIndexFailed}
        keyboardShouldPersistTaps="always"
        data={filteredOptions}
        renderItem={renderOptionItem}
        keyExtractor={keyExtractor}
      />
      <SearchInputWrapper>
        <SearchInput
          ref={searchInputRef}
          leftIcon={leftIcon}
          value={optionSearchText}
          onChangeText={setOptionSearchText}
          placeholder="Search"
          accessibilityRole={"search"}
        />
      </SearchInputWrapper>
    </>
  );
};

interface EntryRendererProps {
  option: DropdownOption;
  onSelectOption: (option: DropdownOption) => void;
  isSelectedOption: boolean;
}
const _StandardEntryRenderer: React.FC<EntryRendererProps> = ({
  option,
  onSelectOption,
  isSelectedOption,
}) => {
  const optionRef = useRef(option);
  optionRef.current = option;
  const onPress = useCallback(
    () => onSelectOption(optionRef.current),
    [onSelectOption]
  );
  const name = option.name;
  const label = useMemo(() => formatIdentifier(name), [name]);

  return (
    <ListEntry activeOpacity={0.8} onPress={onPress}>
      {option.icon ? <Icon icon={option.icon} /> : <IconDummy />}
      <ListEntryName size={isSelectedOption ? "mediumBold" : "medium"}>
        {label}
      </ListEntryName>
    </ListEntry>
  );
};
const StandardEntryRenderer = React.memo(_StandardEntryRenderer, isEqual);
StandardEntryRenderer.displayName = "StandardEntryRenderer";

const ListEntry = styled(PressableOpacity)`
  flex-direction: row;
  padding-vertical: 8px;
`;

const ListEntryName = styled(Text)`
  flex: 1;
  align-self: center;
  ${() => (Platform.OS === "web" ? "user-select: none;" : "")}
`;

const List = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingTop: 66,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
})``;

const SearchInputWrapper = styled.View`
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.background};
`;

const SearchInput = styled(QKTextInput)`
  margin: 16px;
`;

const IconDummy = styled.View`
  width: 40px;
`;
