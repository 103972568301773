import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";

interface CustomItemPlaceholderComponentProps {
  component: {
    id: string;
    title: string;
    type: string;
  };
}

export const CustomItemPlaceholderComponent: React.FC<
  CustomItemPlaceholderComponentProps
> = ({ component }) => {
  return (
    <TextContainer>
      {component.title && (
        <TitleText size="medium">{component.title}</TitleText>
      )}
      <Text $warning={true}>
        It seems there is something new to see here. Please update / reload your
        app to experience some new magics!
      </Text>
    </TextContainer>
  );
};

const TextContainer = styled.View`
  display: flex;
`;

const TitleText = styled(Text)`
  margin-right: 20px;
  margin-bottom: 10px;
`;
