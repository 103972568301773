import TrackPlayer, {
  type AddTrack,
  AppKilledPlaybackBehavior,
  Capability,
} from "react-native-track-player";
import { PlaybackService } from "./backgroundPlaybackService";
import { Event } from "react-native-track-player/src/constants/Event";
import { State } from "react-native-track-player/src/constants/State";
import EventEmitter from "events";
import { ENV } from "@app/config/env";
import { createDebugLogger } from "@app/config/logger";
import { colors } from "@app/themes/Colors";
import { hexToAndroidColorNumber } from "@app/themes/Colors.utils";

class PromiseQueue {
  queue = Promise.resolve();

  add<T>(operation: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.queue = this.queue.then(operation).then(resolve).catch(reject);
    });
  }
}

/**
 * Wrapper for the TrackPlayer
 * to help with common tasks and avoid concurrency issues.
 */
class QuestmateTrackPlayer {
  taskQueue = new PromiseQueue();
  eventEmitter = new EventEmitter();

  constructor() {
    TrackPlayer.registerPlaybackService(() => PlaybackService);

    TrackPlayer.addEventListener(Event.PlaybackError, (event) => {
      debug("PlaybackError", event);
    });

    void this.taskQueue.add(async () => {
      await TrackPlayer.setupPlayer();

      debug(() => {
        TrackPlayer.addEventListener(Event.PlaybackState, (event) => {
          debug("PlaybackStateChanged", event);
          if (event.state === State.Loading) {
            (async () => {
              const activeTrack = await this.getActiveTrack();
              debug("Checking Active Track", activeTrack);
              const getPlayWhenReady = await TrackPlayer.getPlayWhenReady();
              debug("Checking getPlayWhenReady", getPlayWhenReady);
            })();
          }
        });
        TrackPlayer.addEventListener(
          Event.PlaybackActiveTrackChanged,
          (event) => {
            debug("PlaybackActiveTrackChanged", event);
          }
        );
      });

      await TrackPlayer.updateOptions({
        color: hexToAndroidColorNumber(colors.primary700),

        // Media controls capabilities
        capabilities: [
          Capability.Play,
          Capability.Pause,
          Capability.SkipToNext,
          Capability.SkipToPrevious,
          Capability.Stop,
        ],

        // Capabilities that will show up when the notification is in the compact form on Android
        compactCapabilities: [Capability.Play, Capability.Pause],

        android: {
          appKilledPlaybackBehavior:
            AppKilledPlaybackBehavior.StopPlaybackAndRemoveNotification,
        },
      });
    });
  }

  /**
   * Active track index is updated immediately
   * whereas active track is updated after loading.
   * In most of our situations,
   * we want to know what is targeted as the active track, even before it is loaded.
   * @private
   */
  async getActiveTrack() {
    const [activeTrackIndex, queue] = await Promise.all([
      TrackPlayer.getActiveTrackIndex(),
      TrackPlayer.getQueue(),
    ]);
    return activeTrackIndex !== undefined ? queue[activeTrackIndex] : undefined;
  }

  reset(): Promise<void> {
    return this.taskQueue.add(() => TrackPlayer.reset());
  }

  play(trackId: string): Promise<void> {
    return this.taskQueue.add(async () => {
      debug("Requested to play track with id", trackId);
      const activeTrack = await this.getActiveTrack();
      let trackToPlay_debug = activeTrack;
      if (activeTrack?.trackId !== trackId) {
        debug("Loading Track to play it");
        this.eventEmitter.emit("PREPARING_TO_PLAY", { trackId });
        const tracks = await TrackPlayer.getQueue();
        const itemTrackIndex = tracks.findIndex((t) => t.trackId === trackId);
        if (itemTrackIndex === -1) {
          debug("Track not found to play", { trackId, tracks });
          return;
        }
        trackToPlay_debug = tracks[itemTrackIndex];
        await TrackPlayer.skip(itemTrackIndex);
      } else {
        const progress = await TrackPlayer.getProgress();
        if (progress.position === progress.duration) {
          debug("Track is already at the end. Restarting it.");
          await TrackPlayer.seekTo(0);
        }
      }
      debug("Playing track", trackToPlay_debug);
      await TrackPlayer.play();
      await TrackPlayer.setRate(1.2);
    });
  }

  pause(): Promise<void> {
    debug("Requested to pause");
    // this.eventEmitter.emit("PAUSE", { trackId });
    return this.taskQueue.add(() => {
      debug("Pausing");
      return TrackPlayer.pause();
    });
  }

  remove(trackId: string): Promise<void> {
    debug("Requested to remove track with id", trackId);
    return this.taskQueue.add(async () => {
      const tracks = await TrackPlayer.getQueue();
      const trackIndex = tracks.findIndex((track) => track.trackId === trackId);
      if (trackIndex === -1) {
        debug("Track not found to remove", { trackId, tracks });
      } else {
        const activeTrackIndex = await TrackPlayer.getActiveTrackIndex();
        if (activeTrackIndex === trackIndex) {
          debug("Pausing player because we are removing the active track.");
          await TrackPlayer.pause();
        }
        await TrackPlayer.remove(trackIndex);
        debug("Track removed", tracks[trackIndex]);
      }
    });
  }

  async addOrUpdate(track: QuestmateAudioTrack): Promise<void> {
    return this.taskQueue.add(async () => {
      let tracks = await TrackPlayer.getQueue();
      const existingTrackIndex = tracks.findIndex(
        (t) => t.trackId === track.trackId
      );

      const existingTrack =
        existingTrackIndex !== -1 ? tracks[existingTrackIndex] : undefined;

      const canBeUpdatedWithoutRemoving =
        existingTrack &&
        existingTrack?.url === track.url &&
        // It should be possible to reliably move a track without removing it.
        // May require workarounds if it is currently playing, though.
        existingTrack?.sortOrder === track.sortOrder;

      if (canBeUpdatedWithoutRemoving) {
        debug(
          "Updating Track Metadata. existingTrack, newTrack",
          existingTrack,
          track
        );
        await TrackPlayer.updateMetadataForTrack(existingTrackIndex, track);
        await debug(
          async () =>
            await TrackPlayer.getQueue().then((tracks) => {
              debug("Updated Track List", [...tracks]);
            })
        );
      } else {
        if (existingTrack) {
          debug(
            "Removing existing track and re-adding due to update. Also pausing. existingTrack, newTrack",
            existingTrack,
            track
          );
          await TrackPlayer.pause(); // When changing/re-ordering tracks, pause the player.
          await TrackPlayer.remove(existingTrackIndex);
          tracks = await TrackPlayer.getQueue();
        }

        let trackInsertIndex = 0;
        for (const t of tracks) {
          if (t.sortOrder < track.sortOrder) {
            trackInsertIndex++;
          }
        }

        debug("Adding track at index", trackInsertIndex, track);

        // Do not hold up the task queue for the add operation to complete
        // as it will include loading/buffering as well.
        void TrackPlayer.add(track, trackInsertIndex).then(() => {
          return debug(() =>
            TrackPlayer.getQueue().then((tracks) => {
              debug("Updated Track List", [...tracks]);
            })
          );
        });
      }
    });
  }
}

const debug = createDebugLogger(
  "QMTrackPlayer",
  () => ENV.logLevels.trackPlayer === "debug"
);

export type QuestmateTrackId = string & { __brand: "QuestmateTrackId" };

export interface QuestmateAudioTrack extends AddTrack {
  trackId: QuestmateTrackId;
  sortOrder: number;
}

export const QMTrackPlayer = new QuestmateTrackPlayer();
