import { useMemo, useRef } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { sentry } from "@app/util/sentry";

/**
 * useTimeout hook to manage a setTimeout in a React component lifecycle.
 *  - helper functions to start and stop the timeout
 *  - the timeout is cleared on unmount
 *  - the timeout callback can be updated any time and the latest version will be used when the timeout is called
 * @param fn
 * @param ms
 */

type UseTimeoutResult<T extends unknown[]> = {
  start: (...args: T) => void;
  stop: () => void;
};

export const useTimeout = <T extends unknown[]>(
  fn: (...args: T) => void,
  ms: number | undefined
): UseTimeoutResult<T> => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(fn);
  callbackRef.current = fn;

  const handler = useMemo(
    () => ({
      start(...startArgs: T) {
        if (ms !== undefined) {
          timeoutRef.current = setTimeout(() => {
            try {
              timeoutRef.current = undefined;
              callbackRef.current(...startArgs);
            } catch (e) {
              console.warn(
                "Unhandled Exception thrown from useTimeout callback",
                e
              );
              sentry.captureException(e, {
                extra: {
                  startArgs,
                },
              });
              throw e;
            }
          }, ms);
        } else {
          console.warn("useTimeout was called with undefined ms");
        }
      },
      stop() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = undefined;
        }
      },
    }),
    [ms]
  );
  useEffectOnce(() => () => handler.stop());

  return handler;
};
