import React, { useCallback, useRef } from "react";
import { Animated, StyleProp, View, ViewStyle } from "react-native";
import BasePressable, {
  BasePressableProps,
} from "@app/components/questkit/BasePressable";

type LimitedBasePressableProps = Omit<
  BasePressableProps,
  "style" | "onPressIn" | "onPressOut"
> & {
  style?: Extract<BasePressableProps["style"], StyleProp<ViewStyle>>;
};

export interface PressableOpacityProps extends LimitedBasePressableProps {
  activeOpacity?: number;
}

const PressableOpacity: React.FC<PressableOpacityProps> = React.forwardRef<
  View,
  PressableOpacityProps
>(({ children, ...props }, ref) => {
  const animatedOpacity = useRef(new Animated.Value(1)).current;
  const transitionToActiveOpacity = useCallback(() => {
    if (typeof props.activeOpacity !== "number") return;
    Animated.timing(animatedOpacity, {
      toValue: props.activeOpacity,
      duration: 100,
      useNativeDriver: true, // todo: double check this is okay
    }).start();
  }, [animatedOpacity, props.activeOpacity]);
  const transitionToOpaque = useCallback(() => {
    Animated.timing(animatedOpacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true, // todo: double check this is okay
    }).start();
  }, [animatedOpacity]);

  return (
    <AnimatedPressable
      onPressIn={transitionToActiveOpacity}
      onPressOut={transitionToOpaque}
      {...props}
      style={[props.style, { opacity: animatedOpacity }]}
      ref={ref}
    >
      {children}
    </AnimatedPressable>
  );
});
PressableOpacity.displayName = "PressableOpacity";

const AnimatedPressable = Animated.createAnimatedComponent(BasePressable);

export default PressableOpacity;
