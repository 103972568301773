import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components/native";

import * as Localization from "expo-localization";
import * as countriesData from "country-codes-list";
import { type CountryData, type CountryProperty } from "country-codes-list";

import TextInput, {
  QKTextInputProps,
} from "@app/components/questkit/textInput";
import Text from "@app/components/questkit/text";
import {
  ListRenderItem,
  Pressable,
  TextInput as RNTextInput,
} from "react-native";
import Icon from "@app/components/icon";
import QKModal from "@app/components/modal";
import {
  IdentifierParseResult,
  parseAsEmailOrPhone,
  parseMultipleIdentifiers,
  PhoneParseResult,
} from "@app/screens/login/IdentifierParser";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { sentry } from "@app/util/sentry";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

interface BaseEmailPhoneInputProps extends QKTextInputProps {
  onChangeText: (text: string) => void;
  value: string;
  onSubmitEditing?: () => void;
  blurOnSubmit?: boolean;
  editable?: boolean;
  autoFocus?: boolean;
  // TODO: support same type as Text Input and only pass in the icon name
  rightActionComponent?: (isFocused: boolean) => React.ReactElement | null;
}
type EmailPhoneInputProps =
  | (BaseEmailPhoneInputProps & {
      onChangeIdentifier: (identifier: IdentifierParseResult) => void;
      allowMultiple?: false;
    })
  | (BaseEmailPhoneInputProps & {
      onChangeIdentifier: (identifiers: IdentifierParseResult[]) => void;
      allowMultiple: true;
    });

const countryCodeKey = "countryCode" as CountryProperty.countryCode;

const EmailPhoneInput = React.forwardRef<RNTextInput, EmailPhoneInputProps>(
  (props, ref) => {
    const { onChangeIdentifier, value, allowMultiple } = props;
    const [inputType, setInputType, inputTypeRef] = useStateWithRef("email");

    const [showModal, setShowModal] = useState(false);

    const [selectedCountry, setSelectedCountry, selectedCountryRef] =
      useStateWithRef(determineInitialSelectedCountry);

    // Create and feedback input combined with region
    useEffect(() => {
      if (allowMultiple) {
        const identifiers = parseMultipleIdentifiers(value, selectedCountry);
        if (
          identifiers.some((identifier) => identifier.type === "PHONE_NUMBER")
        ) {
          setInputType("phone");
          const detectedCountries = identifiers
            .filter(
              (identifier): identifier is PhoneParseResult =>
                identifier.type === "PHONE_NUMBER"
            )
            .map((identifier) => identifier.detectedCountry)
            .filter((country): country is CountryData => !!country)
            .filter((country, index, self) => self.indexOf(country) === index);
          if (
            detectedCountries.length === 1 &&
            detectedCountries[0] !== selectedCountryRef.current
          ) {
            setSelectedCountry(detectedCountries[0]);
          }
        } else if (
          identifiers.some((identifier) => identifier.type === "EMAIL_ADDRESS")
        ) {
          setInputType("email");
        }
        onChangeIdentifier(identifiers);
      } else {
        const identifier = parseAsEmailOrPhone(value, selectedCountry);
        if (identifier.type !== "UNKNOWN") {
          const newInputType =
            identifier.type === "PHONE_NUMBER" ? "phone" : "email";
          if (inputTypeRef.current !== newInputType) {
            setInputType(newInputType);
          }
          if (
            identifier.type === "PHONE_NUMBER" &&
            identifier.detectedCountry &&
            identifier.detectedCountry !== selectedCountryRef.current
          ) {
            setSelectedCountry(identifier.detectedCountry);
          }
        }
        onChangeIdentifier(identifier);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry, value]);

    const onModalCallback = useCallback(
      (value: CountryData) => {
        setSelectedCountry(value);
        setShowModal(false);
      },
      [setSelectedCountry]
    );
    return (
      <>
        <TextInput
          ref={ref}
          textContentType={
            inputType === "phone" ? "telephoneNumber" : "emailAddress"
          }
          placeholder="Enter Email or Mobile"
          textAlign="center"
          leftIcon={() =>
            inputType === "phone" ? (
              <StyledCountrySelector
                onPress={() => {
                  setShowModal(true);
                }}
                style={{
                  elevation: 3, // android z-index equivalent
                }}
              >
                <Icon icon="chevron-down" offsetX={-1} />
                <Text style={{ fontSize: 18 }}>
                  {selectedCountry?.flag ?? ""}
                </Text>
              </StyledCountrySelector>
            ) : null
          }
          rightIcon={(isFocused) => {
            if (props.rightActionComponent && value.length) {
              return (
                <RightActionComponentWrapper
                  activeOpacity={0.8}
                  isFocused={isFocused}
                  onPress={props.onSubmitEditing}
                >
                  {props.rightActionComponent(isFocused)}
                </RightActionComponentWrapper>
              );
            }
            return null;
          }}
          autoFocus={props.autoFocus}
          {...props}
        />
        <ModalCard
          showModal={showModal}
          setShowModal={setShowModal}
          onModalCallback={onModalCallback}
        />
      </>
    );
  }
);
EmailPhoneInput.displayName = "EmailPhoneInput";

interface ModalCardProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onModalCallback: (value: CountryData) => void;
}

const ModalCard = ({
  showModal,
  setShowModal,
  onModalCallback,
}: ModalCardProps) => {
  const [countrySearchText, setCountrySearchText] = useState("");

  const renderCountryItem: ListRenderItem<CountryData> = useCallback(
    ({ item: country }) => {
      return (
        <StyledCountryModalListEntry
          activeOpacity={0.8}
          onPress={() => onModalCallback(country)}
        >
          <StyledCountryModalListEntryFlag>
            {country.flag}
          </StyledCountryModalListEntryFlag>
          <StyledCountryModalListEntryName>{`${country.countryNameEn} (+${country.countryCallingCode})`}</StyledCountryModalListEntryName>
        </StyledCountryModalListEntry>
      );
    },
    [onModalCallback]
  );

  const deferredCountrySearchText = useDeferredValue(countrySearchText);
  const data = useMemo(
    () =>
      countriesData
        .all()
        .filter((country: CountryData) => {
          const countryNameEn = country.countryNameEn.toLowerCase();
          const countryNameLocal = country.countryNameLocal.toLowerCase();

          return (
            countryNameEn.includes(deferredCountrySearchText.toLowerCase()) ||
            countryNameLocal.includes(deferredCountrySearchText.toLowerCase())
          );
        })
        .sort((a: CountryData, b: CountryData) =>
          a.countryNameEn.localeCompare(b.countryNameEn, undefined, {})
        ),
    [deferredCountrySearchText]
  );
  const keyExtractor = useCallback((item: CountryData) => item.countryCode, []);
  return (
    <QKModal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Country Code"
    >
      <StyledCountryModalList
        data={data}
        renderItem={renderCountryItem}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps="handled"
      />
      <StyledCountrySearchInputWrapper>
        <StyledCountrySearchInput
          leftIcon={"search"}
          value={countrySearchText}
          onChangeText={setCountrySearchText}
          placeholder="Search country codes"
        />
      </StyledCountrySearchInputWrapper>
    </QKModal>
  );
};

const StyledCountrySearchInputWrapper = styled.View`
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.background};
`;

const StyledCountrySearchInput = styled(TextInput)`
  margin: 16px;
`;

const StyledCountryModalListEntry = styled(PressableOpacity)`
  flex-direction: row;
  padding-vertical: 8px;
`;

const StyledCountryModalListEntryFlag = styled(Text)`
  margin-right: 16px;
`;

const StyledCountryModalListEntryName = styled(Text)`
  flex: 1;
`;

const StyledCountryModalList = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingTop: 66,
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 16,
  },
})``;

const StyledCountrySelector = styled(Pressable)`
  background-color: ${({ theme }) => theme.background};
  z-index: 3;
  position: absolute;
  flex-direction: row;
  height: 40px;
  align-items: center;
`;

const RightActionComponentWrapper = styled(PressableOpacity)<{
  isFocused: boolean;
}>`
  background-color: ${({ theme, isFocused }) =>
    isFocused ? theme.background : theme.primary};
  margin: 4px;
  position: absolute;
  right: 0;
  border-radius: 16px;
`;

export default EmailPhoneInput;

const DEFAULT_REGION_CODE = "AU";
const determineInitialSelectedCountry = () => {
  let usersRegionCode;
  const debugData: Record<string, unknown> = {};
  try {
    usersRegionCode = Localization.getLocales()?.[0]?.regionCode;
    debugData.regionCodeFromLocale = usersRegionCode;
  } catch (e) {
    debugData.errorGettingRegionCodeFromLocale = e || true;
    debugData.IntlLocale = Intl?.Locale;
    debugData.Intl = Intl;
    console.warn(
      "Unable to determine users region code by Localization.getLocales().",
      e
    );
  }
  if (!usersRegionCode) {
    try {
      // deprecated way that may still work on some devices where Localization.getLocales() is throwing errors
      // eslint-disable-next-line deprecation/deprecation
      usersRegionCode = Localization.region;
      debugData.regionCodeFromDeprecatedRegion = usersRegionCode;
    } catch (e) {
      debugData.errorGettingRegionCodeFromDeprecatedRegion = e || true;
      console.warn(
        "Unable to determine users region code by Localization.region.",
        e
      );
    }
  }

  if (usersRegionCode) {
    const usersCountry = countriesData.findOne(countryCodeKey, usersRegionCode);
    if (usersCountry) {
      return usersCountry;
    } else {
      sentry.captureMessage(
        `Unable to find country by user's detected region code. Defaulting to ${DEFAULT_REGION_CODE}.`,
        {
          extra: {
            usersRegionCode,
            debugData,
          },
        }
      );
    }
  } else {
    sentry.captureMessage(
      `Unable to determine user's region code. Defaulting to ${DEFAULT_REGION_CODE}.`,
      {
        extra: { debugData },
      }
    );
  }

  return countriesData.findOne(countryCodeKey, DEFAULT_REGION_CODE);
};
