import {
  CreateWorkspaceRequest,
  PatchWorkspaceRequest,
  WorkspaceDetails,
  WorkspaceListItem,
  WorkspaceMembershipListItem,
  Workspaces,
} from "@questmate/openapi-spec";
import { apiRequest } from "@app/util/client";
import { store } from "@app/store";
import { workspaceLoaded, workspacesLoaded } from "@app/store/cache/workspaces";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import {
  workspaceMemberDeleted,
  workspaceMemberLoaded,
  workspaceMembersAdded,
} from "@app/store/cache/workspaceMemberships";
import { createRequest } from "@app/util/client/requests/index";

export const fetchWorkspaces = () => {
  return createRequest<WorkspaceListItem[]>(
    "get",
    `/workspaces`
  )((response) => {
    store.dispatch(
      workspacesLoaded({ userId: getLoggedInUserId()!, workspaces: response })
    );
  });
};

export const fetchWorkspace = (workspaceId: string) => {
  return createRequest<WorkspaceDetails>(
    "get",
    `/workspaces/${workspaceId}`
  )((response) => {
    store.dispatch(workspaceLoaded(response));
  });
};

export const updateWorkspace = async (
  workspaceId: string,
  fields: PatchWorkspaceRequest
): Promise<WorkspaceDetails> => {
  return apiRequest<WorkspaceDetails>(
    "patch",
    `/workspaces/${workspaceId}`,
    fields
  ).then((response) => {
    store.dispatch(workspaceLoaded(response));
    return response;
  });
};

export const createWorkspace = async (
  fields: CreateWorkspaceRequest
): Promise<WorkspaceDetails> => {
  return apiRequest<WorkspaceDetails>("post", "/workspaces", fields).then(
    (response) => {
      store.dispatch(workspaceLoaded(response));
      return response;
    }
  );
};

export const addWorkspaceMember = async (
  workspaceId: string,
  userIds: string[]
): Promise<WorkspaceMembershipListItem[]> => {
  return apiRequest<WorkspaceMembershipListItem[]>(
    "post",
    `/workspaces/${workspaceId}/memberships`,
    {
      userIds,
    }
  ).then((response) => {
    store.dispatch(
      workspaceMembersAdded({ workspaceId, memberships: response })
    );
    return response;
  });
};

export const deleteWorkspaceMember = async (
  workspaceId: string,
  membershipId: string
): Promise<Workspaces.MembershipsDelete.ResponseBody> => {
  return apiRequest<Workspaces.MembershipsDelete.ResponseBody>(
    "delete",
    `/workspaces/${workspaceId}/memberships/${membershipId}`
  ).then((response) => {
    store.dispatch(workspaceMemberDeleted({ workspaceId, membershipId }));
    return response;
  });
};

export const patchWorkspaceMember = async (
  workspaceId: string,
  membershipId: string,
  fields: Workspaces.MembershipsPartialUpdate.RequestBody
): Promise<Workspaces.MembershipsPartialUpdate.ResponseBody> => {
  return apiRequest<Workspaces.MembershipsPartialUpdate.ResponseBody>(
    "patch",
    `/workspaces/${workspaceId}/memberships/${membershipId}`,
    fields
  ).then((response) => {
    store.dispatch(
      workspaceMemberLoaded({ workspaceId, membership: response })
    );
    return response;
  });
};
