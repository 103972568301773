import React from "react";
import { StackActions } from "@react-navigation/native";
import Icon from "@app/components/icon";
import { HeaderBackButtonProps } from "@react-navigation/elements";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { useAppNavigation } from "@app/navigation/QMNavigator";

export const BackButton: React.FC<HeaderBackButtonProps> = () => {
  const navigation = useAppNavigation();
  const canGoBack = navigation.canGoBack();
  const userIsLoggedIn = !!getLoggedInUserId();

  if (canGoBack || userIsLoggedIn) {
    return (
      <HeaderIcon
        testID="back-button"
        activeOpacity={0.8}
        onPress={() => {
          // TODO: Replace this with a link so you can see where you're going. Need to figure out how to determine where "back" is.
          if (canGoBack) {
            navigation.goBack();
          } else if (userIsLoggedIn) {
            navigation.dispatch(StackActions.replace("Home"));
          }
        }}
        accessibilityLabel={`Go ${canGoBack ? "back" : "home"}`}
        accessibilityHint={`Navigates ${
          canGoBack ? "to the previous screen" : 'to the "Home" screen'
        }`}
      >
        <Icon icon="chevron-left" size={32} />
      </HeaderIcon>
    );
  } else {
    return <HeaderIcon />;
  }
};
