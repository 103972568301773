import React, { useCallback, useRef } from "react";
import {
  Animated,
  Platform,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import { useEffectOnce } from "@app/util/useEffectOnce";

interface IProps {
  duration: number;
  onFinish?: () => void;
  content: string;
  style: StyleProp<ViewStyle>;
  textStyle: StyleProp<TextStyle>;
}

const TextAnimator: React.FC<IProps> = ({
  content,
  duration,
  onFinish,
  style,
  textStyle,
}) => {
  const textArr = content.trim().split(" ");
  const initialAnimatedValues = textArr.map(() => new Animated.Value(0));

  const animatedValues = useRef(initialAnimatedValues).current;

  const animated = useCallback(
    (toValue = 1) => {
      const animations = textArr.map((_, i) => {
        return Animated.timing(animatedValues[i], {
          toValue,
          duration: duration,
          useNativeDriver: Platform.OS !== "web",
          isInteraction: false,
        });
      });

      Animated.stagger(
        duration / 5,
        toValue === 0 ? animations.reverse() : animations
      ).start(() => {
        onFinish?.();
      });
    },
    [animatedValues, duration, onFinish, textArr]
  );

  useEffectOnce(() => {
    animated();
  });

  return (
    <StyledView style={[style]}>
      {textArr.map((word, index) => {
        return (
          <Animated.Text
            key={`${word}-${index}`}
            style={[
              textStyle,
              {
                textAlign: "center",
                opacity: animatedValues[index],
              },
            ]}
          >
            {word}
            {`${index < textArr.length ? " " : ""}`}
          </Animated.Text>
        );
      })}
    </StyledView>
  );
};

export default TextAnimator;

const StyledView = styled.View`
  word-break: break-word;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
