import React, {
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  QMTrackPlayer,
  type QuestmateAudioTrack,
  type QuestmateTrackId,
} from "@app/audio/QMTrackPlayer";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { PlaylistManager } from "@app/audio/PlaylistManager";
import EventEmitter from "events";
import produce from "immer";
import isEqual from "react-fast-compare";
import type { Draft } from "immer/src/types/types-external";
import TrackPlayer from "react-native-track-player";
import { Event } from "react-native-track-player/src/constants/Event";
import { State } from "react-native-track-player/src/constants/State";
import type { EventPayloadByEvent } from "react-native-track-player/src/interfaces";
import { createDebugLogger, type DebugFn } from "@app/config/logger";
import { ENV } from "@app/config/env";
import { EmitterSubscription } from "react-native";

export interface QuestmateTrackController {
  state: "PLAYING" | "PREPARING_TO_PLAY" | "NOT_PLAYING";
  play: () => Promise<void>;
  pause: () => Promise<void>;
}

export type TrackStateChangeListener = (event: {
  statesByTrackId: Record<string, QuestmateTrackController["state"]>;
}) => void;

export type TrackDataChangeListener = (
  event:
    | {
        type: "addedOrUpdated";
        track: QuestmateAudioTrack;
      }
    | {
        type: "removed";
        trackId: string;
      }
) => void;

export interface Playlist {
  getTracks: () => QuestmateAudioTrack[];
  id: string;
  play: (trackId: QuestmateTrackId) => Promise<void>;
  pause: () => Promise<void>;
  addOrUpdateTrack: (track: QuestmateAudioTrack) => Promise<void>;
  removeTrack: (trackId: QuestmateTrackId) => Promise<void>;
  getTrackState: (
    trackId: QuestmateTrackId
  ) => QuestmateTrackController["state"];
  on(eventName: "track-state", listener: TrackStateChangeListener): void;
  off(eventName: "track-state", listener: TrackStateChangeListener): void;
  on(eventName: "track-data", listener: TrackDataChangeListener): void;
  off(eventName: "track-data", listener: TrackDataChangeListener): void;
  stopTrackingChanges(): void;
  registerHandle: (handleId: string) => void;
  unregisterHandle: (handleId: string) => void;
  isDetached: boolean;
}

const PlaylistContext = React.createContext<Playlist>({
  getTracks: () => [],
  id: "",
  play: () => Promise.reject("`play` called outside of a playlist."),
  pause: () => Promise.reject("`pause` called outside of a playlist."),
  addOrUpdateTrack: () =>
    Promise.reject("`addOrUpdateTrack` called outside of a playlist."),
  removeTrack: () =>
    Promise.reject("`removeTrack` called outside of a playlist."),
  on: () => undefined,
  off: () => undefined,
  stopTrackingChanges: () => undefined,
  registerHandle: () => undefined,
  unregisterHandle: () => undefined,
  getTrackState: () => "NOT_PLAYING",
  isDetached: false,
});

interface PlaylistProviderProps extends PropsWithChildren {
  playlistId: string;
}

export class PlaylistClass extends EventEmitter implements Playlist {
  private tracks: QuestmateAudioTrack[] = [];
  private trackStateByTrackId: Record<
    QuestmateTrackId,
    QuestmateTrackController["state"]
  > = {};
  private subscriptions: EmitterSubscription[] = [];
  private debug: DebugFn;
  private handles = new Set<string>();

  constructor(public readonly id: string) {
    super();
    this.debug = createDebugLogger(
      `Playlist[${this.id}]`,
      () => ENV.logLevels.trackPlayer === "debug"
    );
  }

  registerHandle(handleId: string) {
    this.handles.add(handleId);
  }

  unregisterHandle(handleId: string) {
    this.handles.delete(handleId);
  }

  get isDetached() {
    // If there are no handles, the playlist is considered detached.
    // This happens when navigating away from the screen that created the
    // playlist.
    return this.handles.size > 0;
  }

  stopTrackingChanges() {
    this.removeSubscriptions();
  }

  getTracks() {
    return this.tracks.map((t) => ({ ...t }));
  }

  async play(trackId: QuestmateTrackId) {
    this.setTrackState(trackId, "PREPARING_TO_PLAY");
    this.ensureSubscribedToUpdates();
    return PlaylistManager.playTrack(this.id, trackId);
  }
  async pause() {
    if (PlaylistManager.getActivePlaylist()?.id !== this.id) {
      console.warn(
        "Requesting to pause from a playlist that is not currently active!"
      );
    }

    this.setAllTracksAsNotPlaying();
    return QMTrackPlayer.pause();
  }
  async addOrUpdateTrack(track: QuestmateAudioTrack) {
    const existingIndex = this.tracks.findIndex(
      (t) => t.trackId === track.trackId
    );
    if (existingIndex >= 0) {
      this.tracks[existingIndex] = track;
    } else {
      this.tracks.push(track);
    }
    this.emit("track-data", { type: "addedOrUpdated", track });
  }
  async removeTrack(trackId: QuestmateTrackId) {
    if (!this.isDetached) {
      // We want to ignore tracks removed due to unmounting
      // if the whole playlist was unmounted.
      // However, we do want to remove tracks when the playlist is mounted
      // and just the one track is removed.
      return;
    }

    const existingIndex = this.tracks.findIndex((t) => t.trackId === trackId);

    if (existingIndex >= 0) {
      this.tracks.splice(existingIndex, 1);
      this.trackStateByTrackId = produce(this.trackStateByTrackId, (draft) => {
        delete draft[trackId];
      });
      this.emit("track-data", { type: "removed", trackId });
    }
  }

  getTrackState(trackId: QuestmateTrackId) {
    return this.trackStateByTrackId[trackId];
  }

  private ensureSubscribedToUpdates() {
    if (this.subscriptions.length > 0) {
      return;
    }

    this.subscriptions.push(
      TrackPlayer.addEventListener(
        Event.PlaybackState,
        this.playbackStateListener
      )
    );
    this.subscriptions.push(
      TrackPlayer.addEventListener(
        Event.PlaybackPlayWhenReadyChanged,
        this.playbackPlayWhenReadyChangedListener
      )
    );
  }

  private removeSubscriptions() {
    for (const subscription of this.subscriptions) {
      subscription.remove();
    }
    this.subscriptions = [];
  }

  private playbackStateListener = (
    event: EventPayloadByEvent[Event.PlaybackState]
  ) => {
    // Run async outside of queue, to avoid waiting for a `play` task.
    (async () => {
      if (PlaylistManager.getActivePlaylist()?.id !== this.id) {
        this.setAllTracksAsNotPlaying();
      }

      if (event.state === State.Playing) {
        // check if it is playing our track
        const activeTrack = await QMTrackPlayer.getActiveTrack();

        if (activeTrack) {
          this.setTrackState(activeTrack.trackId, "PLAYING");
        } else {
          this.setAllTracksAsNotPlaying();
        }
      } else if (event.state === State.Loading) {
        const willAutoplayNextTrack = await TrackPlayer.getPlayWhenReady();
        if (
          willAutoplayNextTrack ||
          Object.values(this.trackStateByTrackId).some(
            (state) => state === "PREPARING_TO_PLAY"
          )
        ) {
          const nextTrack = await QMTrackPlayer.getActiveTrack();

          if (nextTrack) {
            this.setTrackState(nextTrack.trackId, "PREPARING_TO_PLAY");
          } else {
            this.setAllTracksAsNotPlaying();
          }
        } else {
          this.setAllTracksAsNotPlaying();
        }
      } else if (![State.Buffering, State.Ready].includes(event.state)) {
        this.setAllTracksAsNotPlaying();
      }
    })();
  };

  private playbackPlayWhenReadyChangedListener = (
    event: EventPayloadByEvent[Event.PlaybackPlayWhenReadyChanged]
  ) => {
    // Run async outside of queue, to avoid waiting for a `play` task.
    (async () => {
      if (event.playWhenReady) {
        const activeTrack = await QMTrackPlayer.getActiveTrack();
        if (activeTrack) {
          this.setTrackState(activeTrack.trackId, "PREPARING_TO_PLAY");
        } else {
          this.setAllTracksAsNotPlaying();
        }
      } else {
        this.setAllTracksAsNotPlaying();
      }
    })();
  };

  private setAllTracksAsNotPlaying() {
    this.updateTrackStates((draft) => {
      for (const track of this.tracks) {
        draft[track.trackId] = "NOT_PLAYING";
      }
    });
  }

  private setTrackState(
    trackId: QuestmateTrackId,
    newState: Exclude<QuestmateTrackController["state"], "NOT_PLAYING">
  ) {
    this.updateTrackStates((draft) => {
      for (const track of this.tracks) {
        draft[track.trackId] = "NOT_PLAYING";
      }
      draft[trackId] = newState;
    });
  }

  private updateTrackStates(
    updater: (state: Draft<typeof this.trackStateByTrackId>) => unknown
  ) {
    const newTrackStates = produce(this.trackStateByTrackId, updater);
    if (!isEqual(newTrackStates, this.trackStateByTrackId)) {
      this.debug(() => {
        const changedTrackIds = (
          Object.keys(newTrackStates) as QuestmateTrackId[]
        ).filter(
          (trackId) =>
            newTrackStates[trackId] !== this.trackStateByTrackId[trackId]
        );

        this.debug(
          `Track states changed\n${changedTrackIds
            .map(
              (trackId) =>
                `    ${trackId}: ${this.trackStateByTrackId[trackId]} -> ${newTrackStates[trackId]}`
            )
            .join("\n")}`
        );
      });
      this.trackStateByTrackId = newTrackStates;
      this.emit("track-state", { statesByTrackId: newTrackStates });
    }
  }
}

const PlaylistProvider: React.FC<PlaylistProviderProps> = ({
  playlistId,
  children,
}) => {
  const playlist = PlaylistManager.usePlaylist(playlistId);

  return (
    <PlaylistContext.Provider value={playlist}>
      {children}
    </PlaylistContext.Provider>
  );
};

export const QuestPlaylistProvider: React.FC<
  Omit<PlaylistProviderProps, "playlistId">
> = (props) => {
  const questViewContext = useQuestViewContext();
  const playlistId = useMemo(() => {
    if (questViewContext.viewContext === "RUN") {
      return questViewContext.questInstanceId;
    } else {
      return questViewContext.questPrototypeId;
    }
  }, [questViewContext]);

  return <PlaylistProvider {...props} playlistId={playlistId} />;
};

export const useTrack = (
  _track: Omit<QuestmateAudioTrack, "trackId"> & { trackId: string }
): QuestmateTrackController => {
  const playlist = useContext(PlaylistContext);
  if (!playlist) {
    throw new Error("useTrack must be called within a Playlist");
  }

  const track = useIsEqualMemo(_track) as QuestmateAudioTrack;
  useEffect(() => {
    void playlist.addOrUpdateTrack(track);
  }, [playlist, track]);

  const { trackId } = track;
  useEffect(
    () => () => {
      void playlist.removeTrack(trackId);
    },
    [playlist, trackId]
  );

  const [state, setState, stateRef] = useStateWithRef<
    QuestmateTrackController["state"]
  >(() => playlist.getTrackState(track.trackId) ?? "NOT_PLAYING");
  useEffect(() => {
    const listener: TrackStateChangeListener = ({ statesByTrackId }) => {
      const newState = statesByTrackId[trackId] ?? "NOT_PLAYING";
      if (newState !== stateRef.current) {
        setState(newState);
      }
    };
    playlist.on("track-state", listener);
    return () => {
      playlist.off("track-state", listener);
    };
  }, [playlist, setState, stateRef, trackId]);

  const play = useCallback(
    async () => playlist.play(trackId),
    [playlist, trackId]
  );

  const pause = useCallback(async () => playlist.pause(), [playlist]);

  return useMemo(() => {
    return {
      state,
      play,
      pause,
    };
  }, [state, play, pause]);
};
