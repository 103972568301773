import { Image } from "react-native";

export const getImageSize = (
  uri: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        resolve({
          width,
          height,
        });
      },
      (err) => {
        reject(err);
      }
    );
  });
};
