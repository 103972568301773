import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
  Method,
} from "axios";
import { store } from "@app/store";
import { RequireAtLeastOne } from "@app/types";
import { Analytics } from "@app/analytics";
import { sentry } from "@app/util/sentry";
import jwtDecode from "jwt-decode";
import { accessTokenRefreshed } from "@app/store/auth";
import { removeSessionToken } from "@app/store/publicQuestAuth";
import linkingConfig from "@app/navigation/linkingConfig";
import { logOut } from "@app/screens/LogoutScreen";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { selectLoggedInUser } from "@app/store/cache/users";
import {
  retrieveRefreshToken,
  storeRefreshToken,
} from "@app/store/persistConfiguration";
import { cleanAxiosError, setHeadersOnRequestConfig } from "@questmate/common";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { ENV } from "@app/config/env";
import { type QMErrorResponse } from "@questmate/openapi-spec";

const { apiBaseUrl, auth0ClientId, auth0BaseUrl } = ENV;

export const DEFAULT_TIMEOUT = 20 * 1000;

export const createQuestmateApiClient = (
  config?: RequestConfig
): AxiosInstance => {
  const _client = axios.create({
    baseURL: `${apiBaseUrl}`,
    timeout: config?.timeout ?? DEFAULT_TIMEOUT,
  });
  _client.interceptors.request.use(auth0TokenInjector);
  _client.interceptors.request.use(analyticsHeadersInjector);
  return _client;
};

const abortControllerByUserId: Record<string, AbortController> = {};
export const abortRequests = (
  reason: string,
  userId = getLoggedInUserId() || "LOGGED_OUT"
) => {
  abortControllerByUserId[userId]?.abort(reason);
};

const getAbortController = (userId = getLoggedInUserId() || "LOGGED_OUT") => {
  if (!abortControllerByUserId[userId]) {
    abortControllerByUserId[userId] = new AbortController();
  }
  return abortControllerByUserId[userId];
};

const MAX_TOKEN_REFRESH_ATTEMPTS = 1;
type RequestConfig = { timeout?: number };
export const apiRequest = async <T>(
  method: Method,
  url: string,
  data?: unknown,
  publicQuestSessionToken?: string,
  config?: RequestConfig
): Promise<T> => {
  const qmApiClient = createQuestmateApiClient(config);
  let attemptsCount = 0;
  let response;

  do {
    attemptsCount++;
    try {
      publicQuestSessionToken = validatePublicQuestSessionToken(
        publicQuestSessionToken
      );
      // eslint-disable-next-line prefer-const
      response = await qmApiClient<T | QMErrorResponse>(url, {
        signal: getAbortController().signal,
        method: method,
        data: data,
        headers: {
          ...(publicQuestSessionToken
            ? { Authorization: `Bearer ${publicQuestSessionToken}` }
            : {}),
        },
        validateStatus: (status) => {
          return status === 401 || (status >= 200 && status < 300);
        },
      });
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.status >= 500) {
        const sentryEventId = sentry.captureException(
          new Error(
            `${errorResponse.status} response from ${errorResponse.config.url}`
          ),
          {
            contexts: {
              "Error Response": cleanAxiosError(errorResponse),
            },
          }
        );
        console.error(
          `Received 5xx response from server. Event id: ${sentryEventId}. Response:`,
          errorResponse
        );
      }
      throw QMApiError.fromAxiosError(error);
    }

    if (
      response.status === 401 &&
      attemptsCount <= MAX_TOKEN_REFRESH_ATTEMPTS
    ) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "message" in response.data &&
        response.data?.message ===
          "Your account has been blocked. Try logging in again or contact support."
      ) {
        // user account is blocked, usually because the user needs to link their SAML managed account.
        logoutAndNavigateBackHereAfterLogin("User account is blocked.");
        break;
      } else if (publicQuestSessionToken) {
        // public session token is not valid, remove it
        store.dispatch(removeSessionToken(publicQuestSessionToken));
        publicQuestSessionToken = undefined;
        if (!store.getState().auth.loggedInUserId) {
          // User is not logged in, so we should not try again.
          break;
        }
      } else if (store.getState().auth.loggedInUserId) {
        // User is logged in, we should try to refresh the token.
        await refreshTokenAggregator.get();
      } else {
        break;
      }
    }
  } while (
    response.status === 401 &&
    attemptsCount <= MAX_TOKEN_REFRESH_ATTEMPTS
  );

  if (response.status === 401) {
    if (attemptsCount > MAX_TOKEN_REFRESH_ATTEMPTS) {
      throw new QMApiError({
        message:
          "Network error: token refreshed, but got 401 again on subsequent request.",
        axiosResponse: response,
      });
    } else {
      throw QMApiError.fromAxios401Response(response);
    }
  }

  return response.data as T;
  // We don't wanna catch() any exception on purpose, so swr can handle it.
};

async function refreshAccessToken() {
  sentry.addBreadcrumb({
    message: "Refreshing access token.",
  });
  const refreshToken = await retrieveRefreshToken();
  if (!refreshToken) {
    sentry.captureMessage(
      "No Refresh Token available to refresh access token."
    );

    // logoutAndNavigateBackHereAfterLogin();
    throw new QMApiError({
      status: 401,
      message: "No refresh token available to refresh authentication token",
    });
  }

  const body = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    client_id: auth0ClientId,
  };
  const address = `${auth0BaseUrl}/oauth/token/`;
  return axios
    .post<{
      access_token: string;
      refresh_token: string;
    }>(address, body, {
      signal: getAbortController().signal,
      headers: { "Content-Type": "application/json" },
    })
    .then((refreshTokenResponse) => {
      const { access_token, refresh_token } = refreshTokenResponse.data;

      const hasAccessToken = !!access_token;
      const hasRefreshToken = !!refresh_token;
      sentry.addBreadcrumb({
        message: "Refresh access token response ok.",
        data: {
          hasAccessToken,
          hasRefreshToken,
        },
      });
      if (!hasAccessToken) {
        console.error(
          "Failed to refresh Auth0 token. Response ok, but missing access token."
        );
        sentry.captureMessage(
          "Failed to refresh Auth0 token. Response ok, but missing access token.",
          {
            extra: {
              hasAccessToken,
              hasRefreshToken,
              response: {
                status: refreshTokenResponse.status,
                data: {
                  ...refreshTokenResponse.data,
                  ...(refreshTokenResponse.data.refresh_token
                    ? { refresh_token: "redacted" }
                    : {}),
                  ...(refreshTokenResponse.data.access_token
                    ? { access_token: "redacted" }
                    : {}),
                },
              },
            },
          }
        );
        throw new QMApiError({
          message: "Failed to refresh Auth0 token. Missing access token.",
          status: refreshTokenResponse.status,
        });
      }

      store.dispatch(accessTokenRefreshed(access_token));
      if (hasRefreshToken) {
        // TODO throw if no refresh token after we enable token rotation on prod Auth0
        return storeRefreshToken(refresh_token);
      }
    })
    .catch((refreshTokenError) => {
      console.error(
        "Failed to refresh Auth0 token.",
        cleanAxiosError(refreshTokenError)
      );
      const responseStatus = refreshTokenError?.response?.status;
      if (responseStatus === 403) {
        sentry.captureMessage(
          "Received 403 when refreshing access token. Logging out...",
          {
            extra: {
              response: cleanAxiosError(refreshTokenError),
            },
          }
        );
        return logoutAndNavigateBackHereAfterLogin(
          "Received 403 when refreshing access token."
        );
      } else {
        sentry.captureMessage(
          "Failed to refresh Auth0 token with unknown refreshTokenError.",
          {
            extra: {
              response: cleanAxiosError(refreshTokenError),
            },
          }
        );
        throw new QMApiError({
          message: "Failed to refresh Auth0 token.",
          status: refreshTokenError.status,
        });
      }
    });
}

interface QMApiErrorMetadata {
  status?: number;
  message: string;
  errors?: QMErrorResponse["errors"];
  eventId?: QMErrorResponse["eventId"];
  axiosError?: AxiosError;
  axiosResponse?: AxiosResponse;
}

export class QMApiError extends Error {
  readonly errors: QMErrorResponse["errors"];
  readonly eventId: QMErrorResponse["eventId"];
  readonly status: number;

  constructor(
    metadata: RequireAtLeastOne<
      QMApiErrorMetadata,
      "axiosError" | "axiosResponse" | "status"
    >
  ) {
    super(metadata.message || "Unknown Error");
    this.status =
      metadata.status ||
      metadata.axiosError?.response?.status ||
      metadata.axiosResponse?.status ||
      -1;
    this.eventId = metadata.eventId;
    this.errors = metadata.errors ?? [];

    this.name = `QMApiError(status=${this.status}${
      this.eventId ? `, eventId=${this.eventId}` : ""
    })`;
  }

  static fromAxiosError(axiosError: AxiosError): QMApiError {
    return new this({
      message:
        (isQuestmateErrorResponse(axiosError.response) &&
          axiosError.response?.data?.message) ||
        axiosError.message ||
        axiosError.response?.data?.toString() ||
        "Error Message N/A",
      errors:
        isQuestmateErrorResponse(axiosError.response) &&
        Array.isArray(axiosError.response?.data?.errors)
          ? axiosError.response.data.errors
          : [],
      axiosError,
    });
  }

  static fromAxios401Response(axiosResponse: AxiosResponse): QMApiError {
    return new this({
      message:
        (isQuestmateErrorResponse(axiosResponse) &&
          axiosResponse?.data?.message) ||
        axiosResponse?.data?.toString() ||
        "Error Message N/A",
      axiosResponse,
    });
  }
}

function logoutAndNavigateBackHereAfterLogin(reason: string) {
  const pathFromState = linkingConfig.getPathFromState(
    navigationRef.getRootState()
  );
  const urlWithoutLoginId = new URL(pathFromState, "https://www.example.com");
  urlWithoutLoginId.searchParams.delete("loginId");
  const pathBackToHere = urlWithoutLoginId.pathname + urlWithoutLoginId.search;

  const loggedInUser = selectLoggedInUser(store.getState());
  const loginId = loggedInUser?.email || loggedInUser?.phone;

  logOut({
    reason,
    screenName: "Login",
    screenParams: {
      url: pathBackToHere,
      ...(loginId ? { loginId } : {}),
    },
  });
}

async function auth0TokenInjector(requestConfig: InternalAxiosRequestConfig) {
  const headers = {} as Record<string, string>;
  if (!requestConfig.headers.has("Authorization")) {
    let accessToken = store.getState().auth.accessToken;
    if (accessToken) {
      const token = jwtDecode<{ exp: number }>(accessToken);
      const currentUTCTimeInSeconds = Date.now() / 1000;
      if (token?.exp && token.exp < currentUTCTimeInSeconds + 10) {
        sentry.addBreadcrumb({
          message: "Auth0 access token is expired.",
          data: {
            secondsExpired: currentUTCTimeInSeconds - token.exp,
          },
        });
        await refreshTokenAggregator.get();
        accessToken = store.getState().auth.accessToken;
      }

      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }
  return setHeadersOnRequestConfig(requestConfig, headers);
}

async function analyticsHeadersInjector(
  requestConfig: InternalAxiosRequestConfig
) {
  const [sessionId, anonymousId] = await Promise.all([
    Analytics.getSessionId(),
    Analytics.getAnonymousId(),
  ]);

  return setHeadersOnRequestConfig(requestConfig, {
    "X-Analytics-Session-Id": `${sessionId}`,
    "X-Analytics-Anonymous-Id": `${anonymousId}`,
  });
}

function validatePublicQuestSessionToken(
  publicQuestSessionToken: string | undefined
) {
  if (publicQuestSessionToken) {
    const token = jwtDecode<{ exp: number }>(publicQuestSessionToken);
    const currentUTCTimeInSeconds = Date.now() / 1000;
    if (token?.exp && token?.exp < currentUTCTimeInSeconds + 10) {
      // public session token is expired, remove it
      store.dispatch(removeSessionToken(publicQuestSessionToken));
      publicQuestSessionToken = undefined;
    }
  }
  return publicQuestSessionToken;
}

export class AsyncRequestAggregator<T> {
  private promise: Promise<T> | null;
  private readonly sendRequest: () => Promise<T>;
  constructor(provider: () => Promise<T>) {
    this.sendRequest = provider;
  }

  public get(): Promise<T> {
    if (!this.promise) {
      this.promise = this.sendRequest();
      void this.promise.finally(() => {
        this.promise = null;
      });
    }
    return this.promise;
  }
}

function isQuestmateErrorResponse<T = unknown>(
  response?: AxiosResponse<T | QMErrorResponse>
): response is AxiosResponse<QMErrorResponse> {
  return Boolean(
    response &&
      response.data &&
      typeof response.data === "object" &&
      "message" in response.data &&
      typeof response.data.message === "string"
  );
}

const refreshTokenAggregator = new AsyncRequestAggregator(refreshAccessToken);
