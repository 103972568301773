import { UserListItem } from "@questmate/openapi-spec";
import { usersLoaded } from "@app/store/cache/users";
import { store } from "@app/store";
import { createRequest, noopRequest } from "@app/util/client/requests/index";

export const getUsers = (userIdsToQuery: string[]) => {
  if (!Array.isArray(userIdsToQuery) || userIdsToQuery.length === 0) {
    return noopRequest([]);
  }
  return createRequest<UserListItem[]>(
    "get",
    `/users?${userIdsToQuery.map((id) => `id=${id}`).join("&")}`
  )((data) => {
    store.dispatch(usersLoaded(data));
  });
};
