import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActivityIndicator,
  FlatList,
  PixelRatio,
  Platform,
  View,
  ViewProps,
} from "react-native";
import styled, { ThemeContext } from "styled-components/native";
import Icon from "../icon";
import QKIcon from "./icon";
import { uuid } from "@app/util/uuid";
import { Image } from "expo-image";
import {
  FileSystemSessionType,
  FileSystemUploadType,
  uploadAsync,
} from "expo-file-system";
import * as VideoThumbnails from "expo-video-thumbnails";
import axios from "axios";
import { MediaContext } from "@app/context/MediaContext";
import { TokenContext } from "@app/context/TokenContext";
import {
  type MediaItem as PersistedMediaItem,
  MediaItemType,
} from "@questmate/common";

import {
  InProgressMediaItem,
  LocallyUploadedMediaItem,
  MediaItem,
  MediaItemStatus,
  MobileCameraMediaItem,
  MobileGalleryMediaItem,
  UploadableFile,
  WebMediaItem,
} from "@app/types/mediaPicker";
import * as ImageManipulator from "expo-image-manipulator";
import { getImageSize } from "@app/util/media/image";
import Text from "@app/components/questkit/text";
import ConditionalWrap from "conditional-wrap";
import { UploadWrapperWeb } from "./uploadWrapper.web";
import * as ImagePicker from "expo-image-picker";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { RequireAtLeastOne } from "@app/types";
import BasePressable from "@app/components/questkit/BasePressable";
import { createLink } from "@app/util/link.utils";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { Analytics } from "@app/analytics";
import { ENV } from "@app/config/env";
import isEqual from "react-fast-compare";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

const { cloudinaryApiBaseUrl, cloudinaryResourceBaseUrl } = ENV;

const THUMBNAIL_RESOLUTION = PixelRatio.get() * 120;

// @TODO

// Hardy: cater for white images/documents as previews
// Hardy: cater for strip within item, as well as scrolling elements (< >)

// fix sorting across uploadQueue and existing items
// make things work for testing + prod (separate accounts!) ..
// move upload to util/... to reuse outside of filmstrip
// save/show filename (do along with new (multi)picker component that also supports non image/video files)
// secure against unwanted transformations
// make transition from local to remote preview seamless (dont blink)
// multi select ios/android
// improve performance

// James: Make whole item (strip area) not clickable in !readOnly mode (as we don't have a handler bound anyway)
//         \ disabled={!handler} :)

// (Full screen viewer)

interface MediaSliderViewProps {
  editMode: boolean;
  readOnly: boolean;
  mediaItems: PersistedMediaItem[];
  onMediaItemsChange: (mediaItems: PersistedMediaItem[]) => void;
  accessibilityLabelledBy?: ViewProps["accessibilityLabelledBy"];
}

interface MediaItemViewProps {
  editMode: boolean;
  readOnly: boolean;
  mediaItem: MediaItem;
  retryUpload: (id: string) => void;
  removeItem: (
    identifier: RequireAtLeastOne<
      { publicId: string; localId: string },
      "publicId" | "localId"
    >
  ) => void;
}

interface SuccessUploadResult {
  success: true;
  publicId: string;
  resourceType: MediaItemType;
}
interface FailedUploadResult {
  success: false;
}

type UploadResult = SuccessUploadResult | FailedUploadResult;

const isSuccessStatus = (status: number) => {
  return status >= 200 && status < 400;
};

export interface MediaToken {
  mediaPreviewToken: string;
  mediaToken: string;
  mediaUploadSignature: string;
  timestamp: number;
  duration: number;
  expires_at: number;
  apiKey: string;
}

const resizeImage = async (uri: string): Promise<string> => {
  const { width: imageWidth, height: imageHeight } = await getImageSize(uri);

  const imageRatio = imageHeight / imageWidth;

  let newHeight = 1920;
  let newWidth = 1920;

  if (imageRatio > 1) {
    newWidth = newHeight / imageRatio;
  } else if (imageRatio < 1) {
    newHeight = newWidth * imageRatio;
  }

  const imageManipulatorResult = await ImageManipulator.manipulateAsync(
    uri,
    [
      {
        resize: {
          height: newHeight,
          width: newWidth,
        },
      },
    ],
    { compress: 0.5 }
  );

  return imageManipulatorResult.uri;
};

const preProcessFile = async (
  mediaItem: InProgressMediaItem
): Promise<InProgressMediaItem> => {
  let preProcessedMediaItem = { ...mediaItem };

  if (mediaItem.type === MediaItemType.Image) {
    const { width: imageWidth, height: imageHeight } = await getImageSize(
      mediaItem.uri
    );

    const exceedsMaxWidth = imageWidth > 1920;
    const exceedsMaxHeight = imageHeight > 1920;
    if (exceedsMaxWidth || exceedsMaxHeight) {
      const resizedUri = await resizeImage(mediaItem.uri);
      preProcessedMediaItem = {
        ...preProcessedMediaItem,
        uri: resizedUri,
      };
    }
  }

  return preProcessedMediaItem;
};

const captureFromCamera = async (): Promise<MobileCameraMediaItem[]> => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();
  if (status !== "granted") {
    return [];
  }
  const result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 1,
    allowsMultipleSelection: true,
    exif: false,
  });

  if (result.canceled) {
    return [];
  } else {
    return result.assets.map((file) => {
      const name = file.uri.split("/").pop();
      const extension =
        name && name.split(".").length > 1 ? name.split(".").pop() || "" : "";

      return {
        uri: file.uri,
        name: `capture.${extension.toLowerCase()}`,
        type: file.type,
      };
    });
  }
};

const selectFromGallery = async (): Promise<MobileGalleryMediaItem[]> => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 1,
    allowsMultipleSelection: true,
  });
  if (result.canceled) {
    return [];
  } else {
    return result.assets.map((file) => {
      const name = file.uri.split("/").pop();
      const extension =
        name && name.split(".").length > 1 ? name.split(".").pop() || "" : "";

      return {
        uri: file.uri,
        name: file.fileName || `upload.${extension.toLowerCase()}`,
        type: file.type,
      };
    });
  }
};

const uploadFile = async (
  mediaItem: InProgressMediaItem,
  onUploadProgress: (uploadProgress: number) => void,
  mediaContextId: string,
  mediaToken: MediaToken
): Promise<UploadResult> => {
  const uploadPreset = "authenticated";
  const apiKey = mediaToken.apiKey;
  const url = `${cloudinaryApiBaseUrl}/auto/upload`;

  const folder = mediaContextId;

  const signature = mediaToken.mediaUploadSignature;
  const timestamp = mediaToken.timestamp;

  let uploadResult;
  let body;

  const parameters = {
    folder: folder,
    upload_preset: uploadPreset,
    api_key: apiKey,
    timestamp: timestamp.toString(),
    signature: signature,
    type: "authenticated",
    use_filename: "true",
    unique_filename: "true",
  };

  if (Platform.OS === "web") {
    const formData = new FormData();

    formData.append("file", (mediaItem as WebMediaItem).blob);

    for (const [key, value] of Object.entries(parameters)) {
      formData.append(key, value);
    }

    try {
      uploadResult = await axios({
        url: url,
        method: "post",
        data: formData,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = (1 / progressEvent.total) * progressEvent.loaded;
            onUploadProgress(progress);
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }

    body = uploadResult.data;
  } else {
    try {
      uploadResult = await uploadAsync(url, mediaItem.uri, {
        httpMethod: "POST",
        sessionType: FileSystemSessionType.BACKGROUND,
        uploadType: FileSystemUploadType.MULTIPART,
        fieldName: "file",
        parameters,
      });
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }

    body = JSON.parse(uploadResult.body);
  }

  if (isSuccessStatus(uploadResult.status)) {
    return {
      success: true,
      // todo: consider storing the publicId explicitly from body.public_id and the extension separately body.format
      //       this would make it simpler to use the publicId with different extensions like we do when creating a preview URL
      publicId: body.secure_url.split("/").pop(),
      resourceType: body.resource_type,
    };
  } else {
    console.log("Upload failed.", uploadResult);
    return {
      success: false,
    };
  }
};

const MediaSlider: React.FC<MediaSliderViewProps> = ({
  editMode,
  readOnly,
  mediaItems = [],
  onMediaItemsChange,
  accessibilityLabelledBy,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();

  const [mediaSliderId] = useState(uuid());

  const [uploadQueue, setUploadQueue] = useState<InProgressMediaItem[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [finishedUploads, setFinishedUploads] = useState<
    LocallyUploadedMediaItem[]
  >([]);

  const tokenContext = useContext(TokenContext);
  const mediaContext = useContext(MediaContext);

  useEffect(() => {
    if (finishedUploads.length > 0) {
      onMediaItemsChange(
        (finishedUploads as PersistedMediaItem[]).concat(mediaItems)
      );
      setFinishedUploads([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedUploads]);

  useEffect(() => {
    // Upload queue executor
    const uploadFileAsync = async () => {
      if (uploadProgress === null) {
        const uploadFileItem = uploadQueue.find(
          (mediaItem) => mediaItem.status === MediaItemStatus.UploadQueued
        );

        if (uploadFileItem) {
          setUploadQueue((uploadQueue) => {
            return uploadQueue.map((item) => {
              if (item.localId === uploadFileItem.localId) {
                return {
                  ...item,
                  status: MediaItemStatus.Uploading,
                };
              } else {
                return item;
              }
            });
          });
          setUploadProgress(0);
          const compressResult = await preProcessFile(uploadFileItem);
          const mediaToken = await tokenContext.getToken(
            "MEDIA",
            mediaContext.uploadContextType,
            mediaContext.uploadContextId
          );
          if (!mediaToken) {
            throw new Error(
              `Unexpected Error: Media token not found for ${mediaContext.uploadContextType} ${mediaContext.uploadContextId}`
            );
          }

          const uploadResult = await uploadFile(
            compressResult,
            (_progress) => {
              // console.log("progress", progress);
            },
            mediaContext.uploadContextId,
            mediaToken
          );

          if (uploadResult.success) {
            setUploadProgress(null);
            setUploadQueue((uploadQueue) => {
              return uploadQueue.filter((fileInQueue) => {
                if (fileInQueue.localId === uploadFileItem.localId) {
                  setFinishedUploads((finishedUploads) => {
                    return [
                      ...finishedUploads,
                      {
                        name: uploadFileItem.name,
                        mediaContextType: mediaContext.uploadContextType,
                        publicId: uploadResult.publicId,
                        type: uploadResult.resourceType,
                        localId: uploadFileItem.localId,
                        localPreviewUri: compressResult.uri,
                      },
                    ];
                  });
                  return false;
                } else {
                  return true;
                }
              });
            });
          } else {
            setUploadProgress(null);
            // Set status to UploadFailed
            setUploadQueue((uploadQueue) => {
              return uploadQueue.map((fileInQueue) => {
                if (fileInQueue.localId === uploadFileItem.localId) {
                  return {
                    ...fileInQueue,
                    status: MediaItemStatus.UploadFailed,
                  };
                } else {
                  return fileInQueue;
                }
              });
            });
          }
        }
      }
    };
    void uploadFileAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadQueue]);

  const uploadFiles = useCallback(
    (files: UploadableFile[]) => {
      if (files.length > 0) {
        setUploadQueue((uploadQueue) => {
          return files
            .map<InProgressMediaItem>((file) => {
              return {
                name: file.name,
                type: file.type as MediaItemType,
                mediaContextType: mediaContext.uploadContextType,
                uri: file.uri,
                status: MediaItemStatus.UploadQueued,
                localId: uuid(),
                ...("blob" in file ? { blob: file.blob } : {}),
              };
            })
            .concat(uploadQueue);
        });
      }
    },
    [mediaContext.uploadContextType]
  );

  return (
    <View onStartShouldSetResponder={() => true}>
      <ConditionalWrap
        condition={Platform.OS === "web" && !readOnly}
        wrap={(children) => (
          <UploadWrapperWeb
            uploadFiles={uploadFiles}
            uploadButtonId={`hidden-upload-button-web-${mediaSliderId}`}
          >
            {children}
          </UploadWrapperWeb>
        )}
      >
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          accessibilityLabelledBy={accessibilityLabelledBy}
          ListHeaderComponent={
            Platform.OS === "web" ? (
              <label htmlFor={`hidden-upload-button-web-${mediaSliderId}`}>
                <AddMediaItem editMode={editMode}>
                  <Icon icon={"plus"} size={32} inverted />
                </AddMediaItem>
              </label>
            ) : (
              <PressableOpacity
                disabled={readOnly}
                accessibilityRole="button"
                accessibilityHint="Add media"
                onPress={async () => {
                  // Open picker
                  showActionSheetWithOptions(
                    {
                      options: ["Take photo", "Select from gallery", "Cancel"],
                      destructiveButtonIndex: 2,
                    },
                    async (selectedIndex: number) => {
                      switch (selectedIndex) {
                        case 0:
                          // Camera
                          uploadFiles(await captureFromCamera());
                          break;
                        case 1:
                          // Gallery
                          uploadFiles(await selectFromGallery());
                          break;
                        case 2:
                        // Canceled
                      }
                    }
                  );
                }}
              >
                <AddMediaItem editMode={editMode}>
                  <Icon icon={"plus"} size={32} inverted />
                </AddMediaItem>
              </PressableOpacity>
            )
          }
          data={[...uploadQueue, ...mediaItems]}
          keyExtractor={(_item, index) => index.toString()}
          renderItem={({ item, index }) => {
            return (
              <MediaItemView
                editMode={editMode}
                readOnly={readOnly}
                mediaItem={item}
                // onPressDelete={() => onDeleteMediaItem(index)}
                key={"images" + index}
                retryUpload={(localId: string) => {
                  setUploadQueue((uploadQueue) => {
                    return uploadQueue.map((item) => {
                      if (item.localId === localId) {
                        return {
                          ...item,
                          status: MediaItemStatus.UploadQueued,
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }}
                removeItem={({ localId, publicId }) => {
                  if (publicId) {
                    onMediaItemsChange(
                      mediaItems.filter((item) => item.publicId !== publicId)
                    );
                  } else {
                    setUploadQueue((uploadQueue) => {
                      return uploadQueue.filter(
                        (item) => item.localId !== localId
                      );
                    });
                  }
                }}
              />
            );
          }}
        />
      </ConditionalWrap>
    </View>
  );
};

function isInProgress(mediaItem: MediaItem): mediaItem is InProgressMediaItem {
  return "status" in mediaItem && !!mediaItem.status;
}

function isPersisted(mediaItem: MediaItem): mediaItem is PersistedMediaItem {
  return "publicId" in mediaItem && !!mediaItem.publicId;
}

function createTransform(
  width: number,
  height: number,
  assetType: MediaItemType
) {
  let transformResolution = `w_${width},h_${height},c_lfill,f_auto`;
  if (assetType === MediaItemType.Video) {
    transformResolution += ",so_0";
  }
  return transformResolution;
}

const createPreviewUri = async (
  mediaItem: MediaItem,
  mediaContextId: string,
  mediaToken: MediaToken
): Promise<string | undefined> => {
  if (isPersisted(mediaItem)) {
    // Return remote preview URI with thumbnail dimensions
    if (mediaItem.type === MediaItemType.Raw) {
      // cannot create a preview image for raw files
      return undefined;
    }

    let assetId = mediaItem.publicId;

    if (mediaItem.type === MediaItemType.Video) {
      const publicIdWithoutExtension = mediaItem.publicId.substring(
        0,
        mediaItem.publicId.lastIndexOf(".")
      );
      assetId = publicIdWithoutExtension + ".jpg";
    }

    const transformResolution = createTransform(
      THUMBNAIL_RESOLUTION,
      THUMBNAIL_RESOLUTION,
      mediaItem.type
    );

    const baseThumbnailPath = `${cloudinaryResourceBaseUrl}/${mediaItem.type}/authenticated/${transformResolution}/${mediaContextId}`;
    return `${baseThumbnailPath}/${assetId}?${mediaToken.mediaPreviewToken}`;
  }

  // Return local preview uri
  if (Platform.OS === "web") {
    if (mediaItem.uri.startsWith("data:image/")) {
      return mediaItem.uri;
    }
  } else if (mediaItem.type === MediaItemType.Image) {
    return mediaItem.uri?.startsWith("/")
      ? `file://${mediaItem.uri}`
      : mediaItem.uri;
  } else if (mediaItem.type === MediaItemType.Video) {
    const videoPreview = await VideoThumbnails.getThumbnailAsync(
      mediaItem.uri,
      { quality: 1, time: 0 }
    );
    // todo: determine if the videoPreview.uri ever starts with a slash
    return videoPreview.uri?.startsWith("/")
      ? `file://${videoPreview.uri}`
      : videoPreview.uri;
  }
};

const MediaItemView: React.FC<MediaItemViewProps> = React.memo(
  ({ editMode, mediaItem, readOnly, retryUpload, removeItem }) => {
    const themeContext = useContext(ThemeContext);
    const mediaContext = useContext(MediaContext);
    const tokenContext = useContext(TokenContext);

    const mediaContextId = useMemo(() => {
      return mediaContext.contexts.find(
        (mediaContext) => mediaContext.type === mediaItem.mediaContextType
      )!.id;
    }, [mediaContext.contexts, mediaItem.mediaContextType]);
    const [mediaToken, setMediaToken, mediaTokenRef] = useStateWithRef<
      MediaToken | undefined
    >();
    const updateTokenRef = useRef<() => Promise<MediaToken>>();
    updateTokenRef.current = async () => {
      const newToken = await tokenContext.getToken(
        "MEDIA",
        mediaItem.mediaContextType,
        mediaContextId
      );
      if (!newToken) {
        throw new Error("Unexpected Error: Media token not found");
      }
      if (mediaTokenRef.current !== newToken) {
        setMediaToken(newToken);
      }
      return newToken;
    };
    useEffectOnce(() => {
      void updateTokenRef.current!();
      /**
       * Regularly request and store the latest valid token.
       * Tokens are valid for 1 hour. The token context will provide the latest valid
       * token or fetch a new one if it is expired or expires within the next 5 minutes.
       */
      const tokenRefreshInterval = setInterval(async () => {
        void updateTokenRef.current!();
      }, 3 * 60 * 1000);
      return () => {
        clearInterval(tokenRefreshInterval);
      };
    });

    const [previewUri, setPreviewUri] = useState<string | undefined>();
    useEffect(() => {
      const setPreviewUriAsync = async () => {
        if (!mediaToken) {
          return;
        }

        setPreviewUri(
          await createPreviewUri(mediaItem, mediaContextId, mediaToken)
        );
      };
      void setPreviewUriAsync();
    }, [mediaToken, mediaItem, mediaContextId]);

    const onPress = useMemo(() => {
      if (!mediaToken || isInProgress(mediaItem)) {
        return;
      }
      const remoteUrl = `${cloudinaryResourceBaseUrl}/${mediaItem.type}/authenticated/${mediaContextId}/${mediaItem.publicId}`;
      const authenticatedRemoteUrl = `${remoteUrl}?${mediaToken!.mediaToken}`;
      return createLink(authenticatedRemoteUrl, {
        newTabOnWeb: true,
        onPressHook: () => {
          Analytics.trackEvent("Open Media Item", { type: mediaItem.type });
        },
      });
    }, [mediaContextId, mediaItem, mediaToken]);
    return (
      <MediaItemWrapper onPress={onPress} disabled={!onPress}>
        {/* Thumbnail */}
        {previewUri && (
          <ThumbnailImage
            source={{ uri: previewUri }}
            ready={isPersisted(mediaItem)}
          />
        )}
        {mediaItem.name && (
          <FilenameText size="small" numberOfLines={2}>
            {mediaItem.name}
          </FilenameText>
        )}
        {/* Progress indicator */}
        {isInProgress(mediaItem) &&
          mediaItem.status === MediaItemStatus.Uploading && (
            <UploadProgressIndicator>
              <ActivityIndicator color={themeContext.background} size="small" />
            </UploadProgressIndicator>
          )}
        {editMode || !readOnly ? (
          <FileActions>
            {/* Delete action */}
            {(isInProgress(mediaItem) &&
              mediaItem.status !== MediaItemStatus.Uploading) ||
              (isPersisted(mediaItem) && (
                <FileActionButton
                  onPress={() => {
                    removeItem(
                      isInProgress(mediaItem)
                        ? { localId: mediaItem.localId }
                        : { publicId: mediaItem.publicId }
                    );
                  }}
                >
                  <QKIcon name="trash" />
                </FileActionButton>
              ))}
            {/* Retry button */}
            {isInProgress(mediaItem) &&
              mediaItem.status === MediaItemStatus.UploadFailed && (
                <FileActionButton
                  onPress={() => {
                    retryUpload(mediaItem.localId);
                  }}
                >
                  <QKIcon name="refresh" />
                </FileActionButton>
              )}
          </FileActions>
        ) : null}
      </MediaItemWrapper>
    );
  },
  isEqual
);
MediaItemView.displayName = "MediaItemView";

const AddMediaItem = styled.View<{ editMode?: boolean }>`
  ${({ theme, editMode }) => `
      height: 120px;
      width: 120px;
      margin-right: 16px;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      background-color: ${
        editMode ? theme.interaction.neutralStrong : theme.interaction.primary
      };
      ${Platform.OS === "web" && !editMode && "cursor: pointer;"}
    `}
`;

const FilenameText = styled(Text)`
  position: absolute;
  width: 120px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.interaction.secondary};
  background-color: ${({ theme }) => theme.interaction.primary};
  opacity: 0.8;
`;

const FileActions = styled.View`
  position: absolute;
  right: 7px;
  bottom: 7px;
  flex-direction: row;
`;

const FileActionButton = styled(BasePressable)`
  width: 30px;
  height: 30px;
  margin-left: 7px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

interface ThumbnailImageProps {
  ready: boolean;
}
const ThumbnailImage = styled(Image)<ThumbnailImageProps>`
  width: 120px;
  height: 120px;
  opacity: ${({ ready }) => (ready ? 1 : 0.2)};
`;

const MediaItemWrapper = styled(BasePressable)`
  overflow: hidden;
  border-radius: 8px;
  width: 120px;
  height: 120px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.interaction.primary};
`;

const UploadProgressIndicator = styled.View`
  background-color: ${({ theme }) => theme.interaction.primary};
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export default MediaSlider;
