import React from "react";
import styled from "styled-components/native";

import Button, { ButtonProps } from "@app/components/questkit/button";
import AnimatedText from "@app/components/animated/animatedText";
import { Text } from "../questkit";
import { Platform, Pressable } from "react-native";
import isEqual from "react-fast-compare";

export type ButtonComponentProps = Pick<ButtonProps, "onPress" | "loading">;

export type PlaceholderViewAction =
  | {
      type: "primary";
      text: string;
      onPress: () => void;
      success?: boolean;
      loading?: boolean;
    }
  | {
      type: "secondary";
      text: string;
      onPress: () => void;
    }
  | {
      type: "custom";
      Component: React.FC<ButtonComponentProps>;
      onPress: () => void;
      loading?: boolean;
    };

interface PlaceHolderViewProps {
  text: string;
  additionalInfo?: React.ReactElement;
  actions?: PlaceholderViewAction[];
  testID?: string;
}

const _PlaceholderView: React.FC<PlaceHolderViewProps> = ({
  text,
  additionalInfo,
  actions = [],
  testID,
}) => {
  return (
    <PlaceHolderViewWrapper testID={testID}>
      <PlaceHolderViewTextWrapper>
        <AnimatedText content={text} style={{}} />
      </PlaceHolderViewTextWrapper>
      {additionalInfo ? (
        <PlaceHolderViewActionsWrapper>
          {additionalInfo}
        </PlaceHolderViewActionsWrapper>
      ) : null}
      <PlaceHolderViewActionsWrapper>
        {actions.map((action, index) => {
          // When `mutate` is passed as the action's `onPress` it must not be called with the onPress event or
          // else it will update the SWR data to be the onPress event.
          const onPressWithoutEventParameter = () => action.onPress();
          if (action.type === "primary") {
            return (
              <PlaceHolderViewButton
                key={index}
                title={action.text}
                onPress={onPressWithoutEventParameter}
                loading={action.loading ?? false}
                success={action.success ?? false}
              />
            );
          } else if (action.type === "secondary") {
            return (
              <PlaceHolderViewSecondaryButton
                key={index}
                title={action.text}
                onPress={onPressWithoutEventParameter}
              />
            );
          } else if (action.type === "custom") {
            const { Component, loading, onPress } = action;
            return (
              <Component
                key={index}
                onPress={onPress}
                loading={loading ?? false}
              />
            );
          } else {
            return null;
          }
        })}
      </PlaceHolderViewActionsWrapper>
    </PlaceHolderViewWrapper>
  );
};

const PlaceHolderViewSecondaryButton = ({
  onPress,
  title,
}: {
  onPress: () => void;
  title: string;
}) => {
  return (
    <PlaceHolderViewSecondaryButtonWrapper onPress={onPress}>
      <Text style={{ textDecorationLine: "underline" }} size="medium">
        {title}
      </Text>
    </PlaceHolderViewSecondaryButtonWrapper>
  );
};

const PlaceHolderViewWrapper = styled.View`
  flex: 1;
  padding: 10px;
  background-color: ${({ theme }) => theme.background};
  max-width: 100%;
`;

const PlaceHolderViewTextWrapper = styled.View`
  flex: 2;
  justify-content: center;
`;

const PlaceHolderViewActionsWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PlaceHolderViewSecondaryButtonWrapper = styled(Pressable)`
  align-self: center;
  margin-top: 18px;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

const PlaceHolderViewButton = styled(Button)``;

const PlaceholderView = React.memo(_PlaceholderView, isEqual);
export default PlaceholderView;
