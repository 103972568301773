import React, { useContext } from "react";

import styled, { ThemeContext } from "styled-components/native";

import Icon, {
  IconIdentifier,
  isExternalIconIdentifier,
  ValidIconSize,
} from "@app/components/icon";
import { ActivityIndicator, StyleProp, ViewStyle } from "react-native";
import { QuestmateTheme } from "@app/themes/QuestmateTheme";

type ThemeType = keyof QuestmateTheme["button"];

interface IconProps {
  name: IconIdentifier;
  size?: ValidIconSize;
  loading?: boolean;
  themeType?: ThemeType;
  style?: StyleProp<ViewStyle>;
}

export const QKIcon: React.FC<IconProps> = (props) => {
  const theme = useContext(ThemeContext);
  const iconSize = props.size ?? 24;

  const wrapperSize = iconSize === 24 ? 32 : iconSize / 0.8;
  const themeType = props.themeType ?? "primary";

  const isExternalIconImage = isExternalIconIdentifier(props.name);

  return (
    <StyledIconContainer
      themeType={themeType}
      size={wrapperSize}
      style={props.style}
    >
      {props.loading ? (
        <ActivityIndicator color={theme.button[themeType].text} />
      ) : (
        <ColoredIcon
          icon={props.name}
          size={isExternalIconImage ? (wrapperSize as ValidIconSize) : iconSize}
          container="COLLAPSED"
          themeType={themeType}
        />
      )}
    </StyledIconContainer>
  );
};

const ColoredIcon = styled(Icon)<{ size?: number; themeType: ThemeType }>`
  color: ${({ theme, themeType }) => theme.button[themeType].text};
`;

const StyledIconContainer = styled.View<{ size: number; themeType: ThemeType }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ theme, themeType }) =>
    theme.button[themeType ?? "primary"].background};
  border-color: ${({ theme }) => theme.background};
  border-radius: ${({ size }) => `${size / 2}px`};
  align-items: center;
  justify-content: center;
  margin: 4px;
  overflow: hidden;
`;

export default QKIcon;
