import {
  createSideBarItemRowIcon,
  SideBarItem,
} from "@app/navigation/sideBar/items/SideBarItem";
import { Image } from "react-native";
import React, { useMemo } from "react";
import styled from "styled-components/native";
import { fetchMe } from "@app/util/client/requests/me";
import { useAppSelector } from "@app/store";
import { selectFields, selectLoggedInUser } from "@app/store/cache/users";
import { defaultAvatar } from "@app/components/questkit/UserList/StandardUserEntry";
import isEqual from "react-fast-compare";
import { useRequest } from "@app/util/client/requests";

const SettingsIcon = createSideBarItemRowIcon("gear");

export const SideBarSettingsItem: React.FC = () => {
  useRequest(fetchMe());
  const userData = useAppSelector((state) => {
    const user = selectLoggedInUser(state);
    return user
      ? selectFields(user, ["displayName", "avatarSmallUrl"])
      : undefined;
  }, isEqual);
  const avatarSmallUrl = userData?.avatarSmallUrl;
  const avatarImage = useMemo(
    () => (
      <AvatarImage
        source={{
          uri: avatarSmallUrl || defaultAvatar,
        }}
      />
    ),
    [avatarSmallUrl]
  );
  return (
    <SettingsWrapper>
      <SideBarItem
        label={userData?.displayName ?? "Loading ..."}
        screenName={"Settings"}
        disableActiveHighlight={true}
        componentLeft={avatarImage}
        componentRight={SettingsIcon}
      />
    </SettingsWrapper>
  );
};

const AvatarImage = styled(Image)`
  width: 24px;
  height: 24px;
  border-radius: 14px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.sideBar.text};
`;

const SettingsWrapper = styled.View`
  padding: 10px;
`;
