import * as Sentry from "sentry-expo";
import { ENV } from "@app/config/env";
import { Breadcrumb } from "@sentry/types/types/breadcrumb";

export const sentry = Sentry.Browser;

const logLevels = ENV.logLevels.sentry;

export const initSentry = (): void => {
  console.log("Initializing Sentry for Web");
  Sentry.init({
    dsn: "https://e74ef707a7144c9eace3e4f84941f194@o547469.ingest.sentry.io/5669714",
    enableInExpoDevelopment: true,
    environment: ENV.sentryEnvironment,
    debug: logLevels.sdk === "debug",
    tracesSampleRate: ENV.sentryEnvironment === "Production" ? 0.25 : 0,
    enableAutoSessionTracking: true,
    normalizeDepth: 10,

    integrations: [
      new sentry.BrowserTracing({
        // add a trace-id and parent-span-id headers to matching outgoing requests
        tracePropagationTargets: [
          /localhost/,
          /local\..*\.dev\.questmate\.com/,
          /api-testing\.questmate\.com/,
          /api\.questmate\.com/,
          /^\//,
        ],
      }),
    ],
    beforeBreadcrumb(breadcrumb, _hint) {
      // log breadcrumbs to console for e2e tests debugging
      if (
        logLevels.breadcrumbs === "debug" &&
        breadcrumb?.category !== "console"
      ) {
        debugLogBreadcrumb(breadcrumb);
      }
      return breadcrumb;
    },
    beforeSend(event, _eventHint) {
      try {
        const logData = {
          type: event?.type,
          event_id: event?.event_id,
          ...(logLevels.events === "debug"
            ? {
                message: event?.message,
                exception: event?.exception,
                extra: event?.extra,
              }
            : {}),
        };
        console.info(
          "Event captured in Sentry: ",
          logLevels.events === "debug"
            ? JSON.stringify(logData, null, 2)
            : JSON.stringify(logData)
        );
      } catch (_e) {
        // ignore errors, throwing errors from this method will cause the event to be lost
      }

      return event;
    },
  });
};

function debugLogBreadcrumb(breadcrumb: Breadcrumb) {
  if (breadcrumb?.type === "http" && breadcrumb?.category === "xhr") {
    const { method, status_code, url, ...rest } = breadcrumb?.data as {
      method: string;
      status_code: number;
      url: string;
    };
    console.log(
      `Breadcrumb: ${status_code} ${method} ${url}`,
      Object.keys(rest).length > 0 ? JSON.stringify(rest, null, 2) : ""
    );
  } else if (breadcrumb?.category === "navigation") {
    const { from, to } = breadcrumb?.data as {
      from?: string;
      to: string;
    };
    console.info(`Breadcrumb: Navigated from ${from} to ${to}`);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { timestamp, message, category, type, level, ...rest } = breadcrumb;
    const logger =
      level === "error"
        ? console.error
        : level === "warning"
        ? console.warn
        : console.info;

    const header = [type, category].filter((x) => x).join("|");
    logger(
      `Breadcrumb: ${header ? `[${header}] - ` : ""}${
        message ? ` ${message}` : ""
      }`,
      Object.keys(rest).length > 0 ? JSON.stringify(rest, null, 2) : ""
    );
  }
}

export function registerNavigationContainer(): void {
  // Do nothing. Needed for ReactNavigationInstrumentation
}
