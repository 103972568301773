import React, { useEffect, useRef } from "react";
import { HeaderBackButtonProps } from "@react-navigation/elements";
import { Animated, Platform } from "react-native";
import Icon from "@app/components/icon";
import { useIsDesktopWidth } from "@app/themes/Breakpoints";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { useSideBarContext } from "@app/navigation/sideBar/SideBarProvider";

export const OpenSideBarButton: React.FC<HeaderBackButtonProps> = () => {
  const { openDrawer } = useSideBarContext();

  const showButton = !useIsDesktopWidth();

  const fadeAnim = useRef(new Animated.Value(showButton ? 1 : 0)).current;
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: showButton ? 1 : 0,
      duration: 200,
      useNativeDriver: Platform.OS !== "web",
    }).start();
  }, [fadeAnim, showButton]);

  return (
    // We tried createAnimatedComponent(HeaderIcon) instead of wrapping
    // another view around it. This did not work with the native animation
    // driver on iOS, likely because TouchableOpacity has its own opacity
    // animation.
    <Animated.View style={{ opacity: fadeAnim }}>
      <HeaderIcon
        activeOpacity={0.8}
        disabled={!showButton}
        onPress={openDrawer}
      >
        <Icon icon="menu" size={32} />
      </HeaderIcon>
    </Animated.View>
  );
};
