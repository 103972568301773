import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import { Boundary } from "@app/components/screen/boundary";
import Loader from "@app/components/animated/loader";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { createLink } from "@app/util/link.utils";
import ListItem from "@app/components/questkit/listItem";
import { fetchAppList } from "@app/util/client/requests/apps";
import { useRequest } from "@app/util/client/requests";

export const AppsScreen: React.FC = () => {
  const { data, error, refresh, isValidating } = useRequest(fetchAppList());

  if (error) {
    return (
      <PlaceholderView
        text="Couldn't load your apps :("
        actions={[
          {
            type: "primary",
            text: "Retry",
            loading: isValidating,
            onPress: refresh,
          },
        ]}
      />
    );
  }

  if (!data) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <AppsScreenWrapper keyboardShouldPersistTaps="always">
      <SafeAreaView edges={["bottom"]}>
        <Boundary>
          {data
            .sort((a, b) => a.name.localeCompare(b.name, undefined, {}))
            .map((service) => {
              return (
                <ListItem
                  key={service.id}
                  text={service.name}
                  actionIcon="chevron-right"
                  onPress={createLink({
                    screen: "App",
                    params: { appId: service.id },
                  })}
                />
              );
            })}
        </Boundary>
      </SafeAreaView>
    </AppsScreenWrapper>
  );
};

const AppsScreenWrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: "center",
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const LoaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;
