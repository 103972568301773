import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import {
  WorkspaceDetails,
  WorkspaceListItem,
  WorkspaceMembershipListItem,
  WorkspaceMembershipRole,
} from "@questmate/openapi-spec";
import { selectLoggedInUserId, userLogout } from "@app/store/auth";
import { workspaceLoaded, workspacesLoaded } from "@app/store/cache/workspaces";
import { createDataMapper } from "@app/store/cache/DataMapper";

type WorkspaceMembership = {
  id?: string;
  userId: string;
  workspaceId: string;
  role?: WorkspaceMembershipRole;
};

const workspaceMembershipAdapter = createEntityAdapter<
  WorkspaceMembership,
  string
>({
  selectId: (workspaceMembership) =>
    `${workspaceMembership.userId}-${workspaceMembership.workspaceId}`,
});

export const { selectAll: selectAllWorkspaceMemberships } =
  workspaceMembershipAdapter.getSelectors<AppState>(
    (state) => state.cache.workspaceMemberships
  );
export const selectUsersWorkspaceIds = createSelector(
  [selectAllWorkspaceMemberships, (state) => selectLoggedInUserId(state)],
  (memberships, loggedInUserId) => {
    return memberships
      .filter(({ userId }) => userId === loggedInUserId)
      .map(({ workspaceId }) => workspaceId);
  }
);

export const selectWorkspaceMembershipsForWorkspace = createSelector(
  [selectAllWorkspaceMemberships, (_state, workspaceId) => workspaceId],
  (allMemberships, workspaceIdToMatch) => {
    return allMemberships.filter(
      ({ workspaceId }) => workspaceId === workspaceIdToMatch
    );
  }
);

const slice = createSlice({
  name: "cache/workspaceMemberships",
  initialState: workspaceMembershipAdapter.getInitialState(),
  reducers: {
    workspaceMemberLoaded: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        membership: WorkspaceMembershipListItem;
      }>
    ) => {
      workspaceMembershipAdapter.upsertOne(
        state,
        mapWorkspaceMembership({
          ...action.payload.membership,
          workspaceId: action.payload.workspaceId,
        })
      );
    },
    workspaceMembersAdded: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        memberships: WorkspaceMembershipListItem[];
      }>
    ) => {
      workspaceMembershipAdapter.upsertMany(
        state,
        action.payload.memberships
          .map((membership) => ({
            ...membership,
            workspaceId: action.payload.workspaceId,
          }))
          .map(mapWorkspaceMembership)
      );
    },
    workspaceMemberDeleted: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        membershipId: string;
      }>
    ) => {
      const membershipToDelete = Object.values(state.entities).find(
        (membership: WorkspaceMembership) => {
          return (
            membership.workspaceId === action.payload.workspaceId &&
            membership.id === action.payload.membershipId
          );
        }
      );
      if (membershipToDelete) {
        workspaceMembershipAdapter.removeOne(
          state,
          `${membershipToDelete.userId}-${membershipToDelete.workspaceId}`
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) =>
      workspaceMembershipAdapter.removeAll(state)
    );
    builder.addCase(
      workspacesLoaded,
      (
        state,
        action: PayloadAction<{
          userId: string;
          workspaces: WorkspaceListItem[];
        }>
      ) => {
        const { userId, workspaces } = action.payload;
        workspaceMembershipAdapter.upsertMany(
          state,
          workspaces
            .map((workspace) => ({
              workspaceId: workspace.id,
              user: { id: userId },
            }))
            .map(mapWorkspaceMembership)
        );
      }
    );
    builder.addCase(
      workspaceLoaded,
      (state, action: PayloadAction<WorkspaceDetails>) =>
        workspaceMembershipAdapter.upsertMany(
          state,
          action.payload.memberships
            .map((membership) => ({
              ...membership,
              workspaceId: action.payload.id,
            }))
            .map(mapWorkspaceMembership)
        )
    );
  },
});

const reducer = slice.reducer;
export default reducer;

export const {
  workspaceMemberLoaded,
  workspaceMembersAdded,
  workspaceMemberDeleted,
} = slice.actions;

type MinimalWorkspaceMembershipData = {
  user: { id: string };
  workspaceId: string;
};
type APIWorkspaceMembership =
  | (WorkspaceMembershipListItem & { workspaceId: string })
  | MinimalWorkspaceMembershipData;
const mapWorkspaceMembership = createDataMapper<
  APIWorkspaceMembership,
  WorkspaceMembership
>()(["id", "role", "workspaceId"], {
  user: ({ id }) => (id ? { userId: id } : {}),
});
