import React, { useEffect, useRef } from "react";
import { Animated, Easing } from "react-native";
import styled from "styled-components/native";
import Icon from "../icon";
import { OnLinkPress } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

interface ShowMoreButtonProps {
  onPress?: OnLinkPress | (() => void) | undefined;
  count: number;
  animation?: boolean;
  large?: boolean;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  onPress,
  count,
  animation,
  large,
}) => {
  const showInRoundSize = animation && count > 0;
  const slideAnim = useRef(
    new Animated.Value(showInRoundSize ? 95 : 0)
  ).current;
  const heightAnim = useRef(
    new Animated.Value(showInRoundSize ? 80 : 32)
  ).current;
  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      // do not animate on first render
    } else {
      if (showInRoundSize) {
        Animated.timing(slideAnim, {
          useNativeDriver: false,
          // @TODO: change back after we replaced stacked cards design to smaller height
          // toValue: large ? 73 : 95,
          toValue: large ? 95 : 95,
          duration: 800,
          easing: Easing.bounce,
          isInteraction: false,
        }).start();
        Animated.timing(heightAnim, {
          useNativeDriver: false,
          toValue: 80,
          duration: 500,
          isInteraction: false,
        }).start();
      } else {
        Animated.timing(slideAnim, {
          useNativeDriver: false,
          toValue: 0,
          duration: 800,
          easing: Easing.bounce,
          isInteraction: false,
        }).start();
        Animated.timing(heightAnim, {
          useNativeDriver: false,
          toValue: 32,
          duration: 500,
          isInteraction: false,
        }).start();
      }
    }
  }, [showInRoundSize, heightAnim, large, slideAnim]);

  return (
    <AnimatedPressableOpacity
      style={{
        height: heightAnim,
        transform: [{ translateY: slideAnim }],
      }}
      activeOpacity={0.8}
      onPress={onPress}
    >
      <StyledIcon icon="chevron-right" size={32} inverted />
    </AnimatedPressableOpacity>
  );
};

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.button.secondary.text};
`;

const AnimatedPressableOpacity = styled(
  Animated.createAnimatedComponent(PressableOpacity)
)`
  right: 20px;
  position: absolute;

  background-color: ${({ theme }) => theme.button.secondary.background};
  border-radius: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
`;

export default ShowMoreButton;
