import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  ReadonlyRefObject,
  useStateWithRef,
} from "@app/components/questkit/useStateWithRef";
import isEqual from "react-fast-compare";

interface UseSyncedStateOptions<S> {
  /**
   * Only called when the state is updated via the returned setter, not when the parent updates the state.
   */
  onLocalChange?: (newState: S) => unknown | void;

  /**
   * Only called when the parent updates with a different state than local.
   * Not called if change is ignored by `ignoreParentChange`.
   */
  onParentChange?: (newState: S) => unknown | void;

  /**
   * Called before a change from the parent updates the state.
   * @Return `true` to ignore this change and keep the current state.
   * @Return `false` for default behavior.
   */
  ignoreParentChange?: (newState: S) => boolean;
}

/**
 * Helps with syncing state changes between parent and child components.
 * In this context, the parent is providing the current state via the `currentParentState` parameter.
 * The local state is updated via the returned setter function.
 * @param currentParentState Ensure this is the most up-to-date state from the parent.
 * @param options
 *
 * @returns [syncedState, setStateFromLocal, syncedStateRef]
 */
export const useSyncedState = <S>(
  currentParentState: S,
  options?: UseSyncedStateOptions<S>
): [S, Dispatch<SetStateAction<S>>, ReadonlyRefObject<S>] => {
  const {
    onLocalChange: _onLocalChange,
    onParentChange,
    ignoreParentChange,
  } = options || {};

  const [syncedState, setSyncedState, syncedStateRef] =
    useStateWithRef(currentParentState);

  const lastParentChangeReceivedRef = useRef(currentParentState);
  useEffect(() => {
    if (
      !isEqual(currentParentState, lastParentChangeReceivedRef.current) &&
      !isEqual(currentParentState, syncedState)
    ) {
      const result = ignoreParentChange?.(currentParentState);
      const ignoreThisParentChange = result === true;
      if (!ignoreThisParentChange) {
        setSyncedState(currentParentState);
        onParentChange?.(currentParentState);
      }
    }
    lastParentChangeReceivedRef.current = currentParentState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParentState]);

  const onLocalChangeRef = useRef(_onLocalChange);
  onLocalChangeRef.current = _onLocalChange;
  const lastLocalChangeReportedRef = useRef(currentParentState);
  const onLocalChange = useCallback((newState: S) => {
    if (
      onLocalChangeRef.current &&
      !isEqual(newState, lastLocalChangeReportedRef.current)
    ) {
      lastLocalChangeReportedRef.current = newState;

      //TODO: Consider adding a warning if this function takes too long to avoid blocking the UI.
      //      Alternatively we could find a way to make this async.
      onLocalChangeRef.current(newState);
    }
  }, []);

  const setStateFromLocal: typeof setSyncedState = useCallback(
    (setStateAction) => {
      setSyncedState(setStateAction);
      onLocalChange(syncedStateRef.current);
    },
    [syncedStateRef, onLocalChange, setSyncedState]
  );

  return [syncedState, setStateFromLocal, syncedStateRef];
};
