import { Analytics } from "@app/analytics";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import React, { PropsWithChildren, useRef } from "react";
import { useTheme } from "styled-components/native";
import { registerNavigationContainer, sentry } from "@app/util/sentry";
import { QMStackParamList } from "@app/navigation/QMNavigator";
import linkingConfig from "@app/navigation/linkingConfig";
import createNavigationContainerRef from "@react-navigation/core/src/createNavigationContainerRef";
import { NavigationState } from "@react-navigation/routers/src/types";
import { NavigationContainerRef } from "@react-navigation/core";
import { ENV } from "@app/config/env";

export const navigationRef = createNavigationContainerRef<QMStackParamList>();

export const QMNavigationContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const routeNameRef = useRef<string>();
  const theme = useTheme();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: { ...DefaultTheme.colors, background: theme.background },
      }}
      ref={navigationRef as React.Ref<NavigationContainerRef<QMStackParamList>>}
      onUnhandledAction={(action) => {
        console.error("Unable to handle Navigation Action: ", action);
        console.info(
          "Current Navigation State: ",
          navigationRef.getRootState()
        );
        sentry.captureException(
          "Unable to handle Navigation Action (onUnhandledAction)",
          {
            extra: {
              action,
              rootState: navigationRef.getRootState(),
            },
          }
        );
      }}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()!.name;
        Analytics.trackEvent("Navigate To Screen", {
          previousScreenName: undefined,
          screenName: routeNameRef.current,
        });
        registerNavigationContainer(navigationRef);

        if (ENV.logLevels.navigation === "debug") {
          debugLogStateChanges();
          navigationRef.addListener("__unsafe_action__", (action) => {
            console.log(
              `\n----- Navigation Action Fired -----\n`,
              action?.data?.action,
              "\n-----------------------------------\n"
            );
          });
        }
      }}
      linking={linkingConfig}
      // documentTitle={{
      //   formatter: (options, route) =>
      //     `Questmate - ${options?.title ?? route?.name}`,
      // }}
      onStateChange={() => {
        if (ENV.logLevels.navigation === "debug") {
          debugLogStateChanges();
        }

        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()!.name;
        if (previousRouteName !== currentRouteName) {
          Analytics.trackEvent("Navigate To Screen", {
            previousScreenName: previousRouteName,
            screenName: currentRouteName,
          });
        }
        routeNameRef.current = currentRouteName;
      }}
    >
      {children}
    </NavigationContainer>
  );
};

function debugLogStateChanges() {
  console.debug(
    `\n----- Navigation State Change -----\n${formatRoutes(
      navigationRef.getRootState()
    )}\n\n** Root State **`,
    navigationRef.getRootState(),
    "\n-----------------------------------"
  );
}

const formatRoutes = (parentState: NavigationState) => {
  const currentRoute = navigationRef.getCurrentRoute();
  return parentState.routes
    .map((route, index) => {
      const isCurrentlyFocused =
        (route.key && route.key === currentRoute?.key) ||
        (currentRoute?.path && currentRoute?.path === route.path);
      const isTargettedByParentNavigator = parentState.index === index;

      let string = `${index}: ${route.key || `${route.name} (missing key)`}${
        isTargettedByParentNavigator
          ? isCurrentlyFocused
            ? ` <------------- Currently Focused`
            : ` <----- Active`
          : ""
      }`;

      if (route.state?.routes) {
        const subRoutes = formatRoutes(route.state as NavigationState);
        string += `\n   ${subRoutes.replaceAll("\n", "\n   ")}`;
      }
      return string;
    })
    .join("\n");
};
