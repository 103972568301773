import { apiRequest } from "@app/util/client";
import { useCallback, useMemo, useRef } from "react";
import {
  SuccessFieldResponse,
  UpdateQuestPrototypeFieldResponse,
} from "@app/quest/edit/saveQuestPrototype";
import { ItemRenderData } from "@app/types/itemRenderData";
import { EditLevel } from "@app/components/screen/quest/common/questView";
import { uuid } from "@app/util/uuid";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import _ from "lodash";
import {
  ItemDetail,
  ItemInfo,
  QuestPrototypeDetail,
  UpdateItemsRequest,
} from "@questmate/openapi-spec";
import {
  EditableQuestPrototypeDetails,
  questPrototypeLoaded,
} from "@app/store/cache/questPrototypes";
import { store } from "@app/store";
import { sentry } from "@app/util/sentry";
import { itemTypeViewDataMap } from "@app/components/modal/itemOptionsDialog";
import isEqual from "react-fast-compare";
import { OnItemChangeHandler } from "@app/util/client/hooks/useQuestInstance";
import type { ValidationError } from "@questmate/common";

const mapCachedItemToServerItem = (
  item: EditableQuestPrototypeDetails["itemsById"][string]
): UpdateItemsRequest["items"][number] => {
  return {
    id: item.id,
    name: item.name,
    type: item.type,
    defaults: item.defaults,
    customScript: item.customScript,
    isCompletionAction: item.isCompletionAction,
    required: item.required,
    infos: item.infos,
    position: item.position,
    referenceSlug: item.referenceSlug,
    ...(item.subquestId
      ? {
          subquest: {
            id: item.subquestId /* needed by API */,
          },
        }
      : {}),
  };
};

export const updateItems = async (
  questPrototypeId: string,
  { itemsById }: Pick<EditableQuestPrototypeDetails, "itemsById">
): Promise<UpdateQuestPrototypeFieldResponse> => {
  const data: UpdateItemsRequest = {
    items: Object.values(itemsById).map(mapCachedItemToServerItem),
  };
  return apiRequest<QuestPrototypeDetail>(
    "patch",
    `/quests/${questPrototypeId}/items`,
    data
  )
    .then((updatedQuestPrototype) => {
      store.dispatch(questPrototypeLoaded(updatedQuestPrototype));
      return {
        success: true,
        updatedQuestPrototype,
      } as SuccessFieldResponse;
    })
    .catch((e) => {
      console.error(`Failed to save items:`, e);
      sentry.captureException(e);
      // TODO: Ensure `e.errors.path` uses IDs not indices
      return {
        success: false,
        error: e,
      };
    });
};

interface UseItemsResult {
  items: ItemRenderData[];
  onItemAdded: (newItemPosition: number, itemData?: PartialItemData) => string;
  onItemChange: OnItemChangeHandler;
  onItemDelete: (prototypeId: string) => void;
  onItemReorder: (oldIndex: number, newIndex: number) => void;
  editLevel: EditLevel;
}

const createEmptyItemInfo = (itemPrototypeId: string) => {
  return {
    id: `${itemPrototypeId}:${uuid()}`,
    position: 0,
    icon: "info",
    text: "",
    link: "",
  };
};

function createItemViewModels(
  items: undefined | EditableQuestPrototypeDetails["itemsById"][string][],
  itemErrors: ValidationError[]
) {
  return (items || [])
    .map((item) => {
      const errorsForThisReward = itemErrors
        .filter((error) => error.path[0] === item.id)
        .map((error) => ({ ...error, path: error.path.slice(1) }));

      return {
        prototype: item,
        data: item.defaults,
        version: 0, // version is not yet used in edit mode
        errors: errorsForThisReward,
      };
    })
    .sort((a, b) => {
      if (a.prototype.position === b.prototype.position) {
        return a.prototype.id.localeCompare(b.prototype.id);
      }
      return a.prototype.position - b.prototype.position;
    });
}

function extractNonCompletionActionItems(
  itemsById: EditableQuestPrototypeDetails["itemsById"]
) {
  return Object.values(itemsById).filter((item) => !item.isCompletionAction);
}

export type PartialItemData = Partial<
  Pick<
    ItemDetail,
    "type" | "name" | "defaults" | "customScript" | "referenceSlug" | "required"
  >
>;

export const useItems = (): UseItemsResult => {
  const {
    addChange,
    useQuestPrototypeWithChanges,
    questPrototypeId,
    useScopedValidationErrors,
    setFieldTouched,
  } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const allRawItems = useQuestPrototypeWithChanges((questPrototype) =>
    extractNonCompletionActionItems(questPrototype.itemsById ?? {})
  );

  const createEmptyItem = useCallback(
    (position: number, itemData: PartialItemData) => {
      const initialConfig =
        itemTypeViewDataMap[itemData.type ?? "Checkbox"]?.initialConfig;
      const itemPrototypeId = [questPrototypeId, uuid()].join(":");
      return _.defaults(itemData, initialConfig, {
        id: itemPrototypeId,
        position,
        name: "",
        infos: [createEmptyItemInfo(itemPrototypeId)] as ItemInfo[],
        required: true,
        personalData: false,
        type: "Checkbox" as const,
        icon: "item",
        defaults: {},
        isCompletionAction: false,
        customScript: null,
        referenceSlug: "",
      });
    },
    [questPrototypeId]
  );

  // Item Event Handlers
  const onItemChange = useCallback(
    (item: ItemRenderData<ItemDetail>) => {
      setFieldTouched(`items[${item.prototype.id}]`, true);
      addChange((draft) => {
        draft.itemsById[item.prototype.id] = {
          ...item.prototype,
          defaults: item.data,
        };
      });
    },
    [addChange, setFieldTouched]
  );

  const onItemAdded = useCallback(
    (newItemPosition: number, itemData: PartialItemData = {}) => {
      Analytics.trackEvent("Add Item", { type: itemData.type });
      const newItem = createEmptyItem(newItemPosition, itemData);
      addChange((draft) => {
        const items = extractNonCompletionActionItems(draft.itemsById);
        ensureNoGapsInPositions(items);
        for (const item of items) {
          if (item.position >= newItemPosition) {
            item.position += 1;
          }
        }
        draft.itemsById[newItem.id] = newItem;
        draft.itemIds = Object.values(draft.itemsById)
          .sort(({ position: a }, { position: b }) => b - a)
          .map(({ id }) => id);
      });

      return newItem.id;
    },
    [addChange, createEmptyItem]
  );

  const onItemDelete = useCallback(
    (prototypeId: string) => {
      Analytics.trackEvent("Delete Item");

      addChange((draft) => {
        delete draft.itemsById[prototypeId];

        ensureNoGapsInPositions(
          extractNonCompletionActionItems(draft.itemsById)
        );
        draft.itemIds = Object.values(draft.itemsById)
          .sort(({ position: a }, { position: b }) => b - a)
          .map(({ id }) => id);
      });
    },
    [addChange]
  );

  const onItemReorder = useCallback(
    (oldPosition: number, newPosition: number) => {
      if (oldPosition === newPosition) {
        return;
      }
      Analytics.trackEvent("Reorder Item");
      addChange((draft) => {
        const items = extractNonCompletionActionItems(draft.itemsById);
        ensureNoGapsInPositions(items);
        const currentPositions = items.map(({ position }) => position);
        const newPositions = adjustPositions(
          currentPositions,
          oldPosition,
          newPosition
        );

        items.forEach((item, index) => {
          item.position = newPositions[index];
        });
      });
    },
    [addChange]
  );
  const itemErrors = useScopedValidationErrors(["items"]);
  const previousItemViewModelsRef = useRef<ItemRenderData[]>([]);
  const itemViewModels = useMemo<ItemRenderData[]>(() => {
    const previousItemViewModels = previousItemViewModelsRef.current;
    const updatedViewModels = createItemViewModels(allRawItems, itemErrors);
    previousItemViewModelsRef.current = updatedViewModels;

    if (updatedViewModels.length === previousItemViewModels.length) {
      let changed = false;
      const viewModels = updatedViewModels.map((updatedViewModel, index) => {
        const previousViewModel = previousItemViewModels[index]; // by id instead of index?
        if (!isEqual(updatedViewModel, previousViewModel)) {
          changed = true;
          return updatedViewModel;
        }
        return previousViewModel;
      });
      if (!changed) {
        previousItemViewModelsRef.current = previousItemViewModels;
        return previousItemViewModels;
      } else {
        return viewModels;
      }
    } else {
      return updatedViewModels;
    }
  }, [allRawItems, itemErrors]);

  return {
    items: itemViewModels,
    onItemAdded,
    onItemDelete,
    onItemReorder,
    onItemChange,
    editLevel: EditLevel.Editable,
  };
};

export function ensureNoGapsInPositions(items: { position: number }[]): void {
  [...items]
    .sort((a, b) => a.position - b.position)
    .forEach((item, index) => {
      item.position = index;
    });
}

export function adjustPositions(
  positions: number[],
  currentPosition: number,
  newPosition: number
): number[] {
  return positions.map((position) => {
    const needToIncrease =
      newPosition < currentPosition &&
      position >= newPosition &&
      position < currentPosition;
    const needToDecrease =
      newPosition > currentPosition &&
      position <= newPosition &&
      position > currentPosition;

    if (position === currentPosition) {
      return newPosition;
    } else if (needToIncrease) {
      return position + 1;
    } else if (needToDecrease) {
      return position - 1;
    } else {
      return position;
    }
  });
}
