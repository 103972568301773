import React, { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import Icon from "@app/components/icon";
import { Boundary } from "@app/components/screen/boundary";
import { Image, Platform } from "react-native";
import HeaderText from "@app/components/questkit/headerText";
import QKTextInput, {
  QKTextInputProps,
} from "@app/components/questkit/textInput";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import Loader from "@app/components/animated/loader";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import ListItem from "@app/components/questkit/listItem";
import Switch from "@app/components/questkit/switch";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import {
  isPushNotificationsEnabled,
  requestPermissions,
  unregisterPushToken,
} from "@app/util/ensurePushNotifications";
import { useLink } from "@app/util/link.utils";
import { logOut } from "@app/screens/LogoutScreen";
import { useRequest } from "@app/util/client/requests";
import { fetchMe, updateMe } from "@app/util/client/requests/me";

export const AccountScreen: React.FC = () => {
  const { data, error, refresh, isValidating } = useRequest(fetchMe());
  const [displayName, setDisplayName] = useState("");
  const [displayNameSaving, setDisplayNameSaving] = useState(false);
  const snackbarContext = useContext(SnackbarContext);
  const redirectToAccountDeletionRequestQuest = useLink(
    "https://app.questmate.com/p/a316ab94-f019-4c26-93ce-78f20a515951"
  );

  useEffect(() => {
    if (data) {
      setDisplayName(data.displayName);
    }
  }, [data]);

  const saveDisplayName = useCallback(
    (displayName: string) => {
      if (!displayName) {
        return setDisplayName(data?.displayName || "");
      }

      if (displayName === data?.displayName) {
        return;
      }

      setDisplayNameSaving(true);

      updateMe({
        displayName: displayName,
      })
        .then(() => refresh())
        .catch((error) => {
          console.log(error);
          snackbarContext.sendMessage(
            "Couldn't save user name. Please try again.",
            SnackbarSeverity.WARNING
          );
          setDisplayName(data?.displayName || "");
          setDisplayNameSaving(false);
        })
        .finally(() => {
          setDisplayName(displayName);
          setDisplayNameSaving(false);
        });
    },
    [data?.displayName, refresh, snackbarContext]
  );

  const onBlurHandler = useCallback(() => {
    saveDisplayName(displayName);
  }, [saveDisplayName, displayName]);

  if (error) {
    return (
      <>
        <PlaceholderView
          text="Couldn't load your user :("
          actions={[
            {
              type: "primary",
              text: "Retry",
              loading: isValidating,
              onPress: refresh,
            },
            {
              type: "secondary",
              text: "Log out",
              onPress: () =>
                logOut({
                  reason:
                    "Failed to load user. Clicked Log out on account screen.",
                }),
            },
          ]}
        />
      </>
    );
  }

  if (!data) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <AccountScreenWrapper keyboardShouldPersistTaps="always">
      <SafeAreaView edges={["bottom"]}>
        <AvatarContainer>
          {data.avatarLargeUrl ? (
            <Image
              source={{
                uri: data.avatarLargeUrl,
              }}
              style={{
                width: 138,
                height: 138,
                borderRadius: 69,
              }}
            />
          ) : (
            <AvatarPlaceholder icon="person" size={80} />
          )}
        </AvatarContainer>
        <Boundary>
          <DisplayName
            value={displayName}
            onChangeText={setDisplayName}
            onBlur={onBlurHandler}
            editable={!displayNameSaving}
          />
          <LoginIdentifier
            value={data.email_address || data.mobile_number || ""}
            leftIcon={data.email_address ? "mail" : "smartphone"}
          />
          <NotificationsSettings
            email_address={data.email_address}
            mobile_number={data.mobile_number}
            emailNotificationsEnabled={data.emailNotificationsEnabled}
            mobileNotificationsEnabled={data.mobileNotificationsEnabled}
          />
          <OptionsContainer>
            <ListItem
              text="Logout"
              icon="chevron-left"
              onPress={() => {
                logOut({
                  reason: "Click logout in account screen.",
                });
              }}
            />
            <ListItem
              text="Delete my account"
              icon="trash"
              onPress={redirectToAccountDeletionRequestQuest}
            />
          </OptionsContainer>
        </Boundary>
      </SafeAreaView>
    </AccountScreenWrapper>
  );
};

interface UsernameProps {
  value: string;
  onChangeText: (text: string) => void;
  onBlur: () => void;
  editable?: boolean;
}

const DisplayName = ({
  value = "",
  onChangeText,
  onBlur,
  editable,
}: UsernameProps) => {
  return (
    <StyledView>
      <HeaderText title="Display Name" />
      <QKTextInput
        placeholder="Type here"
        value={value}
        onChangeText={onChangeText}
        onBlur={onBlur}
        editable={editable}
      />
    </StyledView>
  );
};

interface LoginIdentifierProps {
  leftIcon?: QKTextInputProps["leftIcon"];
  value: string;
}

interface NotificationsProps {
  email_address: string | null;
  mobile_number: string | null;
  emailNotificationsEnabled: boolean;
  mobileNotificationsEnabled: boolean;
}

const NotificationsSettings: React.FC<NotificationsProps> = (data) => {
  const snackbarContext = useContext(SnackbarContext);

  const [pushNotificationsEnabled, setPushNotificationsEnabled] =
    useState(true);

  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
    data.emailNotificationsEnabled
  );
  const [mobileNotificationsEnabled, setMobileNotificationsEnabled] = useState(
    data.mobileNotificationsEnabled
  );

  const [savingEmail, setSavingEmail] = useState(false);
  const [savingMobile, setSavingMobile] = useState(false);
  const [savingPushNotifications, setSavingPushNotifications] = useState(false);

  useEffect(() => {
    (async () => {
      const { status } = await Notifications.getPermissionsAsync();
      if (status === "granted" && isPushNotificationsEnabled()) {
        setPushNotificationsEnabled(true);
      } else {
        setPushNotificationsEnabled(false);
      }
    })();
  }, []);

  const isPhysicalMobileDevice =
    Device.isDevice && (Platform.OS === "ios" || Platform.OS === "android");

  const toggleMobile = () => {
    if (savingMobile) {
      return;
    }
    setSavingMobile(true);
    setMobileNotificationsEnabled(!mobileNotificationsEnabled);
    updateMe({
      mobileNotificationsEnabled: !mobileNotificationsEnabled,
    })
      .catch((error) => {
        console.log(error);
        snackbarContext.sendMessage(
          "Couldn't save mobile notifications setting. Please try again.",
          SnackbarSeverity.WARNING
        );
        setMobileNotificationsEnabled(mobileNotificationsEnabled);
      })
      .finally(() => {
        setSavingMobile(false);
      });
  };

  const toggleEmail = () => {
    if (savingEmail) {
      return;
    }
    setSavingEmail(true);
    setEmailNotificationsEnabled(!emailNotificationsEnabled);
    updateMe({
      emailNotificationsEnabled: !emailNotificationsEnabled,
    })
      .catch((error) => {
        console.log(error);
        snackbarContext.sendMessage(
          "Couldn't save email notifications setting. Please try again.",
          SnackbarSeverity.WARNING
        );
        setEmailNotificationsEnabled(emailNotificationsEnabled);
      })
      .finally(() => {
        setSavingEmail(false);
      });
  };

  const togglePushNotifications = async (): Promise<void> => {
    if (savingPushNotifications) {
      return;
    }
    setSavingPushNotifications(true);
    setPushNotificationsEnabled(!pushNotificationsEnabled);
    if (pushNotificationsEnabled) {
      unregisterPushToken()
        .then(() => {
          setPushNotificationsEnabled(false);
          setSavingPushNotifications(false);
        })
        .catch((error) => {
          console.log(error);
          setPushNotificationsEnabled(pushNotificationsEnabled);
          setSavingPushNotifications(false);
        });
    } else {
      requestPermissions()
        .then((enabled) => {
          if (enabled) {
            setPushNotificationsEnabled(true);
          } else {
            setPushNotificationsEnabled(false);
          }
          setSavingPushNotifications(false);
        })
        .catch((error) => {
          console.log(error);
          setPushNotificationsEnabled(pushNotificationsEnabled);
          setSavingPushNotifications(false);
        });
    }
  };

  return (
    <StyledView>
      <HeaderText title="Notifications" />
      {isPhysicalMobileDevice && (
        <ListItem
          text="Push"
          icon="notification-unread"
          actionComponent={
            <Switch
              switchOn={pushNotificationsEnabled}
              onSwitch={togglePushNotifications}
            />
          }
        />
      )}
      {data.email_address && (
        <ListItem
          text="Mail"
          icon="mail"
          onPress={toggleEmail}
          actionComponent={
            <Switch
              switchOn={emailNotificationsEnabled}
              onSwitch={toggleEmail}
            />
          }
        />
      )}
      {data.mobile_number && (
        <ListItem
          text="SMS"
          icon="sms"
          onPress={toggleMobile}
          actionComponent={
            <Switch
              switchOn={mobileNotificationsEnabled}
              onSwitch={toggleMobile}
            />
          }
        />
      )}
    </StyledView>
  );
};

const LoginIdentifier = ({ value = "", leftIcon }: LoginIdentifierProps) => {
  return (
    <StyledView>
      <HeaderText title="Login identity" />
      <QKTextInput
        placeholder=""
        value={value}
        leftIcon={leftIcon}
        editable={false}
      />
    </StyledView>
  );
};

const OptionsContainer = styled.View`
  ${({ theme }) => {
    return `
    margin-top: 40px;
    border-left-color: ${theme.primary};
    border-left-width: 1px;
    `;
  }}
`;

const AvatarPlaceholder = styled(Icon)`
  color: ${({ theme }) => theme.background};
`;

const AccountScreenWrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 20,
  },
})`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;

const AvatarContainer = styled.View`
  background-color: ${({ theme }) => theme.primary};
  width: 140px;
  height: 140px;
  border-radius: 70px;
  align-self: center;
  margin-top: 25px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
`;

const StyledView = styled.View`
  margin-top: 40px;
`;

const LoaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;
