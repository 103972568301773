import {
  HostComponent,
  LayoutRectangle,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";
import React, { PropsWithChildren, useImperativeHandle, useRef } from "react";

export interface LayoutViewRef {
  getLastMeasureInWindowResult: () => LayoutRectangle | undefined;
  measureInWindow: () => Promise<LayoutRectangle>;
  measureLayout: (
    ancestorComponent: HostComponent<unknown> | number
  ) => Promise<LayoutRectangle>;
  getLastMeasureResult: () => OnMeasureResult | undefined;
}

interface LayoutViewProps extends PropsWithChildren {
  onMeasureInWindow?: (layoutInWindow: LayoutRectangle) => void;
  onMeasure?: (layoutInScreen: OnMeasureResult) => void;
  style?: StyleProp<ViewStyle>;
}
const LayoutView = React.forwardRef<LayoutViewRef, LayoutViewProps>(
  ({ children, style, onMeasureInWindow, onMeasure }, ref) => {
    const viewRef = useRef<View>(null);
    const measureInWindowResult = useRef<LayoutRectangle | undefined>();
    const measureResult = useRef<OnMeasureResult | undefined>();

    useImperativeHandle(
      ref,
      (): LayoutViewRef => {
        return {
          getLastMeasureInWindowResult: () => measureInWindowResult.current,
          getLastMeasureResult: () => measureResult.current,
          measureInWindow: () => {
            return new Promise((resolve, reject) => {
              if (viewRef.current) {
                viewRef.current.measureInWindow(
                  (x: number, y: number, width: number, height: number) => {
                    measureInWindowResult.current = { x, y, width, height };
                    resolve(measureInWindowResult.current);
                  }
                );
              } else {
                reject;
              }
            });
          },
          measureLayout: (ancestorComponent) => {
            return new Promise((resolve, reject) => {
              if (viewRef.current) {
                viewRef.current.measureLayout(
                  // todo: remove @ts-ignore when types can be equal
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ancestorComponent,
                  (x: number, y: number, width: number, height: number) => {
                    resolve({ x, y, width, height });
                  },
                  reject
                );
              } else {
                reject;
              }
            });
          },
        };
      },
      []
    );

    return (
      <View
        ref={viewRef}
        style={style}
        onLayout={() => {
          if (viewRef.current) {
            viewRef.current.measureInWindow((x, y, width, height) => {
              measureInWindowResult.current = { x, y, width, height };
              onMeasureInWindow?.(measureInWindowResult.current);
            });
            viewRef.current.measure((x, y, width, height, pageX, pageY) => {
              measureResult.current = {
                x,
                y,
                width,
                height,
                pageX,
                pageY,
              };
              onMeasure?.(measureResult.current);
            });
          }
        }}
      >
        {children}
      </View>
    );
  }
);
LayoutView.displayName = "LayoutView";

type OnMeasureResult = {
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
};

export default LayoutView;
