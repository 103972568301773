import * as countriesData from "country-codes-list";
import { type CountryData, CountryProperty } from "country-codes-list";
import parsePhoneNumber from "libphonenumber-js";

export type IdentifierType = "EMAIL_ADDRESS" | "PHONE_NUMBER" | "UNKNOWN";

export type PhoneParseResult = {
  type: "PHONE_NUMBER";
  isValid: boolean;

  value: string;
  inputValue: string; //raw value
  inputValueCleaned: string;
  inputValueMasked: string;

  detectedCountry: CountryData | null;

  fullNumber: string; // includes country code if detected or provided
  localNumber: string; // excludes country code if detected

  // fullNumberFormatted: string;

  format(): string;
};

export type EmailParseResult = {
  type: "EMAIL_ADDRESS";
  isValid: boolean;

  value: string;
  inputValue: string; //raw value
  inputValueCleaned: string;
  inputValueMasked: string;

  domain: string | null;

  format(): string;
};

type UnknownParseResult = {
  type: "UNKNOWN";
  isValid: false;

  value: string;
  inputValue: string; //raw value

  format(): string;
};

export type IdentifierParseResult =
  | PhoneParseResult
  | EmailParseResult
  | UnknownParseResult;
export const parseAsEmailOrPhone = (
  value: string,
  selectedCountry?: CountryData
): IdentifierParseResult => {
  const identifierType = determineIdentifierTypeForIncompleteRawInput(value);

  if (identifierType === "PHONE_NUMBER") {
    return parseAsPhoneNumber(value, selectedCountry);
  } else if (identifierType === "EMAIL_ADDRESS") {
    return parseAsEmailAddress(value);
  } else {
    return {
      type: "UNKNOWN",
      isValid: false,
      value,
      inputValue: value,
      format() {
        return this.inputValue;
      },
    };
  }
};

export const NullIdentifier = {
  type: "UNKNOWN",
  isValid: false,
  value: "",
  inputValue: "",
  inputValueMasked: "",
  format() {
    return "";
  },
} as UnknownParseResult;

const parseAsEmailAddress = (rawInputValue: string): EmailParseResult => {
  const cleanedEmail = rawInputValue.replace(/\s/g, "");

  const atSignIndex = cleanedEmail.indexOf("@");
  const valid =
    atSignIndex !== -1 &&
    atSignIndex === cleanedEmail.lastIndexOf("@") &&
    cleanedEmail.length > 4;

  return {
    type: "EMAIL_ADDRESS",
    inputValue: rawInputValue,
    value: cleanedEmail,
    inputValueCleaned: cleanedEmail,
    get inputValueMasked() {
      return this.inputValue
        .split("")
        .map((character: string) => {
          switch (character) {
            case "+":
            case "@":
            case ".":
            case "-":
            case "_":
              return character;
            case " ":
              return "◻️";
            default:
              return "●";
          }
        })
        .join("");
    },
    isValid: valid,
    domain: valid ? cleanedEmail.split("@")[1] : null,
    format() {
      return this.inputValueCleaned;
    },
  };
};

const countryCodeKey = "countryCode" as CountryProperty.countryCode;
const parseAsPhoneNumber = (
  rawInputValue: string,
  selectedCountry?: CountryData
): PhoneParseResult => {
  // remove all characters that are not a digit or a plus sign
  const cleanedInputValue = rawInputValue.replace(/[^0-9+]/g, "");

  let detectedCountry: CountryData | null = null;
  const inputIncludesCountryCode = cleanedInputValue.startsWith("+");
  if (inputIncludesCountryCode) {
    if (
      selectedCountry &&
      cleanedInputValue.startsWith(`+${selectedCountry.countryCallingCode}`)
    ) {
      detectedCountry = selectedCountry;
    } else {
      if (cleanedInputValue.startsWith("+1")) {
        // @TODO One day somebody will reach out and complain that a canadian number is being detected as an american one.
        // See https://github.com/LucianoGanga/country-codes-list/issues/17 for details
        detectedCountry = countriesData.findOne(countryCodeKey, "US");
      } else {
        detectedCountry =
          countriesData.all().find((country: CountryData) => {
            return cleanedInputValue.startsWith(
              `+${country.countryCallingCode}`
            );
          }) ?? null;
      }
    }
  }

  const localNumber =
    inputIncludesCountryCode && detectedCountry
      ? cleanedInputValue.replace(`+${detectedCountry.countryCallingCode}`, "")
      : cleanedInputValue;

  const fullNumber = inputIncludesCountryCode
    ? cleanedInputValue
    : selectedCountry
    ? `+${selectedCountry.countryCallingCode}${cleanedInputValue.replace(
        // remove leading zeros in local numbers when converting to international format
        /^0+/,
        ""
      )}`
    : cleanedInputValue;

  return {
    type: "PHONE_NUMBER",

    value: fullNumber,
    inputValue: rawInputValue,
    inputValueCleaned: cleanedInputValue,
    get inputValueMasked() {
      return this.inputValue
        .split("")
        .map((character: string) => {
          switch (character) {
            case " ":
              return "◻️";
            default:
              return /\d/.test(character) ? "#" : character;
          }
        })
        .join("");
    },

    detectedCountry,

    fullNumber,
    localNumber,
    isValid: localNumber.length >= 4, // todo: use libphonenumber to validate
    format() {
      const phoneNumber = parsePhoneNumber(this.fullNumber || "");
      if (phoneNumber) {
        return phoneNumber.formatInternational();
      } else {
        return this.fullNumber || "";
      }
    },
  };
};

const determineIdentifierTypeForIncompleteRawInput = (
  rawInputValue: string
): IdentifierType => {
  const cleanerInputValue = rawInputValue.replace(/\s/g, "");

  if (cleanerInputValue.length === 0) {
    return "UNKNOWN";
  }

  if (
    // starts with +
    cleanerInputValue.startsWith("+") ||
    // has at least one number
    (/\d/i.exec(cleanerInputValue) &&
      // does not have any letters or @ sign
      /^[^a-zA-Z@]+$/i.exec(cleanerInputValue))
  ) {
    return "PHONE_NUMBER";
  }

  return "EMAIL_ADDRESS";
};

export const parseMultipleIdentifiers = (
  value: string,
  selectedCountry?: CountryData
): IdentifierParseResult[] =>
  value
    .trim()
    .split(/[,;|]+/)
    .map((s) => s?.trim())
    .filter((s) => !!s)
    .flatMap((rawIdentifier) => {
      const spaceSeparatedFields = rawIdentifier
        .split(/\s+/)
        .filter((s) => !!s);
      if (spaceSeparatedFields.length === 1) {
        return [rawIdentifier];
      }
      const spaceSeparatedIdentifiers = [];
      for (let i = 0; i < spaceSeparatedFields.length; i++) {
        const identifierPart = spaceSeparatedFields[i];
        if (identifierPart.includes("@")) {
          spaceSeparatedIdentifiers.push(identifierPart);
          continue;
        }

        const numberRegex = /^[+()\-.0-9]+$/;
        if (
          identifierPart.charAt(0) === "+" &&
          numberRegex.test(identifierPart)
        ) {
          let fullNumber = identifierPart;
          for (let j = i + 1; j < spaceSeparatedFields.length; j++) {
            const nextPart = spaceSeparatedFields[j];
            const b = !numberRegex.test(nextPart);
            if (nextPart.charAt(0) === "+" || b) {
              break;
            }
            fullNumber += nextPart;
            i++;
          }
          spaceSeparatedIdentifiers.push(fullNumber);
        }
      }
      return spaceSeparatedIdentifiers;
    })
    .filter(
      (identifier, index, self) =>
        identifier?.trim() && self.indexOf(identifier) === index
    )
    .map((rawIdentifier) => {
      return parseAsEmailOrPhone(rawIdentifier, selectedCountry);
    });
