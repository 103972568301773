import React, {
  PropsWithChildren,
  useRef,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import LayoutView, { LayoutViewRef } from "@app/components/dragdrop/LayoutView";
import {
  QKScrollViewController,
  ScrollViewController,
} from "@app/components/questkit/ScrollView";

export type IDragDropListController = Pick<
  QKScrollViewController,
  "getScrollOffset" | "addScrollOffsetListener" | "removeScrollOffsetListener"
> & {
  setTouchPosition: (x: number, y: number) => void;
  setDragging: (dragging: boolean) => void;
};

export const DragDropListControllerContext =
  React.createContext<IDragDropListController>({
    getScrollOffset: () => 0,
    setTouchPosition: () => undefined,
    setDragging: () => undefined,
    addScrollOffsetListener: () => undefined,
    removeScrollOffsetListener: () => undefined,
  });

const TOP_OVERSCROLL = 100;
const BOTTOM_OVERSCROLL = 100;

export const DragDropListControllerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const scrollViewController = useContext(ScrollViewController);
  if (!scrollViewController) {
    throw new Error(
      "DragDropListControllerProvider must be a descendant of a QKScrollView"
    );
  }
  const {
    scrollBy,
    setScrollEnabled,
    getScrollOffset,
    getLayout,
    addScrollOffsetListener,
    removeScrollOffsetListener,
    getNodeHandle,
  } = scrollViewController;

  const layoutViewRef = useRef<LayoutViewRef>(null);

  const touchPositionRef = useRef({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const setTouchPosition = useCallback((x: number, y: number) => {
    touchPositionRef.current = { x, y };
  }, []);

  const setDragging = useCallback(
    (isDragging: boolean) => {
      setIsDragging(isDragging);
      if (isDragging) {
        setScrollEnabled(false);
      } else {
        setScrollEnabled(true);
      }
    },
    [setScrollEnabled]
  );

  const performAutoscroll = useCallback(() => {
    void layoutViewRef.current
      ?.measureLayout(getNodeHandle()!)
      .then((layout) => {
        if (!layout) {
          return;
        }

        const scrollOffsetToTopOfList = getScrollOffset()! + layout.y;
        const scrollOffsetToBottomOfList =
          getScrollOffset()! + layout.y + layout.height - getLayout()!.height;

        const { y: scrollViewY = 0, height: scrollViewHeight = 0 } =
          getLayout() ?? {};

        const diffToTop =
          touchPositionRef.current.y - Math.max(scrollViewY, layout.y);
        const diffToBottom =
          Math.min(scrollViewY + scrollViewHeight, layout.y + layout.height) -
          touchPositionRef.current.y;

        if (diffToBottom < 100) {
          if (
            scrollOffsetToBottomOfList + TOP_OVERSCROLL >
            getScrollOffset()!
          ) {
            scrollBy((100 - Math.max(0, diffToBottom)) * 0.1);
          }
        }

        if (diffToTop < 100) {
          if (
            scrollOffsetToTopOfList - BOTTOM_OVERSCROLL <
            getScrollOffset()!
          ) {
            scrollBy(-(100 - Math.max(0, diffToTop)) * 0.1);
          }
        }
      });
  }, [getLayout, getNodeHandle, getScrollOffset, scrollBy]);

  useEffect(() => {
    if (isDragging) {
      const interval = setInterval(performAutoscroll, 16);
      return () => clearInterval(interval);
    }
  }, [isDragging, performAutoscroll]);

  const context = useMemo(() => {
    return {
      setDragging,
      setTouchPosition,
      getScrollOffset,
      scrollBy,
      addScrollOffsetListener,
      removeScrollOffsetListener,
    };
  }, [
    addScrollOffsetListener,
    getScrollOffset,
    removeScrollOffsetListener,
    scrollBy,
    setDragging,
    setTouchPosition,
  ]);

  return (
    <DragDropListControllerContext.Provider value={context}>
      <LayoutView ref={layoutViewRef}>{children}</LayoutView>
    </DragDropListControllerContext.Provider>
  );
};
