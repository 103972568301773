import React from "react";
import Text from "@app/components/questkit/text";
import styled from "styled-components/native";

export const PlaceholderCompletionAction = () => {
  return (
    <PlaceholderWrapper>
      <Text size="medium" $warning={true}>
        It seems like you are using an old version of Questmate. Please update
        your app to access new content!
      </Text>
    </PlaceholderWrapper>
  );
};

const PlaceholderWrapper = styled.View`
  margin-horizontal: 12px;
`;
