import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { useFocusableRef } from "@app/util/focus";
import { LongAnswerComponentModel } from "@app/components/item/components/custom/types";
import TextInput from "@app/components/questkit/textInput";

interface CustomItemLongAnswerComponentProps {
  component: LongAnswerComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemLongAnswerComponent: React.FC<
  CustomItemLongAnswerComponentProps
> = ({ component, readOnly }) => {
  return (
    <LongAnswerContainer>
      <Text size="medium">{component.title || "Long Answer"}</Text>
      <TextInput
        onChangeText={component.onChange}
        value={component.value}
        placeholder={component.placeholder}
        editable={!(component.readOnly || readOnly)}
        multiline={true}
        autoCorrect={true}
        minNumberOfLines={4}
        blurOnSubmit={false}
      />
    </LongAnswerContainer>
  );
};

const LongAnswerContainer = styled.View`
  display: flex;
`;
