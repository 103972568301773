import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDelayedAction } from "@app/components/NavigationButton";
import { RestartSubmissionBehavior } from "@questmate/openapi-spec";
import Button from "@app/components/questkit/button";
import {
  QMStackParamList,
  useAppNavigation,
  useAppRoute,
} from "@app/navigation/QMNavigator";
import { fetchQuestInstance } from "@app/util/client/requests/quests";
import { useRequest } from "@app/util/client/requests";
import { useAppSelector } from "@app/store";
import { sentry } from "@app/util/sentry";

type RestartQuestRunButtonProps = {
  restartSubmissionBehavior: RestartSubmissionBehavior;
};
export const RestartQuestRunButton: React.FC<RestartQuestRunButtonProps> = ({
  restartSubmissionBehavior,
}) => {
  const navigation = useAppNavigation();
  const route = useAppRoute();
  const [waitingForNextInstanceId, setWaitingForNextInstanceId] =
    useState(false);
  const hasNavigatedRef = useRef(false);
  const navigate = useCallback(() => {
    if (!hasNavigatedRef.current && restartSubmissionBehavior.nextInstanceId) {
      cancelRef.current();
      hasNavigatedRef.current = true;
      if (route.name === "AdminQuestRun") {
        navigation.replace("AdminQuestRun", {
          templateId: (route.params as QMStackParamList["AdminQuestRun"])
            .templateId,
          questInstanceId: restartSubmissionBehavior.nextInstanceId,
        });
      } else {
        let publicId: string | undefined = undefined;
        if (route.name === "QuestInstance") {
          publicId = (route.params as QMStackParamList["QuestInstance"])
            .publicId;
        } else {
          sentry.captureMessage(
            "Unexpected route.name when restarting Quest run. Falling back to QuestInstance screen navigation.",
            {
              contexts: {
                "Extra Info": {
                  route,
                  restartSubmissionBehavior,
                },
              },
            }
          );
        }
        navigation.replace("QuestInstance", {
          id: restartSubmissionBehavior.nextInstanceId,
          ...(publicId ? { publicId } : {}),
        });
      }
    }
  }, [navigation, restartSubmissionBehavior, route]);

  const waitOrNavigate = useCallback(() => {
    if (!restartSubmissionBehavior.nextInstanceId) {
      setWaitingForNextInstanceId(true);
    } else {
      navigate();
    }
  }, [restartSubmissionBehavior, navigate]);
  const { remainingDelaySeconds, cancel } = useDelayedAction({
    action: waitOrNavigate,
    delayMs: !!restartSubmissionBehavior.delaySeconds
      ? restartSubmissionBehavior.delaySeconds * 1000
      : undefined,
    disabled: false,
  });
  const cancelRef = useRef(cancel);
  cancelRef.current = cancel;

  useEffect(() => {
    if (waitingForNextInstanceId && restartSubmissionBehavior.nextInstanceId) {
      navigate();
    }
  }, [waitingForNextInstanceId, restartSubmissionBehavior, navigate]);

  return (
    <>
      {!waitingForNextInstanceId && restartSubmissionBehavior.nextInstanceId ? (
        <QuestInstancePreloader
          questInstanceId={restartSubmissionBehavior.nextInstanceId}
        />
      ) : null}
      <Button
        onPress={waitOrNavigate}
        disabled={false}
        loading={waitingForNextInstanceId}
        success={
          waitingForNextInstanceId &&
          Boolean(restartSubmissionBehavior.nextInstanceId)
        }
        title={
          restartSubmissionBehavior.buttonLabel +
          (remainingDelaySeconds !== undefined
            ? ` (${remainingDelaySeconds})`
            : "")
        }
      />
    </>
  );
};

type QuestInstancePreloaderProps = {
  questInstanceId: string;
};
export const QuestInstancePreloader: React.FC<QuestInstancePreloaderProps> = ({
  questInstanceId,
}) => {
  const publicQuestSessionToken = useAppSelector(
    (state) => state.publicQuestAuth.sessions[questInstanceId] || undefined
  );
  useRequest(fetchQuestInstance(questInstanceId, publicQuestSessionToken), {
    revalidateOnFocus: false,
  });

  return null;
};
