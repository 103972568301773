import React from "react";
import { StyleSheet } from "react-native";
import Text from "@app/components/questkit/text";

import styled from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import { OnLinkPress } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { QuestmateTheme } from "@app/themes/QuestmateTheme";
import { useHover } from "@app/util/useHover";

export interface MetaInfoEntryData {
  icon?: IconIdentifier;
  text?: string;
}
interface QuestEntryProps {
  title: string;
  onPress: OnLinkPress | (() => void) | undefined;
  tileType: keyof QuestmateTheme["tile"];
  metaInfo?: MetaInfoEntryData[];
}

export const QuestEntry: React.FC<QuestEntryProps> = (props) => {
  const { title, metaInfo = [], tileType } = props;
  const { hoverProps } = useHover();

  return (
    <QuestEntryWrapper activeOpacity={0.8} {...props} {...hoverProps}>
      <QuestEntryText
        tileType={tileType}
        size={"medium"}
        numberOfLines={tileType === "assignment" ? 2 : 4}
      >
        {title}
      </QuestEntryText>
      <MetaInfo>
        {metaInfo.map((info, index) => (
          <MetaInfoEntry key={index} {...info} tileType={tileType} />
        ))}
      </MetaInfo>
    </QuestEntryWrapper>
  );
};

const MetaInfoEntryWrapper = styled.View<{
  tileTheme: keyof QuestmateTheme["tile"];
}>`
  flex-direction: row;
  height: 32px;
  background-color: ${({ theme, tileTheme }) =>
    theme.tile[tileTheme].infoPill.background};
  ${({ theme, tileTheme }) => {
    const borderColor = theme.tile[tileTheme].infoPill.border;
    return borderColor
      ? `border-color: ${borderColor}; border-width: ${StyleSheet.hairlineWidth}px;`
      : "";
  }};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-right: 8px;
`;

const MetaInfoIconContainer = styled.View`
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
`;

// TODO: change back height after we replaced stacked cards design to smaller height
// TODO: don't forget to make change in showMorebutton as well then

export const QuestEntryWrapper = styled(PressableOpacity)<{
  tileType: QuestEntryProps["tileType"];
  isHovered: boolean;
}>`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  margin-right: 11px;
  border-radius: 16px;
  background-color: ${({ theme, tileType, isHovered }) =>
    theme.tile[tileType].background[isHovered ? "hover" : "normal"]};
  transition: background-color 0.15s ease-in-out;
  ${({ theme, tileType }) => {
    const border = theme.tile[tileType].border;
    return border
      ? `
      border-width: ${StyleSheet.hairlineWidth}px;
      border-color: ${border};     
    `
      : "";
  }}

  padding: 10px;
  height: 162px;
  width: ${({ tileType }) => `${tileType === "assignment" ? 334 : 162}px`};
`;

type AddEntryProps = Pick<QuestEntryProps, "title" | "onPress">;

export const AddEntry: React.FC<AddEntryProps> = (props) => {
  const { hoverProps } = useHover();
  return (
    <QuestEntryWrapper
      activeOpacity={0.8}
      testID={`${props.title} button`}
      tileType="create"
      {...props}
      {...hoverProps}
    >
      <QuestEntryText size={"medium"} numberOfLines={3} tileType="create">
        {props.title}
      </QuestEntryText>
      <MetaInfoEntry icon="plus" tileType={"create"} />
    </QuestEntryWrapper>
  );
};

const QuestEntryText = styled(Text)<{ tileType: QuestEntryProps["tileType"] }>`
  color: ${({ theme, tileType }) => theme.tile[tileType].text};
`;

type MetaInfoEntryProps = {
  text?: string;
  icon?: IconIdentifier;
  tileType: QuestEntryProps["tileType"];
};
export const MetaInfoEntry: React.FC<MetaInfoEntryProps> = ({
  icon,
  text,
  tileType,
}) => {
  return (
    <MetaInfoEntryWrapper tileTheme={tileType}>
      {icon ? (
        <MetaInfoIconContainer>
          <MetaInfoIcon icon={icon} tileType={tileType} />
        </MetaInfoIconContainer>
      ) : null}
      {text ? (
        <MetaInfoText
          size={"small"}
          tileType={tileType}
          numberOfLines={2}
          addLeftMargin={icon === undefined}
        >
          {text}
        </MetaInfoText>
      ) : null}
    </MetaInfoEntryWrapper>
  );
};

// change max-height to 80px for two lines of meta info
// will need to adjust horizontal card to one line only
// or only do for small cards

const MetaInfoIcon = styled(Icon)<{ tileType: QuestEntryProps["tileType"] }>`
  color: ${({ theme, tileType }) => theme.tile[tileType].infoPill.text};
`;
const MetaInfo = styled.View`
  max-height: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
`;

const MetaInfoText = styled(Text)<{
  addLeftMargin: boolean;
  tileType: QuestEntryProps["tileType"];
}>`
  margin-right: 12px;
  margin-left: ${({ addLeftMargin }) => (addLeftMargin ? "12px" : "0px")};
  color: ${({ theme, tileType }) => theme.tile[tileType].infoPill.text};
`;
