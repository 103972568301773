import React, { useContext } from "react";
import { ThemeContext } from "styled-components/native";
import { Pulse } from "react-native-animated-spinkit";
import { useIsFocused } from "@react-navigation/native";

export const Loader: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const isFocused = useIsFocused();
  return <Pulse size={48} color={themeContext.primary} animating={isFocused} />;
};

export default Loader;
