import React, { useCallback, useMemo } from "react";
import styled from "styled-components/native";
import {
  DragDropList,
  type DraggableItemRenderer,
} from "@app/components/dragdrop";

import ItemInfoEntry from "./itemInfoEntry";
import * as apitypes from "@questmate/openapi-spec";
import { Analytics } from "@app/analytics";
import { DragDropListControllerProvider } from "../dragdrop/DragDropListControllerProvider";

type ItemInfoCommonProps = {
  itemPrototypeId: string;
  infos: apitypes.ItemInfo[];
  onItemInfosChange?: (index: number, itemInfo: apitypes.ItemInfo) => void;
  onItemInfosSubmit?: (index: number) => void;
  onItemInfosDelete?: (index: number) => void;
  onItemInfosReorder?: (oldIndex: number, newIndex: number) => void;
};
type ItemInfoEditProps = {
  editMode: true;
} & Required<ItemInfoCommonProps>;

type ItemInfoFillProps = {
  editMode: false;
} & ItemInfoCommonProps;

type ItemInfoProps = ItemInfoEditProps | ItemInfoFillProps;

const ItemInfo: React.FC<ItemInfoProps> = ({
  editMode,
  infos: _infos,
  onItemInfosSubmit,
  onItemInfosChange,
  onItemInfosDelete,
  onItemInfosReorder,
}) => {
  const infos = useMemo(() => {
    if (!editMode) {
      // filter out empty item infos
      return _infos.filter((info) => !!info.text);
    }
    return _infos;
  }, [_infos, editMode]);

  const onDragEnd = useCallback(
    (oldIndex: number, newIndex: number) =>
      onItemInfosReorder?.(oldIndex, newIndex),
    [onItemInfosReorder]
  );

  const infoCount = infos.length;
  const renderItem = useCallback<DraggableItemRenderer<apitypes.ItemInfo>>(
    ({ item: info, index, dragHandlers, isPlaceholder }) => {
      if (isPlaceholder) {
        return null;
      }
      return (
        <ItemInfoEntryWrap key={info.id} isLast={index + 1 === infoCount}>
          <ItemInfoEntry
            onItemInfoSubmit={() => onItemInfosSubmit!(index)}
            onItemInfoChange={(itemInfo) => {
              Analytics.trackEventDebounced(itemInfo.id, "Set Item Info Name");
              onItemInfosChange!(index, itemInfo);
            }}
            onItemInfoDelete={() => onItemInfosDelete!(index)}
            itemInfo={info}
            editMode={editMode}
            dragHandlers={dragHandlers}
          />
        </ItemInfoEntryWrap>
      );
    },
    [
      editMode,
      infoCount,
      onItemInfosChange,
      onItemInfosDelete,
      onItemInfosSubmit,
    ]
  );
  const getId = useCallback(({ id }: apitypes.ItemInfo) => id, []);
  return (
    <DragDropListControllerProvider>
      <DragDropList
        items={infos}
        getId={getId}
        onDragEnd={onDragEnd}
        renderItem={renderItem}
      />
    </DragDropListControllerProvider>
  );
};

const ItemInfoEntryWrap = styled.View<{ isLast: boolean }>`
  align-items: center;
  flex-direction: row;
  ${({ isLast }) => (isLast ? "" : `margin-bottom: 8px;`)}
`;

export default ItemInfo;
