import Loader from "@app/components/animated/loader";
import { ChooseEntry, SectionData } from "@app/components/modal/chooseEntry";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import React, { useCallback, useState } from "react";
import styled from "styled-components/native";
import { Analytics } from "@app/analytics";
import QKModal from "@app/components/modal";
import CreateQuest from "@app/components/modal/createTemplate";
import { createLink } from "@app/util/link.utils";
import {
  SimpleListItemData,
  useSimpleListItemRenderer,
} from "@app/components/questkit/SimpleListItem";
import { fetchLibraryTemplates } from "@app/util/client/requests/library";
import { useRequest } from "@app/util/client/requests";

export const TemplateNewScreen: React.FC = () => {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const {
    data: templateLibraryData = [],
    error: templateLibraryError,
    refresh: templateLibraryRefresh,
    isValidating: templateLibraryIsValidating,
  } = useRequest(fetchLibraryTemplates());

  const onTemplateSelectBuilder = useCallback(
    (templateId: string) => {
      const template = templateLibraryData.find(({ id }) => id === templateId)!;
      const templateName = template.currentFormPrototype!.name;
      const templateCategory = template.category || "Misc";

      return createLink(
        {
          screen: "PublicTemplatePreview",
          params: {
            questPrototypeId: template.currentFormPrototype!.id,
          },
        },
        {
          onPressHook: () =>
            Analytics.trackEvent("Select Public Template", {
              templateId,
              templateCategory,
              templateName,
            }),
        }
      );
    },
    [templateLibraryData]
  );

  const [createQuestSearchText, setChooseTemplateSearchText] = useState("");

  const templatesByCategories: {
    [key: string]: SectionData<SimpleListItemData>["data"][0][];
  } = {};

  for (const template of templateLibraryData.filter((templateData) => {
    const templateName = templateData.currentFormPrototype?.name.toLowerCase();
    const searchText = createQuestSearchText.toLowerCase();
    return templateName!.includes(searchText);
  })) {
    const data = {
      id: template.id,
      key: template.id,
      name: template.currentFormPrototype!.name,
    };
    const category = template.category || "Misc";
    if (templatesByCategories[category]) {
      templatesByCategories[category].push(data);
    } else {
      templatesByCategories[category] = [data];
    }
  }

  const sectionsData: SectionData<SimpleListItemData>[] = Object.keys(
    templatesByCategories
  )
    .sort((a, b) => a.localeCompare(b, undefined, {}))
    .map((category) => ({
      key: category,
      title: category,
      data: templatesByCategories[category].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {})
      ),
    }));

  const renderItem = useSimpleListItemRenderer(
    "standard",
    onTemplateSelectBuilder
  );

  if (!templateLibraryData) {
    if (templateLibraryError) {
      return (
        <StyledSafeAreaView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: templateLibraryIsValidating,
                onPress: templateLibraryRefresh,
              },
            ]}
            key={"placeholderViewError"}
          />
        </StyledSafeAreaView>
      );
    } else {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
  }

  return (
    <TemplatesScreenWrapper>
      <ChooseEntry
        renderItem={renderItem}
        onCreate={() => {
          setShowCreateTemplateModal(true);
        }}
        createTitle="Start Fresh!"
        search={{
          searchPlaceholder: "Search Templates",
          searchText: createQuestSearchText,
          setSearchText: setChooseTemplateSearchText,
        }}
        filters={{
          availableFilters: {},
          filterTypes: {},
          activeFilters: {},
          setActiveFilters: () => {
            return null;
          },
        }}
        sectionsData={sectionsData}
      />
      <QKModal
        showModal={showCreateTemplateModal}
        setShowModal={setShowCreateTemplateModal}
        title="New Quest"
      >
        <CreateQuest
          createButtonText="Create Quest"
          setShowModal={setShowCreateTemplateModal}
        />
      </QKModal>
    </TemplatesScreenWrapper>
  );
};

const TemplatesScreenWrapper = styled.KeyboardAvoidingView`
  flex: 1;
`;

const LoaderWrapper = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledSafeAreaView = styled.SafeAreaView`
  flex: 1;
`;
