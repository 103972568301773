import React, { useEffect, useMemo, useState } from "react";
import { View, ViewProps, ViewStyle } from "react-native";
import QKModal from "../modal";
import CalendarModal from "../modal/calendarModal";
import QKTextInput from "./textInput";
import Icon from "@app/components/icon";
import styled from "styled-components/native";
import { getTimeDisplayValue } from "@app/components/timeInput/TimeUtils";
import { format, parseISO } from "date-fns";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

interface DateTimeInputItemProps {
  onDone: (date: string | null) => void;
  readOnly?: boolean;
  title: string;
  time?: boolean;
  value: string;
  disableReset?: boolean;
  accessibilityLabelledBy?: ViewProps["accessibilityLabelledBy"];
  style?: ViewStyle;
}

const DateTimePicker: React.FC<DateTimeInputItemProps> = ({
  onDone,
  readOnly = false,
  title,
  time,
  value,
  disableReset = false,
  accessibilityLabelledBy,
  style,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateTime, setDateTime] = useState<Date | null>(null);

  useEffect(() => {
    if (!value) {
      setDateTime(null);
    } else {
      const date = parseISO(value);
      setDateTime(date);
    }
  }, [time, value]);

  const displayValue = useMemo(() => {
    if (!dateTime) {
      return "";
    }
    let formattedValue = format(dateTime, "yyyy/MM/dd");

    if (time) {
      formattedValue +=
        "   " +
        getTimeDisplayValue(dateTime.getHours(), dateTime.getMinutes()).join(
          ""
        );
    }
    return formattedValue;
  }, [dateTime, time]);

  return (
    <View style={style}>
      <PressableOpacity
        activeOpacity={0.8}
        disabled={readOnly}
        accessibilityHint="Select date and time"
        onPress={() => setShowCalendar(true)}
      >
        <DateTimeTextInput
          value={displayValue}
          editable={false}
          leftIcon={time ? "date-time" : "date"}
          accessibilityLabelledBy={accessibilityLabelledBy}
          rightIcon={() =>
            !readOnly && dateTime && !disableReset ? (
              <PressableOpacity
                accessibilityHint="Clear date"
                onPress={() => {
                  setDateTime(null);
                  onDone(null);
                  setShowCalendar(false);
                }}
              >
                <Icon icon="close" />
              </PressableOpacity>
            ) : null
          }
          placeholder={title}
        />
      </PressableOpacity>
      <QKModal
        showModal={showCalendar}
        setShowModal={setShowCalendar}
        title={title}
      >
        <CalendarModal
          onDone={(date) => {
            onDone(date);
            setShowCalendar(false);
          }}
          time={time}
          value={dateTime}
        />
      </QKModal>
    </View>
  );
};

const DateTimeTextInput = styled(QKTextInput)``;

export { DateTimePicker, DateTimeInputItemProps };
