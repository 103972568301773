import React, { useMemo, PropsWithChildren } from "react";
import { ItemContext } from "./ItemContext";

interface ItemContextProviderProps extends PropsWithChildren {
  editMode: boolean;
  prototypeId: string;
  position: number;
  isCompletionAction?: boolean;
}

export const ItemContextProvider: React.FC<ItemContextProviderProps> = ({
  editMode,
  prototypeId,
  position,
  isCompletionAction = false,
  children,
}) => {
  const context = useMemo(() => {
    return {
      editMode,
      prototypeId,
      position,
      isCompletionAction,
    } as const;
  }, [editMode, prototypeId, position, isCompletionAction]);
  return (
    <ItemContext.Provider value={context}>{children}</ItemContext.Provider>
  );
};
