import React, { useEffect, useRef } from "react";
import {
  Animated,
  Platform,
  Pressable,
  StyleProp,
  ViewProps,
  ViewStyle,
} from "react-native";
import styled, { useTheme } from "styled-components/native";
import Icon from "@app/components/icon";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";

interface SwitchProps {
  readOnly?: boolean;
  style?: StyleProp<ViewStyle>;
  switchOn: boolean;
  onSwitch: () => void;
  testID?: string;
  locked?: boolean;
  loading?: boolean;
  switchStyle?: "SYSTEM" | "USER";
  accessibilityLabelledBy?: ViewProps["accessibilityLabelledBy"];
}

const Switch: React.FC<SwitchProps> = ({
  readOnly = false,
  style,
  switchOn,
  onSwitch,
  testID,
  locked = false,
  loading = false,
  switchStyle = "SYSTEM",
  accessibilityLabelledBy,
}) => {
  const theme = useTheme();

  const targetAnimationValue = switchOn ? 1 : 0;
  const switchAnim = useRef(new Animated.Value(targetAnimationValue)).current;

  useEffect(() => {
    Animated.timing(switchAnim, {
      toValue: targetAnimationValue,
      duration: 200,
      useNativeDriver: Platform.OS !== "web",
    }).start();
  }, [switchAnim, targetAnimationValue]);

  return (
    <Pressable
      onPress={onSwitch}
      disabled={readOnly}
      testID={testID}
      accessibilityRole={"switch"}
      accessibilityState={{ checked: switchOn }}
      accessibilityLabelledBy={accessibilityLabelledBy}
    >
      <SwitchContainer
        testID={`${testID} ${switchOn ? "enabled" : "disabled"}`}
        switchStyle={switchStyle}
        style={[
          {
            backgroundColor: switchAnim.interpolate({
              inputRange: [0, 1],
              outputRange:
                switchStyle === "SYSTEM"
                  ? [theme.interaction.neutralLight, theme.interaction.primary]
                  : [theme.background, theme.interaction.primary],
            }),
            borderColor: switchAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [
                theme.interaction.neutralStrong,
                theme.interaction.primary,
              ],
            }),
          },
          style,
        ]}
      >
        <SwitchButton
          switchStyle={switchStyle}
          style={{
            transform: [
              {
                translateX: switchAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: switchStyle === "SYSTEM" ? [4, 22] : [-1, 21],
                }),
              },
            ],
            ...(switchStyle === "SYSTEM"
              ? {
                  backgroundColor: switchAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      theme.interaction.neutralStrong,
                      theme.interaction.secondary,
                    ],
                  }),
                }
              : {
                  borderColor: switchAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [theme.interaction.primary, theme.background],
                  }),
                }),
          }}
        >
          {locked && (
            <SwitchLockIcon icon="lock" size={16} switchOn={switchOn} />
          )}
          {loading && (
            <QKActivityIndicator
              testID={`${testID} loading`}
              size={30}
              color={
                switchOn
                  ? theme.interaction.neutralStrong
                  : theme.interaction.neutralLight
              }
            />
          )}
        </SwitchButton>
      </SwitchContainer>
    </Pressable>
  );
};

const SwitchLockIcon = styled(Icon)<{ switchOn: boolean }>`
  color: ${({ theme, switchOn }) =>
    switchOn ? theme.primary : theme.background};
`;
const SwitchContainer = styled(Animated.View)<{
  switchStyle: SwitchProps["switchStyle"];
}>`
  width: ${({ switchStyle }) => (switchStyle === "SYSTEM" ? 52 : 54)}px;
  height: 32px;
  border-radius: 27px;
  border-width: 1px;
  justify-content: center;
`;

const SwitchButton = styled(Animated.View)<{
  switchStyle: SwitchProps["switchStyle"];
}>`
  width: ${({ switchStyle }) => (switchStyle === "SYSTEM" ? 24 : 32)}px;
  height: ${({ switchStyle }) => (switchStyle === "SYSTEM" ? 24 : 32)}px;
  border-radius: 16px;
  ${({ theme, switchStyle }) =>
    switchStyle === "SYSTEM"
      ? ""
      : `
    border-width: 1px;
    background-color: ${theme.interaction.primary};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Switch;
