import React, { useCallback, useContext } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { type CopyTextComponentModel } from "@app/components/item/components/custom/types";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import QKTextInput from "@app/components/questkit/textInput";
import * as Clipboard from "expo-clipboard";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { sentry } from "@app/util/sentry";

interface CustomItemCopyTextComponentProps {
  component: CopyTextComponentModel;
}

export const CustomItemCopyTextComponent: React.FC<
  CustomItemCopyTextComponentProps
> = ({ component }) => {
  const snackbar = useContext(SnackbarContext);
  const content = component.content;
  const onCopy = useCallback(() => {
    Clipboard.setStringAsync(content)
      .then(() => {
        snackbar.sendMessage("Copied to clipboard");
      })
      .catch((e) => {
        snackbar.sendMessage(
          "Failed to copy to clipboard",
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            message:
              "Failed to copy to Clipboard (from custom item CopyText component)",
            content: content,
          },
        });
      });
  }, [content, snackbar]);

  return (
    <CopyTextContainer>
      {component.title && (
        <TitleText size="medium">{component.title}</TitleText>
      )}
      <PressableOpacity activeOpacity={0.8} onPress={onCopy}>
        <QKTextInput
          value={content}
          editable={false}
          numberOfLines={1}
          // leftIcon={NO_ICON}
          leftIcon="duplicate"
        />
      </PressableOpacity>
    </CopyTextContainer>
  );
};

const CopyTextContainer = styled.View`
  display: flex;
`;

const TitleText = styled(Text)`
  margin-bottom: 8px;
`;
