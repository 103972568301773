import axios, { AxiosResponse } from "axios";
import * as apiTypes from "@questmate/openapi-spec";
import { sentry } from "@app/util/sentry";
import { IdentifierParseResult } from "@app/screens/login/IdentifierParser";
import { cleanAxiosError } from "@questmate/common";
import { getRouteFromPath } from "@app/navigation/linkingConfig";
import { ENV } from "@app/config/env";

const { apiBaseUrl } = ENV;

type LoginSuccess = {
  success: true;
  data: apiTypes.Login.LoginCreate.ResponseBody;
};

type LoginFailure = {
  success: false;
};

export type LoginToQuestmateParams = {
  accessToken: string;
  postLoginTargetUrl?: string | undefined;
  debugData?: Record<string, unknown>;
};

export const login = async ({
  accessToken,
  postLoginTargetUrl,
  debugData,
}: LoginToQuestmateParams): Promise<LoginSuccess | LoginFailure> => {
  const postLoginTargetRoute = getRouteFromPath(postLoginTargetUrl);
  return axios
    .post(`${apiBaseUrl}/login`, {
      accessToken,
      postLoginTargetRoute: postLoginTargetRoute?.name
        ? {
            screen: postLoginTargetRoute.name,
            params: postLoginTargetRoute.params,
            path: postLoginTargetRoute.path,
          }
        : undefined,
    })
    .then(
      (
        loginResponse: AxiosResponse<apiTypes.Login.LoginCreate.ResponseBody>
      ) => {
        return {
          success: true,
          data: loginResponse.data,
        };
      }
    )
    .catch((error) => {
      console.error("Unexpected error occurred when trying to log in!", error);
      sentry.captureException(new Error("Login failed!"), {
        extra: {
          originalError: cleanAxiosError(error),
          ...debugData,
        },
      });

      return {
        success: false,
      };
    });
};

export const getAuth0Connection = async (
  identifier: IdentifierParseResult
): Promise<Result<{ connection: string }>> => {
  const params = new URLSearchParams({
    identifier: identifier.value,
  });
  return axios
    .get(`${apiBaseUrl}/login/connection?${params.toString()}`)
    .then(
      (response: AxiosResponse<apiTypes.Login.ConnectionList.ResponseBody>) => {
        return {
          success: true as const,
          data: { connection: response.data.connection },
        };
      }
    )
    .catch((error) => {
      console.error(
        "Unexpected error occurred when trying to retrieve the login connection!",
        error
      );
      sentry.captureException(
        new Error("Retrieving Login Connection Failed!"),
        {
          extra: {
            originalError: cleanAxiosError(error),
          },
        }
      );

      return {
        success: false,
      };
    });
};

export const linkAuth0Accounts = async (
  primaryAccountAccessToken: string,
  secondaryAccountAccessToken: string
): Promise<Result<undefined>> => {
  return axios
    .post(`${apiBaseUrl}/login/link`, {
      primaryAccountAccessToken,
      secondaryAccountAccessToken,
    })
    .then(() => {
      return {
        success: true,
        data: undefined,
      };
    })
    .catch((error) => {
      console.error(
        "Unexpected error occurred when trying to link accounts!",
        error
      );
      sentry.captureException(new Error("Account Linking failed!"), {
        extra: {
          originalError: cleanAxiosError(error),
        },
      });
      let errorCode = "UNKNOWN";
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.message?.includes(
            "Emails must match for SAML account linking."
          )
        ) {
          errorCode = "EMAILS_MUST_MATCH";
        }
      }

      return {
        success: false,
        error,
        errorCode,
      };
    });
};

type SuccessResult<T> = {
  success: true;
  data: T;
};

type FailureResult = {
  success: false;
  error?: unknown;
  errorMessage?: string;
  errorCode?: string;
};
export type Result<T> = SuccessResult<T> | FailureResult;
