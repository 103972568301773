import { AddEntry } from "@app/components/screen/home/questSelector/entry";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";

import {
  SectionList,
  SectionListData,
  SectionListRenderItemInfo,
  StyleProp,
  TextInput as RNTextInput,
  View,
  ViewStyle,
} from "react-native";
import SearchBar from "@app/components/questkit/SearchBar";
import { Filters, FiltersProps } from "@app/components/filters";
import { TabBar, TabBarItem } from "@app/components/questkit/TabBar";
import { MultiSwitch } from "@app/components/MultiSwitch";
import _ from "lodash";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";
import { colors } from "@app/themes/Colors";
import isEqual from "react-fast-compare";
import { RequireAtLeastOne } from "@app/types";
import { TouchBoundary } from "@app/util/TouchBoundary";

type BaseDataItem =
  | string
  | RequireAtLeastOne<{ key: string; id: string }, "id" | "key">;

export interface SectionData<T extends BaseDataItem> {
  key: string; // Used by SectionList for the React key
  type?: "entries" | "searchbar" | "filter" | "topEntries";
  title?: string; // Used by SectionList for the React key if no key provided
  data: T[];
}

interface SearchConfig {
  setSearchText: Dispatch<SetStateAction<string>>;
  searchPlaceholder: string;
  searchText?: string;
}

interface TabsConfig {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  availableTabs: { name: string; id: string }[];
}

export interface FilterConfig {
  filterTypes: FiltersProps["filterTypes"];
  availableFilters: FiltersProps["filters"];
  activeFilters: FiltersProps["activeFilters"];
  setActiveFilters: FiltersProps["setActiveFilters"];
}

export interface ChooseEntryProps<T extends BaseDataItem> {
  renderItem: (info: SectionListRenderItemInfo<T>) => React.ReactElement | null;
  isLoading?: boolean;
  onCreate?: () => void;
  createTitle?: string;
  tabs?: TabsConfig;
  tabStyle?: "DEFAULT" | "MULTI_SWITCH";
  filters?: FilterConfig;
  search?: SearchConfig;
  sectionsData: SectionData<T>[];
  stickySectionHeadersEnabled?: boolean;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  placeholder?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  refreshData?: () => Promise<unknown>;
}

const _ChooseEntry = <T extends BaseDataItem>({
  onCreate,
  createTitle,
  search,
  filters,
  tabs,
  tabStyle = "DEFAULT",
  sectionsData,
  header,
  footer,
  placeholder,
  style = {},
  renderItem,
  isLoading = false,
  stickySectionHeadersEnabled = false,
  refreshData,
  contentContainerStyle: _contentContainerStyle = {},
}: ChooseEntryProps<T>) => {
  const headerWithSearchBar = useMemo(
    () => (
      <>
        {header}
        {search ? (
          <SearchWithTabsAndFilters
            search={search}
            isLoading={isLoading}
            filters={filters}
            tabs={tabs}
            tabStyle={tabStyle}
          />
        ) : null}
      </>
    ),
    [header, search, isLoading, filters, tabs, tabStyle]
  );

  const sections = useMemo(() => {
    if (!!createTitle && !!onCreate) {
      return [
        {
          key: "Top Entries",
          type: "topEntries",
          data: [],
        },
        ...sectionsData,
      ];
    }
    return sectionsData;
  }, [createTitle, onCreate, sectionsData]);

  const onStartShouldSetResponder = useCallback(() => true, []);
  const stickyHeaderIndices = useMemo(() => [0], []);
  const contentContainerStyle = useIsEqualMemo(_contentContainerStyle);
  const renderSectionHeader = useCallback(
    (info: { section: SectionListData<T> }) => {
      const { type, title } = info.section;
      if (type === "topEntries") {
        return (
          <>
            {title && <SectionText size="small">{title}</SectionText>}
            {onCreate && createTitle ? (
              <TopEntries>
                {onCreate && (
                  <AddEntry
                    title={createTitle ?? "Create"}
                    onPress={onCreate}
                  />
                )}
              </TopEntries>
            ) : null}
          </>
        );
      } else if (title) {
        const sectionTitle = <SectionText size="small">{title}</SectionText>;
        if (stickySectionHeadersEnabled) {
          return (
            <StickySectionHeaderBackground>
              {sectionTitle}
            </StickySectionHeaderBackground>
          );
        } else {
          return sectionTitle;
        }
      } else {
        return null;
      }
    },
    [createTitle, onCreate, stickySectionHeadersEnabled]
  );
  const renderSectionFooter = useCallback(
    (info: { section: SectionListData<T> }) => {
      const section = info.section;
      return section.type === undefined ||
        ["entries", "topEntries"].includes(section.type) ? (
        <View style={{ height: 20 }} />
      ) : null;
    },
    []
  );

  const [refreshRequested, setRefreshRequested] = useState(false);
  const onRefresh = useMemo(
    () =>
      !refreshData
        ? undefined
        : async () => {
            setRefreshRequested(true);
            await refreshData();
            setRefreshRequested(false);
          },
    [refreshData]
  );

  const keyExtractor = useCallback((item: T, index: number) => {
    return (
      (typeof item === "string"
        ? item
        : (item as { key: string })?.key || (item as { id: string })?.id) ||
      index.toString()
    );
  }, []);

  return (
    <StyledSectionList
      style={style}
      sections={sections}
      refreshing={refreshRequested}
      onRefresh={onRefresh}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      initialNumToRender={20}
      ListHeaderComponent={headerWithSearchBar}
      ListFooterComponent={footer}
      ListEmptyComponent={placeholder}
      ItemSeparatorComponent={Spacer}
      stickySectionHeadersEnabled={stickySectionHeadersEnabled}
      stickyHeaderIndices={stickyHeaderIndices}
      contentContainerStyle={[contentContainerStyle]}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      keyboardShouldPersistTaps={"handled"}
      showsVerticalScrollIndicator={false}
      onStartShouldSetResponder={onStartShouldSetResponder}
    />
  );
};
export const ChooseEntry = React.memo(_ChooseEntry, isEqual);
ChooseEntry.displayName = "ChooseEntry";

export const SearchWithTabsAndFilters: React.FC<{
  tabs?: TabsConfig;
  tabStyle?: "DEFAULT" | "MULTI_SWITCH";
  filters?: FilterConfig;
  search: SearchConfig;
  isLoading: boolean;
  style?: StyleProp<ViewStyle>;
}> = ({
  tabStyle = "DEFAULT",
  tabs: _tabs,
  search: _search,
  filters: _filters,
  isLoading,
  style,
}) => {
  const searchbarRef = useRef<RNTextInput>(null);
  const [searchBarFocused, setSearchBarFocused] = useState(false);
  const [filtersFocused, setFiltersFocused] = useState(false);

  const showFilters = searchBarFocused || filtersFocused;

  const tabs = useIsEqualMemo(_tabs);
  const filters = useIsEqualMemo(_filters);
  const search = useIsEqualMemo(_search);

  const tabView = useMemo(() => {
    if (!tabs) {
      return null;
    }

    if (tabStyle === "DEFAULT") {
      return (
        <StyledTabBar>
          {tabs.availableTabs.map((tab) => {
            return (
              <TabBarItem
                key={tab.id}
                title={tab.name}
                active={tab.id === tabs.activeTab}
                onPress={() => tabs.setActiveTab(tab.id)}
              />
            );
          })}
        </StyledTabBar>
      );
    } else if (tabStyle === "MULTI_SWITCH") {
      return (
        <MultiSwitch
          selectedOption={
            tabs.availableTabs.find(({ id }) => id === tabs.activeTab)!.name
          }
          options={tabs.availableTabs.map(({ name }) => name)}
          onChange={(tabName) => {
            const newTabId = tabs.availableTabs.find(
              ({ name }) => name === tabName
            )!.id;
            tabs.setActiveTab(newTabId);
          }}
        />
      );
    } else {
      return null;
    }
  }, [tabStyle, tabs]);

  const blurSearchField = useCallback(() => searchbarRef?.current?.blur(), []);
  const onFocus = useCallback(() => setSearchBarFocused(true), []);

  const onChangeText = useMemo(() => {
    return _.throttle(search.setSearchText, 200, {
      leading: true,
      trailing: true,
    });
  }, [search.setSearchText]);
  const onBlur = useCallback(() => {
    onChangeText.flush();
    setTimeout(() => {
      setSearchBarFocused(false);
    }, 50);
  }, [onChangeText]);

  const rightIcon = useCallback(() => {
    return isLoading ? loadingIndicator : null;
  }, [isLoading]);

  return (
    <TouchBoundary style={style} onTouchOutside={blurSearchField}>
      {tabView}
      {search && (
        <SearchBarWrapper>
          <SearchBar
            ref={searchbarRef}
            placeholder={search.searchPlaceholder}
            value={search.searchText}
            onChangeText={onChangeText}
            onFocus={onFocus}
            onBlur={onBlur}
            rightIcon={rightIcon}
            accessibilityRole={"search"}
          />
        </SearchBarWrapper>
      )}
      {filters && (
        <FiltersWrapper>
          <Filters
            filters={filters.availableFilters}
            filterTypes={filters.filterTypes}
            activeFilters={filters.activeFilters}
            setActiveFilters={filters.setActiveFilters}
            setFiltersFocused={setFiltersFocused}
            hideFilterList={
              !showFilters ||
              (!!search.searchText && search.searchText.length > 0)
            }
            onPressFilter={blurSearchField}
          />
        </FiltersWrapper>
      )}
    </TouchBoundary>
  );
};

const SearchBarWrapper = styled.View`
  width: 100%;
`;
const FiltersWrapper = styled.View`
  padding-top: 20px;
`;

const StickySectionHeaderBackground = styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.background};
`;
export const SectionText = styled(Text)`
  padding-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledSectionList = styled(SectionList).attrs(
  ({ contentContainerStyle }) => ({
    contentContainerStyle: [
      {
        paddingBottom: 10,
        paddingHorizontal: 20,
        flexGrow: 1,
        maxWidth: 1080,
        width: "100%",
        alignSelf: "center",
      },
      contentContainerStyle,
    ],
  })
)`
  background-color: ${({ theme }) => theme.background};
`;

const StyledQKActivityIndicator = styled(QKActivityIndicator)`
  margin-right: 12px;
`;
const loadingIndicator = (
  <StyledQKActivityIndicator size={24} color={colors.neutral300} />
);

const TopEntries = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Spacer = styled.View`
  height: 20px;
`;

const StyledTabBar = styled(TabBar)`
  margin-top: 5px;
`;
