import React, { useContext } from "react";
import { Image, View, ViewStyle } from "react-native";
import { Text } from "@app/components/questkit/text";

import { Icon } from "@app/components/questkit";
import dateFormat from "dateformat";
import styled, { ThemeContext } from "styled-components/native";

interface QuestInfoProps {
  ownerName?: string;
  ownerAvatarUrl?: string | null;
  dueAt: string | null;
  style?: ViewStyle;
}

export const QuestInfo: React.FC<QuestInfoProps> = ({
  ownerName,
  ownerAvatarUrl,
  dueAt,
}) => {
  const theme = useContext(ThemeContext);
  const overdue = !!dueAt ? new Date(dueAt) <= new Date() : false;
  return (
    <View style={{ alignItems: "center" }}>
      {ownerName ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {ownerAvatarUrl ? (
            <AvatarContainer>
              <Image
                source={{
                  uri: ownerAvatarUrl,
                }}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </AvatarContainer>
          ) : (
            <Icon name="person" size={16} />
          )}
          <Text
            size="small"
            style={{
              marginLeft: 6,
              marginRight: 4,
            }}
          >
            By
          </Text>
          <Text size="smallBold" style={{ textDecorationLine: "underline" }}>
            {ownerName}
          </Text>
        </View>
      ) : null}
      {dueAt ? (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Text size="small" style={{ marginRight: 4 }}>
            Due on
          </Text>
          <Text
            size="smallBold"
            style={{
              marginRight: 4,
              textDecorationLine: "underline",
              color: overdue ? theme.warning : theme.primary,
            }}
          >
            {dateFormat(dueAt, "qmDateTime")}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

const AvatarContainer = styled.View`
  background-color: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
