import React, { useCallback, useMemo, useState } from "react";
import {
  DropdownOption,
  InlineDropdown,
} from "@app/components/questkit/inlineDropdown";
import { useAppSelector } from "@app/store";
import { fetchTeams } from "@app/util/client/requests/teams";
import { useRequest } from "@app/util/client/requests";
import { selectUsersTeams } from "@app/store/cache/teams";
import isEqual from "react-fast-compare";

interface TeamListProps {
  teamId: string | undefined;
  setTeamId: (teamId: string) => void;
  allowCreate?: boolean;
}

export const NEW_TEAM_ID = "NEW_TEAM";
export const TeamList: React.FC<TeamListProps> = ({
  teamId,
  setTeamId: _setTeamId,
  allowCreate = false,
}) => {
  const { isValidating: teamsIsValidating } = useRequest(fetchTeams());
  const teams = useAppSelector(selectUsersTeams, isEqual);
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);

  const teamOptions: DropdownOption<string>[] = useMemo(
    () => [
      ...(teams?.map((team) => ({
        label: team.name,
        value: team.id,
        icon: "group" as const,
      })) || []),
      ...(isCreatingTeam
        ? [
            {
              label: "New Team",
              value: NEW_TEAM_ID,
              icon: "group" as const,
            },
          ]
        : []),
    ],
    [isCreatingTeam, teams]
  );

  const onCreate = useCallback(() => {
    setIsCreatingTeam(true);
    _setTeamId(NEW_TEAM_ID);
  }, [_setTeamId]);

  const setTeamId = useCallback(
    (workspaceId: string) => {
      if (workspaceId !== NEW_TEAM_ID) {
        setIsCreatingTeam(false);
      }
      _setTeamId(workspaceId);
    },
    [_setTeamId]
  );

  return (
    <InlineDropdown
      options={teamOptions}
      selectedOption={teamId}
      onSelect={setTeamId}
      onCreate={allowCreate ? onCreate : undefined}
      loadingOptions={teamsIsValidating}
      optionNoun="Team"
      optionPluralNoun="Teams"
    />
  );
};
