import React, { useCallback, useEffect, useRef } from "react";
import { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native";
import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import styled from "styled-components/native";
import ListItem, { ListItemProps } from "@app/components/questkit/listItem";
import { CheckboxToggle } from "../checkbox";
import {
  DragDropList,
  type DraggableItemRenderer,
} from "@app/components/dragdrop";
import DragPanel from "@app/components/questkit/dragPanel";
import { uuid } from "@app/util/uuid";
import { useFocusController } from "@app/util/focus";
import produce from "immer";
import { DragDropListControllerProvider } from "@app/components/dragdrop/DragDropListControllerProvider";

interface MultiSelectItemData {
  id: string;
  checked: boolean;
  text: string;
}

type MultiSelectData = { items: MultiSelectItemData[] };

interface MultiSelectItemProps extends ItemBaseProps {
  singleChoice?: boolean;
}
const emptyArray = Object.freeze([]);
export const MultiSelectItem: React.FC<MultiSelectItemProps> = (props) => {
  const {
    item,
    readOnly,
    editMode,
    onItemValidate,
    singleChoice,
    onItemChange,
  } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const multiSelectItems = (item.data as MultiSelectData).items || emptyArray;
  const focusController = useFocusController();

  const updateSubItems = useCallback(
    (updatedItems: Array<MultiSelectItemData>) => {
      onItemChange?.({
        ...itemRef.current,
        data: { items: updatedItems },
        version: itemRef.current.version + 1,
      });
    },
    [onItemChange]
  );

  useEffect(() => {
    if (!editMode) {
      return;
    }

    if (multiSelectItems.length === 0) {
      updateSubItems([createSubItem()]);
    }
  }, [editMode, multiSelectItems, updateSubItems]);

  const onCheckToggle = useCallback(
    (index: number) => {
      const updatedItems = produce(multiSelectItems, (draft) => {
        if (singleChoice) {
          draft.forEach((subItem) => {
            subItem.checked = false;
          });
        }
        draft[index].checked = !draft[index].checked;
      });
      updateSubItems(updatedItems);
      onItemValidate?.();
    },
    [multiSelectItems, onItemValidate, singleChoice, updateSubItems]
  );

  const onChangeText = useCallback(
    (index: number, text: string) => {
      const updatedItems = produce(multiSelectItems, (draft) => {
        draft[index].text = text;

        const emptyItems = draft.filter(({ text }) => text === "");
        if (emptyItems.length === 0) {
          draft.push(createSubItem());
        } else if (text === "" && draft[index + 1]?.text === "") {
          focusController.focus(draft[index + 1].id);
          draft.splice(index, 1);
        }
      });

      updateSubItems(updatedItems);
    },
    [focusController, multiSelectItems, updateSubItems]
  );

  const onSubItemAdd = useCallback(
    (index: number) => {
      const newSubItem = createSubItem();
      const updatedItems = produce(multiSelectItems, (draft) => {
        const currentItemIsEmpty = !draft[index] || draft[index].text === "";
        draft.splice(
          currentItemIsEmpty ? index : index + 1,
          currentItemIsEmpty ? 1 : 0,
          newSubItem
        );
        // ensure there never two empty options in a row
        for (let i = draft.length - 1; i > 0; i--) {
          if (draft[i].text === "" && draft[i - 1]?.text === "") {
            draft.splice(i, 1);
          }
        }
      });

      updateSubItems(updatedItems);

      focusController.focus(newSubItem.id);
    },
    [multiSelectItems, updateSubItems, focusController]
  );

  const onSubItemDelete = useCallback(
    (index: number) => {
      const updatedItems = produce(multiSelectItems, (draft) => {
        draft.splice(index, 1);
        if (draft.every(({ text }) => text !== "")) {
          // add an empty item if all items are filled
          draft.push(createSubItem());
        }
      });

      focusController.focus(
        updatedItems[index - 1]?.id
          ? updatedItems[index - 1].id
          : updatedItems[0].id
      );

      updateSubItems(updatedItems);
    },
    [focusController, multiSelectItems, updateSubItems]
  );

  const onItemReorder = useCallback(
    (oldIndex: number, newIndex: number) => {
      const updatedItems = produce(multiSelectItems, (draft) => {
        const [item] = draft.splice(oldIndex, 1);
        draft.splice(newIndex, 0, item);
        if (draft[draft.length - 1]?.text !== "") {
          draft.push(createSubItem());
        }

        // ensure there never two empty options in a row
        for (let i = draft.length - 1; i > 0; i--) {
          if (draft[i].text === "" && draft[i - 1]?.text === "") {
            draft.splice(i, 1);
          }
        }
      });
      updateSubItems(updatedItems);
    },
    [multiSelectItems, updateSubItems]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <MultiSelectComponent
          items={multiSelectItems}
          readOnly={!!readOnly}
          editMode={editMode}
          onCheckToggle={onCheckToggle}
          onChangeText={onChangeText}
          onItemAdd={onSubItemAdd}
          onItemDelete={onSubItemDelete}
          onItemReorder={onItemReorder}
        />
      }
    />
  );
};

interface MultiSelectProps {
  items: MultiSelectItemData[];
  readOnly: boolean;
  editMode: boolean;
  onChangeText: (index: number, text: string) => void;
  onCheckToggle: (index: number) => void;
  onItemAdd: (index: number) => void;
  onItemDelete: (index: number) => void;
  onItemReorder: (oldIndex: number, newIndex: number) => void;
}
const MultiSelectComponent: React.FC<MultiSelectProps> = ({
  items,
  readOnly,
  editMode,
  onCheckToggle,
  onChangeText,
  onItemAdd,
  onItemDelete,
  onItemReorder,
}) => {
  const onDragEnd = useCallback(
    (oldIndex: number, newIndex: number) => onItemReorder?.(oldIndex, newIndex),
    [onItemReorder]
  );
  const itemCount = items.length;
  const renderItem = useCallback<DraggableItemRenderer<MultiSelectItemData>>(
    ({ item: subItem, index, dragHandlers, isPlaceholder }) => {
      if (isPlaceholder || (subItem.text === "" && !editMode)) {
        return null;
      }

      return (
        <StyledView isLast={index + 1 === itemCount}>
          <StyledListItemContainer
            id={subItem.id}
            editable={editMode}
            placeholder={"Add option"}
            key={subItem.id}
            text={subItem.text}
            onTextChange={(text: string) => {
              onChangeText(index, text);
            }}
            nativeID={`choice-label-${subItem.id}`}
            actionComponent={
              subItem.text ? (
                <CheckboxToggle
                  checked={subItem.checked}
                  action={() => onCheckToggle(index)}
                  readOnly={readOnly}
                  accessibilityLabelledBy={`choice-label-${subItem.id}`}
                />
              ) : undefined
            }
            onItemAppend={() => onItemAdd(index)}
            onItemDelete={() => onItemDelete(index)}
            onPress={
              !readOnly && !editMode ? () => onCheckToggle(index) : undefined
            }
          />
          {editMode ? <DragPanel dragHandlers={dragHandlers} /> : null}
        </StyledView>
      );
    },
    [
      itemCount,
      editMode,
      onChangeText,
      onCheckToggle,
      onItemAdd,
      onItemDelete,
      readOnly,
    ]
  );
  return (
    <DragDropListControllerProvider>
      <DragDropList
        items={items}
        getId={({ id }) => id}
        onDragEnd={onDragEnd}
        renderItem={renderItem}
      />
    </DragDropListControllerProvider>
  );
};

interface ListItemContainerProps extends ListItemProps {
  onItemDelete: () => void;
  onItemAppend: () => void;
}

const ListItemContainer = (props: ListItemContainerProps) => {
  const onKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (
      event.nativeEvent.key === "Backspace" &&
      props.text === "" &&
      props.onItemDelete
    ) {
      event.preventDefault(); // prevent backspace from triggering other actions, e.g. navigating backwards on web
      props.onItemDelete();
    }
  };

  const onSubmitEditing = () => {
    if (props.onItemAppend) {
      props.onItemAppend();
    }
  };

  return (
    <ListItem
      {...props}
      onSubmitEditing={onSubmitEditing}
      onKeyPress={onKeyPress}
    />
  );
};

const StyledView = styled.View<{ isLast: boolean }>`
  flex-direction: row;
  align-items: center;
  ${({ isLast }) => (isLast ? "" : `margin-bottom: 10px;`)}
`;

const StyledListItemContainer = styled(ListItemContainer)`
  flex: 1;
`;
export function createSubItem(text?: string): MultiSelectItemData {
  return {
    id: uuid(),
    text: text || "",
    checked: false,
  };
}
