import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useChangeTracker } from "@app/quest/edit/useChangeTracker";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { usePromise } from "@app/util/usePromise";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { sentry } from "@app/util/sentry";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { StartTriggerEditFieldsForType } from "@app/store/cache/questStartTriggers";
import { useAppSelector } from "@app/store";
import { UserPickerController } from "@app/components/questkit/UserList/UserPicker";
import HeaderText from "@app/components/questkit/headerText";
import { AssigneePicker } from "@app/components/questkit/AssigneePicker";
import { selectLoggedInUserId } from "@app/store/auth";
import {
  type UserListEvents,
  UserListSyncKey,
} from "@app/components/questkit/UserList/UserList.controller";

interface ManualStartViewProps {
  startTrigger: StartTriggerEditFieldsForType<"BASIC">;
  openStartQuestModal: () => void;
  startChangingQuestType: () => void;
  readOnly: boolean;
  assigneeSynchronizationKey?: UserListSyncKey;
}

export const ManualStartView: React.FC<ManualStartViewProps> = ({
  startTrigger: startTriggerFromServer,
  startChangingQuestType,
  openStartQuestModal,
  readOnly,
  assigneeSynchronizationKey,
}) => {
  const { useValueWithChanges } = useChangeTracker(startTriggerFromServer);
  const startTrigger = useValueWithChanges();

  const snackbar = useContext(SnackbarContext);

  const assigneePickerRef = useRef<UserPickerController>(null);

  const { execute: onRunQuest, isLoading } = usePromise(async () => {
    await assigneePickerRef.current!.flush().catch((e) => {
      snackbar.sendMessage(
        `Some assignees could not be added. Please try again.`,
        SnackbarSeverity.WARNING
      );
      sentry.captureException(e, {
        extra: { message: "Failed to flush assignee picker" },
      });
      throw e;
    });
    openStartQuestModal();
  });

  const [isAssignedToOthers, setIsAssignedToOthers] = useState(false);
  const [hasTextInAssigneeInput, setHasTextInAssigneeInput] = useState(false);
  const [isLookingUpUsers, setIsLookingUpUsers] = useState(false);
  const isSendingQuestToOthers =
    isAssignedToOthers || hasTextInAssigneeInput || isLookingUpUsers;

  useEffect(() => {
    const listener = ({
      lookupCompletedPromise,
    }: UserListEvents["lookupUsers"]) => {
      setIsLookingUpUsers(true);
      void lookupCompletedPromise.finally(() => setIsLookingUpUsers(false));
    };

    const assigneePicker = assigneePickerRef.current;
    assigneePicker?.on("lookupUsers", listener);

    return () => {
      assigneePicker?.off("lookupUsers", listener);
    };
  }, []);

  const loggedInUserId = useAppSelector(selectLoggedInUserId);
  const onAssigneeChange = useCallback(
    (userIds: string[]) => {
      if (
        userIds.length === 0 ||
        (userIds.length === 1 && userIds[0] === loggedInUserId)
      ) {
        setIsAssignedToOthers(false);
      } else {
        setIsAssignedToOthers(true);
      }
    },
    [loggedInUserId]
  );
  const onAssigneeInputTextChange = useCallback((text: string) => {
    setHasTextInAssigneeInput(!!text);
  }, []);

  if (readOnly) {
    return (
      <StartTriggerSummary
        text={"Starts Manually"}
        icon={QUEST_TYPES["BASIC"].icon}
      />
    );
  } else {
    return (
      <StartTriggerCard
        title={QUEST_TYPES["BASIC"].name}
        icon={QUEST_TYPES["BASIC"].icon}
        startChangingQuestType={startChangingQuestType}
      >
        <HeaderText title="Assignees" />
        <Section>
          <AssigneePicker
            startConfigurationId={startTrigger.startConfigurationId!}
            noInitialAssignees={true}
            ref={assigneePickerRef}
            onChangeAssignees={onAssigneeChange}
            onInputTextChange={onAssigneeInputTextChange}
            synchronizationKey={assigneeSynchronizationKey}
          />
        </Section>
        <Button
          onPress={onRunQuest}
          title={isSendingQuestToOthers ? "Run & Send Quest" : "Run Quest"}
          icon={isSendingQuestToOthers ? "send" : "play"}
          testID="open-start-quest-modal-button"
          buttonType="primary"
          loading={isLoading}
          // success={questStarted}
        />
      </StartTriggerCard>
    );
  }
};

const Section = styled.View`
  margin-bottom: 20px;
`;
