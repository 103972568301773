import { ArrayElement, Identity, UnionToIntersection } from "@questmate/common";

type MapFn<S, K extends keyof S, D> = (
  value: Exclude<S[K], undefined>
) => Partial<D>;

type FieldMappings<S, D> = {
  [K in keyof S]?: MapFn<S, K, D>;
};

type CustomMappersResultObj<FM extends FieldMappings<unknown, unknown>> =
  FM extends FieldMappings<infer S, unknown>
    ? UnionToIntersection<
        FM[keyof S] extends MapFn<S, keyof S, infer D>
          ? D
          : Record<string, unknown>
      >
    : never;

type MatchedKeys<O1, O2> = Array<
  keyof {
    [K in keyof O1]: K extends keyof O2 ? K : never;
  }
>;

type ObjKey = string | number | symbol;

type CopiedFields<F extends ObjKey[], S> = F extends MatchedKeys<S, unknown>
  ? ArrayElement<F> extends keyof S
    ? {
        [K in ArrayElement<F>]: S[K];
      }
    : never
  : never;

type MapResult<
  S,
  F extends ObjKey[],
  CM extends FieldMappings<unknown, unknown>
> = Identity<
  CustomMappersResultObj<CM> & CopiedFields<F, UnionToIntersection<S>>
>;

export const createDataMapper =
  <
    // eslint-disable-next-line @typescript-eslint/ban-types
    INPUT extends object,
    OUTPUT
  >() =>
  /**
   * @param fields List of field names to copy over from the source object.
   * @param customMappers Map functions that transform source fields into zero or more fields with the same or different types.
   */
  <
    F extends MatchedKeys<UnionToIntersection<INPUT>, OUTPUT>,
    CM extends FieldMappings<UnionToIntersection<INPUT>, OUTPUT>
  >(
    fields: F,
    customMappers: CM
  ) => {
    const customMapperEntries = Object.entries(customMappers) as [
      keyof CM,
      CM[keyof CM]
    ][];
    /**
     * Maps a source object to a target object using custom mappers and a list of fields to copy.
     * @param sourceObject The source object to map from.
     *
     * NOTE: This function has been optimized for performance.
     *       Avoid refactors that would make it less performant.
     */
    return (sourceObject: INPUT): MapResult<INPUT, F, CM> => {
      const result = {};
      for (let i = 0; i < fields.length; i++) {
        if (fields[i] in sourceObject) {
          // @ts-expect-error It's not worth the effort to get useful type support here.
          result[fields[i]] = sourceObject[fields[i]];
        }
      }

      for (let i = 0; i < customMapperEntries.length; i++) {
        if (customMapperEntries[i][0] in sourceObject) {
          const mappedFieldsObj = customMapperEntries[i][1]!(
            // @ts-expect-error It's not worth the effort to get useful type support here.
            sourceObject[customMapperEntries[i][0]]
          );
          const mappedFields = Object.keys(
            mappedFieldsObj
          ) as (keyof typeof mappedFieldsObj)[];
          for (let j = 0; j < mappedFields.length; j++) {
            // @ts-expect-error It's not worth the effort to get useful type support here.
            result[mappedFields[j]] = mappedFieldsObj[mappedFields[j]];
          }
        }
      }

      return result as MapResult<INPUT, F, CM>;
    };
  };
