import React, { ErrorInfo, ReactNode } from "react";
import { useFocusableRef } from "@app/util/focus";
import styled, { useTheme } from "styled-components/native";
import { CustomItemDropdownComponent } from "@app/components/item/components/custom/components/CustomItemDropdownComponent";
import { InlineErrorWithRetry } from "@app/components/item/components/custom/InlineErrorWithRetry";
import { CustomItemSwitchComponent } from "@app/components/item/components/custom/components/CustomItemSwitchComponent";
import { CustomItemButtonComponent } from "@app/components/item/components/custom/components/CustomItemButtonComponent";
import { CustomItemTextComponent } from "@app/components/item/components/custom/components/CustomItemTextComponent";
import {
  CustomItemComponentModel,
  type CustomItemInlineComponentModel,
} from "@app/components/item/components/custom/types";
import { CustomItemPlaceholderComponent } from "@app/components/item/components/custom/components/CustomItemPlaceholderComponent";
import { CustomItemItemPickerComponent } from "@app/components/item/components/custom/components/CustomItemItemPickerComponent";
import { CustomItemLongAnswerComponent } from "@app/components/item/components/custom/components/CustomItemLongAnswerComponent";
import { CustomItemShortAnswerComponent } from "@app/components/item/components/custom/components/CustomItemShortAnswerComponent";
import { CustomItemNavigationActionComponent } from "@app/components/item/components/custom/components/CustomItemNavigationActionComponent";
import { CustomItemQuestDataPickerComponent } from "@app/components/item/components/custom/components/CustomItemQuestDataPickerComponent";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";
import { useItemContext } from "@app/components/item/ItemContext";
import Icon from "@app/components/icon";
import { CustomItemCheckableInlineComponent } from "@app/components/item/components/custom/components/CustomItemInlineCheckableComponent";
import { CustomItemAudioInlineComponent } from "@app/components/item/components/custom/components/CustomItemAudioInlineComponent";
import { CustomItemCheckableComponent } from "./components/CustomItemCheckableComponent";
import { CustomItemCardComponent } from "@app/components/item/components/custom/components/CustomItemCardComponent";
import { CustomItemCopyTextComponent } from "./components/CustomItemCopyTextComponent";

interface CustomItemComponentsProps {
  components: CustomItemComponentModel[] | undefined | null;
  onErrorRetry: () => Promise<unknown>;
  isLoading: boolean;
  hasError: boolean;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly?: boolean;
}

export const CustomItemComponents: React.FC<CustomItemComponentsProps> = (
  props
) => {
  const { isLoading, onErrorRetry } = props;

  return props.hasError ? (
    <InlineErrorWithRetry
      message="Unable to show item."
      isLoading={isLoading}
      onRetry={onErrorRetry}
    />
  ) : (
    <CustomItemErrorBoundary isLoading={isLoading} onErrorRetry={onErrorRetry}>
      <UnsafeCustomItemComponents {...props} />
    </CustomItemErrorBoundary>
  );
};

const UnsafeCustomItemComponents: React.FC<
  Omit<CustomItemComponentsProps, "hasError">
> = ({ components = [], autoFocusRef, isLoading, readOnly = false }) => {
  const theme = useTheme();
  const { prototypeId } = useItemContext();

  return (
    <>
      {isLoading && components!.length === 0 ? (
        <QKLoadingIndicator
          name={`item-render-${prototypeId}`}
          yieldTo={[
            "allCompletionActions",
            `admin-item-run-${prototypeId}`,
            `item-run-${prototypeId}`,
          ]}
          size={32}
          color={theme.primary}
        />
      ) : (
        components!.map((component, index) => {
          return (
            <CustomItemComponentContainer
              isFirstComponent={index === 0}
              key={component?.id}
            >
              <CustomItemComponent
                component={component}
                autoFocusRef={autoFocusRef}
                readOnly={readOnly}
                isLoading={isLoading}
              />
            </CustomItemComponentContainer>
          );
        })
      )}
    </>
  );
};

type CustomItemComponentProps = Omit<
  CustomItemComponentsProps,
  "components" | "onErrorRetry" | "hasError"
> & {
  component: CustomItemComponentModel;
};

export const CustomItemComponent: React.FC<CustomItemComponentProps> = (
  props
) => {
  const { component } = props;

  const commonProps = {
    autoFocusRef: props.autoFocusRef,
    isLoading: props.isLoading,
    readOnly: Boolean(props.readOnly),
  };

  switch (component.type) {
    case "dropdown":
      return (
        <CustomItemDropdownComponent {...commonProps} component={component} />
      );
    case "switch":
      return (
        <CustomItemSwitchComponent {...commonProps} component={component} />
      );
    case "button":
      return (
        <CustomItemButtonComponent {...commonProps} component={component} />
      );
    case "NavigationAction":
      return (
        <CustomItemNavigationActionComponent
          {...commonProps}
          component={component}
        />
      );
    case "ItemPicker":
      return (
        <CustomItemItemPickerComponent {...commonProps} component={component} />
      );
    case "QuestDataPicker":
      return (
        <CustomItemQuestDataPickerComponent
          {...commonProps}
          component={component}
        />
      );
    case "ShortAnswer":
      return (
        <CustomItemShortAnswerComponent
          {...commonProps}
          component={component}
        />
      );
    case "LongAnswer":
      return (
        <CustomItemLongAnswerComponent {...commonProps} component={component} />
      );
    case "Checkable":
      return (
        <CustomItemCheckableComponent {...commonProps} component={component} />
      );
    case "text":
      return <CustomItemTextComponent component={component} />;
    case "CopyText":
      return <CustomItemCopyTextComponent component={component} />;
    case "Card":
      return <CustomItemCardComponent {...commonProps} component={component} />;
    default:
      console.warn(
        `Unsupported component type: ${
          (component as Record<string, string>).type
        }`
      );
      return <CustomItemPlaceholderComponent component={component} />;
  }
};

type CustomItemInlineComponentProps = {
  component: CustomItemInlineComponentModel;
  isLoading: boolean;
  readOnly?: boolean;
};

export const CustomItemInlineComponent: React.FC<
  CustomItemInlineComponentProps
> = (props) => {
  // TODO: Make user-friendly Error show when error boundary hit.
  return (
    <CustomItemErrorBoundary
      isLoading={false}
      onErrorRetry={() => Promise.resolve()}
    >
      <UnsafeCustomItemInlineComponent {...props} />
    </CustomItemErrorBoundary>
  );
};
const UnsafeCustomItemInlineComponent: React.FC<
  CustomItemInlineComponentProps
> = (props) => {
  const { component } = props;

  const commonProps = {
    isLoading: props.isLoading,
    readOnly: Boolean(props.readOnly),
  };

  switch (component.type) {
    case "Checkable":
      return (
        <CustomItemCheckableInlineComponent
          {...commonProps}
          component={component}
        />
      );
    case "Audio":
      return (
        <CustomItemAudioInlineComponent
          {...commonProps}
          component={component}
        />
      );
    default:
      console.warn(
        `Unsupported component type: ${
          (component as unknown as Record<string, string>)?.type
        }`
      );
      return <Icon icon={"close"} />;
  }
};

interface CustomItemErrorBoundaryProps {
  children: ReactNode | undefined;
  isLoading: boolean;
  onErrorRetry: () => Promise<unknown>;
}
class CustomItemErrorBoundary extends React.Component<CustomItemErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      "ERROR rendering custom item components: ",
      error,
      "errorInfo",
      errorInfo
    );
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps?: Readonly<CustomItemErrorBoundaryProps>) {
    if (!this.props.isLoading && prevProps?.isLoading) {
      this.setState({ hasError: false });
    }
  }

  render() {
    const { children } = this.props;

    if (this.state.hasError) {
      return (
        <InlineErrorWithRetry
          message="Unable to show item."
          isLoading={this.props.isLoading}
          onRetry={() =>
            this.props.onErrorRetry().finally(() => {
              this.setState({ hasError: false });
            })
          }
        />
      );
    } else {
      return children;
    }
  }
}

const CustomItemComponentContainer = styled.View<{ isFirstComponent: boolean }>`
  padding-top: ${({ isFirstComponent }) => (isFirstComponent ? "0px" : "20px")};
`;
