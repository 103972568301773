import { useRef } from "react";
import isEqual from "react-fast-compare";

export const useIsEqualMemo = <T,>(value: T): T => {
  const stableValueRef = useRef(value);
  const lastValueRef = useRef(value);

  if (
    // avoid re-checking deep equality if the value hasn't changed
    lastValueRef.current !== value &&
    !isEqual(stableValueRef.current, value)
  ) {
    stableValueRef.current = value;
  }

  lastValueRef.current = value;

  return stableValueRef.current;
};
