import { Me } from "@questmate/openapi-spec";
import { store } from "@app/store";
import { usersLoaded } from "@app/store/cache/users";
import { createRequest } from "@app/util/client/requests/index";
import { apiRequest } from "@app/util/client";

export const fetchMe = () => {
  return createRequest<Me.GetMe.ResponseBody>(
    "get",
    `/me`
  )((response) => {
    store.dispatch(usersLoaded([response]));
  });
};

export const updateMe = (data: Me.PatchMe.RequestBody) => {
  return apiRequest<Me.PatchMe.ResponseBody>("patch", "/me", data).then(
    (response) => {
      store.dispatch(usersLoaded([response]));
      return response;
    }
  );
};
