import { EditableQuestPrototypeDetails } from "@app/store/cache/questPrototypes";
import {
  type RewardPrototypeValidationContext,
  validateRewardPrototype,
  ValidationError,
} from "@questmate/common";
import { validateItemPrototype } from "@questmate/common/dist/item/ItemValidator";

type ValidateQuestPrototypeResult =
  | {
      valid: true;
    }
  | { valid: false; errors: ValidationError[] };

export type QuestPrototypeValidationContext = RewardPrototypeValidationContext;

export async function validateQuestPrototype(
  questPrototype: EditableQuestPrototypeDetails,
  validationContext: QuestPrototypeValidationContext
): Promise<ValidateQuestPrototypeResult> {
  try {
    // validate rewards
    const rewardValidationResults = (
      await Promise.all(
        (questPrototype.rewardIds || []).map(async (id) => {
          const pathPrefix = ["rewards", id];
          try {
            const errors = await validateRewardPrototype(
              questPrototype.rewardsById[id],
              validationContext
            );
            return errors.map((error) => ({
              ...error,
              path: [...pathPrefix, ...error.path],
            }));
          } catch (error) {
            if (error instanceof ValidationError.MissingDefinition) {
              console.warn(
                "Unable to validate reward, missing definition. Please update app.",
                questPrototype.rewardsById[id]
              );
              return [];
            }
            return [
              {
                message: "Action is not valid.",
                errorCode: "UNKNOWN_ERROR",
                path: pathPrefix,
              } satisfies ValidationError,
            ];
          }
        })
      )
    ).flat();

    // validate items
    const itemValidationResults = (
      await Promise.all(
        (questPrototype.itemIds || []).map(async (id) => {
          const pathPrefix = ["items", id];
          try {
            const errors = await validateItemPrototype(
              questPrototype.itemsById[id],
              validationContext
            );
            return errors.map((error) => ({
              ...error,
              path: [...pathPrefix, ...error.path],
            }));
          } catch (error) {
            if (error instanceof ValidationError.MissingDefinition) {
              console.warn(
                "Unable to validate item, missing definition. Please update app.",
                questPrototype.itemsById[id]
              );
              return [];
            }
            return [
              {
                message: "Item is not valid.",
                errorCode: "UNKNOWN_ERROR",
                path: pathPrefix,
              } satisfies ValidationError,
            ];
          }
        })
      )
    ).flat();

    // TODO: Validate other fields (e.g. items)

    if (
      rewardValidationResults.length === 0 &&
      itemValidationResults.length === 0
    ) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errors: rewardValidationResults.concat(itemValidationResults),
    };
  } catch (error) {
    console.error("Failed to validate Quest. Error:", error);
    return {
      valid: false,
      errors: [
        {
          path: [],
          message: "Failed to validate Quest",
          errorCode: "UNEXPECTED_ERROR",
        },
      ],
    };
  }
}
