import React, { useCallback, useMemo } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { type CardComponentModel } from "@app/components/item/components/custom/types";
import type {
  CardColumnSubComponentDefinition,
  CardImageSubComponentDefinition,
  CardSubComponentDefinition,
  CardTextSubComponentDefinition,
} from "@questmate/questscript";
import { Image } from "expo-image";
import type { DimensionValue } from "react-native";
import type { ImageLoadEventData } from "expo-image/src/Image.types";
import BasePressable from "@app/components/questkit/BasePressable";
import { createLink, type LinkableLocation } from "@app/util/link.utils";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";
import { type HoverProps, useHover } from "@app/util/useHover";

interface CustomItemCardComponentProps {
  component: CardComponentModel;
  readOnly: boolean;
}

export const CustomItemCardComponent: React.FC<
  CustomItemCardComponentProps
> = ({ component, readOnly }) => {
  return (
    <CardContainer>
      {component.title && (
        <TitleText size="medium">{component.title}</TitleText>
      )}
      {component.rows.map((row, index) => (
        <React.Fragment key={row.id}>
          <CardRow row={row} readOnly={readOnly} />
          {index < component.rows.length - 1 ? <RowDivider /> : null}
        </React.Fragment>
      ))}
    </CardContainer>
  );
};
const CardContainer = styled.View`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px;
  padding: 16px;
`;

const RowDivider = styled.View`
  border-color: ${({ theme }) => theme.primary};
  border-bottom-width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const TitleText = styled(Text)`
  margin-right: 20px;
`;

type CardRowModel = CardComponentModel["rows"][number];
interface CardRowProps {
  row: CardRowModel;
  readOnly: boolean;
}
const CardRow: React.FC<CardRowProps> = ({ row, readOnly }) => {
  const link = useIsEqualMemo(row.link);

  const onPress = useMemo(() => {
    if (!link?.to) {
      return;
    }
    return createLink(link.to as LinkableLocation, {
      // Default to opening these links in a new tab.
      newTabOnWeb: link.newTabOnWeb === undefined ? true : link.newTabOnWeb,
    });
  }, [link]);

  const { hoverProps } = useHover();

  return (
    <RowContainer
      {...hoverProps}
      disabled={readOnly || !onPress}
      onPress={onPress}
    >
      {row.components.map((component, index) => {
        const alignment =
          index === 0
            ? "left"
            : index === row.components.length - 1
            ? "right"
            : component.align ?? "center";
        const isEdgeComponent =
          index === 0 || index === row.components.length - 1;
        const widthStrategy =
          component.width ?? (isEdgeComponent ? "collapse" : "expand");
        return (
          <RowComponentContainer
            key={component.id}
            widthStrategy={widthStrategy}
          >
            {component.type === "Column" ? (
              <CardColumn
                key={component.id}
                column={component}
                alignment={alignment}
              />
            ) : (
              <CardSubComponent
                key={component.id}
                component={component}
                alignment={alignment}
              />
            )}
          </RowComponentContainer>
        );
      })}
    </RowContainer>
  );
};
const RowContainer = styled(BasePressable)<HoverProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
// background-color: ${({ theme, isHovered, disabled }) =>
//   theme.tile.standard.background[
//     !disabled && isHovered ? "hover" : "normal"
//   ]};
// transition: background-color 0.15s ease-in-out;

const RowComponentContainer = styled.View<{
  widthStrategy: ColumnWidthStrategy;
}>`
  ${({ widthStrategy }) => (widthStrategy === "expand" ? "flex: 1;" : "")}
`;

type ColumnContentAlignment = "left" | "center" | "right";
type ColumnWidthStrategy = "collapse" | "expand";

interface CardColumnProps {
  column: CardColumnSubComponentDefinition;
  alignment: ColumnContentAlignment;
}
const CardColumn: React.FC<CardColumnProps> = ({ column, alignment }) => {
  return (
    <ColumnContainer alignment={alignment}>
      {column.components.map((component) => (
        <CardSubComponent
          key={component.id}
          component={component}
          alignment={component.align ?? alignment}
        />
      ))}
    </ColumnContainer>
  );
};

const ColumnContainer = styled.View<{ alignment: ColumnContentAlignment }>`
  display: flex;
  flex-direction: column;
`;

interface CardSubComponentProps {
  component: CardSubComponentDefinition;
  alignment: ColumnContentAlignment;
}
const CardSubComponent: React.FC<CardSubComponentProps> = ({
  component,
  alignment,
}) => {
  switch (component.type) {
    case "Text":
      return (
        <CardTextSubComponent component={component} alignment={alignment} />
      );
    case "Image":
      return (
        <CardImageSubComponent component={component} alignment={alignment} />
      );
    default:
      return null;
  }
};

interface CardTextSubComponentProps {
  component: CardTextSubComponentDefinition;
  alignment: ColumnContentAlignment;
}
const CardTextSubComponent: React.FC<CardTextSubComponentProps> = ({
  component,
  alignment,
}) => {
  return (
    <StyledText
      alignment={alignment}
      size={component.bold ? "mediumBold" : "medium"}
    >
      {component.content}
    </StyledText>
  );
};
const StyledText = styled(Text)<{ alignment: ColumnContentAlignment }>`
  ${({ alignment }) => `text-align: ${alignment};`}
`;

interface CardImageSubComponentProps {
  component: CardImageSubComponentDefinition;
  alignment: ColumnContentAlignment;
}
const IMAGE_HEIGHT = 40;
const CardImageSubComponent: React.FC<CardImageSubComponentProps> = ({
  component,
  alignment,
}) => {
  // TODO: Try setting back to "100%" width by default after upgrading to latest
  //  expo-image. Current version requires a pixel width to display jpg on
  //  mobile. But 100% width causes less adjustments on initial render.
  const [width, setWidth] = React.useState<DimensionValue>(IMAGE_HEIGHT * 2);
  const onLoad = useCallback((event: ImageLoadEventData) => {
    const scaledWidth =
      (event.source.width * IMAGE_HEIGHT) / event.source.height;
    setWidth(scaledWidth);
  }, []);
  return (
    <ImageContainer alignment={alignment}>
      <StyledImage
        style={{ width }}
        source={component.url}
        contentFit={"scale-down"}
        onLoad={onLoad}
      />
    </ImageContainer>
  );
};

const StyledImage = styled(Image)`
  max-height: ${IMAGE_HEIGHT}px;
  height: ${IMAGE_HEIGHT}px;
  min-height: ${IMAGE_HEIGHT}px;
`;
const ImageContainer = styled.View<{ alignment: ColumnContentAlignment }>`
  align-items: ${({ alignment }) =>
    alignment === "left"
      ? "flex-start"
      : alignment === "right"
      ? "flex-end"
      : "center"};
  justify-content: center;
`;
