import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { apiRequest } from "@app/util/client";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { StackActions } from "@react-navigation/native";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { QuestPrototypeDetail } from "@questmate/openapi-spec";

export const QuestCreate: React.FC = () => {
  const navigation = useAppNavigation();
  const templateId = useAppRoute<"QuestCreate">().params?.templateId;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const createQuest = () => {
    setLoading(true);

    apiRequest<QuestPrototypeDetail>("post", `/quests`, {
      templateId,
    })
      .then(async (questPrototypeData) => {
        await apiRequest<QuestPrototypeDetail>(
          "post",
          `/quests/${questPrototypeData.id}/start`,
          {}
        ).then((startedQuestPrototypeData) => {
          // snackbarContext.sendMessage(`New Quest created.`);
          navigation.dispatch(
            StackActions.replace("Quest", {
              questId: startedQuestPrototypeData.quest.id,
              screen: "Runs",
              params: {},
            })
          );
        });
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!templateId) {
      navigation.navigate("Home");
      return;
    }
    createQuest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  if (error) {
    return (
      <PlaceholderView
        text="Oops, that didn't quite work."
        actions={[
          {
            type: "primary",
            text: "Try again",
            loading: loading,
            onPress: createQuest,
          },
        ]}
      />
    );
  }

  return (
    <StyledView>
      <Loader />
    </StyledView>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
