import linkingConfig, { getPathForScreen } from "@app/navigation/linkingConfig";
import React, { useMemo } from "react";
import { GestureResponderEvent, Linking, Platform } from "react-native";
import { type To } from "@react-navigation/native/src/useLinkTo";
import {
  type QMRoute,
  type QMStackParamList,
} from "@app/navigation/QMNavigator";
import { type Action } from "@react-navigation/routers/lib/typescript/src/CommonActions";
import { type StackActionType } from "@react-navigation/routers/lib/typescript/src/StackRouter";
import { StackActions, useNavigation } from "@react-navigation/native";
import { sentry } from "@app/util/sentry";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import parse from "url-parse";
import { navigate, reset } from "@react-navigation/routers/src/CommonActions";

export type SupportedNavigationActionType = Extract<
  (Action | StackActionType)["type"],
  "NAVIGATE" | "PUSH" | "REPLACE" | "RESET"
>;
export type LinkableLocation =
  | string
  | (Exclude<To<QMStackParamList>, string> & {
      type?: SupportedNavigationActionType;
    });

type LinkOptions = {
  onPressHook?: () => unknown;
  newTabOnWeb?: boolean;
};

export type LinkProps = {
  accessibilityRole: "link";
  href: string | undefined;
  hrefAttrs?: { target: "blank" };
};

/**
 * Function with a `linkProps` property that can be used in combination
 * with `BasePressable` to ensure that the link will have the appropriate
 * accessibility role and href attributes.
 *
 */
export type OnLinkPress = { linkProps: LinkProps } & ((
  e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent
) => void);

export function useLink(
  to: LinkableLocation | undefined,
  options?: LinkOptions
): OnLinkPress | undefined {
  return useMemo(
    () => (to === undefined ? to : createLink(to, options)),
    // Recreate the link when `navigation` changes to ensure switching
    // from logged-in to logged-out navigators our links will work as expected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [to, options, useNavigation()]
  );
}

/**
 * Creates a link that can be used to navigate to a screen or URL.
 * Intended to work for all link types, internal and external,
 * including app-specific schemes to deep link directly into other apps on devices.
 *
 * @param to - The location to navigate to. Can be a string URL or an object
 * @param options - Options to customize the link behavior
 *
 * @return undefined if location is not valid.
 * @return A function of type {@link OnLinkPress} that can
 * be used to navigate to the specified location. Used in combination with
 * `BasePressable` the link will have the appropriate accessibility role
 * and href attributes.
 *
 * Much of this code was copied/inspired by the useLinkProps hook from
 * react-navigation but made to not require a component/hook to be used.
 *
 */
export function createLink(
  to: LinkableLocation,
  options?: LinkOptions
): OnLinkPress | undefined {
  if (
    !to ||
    (typeof to === "string" && !to.trim()) ||
    (typeof to !== "string" && !to.screen)
  ) {
    return undefined;
  }

  const onPress = (
    pressEvent?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | GestureResponderEvent
  ) => {
    try {
      const result = options?.onPressHook?.();
      if (
        result &&
        typeof result === "object" &&
        "catch" in result &&
        typeof result.catch === "function"
      ) {
        result.catch((e: unknown) => {
          console.warn("onPressHook threw async error when navigating!", e);
          sentry.captureException(e, { extra: { to, options } });
        });
      }
    } catch (e) {
      console.warn("onPressHook threw when navigating!", e);
      sentry.captureException(e, { extra: { to, options } });
    }

    if (shouldHandleEvent(pressEvent)) {
      pressEvent?.preventDefault();
      visitLink(to, options);
      return false;
    }
  };

  onPress.linkProps = {
    accessibilityRole: "link" as const,
    href: isExternalLink(to) ? to : getHrefForInternalLink(to),
    ...(options?.newTabOnWeb
      ? {
          hrefAttrs: { target: "blank" as const },
        }
      : {}),
  };

  return onPress;
}

export function visitLink(to: LinkableLocation, options?: LinkOptions): void {
  const externalLink = isExternalLink(to);
  sentry.addBreadcrumb({
    category: "navigation",
    message: "Visiting Link",
    data: {
      to,
      options,
      isExternalLink: externalLink,
    },
  });
  if (externalLink) {
    if (Platform.OS === "web") {
      if (options?.newTabOnWeb) {
        window.open(to, "_blank");
      } else {
        window.location = to as unknown as Location;
      }
    } else {
      // Just try to open the URL without checking if we can open it (`Linking.canOpenURL()`). On iOS there is a difference
      // between asking if it can open and just trying to open it. Asking is limited to the schemes defined for
      // `LSApplicationQueriesSchemes` in the info.plist which we do not define any, so for all non-http(s) URLs on iOS
      // it would return false.
      Linking.openURL(to).catch((e) => {
        console.warn("Unable to visit External URL: ", to);
        sentry.captureException(e, {
          extra: {
            message: "Unable to Visit External URL",
            to,
          },
        });
      });
    }
  } else {
    navigateToInternalScreen(to);
  }
}

function navigateToInternalScreen(to: LinkableLocation, options?: LinkOptions) {
  if (Platform.OS === "web" && options?.newTabOnWeb) {
    const url = getHrefForInternalLink(to);
    if (url) {
      const qualifiedUrl = new URL(url, window.location.href);
      window.open(qualifiedUrl, "_blank");
    } else {
      throw new Error("Link Failed: Unable to build URL for internal screen");
    }
  } else {
    const navigation = navigationRef.current;
    if (navigation === undefined || navigation === null) {
      throw new Error(
        "Link Failed: Couldn't find a navigation object. Is your component inside NavigationContainer?"
      );
    }

    let route: QMRoute | undefined;
    if (typeof to === "string") {
      const internalUrlPath = removeInternalPrefixes(to);

      if (!internalUrlPath.startsWith("/")) {
        throw new Error(
          `Link Failed: The path must start with '/' (${internalUrlPath}).`
        );
      }
      const state = linkingConfig.getStateFromPath(internalUrlPath);
      if (!state) {
        console.error(
          "Link Failed: Failed to parse the path to a navigation state.",
          JSON.stringify({ to, internalUrlPath, state })
        );
        throw new Error(
          "Link Failed: Failed to parse the path to a navigation state."
        );
      }

      route = state?.routes[state?.index ?? 0] as QMRoute | undefined;
    } else {
      route = {
        name: to.screen,
        params: to.params,
      } as QMRoute;
    }

    if (!route?.name) {
      throw new Error(`Link Failed: The route does not exist (${to}).`);
    }

    const navigationActionType =
      (typeof to === "object" ? to.type : undefined) ?? "NAVIGATE";

    let action;
    switch (navigationActionType) {
      case "NAVIGATE":
        action = navigate(route.name, route.params);
        break;
      case "PUSH":
        action = StackActions.push(route.name, route.params);
        break;
      case "REPLACE":
        action = StackActions.replace(route.name, route.params);
        break;
      case "RESET":
        action = reset({
          index: 0,
          routes: [route],
        });
        break;
      default:
        throw new Error(
          `Link Failed: Unsupported navigation action type (${navigationActionType}).`
        );
    }

    navigation.dispatch(action);
  }
}

export function getHrefForInternalLink(
  to: LinkableLocation
): string | undefined {
  if (typeof to === "string") {
    const state = linkingConfig.getStateFromPath(removeInternalPrefixes(to));
    if (!state) {
      return undefined;
    }
    return linkingConfig.getPathFromState(state);
  } else {
    return getPathForScreen(to);
  }
}

function isExternalLink<ParamList extends ReactNavigation.RootParamList>(
  to: To<ParamList>
): to is string {
  if (typeof to === "string") {
    const url = removeInternalPrefixes(to);
    // If there is still a protocol on the url after removing our internal URL prefixes
    // then it is not one of our internal URLs. Empty object in second argument is to
    // prevent browser location from being considered as part of parsing.
    const isExternalLink = !!parse(url, {}).protocol;
    if (isExternalLink) {
      return true;
    }
  }
  return false;
}

export const removeInternalPrefixes = (url: string) => {
  url = url.trim();
  const internalLinkPrefixes = linkingConfig.prefixes;
  for (const prefix of internalLinkPrefixes) {
    const isInternalLink = new RegExp(`^${prefix}.*`).exec(url);
    if (isInternalLink) {
      return url.replace(prefix, "");
    }
  }
  return url;
};

function shouldHandleEvent(
  e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent
) {
  // code extracted from onPress of useLinkProps
  // so that we can ensure behavior is the same when navigating
  // to a URL as navigating to a screen
  let shouldHandle = false;
  if (Platform.OS !== "web" || !e) {
    const event = e as GestureResponderEvent | undefined;
    shouldHandle = event ? !event.defaultPrevented : true;
  } else {
    const event = e as React.MouseEvent<HTMLAnchorElement, MouseEvent>;
    if (
      !event.defaultPrevented && // onPress prevented default
      !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) && // ignore clicks with modifier keys
      (event.button == null || event.button === 0) && // ignore everything but left clicks
      [undefined, null, "", "self", "blank"].includes(
        event.currentTarget?.target
      ) // let browser handle other targets _parent, _top, etc.
    ) {
      shouldHandle = true;
    }
  }
  return shouldHandle;
}
