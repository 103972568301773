import { Platform } from "react-native";

export type DebugFn = <T>(
  msgOrFn: string | (() => T),
  ...args: unknown[]
) => void | T;

export const createDebugLogger = (
  prefix: string,
  conditionCheck: () => boolean
): DebugFn => {
  return (msgOrFn, ...args) => {
    if (conditionCheck()) {
      if (typeof msgOrFn === "string") {
        const linePrefix =
          Platform.OS === "web" ? "" : new Date().toISOString() + " ";
        console.log(
          linePrefix +
            (prefix ? `${prefix} ` : "") +
            msgOrFn.replaceAll(/\n/g, "\n" + linePrefix),
          ...args
        );
      } else {
        return msgOrFn();
      }
    }
  };
};
