import React from "react";

import styled from "styled-components/native";

import Text from "@app/components/questkit/text";
import {
  CompletionActionType,
  CompletionActionRenderData,
} from "@app/types/completionActionRenderData";
import { Pressable, View } from "react-native";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { IconIdentifier } from "@app/components/icon";

interface CompletionActionOptionsDialogProps {
  completionAction: CompletionActionRenderData;
  onCompletionActionChange: (
    completionAction: CompletionActionRenderData
  ) => void;
  onModalClose: () => void;
}

type CompletionActionTypeViewData = {
  value: CompletionActionType;
  label: string;
  icon: IconIdentifier;
  searchTerms: string[];
  isAdvanced?: boolean;
};
export const completionActionTypeViewDataMap: Record<
  CompletionActionType,
  CompletionActionTypeViewData
> = {
  TREMENDOUS_REWARD: {
    value: "TREMENDOUS_REWARD",
    label: "Issue Reward",
    icon: "credit-card",
    searchTerms: [],
  },
  TEXT: {
    value: "TEXT",
    label: "Reveal Text",
    icon: "text",
    searchTerms: ["content", "message"],
  },
  POST_SLACK_MESSAGE: {
    value: "POST_SLACK_MESSAGE",
    label: "Post Message to Slack",
    icon: "sms",
    searchTerms: [],
  },
  CREATE_QUEST: {
    isAdvanced: true,
    value: "CREATE_QUEST",
    label: "Create Quest",
    icon: "item",
    searchTerms: [],
  },
  RUN_QUEST_SCRIPT: {
    isAdvanced: true,
    value: "RUN_QUEST_SCRIPT",
    label: "Run Quest Script",
    icon: "terminal-prompt",
    searchTerms: [],
  },
  CustomV2: {
    value: "CustomV2",
    label: "Custom",
    icon: "terminal-prompt",
    searchTerms: ["script", "code", "export", "api", "webhook", "app", "call"],
  },
};
export const completionActionTypeViewDataList = Object.values(
  completionActionTypeViewDataMap
) as CompletionActionTypeViewData[];

const CompletionActionOptionsDialog: React.FC<
  CompletionActionOptionsDialogProps
> = ({ completionAction, onCompletionActionChange, onModalClose }) => {
  const { advancedMode } = useQuestViewContext(["MANAGE"]);

  const onCompletionActionTypeSelect = (
    completionActionType: CompletionActionType
  ) => {
    Analytics.trackEvent("Select Completion Action Type", {
      completionActionType:
        completionActionTypeViewDataMap[completionActionType].label,
      // @deprecated - remove after Jan 1st, 2025
      rewardType: completionActionTypeViewDataMap[completionActionType].label,
    });

    const data = {};
    onCompletionActionChange({
      ...completionAction,
      data: data,
      prototype: {
        ...completionAction.prototype,
        type: completionActionType,
      },
    });
    onModalClose();
  };

  return (
    <DialogScrollView keyboardShouldPersistTaps="always">
      <View onStartShouldSetResponder={() => true}>
        <HeaderText size="small">Change action type</HeaderText>
        <CompletionActionTypeOption
          onPress={() => onCompletionActionTypeSelect("TEXT")}
          label={completionActionTypeViewDataMap["TEXT"].label}
          inactive={completionAction.prototype.type !== "TEXT"}
        />
        <CompletionActionTypeOption
          onPress={() => onCompletionActionTypeSelect("CustomV2")}
          label={completionActionTypeViewDataMap["CustomV2"].label}
          inactive={completionAction.prototype.type !== "CustomV2"}
        />
        <CompletionActionTypeOption
          onPress={() => onCompletionActionTypeSelect("POST_SLACK_MESSAGE")}
          label={completionActionTypeViewDataMap["POST_SLACK_MESSAGE"].label}
          inactive={completionAction.prototype.type !== "POST_SLACK_MESSAGE"}
        />
        <CompletionActionTypeOption
          onPress={() => onCompletionActionTypeSelect("TREMENDOUS_REWARD")}
          label={completionActionTypeViewDataMap["TREMENDOUS_REWARD"].label}
          inactive={completionAction.prototype.type !== "TREMENDOUS_REWARD"}
        />
        <CompletionActionTypeOption
          onPress={() => onCompletionActionTypeSelect("RUN_QUEST_SCRIPT")}
          label={completionActionTypeViewDataMap["RUN_QUEST_SCRIPT"].label}
          inactive={completionAction.prototype.type !== "RUN_QUEST_SCRIPT"}
        />
        {advancedMode && (
          <>
            <CompletionActionTypeOption
              onPress={() => onCompletionActionTypeSelect("CREATE_QUEST")}
              label={completionActionTypeViewDataMap["CREATE_QUEST"].label}
              inactive={completionAction.prototype.type !== "CREATE_QUEST"}
            />
          </>
        )}
      </View>
    </DialogScrollView>
  );
};

const CompletionActionTypeOption = ({
  onPress,
  label,
  inactive,
}: {
  onPress: () => void;
  label: string;
  inactive?: boolean;
}) => {
  return (
    <CompletionActionTypeOptionPressable onPress={onPress}>
      <Text size="large" $inactive={inactive}>
        {label}
      </Text>
    </CompletionActionTypeOptionPressable>
  );
};

const CompletionActionTypeOptionPressable = styled(Pressable)``;

const DialogScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 16,
  },
})``;

const HeaderText = styled(Text)`
  margin-bottom: 16px;
`;

export default CompletionActionOptionsDialog;
