export type HexColor = `#${string}`;

/**
 * Calculates the color created by overlapping two colors. The foreground color is overlaid on top of the background color.
 * Notes:
 *  - opacity of 0 will result in the background color being returned.
 *  - opacity of 1 will result in the foreground color being returned.
 *
 * @param backgroundColor
 * @param foregroundColor
 * @param foregroundOpacity
 */
export function overlapColors(
  backgroundColor: HexColor,
  foregroundColor: HexColor,
  foregroundOpacity: number
): HexColor {
  return rgbToHex(
    overlapColorsRGB(
      hexToRGB(backgroundColor),
      hexToRGB(foregroundColor),
      foregroundOpacity
    )
  );
}

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

function overlapColorsRGB(
  backgroundColor: RGBColor,
  foregroundColor: RGBColor,
  foregroundOpacity: number
) {
  return {
    r: calculateOverlapForChannel(
      backgroundColor.r,
      foregroundColor.r,
      foregroundOpacity
    ),
    g: calculateOverlapForChannel(
      backgroundColor.g,
      foregroundColor.g,
      foregroundOpacity
    ),
    b: calculateOverlapForChannel(
      backgroundColor.b,
      foregroundColor.b,
      foregroundOpacity
    ),
  };
}

/**
 * Inspired and co-opted from this Stack Overflow answer: https://stackoverflow.com/a/65233542/2703729
 */
function calculateOverlapForChannel(
  backgroundColor: number,
  foregroundColor: number,
  foregroundOpacity: number
) {
  // normalise the alpha channel across the foreground and background.
  const backgroundContribution = (1 - foregroundOpacity) * backgroundColor;
  const foregroundContribution = foregroundOpacity * foregroundColor;
  const combinedValue = backgroundContribution + foregroundContribution;

  // ensure we don't go over 255 for any channel.
  return Math.min(255, Math.round(combinedValue));
}

function rgbToHex(rgb: RGBColor): HexColor {
  return ("#" +
    componentToHex(rgb.r) +
    componentToHex(rgb.g) +
    componentToHex(rgb.b)) as HexColor;
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function hexToRGB(hex: HexColor): RGBColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error(`Invalid hex color: ${hex}. Failed to convert to RGB.`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export function hexToAndroidColorNumber(hex: HexColor): number {
  return parseInt(hex.substring(1), 16);
}
