import React from "react";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import {
  AvatarImage,
  defaultAvatar,
  UserListItem,
  UserPickerEntryProps,
} from "@app/components/questkit/UserList/StandardUserEntry";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";
import {
  ModalRenderFunctionProps,
  useModal,
} from "@app/components/modal/ModalManager";
import { WorkspaceMembershipRole } from "@questmate/openapi-spec";

export interface MemberEditModalProps
  extends Omit<MemberUserEntryProps, "renderEditModal">,
    ModalRenderFunctionProps {}

interface MemberUserEntryProps extends UserPickerEntryProps {
  role: WorkspaceMembershipRole | undefined;
  renderEditModal: (props: MemberEditModalProps) => React.ReactElement;
}

export const MemberUserEntry: React.FC<MemberUserEntryProps> = (props) => {
  const { entry, disabled, onAdd, onRemove, role, renderEditModal } = props;
  let data: {
    title: string;
    avatarUri: string;
  };

  switch (entry.status) {
    case "LOOKING_UP_USER":
      data = {
        title: entry.identifier.format(),
        avatarUri: defaultAvatar,
      };
      break;
    case "LOADING":
      data = {
        title: "...",
        avatarUri: defaultAvatar,
      };
      break;
    case "READY":
      data = {
        title: entry.displayName,
        avatarUri: entry.avatarSmallUrl ?? defaultAvatar,
      };
      break;
  }

  const isLoggedInUser =
    entry.status !== "LOOKING_UP_USER" && entry.isLoggedInUser;

  const name = isLoggedInUser ? "Myself" : data.title;
  const titleCaseRole = role
    ? role[0] + role.slice(1).toLowerCase()
    : undefined;

  const { openModal } = useModal((modalProps) =>
    renderEditModal({ ...modalProps, ...props })
  );

  return (
    <UserListItem
      isLoggedInUser={isLoggedInUser}
      text={`${name}${role ? ` (${titleCaseRole})` : ""}`}
      icon={
        <AvatarImage
          source={{
            uri: data.avatarUri,
          }}
        />
      }
      {...(isLoggedInUser && !entry.isInList
        ? {
            actionComponent: (
              <CheckboxToggle
                checked={entry.isInList}
                accessibilityHint={"Add Myself to the list"}
                action={() => {
                  if (!disabled) {
                    if (entry.isInList && !!role) {
                      onRemove();
                    } else {
                      onAdd();
                    }
                  }
                }}
              />
            ),
          }
        : {
            actionComponent:
              entry.status !== "READY" || !role ? (
                <QKActivityIndicator />
              ) : (
                <MemberEditButton title="Edit" onPress={openModal} />
              ),
          })}
    />
  );
};

const MemberEditButton = styled(Button)``;
