import styled from "styled-components/native";
import {
  HeaderBackButtonProps,
  HeaderButtonProps,
} from "@react-navigation/elements";
import React from "react";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

export const HeaderIcon = styled(PressableOpacity)`
  margin-horizontal: 16px;
  width: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.background};
  justify-content: center;
  align-items: center;
`;

export const PlaceholderHeaderIcon: React.FC<
  HeaderButtonProps | HeaderBackButtonProps
> = () => {
  return <EmptyView />;
};

const EmptyView = styled.View`
  width: 40px;
  // ensure placeholder covers any other icons from screens beneath this one
  height: 41px;
  margin-bottom: -1px;
`;
