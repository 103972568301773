import {
  EventScheduler,
  HandlerFiredEvent,
} from "@app/components/item/components/custom/v2/EventScheduler";
import _ from "lodash";

export class ActionHandlerCollection {
  private handlers = new Map<string, ActionHandlerFacade<unknown>>();

  constructor(private readonly eventScheduler: EventScheduler) {}

  get<T>(
    handlerId: string | undefined,
    options?: ActionHandlerOptions
  ): ActionHandlerFacade<T> {
    if (!handlerId) {
      return NoOpActionHandlerFacade.SINGLETON;
    }

    if (!this.handlers.has(handlerId)) {
      this.handlers.set(
        handlerId,
        new RealActionHandlerFacade(this.eventScheduler, handlerId, options)
      );
    }
    return this.handlers.get(handlerId) as ActionHandlerFacade<T>;
  }
}

interface ActionHandlerFacade<T> {
  handler: (payload: T) => void;
  lastEvent: HandlerFiredEvent<T> | undefined;
  isDebouncing: boolean;
  isInFlight: boolean;
  lastPayload: T | undefined;
}

type ActionHandlerOptions = {
  debounceMs?: number;
};

class RealActionHandlerFacade<T> implements ActionHandlerFacade<T> {
  private eventIdsThisSession: string[] = [];

  readonly handler: (payload: T) => void;
  private _isDebouncing = false;
  private _lastPayload: T | undefined;

  constructor(
    private readonly eventScheduler: EventScheduler,
    readonly handlerId: string,
    options?: ActionHandlerOptions
  ) {
    const debounceMs = options?.debounceMs;
    const debouncedQueue = _.debounce((payload: T) => {
      this.queueEventOrUpdatePayloadOfQueuedEvent(payload);
      this._isDebouncing = false;
    }, debounceMs);

    this.handler = (payload: T) => {
      this._lastPayload = payload;
      if (debounceMs) {
        this._isDebouncing = true;
        return debouncedQueue(payload);
      } else {
        this.queueEventOrUpdatePayloadOfQueuedEvent(payload);
      }
    };
  }

  get isDebouncing(): boolean {
    return this._isDebouncing;
  }

  get isInFlight(): boolean {
    return this.lastEvent?.isInFlight() ?? false;
  }

  get lastPayload(): T | undefined {
    return this._lastPayload;
  }

  private queueEventOrUpdatePayloadOfQueuedEvent(payload: T) {
    const lastEvent = this.lastEvent;
    if (lastEvent && lastEvent.status === "QUEUED") {
      // If we haven't sent a recently queued event yet, we can just update its payload until it is able to be
      // sent. This helps us avoid queueing up multiple handler fired events for text fields when one would
      // suffice.
      this.eventScheduler.modifyUnsentEvent<HandlerFiredEvent<T>>(
        lastEvent.id,
        (event) => {
          (event.payload as T) = payload;
        }
      );
    } else {
      const event = new HandlerFiredEvent(this.handlerId, payload);
      this.eventScheduler.enqueue(event);
      this.eventIdsThisSession.push(event.id);
    }
  }

  get lastEvent(): HandlerFiredEvent<T> | undefined {
    const lastEventId =
      this.eventIdsThisSession[this.eventIdsThisSession.length - 1];
    return this.eventScheduler.events.find(
      (e) => e.id === lastEventId
    ) as HandlerFiredEvent<T>;
  }
}

/**
 * This is a singleton that is used when the handlerId is not set.
 * It is used to prevent the need to check for nulls in the code.
 */
class NoOpActionHandlerFacade implements ActionHandlerFacade<void> {
  static SINGLETON = new NoOpActionHandlerFacade();

  private constructor() {
    // use the singleton instead
  }

  handler = () => undefined;

  get lastEvent() {
    return undefined;
  }

  get lastPayload() {
    return undefined;
  }

  get isDebouncing() {
    return false;
  }

  get isInFlight() {
    return false;
  }
}
