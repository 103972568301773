import React, { useCallback } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { useFocusableRef } from "@app/util/focus";
import Switch from "@app/components/questkit/switch";
import { SwitchComponentModel } from "@app/components/item/components/custom/types";

interface CustomItemSwitchComponentProps {
  component: SwitchComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemSwitchComponent: React.FC<
  CustomItemSwitchComponentProps
> = ({ component, readOnly }) => {
  const onSwitch = useCallback(() => {
    component.onSwitch(!component.value);
  }, [component]);

  return (
    <SwitchContainer>
      <TitleText size="medium">{component.title || "Switch"}</TitleText>
      <Switch
        readOnly={component.readOnly || readOnly}
        onSwitch={onSwitch}
        switchOn={component.value}
        loading={component.loading}
        switchStyle="USER"
      />
    </SwitchContainer>
  );
};

const TitleText = styled(Text)`
  flex-shrink: 1;
`;

const SwitchContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
