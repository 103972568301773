import { IconIdentifier } from "@app/components/icon";
import * as React from "react";
import { QuestStartTriggerType } from "@questmate/openapi-spec";

type QuestTypeInfo = {
  name: string;
  icon: IconIdentifier;
  useCases: string[];
  shortDescription: string;
  longDescription: string;
  features: (string | React.ReactElement)[];
};

export const QUEST_TYPES: Record<QuestStartTriggerType, QuestTypeInfo> = {
  BASIC: {
    name: "Start & Send Directly",
    icon: "play",
    useCases: ["Assignable Forms", "Employee Onboarding", "Task Checklists"],
    shortDescription:
      "A Quest you start whenever needed. Complete it yourself or assign it to others.",
    longDescription:
      "Best for when your Quest does not happen on a regular schedule and you need control over who is able and required to complete it.",
    features: [
      "Start and complete it as many times as needed.",
      "Assign and send to teammates, clients, or friends.",
      "Ensure it gets done with due dates and automated reminders.",
      "Automatically start when an event occurs in another app (Zapier, IFTTT, etc.)",
      "Provide granular access to your smart devices while maintaining control of who and how long access is permitted.",
      "Great for Quests that provide a gift card or cash payout for completing it.",
    ],
  },
  KIOSK: {
    name: "Kiosk",
    icon: "kiosk",
    useCases: [
      "Visitor Registration",
      "Customer Feedback",
      "Electronic Voting",
    ],
    shortDescription:
      "Quests that are always ready for someone to walk up and fill out.",
    longDescription:
      "Best for setting up Questmate on a public device (like a tablet) to let anyone fill out your Quest.",
    features: [
      "Step-by-step fillout view for easy completion.",
      "Quest automatically restarts after each submission.",
      "No logins required. All submissions are anonymous.",
      "Safe to run on public devices.",
    ],
  },
  PUBLIC: {
    name: "Public Share Link",
    icon: "link",
    useCases: [
      "Contact Form",
      "Order Form",
      "Public Poll",
      "Feedback Form",
      "Signup Form",
      "Raffle Registration",
    ],
    shortDescription:
      "Share a link or QR code for anyone to fill out your Quest.",
    longDescription:
      "Best for collecting anonymous submissions allowing anyone with the link to complete your Quest whenever they need to.",
    features: [
      "No login required, anyone with the link can start and fill out your Quest.",
      "All submissions are anonymous.",
      "Downloadable QR code to include in a poster, flyer, website, or documentation.",
    ],
  },
  SCHEDULE: {
    name: "Scheduled",
    icon: "schedule-send",
    useCases: [
      "Daily Routines",
      "Quarterly Reporting",
      "Team Standups",
      "Diet Tracking",
      "Gratitude Journal",
    ],
    shortDescription: "Ensure things are done on a consistent schedule.",
    longDescription:
      "Best for ensuring regularly occurring tasks are always done on time.",
    features: [
      "Hands-free automation based on a schedule you control.",
      "Automatically assign to yourself, teammates, clients, friends, or family.",
      "Ensure it gets done with due dates and automated reminders.",
      "Flexible scheduling options: daily, weekly, monthly, or custom.",
    ],
  },
};
