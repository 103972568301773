import React, { useCallback, useEffect, useMemo, useRef } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";

import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import {
  UserPicker,
  UserPickerController,
} from "@app/components/questkit/UserList/UserPicker";
import {
  isUserEntry,
  UserListEvents,
} from "@app/components/questkit/UserList/UserList.controller";
import { Analytics } from "@app/analytics";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { StandardUserEntry } from "@app/components/questkit/UserList/StandardUserEntry";

const changeUsers = (
  item: ItemRenderData,
  userEntries: { id: string }[]
): ItemRenderData => ({
  ...item,
  data: {
    userEntries,
  },
  version: item.version + 1,
});

export const UserPickerItem: React.FC<ItemBaseProps> = (props) => {
  // TODO block submit on inflight requests

  const userPickerRef = useRef<UserPickerController>(null);
  const userPickerController = userPickerRef.current;
  useEffect(() => {
    const addListener = (event: UserListEvents["add"]) => {
      event.entriesAdded.forEach((entry) => {
        Analytics.trackEvent("Add User in Item", {
          isThemself:
            isUserEntry(entry) && entry.userId === getLoggedInUserId(),
        });
      });
    };
    const removeListener = (event: UserListEvents["remove"]) => {
      event.entriesRemoved.forEach((entry) => {
        Analytics.trackEvent("Remove User in Item", {
          isThemself:
            isUserEntry(entry) && entry.userId === getLoggedInUserId(),
        });
      });
    };
    userPickerController?.on("add", addListener);
    userPickerController?.on("remove", removeListener);
    return () => {
      userPickerController?.off("add", addListener);
      userPickerController?.off("remove", removeListener);
    };
  }, [userPickerController]);

  const userEntries = useMemo(
    () =>
      ((props.item.data.userEntries as { id: string }[]) || []).map(
        ({ id }) => ({ userId: id })
      ),
    [props.item.data.userEntries]
  );
  const onChange = useCallback(
    (userIds: string[]) => {
      props.onItemChange?.(
        changeUsers(
          props.item,
          userIds.map((id) => ({
            id,
          }))
        )
      );
      props.onItemValidate?.();
    },
    [props]
  );
  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <UserPicker
          ref={userPickerRef}
          onChange={onChange}
          users={userEntries}
          disabled={props.readOnly}
          renderUserEntry={StandardUserEntry}
        />
      }
    />
  );
};
