import ListItem from "@app/components/questkit/listItem";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Pressable, View, ViewProps } from "react-native";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { IconIdentifier } from "@app/components/icon";
import { TouchBoundary } from "@app/util/TouchBoundary";
import Animated, {
  interpolate,
  measure,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

export interface Filter {
  name: string;
  id: string;
  type: string;
  icon?: IconIdentifier;
}

export interface FilterTypes {
  [filterKey: string]: {
    name: string;
    icon: IconIdentifier;
    exclusivity?: "none" | "type";
  };
}

export interface FiltersProps extends ViewProps {
  filters: { [filterType: string]: Filter[] };
  filterTypes: FilterTypes;
  activeFilters: { [filterId: string]: Filter };
  setActiveFilters: Dispatch<SetStateAction<{ [filterId: string]: Filter }>>;
  setFiltersFocused: (focused: boolean) => void;
  onPressFilter?: () => void;
  hideFilterList?: boolean;
}

export const Filters: React.FC<FiltersProps> = ({
  style,
  filters,
  filterTypes,
  activeFilters,
  setActiveFilters,
  setFiltersFocused,
  onPressFilter,
  hideFilterList,
}) => {
  const toggleFilter = useCallback(
    (filter: Filter) => {
      setActiveFilters((prevActiveFilters) => {
        let nextActiveFilters;
        if (activeFilters[filter.id]) {
          nextActiveFilters = {
            ...prevActiveFilters,
          };
          delete nextActiveFilters[filter.id];
        } else {
          if (filterTypes[filter.type].exclusivity === "type") {
            nextActiveFilters = Object.keys(prevActiveFilters).reduce(
              (acc, key) => {
                if (prevActiveFilters[key].type !== filter.type) {
                  acc[key] = prevActiveFilters[key];
                }
                return acc;
              },
              {
                [filter.id]: filter,
              } as typeof prevActiveFilters
            );
          } else {
            nextActiveFilters = {
              ...prevActiveFilters,
              [filter.id]: filter,
            };
          }
        }
        return nextActiveFilters;
      });
    },
    [activeFilters, filterTypes, setActiveFilters]
  );

  // For animating filter list
  // 0 - Closed
  // 1 - Open
  const animation = useSharedValue(0);

  useEffect(() => {
    if (hideFilterList) {
      animation.value = withTiming(0, { duration: 250 });
    } else {
      runOnUI(() => {
        "worklet";
        filterViewHeight.value = measure(filterViewRef)!.height;
        animation.value = withTiming(1, { duration: 250 });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideFilterList]);

  const filterViewRef = useAnimatedRef<Animated.View>();
  const filterViewHeight = useSharedValue(0);
  const collapsableFilterViewStyle = useAnimatedStyle(() => {
    return {
      height: interpolate(animation.value, [0, 1], [0, filterViewHeight.value]),
      opacity: animation.value,
    };
  }, []);

  // Memoized because it will always be rendered. Maybe could be broken down into smaller components.
  const filtersView = useMemo(() => {
    return (
      <>
        {Object.keys(filterTypes)
          .filter(
            (filterTypeKey) =>
              filters[filterTypeKey] && filters[filterTypeKey].length > 0
          )
          .map((filterTypeKey) => {
            return (
              <View key={filterTypeKey}>
                <SectionText size="small">
                  {filterTypes[filterTypeKey].name}
                </SectionText>
                <FilterList>
                  {filters[filterTypeKey].map((filter) => {
                    const isActive = !!activeFilters[filter.id];
                    return (
                      <Pressable
                        testID={`search-filter`}
                        key={filter.id}
                        onPressIn={() => {
                          setFiltersFocused(true);
                        }}
                        onPress={() => {
                          toggleFilter(filter);
                          if (onPressFilter) {
                            onPressFilter();
                          }
                          setFiltersFocused(false);
                        }}
                      >
                        <FilterText
                          size="large"
                          testID={`search-filter-${
                            isActive ? "active" : "inactive"
                          }`}
                          $inactive={!isActive}
                        >
                          {filter.name}
                        </FilterText>
                      </Pressable>
                    );
                  })}
                </FilterList>
              </View>
            );
          })}
      </>
    );
  }, [
    activeFilters,
    filterTypes,
    filters,
    onPressFilter,
    setFiltersFocused,
    toggleFilter,
  ]);

  return (
    <StyledTouchBoundary
      style={style}
      onTouchOutside={() => setFiltersFocused(false)}
    >
      <View>
        {Object.keys(activeFilters).map((activeFilterKey) => (
          <ActiveFilter
            key={activeFilterKey}
            text={activeFilters[activeFilterKey].name}
            icon={
              activeFilters[activeFilterKey].icon ||
              filterTypes[activeFilters[activeFilterKey].type].icon
            }
            actionIcon="close"
            onActionIconClick={() => {
              toggleFilter(activeFilters[activeFilterKey]);
            }}
          />
        ))}
      </View>
      <CollapsableFilterView style={collapsableFilterViewStyle}>
        <FilterView ref={filterViewRef}>{filtersView}</FilterView>
      </CollapsableFilterView>
    </StyledTouchBoundary>
  );
};

const StyledTouchBoundary = styled(TouchBoundary)`
  overflow: hidden;
`;
const FilterView = styled(Animated.View)`
  position: absolute;
  width: 100%;
  top: 0;
`;
const CollapsableFilterView = styled(Animated.View)`
  position: relative;
`;

const FilterText = styled(Text)`
  margin-left: 4px;
`;

const FilterList = styled.View`
  margin-bottom: 20px;
`;

const ActiveFilter = styled(ListItem)`
  border-color: ${({ theme }) => theme.primary}
  border-width: 1px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

export const SectionText = styled(Text)`
  margin-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
