import React, { useCallback } from "react";
import { GestureResponderEvent, Platform, Pressable } from "react-native";
import styled from "styled-components/native";
import Icon, { IconProps } from "@app/components/icon";

export interface DragHandlers {
  onDragStart: () => void;
  onDragHandleRelease: () => void;
}

interface IDragPanel extends Omit<IconProps, "icon"> {
  dragHandlers: DragHandlers;
}

export const DragPanel: React.FC<IDragPanel> = (props) => {
  const { dragHandlers, ...iconProps } = props;

  const onStartShouldSetResponderCapture = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault();
      dragHandlers.onDragStart();
      return true;
    },
    [dragHandlers]
  );
  const onResponderEnd = useCallback(
    (_event: GestureResponderEvent) => {
      dragHandlers.onDragHandleRelease();
    },
    [dragHandlers]
  );
  return (
    <DragHandle
      onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
      onResponderEnd={onResponderEnd}
    >
      <Icon
        icon="drag-panel"
        container={{ width: 40, height: 40 }}
        // offset aligns the icon with the checkbox
        offsetX={4}
        {...iconProps}
      />
    </DragHandle>
  );
};

const DragHandle = styled(Pressable)`
  ${Platform.OS === "web" && "cursor: move; user-select: none;"}
`;

export default DragPanel;
