import {
  parseCronFields,
  ParsedCronFields,
} from "@app/components/scheduler/CronUtils";
import cronstrue from "cronstrue";

const compareOutOfOrder = (
  arr1: readonly unknown[],
  arr2: readonly unknown[]
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const counts = new Map();
  arr1.forEach((value) => counts.set(value, (counts.get(value) ?? 0) + 1));
  arr2.forEach((value) => counts.set(value, (counts.get(value) ?? 0) - 1));
  return Array.from(counts.values()).every((count) => count === 0);
};

function identifyConfiguredFields(fields: ParsedCronFields) {
  const configuredFields = [];
  if (fields.minute.length !== 60) {
    configuredFields.push("minute");
  }
  if (fields.hour.length !== 24) {
    configuredFields.push("hour");
  }
  if (fields.dayOfMonth.length !== 31) {
    configuredFields.push("dayOfMonth");
  }
  if (fields.month.length !== 12) {
    configuredFields.push("month");
  }
  if (fields.dayOfWeek.length < 7) {
    configuredFields.push("dayOfWeek");
  }
  return configuredFields;
}

const dayOfWeekMap = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
};

export function formatCronString(runSchedule: string, shortForm: boolean) {
  if (shortForm) {
    const fields = parseCronFields(runSchedule);
    if (fields.valid) {
      const fieldsConfigured = identifyConfiguredFields(fields);
      if (
        compareOutOfOrder(fieldsConfigured, ["hour", "minute", "dayOfWeek"])
      ) {
        if (fields.hour.length === 1 && fields.minute.length === 1) {
          // If it runs once per day at a specific time
          let weekdayPart = "";

          // if (compareOutOfOrder(fields.dayOfWeek, [1, 2, 3, 4, 5])) {
          //   weekdayPart = "Weekdays";
          // } else {
          let multipleConsecutive = false;
          for (let i = 0; i < fields.dayOfWeek.length; i++) {
            const dayIndex = fields.dayOfWeek[i];
            const prevDayIndex: number | undefined = fields.dayOfWeek[i - 1];
            const nextDayIndex: number | undefined = fields.dayOfWeek[i + 1];
            if (prevDayIndex !== undefined) {
              if (dayIndex - prevDayIndex === 1) {
                // The previous day was consecutive
                if (
                  nextDayIndex !== undefined &&
                  nextDayIndex - dayIndex === 1
                ) {
                  // The next day is consecutive
                  multipleConsecutive = true;
                  continue;
                } else {
                  // The next day is not consecutive
                  weekdayPart += `${multipleConsecutive ? "-" : ", "}${
                    dayOfWeekMap[dayIndex]
                  }`;
                }
              } else {
                weekdayPart += `, ${dayOfWeekMap[dayIndex]}`;
              }
            } else {
              weekdayPart += `${dayOfWeekMap[dayIndex]}`;
            }
          }
          // }

          const hour = fields.hour[0] % 12;
          return `${weekdayPart} ${hour === 0 ? 12 : hour}${
            fields.minute[0] === 0
              ? ""
              : ":" + `${fields.minute[0]}`.padStart(2, "0")
          } ${fields.hour[0] < 12 ? "AM" : "PM"}`;
        }
      }
    }
  }
  try {
    return cronstrue.toString(runSchedule, {
      verbose: true,
    });
  } catch (error) {
    console.warn(`Failed to parse cron schedule '${runSchedule}'`, error);
    return "Invalid schedule";
  }
}
