import {
  DropdownWithModal,
  DropdownStyle,
  DropdownOption,
} from "@app/components/questkit/dropdownWithModal";
import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { ItemPickerComponentModel } from "@app/components/item/components/custom/types";
import { useItems } from "@app/quest/edit/items";

interface CustomItemItemPickerComponentProps {
  component: ItemPickerComponentModel;
  readOnly: boolean;
}

export const CustomItemItemPickerComponent: React.FC<
  CustomItemItemPickerComponentProps
> = ({ component, readOnly }) => {
  const { items } = useItems();

  const options: DropdownOption[] = items.map((item) => {
    const itemUniqueId = item.prototype.id.split(":")[1];
    return {
      icon: "item",
      value: itemUniqueId,
      name: item.prototype.name,
    };
  });
  return (
    <>
      {component.title ? (
        <CustomComponentTitle size="medium">
          {component.title}
        </CustomComponentTitle>
      ) : null}
      <DropdownWithModal
        optionNoun={"Item"}
        optionPluralNoun={"Items"}
        placeholderIcon={"item"}
        options={options}
        dropdownStyle={DropdownStyle.STANDARD}
        selectedOption={component.value}
        setSelectedOption={component.onSelect}
        disabled={readOnly}
        loadingOptions={component.loading}
      />
    </>
  );
};

const CustomComponentTitle = styled(Text)`
  margin-bottom: 10px;
`;
