import React, { useCallback, useRef } from "react";
import { useFocusableRef } from "@app/util/focus";
import { type AudioInlineComponentModel } from "@app/components/item/components/custom/types";
import QKIcon from "@app/components/questkit/icon";
import BasePressable from "@app/components/questkit/BasePressable";
import { useItemContext } from "@app/components/item/ItemContext";
import { useTrack } from "@app/audio/PlaylistContext";
import { Analytics } from "@app/analytics";
import { useAppSelector } from "@app/store";
import { selectItemPrototypeById } from "@app/store/cache/itemPrototypes";

interface CustomItemAudioInlineComponentProps {
  component: AudioInlineComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemAudioInlineComponent: React.FC<
  CustomItemAudioInlineComponentProps
> = ({ component }) => {
  const item = useItemContext();

  const itemName = useAppSelector(
    (state) => selectItemPrototypeById(state, item.prototypeId)?.name
  );

  const { play, pause, state } = useTrack({
    url: component.url,
    artist: component.artist ?? "Questmate",
    artwork: component.artwork,
    title: component.trackTitle ?? itemName,
    trackId: item.prototypeId,
    sortOrder: item.position,
  });

  const stateRef = useRef(state);
  stateRef.current = state;

  const onPress = useCallback(async () => {
    if (stateRef.current === "NOT_PLAYING") {
      Analytics.trackEvent("Play Audio", { itemPosition: item.position });
      void play();
    } else {
      Analytics.trackEvent("Pause Audio", { itemPosition: item.position });
      void pause();
    }
  }, [play, pause, item.position]);

  return (
    <BasePressable onPress={onPress}>
      <QKIcon
        name={state === "NOT_PLAYING" ? "play" : "pause"}
        loading={state === "PREPARING_TO_PLAY"}
      />
    </BasePressable>
  );
};
