import { Reducer } from "redux";
import produce from "immer";

export type PollerState = {
  pollerType: string;
  pollerId: string;
  promiseId: string;
  status: "POLLING" | "FINISHED";
  pollingParameters?: Record<string, unknown>;
};

type PollingState = {
  pollers: Record<SupportedPollerType, Record<string, PollerState>>;
};

export type SupportedPollerType =
  | "QuestExecution"
  | "ItemInstanceQuestExecutions";

type PollingStartedAction = {
  type: "polling/started";
  payload: {
    pollerType: SupportedPollerType;
    pollerId: string;
    promiseId: string;
    pollingParameters?: Record<string, unknown>;
  };
};

type PollingFinishedAction = {
  type: "polling/finished";
  payload: {
    pollerType: SupportedPollerType;
    pollerId: string;
    promiseId: string;
    pollingParameters?: Record<string, unknown>;
  };
};

type PollingAction = PollingStartedAction | PollingFinishedAction;

const initialState: PollingState = {
  pollers: {
    ItemInstanceQuestExecutions: {},
    QuestExecution: {},
  },
};

export const pollingReducer: Reducer<PollingState, PollingAction> = (
  state = initialState,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "polling/started": {
        const { pollerType, pollerId, promiseId, pollingParameters } =
          action.payload;
        draft.pollers[pollerType][pollerId] = {
          pollerType,
          pollerId,
          promiseId,
          pollingParameters,
          status: "POLLING",
        };
      }
    }
  });
};
