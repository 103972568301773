import React, { useCallback, useEffect, useRef } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import { Platform, Pressable } from "react-native";
import { Text } from "@app/components/questkit";
import styled from "styled-components/native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { CircularButton } from "@app/components/questkit/CircularButton";
import { SvgPaths } from "@app/components/item/components/signature/SvgPaths";
import SignaturePad from "@app/components/item/components/signature/SignaturePad";

const changeSignature = (
  item: ItemRenderData,
  svgPaths: string[]
): ItemRenderData => ({
  ...item,
  data: {
    svgPaths,
  },
  version: item.version + 1,
});

export const SignatureItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, readOnly, editMode } = props;
  const itemRef = useRef<ItemRenderData>();
  itemRef.current = item;
  const { svgPaths } = item.data;
  const [signing, setSigning] = React.useState(false);

  const [paths, setPaths, pathsRef] = useStateWithRef(svgPaths ?? []);
  useEffect(() => {
    if (svgPaths !== pathsRef.current) {
      setPaths(svgPaths);
    }
  }, [pathsRef, setPaths, svgPaths]);

  const onDone = useCallback(
    (newSvgPaths: string[]) => {
      setPaths(newSvgPaths);
      onItemChange?.(changeSignature(itemRef.current!, newSvgPaths));
      onItemValidate?.();
      setSigning(false);
    },
    [setPaths, onItemChange, onItemValidate]
  );

  const onChange = useCallback(
    (svgPaths: string[]) => {
      setPaths(svgPaths);
      onItemChange?.(changeSignature(itemRef.current!, svgPaths));
    },
    [setPaths, onItemChange]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <SignatureContainer>
          {!readOnly && signing ? (
            <SignaturePad paths={paths} onChange={onChange} onDone={onDone} />
          ) : (
            <SignatureView
              editMode={editMode}
              readOnly={!!readOnly}
              svgPaths={paths}
              onEdit={() => setSigning(true)}
            />
          )}
        </SignatureContainer>
      }
    />
  );
};

interface SignaturePreviewProps {
  svgPaths: string[];
  onEdit: () => void;
  readOnly: boolean;
  editMode: boolean;
}
const SignatureView: React.FC<SignaturePreviewProps> = ({
  svgPaths,
  onEdit,
  readOnly,
  editMode,
}) => {
  const tapAnywhereToEdit = (!svgPaths || svgPaths.length === 0) && !readOnly;
  const onContainerPress = useCallback(() => {
    if (tapAnywhereToEdit) {
      onEdit();
    }
  }, [onEdit, tapAnywhereToEdit]);

  return (
    <StyledPreviewContainer
      cursor={
        Platform.OS === "web" ? (tapAnywhereToEdit ? "pointer" : "default") : ""
      }
      onPress={onContainerPress}
    >
      <SvgPaths paths={svgPaths} />
      {readOnly ? (
        <>
          {!svgPaths || svgPaths.length === 0 ? (
            <CenterOverlay>
              {editMode ? (
                <StyledHintText>Signature will be captured here</StyledHintText>
              ) : (
                // preview mode
                <StyledText size={"medium"}>Tap to Sign</StyledText>
              )}
            </CenterOverlay>
          ) : null}
        </>
      ) : (
        <>
          {!svgPaths || svgPaths.length === 0 ? (
            <CenterOverlay>
              <StyledText size={"medium"}>Tap to Sign</StyledText>
            </CenterOverlay>
          ) : (
            <EditButton buttonTheme="primary" onPress={onEdit} icon="pen" />
          )}
        </>
      )}
    </StyledPreviewContainer>
  );
};

const CenterOverlay = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  user-select: none;
`;

const StyledHintText = styled(Text)`
  color: ${({ theme }) => theme.inactive};
  font-size: 20px;
  user-select: none;
`;
const EditButton = styled(CircularButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const StyledPreviewContainer = styled(Pressable)<{ cursor: string }>`
  ${({ cursor }) => (cursor ? `cursor: ${cursor}` : "")}
  position: relative;
  height: 100%;
`;

const SignatureContainer = styled.View`
  height: 200px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 20px;
  border-color: ${({ theme }) => theme.primary}
  border-width: 1px;
  overflow: hidden;
`;
