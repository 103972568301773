import * as Updates from "expo-updates";

import { setUpdateAvailable } from "@app/store/main";

import { store } from "../store";
import { sentry } from "@app/util/sentry";

export const initUpdateChecker = (): void => {
  if (Updates.isEmergencyLaunch) {
    sentry.captureMessage(
      "Expo update likely failed. App had emergency launch.",
      {
        extra: {
          manifest: Updates.manifest,
          updateId: Updates.updateId,
          channel: Updates.channel,
          releaseChannel: Updates.releaseChannel,
          runtimeVersion: Updates.runtimeVersion,
        },
      }
    );
  }
  store.dispatch(setUpdateAvailable(false));
  Updates.addListener((event) => {
    if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
      console.log("Update available.");
      store.dispatch(setUpdateAvailable(true));
    } else if (event.type === Updates.UpdateEventType.ERROR) {
      sentry.captureException(
        new Error("Error when checking for app update!"),
        {
          extra: {
            event,
            manifest: Updates.manifest,
            updateId: Updates.updateId,
            channel: Updates.channel,
            releaseChannel: Updates.releaseChannel,
            runtimeVersion: Updates.runtimeVersion,
          },
        }
      );
    }
  });
};
