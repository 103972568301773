import {
  QuestInstanceDetail,
  RewardInstance as RewardInstanceApiModel,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { questInstanceLoaded } from "@app/store/cache/questInstances";

export type CachedRewardInstance<T extends APIRewardInstance> = Omit<
  T,
  "prototype" | "formInstance"
> & {
  /**
   * Reward Prototype ID
   */
  prototypeId: string;
  formInstanceId: string;
};

type RewardInstance = CachedRewardInstance<
  UnionToIntersection<APIRewardInstance>
>;

const rewardInstanceAdapter = createEntityAdapter<RewardInstance, string>({
  selectId: (rewardInstance) =>
    `${rewardInstance.formInstanceId}|${rewardInstance.prototypeId}`,
});

export const {
  selectById: selectRewardInstanceByComboId,
  selectEntities: selectAllRewardInstancesById,
  selectAll: selectAllRewardInstances,
} = rewardInstanceAdapter.getSelectors<AppState>(
  (state) => state.cache.rewardInstances
);

const slice = createSlice({
  name: "cache/rewardInstances",
  initialState: rewardInstanceAdapter.getInitialState(),
  reducers: {
    rewardInstanceLoaded: (
      state,
      action: PayloadAction<
        RewardInstanceApiModel & {
          formInstance: { id: string };
        }
      >
    ) => {
      rewardInstanceAdapter.upsertOne(state, mapRewardInstance(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(questInstanceLoaded, (state, action) => {
        const rewards = action.payload.rewardInstances;
        if (rewards) {
          rewardInstanceAdapter.upsertMany(
            state,
            rewards.map((ii) =>
              mapRewardInstance({
                ...ii,
                formInstance: { id: action.payload.id },
              })
            )
          );
        }
      })
      // .addCase(questInstanceListLoaded, (state, action) => {
      //   const rewards = action.payload.flatMap((questInstance) =>
      //     questInstance.rewardInstances.map((ii) => ({
      //       ...ii,
      //       formInstance: { id: questInstance.id },
      //     }))
      //   );
      //   rewardInstanceAdapter.upsertMany(state, rewards.map(mapRewardInstance));
      // })
      .addCase(userLogout, (state) => rewardInstanceAdapter.removeAll(state));
  },
});

export const { rewardInstanceLoaded } = slice.actions;

const reducer = slice.reducer;
export default reducer;

type APIRewardInstance =
  | (QuestInstanceDetail["rewardInstances"][number] & {
      formInstance: { id: string };
    })
  // | (QuestInstanceListItem["rewardInstances"][number] & {
  //     formInstance: { id: string };
  //   })
  | (RewardInstanceApiModel & {
      formInstance: { id: string };
    });

const mapRewardInstance = createDataMapper<APIRewardInstance, RewardInstance>()(
  ["data", "status", "version"],
  {
    prototype: (prototype) =>
      prototype?.id ? { prototypeId: prototype.id! } : {},
    formInstance: (formInstance) =>
      formInstance?.id ? { formInstanceId: formInstance.id! } : {},
  }
);
