import * as React from "react";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import styled from "styled-components/native";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

interface OverlayManagerProviderProps extends PropsWithChildren {}
export const OverlayManagerProvider: React.FC<OverlayManagerProviderProps> = ({
  children,
}) => {
  const [showOverlay, setShowOverlay, showOverlayRef] = useStateWithRef(false);
  const [config, setConfig, configRef] =
    useStateWithRef<OverlayConfiguration | null>(null);

  const animation = useSharedValue(showOverlay ? 1 : 0);

  const value = useMemo(() => {
    return {
      renderOverlay: (config) => {
        setConfig(config);
        setShowOverlay(true);
        animation.value = withTiming(1, {
          duration: config?.fadeInMs ?? 300,
          easing: Easing.inOut(Easing.ease),
        });
      },
      hideOverlay: () => {
        if (showOverlayRef.current) {
          setShowOverlay(false);
          animation.value = withTiming(
            0,
            {
              duration: configRef.current?.fadeOutMs ?? 300,
              easing: Easing.inOut(Easing.ease),
            },
            (finished) => {
              if (finished) {
                runOnJS(setConfig)(null);
              }
            }
          );
        }
      },
    } satisfies IOverlayManagerContext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overlayStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        animation.value,
        [0, 1],
        [0, 0.8],
        Extrapolation.CLAMP
      ),
    };
  }, []);

  return (
    <OverlayManagerProviderContainer>
      <OverlayManagerContext.Provider value={value}>
        {children}
        <Overlay style={overlayStyle} showOverlay={showOverlay}>
          {config?.content}
        </Overlay>
      </OverlayManagerContext.Provider>
    </OverlayManagerProviderContainer>
  );
};

const Overlay = styled(Animated.View)<{ showOverlay: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  pointer-events: ${({ showOverlay }) => (showOverlay ? "all" : "none")};
`;

const OverlayManagerProviderContainer = styled.View`
  height: 100%;
  width: 100%;
  position: relative;
`;

type OverlayConfiguration = {
  content: React.ReactNode;
  fadeInMs?: number;
  fadeOutMs?: number;
};

interface IOverlayManagerContext {
  renderOverlay: (overlayConfig: OverlayConfiguration) => void;
  hideOverlay: () => void;
}

const OverlayManagerContext = createContext<IOverlayManagerContext>({
  renderOverlay: () => undefined,
  hideOverlay: () => undefined,
});

export const useOverlayManager = (): IOverlayManagerContext => {
  const context = useContext(OverlayManagerContext);
  if (!context) {
    throw new Error(
      "Missing OverlayManagerContext. Ensure we are rendering within an OverlayManagerProvider"
    );
  }

  return context;
};
