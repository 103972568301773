import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLogin, userLogout } from "@app/store/auth";
import { AppState } from "@app/store/index";
import { FilterIdsByGroupId } from "@app/quest/run/QuestRunsScreen";

export interface UIState {
  postLoginTargetUrl: string | null;
  kioskQuestPublicId: string | null;
  loggingOut: boolean;
  sideBar: {
    activeWorkspaceId: string | null;
  };
  questLastTab: Record<string, "QuestTemplate" | "QuestRuns">;
  questRunFilters: Record<string, FilterIdsByGroupId>;
}

const INITIAL_STATE = {
  postLoginTargetUrl: null,
  kioskQuestPublicId: null,
  loggingOut: false,
  sideBar: {
    activeWorkspaceId: null,
  },
  questRunFilters: {},
  questLastTab: {},
} as UIState;

export const selectActiveWorkspaceId = (state: AppState) =>
  state.ui.sideBar.activeWorkspaceId;

const slice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    setKioskQuestPublicId: (state, action: PayloadAction<string | null>) => {
      state.kioskQuestPublicId = action.payload;
    },
    setActiveWorkspace: (state, action: PayloadAction<string | null>) => {
      state.sideBar.activeWorkspaceId = action.payload;
    },
    setPostLoginTargetUrl: (state, action: PayloadAction<string>) => {
      state.postLoginTargetUrl = action.payload;
    },
    removePostLoginTargetUrl: (state) => {
      state.postLoginTargetUrl = null;
    },
    setLoggingOut: (state, action: PayloadAction<boolean>) => {
      state.loggingOut = action.payload;
    },
    setQuestRunFilters: (
      state,
      action: PayloadAction<{ questId: string; filters: FilterIdsByGroupId }>
    ) => {
      const cleanedFilters = Object.fromEntries(
        Object.entries(action.payload.filters).filter(
          ([_groupId, filterIds]) =>
            Array.isArray(filterIds) && filterIds.length > 0
        )
      );
      if (Object.keys(cleanedFilters).length === 0) {
        delete state.questRunFilters[action.payload.questId];
      } else {
        state.questRunFilters[action.payload.questId] = cleanedFilters;
      }
    },
    setQuestLastTab: (
      state,
      action: PayloadAction<{
        questId: string;
        lastTab?: "QuestTemplate" | "QuestRuns";
      }>
    ) => {
      if (action.payload.lastTab === undefined) {
        delete state.questLastTab[action.payload.questId];
      } else {
        state.questLastTab[action.payload.questId] = action.payload.lastTab;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogout, (state) => {
        state.sideBar.activeWorkspaceId = null;
        state.questRunFilters = {};
      })
      .addCase(userLogin, (state) => {
        state.kioskQuestPublicId = null;
      });
  },
});
export const {
  setQuestRunFilters,
  setQuestLastTab,
  setKioskQuestPublicId,
  setActiveWorkspace,
  setPostLoginTargetUrl,
  setLoggingOut,
  removePostLoginTargetUrl,
} = slice.actions;

const reducer = slice.reducer;
export default reducer;
