import React, { useCallback, useRef } from "react";

import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { apiRequest } from "@app/util/client";
import { useFocusableRef } from "@app/util/focus";
import { InlineErrorWithRetry } from "@app/components/item/components/custom/InlineErrorWithRetry";
import { CustomItemComponents } from "@app/components/item/components/custom/ComponentsRenderer";
import { CustomItemV1Response } from "@app/components/item/components/custom/types";
import { ItemBaseProps } from "@app/components/item/components/itemContainer";
import { useV1View } from "@app/components/item/components/custom/v1/useV1View";
import { useEffectOnce } from "@app/util/useEffectOnce";

type CustomItemFilloutViewProps = Pick<
  ItemBaseProps,
  "item" | "onItemChange" | "readOnly"
>;

export const CustomItemV1FilloutView: React.FC<CustomItemFilloutViewProps> = (
  props
) => {
  const { item, onItemChange, readOnly } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const questContext = useQuestViewContext(["RUN"]);
  const focusRefCallback = useFocusableRef(props.item.prototype.id);

  const renderFilloutView = useCallback(
    (filloutData: Record<string, unknown>) => {
      return executeCustom(
        questContext.questInstanceId,
        itemRef.current.prototype.id,
        { filloutData },
        questContext.publicQuestSessionToken
      );
    },
    [questContext.publicQuestSessionToken, questContext.questInstanceId]
  );

  const saveItemState = useCallback(
    (state: Record<string, unknown>) => {
      onItemChange?.({
        ...itemRef.current,
        data: state,
        version: itemRef.current.version + 1,
      });
    },
    [onItemChange]
  );

  const view = useV1View(renderFilloutView, item.data, saveItemState);

  useEffectOnce(() => {
    void view.reload();
  });

  return (
    <>
      {view?.rawResponse && view.rawResponse.status !== "ok" ? (
        <InlineErrorWithRetry
          message="Error retrieving item."
          isLoading={view.isLoading}
          onRetry={view.reload}
        />
      ) : (
        <CustomItemComponents
          autoFocusRef={focusRefCallback}
          components={view.components}
          isLoading={view.isLoading}
          hasError={view.hasError}
          readOnly={!!readOnly}
          onErrorRetry={view.reload}
        />
      )}
    </>
  );
};

async function executeCustom(
  questInstanceId: string,
  itemPrototypeId: string,
  body: unknown,
  publicSessionToken?: string
) {
  return apiRequest<CustomItemV1Response>(
    "POST",
    `/questInstances/${questInstanceId}/item/${questInstanceId}%7C${itemPrototypeId}/custom`,
    body,
    publicSessionToken
  );
}
