import { createRequest } from "@app/util/client/requests/index";
import {
  TremendousCampaign,
  TremendousFundingSource,
} from "@app/components/completionAction/components/TremendousCard";

export const fetchTremendousCampaigns = () => {
  return createRequest<{
    campaigns: TremendousCampaign[];
  }>("get", "/apps/tremendous/api/campaigns")();
};
export const fetchTremendousFundingSources = () => {
  return createRequest<{
    funding_sources: TremendousFundingSource[];
  }>("get", "/apps/tremendous/api/funding_sources")();
};
