import { apiRequest, QMApiError } from "@app/util/client";
import useSWR, { Fetcher, Key, SWRConfiguration, SWRResponse } from "swr";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";
import { useCallback, useMemo } from "react";

export const apiRequestWithOnSuccess =
  <T>(onSuccess?: (response: T) => void) =>
  (apiRequestParams: Parameters<typeof apiRequest>) => {
    return apiRequest<T>(...apiRequestParams).then((response) => {
      onSuccess?.(response);
      return response;
    });
  };

interface CreateRequestResult<DATA = unknown> {
  execute: () => Promise<DATA>;
  swrParams: [key: Key, fetcher: Fetcher<DATA> | null];
}

export const createRequest =
  <DATA>(...requestParams: Parameters<typeof apiRequest>) =>
  (onSuccess?: (response: DATA) => void): CreateRequestResult<DATA> => {
    const fetcher = apiRequestWithOnSuccess(onSuccess);
    return {
      execute: () => fetcher(requestParams),
      swrParams: [
        requestParams,
        fetcher as Fetcher<DATA, Parameters<typeof apiRequest>>,
      ],
    };
  };

export const noopRequest = <T = undefined>(
  ...args: [result?: T]
): CreateRequestResult<T> => {
  const result = Promise.resolve(
    args.length === 1 ? args[0] : undefined
  ) as Promise<T>;

  // https://swr.vercel.app/docs/conditional-fetching#conditional
  const swrKeyToAvoidRequestingAndCaching = null;

  return {
    execute: () => result,
    swrParams: [swrKeyToAvoidRequestingAndCaching, () => result],
  };
};

type NarrowedResponse<T extends CreateRequestResult> =
  T extends CreateRequestResult<infer DATA> ? DATA : never;

export type UseRequestResult<T> = SWRResponse<T, QMApiError> & {
  refresh: () => Promise<void>;
  hasData: boolean;
  hasError: boolean;
};
export const useRequest = <T extends CreateRequestResult>(
  request: T,
  swrOptions?: SWRConfiguration
): UseRequestResult<NarrowedResponse<T>> => {
  const result = useSWR(...request.swrParams, swrOptions);
  const data = useIsEqualMemo(result.data as NarrowedResponse<T>);
  const error = useIsEqualMemo(result.error);
  const mutate = result.mutate;

  const refresh = useCallback(async () => {
    await mutate();
  }, [mutate]);

  const returnValue = useMemo(
    () => ({
      isValidating: result.isValidating,
      isLoading: result.isLoading,
      hasData: !!data,
      hasError: !!error,
      mutate,
      refresh,
      data,
      error,
    }),
    [result.isValidating, result.isLoading, data, error, mutate, refresh]
  );

  return useIsEqualMemo(returnValue);
};
