import React from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { TextComponentModel } from "@app/components/item/components/custom/types";

interface CustomItemTextComponentProps {
  component: TextComponentModel;
}

export const CustomItemTextComponent: React.FC<
  CustomItemTextComponentProps
> = ({ component }) => {
  return (
    <TextContainer>
      {component.title && (
        <TitleText size="medium">{component.title}</TitleText>
      )}
      <Text $warning={component.contentColor === "warning"}>
        {component.content}
      </Text>
    </TextContainer>
  );
};

const TextContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleText = styled(Text)`
  margin-right: 20px;
`;
