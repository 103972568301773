import * as React from "react";
import { useCallback, useContext, useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import { QRCode } from "@app/components/QRCode";
import Text from "@app/components/questkit/text";
import { Platform, Pressable } from "react-native";
import { Analytics } from "@app/analytics";
import QKModal from "@app/components/modal";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import * as Clipboard from "expo-clipboard";
import { downloadQRCodeImage } from "@app/components/QRCodeDownloader";
import { InfoText } from "@app/components/questkit/InfoText";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { sentry } from "@app/util/sentry";
import { useLink } from "@app/util/link.utils";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { useAppSelector } from "@app/store";

type KioskStartViewProps = {
  startTrigger: {
    id: string;
    type: QuestStartTriggerType;
    config: Record<string, unknown>;
    enabled: boolean;
  };
  openStartQuestModal: () => void;
  startChangingQuestType: () => void;
  readOnly: boolean;
};

export const KioskStartView: React.FC<KioskStartViewProps> = ({
  startTrigger,
  openStartQuestModal,
  startChangingQuestType,
  readOnly,
}) => {
  const { questPrototypeId } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const questName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name
  );

  const { publicId, publicUrl } = startTrigger.config as {
    publicId: string;
    publicUrl: string;
  };
  const goToPublicUrl = useLink(
    { screen: "PublicAssignment", params: { id: publicId } },
    {
      onPressHook: () =>
        Analytics.trackEvent("Open Public Url", {
          questPrototypeId: questPrototypeId,
        }),
    }
  );
  const snackbar = useContext(SnackbarContext);

  const downloadQRCode = useCallback(() => {
    Analytics.trackEvent("Download QR Code", {
      questPrototypeId: questPrototypeId,
    });
    downloadQRCodeImage(publicUrl, `${questName} QR Code`);
    setShowQrCodeModal(false);
  }, [questPrototypeId, publicUrl, questName]);

  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const onCopyLink = useCallback(() => {
    Analytics.trackEvent("Copy Public Url to Clipboard", {
      questPrototypeId: questPrototypeId,
      actionLocation: "QR Code Modal",
    });
    Clipboard.setStringAsync(publicUrl)
      .then(() => {
        snackbar.sendMessage("Link copied to clipboard");
      })
      .catch((e) => {
        snackbar.sendMessage(
          "Failed to copy link to clipboard",
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            message: "Failed to copy Public Quest link to clipboard",
            publicUrl,
          },
        });
      });
  }, [questPrototypeId, publicUrl, snackbar]);
  const openQRCodeModal = useCallback(() => {
    Analytics.trackEvent("Open Public QR Code Dialog", {
      questPrototypeId: questPrototypeId,
    });
    setShowQrCodeModal(true);
  }, [questPrototypeId]);

  if (readOnly) {
    return (
      <StartTriggerSummary
        icon={QUEST_TYPES["KIOSK"].icon}
        text={"Kiosk Mode"}
      />
    );
  } else {
    return (
      <>
        <StartTriggerCard
          title={QUEST_TYPES["KIOSK"].name}
          icon={QUEST_TYPES["KIOSK"].icon}
          startChangingQuestType={startChangingQuestType}
        >
          {/*<ListItem*/}
          {/*  icon="printer"*/}
          {/*  onPress={openQRCodeModal}*/}
          {/*  text={<LinkText $underlined>Touchless Start (QR Code)</LinkText>}*/}
          {/*/>*/}
          {/*<ListItem*/}
          {/*  icon="play"*/}
          {/*  onPress={goToPublicUrl}*/}
          {/*  text={<LinkText $underlined>Run once</LinkText>}*/}
          {/*/>*/}
          <StartButton
            onPress={openStartQuestModal}
            title={"Enter Kiosk Mode"}
            testID="open-start-quest-modal-button"
          />
          <ButtonRow>
            <ButtonRowButtonButton
              title="Show QR Code"
              onPress={openQRCodeModal}
              buttonType="secondary"
            />
            <ButtonRowButtonButton
              title="Run once now"
              onPress={goToPublicUrl}
              buttonType="secondary"
            />
          </ButtonRow>
        </StartTriggerCard>
        <QKModal
          testID="qr-code-modal"
          showModal={showQrCodeModal}
          setShowModal={setShowQrCodeModal}
          title="Touchless Start"
        >
          <QrCodeScrollView>
            <StyledQRCode size={300} value={publicUrl} />
            <InfoText text="Print this code to allow users to complete the Quest from their own device." />
            <DownloadQRCodeButton
              onPress={downloadQRCode}
              title="Download QR Code"
            />
            <StyledLink onPress={onCopyLink}>
              <LinkText $underlined>Copy Link</LinkText>
            </StyledLink>
          </QrCodeScrollView>
        </QKModal>
      </>
    );
  }
};

const QrCodeScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 12,
    alignItems: "center",
  },
})``;

const StyledQRCode = styled(QRCode)`
  width: 100%;
`;

const DownloadQRCodeButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 15px;
`;

const StyledLink = styled(Pressable)`
  align-self: center;
  margin-bottom: 15px;
`;

const StartButton = styled(Button)`
  margin-top: 20px;
`;
const ButtonRowButtonButton = styled(Button)`
  margin-top: 20px;
  flex: 1;
`;

const ButtonRow = styled.View`
  flex-direction: row;
  gap: 10px;
`;

const LinkText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
