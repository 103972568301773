import { CronFields, fieldsToExpression, parseExpression } from "cron-parser";
import _ from "lodash";

export type ParsedCronFields = CronFields & { valid: boolean };
export type CronString = string;

export function parseCronFields(cronString: CronString): ParsedCronFields {
  try {
    const fields = parseExpression(cronString).fields;
    return {
      ...fields,
      valid: true,
    };
  } catch (_e) {
    return {
      valid: false,
      second: [],
      minute: [],
      hour: [],
      dayOfWeek: [],
      dayOfMonth: [],
      month: [],
    };
  }
}

export function buildUpdatedCronString(
  previousCronFields: ParsedCronFields,
  newCronFields: Partial<CronFields>
): CronString | null {
  try {
    let startingFields;
    if (previousCronFields.valid) {
      startingFields = previousCronFields;
    } else {
      startingFields = parseExpression("* * * * *").fields;
    }

    const updatedFields = _.defaults(
      newCronFields,
      startingFields
    ) as CronFields;

    return fieldsToExpression(updatedFields).stringify(false);
  } catch (e) {
    console.error("Failed to build Cron string:", e.message);
    return null;
  }
}
