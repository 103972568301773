import React from "react";
import { View, StyleSheet, Animated, Platform } from "react-native";

// Typescript version of https://github.com/ArtemKosiakevych/react-native-three-dots-loader/blob/master/index.js

const SIZE = 7;
const MARGIN = 5;
const BG = "rgb(172, 172, 172)";
const ACTIVE_BG = "#808184";
const dots = [1, 2, 3];
const INTERVAL = 300;
const ANIMATION_DURATION = 400;
const ANIMATION_SCALE = 1.4;
export default class ThreeDotsLoader extends React.Component<
  Partial<Omit<DotProps, "active">>
> {
  interval: NodeJS.Timer;
  state = {
    active: 1,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const active = this.state.active;
      this.setState({ active: active > 2 ? 1 : active + 1 });
    }, INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const active = this.state.active;
    return (
      <View style={styles.main}>
        {dots.map((i) => (
          <Dot {...this.props} key={i} active={i === active} />
        ))}
      </View>
    );
  }
}

interface DotProps {
  active: boolean;
  animationDuration?: number;
  animationScale: number;
  size: number;
  background: string;
  activeBackground: string;
  dotMargin: number;
}

class Dot extends React.Component<DotProps> {
  static defaultProps = {
    size: SIZE,
    background: BG,
    activeBackground: ACTIVE_BG,
    dotMargin: MARGIN,
    animationDuration: ANIMATION_DURATION,
    animationScale: ANIMATION_SCALE,
  };

  scale: Animated.Value;

  constructor(props: DotProps) {
    super(props);
    this.scale = new Animated.Value(1);
  }

  componentDidMount() {
    if (this.props.active) this.scaleUp();
  }

  componentDidUpdate(prevProps: DotProps) {
    if (prevProps.active && !this.props.active) {
      this.scaleDown();
    }
    if (!prevProps.active && this.props.active) {
      this.scaleUp();
    }
  }

  scaleDown = () => {
    Animated.timing(this.scale, {
      toValue: 1,
      duration: this.props.animationDuration,
      useNativeDriver: Platform.OS !== "web",
      isInteraction: false,
    }).start();
  };

  scaleUp = () => {
    Animated.timing(this.scale, {
      toValue: this.props.animationScale,
      duration: this.props.animationDuration,
      useNativeDriver: Platform.OS !== "web",
      isInteraction: false,
    }).start();
  };

  render() {
    const { active, size, background, activeBackground, dotMargin } =
      this.props;
    const style = {
      height: size,
      width: size,
      borderRadius: size / 2,
      marginHorizontal: dotMargin,
      backgroundColor: active ? activeBackground : background,
    };
    return (
      <Animated.View style={[style, { transform: [{ scale: this.scale }] }]} />
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
