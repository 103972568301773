import React from "react";
import ReactQRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";

export const downloadQRCodeImage = (value: string, fileNamePrefix: string) => {
  const qrCodeString = createQRCodeSVGFileContents(value);

  const ab = new ArrayBuffer(qrCodeString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < qrCodeString.length; i++) {
    ia[i] = qrCodeString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: "image/svg+xml" });

  downloadBlob(blob, `${fileNamePrefix || "qr-code"}.svg`);
};

function downloadBlob(blob: Blob, filename: string) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

const createQRCodeSVGFileContents = (url: string) => {
  const qrCodeElement = (
    <ReactQRCode size={500} value={url} fgColor={"#000000"} />
  );
  return ReactDOMServer.renderToStaticMarkup(qrCodeElement);
};
