import React, { useCallback } from "react";
import { useFocusableRef } from "@app/util/focus";
import { CheckableInlineComponentModel } from "@app/components/item/components/custom/types";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import { Platform } from "react-native";
import * as Haptics from "expo-haptics";

interface CustomItemCheckableInlineComponentProps {
  component: CheckableInlineComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemCheckableInlineComponent: React.FC<
  CustomItemCheckableInlineComponentProps
> = ({ component, readOnly }) => {
  const onChange = component.onChange;
  const value = component.value;
  const action = useCallback(() => {
    if (Platform.OS === "ios") {
      void Haptics.selectionAsync();
    }
    onChange(!value);
  }, [onChange, value]);

  return (
    <CheckboxToggle
      readOnly={component.disabled || readOnly}
      action={action}
      checked={value}
    />
  );
};
