import styled from "styled-components/native";

export const Boundary = styled.View<{ paddingHorizontal?: number }>`
  max-width: ${({ paddingHorizontal }) =>
    paddingHorizontal ? 540 + paddingHorizontal * 2 : 540}px;
  ${({ paddingHorizontal }) =>
    paddingHorizontal ? `padding-horizontal: ${paddingHorizontal}px;` : ""}
  align-self: center;
  width: 100%;
`;
