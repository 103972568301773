import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "@app/store/index";
import { LoginResponse } from "@questmate/openapi-spec";

const INITIAL_STATE: AuthState = {
  accessToken: undefined,
  loggedInUserId: null,
};

export interface AuthState {
  accessToken?: string;
  loggedInUserId: string | null;
}

const slice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    userLogin: (
      state,
      action: PayloadAction<{
        user: Exclude<LoginResponse["user"], null>;
        accessToken: string;
      }>
    ) => {
      state.loggedInUserId = action.payload.user.id;
      state.accessToken = action.payload.accessToken;
    },
    userLogout: (state) => {
      state.loggedInUserId = null;
      state.accessToken = undefined;
    },
    accessTokenRefreshed: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

const reducer = slice.reducer;
export default reducer;

export const { userLogin, userLogout, accessTokenRefreshed } = slice.actions;

export const selectLoggedInUserId = (state: AppState) =>
  state.auth.loggedInUserId;

export const selectUserIsLoggedIn = (state: AppState) =>
  Boolean(state.auth.loggedInUserId);
