import React, { useContext, RefCallback, useCallback, useMemo } from "react";

export interface Focusable {
  focus(): void;
  blur(): void;
}

interface FocusManagerContextValue {
  deferredFocusOnKey: undefined | string;
  refMap: Map<string, Focusable>;
}

const FocusManagerContext = React.createContext<FocusManagerContextValue>({
  deferredFocusOnKey: undefined,
  refMap: new Map(),
});

export function useFocusableRef<T extends Focusable>(
  key: string
): RefCallback<T> {
  const context = useContext(FocusManagerContext);

  return useCallback(
    (element: T | null) => {
      if (element !== null) {
        context.refMap.set(key, element);
        if (context.deferredFocusOnKey === key) {
          context.deferredFocusOnKey = undefined;
          element.focus();
        }
      } else {
        context.refMap.delete(key);
      }
    },
    [context, key]
  );
}

export interface FocusController {
  focus(key: string): void;
  blur(key: string): void;
}

export function useFocusController(): FocusController {
  const context = useContext(FocusManagerContext);
  return useMemo(
    () => ({
      focus(key: string) {
        const element = context.refMap.get(key);
        if (element) {
          element.focus();
        } else {
          context.deferredFocusOnKey = key;
        }
      },
      blur(key: string) {
        const element = context.refMap.get(key);
        if (element) {
          element.blur();
        } else if (context.deferredFocusOnKey === key) {
          context.deferredFocusOnKey = undefined;
        }
      },
    }),
    [context]
  );
}
