import Button from "@app/components/questkit/button";
import QKCalendar, {
  CalendarDateInput,
} from "@app/components/questkit/calendar";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import dateFormat from "dateformat";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TextInput, View } from "react-native";
import styled from "styled-components/native";
import { TimeInputField } from "@app/components/timeInput/TimeInputField";
import { format, parse } from "date-fns";
import { useEffectOnce } from "@app/util/useEffectOnce";

const dateValidation =
  /^([1-2][0-9][0-9][0-9])\/(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/;

interface CalendarModalProps {
  onDone: (date: string) => void;
  value: Date | null;
  time?: boolean;
}

const CalendarModal: React.FC<CalendarModalProps> = ({
  onDone,
  value,
  time: captureTime = false,
}) => {
  const [initialDateString, initialHour, initialMinute] = useMemo(() => {
    return value
      ? [format(value, "yyyy/MM/dd"), value.getHours(), value.getMinutes()]
      : [null, null, null];
  }, [value]);

  const snackbarContext = useContext(SnackbarContext);

  const dateRef = useRef<TextInput>(null);

  const hasDateRef = !!dateRef.current;
  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.focus();
    }
  }, [hasDateRef]);

  const today = useRef(dateFormat(new Date(), "qmDate")).current;
  const [markedDate, setMarkedDate] = useState(
    initialDateString ? initialDateString : today
  );
  const [dateKey, setDateKey] = useState<string>(
    today.slice(0, 4) + today.slice(5, 7)
  ); // This will be in the form of YYYYMM

  const [time, setTime] = useState<[number, number]>(() => {
    const startingHour = initialHour ? initialHour : 9;
    const startingMinute = initialMinute ? initialMinute : 0;
    return [startingHour, startingMinute];
  });

  const onDateChange = (text: string) => {
    const cleanedText = text.replace(/[^0-9/]/g, "");

    setMarkedDate(cleanedText);
    return cleanedText;
  };

  const onTimeChange = useCallback((hour: number, minute: number) => {
    setTime([hour, minute]);
  }, []);

  const onDateSubmit = (text: string | null) => {
    if (text === null) {
      text = today;
    }

    if (dateValidation.test(text)) {
      const dateKey = text.slice(0, 4) + text.slice(5, 7);

      setMarkedDate(text);
      setDateKey(dateKey);
      return true;
    } else {
      const dateKey = today.slice(0, 4) + today.slice(5, 7);

      setMarkedDate(today);
      setDateKey(dateKey);

      snackbarContext.sendMessage(
        "Wrong date input format",
        SnackbarSeverity.WARNING
      );
      return false;
    }
  };

  const onSaveDateTime = () => {
    if (onDateSubmit(markedDate)) {
      const date = markedDate || today;
      // Save to date then isostring
      const [years, months, days] = date.split("/").map((val) => parseInt(val));
      // Months minus 1 because JavaScript Date start from 0
      let result = new Date(
        years,
        months - 1,
        days,
        time[0] === null ? undefined : time[0],
        time[1] === null ? undefined : time[1]
      ).toISOString();
      result = result.slice(0, result.indexOf(".")) + "Z";
      onDone(result);
    }
  };

  const onSaveDate = (text: string) => {
    if (onDateSubmit(text)) {
      onDone(text || today);
    }
  };

  useEffectOnce(() => {
    if (!markedDate) {
      setDateKey(today);
      setMarkedDate(today);
    }
  });

  let selectedDateInCalendarView = today;
  if (typeof markedDate === "string") {
    try {
      selectedDateInCalendarView = format(
        parse(markedDate, "yyyy/MM/dd", new Date()),
        "yyyy-MM-dd"
      );
    } catch (_e) {
      // ignore invalid date formats that cannot be parsed/formatted
    }
  }

  return (
    <Wrapper keyboardShouldPersistTaps="always">
      <View onStartShouldSetResponder={() => true}>
        <DateAndTimeContainer>
          <CalendarDateInput
            iconName="date"
            onChangeText={onDateChange}
            onSubmit={(text) => {
              captureTime ? onDateSubmit(text) : onSaveDate(text);
            }}
            ref={dateRef}
            value={markedDate !== null ? markedDate : today}
          />
          {captureTime && (
            <>
              <Spacer />
              <TimeInputField
                hour={time[0]}
                minute={time[1]}
                onTimeChange={onTimeChange}
              />
            </>
          )}
        </DateAndTimeContainer>
        <StyledQKCalendar
          key={dateKey}
          selectedDate={selectedDateInCalendarView}
          setSelectedDate={(dateString: string) => {
            const formattedDate = format(new Date(dateString), "yyyy/MM/dd");
            if (!captureTime && onDateSubmit(formattedDate)) {
              onDone(formattedDate);
            } else {
              setMarkedDate(formattedDate);
            }
          }}
        />
        <StyledSaveButton
          onPress={() => {
            captureTime ? onSaveDateTime() : onSaveDate(markedDate);
          }}
          success={false}
          loading={false}
          disabled={false}
          title={`Done`}
        />
      </View>
    </Wrapper>
  );
};

const DateAndTimeContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSaveButton = styled(Button)`
  align-self: center;
  width: 100%;
  margin-top: 16px;
`;

const StyledQKCalendar = styled(QKCalendar)`
  margin-vertical: 16px;
`;

const Wrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 16,
  },
})``;

const Spacer = styled.View`
  width: 8px;
`;

export { CalendarModal as default };
