import produce, { applyPatches, Draft, Patch, produceWithPatches } from "immer";
import _ from "lodash";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useCallback, useEffect, useRef } from "react";
import { uuid } from "@app/util/uuid";
import { Objectish } from "immer/dist/types/types-internal";
import { sentry } from "@app/util/sentry";
import isEqual from "react-fast-compare";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

type ChangeStatus = "unsaved" | "pending" | "saved";

interface Change {
  id: string;
  patches: Patch[];
  inversePatches: Patch[];
  status: ChangeStatus;
}

export type ChangeSet<T extends Objectish | null | undefined> = {
  hasUnsavedChanges: boolean;
  changesByStatus: Record<ChangeStatus, string[]>;
  valueWithChanges: T;
  getChangesForPath: (path: string[], status?: ChangeStatus) => string[];
  markPending: (changeIds?: string[]) => string[];
  markSaved: (changeIds?: string[]) => string[];
  markUnsaved: (changeIds?: string[]) => string[];
  rollbackChanges: (changeIds?: string[]) => void;
};

export type UseChangeTrackerResult<T extends Objectish | null | undefined> = {
  useValueWithChanges: <S extends Selector = Selector<T, T>>(
    selector?: S
  ) => S extends Selector<unknown, infer O> ? O : never;
  getChangeSet: () => ChangeSet<T>;
  addChange: (
    changeApplicator: (draft: Draft<NonNullable<T>>) => unknown
  ) => void;
  addChangeListener: (listener: () => void) => void;
  removeChangeListener: (listener: () => void) => void;
};

export const useChangeTracker = <T extends Objectish | null | undefined>(
  valueToTrack: T
): UseChangeTrackerResult<T> => {
  const changeListRef = useRef<Change[]>([]);
  const trackedValueRef = useRef(valueToTrack);
  const valueWithChangesRef = useRef<T>(valueToTrack);
  const changeListenerListRef = useRef<(() => unknown)[]>([]);

  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const updateValueWithChanges = useCallback(() => {
    if (updateTimeoutRef.current !== null) {
      clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = null;
    }

    const previousValueWithChanges = valueWithChangesRef.current;

    if (typeof trackedValueRef.current !== typeof previousValueWithChanges) {
      valueWithChangesRef.current = trackedValueRef.current;
      changeListRef.current = [];
    } else if (
      trackedValueRef.current === null ||
      trackedValueRef.current === undefined
    ) {
      valueWithChangesRef.current = trackedValueRef.current;
    } else {
      const startingChanges = [...changeListRef.current];
      valueWithChangesRef.current = startingChanges.reduce(
        (acc, change, index) => {
          try {
            return applyPatches(acc, change.patches);
          } catch (_e) {
            sentry.addBreadcrumb({
              type: "warn",
              message:
                "Failed to re-apply a change to the current tracked value. Skipping change.",
              data: {
                allChanges: startingChanges,
                change,
                changeIndex: index,
                trackedValue: trackedValueRef.current,
                trackedValueWithPartialChangesApplied: acc,
                previousValueWithChanges,
              },
            });
            // Remove changes (and associated patches) that are incompatible with the new tracked value.
            // This could happen if a change was made to an item removed from the tracked value.
            changeListRef.current = changeListRef.current.filter(
              ({ id }) => id !== change.id
            );
            return acc;
          }
        },
        trackedValueRef.current
      );
    }
    if (!isEqual(previousValueWithChanges, valueWithChangesRef.current)) {
      changeListenerListRef.current.forEach((listener) => listener());
    }
  }, []);

  /**
   * Do this inline instead of in a useEffect so that changes are applied immediately.
   * Additionally, we get the benefit of avoiding an extra render when the value changes.
   */
  if (!isEqual(valueToTrack, trackedValueRef.current)) {
    trackedValueRef.current = valueToTrack;
    updateValueWithChanges();
  }

  const addChange = useCallback(
    (changeApplicator: (draft: Draft<NonNullable<T>>) => unknown) => {
      const trackedValue = trackedValueRef.current;
      if (trackedValue === null || trackedValue === undefined) {
        console.error("Cannot add change to null or undefined value!");
        return;
      }
      const [nextState, patches, inversePatches] = produceWithPatches(
        valueWithChangesRef.current!,
        (draft) => {
          changeApplicator(draft);
        }
      );
      if (patches.length === 0) {
        // addChange called with no changes
        return;
      }

      valueWithChangesRef.current = nextState;

      const previousChanges = changeListRef.current;
      const patchPaths = patches.map((patch) => patch.path);
      changeListRef.current = produce(previousChanges, (changesDraft) => {
        // if a previous unsaved change has been made to the same field(s) we want to consolidate the changes
        // to avoid having performance issues with an ever-growing list of changes
        const existingUnsavedChangeIndex = changesDraft.findIndex(
          (change) =>
            change.status === "unsaved" &&
            change.patches.length === patchPaths.length &&
            // leaving this inline allows for short-circuiting
            change.patches.every((patch) => {
              return !!patchPaths.find((path) => isEqual(patch.path, path));
            })
        );

        if (existingUnsavedChangeIndex !== -1) {
          const [, consolidatedPatches, consolidatedInversePatches] =
            produceWithPatches(trackedValue, (valueDraft) => {
              // Note: `applyPatches` should not return a promise. I don't know why eslint complains here...
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              applyPatches(valueDraft, [
                ...changesDraft[existingUnsavedChangeIndex].patches,
                ...patches,
              ]);
            });

          changesDraft.splice(existingUnsavedChangeIndex, 1);
          if (consolidatedPatches.length > 0) {
            // just remove change entirely if user sets the value back to what it was
            changesDraft.push(
              newChange(consolidatedPatches, consolidatedInversePatches)
            );
          }
        } else {
          changesDraft.push(newChange(patches, inversePatches));
        }
      });
      changeListenerListRef.current.forEach((listener) => listener());
    },
    []
  );

  const updateChangeStatus = useCallback(
    (changeIds: string[], newStatus: ChangeStatus) => {
      const previousChanges = changeListRef.current;
      changeListRef.current = produce(previousChanges, (draft) => {
        changeIds.forEach((changeId) => {
          const changeIndex = draft.findIndex(({ id }) => id === changeId);
          if (changeIndex !== -1) {
            if (newStatus === "saved") {
              // If change is "saved" it is no longer needed.
              draft.splice(changeIndex, 1);
            } else {
              draft[changeIndex].status = newStatus;
            }
          }
        });
      });
      if (newStatus === "saved") {
        updateTimeoutRef.current = setTimeout(() => {
          // Delay updating the value to give the tracked value a chance to reflect the new saved changes.
          // Updating immediately can cause a flicker in some situations.
          // To cover some edge cases where tracked value somehow is not changed and does not trigger an update,
          // we will ensure one gets called after a delay.
          updateTimeoutRef.current = null;
          console.warn(
            'Forcing update of value after removing "saved" changes. Expected tracked value to trigger update instead.'
          );
          updateValueWithChanges();
        }, 1000);
      }
    },
    [updateValueWithChanges]
  );
  const getChangesForPath = useCallback(
    (path: string[], status?: ChangeStatus) =>
      changeListRef.current
        .filter(
          (change) =>
            (status === undefined || change.status === status) &&
            change.patches.some((patch) => {
              return path.every(
                (pathPart, index) => patch.path[index] === pathPart
              );
            })
        )
        .map(({ id }) => id),
    []
  );

  const rollbackChanges = useCallback(
    (changeIds: string[]) => {
      const previousChanges = changeListRef.current;
      changeListRef.current = produce(previousChanges, (draft) => {
        return draft.filter((change) => {
          return !changeIds.includes(change.id);
        });
      });
      updateValueWithChanges();
    },
    [updateValueWithChanges]
  );

  const getChangeSet = useCallback(() => {
    const changesInThisSet = changeListRef.current.map(({ id }) => id);
    const getChangesByStatus = () =>
      changeListRef.current
        .filter(({ id }) => changesInThisSet.includes(id))
        .reduce(
          (acc, change) => {
            acc[change.status].push(change.id);
            return acc;
          },
          {
            pending: [],
            saved: [],
            unsaved: [],
          } as Record<ChangeStatus, string[]>
        );

    return {
      get hasUnsavedChanges() {
        return getChangesByStatus().unsaved.length > 0;
      },
      get changesByStatus() {
        return getChangesByStatus();
      },
      valueWithChanges: valueWithChangesRef.current,
      getChangesForPath,
      markPending: (changeIds?: string[]) => {
        changeIds = changeIds ?? getChangesByStatus().unsaved;
        updateChangeStatus(changeIds, "pending");
        return changeIds;
      },
      markSaved: (changeIds?: string[]) => {
        changeIds =
          changeIds ??
          (() => {
            const changesByStatus = getChangesByStatus();
            return [...changesByStatus.pending, ...changesByStatus.unsaved];
          })();
        updateChangeStatus(changeIds, "saved");
        return changeIds;
      },
      markUnsaved: (changeIds?: string[]) => {
        changeIds = changeIds ?? getChangesByStatus().pending;
        updateChangeStatus(changeIds, "unsaved");
        return changeIds;
      },
      rollbackChanges: (changeIds?: string[]) => {
        changeIds = changeIds ?? getChangesByStatus().unsaved;
        rollbackChanges(changeIds);
      },
    } satisfies ChangeSet<T>;
  }, [getChangesForPath, rollbackChanges, updateChangeStatus]);

  const addChangeListener = useCallback((listener: () => void) => {
    changeListenerListRef.current = [
      ...changeListenerListRef.current,
      listener,
    ];
  }, []);
  const removeChangeListener = useCallback((listener: () => void) => {
    changeListenerListRef.current = changeListenerListRef.current.filter(
      (l) => l !== listener
    );
  }, []);

  const useValueWithChanges = useCallback(
    <S extends Selector = Selector<T, T>>(
      selector?: S
    ): S extends Selector<unknown, infer O> ? O : never => {
      const selectorRef = useRef(selector);
      selectorRef.current = selector;

      const selectValue = useCallback(
        () =>
          (selectorRef.current
            ? selectorRef.current(valueWithChangesRef.current)
            : valueWithChangesRef.current) as S extends Selector<
            unknown,
            infer O
          >
            ? O
            : never,
        []
      );

      // must be `useState` so that updates trigger re-renders
      const [, setState, stateRef] = useStateWithRef(selectValue);
      const timeoutRef = useRef<NodeJS.Timeout | null>(null);

      const updateState = useCallback(() => {
        // No need to set another timeout if one is already scheduled to run.
        if (timeoutRef.current === null) {
          // Wait until the current render cycle is complete before updating the state and causing consumers of
          // `useValueWithChanges` to re-render.
          timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            const newState = selectValue();
            if (!isEqual(stateRef.current, newState)) {
              setState(newState);
            }
          }, 0);
        }
      }, [selectValue, setState, stateRef]);

      useEffect(() => {
        addChangeListener(updateState);
        return () => {
          removeChangeListener(updateState);
        };
      }, [updateState]);

      // Re-select the value in case the selector function provided changed since the last render
      return useIsEqualMemo(selectValue());
    },
    [addChangeListener, removeChangeListener]
  );

  return {
    useValueWithChanges,
    getChangeSet,
    addChange,
    addChangeListener,
    removeChangeListener,
  };
};

type Selector<I = unknown, O = unknown> = (input: I) => O;

function newChange(patches: Patch[], inversePatches: Patch[]): Change {
  return {
    id: uuid(),
    patches,
    inversePatches,
    status: "unsaved",
  };
}

export const extractUnsavedFields = <T extends Objectish>(
  changeSet: ChangeSet<T | undefined>,
  pathsToInclude: string[][]
): Partial<T> => {
  const { valueWithChanges } = changeSet;
  const unsavedFields: Partial<T> = {};
  pathsToInclude.forEach((path) => {
    if (changeSet.getChangesForPath(path).length > 0) {
      const pathValue = _.get(valueWithChanges, path);
      _.set(unsavedFields, path, pathValue);
    }
  });
  return unsavedFields;
};

export const isChangeSetTrackingValidValue = <T extends Objectish>(
  changeSet: ChangeSet<T | null | undefined>
): changeSet is ChangeSet<T> => {
  return (
    changeSet.valueWithChanges !== null &&
    changeSet.valueWithChanges !== undefined
  );
};
