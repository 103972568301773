import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/animations/questie-animation-lottie.json";

export const LottiePlayer: React.FC = (props) => {
  return (
    <Lottie
      options={{
        loop: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      speed={0.8}
      height={"100%"}
      width={"100%"}
      {...props}
    />
  );
};
