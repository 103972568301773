import React, { PropsWithChildren, useRef, useState } from "react";
import { Animated, Easing, Platform, ViewStyle } from "react-native";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { useIsFocused } from "@react-navigation/native";

interface FadeInViewProps {
  delay?: number;
  style?: ViewStyle;
  testID?: string;
}

export const FadeInView: React.FC<PropsWithChildren<FadeInViewProps>> = (
  props
) => {
  const { testID, delay = 0 } = props;

  const isFocused = useIsFocused();
  const [fadeInCompleted, setFadeInCompleted] = useState(!isFocused);

  const slideAnim = useRef(
    new Animated.Value(fadeInCompleted ? 0 : 20)
  ).current;

  const fadeAnim = useRef(
    new Animated.Value(fadeInCompleted ? 1 : 0.0000001)
  ).current;

  useEffectOnce(() => {
    if (!fadeInCompleted) {
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: 500,
        delay,
        easing: Easing.out(Easing.ease),
        useNativeDriver: Platform.OS !== "web",
        isInteraction: false,
      }).start();

      const fullyOpaque = 1;
      Animated.timing(fadeAnim, {
        toValue: fullyOpaque,
        duration: 500,
        delay,
        easing: Easing.out(Easing.ease),
        useNativeDriver: Platform.OS !== "web",
        isInteraction: false,
      }).start();

      const listenerId = fadeAnim.addListener(({ value }) => {
        if (value === fullyOpaque) {
          setFadeInCompleted(true);
        }
      });

      return () => {
        fadeAnim.removeListener(listenerId);
      };
    }
  });

  return (
    <Animated.View
      style={[
        props.style,
        {
          transform: [{ translateY: slideAnim }],
          opacity: fadeAnim,
        },
      ]}
      testID={`${testID}${fadeInCompleted ? "" : "-fading-in"}`}
    >
      {props.children}
    </Animated.View>
  );
};
