import React, { useContext, useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { usePromise } from "@app/util/usePromise";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { sentry } from "@app/util/sentry";
import { TeamList } from "@app/components/questkit/TeamList";
import { useAppSelector } from "@app/store";
import { selectWorkspaceById } from "@app/store/cache/workspaces";
import { createWorkspace } from "@app/util/client/requests/workspaces";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { ENV } from "@app/config/env";

const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

interface CreateWorkspaceProps {
  setShowModal: (showModal: boolean) => void;
}

const CreateWorkspace: React.FC<CreateWorkspaceProps> = ({ setShowModal }) => {
  const navigation = useAppNavigation<"Workspaces">();
  const snackbar = useContext(SnackbarContext);

  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceCreated, setWorkspaceCreated] = useState(false);

  const activeWorkspaceTeamId = TEAMS_ENABLED
    ? useAppSelector(
        (state) =>
          (state.ui.sideBar.activeWorkspaceId &&
            selectWorkspaceById(state, state.ui.sideBar.activeWorkspaceId)
              ?.teamId) ||
          undefined
      )
    : undefined;

  const [teamId, setTeamId] = useState(activeWorkspaceTeamId);

  const { execute: onCreateWorkspace, isLoading: isCreatingWorkspace } =
    usePromise(async () => {
      if ((workspaceName || "").trim().length === 0) {
        snackbar.sendMessage(
          "Please enter a name for your workspace.",
          SnackbarSeverity.WARNING
        );
        return;
      } else if (TEAMS_ENABLED && !teamId) {
        snackbar.sendMessage(
          "Please select a team for your workspace.",
          SnackbarSeverity.WARNING
        );
        return;
      }

      return createWorkspace({
        name: workspaceName,
        teamId,
      })
        .then((createdWorkspaceData) => {
          setWorkspaceCreated(true);
          snackbar.sendMessage("Workspace created.");

          setTimeout(() => {
            setShowModal(false);

            navigation.navigate("Workspace", {
              workspaceId: createdWorkspaceData.id,
            });
          }, MODAL_CLOSE_DELAY);
        })
        .catch((e) => {
          console.error("Failed to create workspace", e);
          snackbar.sendMessage(
            "Failed to create workspace. Please try again later.",
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, { extra: { workspaceName } });
        });
    });

  return (
    <CreateWorkspaceDialog>
      <HeaderText icon="item" title="Workspace Name" />
      <StyledTextInput
        onChangeText={setWorkspaceName}
        value={workspaceName}
        placeholder={"Sales, Marketing, HR, Engineering, Team Hulk..."}
        autoFocus={true}
        editable={!isCreatingWorkspace && !workspaceCreated}
        onSubmitEditing={onCreateWorkspace}
      />
      {TEAMS_ENABLED ? (
        <>
          <HeaderText icon="group" title="Team" />
          <TeamList setTeamId={setTeamId} teamId={teamId} />
        </>
      ) : null}
      <StyledCreateWorkspaceButton
        onPress={onCreateWorkspace}
        success={workspaceCreated}
        loading={isCreatingWorkspace}
        title="Create Workspace"
      />
    </CreateWorkspaceDialog>
  );
};

const CreateWorkspaceDialog = styled.View`
  margin-horizontal: 12px;
  margin-vertical: 10px;
`;

const StyledCreateWorkspaceButton = styled(Button)`
  width: 213px;
  align-self: center;
  margin-bottom: 24px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 40px;
`;

export default CreateWorkspace;
