import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DropdownOption,
  DropdownStyle,
  DropdownWithModal,
} from "@app/components/questkit/dropdownWithModal";
import { useApp } from "@app/screens/settings/app";
import { LinkAppPrompt } from "@app/components/completionAction/LinkAppPrompt";
import { Analytics } from "@app/analytics";
import {
  GetSlackChannelInfoResponse,
  useSlackChannels,
} from "@app/components/completionAction/components/useSlackChannels";
import {
  createRequest,
  noopRequest,
  useRequest,
} from "@app/util/client/requests";

interface SlackChannelPickerProps {
  channelId: string;
  onChangeChannelId: (channelId: string | undefined) => void;
  onChangeAppLinkStatus: (appLinked?: boolean) => void;
  onTouched: () => void;
}

// loose match. All channels should start with C,G, or D and then contain 10 upper-case letters or numbers. In order to
// avoid accidentally excluding ids we can safely match a little more, as it will only incur an API call to check for
// the channel's info.
const slackChannelIdMatcher = /^\s*[A-Z][A-Z0-9]{8,12}\s*$/;

const fetchSlackChannelInfo = (channelId: string) => {
  return createRequest<GetSlackChannelInfoResponse>(
    "get",
    `/apps/slack/api/conversations.info?channel=${channelId}`
  )();
};

export const SlackChannelPicker: React.FC<SlackChannelPickerProps> = ({
  channelId,
  onChangeChannelId,
  onChangeAppLinkStatus,
  onTouched,
}) => {
  const { app, link, isLinking, isLoading } = useApp("slack");

  useEffect(() => {
    onChangeAppLinkStatus(!!app?.linked);
  }, [app?.linked, onChangeAppLinkStatus]);

  const { channels, isValidating, revalidateChannels } = useSlackChannels(
    app?.linked
  );

  const [currentSearchText, setCurrentSearchText] = useState("");

  const userIsSearchingForChannelId =
    slackChannelIdMatcher.test(currentSearchText);
  const {
    data: searchedChannelData,
    refresh: refreshSearchedChannelData,
    isValidating: isLoadingSearchedChannel,
  } = useRequest(
    app?.linked && userIsSearchingForChannelId
      ? fetchSlackChannelInfo(currentSearchText)
      : noopRequest()
  );
  const {
    data: selectedChannelData,
    refresh: refreshSelectedChannelData,
    isValidating: isLoadingSelectedChannel,
  } = useRequest(
    app?.linked && channelId ? fetchSlackChannelInfo(channelId) : noopRequest()
  );

  const options: DropdownOption[] = useMemo(() => {
    const allChannels = [
      ...(channels || []),
      ...(searchedChannelData?.ok ? [searchedChannelData.channel] : []),
      ...(selectedChannelData?.ok ? [selectedChannelData.channel] : []),
    ];
    return allChannels
      .filter(
        (channel, index, self) =>
          // filter out duplicates (channels loaded by id)
          self.findIndex(({ id }) => id === channel.id) === index
      )
      .map(
        ({ id, is_private, name }) =>
          ({
            value: id,
            name: name,
            icon: is_private ? "lock" : "group",
          } as const)
      )
      .sort((a, b) => a.name.localeCompare(b.name, undefined, {}));
  }, [channels, searchedChannelData, selectedChannelData]);

  const linkApp = useCallback(() => {
    void link().then((apiAppAuthService) => {
      if (apiAppAuthService.linked) {
        revalidateChannels();
        void refreshSearchedChannelData();
        void refreshSelectedChannelData();
      } else {
        onTouched();
      }
    });
  }, [
    link,
    onTouched,
    refreshSearchedChannelData,
    refreshSelectedChannelData,
    revalidateChannels,
  ]);

  return app?.linked ? (
    <DropdownWithModal
      options={options}
      loadingOptions={
        isValidating || isLoadingSelectedChannel || isLoadingSearchedChannel
      }
      selectedOption={channelId}
      setSelectedOption={(channelId) => {
        if (channelId) {
          Analytics.trackEvent("Set Slack Channel");
        } else {
          Analytics.trackEvent("Remove Slack Channel");
        }
        onChangeChannelId(channelId as string);
        onTouched();
      }}
      onSearchTextChange={(searchText) => {
        setCurrentSearchText(searchText);
      }}
      dropdownStyle={DropdownStyle.STANDARD}
      placeholderIcon="group"
      optionNoun="Channel"
      optionPluralNoun="Channels"
    />
  ) : (
    <LinkAppPrompt
      buttonText="Link Slack Account"
      infoText="To send Quest results to a Slack channel, you must first link your Slack account."
      link={linkApp}
      loading={isLinking || isLoading}
    />
  );
};
