import React, { useContext, useMemo } from "react";

import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";

import TextAnimator from "./textAnimator";
import { ThemeContext } from "styled-components/native";
import isEqual from "react-fast-compare";
import {
  LINE_HEIGHT_RATIO,
  QKTextProps,
  TEXT_SIZES,
} from "@app/components/questkit/text";

interface AnimatedTextProps {
  content: string;
  style?: StyleProp<ViewStyle>;
  inactive?: boolean;
  size?: QKTextProps["size"];
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  content,
  style = {},
  inactive,
  size,
}) => {
  const theme = useContext(ThemeContext);

  const styles = useMemo(() => {
    const fontSize = TEXT_SIZES[size ?? "xlarge"].size;
    return StyleSheet.create({
      containerStyle: {},
      textStyle: {
        fontSize: fontSize,
        fontFamily: "CentraNo2-Bold",
        color: inactive ? theme.inactive : theme.primary,
        lineHeight: fontSize * LINE_HEIGHT_RATIO,
      },
    });
  }, [inactive, theme.inactive, theme.primary, size]);

  return (
    <View
      style={[
        {
          marginHorizontal: 16,
          marginVertical: 8,
        },
        style,
      ]}
    >
      <TextAnimator
        duration={500}
        textStyle={styles.textStyle}
        style={styles.containerStyle}
        content={content || ""}
        key={content}
      ></TextAnimator>
    </View>
  );
};

export default React.memo(AnimatedText, isEqual);
