import {
  apiRequestWithOnSuccess,
  createRequest,
} from "@app/util/client/requests/index";
import { type AppId, Apps } from "@questmate/openapi-spec";
import { store } from "@app/store";
import { appAuthListLoaded, appAuthLoaded } from "@app/store/cache/appAuths";

export const fetchApp = (appId: string) => {
  return createRequest<Apps.AuthDetail.ResponseBody>(
    "get",
    `/apps/${appId}/auth`
  )((response) => {
    store.dispatch(appAuthLoaded(response));
  });
};

export const fetchAppList = () => {
  return createRequest<Apps.AuthList.ResponseBody>(
    "get",
    "/apps/auth"
  )((response) => {
    store.dispatch(appAuthListLoaded(response));
  });
};

export const linkApp = (
  appId: AppId,
  body: Apps.AuthLinkCreate.RequestBody
) => {
  return apiRequestWithOnSuccess<Apps.AuthLinkCreate.ResponseBody>(
    (response) => {
      store.dispatch(appAuthLoaded(response));
    }
  )(["post", `/apps/${appId}/auth/link`, body]);
};

export const unlinkApp = (appId: AppId) => {
  return apiRequestWithOnSuccess<Apps.AuthUnlinkCreate.ResponseBody>(
    (response) => {
      store.dispatch(appAuthLoaded(response));
    }
  )(["post", `/apps/${appId}/auth/unlink`]);
};
