import type { ReactNode } from "react";
import React, { useCallback, useRef } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { Text, TextInput } from "@app/components/questkit/text";
import { ItemRenderData } from "@app/types/itemRenderData";
import { useFocusableRef } from "@app/util/focus";
import { Analytics } from "@app/analytics";
import { RequireAtLeastOne } from "@questmate/common";
import BasePressable, { OnPress } from "@app/components/questkit/BasePressable";
import { OnItemChangeHandler } from "@app/util/client/hooks/useQuestInstance";
import { useQuestViewContext } from "@app/quest/QuestViewContext";

export interface ItemBaseProps {
  editMode: boolean;

  slideMode?: boolean;

  item: ItemRenderData;
  setItemAction?: (itemAction: OnPress | undefined) => void;
  onItemChange?: OnItemChangeHandler;
  onItemAppend?: () => void;
  onItemDelete?: () => void;
  onItemValidate?: () => void;
  onComplete?: () => void;

  readOnly?: boolean;
}

interface ContainerProps extends ItemBaseProps {
  editMode: boolean;
  inlineNode?: ReactNode;
  blockNode?: ReactNode;
}

export const ItemContainerWrapper: React.FC<
  RequireAtLeastOne<ContainerProps, "inlineNode" | "blockNode">
> = ({
  item,
  onItemChange,
  editMode,
  inlineNode,
  blockNode,
  // onItemAppend,
}) => {
  const itemRef = useRef(item);
  itemRef.current = item;
  const onItemNameChange = useCallback(
    (newName: string) => {
      onItemChange?.({
        ...itemRef.current,
        prototype: {
          ...itemRef.current.prototype,
          name: newName,
        },
      });
    },
    [onItemChange]
  );

  return (
    <ItemContainer
      name={item.prototype.name}
      itemId={item.prototype.id}
      editMode={editMode}
      inlineNode={inlineNode}
      blockNode={blockNode!}
      onItemNameChange={onItemNameChange}
      // onItemAppend={onItemAppend}
      placeholder={"Task description/question"}
    />
  );
};

type ItemContainerProps = RequireAtLeastOne<
  {
    name: string;
    itemId: string;
    editMode: boolean;
    inlineNode?: ReactNode;
    blockNode?: ReactNode;

    /**
     * The following are only used when in edit mode.
     */
    onItemNameChange?: (newName: string) => void;
    // onItemAppend?: () => void;
    placeholder?: string;
    /**
     * The following are only used when not in edit mode.
     */
    onHeaderPress?: () => void;
  },
  "inlineNode" | "blockNode"
>;
export const ItemContainer: React.FC<ItemContainerProps> = ({
  name,
  itemId,
  editMode,
  inlineNode,
  blockNode,
  onItemNameChange,
  // onItemAppend,
  onHeaderPress,
  placeholder,
}) => {
  const focusRefCallback = useFocusableRef(itemId);
  const itemNameRef = useRef(name);
  itemNameRef.current = name;

  const questViewContext = useQuestViewContext();

  const onChangeText = useCallback(
    (text: string) => {
      if (questViewContext.viewContext !== "MANAGE") {
        return;
      }

      questViewContext.setFieldTouched(`items[${itemId}]`, true);
      Analytics.trackEventDebounced(itemId, "Set Item Name");
      const singleLineText = text.replace(/[\r\n]+/g, "");
      onItemNameChange?.(singleLineText);
      return singleLineText;
    },
    [itemId, onItemNameChange, questViewContext]
  );

  // const focusController = useFocusController();
  // const onSubmitEditing = useCallback(async () => {
  //   const newItemId = onItemAppend?.();
  //   if (newItemId) {
  //     focusController.focus(newItemId);
  //   }
  // }, [focusController, onItemAppend]);

  const onBlur = useCallback(async () => {
    if (questViewContext.viewContext !== "MANAGE") {
      return;
    }

    questViewContext.setFieldTouched(`items[${itemId}]`, true);
  }, [itemId, questViewContext]);

  return (
    <>
      <HeaderRow
        addSpaceBelow={!blockNode}
        onPress={onHeaderPress}
        // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
        tabIndex={onHeaderPress ? 0 : -1}
      >
        <HeaderTextContainer>
          {editMode ? (
            <TextInput
              ref={focusRefCallback}
              scrollEnabled={false}
              autoComplete="off"
              autoCorrect={true}
              placeholder={placeholder}
              multiline={true}
              size="large"
              editable={editMode}
              value={name || ""}
              onChangeText={onChangeText}
              blurOnSubmit={true}
              // onSubmitEditing={onSubmitEditing}
              onBlur={onBlur}
            />
          ) : (
            <HeaderText
              size="large"
              nativeID={`item-name-label-${itemId}`}
              // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
              accessibilityRole={Platform.select({
                web: "label",
                native: undefined,
              })}
              hasOnHeaderPress={!!onHeaderPress}
            >
              {name}
            </HeaderText>
          )}
        </HeaderTextContainer>
        {inlineNode}
      </HeaderRow>
      {blockNode ? <BlockNodeWrap>{blockNode}</BlockNodeWrap> : null}
    </>
  );
};

const HeaderText = styled(Text)<{ hasOnHeaderPress: boolean }>`
  ${({ hasOnHeaderPress }) =>
    Platform.OS !== "web"
      ? ""
      : hasOnHeaderPress
      ? `cursor: pointer`
      : `cursor: default`}
`;
const HeaderTextContainer = styled.View`
  margin-right: 12px;
  flex: 1;
`;

const BlockNodeWrap = styled.View`
  margin-vertical: 16px;
`;

export const HeaderRow = styled(BasePressable)<{ addSpaceBelow: boolean }>`
  flex-direction: row;
  align-items: center;
  ${({ addSpaceBelow }) => (addSpaceBelow ? "margin-bottom: 8px;" : "")}
  ${({ onPress }) =>
    Platform.OS !== "web"
      ? ""
      : onPress
      ? `cursor: pointer`
      : `cursor: default`}
`;
