import { useLink } from "@app/util/link.utils";
import React, { useCallback, useEffect, useRef } from "react";
import Button from "@app/components/questkit/button";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useTimeout } from "@app/util/useTimeout";
import { useInterval } from "@app/util/useInterval";

type NavigationButtonProps = {
  label: string;
  to: Parameters<typeof useLink>[0];
  autoRedirectDelay?: number;
  autoRedirectDisabled?: boolean;
  disabled?: boolean;
  newTabOnWeb?: boolean;
};
export const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  to,
  autoRedirectDelay,
  autoRedirectDisabled,
  disabled,
  newTabOnWeb,
}) => {
  const onPress = useLink(to, {
    newTabOnWeb: newTabOnWeb ?? false,
    onPressHook: () => {
      cancel();
    },
  });
  const { remainingDelaySeconds, cancel } = useDelayedAction({
    action: onPress,
    delayMs: !!autoRedirectDelay ? autoRedirectDelay * 1000 : undefined,
    disabled: autoRedirectDisabled,
  });

  return (
    <Button
      onPress={onPress}
      disabled={disabled}
      title={
        label +
        (remainingDelaySeconds !== undefined
          ? ` (${remainingDelaySeconds})`
          : "")
      }
    />
  );
};

type UseDelayedAction = (config: {
  action: undefined | (() => unknown);
  delayMs?: number;
  disabled?: boolean;
}) => {
  remainingDelaySeconds: number | undefined;
  cancel: () => void;
};
export const useDelayedAction: UseDelayedAction = (config) => {
  const { action, delayMs, disabled } = config;
  const [
    remainingDelaySeconds,
    setRemainingDelaySeconds,
    remainingDelaySecondsRef,
  ] = useStateWithRef<number | undefined>(undefined);

  const actionFnRef = useRef(action);
  actionFnRef.current = action;
  const { start: scheduleActionAfterDelay, stop: abortDelayedAction } =
    useTimeout(() => actionFnRef.current?.(), delayMs);

  const updateCountDown = useRef((performActionAt: number) => {
    const seconds = Math.ceil((performActionAt - new Date().getTime()) / 1000);
    if (remainingDelaySecondsRef.current !== seconds) {
      if (seconds >= 0) {
        setRemainingDelaySeconds(seconds);
      } else {
        setRemainingDelaySeconds(undefined);
        stopUpdatingCountDown();
      }
    }
  }).current;
  const {
    start: startRegularlyUpdatingCountDown,
    stop: stopUpdatingCountDown,
  } = useInterval(updateCountDown, 50);

  const cancel = useCallback(() => {
    stopUpdatingCountDown();
    abortDelayedAction();
    setRemainingDelaySeconds(undefined);
  }, [stopUpdatingCountDown, abortDelayedAction, setRemainingDelaySeconds]);

  useEffect(() => {
    if (!disabled && action && delayMs !== undefined && delayMs >= 0) {
      const performActionAt = new Date().getTime() + delayMs;

      // set initial countdown value
      updateCountDown(performActionAt);

      // check regularly to see if we need to update the countdown value
      startRegularlyUpdatingCountDown(performActionAt);

      // schedule the action to take place after the delay
      scheduleActionAfterDelay();

      // Cancel async tasks if the component is unmounted, `delayMs` changes or `disabled` becomes true
      return cancel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayMs, disabled]);

  return {
    remainingDelaySeconds,
    cancel,
  };
};
