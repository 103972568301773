import React, { useCallback, useMemo, useRef, useState } from "react";
import { CompletionActionComponentProps as CompletionActionBaseProps } from "@app/components/completionAction";
import Text from "@app/components/questkit/text";
import { Platform, Pressable } from "react-native";
import { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import styled, { useTheme } from "styled-components/native";
import TextInput from "@app/components/questkit/textInput";
import Button from "@app/components/questkit/button";
import QKModal, { ModalDisplayMode } from "@app/components/modal";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import RewardContainer from "@app/components/completionAction/components/RewardContainer";
import { NavigationButton } from "@app/components/NavigationButton";
import { ScriptableResponse } from "@app/components/item/components/custom/types";
import { SupportedNavigationActionType } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { ItemRunStatusIndicator } from "@app/components/item/components/custom/CustomItemDebuggerModal";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";

const changeScript = (
  item: CompletionActionRenderData,
  script: string
): CompletionActionRenderData => ({
  ...item,
  data: {
    ...item.data,
    script,
  },
  version: item.version + 1,
});

export const RunQuestScriptReward: React.FC<CompletionActionBaseProps> = ({
  item,
  onCompletionActionChange,
  editMode,
}) => {
  const itemRef = useRef<CompletionActionRenderData>();
  itemRef.current = item;

  const onChangeScript = useCallback(
    (script: string) =>
      onCompletionActionChange(changeScript(itemRef.current!, script)),
    [onCompletionActionChange]
  );

  const hasActions = (item.data?.response?.value?.actions?.length ?? 0) > 0;

  const questContext = useQuestViewContext();
  const theme = useTheme();

  return (
    <>
      {editMode ? (
        // eslint-disable-next-line deprecation/deprecation
        <RewardContainer
          blockNode={
            <QuestScriptEditor
              script={item.data?.script}
              onChangeScript={onChangeScript}
            />
          }
          title={"Run QuestScript"}
        />
      ) : (
        <ContentContainer>
          {questContext.viewContext === "RUN" &&
          questContext.status === "COMPLETED" ? (
            <QKLoadingIndicator
              name={`quest-script-${item.prototype.id}`}
              loading={["WAITING_TO_START", "PENDING"].includes(item.status!)}
              yieldTo={[
                "allCompletionActions",
                `admin-quest-script-${item.prototype.id}`,
              ]}
              size={32}
              color={theme.primary}
            />
          ) : null}
          {questContext.viewContext !== "RUN" ||
          (questContext.status === "COMPLETED" &&
            questContext.roles.includes("OWNER")) ? (
            <QuestScriptAdminView item={item} />
          ) : null}
          {hasActions ? (
            <QuestScriptActions actions={item.data.response.value.actions} />
          ) : null}
        </ContentContainer>
      )}
    </>
  );
};
const ContentContainer = styled.View`
  margin-left: 20px;
  margin-right: 20px;
`;

type QuestScriptEditorProps = {
  script: string;
  onChangeScript: (script: string) => void;
};
const QuestScriptEditor: React.FC<QuestScriptEditorProps> = ({
  script,
  onChangeScript,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <ReadonlyCodeWrapper>
      {Platform.OS === "web" ? (
        <Pressable
          onPress={() => setShowModal(true)}
          testID={"open-questscript-editor"}
        >
          <CodeMirror
            value={script}
            height="100%"
            minHeight="200px"
            extensions={[javascript({ jsx: false })]}
            editable={false}
            basicSetup={{
              lineNumbers: false,
              foldGutter: false,
              highlightActiveLine: false,
            }}
            style={{
              fontSize: 15,
            }}
          />
        </Pressable>
      ) : (
        <TextInput
          value={script}
          onChangeText={onChangeScript}
          placeholder="return questmate.getQuest(...);"
          multiline={true}
          autoComplete={"off"}
          autoCorrect={false}
          minNumberOfLines={4}
          blurOnSubmit={false}
          fontSize={14}
        />
      )}
      <QKModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Quest Script Editor"}
        displayMode={ModalDisplayMode.FULL_SCREEN}
      >
        <ModalContentWrapper>
          <EditorWrapper>
            <CodeMirror
              value={script}
              minHeight={"100%"}
              height={"100%"}
              maxHeight={"100%"}
              extensions={[javascript({ jsx: false })]}
              onChange={onChangeScript}
              readOnly={false}
              autoFocus={true}
              basicSetup={{
                lineNumbers: true,
                foldGutter: true,
                autocompletion: true,
                highlightActiveLine: false,
                completionKeymap: true,
                bracketMatching: true,
                syntaxHighlighting: true,
              }}
              style={{
                fontSize: 15,
                minHeight: "100%",
                height: "100%",
                maxHeight: "100%",
              }}
            />
          </EditorWrapper>
          <Button title={"Done"} onPress={() => setShowModal(false)} />
        </ModalContentWrapper>
      </QKModal>
    </ReadonlyCodeWrapper>
  );
};

type QuestScriptNavigateAction = {
  type: "navigate";
  linkText?: string;
  to: SupportedNavigationActionType;
  autoRedirectDelay?: number;
};

type QuestScriptAction = QuestScriptNavigateAction;

type QuestScriptActionsProps = {
  actions: QuestScriptAction[];
};
const QuestScriptActions: React.FC<QuestScriptActionsProps> = ({ actions }) => {
  return (
    <>
      {actions.map((action, index) => (
        <ActionsContainer key={`action-${index}`}>
          {action.type === "navigate" ? (
            <NavigateAction {...action} />
          ) : (
            <Text>Unhandled action: {JSON.stringify(action, null, 2)}</Text>
          )}
        </ActionsContainer>
      ))}
    </>
  );
};

const NavigateAction: React.FC<QuestScriptNavigateAction> = ({
  linkText,
  to,
  autoRedirectDelay,
}) => {
  const { recentlySubmittedByUser } = useQuestViewContext(["RUN"]);

  return (
    <NavigationButton
      label={linkText || "Redirect URL"}
      to={to}
      autoRedirectDelay={autoRedirectDelay}
      autoRedirectDisabled={!recentlySubmittedByUser}
    />
  );
};

type QuestScriptAdminViewProps = {
  item: CompletionActionRenderData;
};
const QuestScriptAdminView: React.FC<QuestScriptAdminViewProps> = ({
  item,
}) => {
  const questContext = useQuestViewContext();

  const [isOpen, setIsOpen] = useState(false);

  const status = useMemo(() => {
    switch (item.status) {
      case "WAITING_TO_START":
        return "PENDING";
      case "COMPLETED":
        if (
          (
            item.data?.response as ScriptableResponse<unknown> | undefined
          )?.status?.toLowerCase() === "ok"
        ) {
          return "OK";
        } else {
          return "FAILED";
        }
      default:
        return item.status!;
    }
  }, [item.data?.response, item.status]);

  const onPress = useMemo(() => {
    if (questContext.viewContext === "RUN") {
      return () => setIsOpen(!isOpen);
    }
  }, [questContext.viewContext, setIsOpen, isOpen]);

  const formattedText = useMemo(() => {
    if (item.data?.response) {
      return JSON.stringify(item.data?.response, null, 2);
    } else if (item.data?.error) {
      return item.data?.error;
    }
    return "N/A";
  }, [item.data]);

  return (
    <>
      <ResponseSummary onPress={onPress}>
        <StyledText size="large">Run QuestScript</StyledText>
        {questContext.viewContext === "RUN" ? (
          <ItemRunStatusIndicator
            name={`admin-quest-script-${item.prototype.id}`}
            status={status}
          />
        ) : (
          <CheckboxToggle readOnly={true} checked={false} />
        )}
      </ResponseSummary>
      {questContext.viewContext === "PREVIEW" ? (
        <Text>Preview unavailable.</Text>
      ) : null}
      {isOpen ? (
        Platform.OS === "web" ? (
          <CodeMirror
            value={formattedText}
            height="100%"
            minHeight="200px"
            editable={false}
            extensions={[javascript()]}
            basicSetup={{
              lineNumbers: false,
              foldGutter: true,
              highlightActiveLine: false,
            }}
            style={{
              fontSize: 15,
            }}
          />
        ) : (
          <Text>{formattedText}</Text>
        )
      ) : null}
    </>
  );
};

const StyledText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const ActionsContainer = styled.View`
  margin-left: 10px;
`;

const ReadonlyCodeWrapper = styled.View`
  border-radius: 20px;
  overflow: hidden;
  max-height: 200px;
`;

const ModalContentWrapper = styled.View`
  padding: 20px;
  height: 100%;
`;
const EditorWrapper = styled.View`
  display: flex;
  max-height: 90%;
  flex-grow: 2;
  width: 100%;
  margin-bottom: 20px;
`;

const ResponseSummary = styled(PressableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-vertical: 10px;
  margin: 0 0 0 0;
  cursor: ${({ onPress }) => (onPress ? "pointer" : "default")};
`;
