import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import Amount from "@app/components/questkit/amount";
import Text from "@app/components/questkit/text";
import Icon, { IconIdentifier } from "@app/components/icon";
import { Platform, ViewStyle } from "react-native";
import { TimeAmount, TimeAmountUnit } from "@app/types/time";
import { convertToSeconds } from "@app/util/time";

const timeUnit: TimeAmountUnit[] = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "year",
];

interface RelativeTimePickerProps {
  time: TimeAmount;
  iconName: IconIdentifier;
  style?: ViewStyle;
  onChange?: (second: number | null) => void;
  readOnly?: boolean;
  contextText?: string;
}

const RelativeTimePicker: React.FC<RelativeTimePickerProps> = ({
  time,
  iconName,
  style,
  onChange,
  readOnly = false,
  contextText = "before",
}) => {
  const [currentValue, setCurrentValue] = useState<number>(1);
  const [currentUnit, setCurrentUnit] = useState<TimeAmountUnit>("hour");

  useEffect(() => {
    setCurrentUnit(time.unit);
    setCurrentValue(time.value);
  }, [time]);

  const updateData = (value: number, unit: TimeAmountUnit) => {
    if (readOnly) {
      return;
    }
    const result = convertToSeconds({
      value: value,
      unit: unit,
    });

    onChange?.(result);
  };

  const onPressTimeUnit = () => {
    if (readOnly) {
      return;
    }
    const currentIndex = timeUnit.indexOf(time.unit);
    let newIndex: number;
    if (currentIndex === -1 || currentIndex + 1 >= timeUnit.length) {
      newIndex = 0;
    } else {
      newIndex = currentIndex + 1;
    }
    const newTimeUnit = timeUnit[newIndex];
    setCurrentUnit(newTimeUnit);
    updateData(currentValue, newTimeUnit);
  };

  const onChangeAmountHandler = (value: number) => {
    if (readOnly) {
      return;
    }
    setCurrentValue(value);
    updateData(value, currentUnit);
  };

  return (
    <RelativeTimePickerWrapper style={style}>
      <Icon icon={iconName} />
      <RelativeTimeValue
        onChangeAmount={onChangeAmountHandler}
        amount={currentValue}
        minAmount={1}
        maxAmount={999}
      />
      <RelativeTimeUnit onPress={onPressTimeUnit}>
        <Text $inverted={true}>
          {currentValue !== 1 ? currentUnit + "s" : currentUnit}
        </Text>
      </RelativeTimeUnit>
      <ContextText>{contextText}</ContextText>
    </RelativeTimePickerWrapper>
  );
};

const RelativeTimeUnit = styled.Pressable`
  height: 32px;
  width: 110px;
  border-radius: 16px;
  margin-right: 11px;

  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.interaction.primary};
  ${() => Platform.OS === "web" && "user-select: none;"}
`;

const RelativeTimePickerWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const RelativeTimeValue = styled(Amount)`
  margin-right: 4px;
  height: 32px;
`;

const ContextText = styled(Text)`
  margin-right: 8px;
`;

export default RelativeTimePicker;
