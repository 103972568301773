import {
  createTabNavigator,
  type TabScreenOptions,
} from "@app/quest/TabNavigator";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  type QMStackParamList,
  useAppNavigation,
  useAppRoute,
} from "@app/navigation/QMNavigator";
import { QuestTemplateScreen } from "@app/quest/edit/QuestTemplateScreen";
import {
  type FilterIdsByGroupId,
  QuestRunsScreen,
} from "@app/quest/run/QuestRunsScreen";
import QKModal from "@app/components/modal";
import TemplateOptionsDialog from "@app/components/modal/templateOptionsDialog";
import { fetchQuest } from "@app/util/client/requests/quests";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import CreateQuest from "@app/components/modal/createTemplate";
import { View } from "react-native";
import { useMultiTapToggler } from "@app/quest/edit/useMultiTapToggler";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import {
  type FullQuestContext,
  type LoadingQuestContext,
  QuestContext,
  useQuestContext,
} from "./QuestContext";
import { useAppDispatch, useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import Loader from "@app/components/animated/loader";
import { selectQuestById } from "@app/store/cache/quests";
import { useRequest } from "@app/util/client/requests";
import { useScreenHasBeenFocusedAtLeastOnce } from "@app/util/useScreenHasBeenFocusedAtLeastOnce";
import { QuestEditScreen } from "@app/quest/edit/QuestEditScreen";
import type { EventMapBase, NavigationProp } from "@react-navigation/native";
import type { NavigationState } from "@react-navigation/routers";
import type { StackNavigationProp } from "@react-navigation/stack";
import {
  HeaderIcon,
  PlaceholderHeaderIcon,
} from "@app/navigation/components/HeaderIcon";
import { Analytics } from "@app/analytics";
import Icon from "@app/components/icon";
import { setQuestLastTab } from "@app/store/UI";
import { OpenSideBarButton } from "@app/navigation/components/OpenSideBarButton";
import { createLink } from "@app/util/link.utils";

const QKTabNavigator = createTabNavigator();

export type QuestTabsParamList = {
  QuestEdit: {
    questPrototypeId?: string;
    advancedMode?: boolean;
  };
  QuestTemplate: {
    questPrototypeId?: string;
    advancedMode?: boolean;
  };
  QuestRuns: {
    filters?: FilterIdsByGroupId;
  };
};
export type QMTabNavigationProp<T extends keyof QuestTabsParamList> = Omit<
  NavigationProp<
    QuestTabsParamList,
    T,
    undefined,
    NavigationState<QuestTabsParamList>,
    TabScreenOptions,
    EventMapBase
  >,
  "getParent"
> & {
  getParent: () => StackNavigationProp<QMStackParamList, "Quest">;
};

export const MainQuestScreen: React.FC = () => {
  const route = useAppRoute<"Quest">();
  const navigation = useAppNavigation();
  const { questId, advancedMode: advancedModeParam } = route.params;
  // Get Template Data
  useRequest(fetchQuest(questId));
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(advancedModeParam || false);

  const openQuestOptionsModal = useCallback(
    () => setShowOptionsModal(true),
    []
  );

  const rootQuestPrototypeId = useAppSelector((state) =>
    questId
      ? selectQuestById(state, questId)?.currentQuestPrototypeId ?? undefined
      : undefined
  );

  useQuestPrototype(rootQuestPrototypeId);

  const questName = useAppSelector((state) => {
    if (!rootQuestPrototypeId) {
      return undefined;
    }
    return selectQuestPrototypeById(state, rootQuestPrototypeId)!.name;
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: questName ?? "",
    });
  }, [rootQuestPrototypeId, navigation, questName]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    return navigation.addListener("state", (event) => {
      const rootState = event.data.state;

      const focusedRoute = rootState.routes[rootState.index];
      const questRoutes = focusedRoute.state?.routes;
      const focusedQuestRouteIndex = focusedRoute.state?.index;
      if (
        focusedRoute.name === "Quest" &&
        Array.isArray(questRoutes) &&
        typeof focusedQuestRouteIndex === "number"
      ) {
        const focusedTab = questRoutes[focusedQuestRouteIndex];

        // QuestEdit should set last tab to QuestTemplate.
        const lastTab =
          focusedTab.name === "QuestRuns" ? "QuestRuns" : "QuestTemplate";

        const questScreenParams =
          focusedRoute.params as QMStackParamList["Quest"];

        dispatch(
          setQuestLastTab({ questId: questScreenParams.questId, lastTab })
        );
      }
    });
  }, [dispatch, navigation]);

  const snackbar = useContext(SnackbarContext);

  const { onStartShouldSetResponderCapture } = useMultiTapToggler({
    tapCount: 10,
    timeWindow: 2.5 * 1000,
    onToggle: () => {
      setAdvancedMode((prev) => !prev);
      snackbar.sendMessage(
        `Advanced mode ${advancedMode ? "disabled" : "enabled"}.`
      );
    },
  });

  const questContext = useMemo(() => {
    if (!rootQuestPrototypeId) {
      return {
        isReady: false,
        questId,
        advancedMode,
      } satisfies LoadingQuestContext;
    }
    return {
      isReady: true,
      questId,
      advancedMode,
      openQuestOptionsModal,
      currentQuestPrototypeId: rootQuestPrototypeId,
      name: questName!,
    } satisfies FullQuestContext;
  }, [
    advancedMode,
    openQuestOptionsModal,
    rootQuestPrototypeId,
    questName,
    questId,
  ]);

  return (
    <View
      style={{ flex: 1 }}
      onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
    >
      <QuestContext.Provider value={questContext}>
        <QKTabNavigator.Navigator
          screenOptions={
            {
              headerLeft: OpenSideBarButton,
              headerRight: PlaceholderHeaderIcon,
            } as TabScreenOptions
          }
        >
          <QKTabNavigator.Group navigationKey={questId}>
            <QKTabNavigator.Screen
              name="QuestEdit"
              component={
                questContext.isReady
                  ? QuestEditScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={({ route }) => {
                const questPrototypeIdFromRoute = (
                  route.params as QuestTabsParamList["QuestEdit"] | undefined
                )?.questPrototypeId;

                const onPress =
                  questPrototypeIdFromRoute &&
                  questPrototypeIdFromRoute !== rootQuestPrototypeId
                    ? createLink({
                        screen: "Quest",
                        params: {
                          questId,
                          screen: "QuestEdit",
                          params: { questPrototypeId: rootQuestPrototypeId },
                        },
                      })
                    : createLink({
                        screen: "Quest",
                        params: {
                          questId,
                          screen: "QuestTemplate",
                          params: {},
                        },
                      });
                return {
                  hideTabBar: true,
                  // browser tab title
                  headerLeft: () => (
                    <HeaderIcon onPress={onPress}>
                      <Icon icon="chevron-left" size={32} />
                    </HeaderIcon>
                  ),
                  title: questName ?? "Quest Edit",
                };
              }}
            />
            <QKTabNavigator.Screen
              name="QuestTemplate"
              component={
                questContext.isReady
                  ? QuestTemplateScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={{
                // browser tab title
                title: questName ?? "Quest",
                tabTitle: "Quest",
                tabTitleIcon: "pen",
                testID: "QuestTemplateTab",
              }}
            />
            <QKTabNavigator.Screen
              name="QuestRuns"
              component={QuestRunsScreen}
              options={{
                // browser tab title
                title: questName ?? "Quest Runs",
                tabTitle: "Runs",
                tabTitleIcon: "item",
                testID: "QuestRunsTab",
                headerRight: () =>
                  rootQuestPrototypeId ? (
                    <HeaderIcon
                      testID="open-quest-options-dialog"
                      onPress={() => {
                        Analytics.trackEvent("Open Quest Options Dialog");
                        setShowOptionsModal(true);
                      }}
                    >
                      <Icon icon="gear" />
                    </HeaderIcon>
                  ) : (
                    <PlaceholderHeaderIcon />
                  ),
              }}
            />
          </QKTabNavigator.Group>
        </QKTabNavigator.Navigator>

        {!questContext.isReady ? null : (
          <>
            <QKModal
              showModal={showOptionsModal}
              setShowModal={setShowOptionsModal}
              title="Default Run Options"
            >
              <TemplateOptionsDialog
                setShowOptionsModal={setShowOptionsModal}
                setShowDuplicateModal={setShowDuplicateModal}
              />
            </QKModal>

            <QKModal
              showModal={showDuplicateModal}
              setShowModal={setShowDuplicateModal}
              title="Duplicate Quest"
            >
              <CreateQuest
                createButtonText={"Clone"}
                defaultName={questName}
                sourceTemplateId={questId}
                setShowModal={setShowDuplicateModal}
              />
            </QKModal>
          </>
        )}
      </QuestContext.Provider>
    </View>
  );
};

const CenteredLoadingIndicator: React.FC = () => (
  <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
    <Loader />
  </View>
);

const QuestTemplateScreenWrapper: React.FC = () => {
  const { currentQuestPrototypeId } = useQuestContext();
  const questPrototypeId =
    useAppRoute<"QuestTemplate">().params?.questPrototypeId;
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = questPrototypeId ?? currentQuestPrototypeId;
  return hasBeenFocused ? (
    <QuestTemplateScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};

const QuestEditScreenWrapper: React.FC = () => {
  const { currentQuestPrototypeId } = useQuestContext();
  const questPrototypeId = useAppRoute<"QuestEdit">().params?.questPrototypeId;
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = questPrototypeId ?? currentQuestPrototypeId;
  return hasBeenFocused ? (
    <QuestEditScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};
