import React from "react";

import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import Amount from "@app/components/questkit/amount";
import { ViewProps } from "react-native";

export const AmountInputItem: React.FC<ItemBaseProps> = (props) => {
  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={
        <AmountInputContainer
          onItemChange={props.onItemChange}
          onItemValidate={props.onItemValidate}
          readOnly={props.readOnly}
          item={props.item}
          accessibilityLabelledBy={`item-name-label-${props.item.prototype.id}`}
        />
      }
    />
  );
};

const AmountInputContainer: React.FC<
  Pick<
    ItemBaseProps,
    "item" | "readOnly" | "onItemChange" | "onItemValidate"
  > & { accessibilityLabelledBy?: ViewProps["accessibilityLabelledBy"] }
> = ({
  onItemChange,
  onItemValidate,
  item,
  readOnly,
  accessibilityLabelledBy,
}) => {
  const onChangeAmount = (newAmount: number) => {
    onItemChange?.({
      ...item,
      data: {
        ...item.data,
        amount: !newAmount ? 0 : newAmount,
      },
      version: item.version + 1,
    });
    onItemValidate?.();
  };

  return (
    <Amount
      // we unfortunately saved data as string before,
      // hence we parseInt for safety here until we validated/migrated
      // existing data in the database
      amount={parseInt(item.data.amount || 0)}
      readOnly={readOnly}
      onChangeAmount={onChangeAmount}
      minAmount={0}
      maxAmount={999}
      accessibilityLabelledBy={accessibilityLabelledBy}
    />
  );
};
