import React, { useContext } from "react";
import { Platform, Pressable, StyleProp, ViewStyle } from "react-native";
import styled, { DefaultTheme, ThemeContext } from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import Text from "@app/components/questkit/text";

interface DropdownProps {
  value: string;
  valueIsPlaceholder: boolean;
  leftIcon?: IconIdentifier;
  rightIcon?: IconIdentifier;
  showIconBorder: boolean;
  onPress?: () => void;
  onPressLeftIcon?: () => void;
  onPressRightIcon?: () => void;
  style?: StyleProp<ViewStyle>;
  textAlignment: "center" | "left";
  disabled?: boolean;
  readOnly?: boolean;
}

function doNothing() {
  // do nothing
}

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  valueIsPlaceholder,
  leftIcon,
  rightIcon,
  showIconBorder,
  onPress,
  onPressLeftIcon,
  onPressRightIcon,
  style,
  textAlignment,
  disabled = false,
  readOnly = false,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <StyledPressable
      onPress={onPress || doNothing}
      style={style}
      disabled={disabled || readOnly}
    >
      {leftIcon ? (
        <Pressable
          onPress={onPressLeftIcon ? onPressLeftIcon : () => undefined}
          // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
          tabIndex={onPressLeftIcon ? 0 : -1}
          disabled={disabled || readOnly}
          style={{
            marginLeft: -1,
            borderRightWidth: showIconBorder ? 1 : 0,
            borderRightColor: theme.primary,
          }}
        >
          <Icon icon={leftIcon} />
        </Pressable>
      ) : null}
      <ValueText
        numberOfLines={1}
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
        textAlignment={textAlignment}
        valueIsPlaceholder={valueIsPlaceholder}
        disabled={disabled}
      >
        {value}
      </ValueText>
      {rightIcon ? (
        <Pressable
          onPress={onPressRightIcon ? onPressRightIcon : () => undefined}
          // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
          tabIndex={onPressRightIcon ? 0 : -1}
          disabled={disabled || readOnly}
          style={{
            borderLeftWidth: showIconBorder ? 1 : 0,
            borderLeftColor: theme.primary,
          }}
        >
          <Icon icon={rightIcon} />
        </Pressable>
      ) : null}
    </StyledPressable>
  );
};

interface ValueTextProps {
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
  textAlignment: "center" | "left";
  valueIsPlaceholder: boolean;
  theme: DefaultTheme;
  disabled: boolean;
}

const ValueText = styled(Text)<ValueTextProps>`
  text-align: ${({ textAlignment }) => textAlignment};
  flex: 1;
  margin-left: ${({ hasLeftIcon }) => (hasLeftIcon ? 4 : 44)}px;
  margin-right: ${({ hasRightIcon }) => (hasRightIcon ? 4 : 44)}px;
  color: ${({ valueIsPlaceholder, theme, disabled }) =>
    valueIsPlaceholder || disabled
      ? theme.textInput.normal.placeholder
      : theme.textInput.normal.text};
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

const StyledPressable = styled.Pressable`
  height: 40px;
  border-radius: 20px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.textInput.normal.border};
  background-color: ${({ theme }) => theme.textInput.normal.background};
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
