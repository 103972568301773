import * as React from "react";
import { useTheme } from "styled-components/native";
import Svg, { G, Path } from "react-native-svg";
import isEqual from "react-fast-compare";

interface SvgPathsProps {
  paths: string[];
  strokeColor?: string;
  strokeWidth?: number;
  width?: string | number;
  height?: string | number;
}

const _SvgPaths: React.FC<SvgPathsProps> = (props) => {
  const { paths, strokeWidth = 3, width = "100%", height = "100%" } = props;
  const theme = useTheme();
  const strokeColor = props.strokeColor ?? theme.interaction.primary;
  return (
    <Svg width={width} height={height}>
      <G stroke={strokeColor} strokeWidth={strokeWidth} fill="none">
        {(paths ?? []).map((path, i) => {
          return <Path key={`path-${i}`} d={path} />;
        })}
      </G>
    </Svg>
  );
};

export const SvgPaths = React.memo(_SvgPaths, (prev, next) => {
  return (
    isEqual(prev.paths, next.paths) &&
    prev.strokeColor === next.strokeColor &&
    prev.strokeWidth === next.strokeWidth &&
    prev.width === next.width &&
    prev.height === next.height
  );
});
