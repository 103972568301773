import React, { useCallback, useLayoutEffect, useRef } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputProps,
  TextInputSubmitEditingEventData,
} from "react-native";
import { useRefSynchronizer } from "@app/util/useRefSynchronizer";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";

type RevisedText = string;
export type BaseTextInputProps = Omit<TextInputProps, "onChangeText"> & {
  /**
   * @param text The text that was entered into the TextInput
   * @returns If a string is returned, it will be used as the new text value
   */
  onChangeText?: (text: string) => void | RevisedText;
};

const BaseTextInput = React.forwardRef<TextInput, BaseTextInputProps>(
  (props, forwardRef) => {
    const { value, onChange, onChangeText, multiline } = props;

    const textInputRef = useRef<TextInput>(null);
    const ref = useRefSynchronizer(textInputRef, forwardRef);

    // Maintain a local state to fix the cursor jumping to end when editing text in the middle.
    // This is a workaround of the root issue that some consumers of this component are updating the state
    // asynchronously delaying the re-render, which causes the cursor to jump to the end.
    const [localValue, setLocalValue] = useSyncedState(value);
    const localOnChangeText = useCallback(
      (text: string) => {
        const modifiedText = onChangeText?.(text);
        setLocalValue(modifiedText !== undefined ? modifiedText : text);
      },
      [onChangeText, setLocalValue]
    );

    useLayoutEffect(() => {
      const requiresHeightAdjustment =
        Platform.OS === "web" && multiline === true;

      const el = textInputRef.current;
      if (requiresHeightAdjustment && el instanceof HTMLTextAreaElement) {
        el.style.height = "0";
        el.style.height = el.scrollHeight + "px";
      }
    }, [multiline, localValue]);

    const parentOnSubmitEditing = props.onSubmitEditing;
    const onSubmitEditing = useCallback(
      (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
        // Just the presence of this handler (even without a `parentOnSubmitEditing` handler) somehow removes flicker
        // when pressing enter on TextInputs that filter out newline characters. I think it causes an extra delay in
        // the next render.
        parentOnSubmitEditing?.(e);
      },
      [parentOnSubmitEditing]
    );

    return (
      <TextInput
        {...props}
        ref={ref}
        onChange={onChange}
        value={localValue}
        onChangeText={localOnChangeText}
        onSubmitEditing={onSubmitEditing}
      />
    );
  }
);
BaseTextInput.displayName = "BaseTextInput";

export default BaseTextInput;
