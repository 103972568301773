import { Dimensions, ScaledSize, useWindowDimensions } from "react-native";

export const DESKTOP_WIDTH_BREAKPOINT = 1080;

/**
 * @param dimensions If you use the outcome of this function for rendering purposes
 *                   you must provide this parameter from a `useWindowDimensions`
 *                   hook or just use `useIsDesktopWidth` instead.
 *                   Otherwise, it will not trigger a re-render when the window dimensions change.
 */
export const isDesktopWidth = (
  dimensions: ScaledSize = Dimensions.get("window")
): boolean => {
  return dimensions.width >= DESKTOP_WIDTH_BREAKPOINT;
};

export const useIsDesktopWidth = (): boolean =>
  isDesktopWidth(useWindowDimensions());
