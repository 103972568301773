import Svg, { Path } from "react-native-svg";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import styled, { useTheme } from "styled-components/native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import {
  LayoutChangeEvent,
  LayoutRectangle,
  StyleProp,
  ViewStyle,
} from "react-native";
import { runOnJS, useSharedValue } from "react-native-reanimated";
import { SvgPaths } from "@app/components/item/components/signature/SvgPaths";
import isEqual from "react-fast-compare";

interface DrawingCanvasProps {
  height: `${number}%` | number;
  /**
   * @default "100%"
   */
  width?: `${number}%` | number;
  containerStyle?: StyleProp<ViewStyle>;
  strokeColor?: string;
  strokeWidth?: number;
  paths: string[];
  onPathAdd?: (svgPath: string, canvasLayout: LayoutRectangle) => void;
}

// Increasing this could improve performance but will make lines more jagged.
const MIN_POINT_DISTANCE = 1;

export const DrawingCanvas: React.FC<DrawingCanvasProps> = (props) => {
  const {
    containerStyle = {},
    width = "100%",
    height = "100%",
    strokeWidth = 3,
    paths: parentPaths,
    onPathAdd = () => undefined,
  } = props;
  const theme = useTheme();
  const strokeColor = props.strokeColor ?? theme.interaction.primary;

  const [paths, setPaths, pathsRef] = useStateWithRef<string[]>(
    parentPaths ?? []
  );
  useEffect(() => {
    if (!isEqual(parentPaths, pathsRef.current)) {
      setPaths(parentPaths);
    }
  }, [parentPaths, pathsRef, setPaths]);

  const [points, setPoints, pointsRef] = useStateWithRef<Point[]>([]);
  const canvasLayoutRef = useRef<LayoutRectangle>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  });
  const onCanvasContainerLayout = useCallback((e: LayoutChangeEvent) => {
    canvasLayoutRef.current = e.nativeEvent.layout;
  }, []);

  const lastRecordedPoint = useSharedValue({ locationX: 0, locationY: 0 });
  const addNewPathFromPoints = useCallback(() => {
    if (pointsRef.current.length > 0) {
      const newPath = pointsToSvgPathCommands(
        pointsRef.current,
        canvasLayoutRef.current
      );
      // filter out any empty paths or paths with only an M command and no L commands
      if (newPath.length > 0 && newPath.indexOf("L") !== -1) {
        setPaths((paths) => {
          return [...paths, newPath];
        });
        setPoints([]);
        onPathAdd(newPath, canvasLayoutRef.current);
      }
      lastRecordedPoint.value = {
        locationX: 0,
        locationY: 0,
      };
    }
  }, [lastRecordedPoint, onPathAdd, pointsRef, setPaths, setPoints]);

  const addNewPoint = useCallback(
    (locationX: number, locationY: number) => {
      setPoints((points) => {
        return [...points, { locationX, locationY }];
      });
    },
    [setPoints]
  );

  const gesture = useMemo(
    () =>
      Gesture.Manual()
        .hitSlop(0)
        .onTouchesDown((event, stateManager) => {
          const locationX = event.changedTouches[0].x;
          const locationY = event.changedTouches[0].y;

          const minX = canvasLayoutRef.current?.x ?? 0;
          const maxX = minX + canvasLayoutRef.current?.width ?? 0;
          const minY = canvasLayoutRef.current?.y ?? 0;
          const maxY = minY + canvasLayoutRef.current?.height ?? 0;

          if (
            locationX > minX &&
            locationY > minY &&
            locationX < maxX &&
            locationY < maxY
          ) {
            stateManager.begin();
            stateManager.activate();
            runOnJS(addNewPoint)(Math.round(locationX), Math.round(locationY));
            runOnJS(addNewPoint)(locationX, locationY);
            lastRecordedPoint.value = {
              locationX,
              locationY,
            };
          }
        })
        .onTouchesMove((event) => {
          const currentX = event.changedTouches[0]?.x;
          const currentY = event.changedTouches[0]?.y;
          if (currentX === undefined || currentY === undefined) {
            return;
          }

          const lastX = lastRecordedPoint.value.locationX;
          const lastY = lastRecordedPoint.value.locationY;
          const xMovedEnough = Math.abs(lastX - currentX) > MIN_POINT_DISTANCE;
          const yMovedEnough = Math.abs(lastY - currentY) > MIN_POINT_DISTANCE;
          if (xMovedEnough || yMovedEnough) {
            lastRecordedPoint.value = {
              locationX: currentX,
              locationY: currentY,
            };
            runOnJS(addNewPoint)(currentX, currentY);
          }
        })
        .onTouchesUp((_event, stateManager) => {
          stateManager.end();
          runOnJS(addNewPathFromPoints)();
        }),
    [addNewPathFromPoints, addNewPoint, lastRecordedPoint]
  );

  return (
    <GestureDetector gesture={gesture} userSelect="none">
      <StyledCanvasContainer
        onLayout={onCanvasContainerLayout}
        style={[containerStyle, { width, height }]}
      >
        <ExistingSvgPaths
          paths={paths}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
          width={width}
          height={height}
        />
        <InProgressSvgPath width={width} height={height}>
          <Path
            d={pointsToSvgPathCommands(points, canvasLayoutRef.current)}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            fill="none"
          />
        </InProgressSvgPath>
      </StyledCanvasContainer>
    </GestureDetector>
  );
};

const StyledCanvasContainer = styled.View`
  cursor: crosshair;
`;
const InProgressSvgPath = styled(Svg)`
  position: absolute;
  cursor: crosshair;
`;
const ExistingSvgPaths = styled(SvgPaths)`
  position: absolute;
`;

function pointsToSvgPathCommands(
  points: Point[],
  canvasLayout: LayoutRectangle
) {
  const { posX, posY } = { posX: 0, posY: 0 };
  if (points.length > 1) {
    const pathCommands = [
      `M ${points[0].locationX - posX},${points[0].locationY - posY}`,
    ];

    let lastPointWasOffCanvas = false;
    let lastPoint = points[0];

    for (let i = 1; i < points.length; i++) {
      const { locationX, locationY } = points[i];
      const pointIsOnCanvas =
        locationX >= canvasLayout.x &&
        locationY >= canvasLayout.y &&
        locationX <= canvasLayout.width &&
        locationY <= canvasLayout.height;

      if (pointIsOnCanvas) {
        if (lastPointWasOffCanvas) {
          const pointOnEdgeOfCanvas = getPointOnEdgeOfCanvas(
            points[i],
            lastPoint,
            canvasLayout
          );
          pathCommands.push(
            `M ${pointOnEdgeOfCanvas.locationX},${pointOnEdgeOfCanvas.locationY}`
          );
        }
        pathCommands.push(`L ${locationX - posX},${locationY - posY}`);
      } else if (!lastPointWasOffCanvas) {
        lastPointWasOffCanvas = true;

        const pointOnEdgeOfCanvas = getPointOnEdgeOfCanvas(
          lastPoint,
          points[i],
          canvasLayout
        );
        pathCommands.push(
          `L ${pointOnEdgeOfCanvas.locationX},${pointOnEdgeOfCanvas.locationY}`
        );
      }

      lastPoint = points[i];
      lastPointWasOffCanvas = !pointIsOnCanvas;
    }
    return pathCommands.join(" ");
  } else {
    return "";
  }
}

type Point = { locationX: number; locationY: number };

/**
 *
 * We do not want to persist points that are off the canvas, so we need to replace the point that is off the canvas
 * with the point that is on the edge of the canvas while maintaining the same direction of the line.
 */

function getPointOnEdgeOfCanvas(
  pointOnCanvas: Point,
  pointOffCanvas: Point,
  canvasLayout: LayoutRectangle
): Point {
  const lastX = pointOnCanvas.locationX;
  const lastY = pointOnCanvas.locationY;
  const nextX = pointOffCanvas.locationX;
  const nextY = pointOffCanvas.locationY;

  const minY = canvasLayout.y;
  const maxY = canvasLayout.height + canvasLayout.y;
  const minX = canvasLayout.x;
  const maxX = canvasLayout.width + canvasLayout.x;

  const slope = (nextY - lastY) / (nextX - lastX);

  let newX = Math.min(Math.max(nextX, minX), maxX);
  let newY = Math.min(Math.max(nextY, minY), maxY);

  if (newY !== nextY) {
    // if newY is not on canvas (the pointOffCanvas was outside on the y-axis)
    newX = lastX + (newY - lastY) / slope;
  } else if (newX !== nextX) {
    // if newX is not on canvas (the pointOffCanvas was outside on the x-axis)
    newY = lastY + (newX - lastX) * slope;
  }

  return { locationX: newX, locationY: newY };
}
