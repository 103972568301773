import "react-native-url-polyfill/auto";
import "core-js/actual/structured-clone"; // remove when Hermes supports natively: https://github.com/facebook/hermes/issues/684
import { initSentry, sentry } from "@app/util/sentry";
initSentry();
import * as SplashScreen from "expo-splash-screen";
// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync().catch((e) => {
  console.warn("Failed to prevent splash screen from auto-hiding.", e);
});
import { LogBox } from "react-native";
LogBox.ignoreLogs([
  /^Failed to send [0-9]+ events\./g,
  /^TypeError: Network request failed/g,
  /^Could not receive settings from Segment/g,
]);

import React from "react";
import ThemeProvider from "@app/provider/ThemeProvider";
import { QKStatusBar } from "@app/components/app/StatusBar";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { Provider as ReduxProvider } from "react-redux";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { reduxPersistor, store } from "@app/store";
import { AppBackgroundContainer } from "@app/components/screen/AppBackgroundContainer";
import { SnackBar } from "@app/components/snackbar";
import { SnackbarProvider } from "@app/components/snackbar/SnackbarContext";
import { SWRConfig } from "swr";
import { initUpdateChecker } from "@app/util/updates";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { TokenProvider } from "@app/context/TokenContext";
import dateFormat from "dateformat";
import { Analytics } from "@app/analytics";
import Constants from "expo-constants";
import { useAnalyticsTracking } from "@app/analytics/useAnalyticsTracking";
import { useAppLoading } from "@app/util/appLoading";
import { QMNavigationContainer } from "@app/navigation/QMNavigationContainer";
import { TopLevelErrorBoundary } from "@app/TopLevelErrorBoundary";
import { enablePatches } from "immer";
import { SideBar } from "@app/navigation/sideBar/SideBar";
import { SideBarProvider } from "@app/navigation/sideBar/SideBarProvider";
import { SideBarSafeArea } from "@app/navigation/sideBar/SideBarSafeArea";
import {
  enableExperimentalWebImplementation,
  GestureHandlerRootView,
} from "react-native-gesture-handler";
import { QMNavigator } from "@app/navigation/QMNavigator";
import { PromiseKeeperProvider } from "@app/context/PromiseKeeper";
// import { Chat } from "@app/chat/Chat";
import { PersistGate } from "redux-persist/integration/react";
import { Platform } from "expo-modules-core";
import { enableFreeze } from "react-native-screens";
import { Auth0Provider } from "@app/authentication/Auth0Provider";
import { ModalContextProvider } from "@app/components/modal/ModalManager";
import { PortalProvider } from "@gorhom/portal";
import { TouchDetectorView } from "@app/util/TouchDetector";
import { swrNativeMiddleware } from "@app/util/swrNativeMiddleware";
import { OverlayManagerProvider } from "@app/util/OverlayManagerProvider";

{
  Analytics.initialize();
  Analytics.setUserProperties({
    AppVersion: Constants.expoConfig?.version,
  });
  Analytics.trackEvent("Start App");

  let lastLoggedInUserId: string | null = null;
  Analytics.setUser(lastLoggedInUserId);
  store.subscribe(() => {
    const currentLoggedInUserId = store.getState().auth.loggedInUserId;
    if (currentLoggedInUserId !== lastLoggedInUserId) {
      Analytics.setUser(currentLoggedInUserId);
      sentry.setUser({
        id: currentLoggedInUserId === null ? undefined : currentLoggedInUserId,
      });
    }
    lastLoggedInUserId = currentLoggedInUserId;
  });
}
// Create Date Format
dateFormat.masks.qmDateTime = "yyyy/mm/dd hh.MM tt";
dateFormat.masks.qmDate = "yyyy/mm/dd";

initUpdateChecker();

// used by useTrackChanges
enablePatches();

// used for signature pad
enableExperimentalWebImplementation();

// allows non-focused screens to have renders frozen until they become active again.
enableFreeze();

function App(): React.ReactElement | null {
  useAnalyticsTracking();

  const [appIsReady, onLayoutRootView] = useAppLoading();

  if (!appIsReady) {
    return null;
  }

  return (
    <ThemeProvider>
      <TopLevelErrorBoundary>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={reduxPersistor}>
            <SWRConfig
              value={{
                use: [swrNativeMiddleware],
                shouldRetryOnError: false,
                revalidateOnFocus: true,
                revalidateOnReconnect: true,
              }}
            >
              <PromiseKeeperProvider>
                <TokenProvider>
                  <SafeAreaProvider>
                    <SnackbarProvider>
                      <GestureHandlerRootView style={{ flex: 1 }}>
                        <ActionSheetProvider>
                          <AppBackgroundContainer onLayout={onLayoutRootView}>
                            <PortalProvider
                              shouldAddRootHost={true}
                              rootHostName={"rootPortal"}
                            >
                              <TouchDetectorView>
                                <OverlayManagerProvider>
                                  <Auth0Provider>
                                    <QMNavigationContainer>
                                      <SideBarProvider>
                                        <ModalContextProvider>
                                          <QKStatusBar />
                                          <SideBarSafeArea>
                                            <QMNavigator />
                                          </SideBarSafeArea>
                                          <SideBar />
                                          {/*<Chat />*/}
                                          <SnackBar />
                                        </ModalContextProvider>
                                      </SideBarProvider>
                                    </QMNavigationContainer>
                                  </Auth0Provider>
                                </OverlayManagerProvider>
                              </TouchDetectorView>
                            </PortalProvider>
                          </AppBackgroundContainer>
                        </ActionSheetProvider>
                      </GestureHandlerRootView>
                    </SnackbarProvider>
                  </SafeAreaProvider>
                </TokenProvider>
              </PromiseKeeperProvider>
            </SWRConfig>
          </PersistGate>
        </ReduxProvider>
      </TopLevelErrorBoundary>
    </ThemeProvider>
  );
}

export default Platform.select({
  native: () => sentry.wrap(App),
  default: () => App,
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
