import {
  QuestInstanceDetail,
  QuestInstanceListItem,
} from "@questmate/openapi-spec";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLogout } from "@app/store/auth";
import {
  questInstanceListLoaded,
  questInstanceLoaded,
} from "@app/store/cache/questInstances";
import { isNotUndefined } from "@questmate/common";
import isEqual from "react-fast-compare";
import {
  createSearchTerm,
  SearchTerm,
} from "@app/components/questkit/searchFilter";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";

type QuestRunListViewModel = {
  id: string;
  startedAt: string;
  sectionKey: string;
  searchTerms: SearchTerm[];
};
type QuestInstanceViewsState = {
  /**
   * Quest Instance IDs by Quest sorted by `startedAt` DESC
   */
  sortedIdsByQuestId: Record<string, string[]>;
  runListViewsById: Record<string, QuestRunListViewModel>;
};

const initialState = (): QuestInstanceViewsState => ({
  sortedIdsByQuestId: {},
  runListViewsById: {},
});

const slice = createSlice({
  name: "cache/questInstanceViews",
  initialState: initialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => initialState());
    builder.addCase(
      questInstanceListLoaded,
      (state, action: PayloadAction<QuestInstanceListItem[]>) => {
        const preSortedNewIds: string[] = [];
        for (let i = 0; i < action.payload.length; i++) {
          const questInstance = action.payload[i];
          if (!(questInstance.id in state.runListViewsById)) {
            /**
             * Only add new IDs to the list. Avoid duplicates. `startedAt` is immutable.
             */
            preSortedNewIds.push(questInstance.id);
          }

          const view = {
            id: questInstance.id,
            startedAt: questInstance.startedAt!,
            sectionKey: sectionGroupIdentifierFormatter.format(
              new Date(questInstance.startedAt!)
            ),
            searchTerms: [
              createSearchTerm(questInstance.name),
              createSearchTerm(questInstance.submittedByUser?.displayName),
              ...(questInstance.assignments?.map((a) =>
                createSearchTerm(a.assignee?.displayName)
              ) ?? []),
            ].filter(isNotUndefined),
          } satisfies QuestRunListViewModel;

          if (!isEqual(view, state.runListViewsById[questInstance.id])) {
            state.runListViewsById[questInstance.id] = view;
          }
        }
        if (preSortedNewIds.length === 0) {
          return;
        }
        const questId = action.payload[0].quest.id;

        if (!state.sortedIdsByQuestId[questId]) {
          state.sortedIdsByQuestId[questId] = [];
        }

        mergeTwoSortedArrays(
          state.sortedIdsByQuestId[questId],
          preSortedNewIds,
          startedAtDescendingComparator(state)
        );
      }
    );
    builder.addCase(
      questInstanceLoaded,
      (state, action: PayloadAction<QuestInstanceDetail>) => {
        const alreadyCached = action.payload.id in state.runListViewsById;
        const view = {
          id: action.payload.id,
          startedAt: action.payload.startedAt!,
          sectionKey: sectionGroupIdentifierFormatter.format(
            new Date(action.payload.startedAt!)
          ),
          searchTerms: [
            createSearchTerm(action.payload.name),
            createSearchTerm(action.payload.submittedByUser?.displayName),
            ...(action.payload.assignments?.map((a) =>
              createSearchTerm(a.assignee?.displayName)
            ) ?? []),
          ].filter(isNotUndefined),
        } satisfies QuestRunListViewModel;
        if (!isEqual(view, state.runListViewsById[action.payload.id])) {
          state.runListViewsById[action.payload.id] = view;
        }
        if (alreadyCached) {
          /**
           * Exit early. Avoid duplicating the ID in the sorted list. `startedAt` is immutable.
           */
          return;
        }
        const questId = action.payload.quest.id;
        if (!state.sortedIdsByQuestId[questId]) {
          state.sortedIdsByQuestId[questId] = [];
        }

        const insertIndex = findInsertIndex(
          state.sortedIdsByQuestId[questId],
          action.payload.id,
          startedAtDescendingComparator(state)
        );
        state.sortedIdsByQuestId[questId].splice(
          insertIndex,
          0,
          action.payload.id
        );
      }
    );

    builder.addCase(questPrototypeLoaded, (state, action) => {
      // TODO: Reduce duplication between here and the other action handlers.
      //  It's a bit tricky b/c of the different payloads
      //  and we do not want to lose any performance in the `questInstanceListLoaded` handler.
      const assignments = action.payload.assignments;
      if (
        Array.isArray(assignments) &&
        assignments.length > 0 &&
        assignments[0].formInstance?.id
      ) {
        const preSortedNewIds: string[] = [];
        for (let i = 0; i < assignments.length; i++) {
          const assignment = assignments[i];
          const questInstance = assignment?.formInstance;
          if (!questInstance?.id) {
            continue;
          }
          if (!(questInstance.id in state.runListViewsById)) {
            /**
             * Only add new IDs to the list. Avoid duplicates. `startedAt` is immutable.
             */
            preSortedNewIds.push(questInstance.id);
          }

          const view = {
            id: questInstance.id,
            startedAt: questInstance.startedAt!,
            sectionKey: sectionGroupIdentifierFormatter.format(
              new Date(questInstance.startedAt!)
            ),
            searchTerms: [
              createSearchTerm(action.payload.name),
              // createSearchTerm(questInstance.submittedByUser?.displayName),
              createSearchTerm(assignment.assignee?.displayName),
            ].filter(isNotUndefined),
          } satisfies QuestRunListViewModel;

          if (!isEqual(view, state.runListViewsById[questInstance.id])) {
            state.runListViewsById[questInstance.id] = view;
          }
        }
        if (preSortedNewIds.length === 0) {
          return;
        }
        const questId = action.payload.quest.id;

        if (!state.sortedIdsByQuestId[questId]) {
          state.sortedIdsByQuestId[questId] = [];
        }

        mergeTwoSortedArrays(
          state.sortedIdsByQuestId[questId],
          preSortedNewIds,
          startedAtDescendingComparator(state)
        );
      }

      if (action.payload.sharedInstance?.id) {
        const alreadyCached =
          action.payload.sharedInstance.id in state.runListViewsById;
        const view = {
          id: action.payload.sharedInstance.id,
          startedAt: action.payload.sharedInstance.startedAt!,
          sectionKey: sectionGroupIdentifierFormatter.format(
            new Date(action.payload.sharedInstance.startedAt!)
          ),
          searchTerms: [
            createSearchTerm(action.payload.name),
            // createSearchTerm(action.payload.sharedInstance.submittedByUser?.displayName),
            ...(action.payload.sharedInstance.assignments?.map((a) =>
              createSearchTerm(a.assignee?.displayName)
            ) ?? []),
          ].filter(isNotUndefined),
        } satisfies QuestRunListViewModel;
        if (
          !isEqual(
            view,
            state.runListViewsById[action.payload.sharedInstance.id]
          )
        ) {
          state.runListViewsById[action.payload.sharedInstance.id] = view;
        }
        if (alreadyCached) {
          /**
           * Exit early. Avoid duplicating the ID in the sorted list. `startedAt` is immutable.
           */
          return;
        }
        const questId = action.payload.quest.id;
        if (!state.sortedIdsByQuestId[questId]) {
          state.sortedIdsByQuestId[questId] = [];
        }

        const insertIndex = findInsertIndex(
          state.sortedIdsByQuestId[questId],
          action.payload.sharedInstance.id,
          startedAtDescendingComparator(state)
        );
        state.sortedIdsByQuestId[questId].splice(
          insertIndex,
          0,
          action.payload.sharedInstance.id
        );
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

// export const {  } = slice.actions;

/**
 * Create a section group identifier to use to group Quest runs by started at.
 * By choosing AU format (not timezone),
 * the day of month is the first part of the string
 * and will minimize time to compare if the same day due to the implementation of grouping.
 */
const sectionGroupIdentifierFormatter = new Intl.DateTimeFormat("en-AU", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

/**
 * The goal of this function is to insert the new IDs into the sorted list of IDs in the most performant way as possible.
 * Because the existing list is already sorted and the new IDs are also sorted (from the API),
 * we can take advantage of this to avoid having to re-sort the entire list.
 * Additionally, we want to minimize the number of insertions
 * (calls to `.slice(...)`) into the list to avoid unnecessary overhead.
 */
function mergeTwoSortedArrays(
  existingSortedIds: string[],
  preSortedNewIds: string[],
  comparator: ComparisonFunction<string>
) {
  const firstInstanceId = preSortedNewIds[0];
  const lastInstanceId = preSortedNewIds[preSortedNewIds.length - 1];

  const startIndex = findInsertIndex(
    existingSortedIds,
    firstInstanceId,
    comparator
  );
  const endIndex = findInsertIndex(
    existingSortedIds,
    lastInstanceId,
    comparator,
    startIndex
  );

  const overlappingExistingIds = existingSortedIds.slice(startIndex, endIndex);
  let newIdIndexOfLastInsert = 0;
  let lastRelativeInsertIndex = 0;
  for (let i = 1; i < preSortedNewIds.length; i++) {
    const relativeInsertIndex = findInsertIndex(
      overlappingExistingIds,
      preSortedNewIds[i],
      comparator,
      lastRelativeInsertIndex
    );
    if (lastRelativeInsertIndex !== relativeInsertIndex) {
      const insertIndex =
        startIndex + newIdIndexOfLastInsert + lastRelativeInsertIndex;
      const arrayToInsert = preSortedNewIds.slice(newIdIndexOfLastInsert, i);
      existingSortedIds.splice(insertIndex, 0, ...arrayToInsert);
      newIdIndexOfLastInsert = i;
      lastRelativeInsertIndex = relativeInsertIndex;
    }
  }
  existingSortedIds.splice(
    startIndex + newIdIndexOfLastInsert + lastRelativeInsertIndex,
    0,
    ...preSortedNewIds.slice(newIdIndexOfLastInsert)
  );
}

const startedAtDescendingComparator =
  (state: QuestInstanceViewsState) => (_a: string, _b: string) => {
    const a = state.runListViewsById[_a]?.startedAt;
    const b = state.runListViewsById[_b]?.startedAt;
    if (a === b) {
      // Sort by id if `startedAt` is the same. Mimics the behavior of the API.
      return state.runListViewsById[_a]?.id > state.runListViewsById[_b]?.id
        ? -1
        : 1;
    } else if (!a) {
      return 1;
    } else if (!b) {
      return -1;
    }

    return a > b ? -1 : 1;
    // date comparison should not be needed since `startedAt` is a UTC ISO formatted string
    // return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
  };

/**
 * Fast insert of a new item into a sorted array.
 * Taken from: https://github.com/replayio/devtools/blob/ce97fc7abfd5ae03f767216efa44173209c27cdc/packages/replay-next/src/utils/array.ts#L131
 */
type ComparisonFunction<T> = (a: T, b: T) => number;
export function findInsertIndex<T>(
  sortedItems: T[],
  item: T,
  comparisonFunction: ComparisonFunction<T>,
  lowIndexOverride?: number
): number {
  let lowIndex = lowIndexOverride ?? 0;
  let highIndex = sortedItems.length;
  while (lowIndex < highIndex) {
    const middleIndex = (lowIndex + highIndex) >>> 1;
    const currentItem = sortedItems[middleIndex];
    if (comparisonFunction(item, currentItem) > 0) {
      lowIndex = middleIndex + 1;
    } else {
      highIndex = middleIndex;
    }
  }

  return lowIndex;
}
