import {
  QuestInstanceDetail,
  QuestInstances,
  QuestPrototypeDetail,
  Quests,
  RecentQuestsListItem,
  RestartRunResponse,
  RewardInstance,
  TemplateCopyRequest,
  TemplateDetails,
  TemplateListItem,
  Templates,
} from "@questmate/openapi-spec";
import { store } from "@app/store";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { questLoaded, questsLoaded } from "@app/store/cache/quests";
import { createRequest, noopRequest } from "@app/util/client/requests/index";
import { apiRequest } from "@app/util/client";
import {
  questInstanceLoaded,
  setNextInstanceId,
} from "@app/store/cache/questInstances";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { sentry } from "@app/util/sentry";
import { itemInstanceLoaded } from "@app/store/cache/itemInstances";
import { addSessionToken } from "@app/store/publicQuestAuth";
import { rewardInstanceLoaded } from "@app/store/cache/rewardInstances";

export const fetchQuestPrototype = (questPrototypeId: string | undefined) => {
  if (!questPrototypeId) {
    return noopRequest();
  }
  return createRequest<QuestPrototypeDetail>(
    "get",
    `/quests/${questPrototypeId}`
  )((response) => {
    store.dispatch(questPrototypeLoaded(response));
  });
};

export const fetchQuests = (query?: Templates.TemplatesList.RequestQuery) => {
  const params = new URLSearchParams({});
  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      params.append(key, String(value));
    });
  }
  return createRequest<TemplateListItem[]>(
    "get",
    `/templates?${params.toString()}`
  )((response) => {
    store.dispatch(questsLoaded(response));
  });
};
export const fetchRecentQuests = (limit: number) => {
  const params = new URLSearchParams({});
  if (limit) {
    params.append("limit", String(limit));
  }
  return createRequest<RecentQuestsListItem[]>(
    "get",
    `/internal/quests/recent?${params.toString()}`
  )((response) => {
    store.dispatch(questsLoaded(response));
  });
};
export const fetchQuest = (questId: string | null) => {
  if (!questId) {
    return noopRequest();
  }
  return createRequest<TemplateDetails>(
    "get",
    `/templates/${questId}`
  )((response) => {
    store.dispatch(questLoaded(response));
  });
};

export const createQuest = async (
  options: TemplateCopyRequest
): Promise<TemplateDetails> => {
  return apiRequest<TemplateDetails>("post", `/templates`, options).then(
    (response) => {
      store.dispatch(questLoaded(response));
      return response;
    }
  );
};

export const updateQuest = async (
  templateId: string,
  options: Templates.TemplatesPartialUpdate.RequestBody
): Promise<TemplateDetails> => {
  return apiRequest<TemplateDetails>(
    "patch",
    `/templates/${templateId}`,
    options
  ).then((response) => {
    store.dispatch(questLoaded(response));
    return response;
  });
};

export const updateQuestArchiveStatus = async (
  templateId: string,
  action: "archive" | "unarchive"
): Promise<TemplateDetails> => {
  return apiRequest<{ success: boolean; data: TemplateDetails }>(
    "post",
    `/templates/${templateId}/${action}`
  )
    .then((response) => {
      if (response.success) {
        store.dispatch(questLoaded(response.data));
      } else {
        throw new Error("Failed to update quest archive status");
      }
      return response.data;
    })
    .catch((e) => {
      console.error("Failed to update archive status:", e);
      sentry.captureException(e, { extra: { templateId, action } });
      throw e;
    });
};

export const updateQuestPrototype = async (
  questPrototypeId: string,
  options: Quests.QuestsPartialUpdate.RequestBody
): Promise<QuestPrototypeDetail> => {
  return apiRequest<QuestPrototypeDetail>(
    "patch",
    `/quests/${questPrototypeId}`,
    options
  ).then((response) => {
    store.dispatch(questPrototypeLoaded(response));
    return response;
  });
};

// export const createQuestRunDraft = async (
//   options: Quests.QuestsCreate.RequestBody
// ): Promise<QuestPrototypeDetail> => {
//   return apiRequest<QuestPrototypeDetail>("post", `/quests`, options).then(
//     (response) => {
//       store.dispatch(questPrototypeLoaded(response));
//       return response;
//     }
//   );
// };

export const startQuestPrototype = async (
  questPrototypeId: string,
  options: Quests.StartCreate.RequestBody
): Promise<QuestPrototypeDetail> => {
  return apiRequest<QuestPrototypeDetail>(
    "post",
    `/quests/${questPrototypeId}/start`,
    options
  ).then((response) => {
    store.dispatch(questPrototypeLoaded(response));
    return response;
  });
};

export const fetchQuestInstance = (
  questInstanceId: string | undefined,
  publicSessionToken?: string
) => {
  const userIsLoggedIn = Boolean(getLoggedInUserId());
  const noAvailableAuthenticationMechanisms =
    !userIsLoggedIn && !publicSessionToken;

  return !questInstanceId || noAvailableAuthenticationMechanisms
    ? noopRequest()
    : createRequest<QuestInstanceDetail>(
        "get",
        `/questInstances/${questInstanceId}`,
        undefined,
        publicSessionToken
      )((response) => {
        store.dispatch(questInstanceLoaded(response));
      });
};

export const updateQuestInstance = (
  questInstanceId: string | undefined,
  options: QuestInstances.QuestInstancesPartialUpdate.RequestBody
) => {
  return apiRequest<QuestInstances.QuestInstancesPartialUpdate.ResponseBody>(
    "patch",
    `/questInstances/${questInstanceId}`,
    options
  ).then((response) => {
    store.dispatch(questInstanceLoaded(response));
    return response;
  });
};

export const submitQuestInstance = async (
  questInstanceId: string,
  options: QuestInstances.SubmitCreate.RequestBody,
  publicSessionToken?: string
): Promise<QuestInstanceDetail> => {
  return apiRequest<QuestInstanceDetail>(
    "post",
    `/questInstances/${questInstanceId}/submit`,
    options,
    publicSessionToken
  ).then((response) => {
    store.dispatch(questInstanceLoaded(response));
    return response;
  });
};

export const restartQuestInstance = async (
  questInstanceId: string,
  publicSessionToken?: string
): Promise<RestartRunResponse> => {
  return apiRequest<RestartRunResponse>(
    "POST",
    `/questInstances/${questInstanceId}/restart`,
    {},
    publicSessionToken
  ).then((response) => {
    if (response.questInstanceId) {
      store.dispatch(
        setNextInstanceId({
          initialInstanceId: questInstanceId,
          nextInstanceId: response.questInstanceId,
        })
      );
      if (response.publicQuestSessionToken) {
        store.dispatch(
          addSessionToken({
            questInstanceId: response.questInstanceId,
            sessionToken: response.publicQuestSessionToken,
          })
        );
      }
    }
    return response;
  });
};

export const updateItemInstance = async (
  questInstanceId: string,
  itemPrototypeId: string,
  body: QuestInstances.ItemPartialUpdate.RequestBody,
  publicSessionToken?: string
): Promise<QuestInstances.ItemPartialUpdate.ResponseBody> => {
  const itemId = `${questInstanceId}%7C${itemPrototypeId}`;

  return apiRequest<QuestInstances.ItemPartialUpdate.ResponseBody>(
    "patch",
    `/questInstances/${questInstanceId}/item/${itemId}`,
    body,
    publicSessionToken
  ).then((response) => {
    store.dispatch(
      itemInstanceLoaded({ ...response, formInstance: { id: questInstanceId } })
    );
    return response;
  });
};

export const fetchRewardInstance = (
  questInstanceId: string,
  rewardPrototypeId: string,
  publicSessionToken?: string
) => {
  return createRequest<RewardInstance>(
    "get",
    `/questInstances/${questInstanceId}/rewards/${rewardPrototypeId}`,
    undefined,
    publicSessionToken
  )((response) => {
    store.dispatch(
      rewardInstanceLoaded({
        ...response,
        formInstance: { id: questInstanceId },
      })
    );
  });
};
