import { QMTrackPlayer } from "@app/audio/QMTrackPlayer";
import { createDebugLogger } from "@app/config/logger";
import { ENV } from "@app/config/env";
import {
  type Playlist,
  PlaylistClass,
  type TrackDataChangeListener,
} from "@app/audio/PlaylistContext";
import { useEffect, useId } from "react";

export interface PlaylistManager {
  usePlaylist: (playlistId: string) => Playlist;
  getActivePlaylist: () => Playlist | undefined;
  playTrack: (playlistId: string, trackId: string) => Promise<void>;
}

class PlaylistManagerClass implements PlaylistManager {
  private static instance: PlaylistManagerClass;
  private playlists: Record<string, Playlist> = {};
  private activePlaylistId?: string;
  private debug = createDebugLogger(
    "PlaylistManager",
    () => ENV.logLevels.trackPlayer === "debug"
  );

  private constructor() {}

  public static getInstance(): PlaylistManagerClass {
    if (!PlaylistManagerClass.instance) {
      PlaylistManagerClass.instance = new PlaylistManagerClass();
    }
    return PlaylistManagerClass.instance;
  }

  public usePlaylist(playlistId: string): Playlist {
    const id = useId();
    if (!this.playlists[playlistId]) {
      this.playlists[playlistId] = new PlaylistClass(playlistId);
      this.playlists[playlistId].registerHandle(id);
    }

    useEffect(() => {
      this.playlists[playlistId].registerHandle(id);
      return () => {
        this.playlists[playlistId]?.unregisterHandle(id);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return this.playlists[playlistId];
  }

  public getActivePlaylist(): Playlist | undefined {
    if (!this.activePlaylistId || !(this.activePlaylistId in this.playlists)) {
      return undefined;
    }
    return this.playlists[this.activePlaylistId];
  }

  public async playTrack(playlistId: string, trackId: string): Promise<void> {
    this.debug("Requested to play track", { playlistId, trackId });
    if (this.activePlaylistId === playlistId) {
      await QMTrackPlayer.play(trackId);
    } else {
      // Set the playlist as active first.
      this.debug(
        "Playlist not active. Resetting player and Queuing playlist.",
        {
          playlistId,
          trackId,
        }
      );
      void this.clearActivePlaylist();

      if (!(playlistId in this.playlists)) {
        throw new Error("Unable to find playlist to play track!");
      }
      this.activePlaylistId = playlistId;

      const playlist = this.playlists[playlistId];
      const tracks = playlist.getTracks();
      const trackToPlay = tracks.find((t) => t.trackId === trackId);

      if (!trackToPlay) {
        throw new Error("Unable to find track in playlist!");
      }

      // Queue the track to play first to avoid waiting for another track to
      // load before the track to play
      void QMTrackPlayer.addOrUpdate(trackToPlay);

      for (const track of tracks) {
        if (track.trackId !== trackToPlay.trackId) {
          void QMTrackPlayer.addOrUpdate(track);
        }
      }

      // Subscribe after synchronously queuing up all tracks.
      // That way we do not miss any updates.
      this.subscribeToActivePlaylist();

      this.debug("Playlist queued. Playing requested track.", {
        playlistId,
        trackId,
      });
      await QMTrackPlayer.play(trackId);
    }
  }

  private async clearActivePlaylist() {
    const activePlaylist = this.activePlaylistId
      ? this.playlists[this.activePlaylistId]
      : undefined;
    if (activePlaylist) {
      void activePlaylist.pause(); // to inform tracks of state update.
      activePlaylist.stopTrackingChanges();
    }
    const resetPromise = QMTrackPlayer.reset();
    this.unsubscribeFromActivePlaylist();
    this.activePlaylistId = undefined;
    return resetPromise;
  }

  private handleTrackDataChange: TrackDataChangeListener = (event) => {
    if (event.type === "addedOrUpdated") {
      void QMTrackPlayer.addOrUpdate(event.track);
    } else if (event.type === "removed") {
      void QMTrackPlayer.remove(event.trackId);
    }
  };

  private subscribeToActivePlaylist() {
    if (!this.activePlaylistId) {
      return;
    }
    const activePlaylist = this.playlists[this.activePlaylistId];
    activePlaylist.on("track-data", this.handleTrackDataChange);
  }

  private unsubscribeFromActivePlaylist() {
    if (!this.activePlaylistId) {
      return;
    }
    const activePlaylist = this.playlists[this.activePlaylistId];
    activePlaylist.off("track-data", this.handleTrackDataChange);
    if (activePlaylist.isDetached) {
      delete this.playlists[this.activePlaylistId!];
    }
  }
}

export const PlaylistManager = PlaylistManagerClass.getInstance();
