import {
  CreateTeamRequest,
  PatchTeamRequest,
  TeamDetails,
  TeamListItem,
  TeamMembershipListItem,
  Teams,
} from "@questmate/openapi-spec";
import { store } from "@app/store";
import { teamLoaded, teamsLoaded } from "@app/store/cache/teams";
import { apiRequest } from "@app/util/client";
import {
  teamMemberAdded,
  teamMemberDeleted,
  teamMemberLoaded,
} from "@app/store/cache/teamMemberships";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { createRequest } from "@app/util/client/requests/index";

export const fetchTeam = (teamId: string) => {
  return createRequest<TeamDetails>(
    "get",
    `/teams/${teamId}`
  )((response) => {
    store.dispatch(teamLoaded(response));
  });
};

export const fetchTeams = () => {
  return createRequest<TeamListItem[]>(
    "get",
    `/teams`
  )((response) => {
    store.dispatch(
      teamsLoaded({ userId: getLoggedInUserId()!, teams: response })
    );
  });
};

export const createTeam = async (
  fields: CreateTeamRequest
): Promise<TeamDetails> => {
  return apiRequest<TeamDetails>("post", `/teams`, fields).then((response) => {
    store.dispatch(teamLoaded(response));
    return response;
  });
};

export const updateTeam = async (
  teamId: string,
  fields: PatchTeamRequest
): Promise<TeamDetails> => {
  return apiRequest<TeamDetails>("patch", `/teams/${teamId}`, fields).then(
    (response) => {
      store.dispatch(teamLoaded(response));
      return response;
    }
  );
};

export const addTeamMember = async (
  teamId: string,
  userId: string
): Promise<TeamMembershipListItem> => {
  return apiRequest<TeamMembershipListItem>(
    "post",
    `/teams/${teamId}/memberships`,
    {
      userId,
    }
  ).then((response) => {
    store.dispatch(teamMemberAdded({ teamId, membership: response }));
    return response;
  });
};

export const deleteTeamMember = async (
  teamId: string,
  membershipId: string
): Promise<Teams.MembershipsDelete.ResponseBody> => {
  return apiRequest<Teams.MembershipsDelete.ResponseBody>(
    "delete",
    `/teams/${teamId}/memberships/${membershipId}`
  ).then((response) => {
    store.dispatch(teamMemberDeleted({ teamId, membershipId }));
    return response;
  });
};

export const patchTeamMember = async (
  teamId: string,
  membershipId: string,
  fields: Teams.MembershipsPartialUpdate.RequestBody
): Promise<Teams.MembershipsPartialUpdate.ResponseBody> => {
  return apiRequest<Teams.MembershipsPartialUpdate.ResponseBody>(
    "patch",
    `/teams/${teamId}/memberships/${membershipId}`,
    fields
  ).then((response) => {
    store.dispatch(teamMemberLoaded({ teamId, membership: response }));
    return response;
  });
};
