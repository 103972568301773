import { Platform, ViewProps } from "react-native";
import styled from "styled-components/native";
import { useIsFocused } from "@react-navigation/native";
import React from "react";

interface ScreenContainerProps extends Omit<ViewProps, "testID"> {}

export const ScreenContainer: React.FC<ScreenContainerProps> = (props) => {
  const isFocusedScreen = useIsFocused();
  return (
    <StyledView
      {...props}
      isFocusedScreen={isFocusedScreen}
      testID={isFocusedScreen ? "focused-screen" : "background-screen"}
    />
  );
};

const StyledView = styled.View<{ isFocusedScreen: boolean }>`
  flex: 1;
  ${({ isFocusedScreen }) =>
    Platform.OS === "web" && !isFocusedScreen ? "visibility: hidden" : ""};
`;
