import React, { useRef } from "react";
import createIconSet from "@expo/vector-icons/build/createIconSet";
import styled from "styled-components/native";
import { StyleProp, TextStyle } from "react-native";
import { Image } from "expo-image";
import equal from "react-fast-compare";
import QuestmateIcons from "./QuestmateIcons.generated";
import { RequireAtLeastOne } from "@app/types";
import { sentry } from "@app/util/sentry";

export type ValidIconName = typeof QuestmateIcons[number]["name"];
export type ValidIconSize = 16 | 24 | 32 | 60 | 80;

const glyphMap = QuestmateIcons.reduce((acc, icon) => {
  acc[icon.name] = icon.code;
  return acc;
}, {} as Record<ValidIconName, number>);

const IconSet = createIconSet(
  glyphMap,
  "QuestmateIcon",
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("@assets/fonts/QuestmateIcon.ttf")
);

export const NO_ICON = "NONE";
type ExternalIconIdentifier = { url: string };
export type IconIdentifier =
  | ValidIconName
  | typeof NO_ICON
  | ExternalIconIdentifier;
export interface IconProps {
  icon: IconIdentifier;
  size?: ValidIconSize;
  container?:
    | "DEFAULT"
    | "COLLAPSED"
    | RequireAtLeastOne<
        { height?: number | string; width?: number | string },
        "height" | "width"
      >;
  offsetX?: number;
  offsetY?: number;
  inverted?: boolean;
  disabled?: boolean;
  style?: StyleProp<TextStyle>;
  testID?: string;
}

const _Icon: React.FC<IconProps> = (props) => {
  const reportErrorOnceRef = useRef(false);
  if (!isIconIdentifier(props.icon)) {
    reportError(
      props,
      reportErrorOnceRef,
      `Invalid IconIdentifier provided for Icon.`
    );
  } else if (
    typeof props.icon === "string" &&
    props.icon !== NO_ICON &&
    !glyphMap[props.icon]
  ) {
    reportError(
      props,
      reportErrorOnceRef,
      `"${props.icon}" is not valid icon name.`
    );
  }

  const iconFontSize = props.size ?? 24;
  const { height: containerHeight, width: containerWidth } =
    getContainerDimensions(props.container, iconFontSize);
  return (
    <IconContainer
      height={containerHeight}
      width={containerWidth}
      offsetX={props.offsetX}
      offsetY={props.offsetY}
    >
      {typeof props.icon === "object" && props.icon?.url ? (
        <Image
          source={props.icon.url}
          style={{ width: iconFontSize, height: iconFontSize }}
        />
      ) : props.icon && props.icon !== NO_ICON ? (
        <StyledIconSet
          style={props.style}
          name={props.icon}
          size={iconFontSize}
          inverted={props.inverted}
          disabled={props.disabled}
          selectable={false}
        />
      ) : null}
    </IconContainer>
  );
};

export function isIconIdentifier<T = unknown>(
  icon: IconProps["icon"] | T
): icon is IconProps["icon"] {
  return !!icon && (typeof icon === "string" || isExternalIconIdentifier(icon));
}

export function isExternalIconIdentifier<T = unknown>(
  icon: IconIdentifier | T
): icon is ExternalIconIdentifier {
  // noinspection SuspiciousTypeOfGuard
  return (
    !!icon &&
    typeof icon === "object" &&
    "url" in icon &&
    typeof icon.url === "string"
  );
}

function reportError(
  props: unknown,
  reportErrorOnceRef: React.MutableRefObject<boolean>,
  message: string
) {
  const error = new Error(message);
  if (__DEV__) {
    console.info("Icon Props", JSON.stringify(props));
    throw error;
  } else if (!reportErrorOnceRef.current) {
    reportErrorOnceRef.current = true;
    const eventId = sentry.captureException(error, { extra: { props } });
    console.error(`${message} -- ${eventId}`);
  }
}

function getContainerDimensions(
  container: IconProps["container"],
  iconFontSize: number
): { height: number | string; width: number | string } {
  const lineHeightMultiplier = 1 + 1 / 3;

  if (!container || container === "DEFAULT") {
    return {
      width: iconFontSize * lineHeightMultiplier + 8,
      height: iconFontSize * lineHeightMultiplier,
    };
  } else if (container === "COLLAPSED") {
    return {
      width: iconFontSize,
      height: iconFontSize,
    };
  } else {
    return {
      width: container.width ?? iconFontSize * lineHeightMultiplier + 8,
      height: container.height ?? iconFontSize * lineHeightMultiplier,
    };
  }
}

interface IconContainerProps extends Pick<IconProps, "offsetX" | "offsetY"> {
  height: undefined | number | string;
  width: number | string;
}
const IconContainer = styled.View<IconContainerProps>`
  height: ${({ height }) =>
    typeof height === "number" ? `${height}px` : height};
  width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  align-items: center;
  justify-content: center;
  ${({ offsetX }) =>
    !offsetX
      ? ""
      : offsetX < 0
      ? `margin-left: ${offsetX}px;`
      : `margin-right: ${-offsetX}px;`}
  ${({ offsetY }) => (offsetY ? `margin-top: ${offsetY}px;` : "")}
`;

const StyledIconSet = styled(IconSet)<
  Pick<IconProps, "inverted" | "disabled" | "size" | "offsetX" | "offsetY">
>`
  color: ${({ theme, inverted, disabled }) =>
    disabled ? theme.inactive : inverted ? theme.background : theme.primary};
  line-height: ${({ size }) => size}px;
  ${({ offsetX }) => (offsetX ? `margin-left: ${offsetX}px;` : "")}
  ${({ offsetY }) => (offsetY ? `margin-top: ${offsetY}px;` : "")}
`;

const Icon = React.memo(_Icon, equal);
Icon.displayName = "Icon";

export default Icon;
