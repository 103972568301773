import React, { useContext } from "react";
import { Image, ImageRequireSource, ImageStyle } from "react-native";

import imageLogoWhite from "@assets/icons/logo_white.png";
import imageLogoPurple from "@assets/icons/logo_purple.png";
import { ThemeContext } from "styled-components/native";

interface ImageLogoProps {
  style?: ImageStyle;
}

export const ImageLogo: React.FC<ImageLogoProps> = ({ style }) => {
  const theme = useContext(ThemeContext);
  return (
    <Image
      style={{
        ...(typeof style !== "undefined" && style),
      }}
      resizeMode={"contain"}
      source={
        (theme.colorScheme === "dark"
          ? imageLogoWhite
          : imageLogoPurple) as ImageRequireSource
      }
    />
  );
};
