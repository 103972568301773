import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Animated,
  LayoutChangeEvent,
  LayoutRectangle,
  PanResponder,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";

import { DragDropListController, DragDropListViewModel } from "./controller";
import { DragHandlers } from "../questkit/dragPanel";
import { DragDropListControllerContext } from "@app/components/dragdrop/DragDropListControllerProvider";
import isEqual from "react-fast-compare";

export interface DragDropListItemProps<T> {
  item: T;
  index: number;
  dragHandlers: DragHandlers;
  isBeingDragged: boolean;
  isPlaceholder: boolean;
}

export type DraggableItemRenderer<T> = (
  props: DragDropListItemProps<T>
) => ReactElement | null;

export interface DragDropListProps<T> {
  items: T[];
  getId: (item: T) => string;
  renderItem: DraggableItemRenderer<T>;
  onDragStart?: () => void;
  onDragEnd?: (oldIndex: number, newIndex: number) => void;
}

export function DragDropList<T>({
  items,
  getId,
  renderItem,
  onDragStart,
  onDragEnd,
}: DragDropListProps<T>): React.ReactElement {
  const scrollViewController = useContext(DragDropListControllerContext);
  const controller = useRef(new DragDropListController<T>()).current;
  controller.setScrollViewController(scrollViewController);
  const [viewModel, setViewModel] = useState<
    DragDropListViewModel<T> | undefined
  >();

  useEffect(() => {
    const onStart = () => scrollViewController.setDragging(true);
    const onStop = () => scrollViewController.setDragging(false);

    controller.on("start", onStart);
    controller.on("stop", onStop);

    return () => {
      controller.off("start", onStart);
      controller.off("stop", onStop);
    };
  }, [controller, scrollViewController]);

  useEffect(() => {
    if (onDragStart) {
      controller.on("start", onDragStart);
      return () => {
        controller.off("start", onDragStart);
      };
    }
  }, [controller, onDragStart]);

  useEffect(() => {
    if (onDragEnd) {
      controller.on("stop", onDragEnd);
      return () => {
        controller.off("stop", onDragEnd);
      };
    }
  }, [controller, onDragEnd]);

  const layoutMapRef = useRef(new Map<string, LayoutRectangle>());

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onMoveShouldSetPanResponder: () => {
          if (controller.isItemSelectedToDragDrop()) {
            const newViewModel = controller.getViewModel();
            setViewModel(newViewModel);
            controller.startDragDrop();
            return true;
          }
          return false;
        },
        onPanResponderMove: (e, gestureState) => {
          e.preventDefault();
          if (controller.isDragActive()) {
            controller.move(gestureState.dx, gestureState.dy);
            scrollViewController.setTouchPosition(
              e.nativeEvent.pageX,
              e.nativeEvent.pageY
            );
          }
        },
        onPanResponderRelease: () => {
          setViewModel(undefined);
          if (controller.isDragActive()) {
            controller.stopDragDrop();
          }
        },
        onPanResponderTerminationRequest: () => false, // keeps auto-scroll from taking over
        onPanResponderTerminate: () => {
          setViewModel(undefined);
          controller.stopDragDrop();
        },
      }),
    [controller, scrollViewController]
  );

  const onDragActiveItemStart = useCallback(() => {
    console.warn("`onDragStart` called from active item!");
    /* drag start initiates from a non-active item */
  }, []);

  const onDragActiveItemHandleRelease = useCallback(() => {
    controller.maybeDeselectItem();
    if (!controller.isDragActive()) {
      setViewModel(undefined);
    }
  }, [controller]);

  const renderActiveItem = () => {
    if (!viewModel) {
      return <></>;
    }
    const id = getId(viewModel.activeItem.item);
    const style = {
      zIndex: 100,
      ...layoutToStyle(viewModel.activeItem.layout),
      transform: viewModel.activeItem.offset.getTranslateTransform(),
    };

    return (
      <DraggableItem
        key={id}
        id={id}
        item={viewModel.activeItem.item}
        index={viewModel.activeItem.index}
        onItemLayout={undefined}
        isPlaceholder={false}
        isBeingDragged={true}
        containerStyle={style}
        onDragItemStart={onDragActiveItemStart}
        onDragItemHandleRelease={onDragActiveItemHandleRelease}
        renderItem={renderItem}
      />
    );
  };

  // Perform a "mark-and-copy"-style garbage collection on the layout map by copying
  // entries from the old map to the new, based on which items are in the array.
  useEffect(() => {
    const newLayoutMap = new Map();
    for (const item of items) {
      const id = getId(item);
      if (layoutMapRef.current.has(id)) {
        newLayoutMap.set(id, layoutMapRef.current.get(id));
      }
    }
    layoutMapRef.current = newLayoutMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  let containerStyle: StyleProp<ViewStyle> = undefined;
  if (viewModel) {
    containerStyle = { width: "100%", height: viewModel.listHeight };
  }

  const onItemLayout = useCallback((itemId: string, e: LayoutChangeEvent) => {
    layoutMapRef.current.set(itemId, e.nativeEvent.layout);
  }, []);

  const itemsRef = useRef(items);
  itemsRef.current = items;

  const onDragItemStart = useCallback(
    (index: number) => {
      if (!controller.isDragActive()) {
        let offsetY = 0;
        const updatedItemsWithLayout = itemsRef.current.map((item) => {
          const id = getId(item);
          const { width, height } = layoutMapRef.current.get(id)!;
          const y = offsetY;
          offsetY += height;
          return {
            item,
            layout: {
              x: 0,
              y: y,
              width,
              height,
            },
          };
        });

        controller.setItems(updatedItemsWithLayout);
        controller.selectItem(index);
      }
    },
    [controller, getId]
  );

  return (
    <View style={containerStyle} {...panResponder.panHandlers}>
      {items.map((item, index) => {
        const id = getId(item);

        let isPlaceholder = false;
        let itemStyle = undefined;
        if (viewModel) {
          isPlaceholder = index === viewModel.activeItem.index;
          const inactiveItemDragModel = viewModel.itemsInList[index];
          itemStyle = {
            ...layoutToStyle(inactiveItemDragModel.layout),
            transform: inactiveItemDragModel.offset.getTranslateTransform(),
            zIndex: isPlaceholder ? 0 : 1,
          };
        }

        return (
          <DraggableItem
            key={isPlaceholder ? "__placeholder__" : id}
            id={id}
            item={item}
            onItemLayout={onItemLayout}
            isPlaceholder={isPlaceholder}
            containerStyle={itemStyle}
            index={index}
            onDragItemStart={onDragItemStart}
            onDragItemHandleRelease={doNothing}
            renderItem={renderItem}
          />
        );
      })}
      {controller.isItemSelectedToDragDrop() ? renderActiveItem() : null}
    </View>
  );
}

const layoutToStyle = (layout: LayoutRectangle): ViewStyle => ({
  position: "absolute",
  left: layout.x,
  top: layout.y,
  width: layout.width,
  height: layout.height,
});
function doNothing() {
  // do nothing
}

interface DraggableItemProps<T> {
  id: string;
  index: number;
  item: T;
  renderItem: (props: DragDropListItemProps<T>) => React.ReactElement | null;
  containerStyle: StyleProp<ViewStyle>;
  onItemLayout?: (itemId: string, e: LayoutChangeEvent) => void;
  onDragItemStart: (index: number) => void;
  onDragItemHandleRelease: (index: number) => void;
  isPlaceholder?: boolean;
  isBeingDragged?: boolean;
}

const _DraggableItem = <T,>({
  id,
  index,
  item,
  renderItem,
  containerStyle,
  onItemLayout,
  onDragItemStart,
  onDragItemHandleRelease,
  isPlaceholder = false,
  isBeingDragged = false,
}: DraggableItemProps<T>) => {
  const onLayout = useCallback(
    (e: LayoutChangeEvent) => onItemLayout?.(id, e),
    [id, onItemLayout]
  );
  const onDragStart = useCallback(
    () => onDragItemStart(index),
    [index, onDragItemStart]
  );

  const onDragHandleRelease = useCallback(
    () => onDragItemHandleRelease(index),
    [index, onDragItemHandleRelease]
  );

  const dragHandlers = useMemo(
    () => ({
      onDragStart,
      onDragHandleRelease,
    }),
    [onDragStart, onDragHandleRelease]
  );

  return (
    <Animated.View style={containerStyle} onLayout={onLayout}>
      {renderItem({
        item,
        index,
        dragHandlers,
        isBeingDragged,
        isPlaceholder,
      })}
    </Animated.View>
  );
};
const DraggableItem = React.memo(_DraggableItem, isEqual);
DraggableItem.displayName = "DraggableItem";
