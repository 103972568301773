import React, { useCallback, useRef } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import { SlackChannelPicker } from "@app/components/completionAction/components/slackChannelPicker";
import { Text } from "@app/components/questkit/text";

const changeChannel = (
  item: ItemRenderData,
  channelId: string
): ItemRenderData => ({
  ...item,
  data: {
    channelId,
  },
  version: item.version + 1,
});

function doNothing() {
  // do nothing
}

export const PostSlackMessageItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, editMode } = props;
  const itemRef = useRef<ItemRenderData>(item);
  itemRef.current = item;

  const onChangeChannelId = useCallback(
    (channelId: string) => {
      onItemChange?.(changeChannel(itemRef.current, channelId));
      onItemValidate?.();
    },
    [onItemChange, onItemValidate]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        editMode ? (
          <Text>Assignees will be prompted to choose a Slack channel.</Text>
        ) : (
          <SlackChannelPicker
            channelId={item.data.channelId}
            onChangeChannelId={onChangeChannelId}
            onChangeAppLinkStatus={doNothing}
            onTouched={doNothing}
          />
        )
      }
    />
  );
};
