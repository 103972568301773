import { UserEntryViewModel } from "@app/components/questkit/UserList/useUserList";
import React from "react";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import styled from "styled-components/native";
import { Image, Platform } from "react-native";
import ListItem from "@app/components/questkit/listItem";

export interface UserPickerEntryProps {
  entry: UserEntryViewModel;
  disabled: boolean;
  readOnly: boolean;
  onAdd: () => void;
  onRemove: () => void;
}
export const StandardUserEntry: React.FC<UserPickerEntryProps> = ({
  entry,
  disabled,
  readOnly,
  onAdd,
  onRemove,
}) => {
  let data: {
    title: string;
    avatarUri: string;
  };
  switch (entry.status) {
    case "LOOKING_UP_USER":
      data = {
        title: entry.identifier.format(),
        avatarUri: defaultAvatar,
      };
      break;
    case "LOADING":
      data = {
        title: "...",
        avatarUri: defaultAvatar,
      };
      break;
    case "READY":
      data = {
        title: entry.displayName,
        avatarUri: entry.avatarSmallUrl ?? defaultAvatar,
      };
      break;
  }

  const isLoggedInUser =
    entry.status !== "LOOKING_UP_USER" && entry.isLoggedInUser;

  return (
    <UserListItem
      isLoggedInUser={isLoggedInUser}
      text={isLoggedInUser ? "Myself" : data.title}
      icon={
        <AvatarImage
          source={{
            uri: data.avatarUri,
          }}
        />
      }
      {...(isLoggedInUser
        ? {
            actionComponent: (
              <CheckboxToggle
                checked={entry.isInList}
                readOnly={disabled || readOnly}
                accessibilityHint={`${
                  entry.isInList ? "Remove" : "Add"
                } Myself ${entry.isInList ? "from" : "to"} the list`}
                action={() => {
                  if (!disabled && !readOnly) {
                    if (entry.isInList) {
                      onRemove();
                    } else {
                      onAdd();
                    }
                  }
                }}
              />
            ),
          }
        : !readOnly
        ? {
            onActionIconClick: () => {
              if (!disabled) {
                onRemove();
              }
            },
            actionIcon: "trash",
            actionLoading: entry.status !== "READY",
          }
        : {})}
    />
  );
};

export const UserListItem = styled(ListItem)<{ isLoggedInUser: boolean }>`
  ${({ isLoggedInUser }) =>
    Platform.OS === "web" && isLoggedInUser ? "user-select: none;" : ""}
`;

export const defaultAvatar =
  "https://questmate-static-public.s3.amazonaws.com/avatar_default.png";
export const AvatarImage = styled(Image)`
  width: 26px;
  height: 26px;
  border-radius: 14px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.primary};
  margin-left: 7px;
  margin-right: 8px;
`;
