import {
  DropdownStyle,
  DropdownWithModal,
} from "@app/components/questkit/dropdownWithModal";
import React, { useMemo } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { useFocusableRef } from "@app/util/focus";
import { DropdownComponentModel } from "@app/components/item/components/custom/types";

interface CustomItemDropdownComponentProps {
  component: DropdownComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemDropdownComponent: React.FC<
  CustomItemDropdownComponentProps
> = ({ component, autoFocusRef, readOnly }) => {
  const _options = component.options;

  const options = useMemo(
    () =>
      _options.map((i) => ({
        icon: component.placeholderIcon,
        name: i.label,
        value: i.value,
      })),
    [_options, component.placeholderIcon]
  );
  return (
    <>
      {component.title ? (
        <CustomComponentTitle size="medium">
          {component.title}
        </CustomComponentTitle>
      ) : null}
      <DropdownWithModal
        autoFocusRef={component.autoFocus ? autoFocusRef : undefined}
        optionNoun={component.optionNoun}
        optionPluralNoun={component.optionPluralNoun}
        placeholderIcon={component.placeholderIcon}
        options={options}
        dropdownStyle={DropdownStyle.STANDARD}
        selectedOption={component.value}
        setSelectedOption={component.onSelect}
        disabled={component.disabled || readOnly}
        loadingOptions={component.isLoading}
      />
    </>
  );
};

const CustomComponentTitle = styled(Text)`
  margin-bottom: 10px;
`;
