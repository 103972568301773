import { Platform } from "expo-modules-core";
import { Analytics } from "@app/analytics";
import { useCallback, useEffect, useRef } from "react";
import { AppState as RNAppState, AppStateStatus } from "react-native";
import { uuid } from "@app/util/uuid";

const hooksInUse = new Set();

export function useAnalyticsTracking(): void {
  const hookId = useRef(uuid()).current;
  hooksInUse.add(hookId);
  if (hooksInUse.size > 1) {
    throw new Error("This hook can only be used in one place!");
  }

  if (Platform.OS === "web") {
    useEffect(() => {
      window.onfocus = function () {
        Analytics.trackEvent("Focus App");
      };
      window.onblur = function () {
        Analytics.trackEvent("Blur App");
      };
    }, []);
  } else {
    const appState = useRef(RNAppState.currentState);
    useEffect(() => {
      const eventSubscription = RNAppState.addEventListener(
        "change",
        _handleAppStateChange
      );

      return () => {
        eventSubscription.remove();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleAppStateChange = useCallback(
      (nextAppState: AppStateStatus) => {
        if (
          /inactive|background/.exec(appState.current) &&
          nextAppState === "active"
        ) {
          Analytics.trackEvent("Foreground App");
        } else if (
          appState.current === "active" &&
          /inactive|background/.exec(nextAppState)
        ) {
          Analytics.trackEvent("Background App");
        }
        appState.current = nextAppState;
      },
      []
    );
  }
}
