import React, { useCallback, useContext, useMemo } from "react";
import isEqual from "react-fast-compare";
import { colors } from "@app/themes/Colors";
import {
  QuestRunListItem,
  RunListItemMetadata,
} from "@app/quest/run/QuestRunListItem";
import { useAppSelector } from "@app/store";
import {
  selectAssignmentUserIdsForScheduledQuestStart,
  selectReviewerUserIdsForScheduledQuestStart,
  selectScheduledQuestStartById,
  selectScheduledQuestStartNameById,
} from "@app/store/cache/scheduledQuestStarts";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { selectAssignmentById } from "@app/store/cache/assignments";
import { isTruthy, NotUndefined, Overwrite } from "@questmate/common";
import { selectUserById } from "@app/store/cache/users";
import { useModal } from "@app/components/modal/ModalManager";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import styled from "styled-components/native";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import { createSelector } from "@reduxjs/toolkit";
import { InformationListItem } from "@app/components/questkit/InformationListItem";
import {
  RelativeDateText,
  useRelativeDateText,
} from "@app/components/questkit/RelativeDateText";
import { DateTimePicker } from "@app/components/questkit/dateTimePicker";
import { UserPicker } from "@app/components/questkit/UserList/UserPicker";
import { StandardUserEntry } from "@app/components/questkit/UserList/StandardUserEntry";
import DueDate from "@app/components/modal/questRunOptionsDialog/dueDateOptions";
import QKScrollView from "@app/components/questkit/ScrollView";
import {
  SplitButton,
  SplitButtonAction,
} from "@app/components/questkit/SplitButton";
import {
  deleteScheduledQuestStart,
  startScheduledQuestStart,
} from "@app/util/client/requests/scheduledQuestStarts";
import { usePromise } from "@app/util/usePromise";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { sentry } from "@app/util/sentry";

const _ScheduledQuestStartListItem: React.FC<{
  scheduledQuestStartId: string;
}> = ({ scheduledQuestStartId }) => {
  const metadataFields = useAppSelector((state) => {
    const scheduledQuestStart = selectScheduledQuestStartById(
      state,
      scheduledQuestStartId
    );
    if (!scheduledQuestStart) {
      return {
        startAt: null,
        assignees: [],
      };
    }
    const startConfiguration = selectQuestStartConfigurationById(
      state,
      scheduledQuestStart.startConfigurationId
    );
    const assignees = startConfiguration.assignmentIds
      .map((id) => selectAssignmentById(state, id))
      .filter(isTruthy)
      .map(({ assigneeId }) => assigneeId)
      .filter(isTruthy)
      .map((assigneeId) => {
        const user = selectUserById(state, assigneeId);
        return user
          ? {
              id: user.id,
              displayName: user.displayName,
              avatarSmallUrl: user.avatarSmallUrl,
              avatarLargeUrl: user.avatarLargeUrl,
            }
          : undefined;
      })
      .filter(
        (
          user
        ): user is Overwrite<
          typeof user,
          {
            displayName: NotUndefined;
            avatarSmallUrl: NotUndefined;
            avatarLargeUrl: NotUndefined;
          }
        > =>
          !!user &&
          user.displayName !== undefined &&
          user.avatarSmallUrl !== undefined &&
          user.avatarLargeUrl !== undefined
      );

    return {
      startAt: scheduledQuestStart.startAt,
      assignees,
    };
  }, isEqual);

  const name = useAppSelector((state) =>
    selectScheduledQuestStartNameById(state, scheduledQuestStartId)
  );

  const metadata = useMemo(() => {
    const { startAt, assignees } = metadataFields;
    const metadata: RunListItemMetadata[] = [];
    if (assignees.length > 0) {
      const firstAssignee = assignees[0];
      metadata.push({
        icon: firstAssignee.avatarSmallUrl
          ? { url: firstAssignee.avatarSmallUrl }
          : "person",
        text: `${firstAssignee.displayName || "Public user"}${
          assignees.length > 1
            ? ` (+${assignees.length - 1} other user${
                assignees.length - 1 > 1 ? "s" : ""
              })`
            : ""
        }`,
      });
    }

    if (startAt) {
      metadata.push({
        icon: "schedule-send",
        text: (
          <>
            Starts{" "}
            <RelativeDateText date={new Date(startAt)} size={"mediumBold"} /> (
            {new Date(startAt).toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
            )
          </>
        ),
      });
    }
    return metadata;
  }, [metadataFields]);

  const { openModal, closeModal } = useModal((props) => {
    return (
      <QKModal {...props} title={"Scheduled Quest Start"}>
        <ScheduledQuestStartModalViewContainer
          scheduledQuestStartId={scheduledQuestStartId}
          onClose={closeModal}
        />
      </QKModal>
    );
  });

  const statusPills = useMemo(() => {
    return [
      {
        text: "Scheduled",
        color: colors.neutral300,
      },
    ];
  }, []);

  return (
    <QuestRunListItem
      name={name}
      metadata={metadata}
      statusPills={statusPills}
      onPress={openModal}
    />
  );
};
export const ScheduledQuestStartListItem = React.memo(
  _ScheduledQuestStartListItem
);
ScheduledQuestStartListItem.displayName = "ScheduledQuestStartListItem";

interface ScheduledQuestStartModalViewContainerProps {
  scheduledQuestStartId: string;
  onClose: () => void;
}

const selectScheduledQuestStartModalViewProps = createSelector(
  [
    (state, scheduledQuestStartId) =>
      selectScheduledQuestStartNameById(state, scheduledQuestStartId),
    (state, scheduledQuestStartId) => {
      return selectScheduledQuestStartById(state, scheduledQuestStartId);
    },
    (state, scheduledQuestStartId) => {
      const scheduledStart = selectScheduledQuestStartById(
        state,
        scheduledQuestStartId
      );
      if (!scheduledStart) {
        return;
      }
      return selectQuestStartConfigurationById(
        state,
        scheduledStart.startConfigurationId
      );
    },
    (state, scheduledQuestStartId) =>
      selectAssignmentUserIdsForScheduledQuestStart(
        state,
        scheduledQuestStartId
      ),
    (state, scheduledQuestStartId) =>
      selectReviewerUserIdsForScheduledQuestStart(state, scheduledQuestStartId),
  ],
  (
    name,
    scheduledStart,
    startConfiguration,
    assigneeUserIds,
    reviewerUserIds
  ) => {
    if (!scheduledStart || !startConfiguration) {
      return;
    }

    return {
      id: scheduledStart.id,
      name,
      questId: scheduledStart.questId,
      createdAt: new Date(scheduledStart.createdAt),
      startAt: new Date(scheduledStart.startAt),
      runExternalId: scheduledStart.runExternalId,
      dueAt: startConfiguration.dueAt
        ? new Date(startConfiguration.dueAt)
        : null,
      remindBeforeSeconds: startConfiguration.remindBeforeSeconds,
      alertBeforeSeconds: startConfiguration.alertBeforeSeconds,
      allowOverdueSubmissions: startConfiguration.allowOverdueSubmissions,
      assigneeUserIds,
      reviewerUserIds,
    };
  }
);

export const ScheduledQuestStartModalViewContainer: React.FC<
  ScheduledQuestStartModalViewContainerProps
> = (props) => {
  const { scheduledQuestStartId, ...rest } = props;
  const data = useAppSelector((state) =>
    selectScheduledQuestStartModalViewProps(state, scheduledQuestStartId)
  );
  return data ? <ScheduledQuestStartModalView {...rest} {...data} /> : null;
};

type ScheduledQuestStartModalViewProps = Omit<
  ScheduledQuestStartModalViewContainerProps,
  "scheduledQuestStartId"
> &
  ReturnType<typeof selectScheduledQuestStartModalViewProps>;

const ScheduledQuestStartModalView: React.FC<
  ScheduledQuestStartModalViewProps
> = ({
  id,
  name,
  questId,
  createdAt,
  startAt,
  runExternalId,
  dueAt,
  remindBeforeSeconds,
  alertBeforeSeconds,
  allowOverdueSubmissions,
  assigneeUserIds,
  reviewerUserIds,
  onClose,
}) => {
  const snackbar = useContext(SnackbarContext);

  // const relativeStartAtText = useRelativeDateText(startAt);
  const relativeCreatedAtText = useRelativeDateText(createdAt);
  // const relativeDueAtText = useMemo(() => {
  //   if (!dueAt) {
  //     return;
  //   }
  //   return getRelativeTextForDate(dueAt, startAt.getTime());
  // }, [startAt, dueAt]);

  const assignedUsers = useMemo(
    () => assigneeUserIds?.map((userId) => ({ userId })) ?? [],
    [assigneeUserIds]
  );
  const reviewingUsers = useMemo(
    () => reviewerUserIds?.map((userId) => ({ userId })) ?? [],
    [reviewerUserIds]
  );

  const { execute: cancelScheduledStart } = usePromise(() =>
    deleteScheduledQuestStart(questId, id)
  );

  const { execute: startScheduledQuestImmediately } = usePromise(() =>
    startScheduledQuestStart(questId, id)
  );

  const onCancel = useCallback(async () => {
    try {
      await cancelScheduledStart();
      snackbar.sendMessage("Scheduled Start has been cancelled.");
      setTimeout(() => onClose(), MODAL_CLOSE_DELAY);
    } catch (e) {
      snackbar.sendMessage(
        "Failed to cancel Scheduled Start. Please try again later.",
        SnackbarSeverity.WARNING
      );
      sentry.captureException(e, { extra: { scheduledQuestStartId: id } });
      return { success: false };
    }
  }, [cancelScheduledStart, id, onClose, snackbar]);

  const onStartNow = useCallback(async () => {
    try {
      await startScheduledQuestImmediately();
      snackbar.sendMessage("Quest started successfully.");
      setTimeout(() => onClose(), MODAL_CLOSE_DELAY);
    } catch (e) {
      snackbar.sendMessage(
        "Failed to Start Scheduled Quest. Please try again later.",
        SnackbarSeverity.WARNING
      );
      sentry.captureException(e, { extra: { scheduledQuestStartId: id } });
      return { success: false };
    }
  }, [id, onClose, snackbar, startScheduledQuestImmediately]);

  const actions = useMemo(() => {
    return [
      {
        title: "Close",
        onPress: onClose,
        buttonType: "action",
      },
      {
        title: "Start now",
        onPress: onStartNow,
        buttonType: "primary",
      },
      {
        title: "Cancel",
        onPress: onCancel,
        buttonType: "warning",
      },
    ] satisfies SplitButtonAction[];
  }, [onClose, onStartNow, onCancel]);
  return (
    <ScheduledQuestStartModalViewWrapper>
      <ModalScrollView>
        <HeaderText title="Name" />
        <Section>
          <TextInput value={name} editable={false} />
        </Section>
        <HeaderText title={`Scheduled Start`} />
        <Section>
          <DateTimePicker
            onDone={() => {}}
            readOnly={true}
            title={"Select a date and time"}
            time={true}
            disableReset={true}
            value={startAt.toISOString()}
          />
        </Section>
        <HeaderText title={`Due date`} />
        <Section>
          <DueDate
            options={{
              dueAt: dueAt === null ? null : dueAt.toISOString(),
              dueAfterSeconds: null,
              remindBeforeSeconds,
              alertBeforeSeconds,
              allowOverdueSubmissions,
            }}
            onChange={() => {}}
            readOnly={true}
            readOnlyMessage={
              "Unable to change scheduled start configuration at this time."
            }
          />
        </Section>
        <Section>
          <UserPicker
            title={"Assignees"}
            users={assignedUsers}
            renderUserEntry={StandardUserEntry}
            readOnly={true}
          />
        </Section>
        <Section>
          <UserPicker
            title={"Reviewers"}
            users={reviewingUsers}
            renderUserEntry={StandardUserEntry}
            readOnly={true}
          />
        </Section>

        <HeaderText title="Metadata" />
        <Section>
          <InformationListItem
            label="Created"
            value={
              <>
                {relativeCreatedAtText} (
                {createdAt.toLocaleString(undefined, {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
                )
              </>
            }
          />
          <InformationListItem
            label="External ID"
            value={runExternalId ? runExternalId : "No external ID"}
          />
        </Section>
      </ModalScrollView>
      <StyledSplitButton actions={actions} />
    </ScheduledQuestStartModalViewWrapper>
  );
};

const StyledSplitButton = styled(SplitButton)`
  width: 100%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const ScheduledQuestStartModalViewWrapper = styled.View`
  padding-horizontal: 20px;
  padding-vertical: 10px;
  flex-shrink: 1;
`;
const ModalScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 16,
  },
})``;

const Section = styled.View`
  margin-bottom: 20px;
`;
