import React, {
  Component,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Animated, Easing, StyleProp, ViewStyle } from "react-native";
import {
  LinearGradient as NativeLinearGradient,
  LinearGradientProps,
} from "expo-linear-gradient";
import { colors } from "@app/themes/Colors";

/**
 * This implementation was heavily inspired by this outdated library:
 * https://github.com/heineiuo/react-native-animated-linear-gradient
 */

export const AnimatedGradient: React.FC<
  PropsWithChildren<{
    customColors?: string[];
    speed?: number;
    paused?: boolean;
    style?: StyleProp<ViewStyle>;
  }>
> = ({
  customColors = presetColors.questmate,
  speed = 4000,
  paused = false,
  children,
  style,
}) => {
  const color0 = useRef(new Animated.Value(0)).current;
  const color1 = useRef(new Animated.Value(0)).current;

  const [hasStarted, setHasStarted] = React.useState(false);
  useEffect(() => {
    if (paused && hasStarted) {
      color0.stopAnimation();
      color1.stopAnimation();
    } else if (!paused) {
      setHasStarted(true);
      Animated.loop(
        Animated.parallel(
          [color0, color1].map((animatedColor) => {
            return Animated.timing(animatedColor, {
              toValue: customColors.length,
              duration: customColors.length * speed,
              easing: Easing.linear,
              useNativeDriver: false,
            });
          })
        )
      ).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  const interpolatedColors = useMemo(() => {
    const preferColors: string[][] = [];
    while (preferColors.length < 2) {
      preferColors.push(
        customColors
          .slice(preferColors.length)
          .concat(customColors.slice(0, preferColors.length + 1))
      );
    }
    return [color0, color1].map((animatedColor, index) =>
      animatedColor.interpolate({
        inputRange: Array.from(
          { length: customColors.length + 1 },
          (_v, k) => k
        ),
        outputRange: preferColors[index],
      })
    );
  }, [color0, color1, customColors]);

  return (
    <AnimatedLinearGradient
      style={[style]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      color0={interpolatedColors[0]}
      color1={interpolatedColors[1]}
    >
      {children}
    </AnimatedLinearGradient>
  );
};

/**
 * This is a workaround for the fact that `Animated.createAnimatedComponent` does not support animating array props.
 */
class LinearGradientWithAnimatableProps extends Component<
  Omit<LinearGradientProps, "colors"> & { color0: string; color1: string }
> {
  render() {
    return (
      <NativeLinearGradient
        {...this.props}
        colors={[this.props.color0, this.props.color1]}
      />
    );
  }
}

const AnimatedLinearGradient = Animated.createAnimatedComponent(
  LinearGradientWithAnimatableProps
);

export const presetColors = {
  questmate: [
    colors.primary200,
    colors.yellow700,
    colors.red500,
    colors.green600,
  ],
  firefox: [
    "rgb(236, 190, 55)",
    "rgb(215, 110, 51)",
    "rgb(181, 63, 49)",
    "rgb(192, 71, 45)",
  ],
  sunrise: [
    "rgb(92, 160, 186)",
    "rgb(106, 166, 186)",
    "rgb(142, 191, 186)",
    "rgb(172, 211, 186)",
    "rgb(239, 235, 186)",
    "rgb(212, 222, 206)",
    "rgb(187, 216, 200)",
    "rgb(152, 197, 190)",
    "rgb(100, 173, 186)",
  ],
};
