import React from "react";
import Text from "@app/components/questkit/text";
import styled from "styled-components/native";

type InformationListItemProps = {
  label: string;
  value: React.ReactNode | string | null | undefined;
};
export const InformationListItem: React.FC<InformationListItemProps> = ({
  label,
  value,
}) => {
  return (
    <InfoItemContainer>
      <Text size={"mediumBold"}>{label}</Text>
      <Text>{value}</Text>
    </InfoItemContainer>
  );
};

const InfoItemContainer = styled.View`
  margin-bottom: 10px;
`;
