import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components/native";
import {
  Calendar,
  type CalendarProps,
  type CalendarTheme,
  type CustomMarking,
  type CustomMarkingProps,
  type DayComponentProps,
  LocaleConfig,
} from "react-native-calendars";
import QKIcon from "./icon";
import { QKTextInputProps, Text } from "./text";
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput as RNTextInput,
  TextInputFocusEventData,
  ViewStyle,
} from "react-native";
import { IconIdentifier } from "@app/components/icon";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import QKTextInput from "@app/components/questkit/textInput";

LocaleConfig.locales["qm"] = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  dayNames: ["S", "M", "T", "W", "T", "F", "S"],
  dayNamesShort: ["S", "M", "T", "W", "T", "F", "S"],
  today: "Today",
};
LocaleConfig.defaultLocale = "qm";

type selectionType = { start: number; end: number };

interface CalendarInputProps extends QKTextInputProps {
  iconName: IconIdentifier;
  onSubmit?: (text: string) => void;
  rightComponent?: React.ReactElement;
  selection?: selectionType;
  setSelection?: (selection: selectionType) => void;
  disabled?: boolean;
}

interface QKCalendarProps {
  key?: string;
  selectedDate: string;
  setSelectedDate: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const QKCalendar: React.FC<QKCalendarProps> = ({
  key,
  selectedDate,
  setSelectedDate,
  style,
}) => {
  const themeContext = useContext(ThemeContext);
  const todayDateString = new Date().toDateString();

  return (
    <StyledCalendar
      key={key}
      current={todayDateString}
      dayComponent={({ date, state, marking }: DayComponentProps) => {
        const onDayPress = () => {
          setSelectedDate(date.dateString);
        };

        const selectedStyles = (marking as unknown as CustomMarking)
          ?.customStyles;
        return (
          <PressableOpacity onPress={onDayPress}>
            <DayView
              style={
                selectedStyles?.container ??
                (state === "today"
                  ? {
                      borderWidth: 1,
                      borderColor: themeContext.interaction.primary,
                    }
                  : undefined)
              }
            >
              {state !== "disabled" ? (
                <Text
                  style={
                    selectedStyles?.text ?? {
                      color:
                        state === "today"
                          ? themeContext.interaction.primary
                          : themeContext.primary,
                    }
                  }
                >
                  {date.day}
                </Text>
              ) : null}
            </DayView>
          </PressableOpacity>
        );
      }}
      hideExtraDays={true}
      markingType={"custom"}
      markedDates={
        {
          [selectedDate]: {
            customStyles: {
              container: {
                backgroundColor: themeContext.interaction.primary,
              },
              text: {
                color: themeContext.interaction.secondary,
              },
            },
          },
        } as CustomMarkingProps["markedDates"]
      }
      renderArrow={(direction: "left" | "right") => {
        return direction === "left" ? (
          <StyledQKIcon name={"chevron-left"} />
        ) : (
          <StyledQKIcon name={"chevron-right"} />
        );
      }}
      style={[{ borderColor: themeContext.primary }, style]}
    />
  );
};

const CalendarTimeInput = React.forwardRef<RNTextInput, CalendarInputProps>(
  (
    {
      iconName,
      onChangeText,
      onSubmit,
      rightComponent,
      value,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <ShowItemTextInput
        testID="time-input"
        leftIcon={iconName}
        rightIcon={() => rightComponent || null}
        blurOnSubmit={true}
        onBlur={({
          nativeEvent,
        }: NativeSyntheticEvent<TextInputFocusEventData>) => {
          onSubmit ? onSubmit(nativeEvent.text) : null;
        }} //The reason why we use onBlur because onEndEditing doesn't work on web
        onChangeText={onChangeText}
        inputMode="decimal"
        returnKeyType="done"
        ref={ref}
        textAlign="center"
        value={value}
        editable={!disabled}
        {...props}
      />
    );
  }
);
CalendarTimeInput.displayName = "CalendarTimeInput";

const CalendarDateInput = React.forwardRef<RNTextInput, CalendarInputProps>(
  (
    {
      iconName,
      onChangeText,
      onSubmit,
      rightComponent,
      selection,
      setSelection,
      value: _value,
      ...props
    },
    ref
  ) => {
    const value = _value || "";
    return (
      <ShowItemTextInput
        blurOnSubmit={true}
        leftIcon={iconName}
        rightIcon={() => rightComponent || null}
        onSubmitEditing={() => {
          onSubmit?.(value);
        }} //The reason why we use onBlur because onEndEditing doesn't work on web
        onChangeText={onChangeText}
        onSelectionChange={
          setSelection
            ? () => setSelection({ start: value.length, end: value.length })
            : undefined
        }
        ref={ref}
        selection={selection}
        textAlign="center"
        value={value}
        {...props}
      />
    );
  }
);
CalendarDateInput.displayName = "CalendarDateInput";

const ShowItemTextInput = styled(QKTextInput)`
  text-align: center;
  flex: 1;
  width: 100%;
`;

const DayView = styled.View`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

const StyledCalendar = styled(Calendar).attrs<CalendarProps>(({ theme }) => ({
  theme: {
    /**
     * Theme prop for `<StyledCalendar> is overridden to `DefaultTheme` from styled components.
     * However, the `theme` prop for `<Calendar>` is of type `CalendarTheme`.
     * For types to be happy, we need to specify the theme prop here instead of as a prop to `<StyledCalendar>`.
     */
    calendarBackground: theme.interaction.neutralLight,
    monthTextColor: theme.primary,
    textDayHeaderFontFamily: "CentraNo2-Book",
    textMonthFontFamily: "CentraNo2-Book",
    textMonthFontSize: 20,
    textDayHeaderFontSize: 16,
    textSectionTitleColor: theme.interaction.neutralStrong,
    "stylesheet.calendar.header": {
      arrow: {
        padding: 0,
      },
      header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 16,
        alignItems: "center",
      },
      week: {
        marginTop: 8,
        marginHorizontal: 32,
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    "stylesheet.calendar.main": {
      monthView: {
        marginHorizontal: 32,
        marginBottom: 24,
      },
      week: {
        borderTopWidth: 1,
        borderTopColor: theme.interaction.neutralMedium,
        paddingTop: 6,
        paddingBottom: 6,
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
  } as CalendarTheme,
}))<CustomMarkingProps>`
  border-radius: 20px;
  padding: 8px;
  ${Platform.OS === "web" ? `user-select: none;` : ``}
`;

const StyledQKIcon = styled(QKIcon)`
  padding: 0;
  background-color: ${({ theme }) => theme.interaction.neutralStrong};
`;

export { QKCalendar as default, CalendarDateInput, CalendarTimeInput };
