import { useAppSelector } from "@app/store";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { useIsFocused } from "@react-navigation/native";
import { useOverlayManager } from "@app/util/OverlayManagerProvider";
import { useTimeout } from "@app/util/useTimeout";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TouchDetector } from "@app/util/TouchDetector";
import { Text } from "@app/components/questkit/text";
import * as React from "react";
import styled from "styled-components/native";
import { useDelayedAction } from "@app/components/NavigationButton";
import { sentry } from "@app/util/sentry";
import { Platform } from "react-native";

interface InactivityTimeoutManager {
  reportActivityOccurred: () => void;
}

const OVERLAY_TIMEOUT = 60 * 1000;
const RESET_COUNTDOWN = 10 * 1000;

export const useKioskInactivityTimeout = (): InactivityTimeoutManager => {
  const kioskQuestPublicId = useAppSelector(
    (state) => state.ui.kioskQuestPublicId
  );
  const overlayManager = useOverlayManager();
  const route = useAppRoute();
  const isOnInstanceScreen = route.name === "QuestInstance";
  const isScreenFocused = useIsFocused();
  const isInKioskMode = Boolean(kioskQuestPublicId);
  const [screenIsDirty, setScreenIsDirty] = useState(false);

  const navigation = useAppNavigation();
  const navigateToNewKioskRun = useCallback(() => {
    if (!kioskQuestPublicId) {
      sentry.captureMessage(
        "Attempted to navigate to new kiosk run after inactivity timeout, but no kiosk public Quest id was available!"
      );
      return;
    }
    navigation.replace("PublicAssignment", { id: kioskQuestPublicId });
  }, [kioskQuestPublicId, navigation]);
  const showInactivityOverlay = useCallback(() => {
    overlayManager.renderOverlay({
      content: (
        <InactivityOverlayMessage timeoutAction={navigateToNewKioskRun} />
      ),
      fadeInMs: 1000,
      fadeOutMs: 100,
    });
  }, [navigateToNewKioskRun, overlayManager]);
  const { start, stop } = useTimeout(showInactivityOverlay, OVERLAY_TIMEOUT);

  const shouldMonitorInactivity =
    isOnInstanceScreen && isScreenFocused && isInKioskMode && screenIsDirty;
  const shouldMonitorInactivityRef = useRef(shouldMonitorInactivity);
  shouldMonitorInactivityRef.current = shouldMonitorInactivity;

  useEffect(() => {
    stop();
    overlayManager.hideOverlay();
    if (!shouldMonitorInactivity) {
      return;
    }

    start();

    const touchListener = () => {
      stop();
      overlayManager.hideOverlay();
      start();
    };
    TouchDetector.on("touch", touchListener);

    return () => {
      TouchDetector.off("touch", touchListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMonitorInactivity]);

  return useMemo(() => {
    return {
      reportActivityOccurred: () => {
        setScreenIsDirty(true);
        if (shouldMonitorInactivityRef.current) {
          stop();
          overlayManager.hideOverlay();
          start();
        }
      },
    };
  }, [overlayManager, start, stop]);
};

type InactivityOverlayMessageProps = { timeoutAction: () => void };
export const InactivityOverlayMessage: React.FC<
  InactivityOverlayMessageProps
> = ({ timeoutAction }) => {
  const { remainingDelaySeconds } = useDelayedAction({
    action: timeoutAction,
    delayMs: RESET_COUNTDOWN,
  });
  return (
    <InactivityOverlayMessageContainer>
      <InteractText>Interact to resume</InteractText>
      <ResetText>Resetting in {remainingDelaySeconds} seconds</ResetText>
    </InactivityOverlayMessageContainer>
  );
};

const ResetText = styled(Text).attrs({ size: "mediumBold" })`
  color: white;
  text-align: center;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const InteractText = styled(Text).attrs({ size: "xlarge" })`
  color: white;
  text-align: center;
  margin-bottom: 32px;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

const InactivityOverlayMessageContainer = styled.View`
  height: 100%;
  padding-top: 15%;
`;
