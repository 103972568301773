import React, { useCallback, useMemo } from "react";
import { SectionListRenderItemInfo, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";
import Icon from "@app/components/icon";
import Text from "@app/components/questkit/text";
import { OnLinkPress } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { QuestmateTheme } from "@app/themes/QuestmateTheme";
import { useHover } from "@app/util/useHover";

interface ChooseEntryListItemProps {
  name: string;
  onPress: OnLinkPress | (() => void) | undefined;
  tileType: keyof QuestmateTheme["tile"];
  style?: StyleProp<ViewStyle>;
}

const ChooseEntryListItem: React.FC<ChooseEntryListItemProps> = ({
  name,
  onPress,
  style,
  tileType,
}) => {
  const { hoverProps } = useHover();
  return (
    <QuestListItemContainer
      activeOpacity={0.8}
      style={style}
      onPress={onPress}
      tileType={tileType}
      {...hoverProps}
    >
      {name && (
        <QuestTitleWrapper>
          <QuestTitleName>
            <NameText size="medium" numberOfLines={2} tileType={tileType}>
              {name}
            </NameText>
          </QuestTitleName>
          <QuestTitleIcon>
            <IconWrapper>
              <Icon icon="chevron-right" size={32} />
            </IconWrapper>
          </QuestTitleIcon>
        </QuestTitleWrapper>
      )}
    </QuestListItemContainer>
  );
};

const QuestListItemContainer = styled(PressableOpacity)<{
  tileType: ChooseEntryListItemProps["tileType"];
  isHovered: boolean;
}>`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 16px;
  background-color: ${({ theme, tileType, isHovered }) =>
    theme.tile[tileType].background[isHovered ? "hover" : "normal"]};
  transition: background-color 0.15s ease-in-out;
`;

const NameText = styled(Text)<{
  tileType: ChooseEntryListItemProps["tileType"];
}>`
  color: ${({ theme, tileType }) => theme.tile[tileType].text};
`;

const QuestTitleWrapper = styled.View`
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

const QuestTitleName = styled.View`
  flex: 1;
`;

const QuestTitleIcon = styled.View`
  height: 24px;
  justify-content: center;
`;

const IconWrapper = styled.View`
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
`;

export interface SimpleListItemData {
  key: string;
  id: string;
  name: string;
}
type SimpleListItemProps = {
  item: SimpleListItemData;
  onSelectBuilder: (id: string) => OnLinkPress | undefined;
  tileType: keyof QuestmateTheme["tile"];
};
const SimpleListItem: React.FC<SimpleListItemProps> = ({
  item,
  onSelectBuilder,
  tileType,
}) => {
  const onPress = useMemo(() => {
    return onSelectBuilder(item.id);
  }, [onSelectBuilder, item.id]);

  return (
    <ChooseEntryListItem
      key={item.id}
      name={item.name}
      onPress={onPress}
      tileType={tileType}
    />
  );
};

export const useSimpleListItemRenderer = (
  tileType: keyof QuestmateTheme["tile"],
  onSelectBuilder: (id: string) => OnLinkPress | undefined
) => {
  return useCallback(
    ({ item }: SectionListRenderItemInfo<SimpleListItemProps["item"]>) => (
      <SimpleListItem
        item={item}
        onSelectBuilder={onSelectBuilder}
        tileType={tileType}
      />
    ),
    [tileType, onSelectBuilder]
  );
};
