import * as React from "react";
import { useRef } from "react";
import {
  CombinedRoutesParamList,
  QMStackParamList,
  useAppNavigation,
  useAppRoute,
} from "@app/navigation/QMNavigator";
import { useAppSelector } from "@app/store";
import { selectUserIsLoggedIn } from "@app/store/auth";
import linkingConfig, { getRouteFromPath } from "@app/navigation/linkingConfig";
import { sentry } from "@app/util/sentry";
import {
  NavigationState,
  PartialState,
} from "@react-navigation/routers/src/types";
import { StackNavigationState } from "@react-navigation/native";
import { useEffectOnce } from "@app/util/useEffectOnce";
import {
  EventListenerCallback,
  EventMapCore,
} from "@react-navigation/core/src/types";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import styled from "styled-components/native";

/**
 * This screen is shared between the logged-in and logged-out navigation stacks.
 * This allows us to register a single URL on Auth0 Logout. Required for entering kiosk mode.
 */
export const RedirectScreen: React.FC = () => {
  const navigation = useAppNavigation<"Redirect">();
  const route = useAppRoute<"Redirect">();
  const url = route.params?.url;
  const userIsLoggedIn = useAppSelector(selectUserIsLoggedIn);
  const redirectSuccessRef = useRef(false);

  useEffectOnce(() => {
    sentry.addBreadcrumb({
      message: "Arrived on Redirect Screen",
      data: { url, userIsLoggedIn },
    });
    let ensureRedirectHappens: EventListenerCallback<
      EventMapCore<NavigationState<CombinedRoutesParamList>>,
      "state"
    >;

    if (url) {
      const targetState = linkingConfig.getStateFromPath(url);
      if (targetState) {
        const intendedRoute = getRouteFromPath(url);

        const fallbackNavigateTimeout = setTimeout(() => {
          if (!redirectSuccessRef.current) {
            navigation.removeListener("state", ensureRedirectHappens);
            if (getLoggedInUserId()) {
              navigation.navigate("Home");
            } else {
              navigation.navigate("Login");
            }
          }
        }, 2000);

        ensureRedirectHappens = (event) => {
          const state = event.data.state ?? {};
          if (state.routes?.[state.index]?.name === intendedRoute?.name) {
            sentry.addBreadcrumb({
              message: "Redirected to intended route",
              data: { intendedRoute },
            });
            redirectSuccessRef.current = true;
            clearTimeout(fallbackNavigateTimeout);
            navigation.removeListener("state", ensureRedirectHappens);
          }
        };
        navigation.addListener("state", ensureRedirectHappens);
        setTimeout(() => {
          navigation.reset(
            targetState as PartialState<StackNavigationState<QMStackParamList>>
          );
        }, 1);
        return;
      } else {
        sentry.captureMessage("Invalid redirect screen url", {
          extra: { url, targetState },
        });
      }
    }

    if (userIsLoggedIn) {
      navigation.navigate("Home");
    } else {
      navigation.navigate("Login");
    }
  });

  return <BlankView />;
};
const BlankView = styled.View`
  background-color: white;
`;
