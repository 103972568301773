import React, { useState, useRef } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";
import Button from "@app/components/questkit/button";
import Text from "@app/components/questkit/text";
import styled from "styled-components/native";

interface InlineErrorWithRetryProps {
  message: string;
  isLoading: boolean;
  onRetry: () => Promise<unknown>;
}
export const InlineErrorWithRetry: React.FC<InlineErrorWithRetryProps> = ({
  message,
  isLoading,
  onRetry,
}) => {
  const [retryLoading, setRetryLoading] = useState(false);
  const mounted = useRef(false);
  useEffectOnce(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  return (
    <ErrorMessageView>
      <Text $warning={true}>{message}</Text>
      <Button
        title="Retry"
        buttonType={"warning"}
        loading={retryLoading || isLoading}
        loadingPosition="right"
        onPress={() => {
          setRetryLoading(true);
          void onRetry().finally(() => {
            if (mounted.current) {
              setRetryLoading(false);
            }
          });
        }}
      />
    </ErrorMessageView>
  );
};

const ErrorMessageView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
