import {
  AssignmentListItem,
  QuestInstanceDetail,
  QuestInstanceListItem,
  QuestPrototypeDetail,
  TemplateDetails,
  TemplateListItem,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  assignmentAdded,
  assignmentRemoved,
  assignmentsLoaded,
} from "@app/store/cache/assignments";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { questLoaded, questsLoaded } from "@app/store/cache/quests";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { selectItemPrototypeById } from "@app/store/cache/itemPrototypes";
import { selectRewardPrototypeById } from "@app/store/cache/rewardPrototypes";
import { sentry } from "@app/util/sentry";
import {
  minimalPublicQuestDataLoaded,
  selectQuestStartTriggerEditFields,
} from "@app/store/cache/questStartTriggers";
import {
  questInstanceListLoaded,
  questInstanceLoaded,
} from "@app/store/cache/questInstances";

type QuestPrototype = Omit<
  UnionToIntersection<APIQuestPrototype>,
  | "items"
  | "rewards"
  | "sharedInstance"
  | "owner"
  | "quest"
  | "assignments"
  | "startTriggers"
> & {
  itemIds?: string[];
  rewardIds?: string[];
  sharedInstanceId?: string | null;
  ownerId?: string;
  questId?: string;
  assignmentIds?: string[];
  startTriggerIds?: string[];
};

const questPrototypeAdapter = createEntityAdapter<QuestPrototype>({});

export const {
  selectById: selectQuestPrototypeById,
  selectEntities: selectAllQuestPrototypesById,
} = questPrototypeAdapter.getSelectors<AppState>(
  (state) => state.cache.questPrototypes
);

// This is not a particularly meaningful type, it's just that we do not have a good way to denormalize data from the
// cache so this type contains what is convenient to retrieve while ensuring we are loading all the fields that will
// possibly be edited and saved on the edit screen.
export type EditableQuestPrototypeDetails = Exclude<
  ReturnType<typeof selectEditableQuestPrototypeDetailsById>,
  undefined
>;

export type QuestStartTriggerEditData = Exclude<
  EditableQuestPrototypeDetails["startTriggersById"],
  undefined
>[string];

export const selectEditableQuestPrototypeDetailsById = createSelector(
  [
    (state) => state,
    (state, questPrototypeId) =>
      selectQuestPrototypeById(state, questPrototypeId),
  ],
  (state, questPrototype) => {
    if (
      !questPrototype ||
      !questPrototype.itemIds ||
      !questPrototype.rewardIds
    ) {
      const error = new Error(
        "Unexpected Error: Unable to select Editable Quest Prototype Details, missing data in cache."
      );
      sentry.captureException(error, { extra: { questPrototype } });
      throw error;
    }

    return {
      ...questPrototype,
      itemsById: questPrototype.itemIds.reduce((acc, id) => {
        const itemPrototype = selectItemPrototypeById(state, id);
        if (!itemPrototype) {
          const error = new Error(
            "Unexpected Error: Unable to select Editable Quest Prototype Details, missing ItemPrototype in cache."
          );
          sentry.captureException(error, {
            extra: { questPrototype, itemPrototypeId: id, itemPrototype },
          });
          throw error;
        }
        acc[id] = itemPrototype;
        return acc;
      }, {} as Record<string, Exclude<ReturnType<typeof selectItemPrototypeById>, undefined>>),
      rewardsById: questPrototype.rewardIds.reduce((acc, id) => {
        const rewardPrototype = selectRewardPrototypeById(state, id);
        if (!rewardPrototype) {
          const error = new Error(
            "Unexpected Error: Unable to select Editable Quest Prototype Details, missing RewardPrototype in cache."
          );
          sentry.captureException(error, {
            extra: { questPrototype, rewardPrototypeId: id, rewardPrototype },
          });
          throw error;
        }
        acc[id] = rewardPrototype;
        return acc;
      }, {} as Record<string, Exclude<ReturnType<typeof selectRewardPrototypeById>, undefined>>),
      startTriggersById: questPrototype.startTriggerIds?.reduce((acc, id) => {
        const startTrigger = selectQuestStartTriggerEditFields(
          state,
          questPrototype.id,
          id
        );
        if (!startTrigger) {
          const error = new Error(
            "Unexpected Error: Unable to select Editable Quest Prototype Details, missing StartTrigger in cache."
          );
          sentry.captureException(error, {
            extra: { questPrototype, startTriggerId: id, startTrigger },
          });
          throw error;
        }
        acc[id] = startTrigger;
        return acc;
      }, {} as Record<string, Exclude<ReturnType<typeof selectQuestStartTriggerEditFields>, undefined>>),
    };
  }
);

const slice = createSlice({
  name: "cache/questPrototypes",
  initialState: questPrototypeAdapter.getInitialState(),
  reducers: {
    questPrototypeLoaded: (
      state,
      action: PayloadAction<QuestPrototypeDetail>
    ) => {
      questPrototypeAdapter.upsertOne(state, mapQuestPrototype(action.payload));
      questPrototypeAdapter.upsertMany(
        state,
        action.payload.items
          .filter(({ type }) => type === "Subquest")
          .map((item) => mapQuestPrototype(item.subquest!))
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignmentAdded, (state, action) => {
        const formPrototypeId = action.payload.formPrototype?.id;
        if (formPrototypeId) {
          questPrototypeAdapter.updateOne(state, {
            id: formPrototypeId,
            changes: {
              assignmentIds: [
                ...(state.entities[formPrototypeId]?.assignmentIds || []),
                action.payload.id,
              ],
            },
          });
        }
      })
      .addCase(assignmentRemoved, (state, action) => {
        const formPrototypeId = action.payload.formPrototype?.id;
        if (formPrototypeId) {
          questPrototypeAdapter.updateOne(state, {
            id: formPrototypeId,
            changes: {
              assignmentIds: (
                state.entities[formPrototypeId]?.assignmentIds || []
              ).filter((assignmentId) => action.payload.id !== assignmentId),
            },
          });
        }
      })
      .addCase(assignmentsLoaded, (state, action) =>
        questPrototypeAdapter.upsertMany(
          state,
          action.payload.assignments
            .flatMap((assignment) => [
              assignment?.formPrototype,
              assignment?.formPrototype?.quest?.currentFormPrototype,
            ])
            .filter((formPrototype) => Boolean(formPrototype?.id))
            .map(mapQuestPrototype)
        )
      )
      .addCase(questsLoaded, (state, action) =>
        questPrototypeAdapter.upsertMany(
          state,
          action.payload
            .filter((quest) =>
              Boolean((quest as TemplateListItem)?.currentQuestPrototype?.id)
            )
            .map((quest) =>
              mapQuestPrototype(
                (quest as TemplateListItem).currentQuestPrototype!
              )
            )
        )
      )
      .addCase(questLoaded, (state, action) =>
        questPrototypeAdapter.upsertOne(
          state,
          mapQuestPrototype({
            ...action.payload.currentQuestPrototype!,
            quest: action.payload,
          })
        )
      )
      .addCase(minimalPublicQuestDataLoaded, (state, action) =>
        questPrototypeAdapter.upsertOne(
          state,
          mapQuestPrototype(action.payload.formPrototype)
        )
      )
      .addCase(questInstanceLoaded, (state, action) => {
        questPrototypeAdapter.upsertOne(
          state,
          mapQuestPrototype({
            ...action.payload.prototype,
            quest: { id: action.payload.quest.id },
          })
        );
      })
      .addCase(questInstanceListLoaded, (state, action) =>
        questPrototypeAdapter.upsertMany(
          state,
          action.payload.map((questInstance) => {
            return mapQuestPrototype({
              ...questInstance.prototype,
              quest: questInstance.quest,
            });
          })
        )
      )
      .addCase(userLogout, (state) => questPrototypeAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

export const { questPrototypeLoaded } = slice.actions;

type APIQuestPrototype =
  | QuestPrototypeDetail
  | Exclude<TemplateListItem["currentQuestPrototype"], null>
  | Exclude<TemplateDetails["currentQuestPrototype"], null>
  | AssignmentListItem["formPrototype"]
  | AssignmentListItem["formPrototype"]["quest"]["currentFormPrototype"]
  | (Omit<QuestInstanceDetail["prototype"], "quest"> & {
      quest: { id: string };
    })
  | (QuestInstanceListItem["prototype"] & { quest: { id: string } })
  | Exclude<
      QuestPrototypeDetail["items"][number]["subquest"],
      undefined | null
    >;

const mapQuestPrototype = createDataMapper<APIQuestPrototype, QuestPrototype>()(
  ["id", "status", "mode", "name", "introText", "parentItemPrototypeId"],
  {
    owner: (owner) => (owner.id ? { ownerId: owner.id! } : {}),
    quest: (quest) => (quest.id ? { questId: quest.id } : {}),
    sharedInstance: (sharedInstance) => ({
      sharedInstanceId: sharedInstance?.id ?? null,
    }),
    assignments: (assignments) => ({
      assignmentIds: assignments.map(({ id }) => id!),
    }),
    startTriggers: (startTriggers) => ({
      startTriggerIds: startTriggers.map(({ id }) => id!),
    }),
    items: (items) => ({ itemIds: items.map(({ id }) => id!) }),
    rewards: (rewards) => ({ rewardIds: rewards.map(({ id }) => id!) }),
  }
);
