import React, { useContext, useState } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  ViewStyle,
} from "react-native";

import styled, { ThemeContext } from "styled-components/native";
import Text from "./text";
import BaseTextInput from "@app/components/textInput";
import Icon, { IconIdentifier } from "@app/components/icon";

export interface QKTextInputProps extends TextInputProps {
  leftIcon?:
    | IconIdentifier
    | ((isFocused: boolean) => React.ReactElement | null);
  rightIcon?:
    | IconIdentifier
    | ((isFocused: boolean) => React.ReactElement | null);
  /**
   * Style for container
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Defaults to 18
   */
  fontSize?: number;
  /**
   * Minimum # of lines to show when `multiline: true`
   * Not to be confused with:
   *   1) `numberOfLines` which is an Android-only `TextInput` prop
   *   2) `numberOfLines` which is a `Text prop that is used with ellipses
   */
  minNumberOfLines?: number;
  textInputTheme?: "DEFAULT" | "SUBTLE";
}

const QKTextInput = React.forwardRef<TextInput, QKTextInputProps>(
  (
    {
      value,
      placeholder,
      editable = true,
      style,
      onBlur,
      blurOnSubmit,
      autoFocus,
      textAlign,
      multiline = false,
      minNumberOfLines,
      numberOfLines,
      onFocus,
      fontSize = 18,
      leftIcon,
      rightIcon,
      testID,
      textInputTheme = "DEFAULT",
      ...restTextInputProps
    },
    ref
  ) => {
    const theme = useContext(ThemeContext);

    const [isFocused, setIsFocused] = useState(false);

    const textInputLineHeightMultiplier = 1 + 1 / 3;
    const textInputMinHeight =
      multiline && minNumberOfLines
        ? minNumberOfLines * fontSize * textInputLineHeightMultiplier +
          (Platform.OS === "ios" ? 6 : 0)
        : undefined;
    const inputTheme = theme.textInput[isFocused ? "focused" : "normal"];
    return (
      <StyledTextInputContainer
        style={style}
        isFocused={isFocused}
        textInputTheme={textInputTheme}
        multiline={multiline}
      >
        {typeof leftIcon === "function" ? (
          leftIcon(isFocused)
        ) : (
          <Icon
            icon={leftIcon ?? "pen"}
            offsetX={-1 /* due to border width */}
            offsetY={multiline ? 2 : 0}
          />
        )}
        <StyledTextWrapper editable={editable} rightIconPresent={!!rightIcon}>
          {editable ? (
            <StyledTextInput
              autoCorrect={true}
              autoCapitalize="none"
              autoComplete="off"
              textAlignVertical="top"
              pointerEvents={editable ? "auto" : "none"}
              {...restTextInputProps}
              value={value || ""}
              blurOnSubmit={blurOnSubmit !== undefined ? blurOnSubmit : true}
              editable={editable}
              placeholder={placeholder}
              placeholderTextColor={inputTheme.placeholder}
              autoFocus={autoFocus}
              selectionColor={inputTheme.selection}
              ref={ref}
              fontSize={fontSize}
              testID={testID || `${placeholder} field`}
              textAlign={textAlign}
              isFocused={isFocused}
              multiline={multiline}
              minHeight={textInputMinHeight}
              textInputLineHeightMultiplier={textInputLineHeightMultiplier}
              onFocus={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                if (editable) {
                  setIsFocused(true);
                }
                if (onFocus) {
                  onFocus(e);
                }
              }}
              onBlur={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                setIsFocused(false);
                if (onBlur) {
                  onBlur(e);
                }
              }}
            />
          ) : (
            <StyledText
              textAlign={textAlign}
              testID={testID || `${placeholder} field`}
              numberOfLines={numberOfLines}
              colorOverride={
                Boolean(placeholder) && !value
                  ? inputTheme.placeholder
                  : inputTheme.text
              }
              hasContent={!!value}
              minHeight={textInputMinHeight}
            >
              {value || placeholder}
            </StyledText>
          )}
        </StyledTextWrapper>
        {typeof rightIcon === "function" ? (
          rightIcon(isFocused)
        ) : !!rightIcon ? (
          <Icon icon={rightIcon} />
        ) : null}
      </StyledTextInputContainer>
    );
  }
);
QKTextInput.displayName = "QKTextInput";

const StyledText = styled(Text)<{
  textAlign?: string;
  minHeight?: number;
  colorOverride: string;
  hasContent: boolean;
}>`
  align-self: ${({ textAlign }) =>
    textAlign === "center" ? "center" : "flex-start"};
  ${Platform.OS === "web" ? `word-break: break-word` : ``}
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : "")}
  color: ${({ colorOverride }) => colorOverride};
  ${({ hasContent }) =>
    !hasContent && Platform.OS === "web" ? "user-select: none;" : ""}
`;

const StyledTextWrapper = styled.View<{
  editable: boolean;
  rightIconPresent: boolean;
}>`
  padding-top: ${({ editable }) =>
    Platform.OS === "ios" && editable ? 1 : 7}px;
  padding-bottom: 7px;
  flex: 1;
  margin-right: ${({ rightIconPresent }) => (rightIconPresent ? "0" : "20px")};
`;

const StyledTextInput = styled(BaseTextInput)<{
  isFocused: boolean;
  fontSize: number;
  minHeight?: number;
  textInputLineHeightMultiplier: number;
}>`
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize, textInputLineHeightMultiplier }) =>
    fontSize * textInputLineHeightMultiplier}px;
  font-family: CentraNo2-Book;
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : "")}
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.textInput.focused.text : theme.textInput.normal.text};
  ${Platform.OS === "web" ? `outline-style: none;` : ""}
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "auto")};
`;

const StyledTextInputContainer = styled.View<{
  isFocused: boolean;
  textInputTheme: "DEFAULT" | "SUBTLE";
  multiline: boolean;
}>`
  background-color: ${({ theme, isFocused, textInputTheme }) =>
    isFocused
      ? theme.textInput.focused.background
      : textInputTheme === "SUBTLE"
      ? theme.modal
      : theme.textInput.normal.background};
  min-height: 40px;
  border-radius: 20px;
  overflow: hidden;
  ${({ textInputTheme }) =>
    textInputTheme === "DEFAULT" ? "border-width: 1px;" : ""}
  border-color: ${({ theme, isFocused }) =>
    isFocused ? theme.textInput.focused.border : theme.textInput.normal.border};
  flex-direction: row;
  ${({ multiline }) => (multiline ? "" : `align-items: center;`)}
`;

export default QKTextInput;
