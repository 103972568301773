import React from "react";
import { Platform, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

import Text from "@app/components/questkit/text";

export type QuestSectionHeaderProps = {
  text: string;
  description?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export const QuestSectionHeader: React.FC<QuestSectionHeaderProps> = (
  props
) => {
  return (
    <StyledView style={props.style}>
      <HeaderContent>
        <HeaderTitle size="large">{props.text}</HeaderTitle>
        {props.description ? props.description : null}
      </HeaderContent>
    </StyledView>
  );
};

const HeaderTitle = styled(Text)`
  text-align: center;
`;

const HeaderContent = styled.View`
  flex: 1;
  padding: 16px;
  row-gap: 16px;
`;

const StyledView = styled.View`
  flex-direction: row;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const QuestSectionHeaderDescriptionText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.questView.sectionHeader.description};
`;

export const OldQuestSectionHeader: React.FC<QuestSectionHeaderProps> = (
  props
) => {
  return (
    <OldStyledView style={props.style}>
      <StyledText size="small">{props.text}</StyledText>
    </OldStyledView>
  );
};

const StyledText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const OldStyledView = styled.View`
  margin: 20px 12px 20px 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.primary}
  flex-direction: column;
  padding-bottom: 8px;
`;
