import { Platform, Pressable, TextInput } from "react-native";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import styled from "styled-components/native";
import { CalendarTimeInput } from "@app/components/questkit/calendar";
import { Text } from "@app/components/questkit/text";
import {
  FormattedTime,
  getTimeDisplayValue,
  Period,
  validateAndCleanTime,
  ValidateAndCleanTimeResult,
} from "@app/components/timeInput/TimeUtils";

interface TimeInputFieldProps {
  hour: number;
  minute: number;
  onTimeChange: (hour: number, minute: number) => void;
  disabled?: boolean;
}

export const TimeInputField: React.FC<TimeInputFieldProps> = (props) => {
  const {
    hour: parentHour,
    minute: parentMinute,
    onTimeChange,
    disabled = false,
  } = props;
  const snackbar = useContext(SnackbarContext);

  const timeInputRef = useRef<TextInput>(null);

  const [timeValue, setTimeValue] = useState<FormattedTime>(
    () => getTimeDisplayValue(parentHour, parentMinute)[0]
  );
  const [timePeriod, setTimePeriod] = useState<Period>(
    () => getTimeDisplayValue(parentHour, parentMinute)[1]
  );
  const onChangeText = useCallback((text: string) => {
    const cleanedText = text.replaceAll(/[^0-9.]/g, "");
    setTimeValue(cleanedText);
    return cleanedText;
  }, []);

  useEffect(() => {
    const parentTimeDisplayValue = getTimeDisplayValue(
      parentHour,
      parentMinute
    )[0];

    if (timeValue !== parentTimeDisplayValue) {
      setTimeValue(parentTimeDisplayValue);
    }
    // This should only run when the parent minute or hour change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentMinute, parentHour]);

  const processResult = useCallback(
    (result: ValidateAndCleanTimeResult) => {
      if (result.valid) {
        const { hour, minute } = result;
        const [time, period] = getTimeDisplayValue(hour, minute);

        setTimeValue(time);
        setTimePeriod(period);
        onTimeChange(hour, minute);
      } else {
        setTimeValue("9.00");
        setTimePeriod("am");
        onTimeChange(9, 0);
        snackbar.sendMessage(
          "Invalid time input format",
          SnackbarSeverity.WARNING
        );
      }
    },
    [onTimeChange, snackbar]
  );

  const onTimeTextSubmit = useCallback(() => {
    processResult(validateAndCleanTime(timeValue, timePeriod));
  }, [timePeriod, timeValue, processResult]);

  const onToggleTimePeriod = useCallback(() => {
    const newTimePeriod = timePeriod === "pm" ? "am" : "pm";
    setTimePeriod(newTimePeriod);
    processResult(validateAndCleanTime(timeValue, newTimePeriod));
  }, [processResult, timePeriod, timeValue]);

  return (
    <CalendarTimeInput
      iconName="clock"
      ref={timeInputRef}
      onChangeText={onChangeText}
      onSubmit={onTimeTextSubmit}
      rightComponent={
        <AmPmPressable
          onPress={onToggleTimePeriod}
          style={{ paddingRight: 25 }}
          disabled={disabled}
        >
          <TimePeriodText>{timePeriod}</TimePeriodText>
        </AmPmPressable>
      }
      value={timeValue}
      disabled={disabled}
    />
  );
};

const AmPmPressable = styled(Pressable)`
  justify-content: center;
`;
const TimePeriodText = styled(Text)`
  ${Platform.OS === "web" ? `user-select: none` : ``}
  color: ${({ theme }) => theme.primary};
`;
