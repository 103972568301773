import React, { useCallback } from "react";
import { useFocusableRef } from "@app/util/focus";
import { CheckableComponentModel } from "@app/components/item/components/custom/types";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import { Platform } from "react-native";
import * as Haptics from "expo-haptics";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";

interface CustomItemCheckableComponentProps {
  component: CheckableComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemCheckableComponent: React.FC<
  CustomItemCheckableComponentProps
> = ({ component, readOnly }) => {
  const onChange = component.onChange;
  const value = component.value;
  const action = useCallback(() => {
    if (Platform.OS === "ios") {
      void Haptics.selectionAsync();
    }
    onChange(!value);
  }, [onChange, value]);

  return (
    <Row>
      <TitleText size="medium">{component.title}</TitleText>
      <CheckboxToggle
        readOnly={component.disabled || readOnly}
        action={action}
        checked={value}
      />
    </Row>
  );
};

const TitleText = styled(Text)`
  flex-shrink: 1;
`;
const Row = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
