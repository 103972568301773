import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import {
  TeamDetails,
  TeamListItem,
  TeamMembershipListItem,
  WorkspaceDetails,
  WorkspaceListItem,
} from "@questmate/openapi-spec";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { userLogout } from "@app/store/auth";
import { UnionToIntersection } from "@questmate/common";
import { workspaceLoaded, workspacesLoaded } from "@app/store/cache/workspaces";
import { selectAllUsersById } from "@app/store/cache/users";
import {
  selectTeamMembershipsForTeam,
  selectUsersTeamIds,
  teamMemberAdded,
  teamMemberDeleted,
} from "@app/store/cache/teamMemberships";

type Team = Omit<UnionToIntersection<APITeam>, "memberships"> & {
  membershipIds?: string[];
};
const teamAdapter = createEntityAdapter<Team>({});

export const {
  selectById: selectTeamById,
  selectEntities: selectAllTeamsById,
} = teamAdapter.getSelectors<AppState>((state) => state.cache.teams);

export const selectUsersTeams = createSelector(
  [(state) => selectUsersTeamIds(state), selectAllTeamsById],
  (usersTeamIds, allTeams): Team[] => {
    return usersTeamIds.map((id) => allTeams[id]).filter(Boolean) as Team[];
  }
);

export const selectTeamWithMemberships = createSelector(
  [
    (state, teamId) => selectTeamById(state, teamId),
    (state, teamId) => selectTeamMembershipsForTeam(state, teamId),
    (state) => selectAllUsersById(state),
  ],
  (team, teamMemberships, usersById) => {
    if (!team) {
      return team;
    }
    return {
      ...team,
      memberships: team.membershipIds?.map((teamMembershipId) => {
        const teamMembership = teamMemberships.find(
          ({ id }) => id === teamMembershipId
        );
        if (!teamMembership) {
          console.error(
            `Failed to find team membership with id ${teamMembershipId} in cache. Team`,
            team,
            "Team Memberships available",
            teamMemberships
          );
          throw new Error("Expected team membership to exist in cache");
        }
        return {
          ...teamMembership,
          id: teamMembershipId,
          user: usersById[teamMembership.userId]!,
        };
      }),
    };
  }
);

const slice = createSlice({
  name: "cache/teams",
  initialState: teamAdapter.getInitialState(),
  reducers: {
    teamsLoaded: (
      state,
      action: PayloadAction<{ userId: string; teams: TeamListItem[] }>
    ) => teamAdapter.upsertMany(state, action.payload.teams.map(mapTeam)),
    teamLoaded: (state, action: PayloadAction<TeamDetails>) =>
      teamAdapter.setOne(state, mapTeam(action.payload)),
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) => teamAdapter.removeAll(state));
    builder.addCase(
      teamMemberAdded,
      (
        state,
        action: PayloadAction<{
          teamId: string;
          membership: TeamMembershipListItem;
        }>
      ) => {
        teamAdapter.updateOne(state, {
          id: action.payload.teamId,
          changes: {
            membershipIds: [
              ...(state.entities[action.payload.teamId]!.membershipIds || []),
              action.payload.membership.id,
            ],
          },
        });
      }
    );
    builder.addCase(
      teamMemberDeleted,
      (
        state,
        action: PayloadAction<{
          teamId: string;
          membershipId: string;
        }>
      ) => {
        teamAdapter.updateOne(state, {
          id: action.payload.teamId,
          changes: {
            membershipIds: (
              state.entities[action.payload.teamId]!.membershipIds || []
            ).filter(
              (membershipId) => membershipId !== action.payload.membershipId
            ),
          },
        });
      }
    );
    // builder.addCase(questPrototypeLoaded, (state, action) => {
    //   if (!action.payload.workspace) {
    //     return;
    //   }
    //
    //   teamAdapter.upsertOne(state, mapTeam(action.payload.team!));
    // });
    // builder.addCase(questsLoaded, (state, action) =>
    //   teamAdapter.upsertMany(
    //     state,
    //     action.payload
    //       .filter(
    //         (quest): quest is TemplateListItem =>
    //           !!(quest as TemplateListItem).team
    //       )
    //       .map((quest) => mapTeam(quest.team!))
    //   )
    // );
    // builder.addCase(
    //   questLoaded,
    //   (state, action: PayloadAction<TemplateDetails>) => {
    //     if (!action.payload.team) {
    //       return;
    //     }
    //     teamAdapter.upsertOne(state, mapTeam(action.payload.team));
    //   }
    // );
    builder.addCase(workspacesLoaded, (state, action) => {
      teamAdapter.upsertMany(
        state,
        action.payload.workspaces
          .map(({ team }) => team)
          .filter((team): team is APITeam => !!team)
          .map(mapTeam)
      );
    });
    builder.addCase(workspaceLoaded, (state, action) => {
      if (action.payload.team) {
        teamAdapter.upsertOne(state, mapTeam(action.payload.team));
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const { teamsLoaded, teamLoaded } = slice.actions;

type APITeam =
  | TeamDetails
  | TeamListItem
  | Exclude<WorkspaceListItem["team"], null>
  | Exclude<WorkspaceDetails["team"], null>;
const mapTeam = createDataMapper<APITeam, Team>()(["id", "name"], {
  memberships: (memberships) => {
    return {
      membershipIds: memberships.map(({ id }) => id),
    };
  },
});
