import React, { useCallback, useState } from "react";
import { useApp } from "@app/screens/settings/app";
import { LinkAppPrompt } from "@app/components/completionAction/LinkAppPrompt";
import { AppId } from "@questmate/openapi-spec";
import Button from "@app/components/questkit/button";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { useQuestViewContext } from "@app/quest/QuestViewContext";

interface AppLinkerProps {
  appId: AppId;
  onLink?: (success: boolean) => Promise<void> | void;

  layoutStyle?: "full" | "compact";
}
export const QuestScriptAppLinker: React.FC<AppLinkerProps> = ({
  appId,
  onLink,
  layoutStyle = "full",
}) => {
  const { app, link, isLinking, isLoading } = useApp(appId);
  const { isOwner } = useQuestViewContext(["MANAGE"]);

  const [onLinkIsLoading, setOnLinkIsLoading] = useState(false);

  const linkApp = useCallback(() => {
    void link().then((apiAppAuthService) => {
      setOnLinkIsLoading(true);
      const promise = onLink?.(apiAppAuthService.linked);
      if (!promise || !("finally" in promise)) {
        setOnLinkIsLoading(false);
      } else {
        void promise.finally(() => {
          setOnLinkIsLoading(false);
        });
      }
    });
  }, [link, onLink]);

  if (!app) {
    return null;
  }

  const infoText = isOwner
    ? `In order to use this item you must first link your ${app.name} account.`
    : `In order to use this item the Quest owner must first link their ${app.name} account.`;
  return layoutStyle === "full" ? (
    <LinkAppPrompt
      buttonText={`Link ${app.name} Account`}
      infoText={infoText}
      link={linkApp}
      userCanLink={isOwner}
      loading={isLinking || isLoading || onLinkIsLoading}
    />
  ) : (
    <Row>
      <Text size="medium">{app.name}</Text>
      {app.linked ? (
        <LinkedTextWrapper>
          <Text size="medium">Linked</Text>
        </LinkedTextWrapper>
      ) : isOwner ? (
        <LinkButton
          title="Link"
          onPress={linkApp}
          loading={isLinking || isLoading}
          loadingPosition="right"
        />
      ) : (
        <Text>Quest Owner Must Link</Text>
      )}
    </Row>
  );
};

const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const LinkButton = styled(Button)`
  min-width: 100px;
`;
const LinkedTextWrapper = styled.View`
  align-items: center;
`;
