import React from "react";

import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { QuestManageView } from "@app/components/screen/quest/QuestManageView";

import PlaceholderView from "@app/components/screen/PlaceholderView";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { useAppRoute } from "@app/navigation/QMNavigator";
import { ManageQuestViewContextProvider } from "@app/quest/edit/ManageQuestViewContextProvider";
import { useQuestContext } from "@app/quest/QuestContext";
import { ScreenContainer } from "@app/screens/ScreenContainer";

export const QuestEditScreen: React.FC = () => {
  const {
    questId,
    currentQuestPrototypeId: rootQuestPrototypeId,
    advancedMode,
  } = useQuestContext();
  const { questPrototypeId: questPrototypeIdFromRoute } =
    useAppRoute<"QuestEdit">().params || {};

  const questPrototypeId = questPrototypeIdFromRoute ?? rootQuestPrototypeId;
  const { questPrototype, error, isLoading, refresh } =
    useQuestPrototype(questPrototypeId);

  if (!questPrototype) {
    if (error) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: isLoading,
                onPress: refresh,
              },
            ]}
          />
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <ScreenContainer>
      <ManageQuestViewContextProvider
        questId={questId}
        questPrototypeId={questPrototypeId}
        advancedMode={advancedMode}
      >
        <QuestManageView viewMode={"EDIT"} />
      </ManageQuestViewContextProvider>
    </ScreenContainer>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
