import React, { useCallback, useMemo, useState } from "react";
import Icon from "@app/components/icon";
import styled, { useTheme } from "styled-components/native";
import { Text } from "@app/components/questkit";
import {
  CustomItemComponents,
  CustomItemInlineComponent,
} from "@app/components/item/components/custom/ComponentsRenderer";
import { CustomItemModalEditor } from "@app/components/item/components/custom/edit/CustomItemModalEditor";
import {
  CustomItemComponentModel,
  type CustomItemInlineComponentModel,
  CustomItemQuestScriptResponse,
} from "@app/components/item/components/custom/types";
import { LinkText } from "@app/components/LinkText";
import { Pressable } from "react-native";
import QKScrollView from "@app/components/questkit/ScrollView";
import { QuestScriptAppLinker } from "@app/components/item/components/custom/components/QuestScriptAppLinker";
import { AppId } from "@questmate/openapi-spec";
import isEqual from "react-fast-compare";
import { useAutoSave } from "@app/components/item/components/custom/edit/CodeEditor";
import { InfoText } from "@app/components/questkit/InfoText";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { CustomItemDebugCommand } from "@app/components/item/components/custom/v2/CustomItemV2ManageView";
import { Portal } from "@gorhom/portal";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";

export interface CustomItemView<
  T extends CustomItemQuestScriptResponse = CustomItemQuestScriptResponse
> {
  isRegistered: boolean | undefined;
  components: CustomItemComponentModel[];
  inlineComponent: CustomItemInlineComponentModel | undefined;
  isLoading: boolean;
  hasError: boolean;
  reload: (skipRefreshOfDataSources?: boolean) => Promise<void>;
  rawResponse: T | undefined;
}

export interface CustomItemManageViewProps {
  viewMode: "EDIT" | "READ_ONLY";
  script: string;
  onChangeScript: (script: string) => void;
  requestedApps: CustomItemQuestScriptResponse["requestedApps"];
  configView: CustomItemView;
  runViewPreview: CustomItemView;
  onDebugCommand?: (command: CustomItemDebugCommand) => void;
  inlineNodePortalName: string;
  isCompletionAction: boolean;
  isLoadingScriptContent?: boolean;
}

const _CustomItemManageView: React.FC<CustomItemManageViewProps> = ({
  viewMode,
  script,
  onChangeScript: _onChangeScript,
  requestedApps,
  configView,
  runViewPreview,
  onDebugCommand,
  inlineNodePortalName,
  isCompletionAction,
  isLoadingScriptContent,
}) => {
  const theme = useTheme();
  const appsRequiringLinking = useMemo(() => {
    return requestedApps.filter(({ linked }) => !linked);
  }, [requestedApps]);

  const _reloadConfigView = configView.reload;
  const reloadConfigView = useCallback(
    () => _reloadConfigView(),
    [_reloadConfigView]
  );
  const _reloadRunViewPreview = runViewPreview.reload;
  const reloadRunViewPreview = useCallback(
    () => _reloadRunViewPreview(),
    [_reloadRunViewPreview]
  );

  const onAppLink = useCallback(
    (success: boolean) => {
      if (success) {
        return reloadConfigView().then(reloadRunViewPreview);
      }
    },
    [reloadConfigView, reloadRunViewPreview]
  );
  const [showModal, setShowModal] = useState(false);

  // TODO: consider moving this to the CodeEditor to better encapsulate responsibility
  const { onTriggerSave: onScriptSaveTriggered, onChange: onChangeScript } =
    useAutoSave(script, _onChangeScript, () =>
      reloadConfigView().then(reloadRunViewPreview)
    );

  const requiresAppLinking = appsRequiringLinking.length > 0;
  const scriptIsEmpty = !Boolean(script);
  const configViewHasNoComponents =
    configView.components.length === 0 &&
    !configView.inlineComponent &&
    !configView.isLoading &&
    !configView.hasError;

  const runViewPreviewHasBeenRenderedBefore =
    runViewPreview.isRegistered !== undefined;
  const renderRunViewPreviewSection = !requiresAppLinking && !scriptIsEmpty;
  const runViewHasNoComponents =
    runViewPreview.components.length === 0 &&
    !runViewPreview.inlineComponent &&
    !runViewPreview.isLoading &&
    !runViewPreview.hasError;

  return (
    <>
      {isLoadingScriptContent ? (
        <QKActivityIndicator />
      ) : (
        <>
          <>
            {viewMode === "EDIT" ? (
              <Row>
                <Text size="mediumBold">Configuration</Text>
                <Pressable onPress={() => setShowModal(true)}>
                  <Text $underlined>Customize</Text>
                </Pressable>
              </Row>
            ) : null}
            {requiresAppLinking ? (
              <>
                {appsRequiringLinking.map((app, index) => (
                  <React.Fragment key={app.id}>
                    {index !== 0 ? <SpacerSmall /> : null}
                    <QuestScriptAppLinker
                      appId={app.id as AppId}
                      onLink={onAppLink}
                    />
                  </React.Fragment>
                ))}
              </>
            ) : scriptIsEmpty ? (
              <>
                <Text $warning={true}>
                  This item has not been customized yet.
                </Text>
                {viewMode === "EDIT" ? (
                  <StyledInfoText
                    text={
                      <>
                        Write your own script to unleash the full power of
                        Questmate or{" "}
                        <LinkText
                          to="https://starbase.questmate.com/automation-customization-integrations/questscript/custom-items"
                          newTabOnWeb={true}
                        >
                          start with one of ours
                        </LinkText>{" "}
                        and tweak it to perfection.{" "}
                        <LinkText
                          to="https://help.questmate.com/automation-customization-integrations"
                          newTabOnWeb={true}
                        >
                          Learn more.
                        </LinkText>
                      </>
                    }
                  />
                ) : null}
              </>
            ) : viewMode === "READ_ONLY" ? null : configViewHasNoComponents ? (
              <Text>This item is not configurable.</Text>
            ) : (
              <CustomItemComponents
                components={configView.components}
                isLoading={configView.isLoading}
                hasError={configView.hasError}
                onErrorRetry={reloadConfigView}
              />
            )}
          </>
          {runViewPreview.inlineComponent ? (
            <Portal hostName={inlineNodePortalName}>
              <CustomItemInlineComponent
                component={runViewPreview.inlineComponent}
                readOnly={true}
                isLoading={runViewPreview.isLoading}
              />
            </Portal>
          ) : isCompletionAction && viewMode === "READ_ONLY" ? (
            <Portal hostName={inlineNodePortalName}>
              <CheckboxToggle readOnly={true} checked={false} />
            </Portal>
          ) : null}
          {renderRunViewPreviewSection ? (
            configView.isLoading && !runViewPreviewHasBeenRenderedBefore ? (
              viewMode === "READ_ONLY" ? (
                <QKLoadingIndicator
                  name={`run-preview-loader-while-waiting-for-config-view-to-render`}
                  size={32}
                  color={theme.primary}
                />
              ) : null
            ) : (
              <>
                {viewMode === "EDIT" ? (
                  <>
                    <Spacer />
                    <Text size="mediumBold">Run (Preview)</Text>
                    <SpacerSmall />
                  </>
                ) : null}
                {runViewPreview.isRegistered === false ? (
                  <Text>This item does not have a Run View registered.</Text>
                ) : runViewPreviewHasBeenRenderedBefore &&
                  runViewHasNoComponents ? (
                  <Text $warning={true}>
                    This item is not rendering any components for the Run View.
                    Ensure the configuration is valid, check the script for
                    errors, or do not register the Run View.
                  </Text>
                ) : (
                  <CustomItemComponents
                    components={runViewPreview.components}
                    isLoading={configView.isLoading || runViewPreview.isLoading}
                    hasError={runViewPreview.hasError}
                    readOnly={true}
                    onErrorRetry={reloadRunViewPreview}
                  />
                )}
              </>
            )
          ) : null}
        </>
      )}

      {viewMode === "EDIT" ? (
        <CustomItemModalEditor
          script={script}
          onChangeScript={onChangeScript}
          onScriptSaveTriggered={onScriptSaveTriggered}
          showModal={showModal}
          setShowModal={setShowModal}
          configViewResponse={configView.rawResponse}
          runViewResponse={runViewPreview.rawResponse}
          sideContent={
            <QKScrollView>
              <Text size="mediumBold">Apps</Text>
              <SpacerSmall />
              {requestedApps.length > 0 ? (
                requestedApps.map((app, index) => (
                  <React.Fragment key={app.id}>
                    {index !== 0 && <SpacerSmall />}
                    <QuestScriptAppLinker
                      appId={app.id}
                      layoutStyle="compact"
                      onLink={(result) => {
                        void onAppLink(result)?.finally(() => undefined);
                        // do not return promise here to let link button stop spinning right after linking is completed
                        // since we have other loading indicators in this view
                      }}
                    />
                  </React.Fragment>
                ))
              ) : (
                <Text>
                  This item does not require any apps to be linked to Questmate.
                </Text>
              )}
              <Spacer />
              <Row>
                <Text size="mediumBold">Config View</Text>
                {configView.isLoading ? null : (
                  <StyledRefreshButton
                    activeOpacity={0.8}
                    onPress={reloadConfigView}
                  >
                    <Icon icon="refresh" />
                  </StyledRefreshButton>
                )}
              </Row>
              {configView.isRegistered ||
              configView.isRegistered === undefined ? (
                <CustomItemComponents
                  components={configView.components}
                  isLoading={configView.isLoading}
                  hasError={configView.hasError}
                  onErrorRetry={reloadConfigView}
                />
              ) : (
                <Text>This item does not have a Config View registered.</Text>
              )}
              <Spacer />
              <Spacer />
              <Row>
                <Text size="mediumBold">Run View (Preview)</Text>
                {configView.isLoading || runViewPreview.isLoading ? null : (
                  <StyledRefreshButton
                    activeOpacity={0.8}
                    onPress={reloadRunViewPreview}
                  >
                    <Icon icon="refresh" />
                  </StyledRefreshButton>
                )}
              </Row>

              {runViewPreview.isRegistered ||
              runViewPreview.isRegistered === undefined ? (
                <>
                  {runViewPreview.inlineComponent ? (
                    <Row>
                      <Text>Inline Component</Text>
                      <CustomItemInlineComponent
                        component={runViewPreview.inlineComponent}
                        readOnly={false}
                        isLoading={runViewPreview.isLoading}
                      />
                    </Row>
                  ) : null}
                  <CustomItemComponents
                    components={runViewPreview.components}
                    isLoading={runViewPreview.isLoading}
                    hasError={runViewPreview.hasError}
                    onErrorRetry={reloadRunViewPreview}
                  />
                </>
              ) : (
                <Text>This item does not have a Run View registered.</Text>
              )}
              {onDebugCommand && (
                <>
                  <Spacer />
                  <Text size="mediumBold">Debug</Text>
                  <SpacerSmall />
                  <DebugCommandLink
                    onDebugCommand={onDebugCommand}
                    command={"CLEAR_CONFIG_STATE"}
                    text={"Reset Config Data"}
                  />
                  <DebugCommandLink
                    onDebugCommand={onDebugCommand}
                    command={"CLEAR_RUN_PREVIEW_STATE"}
                    text={"Reset Run Preview Data"}
                  />
                  <DebugCommandLink
                    onDebugCommand={onDebugCommand}
                    command={"CLEAR_CACHE"}
                    text={"Clear Datasource Cache"}
                  />
                </>
              )}
            </QKScrollView>
          }
        />
      ) : null}
    </>
  );
};

type DebugCommandLinkProps = {
  command: CustomItemDebugCommand;
  onDebugCommand: (command: CustomItemDebugCommand) => void;
  text: string;
};
export const DebugCommandLink: React.FC<DebugCommandLinkProps> = ({
  command,
  onDebugCommand,
  text,
}) => {
  const onPress = useCallback(() => {
    onDebugCommand(command);
  }, [command, onDebugCommand]);
  return (
    <DebugCommandLinkButton onPress={onPress}>
      <Text $underlined>{text}</Text>
    </DebugCommandLinkButton>
  );
};

const DebugCommandLinkButton = styled(PressableOpacity)`
  margin-bottom: 12px;
`;

export const CustomItemManageView = React.memo(_CustomItemManageView, isEqual);
CustomItemManageView.displayName = "CustomItemManageView";

const StyledInfoText = styled(InfoText)``;
const StyledRefreshButton = styled(PressableOpacity)`
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
`;

const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Spacer = styled.View`
  height: 40px;
`;
const SpacerSmall = styled.View`
  height: 20px;
`;
