import React, { useCallback } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import TextInput from "@app/components/questkit/textInput";
import styled from "styled-components/native";
import { useFocusableRef } from "@app/util/focus";

const changeText = (item: ItemRenderData, text: string): ItemRenderData => ({
  ...item,
  data: {
    text: text,
  },
  version: item.version + 1,
});

export const TextInputItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate, onComplete, editMode, readOnly } =
    props;
  const text = item.data.text;
  const focusRefCallback = useFocusableRef(item.prototype.id);

  const onChangeText = useCallback(
    (text: string) => {
      onItemChange?.(changeText(item, text));
    },
    [onItemChange, item]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <StyledTextInput
          ref={focusRefCallback}
          value={text}
          onChangeText={onChangeText}
          onBlur={onItemValidate}
          onSubmitEditing={onComplete}
          leftIcon="pen"
          placeholder={
            editMode ? "Answer will be written here." : "Write here."
          }
          editable={!readOnly}
          accessibilityLabelledBy={`item-name-label-${item.prototype.id}`}
        />
      }
    />
  );
};

const StyledTextInput = styled(TextInput)``;
