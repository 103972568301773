import Intercom from "@intercom/messenger-js-sdk";
import { shutdown, update } from "@intercom/messenger-js-sdk/index";
import { IntercomSettings } from "@intercom/messenger-js-sdk/types";

// interface IntercomInitProps {
//   // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
//   user_id?: string;
//   // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
//   name?: string;
//   // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
//   email?: string;
//   avatar?: {
//     type: "avatar";
//     image_url: string;
//   };
//   // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
//   // created_at: string;
// }

export function InitIntercom(props: Omit<IntercomSettings, "app_id">) {
  Intercom({
    ...props,
    app_id: "jb6c0bj2",
  });
}

export function logoutIntercom() {
  shutdown();
}

export function updateIntercom(...args: Parameters<typeof update>) {
  update(...args);
}
