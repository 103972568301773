import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components/native";
import Icon from "@app/components/icon";
import { Pressable } from "react-native";
import QKScrollView from "@app/components/questkit/ScrollView";
import QuestmateIcons from "@app/components/icon/QuestmateIcons.generated";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import TextInput from "@app/components/questkit/textInput";

export const IconList: React.FC = () => {
  const snackbarContext = useContext(SnackbarContext);

  const [searchText, setSearchText] = useState("");

  const icons = useMemo(() => {
    return QuestmateIcons.filter((icon) => {
      const searchTerms = searchText
        .split(/[,.-/_\s]+/)
        .map((term) => term.toLowerCase().trim())
        .filter((term) => term.length > 0);
      return (
        searchTerms.length === 0 ||
        searchTerms.some(
          (term) =>
            (icon.name || "").toLowerCase().includes(term) ||
            icon.tags.some((tag: string) =>
              (tag || "").toLowerCase().includes(term)
            ) ||
            icon.code.toString() === term
        )
      );
    });
  }, [searchText]);

  return (
    <QKScrollView>
      <Wrapper>
        <IconSearchbar
          value={searchText}
          onChangeText={setSearchText}
          placeholder={"Search..."}
        />
        <Icons>
          {icons.map((icon, index) => (
            <Pressable
              key={index}
              onPress={() => {
                snackbarContext.sendMessage(
                  `Name: ${icon.name}\nTags: ${icon.tags.join(", ")}\nCode: ${
                    icon.code
                  }`
                );
                console.log(`Icon Name: ${icon.name}`);
                console.log(`Icon Tags: ${icon.tags}`);
              }}
            >
              <Icon icon={icon.name} size={80} />
            </Pressable>
          ))}
        </Icons>
      </Wrapper>
    </QKScrollView>
  );
};

const IconSearchbar = styled(TextInput)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Wrapper = styled.View`
  padding: 20px;
`;

const Icons = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.background};
`;
