import React from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { Platform, StyleProp, ViewStyle } from "react-native";

type LetterIconProps = {
  size: number;
  letter: string;
  style?: StyleProp<ViewStyle>;
};
export const LetterIcon: React.FC<LetterIconProps> = ({
  size,
  letter,
  style,
}) => {
  const singleLetter = letter?.[0]?.toUpperCase() ?? "?";

  return (
    <LetterIconContainer size={size} style={style}>
      <LetterIconText iconSize={size}>{singleLetter}</LetterIconText>
    </LetterIconContainer>
  );
};

const ratioOfLetterSizeToIconSize = 2 / 3;

const LetterIconText = styled(Text)<{ iconSize: number }>`
  color: ${({ theme }) => theme.background};
  font-size: ${({ iconSize }) =>
    Math.floor(iconSize * ratioOfLetterSizeToIconSize)}px;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;
const LetterIconContainer = styled.View<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: ${({ size }) => Math.ceil(size / 2)}px;
  border-color: ${({ theme }) => theme.primary};
  border-width: 1px;
  justify-content: center;
  align-items: center;
`;
