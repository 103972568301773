import { MutableRefObject, Ref, RefCallback, useCallback, useRef } from "react";

export const useRefSynchronizer = <T>(
  ...refsToSynchronize: Ref<T>[]
): RefCallback<T> => {
  const refs = useRef(refsToSynchronize);
  refs.current = refsToSynchronize;

  return useCallback((instance: T | null) => {
    for (const ref of refs.current) {
      assignValueToRef(ref, instance);
    }
  }, []);
};

export function assignValueToRef<T>(ref: Ref<T>, value: T | null) {
  if (ref) {
    if (typeof ref === "function") {
      ref(value);
    } else {
      (ref as MutableRefObject<T | null>).current = value;
    }
  }
}
