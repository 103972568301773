import React, { useCallback, useContext, useRef, useState } from "react";

import styled from "styled-components/native";
import { Pressable, ScrollView } from "react-native";
import {
  DEFAULT_INITIAL_SCHEDULE,
  SchedulerInput,
} from "@app/components/scheduler";
import ListItem from "@app/components/questkit/listItem";
import Switch from "@app/components/questkit/switch";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import * as Localization from "expo-localization";
import { Analytics } from "@app/analytics";
import {
  extractUnsavedFields,
  useChangeTracker,
} from "@app/quest/edit/useChangeTracker";
import { usePromise } from "@app/util/usePromise";
import HeaderText from "@app/components/questkit/headerText";
import { AssigneePicker } from "@app/components/questkit/AssigneePicker";
import { Section } from "@app/quest/start/QuestStartTrigger";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import Button from "@app/components/questkit/button";
import { updateQuestAssignees } from "@app/util/client/requests/assignments";
import { UserPickerController } from "@app/components/questkit/UserList/UserPicker";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { useModal } from "@app/components/modal/ModalManager";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { StartTriggerEditFieldsForType } from "@app/store/cache/questStartTriggers";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import Text from "@app/components/questkit/text";
import { formatCronString } from "@app/quest/start/FormatCronString";
import { useQuestViewContext } from "@app/quest/QuestViewContext";

interface ScheduleStartViewProps {
  startTrigger: StartTriggerEditFieldsForType<"SCHEDULE">;
  openStartQuestModal: () => void;
  startChangingQuestType: () => void;
  readOnly: boolean;
}

export const ScheduleStartView: React.FC<ScheduleStartViewProps> = ({
  startTrigger,
  openStartQuestModal,
  startChangingQuestType,
  readOnly,
}) => {
  const { questPrototypeId } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const { runSchedule } = startTrigger.config;

  const {
    openModal: openEditQuestScheduleDialog,
    closeModal,
    addListener,
  } = useModal(({ showModal, setShowModal }) => {
    return (
      <QKModal
        title={`Edit Schedule`}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <EditScheduleModalView
          questPrototypeId={questPrototypeId}
          startTrigger={startTrigger}
          closeEditScheduleModal={closeModal}
        />
      </QKModal>
    );
  });

  useEffectOnce(() =>
    addListener((event) => {
      if ("showModal" in event.updatedFields) {
        if (event.updatedFields.showModal) {
          Analytics.trackEvent("Open Quest Schedule Dialog", {
            actionLocation: "Edit Configuration Link",
          });
        } else {
          Analytics.trackEvent("Close Quest Schedule Dialog");
        }
      }
    })
  );

  let scheduleText = "No schedule set";
  if (runSchedule) {
    scheduleText = formatCronString(runSchedule, readOnly);
  }

  let assigneeText = "No assignees set";
  const assignments = startTrigger.startConfiguration?.assignments;
  if (Array.isArray(assignments) && assignments.length > 0) {
    assigneeText = `${assignments[0].assignee?.displayName || "Public user"} ${
      assignments.length > 1
        ? ` (+${assignments.length - 1} other user${
            assignments.length - 1 > 1 ? "s" : ""
          })`
        : ""
    }`;
  }

  if (readOnly) {
    return (
      <StartTriggerSummary
        icon={QUEST_TYPES["SCHEDULE"].icon}
        text={scheduleText}
      />
    );
  } else {
    return (
      <StartTriggerCard
        title={QUEST_TYPES["SCHEDULE"].name}
        icon={QUEST_TYPES["SCHEDULE"].icon}
        startChangingQuestType={startChangingQuestType}
      >
        <ConfigurationContent>
          <ListItem
            text="Enable Quest Schedule"
            icon="turn-off"
            onPress={openEditQuestScheduleDialog}
            actionComponent={
              <Switch
                switchOn={startTrigger.enabled}
                onSwitch={openEditQuestScheduleDialog}
                loading={false}
              />
            }
          />
          <Pressable onPress={openEditQuestScheduleDialog}>
            <ReadOnlySchedulerInput
              schedule={startTrigger.config.runSchedule!}
              timezone={startTrigger.config.runScheduleTimezone!}
              onChange={noOp}
              readOnly={true}
            />
          </Pressable>
          <ListItem
            onPress={openEditQuestScheduleDialog}
            testID="setup-schedule-configuration-button"
            text={
              <>
                {startTrigger.enabled ? scheduleText : "Schedule Disabled"}.{" "}
                <Text $underlined>Change Settings</Text>
              </>
            }
            icon="clock"
          />
          <ListItem
            onPress={openEditQuestScheduleDialog}
            text={assigneeText}
            icon="group"
          />
        </ConfigurationContent>
        <StyledButton
          onPress={openStartQuestModal}
          testID="open-start-quest-modal-button"
          title={"Run once now"}
        />
      </StartTriggerCard>
    );
  }
};

const noOp = () => undefined;

const ReadOnlySchedulerInput = styled(SchedulerInput)`
  background-color: ${({ theme }) => theme.cardInCard};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ConfigurationContent = styled.View`
  padding-horizontal: 16px;
`;
const StyledButton = styled(Button)`
  margin-top: 40px;
`;

type EditScheduleModalViewProps = {
  questPrototypeId: string;
  startTrigger: ScheduleStartViewProps["startTrigger"];
  closeEditScheduleModal: () => void;
};
export const EditScheduleModalView: React.FC<EditScheduleModalViewProps> = ({
  questPrototypeId,
  startTrigger: startTriggerFromServer,
  closeEditScheduleModal,
}) => {
  const snackbar = useContext(SnackbarContext);

  const { useValueWithChanges, addChange, getChangeSet } = useChangeTracker(
    startTriggerFromServer
  );
  const startTrigger = useValueWithChanges();

  const userTimezone = Localization.useCalendars()?.[0]?.timeZone;
  useEffectOnce(() => {
    // Set default schedule and timezone values if not set
    if (!startTrigger.config.runSchedule) {
      addChange((startTrigger) => {
        startTrigger.config.runSchedule = DEFAULT_INITIAL_SCHEDULE;
      });
    }
    if (!startTrigger.config.runScheduleTimezone) {
      addChange((startTrigger) => {
        startTrigger.config.runScheduleTimezone = userTimezone;
      });
    }
  });

  const assigneePickerRef = useRef<UserPickerController>(null);

  const [saveSuccess, setSaveSuccess] = useState(false);
  const { execute: save, isLoading } = usePromise(async () => {
    Analytics.trackEvent("Save Quest Schedule");

    const changeSet = getChangeSet();
    const changedStartTrigger = changeSet.valueWithChanges;
    if (
      !changedStartTrigger.config.runSchedule?.trim() &&
      changedStartTrigger.config.runSchedule !== null
    ) {
      snackbar.sendMessage(
        "Please enter a valid schedule",
        SnackbarSeverity.WARNING
      );
      return;
    } else if (
      changedStartTrigger.config.runSchedule &&
      !changedStartTrigger.config.runScheduleTimezone
    ) {
      snackbar.sendMessage(
        "Please select a timezone",
        SnackbarSeverity.WARNING
      );
      return;
    }

    let assigneesUpdated = false;

    if (assigneePickerRef.current) {
      try {
        const assignees = await assigneePickerRef.current.flush();
        if (changedStartTrigger.enabled && assignees.length === 0) {
          // Let them save with zero assignees if it is disabled.
          snackbar.sendMessage(
            "Please add at least one assignee.",
            SnackbarSeverity.WARNING
          );
          return;
        }
        const result = await updateQuestAssignees(
          questPrototypeId,
          changedStartTrigger.startConfiguration!.assignments.map(
            ({ id, assigneeId }) => ({
              id,
              assigneeId,
            })
          ),
          assignees
        );

        if (result.errors.length > 0) {
          result.errors.forEach((error) => {
            console.log(error);
            snackbar.sendMessage(
              `Could not ${
                error.action === "ADD" ? "add" : "remove"
              } assignee.`,
              SnackbarSeverity.WARNING
            );
          });
          return;
        }

        assigneesUpdated = true;
      } catch (e) {
        console.log(e);
        snackbar.sendMessage(
          `Could not save assignees.`,
          SnackbarSeverity.WARNING
        );
        return;
      }
    }

    if (changeSet.hasUnsavedChanges) {
      const unsavedFields = extractUnsavedFields(changeSet, [
        ["enabled"],
        ["config", "runSchedule"],
        ["config", "runScheduleTimezone"],
      ]);
      changeSet.markPending();
      await updateQuestStartTrigger(
        questPrototypeId,
        startTrigger.id,
        unsavedFields
      )
        .then(() => {
          changeSet.markSaved();
          setSaveSuccess(true);
          setTimeout(closeEditScheduleModal, MODAL_CLOSE_DELAY);
        })
        .catch(() => {
          const message = "Couldn't save schedule. Try again later.";
          snackbar.sendMessage(message, SnackbarSeverity.WARNING);
          // if save failed, reset the local to previous value to avoid confusion to the user
          changeSet.rollbackChanges(changeSet.changesByStatus.pending);
        });
    } else {
      if (assigneesUpdated) {
        setSaveSuccess(true);
      }
      setTimeout(closeEditScheduleModal, MODAL_CLOSE_DELAY);
    }
  });

  const toggleScheduleEnabled = useCallback(() => {
    addChange((startTrigger) => {
      const newScheduleEnabled = !startTrigger.enabled;
      startTrigger.enabled = newScheduleEnabled;
      Analytics.trackEvent(
        `${newScheduleEnabled ? "Enable" : "Disable"} Quest Schedule`
      );
    });
  }, [addChange]);

  const setScheduleAndTimezone = useCallback(
    (schedule: string, timezone: string) => {
      addChange((qp) => {
        qp.config.runSchedule = schedule;
        qp.config.runScheduleTimezone = timezone;
      });
    },
    [addChange]
  );

  return (
    <EditScheduleDialogWrapper>
      <ScrollView
        keyboardShouldPersistTaps="always"
        showsVerticalScrollIndicator={false}
      >
        <ListItem
          text="Enable Quest Schedule"
          icon="turn-off"
          onPress={toggleScheduleEnabled}
          actionComponent={
            <Switch
              switchOn={startTrigger.enabled}
              onSwitch={toggleScheduleEnabled}
              loading={isLoading}
              testID="schedule-quest-switch"
            />
          }
        />
        <HeaderText title="Schedule" />
        <Section>
          <SchedulerInput
            schedule={startTrigger.config.runSchedule!}
            timezone={startTrigger.config.runScheduleTimezone!}
            onChange={setScheduleAndTimezone}
          />
        </Section>
        <HeaderText title="Assignees" />
        <Section>
          <AssigneePicker
            startConfigurationId={startTrigger.startConfigurationId!}
            disabled={saveSuccess}
            ref={assigneePickerRef}
          />
        </Section>
      </ScrollView>
      <StyledOptionsDialogButton
        onPress={save}
        success={saveSuccess}
        loading={isLoading}
        title="Save"
      />
    </EditScheduleDialogWrapper>
  );
};

const EditScheduleDialogWrapper = styled.View`
  padding-horizontal: 20px;
  padding-vertical: 10px;
  flex-shrink: 1;
`;

const StyledOptionsDialogButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;
