import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { uuid } from "@app/util/uuid";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import isEqual from "react-fast-compare";

export type ModalUpdatedEvent = { updatedFields: { showModal?: boolean } };
type RemoveListenerCallback = () => void;
type ModalContext = {
  useModal: (renderFn: ModalRenderFunction) => {
    showModal: boolean;
    openModal: () => void;
    closeModal: () => void;
    setShowModal: (b: boolean) => void;
    addListener: (
      listener: (event: ModalUpdatedEvent) => void
    ) => RemoveListenerCallback;
  };
};
export const ModalContext = React.createContext<ModalContext>({
  useModal: () => ({
    showModal: false,
    openModal: () => undefined,
    closeModal: () => undefined,
    setShowModal: () => undefined,
    addListener: () => () => undefined,
  }),
});

export const ModalContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [availableModalIds, setAvailableModalIds] = useState<string[]>([]);
  const modalRegistry = useRef<Record<string, ModalManager>>({}).current;
  const useModal = useRef((renderFn: ModalRenderFunction) => {
    // Something unique for each usage of `useModal`
    const modalId = useRef(uuid()).current;

    if (!modalRegistry[modalId]) {
      modalRegistry[modalId] = new ModalManager();
      setTimeout(() => {
        // Cannot call setters in a child components render function. This delay allows for it.
        setAvailableModalIds((prev) => [...prev, modalId]);
      }, 0);
    }
    const modalManager = modalRegistry[modalId];

    modalManager.update({ renderFn });

    const [showModal, _setShowModal, showModalRef] = useStateWithRef(
      modalManager.showModal
    );
    useEffectOnce(() => {
      // Listen for `showModal` to change and then update the state for the calling component to re-render
      const removeListener = modalManager.addListener(() => {
        if (showModalRef.current !== modalManager.showModal) {
          _setShowModal(modalManager.showModal);
        }
      });
      return () => {
        removeListener();
        // remove modal shortly after source is unmounted giving time for it to close
        setTimeout(() => {
          setAvailableModalIds((prev) => prev.filter((id) => id !== modalId));
        }, 3000);
      };
    });

    const setShowModal = useCallback(
      (showModal: boolean) => modalManager.update({ showModal }),
      [modalManager]
    );
    const openModal = useCallback(() => setShowModal(true), [setShowModal]);
    const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

    return useMemo(
      () => ({
        showModal,
        openModal,
        closeModal,
        setShowModal,
        addListener: modalManager.addListener,
      }),
      [modalManager.addListener, closeModal, openModal, setShowModal, showModal]
    );
  }).current;

  const context = useMemo((): ModalContext => {
    return {
      useModal,
    };
  }, [useModal]);
  return (
    <ModalContext.Provider value={context}>
      <>{children}</>
      {availableModalIds.map((id) => {
        return <ModalRenderer key={id} modalManager={modalRegistry[id]} />;
      })}
    </ModalContext.Provider>
  );
};

class ModalManager {
  private readonly listeners: ((event: ModalUpdatedEvent) => void)[] = [];
  private renderFn: ModalRenderFunction;
  showModal = false;

  update({
    renderFn,
    showModal,
  }: Partial<{
    renderFn: ModalRenderFunction;
    showModal: boolean;
  }>) {
    const updatedFields: Partial<{
      renderFn: ModalRenderFunction;
      showModal: boolean;
    }> = {};
    if (renderFn !== undefined && renderFn !== this.renderFn) {
      updatedFields.renderFn = renderFn;
      this.renderFn = renderFn;
    }
    if (showModal !== undefined && showModal !== this.showModal) {
      updatedFields.showModal = showModal;
      this.showModal = showModal;
    }

    if (Object.keys(updatedFields).length > 0) {
      // call listeners after this render is complete to avoid child components calling set state during their render
      setTimeout(() => {
        this.listeners.forEach((listener) => listener({ updatedFields }));
      });
    }
  }

  useModalView = () => {
    const [currentRenderFn, setCurrentRenderFn] = useState(() => this.renderFn);
    const [showModal, _setShowModal] = useState(this.showModal);
    useEffectOnce(() => {
      const listener = () => {
        setCurrentRenderFn(() => this.renderFn);
        _setShowModal(this.showModal);
      };
      this.listeners.push(listener);
      return () => {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
      };
    });

    const setShowModal = useCallback((b: boolean) => {
      this.showModal = b;
      _setShowModal(b);
    }, []);

    return currentRenderFn({ showModal, setShowModal });
  };

  addListener = (listener: (event: ModalUpdatedEvent) => void) => {
    this.listeners.push(listener);
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    };
  };
}

const ModalRenderer: React.FC<{ modalManager: ModalManager }> = React.memo(
  ({ modalManager }) => {
    return modalManager.useModalView();
  },
  isEqual
);
ModalRenderer.displayName = "ModalRenderer";

export type ModalRenderFunctionProps = {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
};

type ModalRenderFunction = (
  props: ModalRenderFunctionProps
) => React.ReactElement;

export const useModal: ModalContext["useModal"] = (...args) => {
  const modalContext = React.useContext(ModalContext);
  if (!modalContext) {
    throw new Error("`useModal` must be used within a `ModalContextProvider`");
  }

  return modalContext.useModal(...args);
};
