import { javascript } from "@codemirror/lang-javascript";
import { type Extension, Prec } from "@codemirror/state";
import { keymap, ViewUpdate } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import React, {
  type ClipboardEvent,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import isEqual from "react-fast-compare";
import { Platform } from "react-native";
import { TextInput } from "@app/components/questkit/text";
import { useEffectOnce } from "@app/util/useEffectOnce";
import styled from "styled-components/native";

interface CodeEditorProps {
  value: string;
  onChange?: (script: string) => unknown;
  readOnly?: boolean;
  autoFocus?: boolean;
  onSaveTriggered?: (explicitSave: boolean) => unknown;
}

export const CodeEditor: React.FC<CodeEditorProps> = (props) => {
  const { value: _value, onChange: _onChange } = props;

  const valueRef = useRef(_value);

  const [, onChange] = useSyncedState(_value, {
    onLocalChange: _onChange,
    onParentChange: (newValue: string) => {
      valueRef.current = newValue;
    },
  });

  const valueThatShouldCauseReRendersWhenItChanges = valueRef.current;
  return Platform.OS === "web" ? (
    <MemoizedCodeMirror
      {...props}
      value={valueThatShouldCauseReRendersWhenItChanges}
      onChange={onChange}
    />
  ) : (
    <StyledTextInput
      value={valueThatShouldCauseReRendersWhenItChanges}
      onChangeText={onChange}
      multiline={true}
    />
  );
};

const StyledTextInput = styled(TextInput)`
  height: 100%;
  width: 100%;
`;

/**
 * Codemirror will close search toolbar, autocomplete suggestions, etc. when it is re-rendered. In order to avoid doing
 * this everytime we make an edit to the script or otherwise cause a re-render, we need to both memoize the render and
 * prevent it from receiving updated script values unless it is actually an update from the server that we have not
 * made locally.
 */

const _MemoizedCodeMirror: React.FC<CodeEditorProps> = ({
  value,
  onChange,
  readOnly,
  autoFocus,
  onSaveTriggered,
}) => {
  const extensions = useMemo(
    (): Extension[] => [
      javascript({ jsx: false }),
      Prec.highest(
        keymap.of([
          {
            key: "Mod-s",
            run(_editorView) {
              onSaveTriggered?.(true);
              // prevent default browser save when user presses save hotkey
              return true;
            },
          },
          // Escape still closes the modal :(
          // {
          //   key: "Escape",
          //   preventDefault: true,
          //   run({ state }) {
          //     // prevent close modal when user presses escape key inside editor
          //     console.log("CodeEditor:76 - escape pressed");
          //     return true;
          //   },
          // },
        ])
      ),
    ],
    [onSaveTriggered]
  );
  const onPaste = useCallback(
    (event: ClipboardEvent) => {
      const linesPasted =
        event.clipboardData?.getData("text/plain")?.split("\n").length ?? 0;
      if (linesPasted > 10) {
        // when users paste a whole new script, we should immediately save it to trigger a fresh render
        onSaveTriggered?.(false);
      }
    },
    [onSaveTriggered]
  );
  const onUpdate = useCallback(
    (viewUpdate: ViewUpdate) => {
      if (viewUpdate.focusChanged && !viewUpdate.view.hasFocus) {
        onSaveTriggered?.(false);
      }
    },
    [onSaveTriggered]
  );
  return (
    <CodeMirror
      value={value}
      minHeight={"100%"}
      height={"100%"}
      maxHeight={"100%"}
      extensions={extensions}
      onPaste={onPaste}
      onChange={onChange}
      onUpdate={onUpdate}
      readOnly={readOnly}
      autoFocus={autoFocus}
      basicSetup={{
        autocompletion: !readOnly,
        completionKeymap: !readOnly,
        lineNumbers: true,
        foldGutter: true,
        highlightActiveLine: false,
        bracketMatching: true,
        syntaxHighlighting: true,
      }}
      style={{
        fontSize: 15,
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
      }}
    />
  );
};

const MemoizedCodeMirror = React.memo(_MemoizedCodeMirror, isEqual);
MemoizedCodeMirror.displayName = "MemoizedCodeMirror";

export const useAutoSave = <T,>(
  initialValue: T,
  onChange: (value: T) => unknown,
  onSave: () => unknown,
  options?: { debounceSeconds?: number }
) => {
  const lastSaveRef = useRef({ time: Date.now(), value: initialValue });
  const lastChangeRef = useRef({ time: Date.now(), value: initialValue });
  const { debounceSeconds = 15 } = options ?? {};

  const onSaveRef = useRef(onSave);
  onSaveRef.current = onSave;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const valueChangedSinceLastSave = () =>
    !isEqual(lastSaveRef.current.value, lastChangeRef.current.value);

  const autoSaveTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const clearAutoSaveTimeout = useRef(() => {
    if (autoSaveTimeoutRef.current) {
      clearTimeout(autoSaveTimeoutRef.current);
    }
  }).current;
  useEffectOnce(() => clearAutoSaveTimeout);

  const save = useCallback(() => {
    lastSaveRef.current = {
      time: Date.now(),
      value: lastChangeRef.current.value,
    };
    clearAutoSaveTimeout();
    return onSaveRef.current();
  }, [clearAutoSaveTimeout]);

  const _onChange = useCallback(
    (newValue: T) => {
      lastChangeRef.current = { time: Date.now(), value: newValue };
      clearAutoSaveTimeout();
      autoSaveTimeoutRef.current = setTimeout(() => {
        if (valueChangedSinceLastSave()) {
          save();
        }
      }, debounceSeconds * 1000);
      onChangeRef.current?.(newValue);
    },
    [clearAutoSaveTimeout, debounceSeconds, save]
  );

  const onTriggerSave = useRef((explicitSave: boolean) => {
    if (explicitSave || valueChangedSinceLastSave()) {
      save();
    }
  }).current;

  return {
    onChange: _onChange,
    onTriggerSave,
    cancelNextAutoSave: clearAutoSaveTimeout,
  };
};
