import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthRequest, loadAsync, dismiss } from "expo-auth-session";
import styled from "styled-components/native";

import Loader from "@app/components/animated/loader";
import PlaceholderView, {
  ButtonComponentProps,
} from "@app/components/screen/PlaceholderView";
import { QMApiError } from "@app/util/client";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { App, AppId } from "@questmate/openapi-spec";
import { sentry } from "@app/util/sentry";
import { uuid } from "@app/util/uuid";

import * as WebBrowser from "expo-web-browser";
import BasePressable from "@app/components/questkit/BasePressable";
import { fetchApp, linkApp, unlinkApp } from "@app/util/client/requests/apps";
import { useRequest } from "@app/util/client/requests";
import { ENV } from "@app/config/env";
import { Analytics } from "@app/analytics";
import { Linking, Platform } from "react-native";
import { AuthSessionResult } from "expo-auth-session/build/AuthSession.types";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { EmitterSubscription } from "react-native/Libraries/vendor/emitter/EventEmitter";
import { useEffectOnce } from "@app/util/useEffectOnce";

WebBrowser.maybeCompleteAuthSession();

interface UseAppResult {
  app?: App;
  error?: QMApiError;
  isLoading: boolean;
  refresh: () => Promise<void>;
  link: () => Promise<App>;
  unlink: () => Promise<App>;
  isLinking: boolean;
  isUnlinking: boolean;
}
export const useApp = (appId: AppId): UseAppResult => {
  const [isLinking, setIsLinking] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [authRequest, setAuthRequest] = useState<AuthRequest | null>(null);
  const snackbar = useContext(SnackbarContext);
  const subscriptions = useRef<EmitterSubscription[]>([]).current;

  useEffectOnce(() => () => {
    subscriptions.forEach((subscription) => subscription.remove());
  });

  // TODO: Use appAuth stored in cache.
  const {
    data: app,
    error,
    refresh,
    mutate,
    isValidating: isLoading,
  } = useRequest(fetchApp(appId));

  useEffect(() => {
    // we can't have this as part of the click handler where it's being
    // used, as it will result in a popup block from desktop safari
    // because of its async nature
    if (app) {
      void loadAsync(
        {
          clientId: app.clientId!,
          redirectUri: makeRedirectUri(app.id),
          responseType: "code",
          scopes: app.scopes!,
          extraParams: {
            ...(app.audience && {
              audience: app.audience,
            }),
            ...(app.additionalAuthParams || {}),
          },
          state: uuid(),
        },
        {
          authorizationEndpoint: app.authorizationUrl,
        }
      ).then(setAuthRequest);
    }
  }, [app]);

  const link = useCallback(async () => {
    if (!authRequest) {
      console.error("AuthRequest not yet initialized, unable to link app.");
      snackbar.sendMessage(
        "Unable to link app. Please try again later.",
        SnackbarSeverity.WARNING
      );
      return (await refresh())!;
    }

    Analytics.trackEvent("Try Link App", {
      appId,
      returnUrl: authRequest.redirectUri,
    });

    setIsLinking(true);
    let linkResult: App;
    try {
      const promptPromise = authRequest.promptAsync({
        authorizationEndpoint: `${app!.authorizationUrl}`,
      });

      const resultProviders = [promptPromise];
      if (Platform.OS === "ios") {
        const { subscription, deepLinkResult } =
          iosRedirectListenerWorkaround(authRequest);
        subscriptions.push(subscription);
        resultProviders.push(deepLinkResult);
      }

      const result = await Promise.race(resultProviders);

      if (result.type === "success") {
        linkResult = await linkApp(appId, {
          params: result.params,
          codeVerifier: authRequest.codeVerifier,
          returnUrl: authRequest.redirectUri,
        });
        await mutate(linkResult, false);
      } else if (result.type === "dismiss") {
        // User dismissed the auth flow.
        console.warn(`User dismissed linking popup for app ${appId}`);
        linkResult = (await refresh())!;
      } else {
        throw result;
      }
    } catch (error) {
      console.error(`Failed to link ${appId} app: `, error);
      sentry.captureException(error, {
        extra: {
          message: `Failed to link ${appId} app.`,
        },
      });
      linkResult = (await refresh())!;
    }
    setIsLinking(false);

    return linkResult;
  }, [appId, app, authRequest, mutate, refresh, snackbar, subscriptions]);

  const unlink = useCallback(async () => {
    setIsUnlinking(true);
    let unlinkResult: App;
    try {
      unlinkResult = await unlinkApp(appId);
      await mutate(unlinkResult, false);
    } catch (error) {
      console.error(`Failed to unlink ${appId} app: `, error);
      sentry.captureException(error, {
        extra: {
          message: `Failed to unlink ${appId} app.`,
        },
      });
      unlinkResult = (await refresh())!;
    }
    setIsUnlinking(false);

    return unlinkResult;
  }, [appId, mutate, refresh]);

  return {
    app,
    isLoading,
    refresh,
    error,
    link,
    unlink,
    isLinking,
    isUnlinking,
  };
};

export const AppScreen: React.FC = () => {
  const navigation = useAppNavigation();
  const appId = useAppRoute<"App">().params?.appId;
  const { app, isLoading, refresh, error, link, unlink, isLinking } = useApp(
    appId as AppId
  );

  useEffect(() => {
    if (app) {
      navigation.setOptions({
        title: app.name,
        headerRight: () => {
          return <HeaderIcon />;
        },
      });
    }
  }, [app, navigation]);

  if (!app) {
    if (error) {
      return (
        <PlaceholderView
          text="Couldn't load your app :("
          actions={[
            {
              type: "primary",
              text: "Retry",
              loading: isLoading,
              onPress: refresh,
            },
          ]}
        />
      );
    } else {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
  }

  return (
    <PlaceholderView
      text={app.linked ? "You're all set!" : "Let's set things up!"}
      actions={
        app.linked
          ? [
              {
                type: "primary",
                text: "Go to Home",
                loading: false,
                onPress: () => navigation.navigate("Home"),
              },
              {
                type: "secondary",
                text: `Unlink ${app.name} Account`,
                onPress: unlink,
              },
            ]
          : app && !app.linked && app.id === "google"
          ? [
              {
                type: "custom",
                Component: GoogleSignInButtonComponent,
                loading: isLinking,
                onPress: link,
              },
            ]
          : [
              {
                type: "primary",
                text: `Link ${app.name} Account`,
                loading: isLinking,
                onPress: link,
              },
            ]
      }
    />
  );
};

const GoogleSignInButtonComponent: React.FC<ButtonComponentProps> = ({
  onPress,
  loading,
}) => {
  return (
    <BasePressable onPress={onPress} disabled={loading}>
      <GoogleSignInButton
        source={require("@app/../assets/images/apps/google/sign-in.png")}
      />
    </BasePressable>
  );
};

const LoaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;

const HeaderIcon = styled.View`
  width: 32px;
`;

const GoogleSignInButton = styled.Image`
  width: 191px;
  height: 46px;
`;

const makeRedirectUri = (appId: AppId) =>
  `${ENV.appBaseUrl}/settings/apps/${appId}`;

const iosRedirectListenerWorkaround = (
  authRequest: AuthRequest
): {
  subscription: EmitterSubscription;
  deepLinkResult: Promise<AuthSessionResult>;
} => {
  let subscription: EmitterSubscription;
  const deepLinkResult = new Promise<AuthSessionResult>((resolve) => {
    subscription = Linking.addEventListener("url", (event) => {
      const { url } = event;
      if (url) {
        const urlPath = url.split("?")[0];
        if (urlPath === authRequest.redirectUri) {
          subscription?.remove();
          try {
            // iOS popup auth session will stay open if not manually closed.
            dismiss();
          } catch (_e) {
            /* ignore */
          }
          resolve(authRequest.parseReturnUrl(event.url));
        }
      }
    });
  });

  return {
    subscription: subscription!,
    deepLinkResult,
  };
};
