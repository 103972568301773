import React, { useCallback } from "react";
import styled from "styled-components/native";

import { Text } from "@app/components/questkit";

import Icon from "@app/components/icon";
import DragPanel, { DragHandlers } from "../questkit/dragPanel";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { Platform } from "react-native";
import Switch from "@app/components/questkit/switch";
import isEqual from "react-fast-compare";
import { Analytics } from "@app/analytics";

interface ItemRowHeaderProps {
  typeName: string;
  setRequired?: (required: boolean) => void;
  isRequired?: boolean;
  onShowItemOptions?: () => void;
  onDelete?: () => void;
  dragHandlers?: DragHandlers;
}

const ItemRowHeader: React.FC<ItemRowHeaderProps> = ({
  typeName,
  onShowItemOptions,
  onDelete,
  setRequired,
  isRequired = false,
  dragHandlers,
}) => {
  const onToggleRequired = useCallback(() => {
    const newValue = !isRequired;
    Analytics.trackEvent(
      `Set Item As ${newValue ? "Required" : "Not Required"}`
    );
    setRequired?.(newValue);
  }, [setRequired, isRequired]);

  return (
    <>
      <Header testID={`item-type-${typeName}`}>
        <ItemTypeDropdown
          activeOpacity={0.8}
          onPress={onShowItemOptions}
          testID="open-item-options"
        >
          <ItemTypeText size="small">{typeName}</ItemTypeText>
          <ChevronIcon
            icon={typeName === "Subquest" ? "gear" : "chevron-down"}
            container="COLLAPSED"
          />
        </ItemTypeDropdown>
        <FullHeightRow>
          {setRequired ? (
            <RequiredContainer>
              <RequiredText size="small">Required</RequiredText>
              <Switch switchOn={isRequired} onSwitch={onToggleRequired} />
            </RequiredContainer>
          ) : null}
          <DeleteButton
            activeOpacity={0.8}
            testID="delete-item"
            onPress={onDelete}
          >
            <Icon icon="trash" container={{ height: 32, width: 32 }} />
          </DeleteButton>
          <DragPanel dragHandlers={dragHandlers!} />
        </FullHeightRow>
      </Header>
      <Separator />
    </>
  );
};

export const Separator = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme.textInput.normal.border};
  margin-vertical: 16px;
  margin-horizontal: 24px;
`;

export const Header = styled.View`
  flex-direction: row;
  padding-horizontal: 20px;
  padding-top: 24px;
  align-items: center;
  justify-content: space-between;
`;

const FullHeightRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ItemTypeDropdown = styled(PressableOpacity)`
  background-color: ${({ theme }) => theme.button.secondary.background};
  padding: 8px 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.button.secondary.border};
  border-radius: 25px;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

const DeleteButton = styled(PressableOpacity)`
  margin: 4px;
`;

const RequiredContainer = styled.View`
  flex-direction: row;
  margin-right: 8px;
  align-items: center;
`;
const RequiredText = styled(Text)`
  margin-right: 8px;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

export const ItemTypeText = styled(Text)`
  color: ${({ theme }) => theme.button.secondary.text};
  ${Platform.OS === "web" ? "user-select: none;" : ""}
  margin-right: 8px;
`;

const ChevronIcon = styled(Icon)`
  color: ${({ theme }) => theme.button.secondary.text};
`;
export default React.memo(ItemRowHeader, isEqual);
