import React, { useContext } from "react";

interface EditItemContext {
  editMode: true;
  prototypeId: string;
  position: number;
  isCompletionAction: boolean;
}

interface RunItemContext {
  editMode: false;
  prototypeId: string;
  position: number;
  isCompletionAction: boolean;
}

type ItemContext = EditItemContext | RunItemContext;

export const ItemContext = React.createContext<ItemContext>({
  editMode: true,
  prototypeId: "",
  position: -1,
  isCompletionAction: false,
});

function inEditMode(itemContext: ItemContext): itemContext is EditItemContext {
  return itemContext.editMode;
}

type ItemContextForEditMode<T extends boolean> = T extends undefined
  ? ItemContext
  : T extends true
  ? EditItemContext
  : RunItemContext;

export const useItemContext = <T extends boolean>(
  expectedEditMode?: T
): ItemContextForEditMode<T> => {
  const itemContext = useContext(ItemContext);
  if (!itemContext) {
    throw new Error(
      "Attempted to use ItemContext outside of a ItemContextProvider"
    );
  }
  const actualEditMode = inEditMode(itemContext);
  if (expectedEditMode !== undefined && actualEditMode !== expectedEditMode) {
    throw new Error(
      `Expected edit mode '${expectedEditMode}' did not match actual edit mode '${actualEditMode}'`
    );
  }
  return itemContext as ItemContextForEditMode<T>;
};
