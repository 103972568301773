import React from "react";
import { MediaContext as APIMediaContext } from "@questmate/openapi-spec";

type MediaContextId = string;
export type MediaContextType = APIMediaContext["type"];

interface MediaContext {
  contexts: APIMediaContext[];
  uploadContextType: MediaContextType;
  uploadContextId: MediaContextId;
}

export const MediaContext = React.createContext<MediaContext>({
  contexts: [],
  uploadContextType: "" as MediaContextType,
  uploadContextId: "",
});
