import styled, { DefaultTheme, useTheme } from "styled-components/native";
import React, { PropsWithChildren } from "react";
import { Platform, TransformsStyle } from "react-native";

const RibbonContainer = styled.View<{
  size: number;
  corner: Exclude<SpecialRibbonProps["corner"], undefined>;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  justify-content: ${({ corner }) =>
    corner.includes("TOP") ? "flex-start" : "flex-end"};
  align-items: ${({ corner }) =>
    corner.includes("LEFT") ? "flex-start" : "flex-end"};
`;

const Ribbon = styled.View<{
  color: string;
}>`
  background-color: ${({ color }) => color};
  align-items: center;
  width: 200%;
`;

type SpecialRibbonProps = PropsWithChildren<{
  color: string | ((props: { theme: DefaultTheme }) => string);
  corner?: "TOP_LEFT" | "TOP_RIGHT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT";
  offset?: number;
}>;
export const SpecialRibbon: React.FC<SpecialRibbonProps> = ({
  children,
  color,
  corner = "TOP_RIGHT",
  offset = 100,
}) => {
  if (Platform.OS !== "web") {
    /**
     * This component could be improved to work on mobile,
     * but all the percentage-based translations (`translateX`, `translateY`) must be changed to pixel values.
     * This could potentially be done by measuring the children with `onLayout`
     * but would likely result in some jank unless thoughtfully countered.
     */
    throw new Error(
      "SpecialRibbon is only supported on web. See comment for more details."
    );
  }
  const containerSize = offset * 2;

  const theme = useTheme();

  const ribbonBackgroundColor =
    typeof color === "function" ? color({ theme }) : color;

  return (
    <RibbonContainer size={containerSize} corner={corner}>
      <Ribbon
        color={ribbonBackgroundColor}
        style={{
          transform: [
            { translateX: corner.includes("RIGHT") ? "50%" : "-50%" },
            { translateY: corner.includes("TOP") ? "-50%" : "50%" },
            {
              rotate:
                corner.includes("TOP_RIGHT") || corner.includes("BOTTOM_LEFT")
                  ? "45deg"
                  : "-45deg",
            },
            { translateY: corner.includes("TOP") ? "50%" : "-50%" },
            { translateY: `${corner.includes("TOP") ? offset : -offset}px` },
          ] as TransformsStyle["transform"],
        }}
      >
        {children}
      </Ribbon>
    </RibbonContainer>
  );
};
