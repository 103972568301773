import {
  QuestInstanceDetail,
  QuestInstanceListItem,
  ItemInstance as ItemInstanceApiModel,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import {
  questInstanceListLoaded,
  questInstanceLoaded,
} from "@app/store/cache/questInstances";
import { selectItemPrototypeById } from "@app/store/cache/itemPrototypes";

export type CachedItemInstance<T extends APIItemInstance> = Omit<
  T,
  "prototype" | "subquestInstances" | "formInstance"
> & {
  /**
   * Item Prototype ID
   */
  prototypeId: string;
  formInstanceId: string;
  subquestInstanceIds?: string[];
};

type ItemInstance = CachedItemInstance<UnionToIntersection<APIItemInstance>>;

const itemInstanceAdapter = createEntityAdapter<ItemInstance, string>({
  selectId: (itemInstance) =>
    `${itemInstance.formInstanceId}|${itemInstance.prototypeId}`,
});

export const {
  selectById: selectItemInstanceByComboId,
  selectEntities: selectAllItemInstancesById,
  selectAll: selectAllItemInstances,
} = itemInstanceAdapter.getSelectors<AppState>(
  (state) => state.cache.itemInstances
);

export const selectItemInstanceWithPrototype = createSelector(
  [
    (state, formInstanceId, itemPrototypeId) =>
      selectItemInstanceByComboId(
        state,
        `${formInstanceId}|${itemPrototypeId}`
      ),
    (state, _formInstanceId, itemPrototypeId) =>
      selectItemPrototypeById(state, itemPrototypeId),
  ],
  (itemInstance, itemPrototype) => {
    return { ...itemInstance, prototype: itemPrototype };
  }
);

const slice = createSlice({
  name: "cache/itemInstances",
  initialState: itemInstanceAdapter.getInitialState(),
  reducers: {
    itemInstanceLoaded: (
      state,
      action: PayloadAction<
        ItemInstanceApiModel & {
          formInstance: { id: string };
        }
      >
    ) => {
      itemInstanceAdapter.upsertOne(state, mapItemInstance(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(questInstanceLoaded, (state, action) => {
        const items = action.payload.itemInstances;
        if (items) {
          itemInstanceAdapter.upsertMany(
            state,
            items.map((ii) =>
              mapItemInstance({
                ...ii,
                formInstance: { id: action.payload.id },
              })
            )
          );
        }
      })
      .addCase(questInstanceListLoaded, (state, action) => {
        const items = action.payload.flatMap((questInstance) =>
          questInstance.itemInstances.map((ii) => ({
            ...ii,
            formInstance: { id: questInstance.id },
          }))
        );
        itemInstanceAdapter.upsertMany(state, items.map(mapItemInstance));
      })
      .addCase(userLogout, (state) => itemInstanceAdapter.removeAll(state));
  },
});

export const { itemInstanceLoaded } = slice.actions;

const reducer = slice.reducer;
export default reducer;

type APIItemInstance =
  | (QuestInstanceDetail["itemInstances"][number] & {
      formInstance: { id: string };
    })
  | (QuestInstanceListItem["itemInstances"][number] & {
      formInstance: { id: string };
    })
  | (ItemInstanceApiModel & {
      formInstance: { id: string };
    });

const mapItemInstance = createDataMapper<APIItemInstance, ItemInstance>()(
  ["data", "version", "completed", "isCompletionAction"],
  {
    prototype: (prototype) =>
      prototype?.id ? { prototypeId: prototype.id! } : {},
    subquestInstances: (subquestInstances) => ({
      subquestInstanceIds: subquestInstances?.map((si) => si.id) || [],
    }),
    formInstance: (formInstance) =>
      formInstance?.id ? { formInstanceId: formInstance.id! } : {},
  }
);
