import { useRef, useCallback } from "react";
import { GestureResponderEvent, Platform } from "react-native";

type UseMultiTapTogglerProps = {
  tapCount: number;
  timeWindow: number; // ms
  onToggle: () => void;
};
export const useMultiTapToggler = ({
  tapCount,
  timeWindow,
  onToggle,
}: UseMultiTapTogglerProps) => {
  const recentTouches = useRef<number[]>([]).current;
  const onToggleRef = useRef(onToggle);
  onToggleRef.current = onToggle;

  const recordTapAndToggleIfAppropriate = useCallback(
    (tapTimestamp: number) => {
      recentTouches.push(tapTimestamp);
      if (recentTouches.length > tapCount) {
        const tenthMostRecentTouch = recentTouches.shift();
        if (
          tenthMostRecentTouch &&
          tapTimestamp - tenthMostRecentTouch < timeWindow
        ) {
          recentTouches.splice(0, recentTouches.length);
          onToggleRef.current();
        }
      }
    },
    [recentTouches, tapCount, timeWindow]
  );

  // this event handler receives all taps on the component and all it's children
  const onStartShouldSetResponderCapture = useCallback(
    (e: GestureResponderEvent) => {
      recordTapAndToggleIfAppropriate(
        // ios does not have a useful event timestamp
        Platform.OS === "ios" ? Date.now() : e.nativeEvent.timestamp
      );
      return false;
    },
    [recordTapAndToggleIfAppropriate]
  );

  return { onStartShouldSetResponderCapture };
};
