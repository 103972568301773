import React, { PropsWithChildren, useCallback, useMemo } from "react";

import {
  Auth0Provider as WebAuth0Provider,
  Cacheable,
  useAuth0,
} from "@auth0/auth0-react";
import { TokenEndpointResponse } from "@auth0/auth0-spa-js/src/global";
import { sentry } from "@app/util/sentry";
import {
  Auth0Context,
  Auth0LogoutGlobalRef,
  IAuth0Context,
  LoginAppState,
} from "./Auth0Context";
import { ENV } from "@app/config/env";

const { appBaseUrl, auth0ClientId, auth0BaseUrl, auth0Audience, auth0Scope } =
  ENV;
const authorizationParams = {
  redirect_uri: `${appBaseUrl}/login`,
  audience: auth0Audience,
  scope: auth0Scope,
};

export const Auth0Provider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <WebAuth0Provider
      domain={auth0BaseUrl}
      clientId={auth0ClientId}
      useRefreshTokens={true}
      cache={Auth0ResponseCache.singleton()}
      authorizationParams={authorizationParams}
    >
      <QuestmateAuth0ProviderAdapter>{children}</QuestmateAuth0ProviderAdapter>
    </WebAuth0Provider>
  );
};

const QuestmateAuth0ProviderAdapter: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { loginWithRedirect, handleRedirectCallback, user, error, logout } =
    useAuth0();
  const [isResultAvailable, setIsResultAvailable] = React.useState(
    window.location.search.includes("code=") ||
      window.location.search.includes("error=")
  );

  const openAuth0UniversalLogin = useCallback(
    (options: {
      appState: LoginAppState;
      identifierHint?: string;
      connection?: string;
    }) =>
      loginWithRedirect({
        appState: options.appState,
        authorizationParams: {
          ...authorizationParams,
          max_age: 10,
          login_hint: options.identifierHint,
          connection: options.connection,
        },
      }),
    [loginWithRedirect]
  );

  const getUniversalLoginResult = useCallback(async () => {
    setIsResultAvailable(false);
    if (error) {
      console.error("Error authenticating with universal login", error);
      sentry.captureException(error);
      return {
        success: false as const,
        error,
      };
    }

    let savedAppState = undefined;
    try {
      const result = await handleRedirectCallback();
      savedAppState = result.appState;
    } catch (e) {
      console.warn("Failed to handle redirect callback", e);
      sentry.captureException(e);
    }

    const tokens = Auth0ResponseCache.singleton().extractTokens();
    if (!tokens.accessToken) {
      console.error("No access token found in Auth0 Response Cache!");
      return {
        success: false as const,
        error: new Error("No access token found"),
      };
    }

    // clear the session from Auth0 so it does not try to refresh the token causing rotation errors
    void logout({ openUrl: false });

    return {
      success: true as const,
      userAttributes: {
        email: user?.email,
      },
      accessToken: tokens.accessToken,
      refreshToken:
        tokens.refreshToken ||
        "NoRefreshTokenProvidedFromAuth0UniversalLoginWebSession",
      savedAppState,
    };
  }, [error, logout, user?.email, handleRedirectCallback]);

  const logoutAuth0 = useCallback(
    (redirectPath = "/login") => {
      return logout({
        openUrl: (url) => {
          // note this is not synchronous and will not delay the logout promise completing.
          window.location.replace(url);
        },
        logoutParams: {
          federated: false,
          returnTo: `${appBaseUrl}${redirectPath}`,
        },
      });
    },
    [logout]
  );

  const context = useMemo((): IAuth0Context => {
    return {
      openAuth0UniversalLogin,
      getUniversalLoginResult,
      logoutAuth0,
      isAuthenticationResultAvailable: isResultAvailable,
    };
  }, [
    getUniversalLoginResult,
    isResultAvailable,
    logoutAuth0,
    openAuth0UniversalLogin,
  ]);

  Auth0LogoutGlobalRef.current = context.logoutAuth0;

  return (
    <Auth0Context.Provider value={context}>{children}</Auth0Context.Provider>
  );
};

class Auth0ResponseCache {
  private static _singleton: Auth0ResponseCache;

  static singleton() {
    if (!Auth0ResponseCache._singleton) {
      Auth0ResponseCache._singleton = new Auth0ResponseCache();
    }
    return Auth0ResponseCache._singleton;
  }

  private _cache: Map<string, unknown> = new Map();

  private constructor() {
    // singleton
  }

  get<T = Cacheable>(key: string): Promise<T | undefined> {
    return Promise.resolve(this._cache.get(key) as T | undefined);
  }

  remove(key: string) {
    this._cache.delete(key);
  }

  set<T = Cacheable>(key: string, entry: T) {
    this._cache.set(key, entry);
  }

  allKeys() {
    return [...this._cache.keys()];
  }

  extractTokens() {
    const keys = this.allKeys();
    const tokens: {
      accessToken?: string;
      refreshToken?: string;
    } = {};
    for (const key of keys) {
      const value = this._cache.get(key);
      if (this.isTokenResponse(value)) {
        tokens.accessToken = value.body.access_token;
        tokens.refreshToken = value.body.refresh_token;
      }
    }
    return tokens;
  }

  private isTokenResponse(value: unknown): value is {
    body: Omit<TokenEndpointResponse, "IDToken">;
  } {
    return Boolean(
      value &&
        typeof value === "object" &&
        "body" in value &&
        value.body &&
        typeof value.body === "object" &&
        "access_token" in value.body &&
        typeof value.body.access_token === "string"
    );
  }
}
