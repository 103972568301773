import {
  CustomItemV1Response,
  CustomItemComponentModel,
} from "@app/components/item/components/custom/types";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { usePromise } from "@app/util/usePromise";
import { mapStaticProps } from "@app/components/item/components/custom/CommonModelMapper";
import { ComponentDefinition } from "@questmate/questscript";
import { CustomItemView } from "@app/components/item/components/custom/edit/CustomItemManageView";
import isEquivalent from "@app/components/item/components/custom/isEquivalent";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";

type SupportedItemType = "dropdown" | "button" | "switch" | "text";

type ExtractComponentsOfType<
  C extends CustomItemComponentModel,
  T extends CustomItemComponentModel["type"]
> = C extends unknown ? (C["type"] extends T ? C : never) : never;

type SupportedComponentModel = ExtractComponentsOfType<
  CustomItemComponentModel,
  SupportedItemType
>;

function mapDefinitionsToModels<T extends Record<string, unknown>>(
  componentDefinitions: ComponentDefinition[],
  viewState: T,
  setViewState: Dispatch<SetStateAction<T>>,
  isLoading: boolean
): (undefined | SupportedComponentModel)[] {
  return componentDefinitions.map((component) => {
    switch (component.type) {
      case "dropdown":
        return {
          ...mapStaticProps(component),
          value: viewState[component.id] as string | number | undefined | null,
          options: component.options,
          onSelect: (value) => {
            setViewState((state) => {
              return {
                ...state,
                [component.id]: value,
              };
            });
          },
          onSearchChange: () => {
            // not implemented
          },

          isLoading: isLoading,
          disabled: isLoading,
        };
      case "button":
        return {
          ...mapStaticProps(component),
          onPress: () => {
            setViewState((state) => {
              return {
                ...state,
                [component.id]: true,
              };
            });
          },
          disabled: isLoading,
          loading: viewState[component.id] === true,
          success: false,
        };
      case "switch":
        return {
          ...mapStaticProps(component),
          value: viewState[component.id] as boolean,
          onSwitch: (value) => {
            setViewState((state) => {
              return {
                ...state,
                [component.id]: value,
              };
            });
          },
          readOnly: isLoading,
          loading: false,
        };
      case "text":
        return {
          ...mapStaticProps(component),
        };
    }
  });
}

export function useV1View(
  executeCustom: (
    viewState: Record<string, unknown>
  ) => Promise<CustomItemV1Response | undefined>,
  parentViewState?: Record<string, unknown>,
  onChangeViewState?: (newState: Record<string, unknown>) => void
): CustomItemView<CustomItemV1Response> {
  const [viewState, setViewState, viewStateRef] = useStateWithRef<
    Record<string, unknown>
  >(parentViewState ?? {});

  const lastReportedViewStateRef = useRef(parentViewState);
  const setViewStateAndReport = useCallback(
    (stateFromResponse: Record<string, unknown> | undefined) => {
      stateFromResponse = stateFromResponse ?? {};
      setViewState(stateFromResponse);

      if (!isEquivalent(stateFromResponse, lastReportedViewStateRef.current)) {
        lastReportedViewStateRef.current = stateFromResponse;
        onChangeViewState?.(stateFromResponse);
      }
    },
    [onChangeViewState, setViewState]
  );

  const lastReceivedParentViewStateRef = useRef(parentViewState);
  useEffect(() => {
    if (
      parentViewState !== undefined &&
      !isEquivalent(parentViewState, lastReceivedParentViewStateRef.current) &&
      !isEquivalent(parentViewState, viewStateRef.current)
    ) {
      lastReportedViewStateRef.current = parentViewState;
      setViewStateAndReRender(parentViewState);
    }
    lastReceivedParentViewStateRef.current = parentViewState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentViewState]);

  const [response, setResponse] = useState<CustomItemV1Response | undefined>(
    undefined
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const questContext = useQuestViewContext();

  const { execute, isLoading } = usePromise(() => {
    const promise = executeCustom(viewStateRef.current)
      .catch((e) => {
        // handle non-2xx responses
        setResponse(e);
        throw e;
      })
      .then((res) => {
        setResponse(res);

        if (res === undefined) {
          return;
        } else if (res.status !== "ok") {
          throw new Error(`QuestScript error: ${res.exception?.message}`);
        }
        const { value } = res;

        setViewStateAndReport(value?.state);
        setHasError(false);
      })
      .catch((err) => {
        setHasError(true);

        // if a change to the view state causes an error, revert to the last reported state
        // this fixes situations where a bad state is saved and then the view can never be loaded
        // also gives users a mechanism of retrying by setting the state again
        setViewState(lastReportedViewStateRef.current ?? {});
        console.error(`Error when rendering: `, err);
      });

    if (isViewContext(questContext, "RUN")) {
      questContext.preSubmitPromiseTracker.trackPromise(promise);
    }

    return promise;
  });

  const setViewStateAndReRender = useCallback(
    (setStateAction: SetStateAction<Record<string, unknown>>) => {
      const prevViewState = viewStateRef.current;
      setViewState(setStateAction);
      const newViewState = viewStateRef.current;
      if (!isEquivalent(prevViewState, newViewState)) {
        // re-render when state changes from component events
        void execute();
      }
    },
    [execute, setViewState, viewStateRef]
  );

  const componentDefinitions = response?.value?.components;
  const componentModels = useMemo(
    () =>
      mapDefinitionsToModels(
        componentDefinitions ?? [],
        viewState,
        setViewStateAndReRender,
        isLoading
      ).filter((c): c is SupportedComponentModel => c !== undefined),
    [componentDefinitions, viewState, setViewStateAndReRender, isLoading]
  );

  return useMemo(() => {
    return {
      isRegistered: true, // if we wanted to improve v1 we could detect if they defined a renderer for this view.
      rawResponse: response,
      hasError,
      isLoading,
      components: componentModels,
      inlineComponent: undefined,
      reload: execute,
    };
  }, [response, hasError, isLoading, componentModels, execute]);
}
