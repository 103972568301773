import React, { useCallback, useContext } from "react";
import { LayoutChangeEvent, Platform } from "react-native";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { SnackbarContext, SnackbarSeverity } from "./SnackbarContext";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { SideBarSafeArea } from "@app/navigation/sideBar/SideBarSafeArea";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import Animated, {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";

export const SnackBar: React.FC = () => {
  const { message, notificationAnimation, resetCurrentMessageDuration } =
    useContext(SnackbarContext);

  const safeAreaInsets = useSafeAreaInsets();

  const topMargin = Platform.OS !== "ios" ? 12 : safeAreaInsets.top + 4;
  const onPressMessage = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation();
      resetCurrentMessageDuration();
    },
    [resetCurrentMessageDuration]
  );

  const [messageIsShown, setMessageIsShown, messageIsShownRef] =
    useStateWithRef(false);

  const updateMessageIsShown = useCallback(
    (newMessageIsShown: boolean) => {
      if (newMessageIsShown !== messageIsShownRef.current) {
        setMessageIsShown(newMessageIsShown);
      }
    },
    [setMessageIsShown, messageIsShownRef]
  );

  useAnimatedReaction(
    () => notificationAnimation.value,
    (value) => {
      const newMessageIsShown = value < 2;
      runOnJS(updateMessageIsShown)(newMessageIsShown);
    },
    [updateMessageIsShown]
  );

  const messageContainerHeight = useSharedValue(200);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      messageContainerHeight.value = event.nativeEvent.layout.height;
    },
    [messageContainerHeight]
  );

  const messageStyle = useAnimatedStyle(() => {
    return {
      elevation: 3,
      transform: [
        {
          translateY: interpolate(
            notificationAnimation.value,
            [0, 1],
            [-messageContainerHeight.value, topMargin],
            Extrapolation.CLAMP
          ),
        },
      ],
      opacity: interpolate(
        notificationAnimation.value,
        [1, 2],
        [1, 0],
        Extrapolation.CLAMP
      ),
    };
  }, [topMargin]);

  return (
    <OverlayContainer
      style={{ pointerEvents: messageIsShown ? "auto" : "none" }}
    >
      <SideBarSafeArea>
        <AnimatedMessageContainer onLayout={onLayout} style={messageStyle}>
          <NotificationPill
            severity={message.severity}
            activeOpacity={0.8}
            onPress={onPressMessage}
            onHoverIn={onPressMessage}
          >
            <SnackbarMessage
              size="medium"
              numberOfLines={8}
              accessibilityRole={"alert"}
            >
              {message.text}
            </SnackbarMessage>
          </NotificationPill>
        </AnimatedMessageContainer>
      </SideBarSafeArea>
    </OverlayContainer>
  );
};

const SnackbarMessage = styled(Text)`
  color: ${({ theme }) => theme.background};
`;
const NotificationPill = styled(PressableOpacity)<{
  severity: SnackbarSeverity;
}>`
  background-color: ${({ theme, severity }) =>
    severity === SnackbarSeverity.WARNING ? theme.warning : theme.primary};
  border-radius: 20px;
  padding-vertical: 8px;
  padding-horizontal: 16px;

  align-self: center;
  margin-horizontal: 20px;
  z-index: 10;
  flex-direction: row;
  max-width: 540px;
`;
const OverlayContainer = styled.View`
  position: absolute;
  width: 100%;
`;

const AnimatedMessageContainer = styled(Animated.View)`
  align-self: center;
  z-index: 10;
  max-width: 540px;
`;
