import {
  type MediaItem as PersistedMediaItem,
  type BaseMediaItem,
} from "@questmate/common";

export interface LocallyUploadedMediaItem extends PersistedMediaItem {
  // state when the item is transitioning from in progress to saved
  localPreviewUri: string; // compressed data URI
  localId: string;
}

export interface InProgressMediaItem extends BaseMediaItem {
  localId: string;
  status: MediaItemStatus;
  blob?: File; // only present on web
  uri: string; // compressed data URI
}

export interface WebMediaItem {
  uri: string;
  type: string; // https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
  blob: File;
  name: string;
}
export interface MobileCameraMediaItem {
  uri: string;
  name: string;
  type: "image" | "video" | undefined;
}
export interface MobileGalleryMediaItem {
  uri: string;
  name: string;
  type: "image" | "video" | undefined;
}
export type UploadableFile =
  | WebMediaItem
  | MobileCameraMediaItem
  | MobileGalleryMediaItem;

export type MediaItem =
  | PersistedMediaItem
  | LocallyUploadedMediaItem
  | InProgressMediaItem;

export enum MediaItemStatus {
  UploadQueued = "UploadQueued",
  UploadFailed = "UploadFailed",
  Uploading = "Uploading",
}
