import Switch from "@app/components/questkit/switch";
import React, { useCallback, useContext, useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { DateTimePicker } from "@app/components/questkit/dateTimePicker";
import styled from "styled-components/native";
import RelativeTimePicker from "@app/components/questkit/relativeTimePicker";
import { TimeAmount, TimeAmountUnit } from "@app/types/time";
import { convertToSeconds, convertToTimeAmount } from "@app/util/time";
import ListItem from "@app/components/questkit/listItem";
import { Analytics } from "@app/analytics";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { InlineDropdown } from "@app/components/questkit/inlineDropdown";

export interface DueDateOptions {
  dueAt: string | null;
  dueAfterSeconds: number | null;
  remindBeforeSeconds: number | null;
  alertBeforeSeconds: number | null;
  allowOverdueSubmissions: boolean;
}

type DueDateDefinitionType = "RELATIVE" | "SPECIFIC";

interface DueDateProps {
  options: DueDateOptions;
  onChange: (dueDateConfig: DueDateOptions) => void;
  style?: StyleProp<ViewStyle>;
  readOnly?: boolean;
  readOnlyMessage?: string;
  disableRelativeDueDate?: boolean;
}

const DueDate: React.FC<DueDateProps> = ({
  options: {
    dueAt: dueAtProp,
    dueAfterSeconds: dueAfterSecondsProp,
    remindBeforeSeconds: remindBeforeSecondsProp,
    alertBeforeSeconds: alertBeforeSecondsProp,
    allowOverdueSubmissions: allowOverdueSubmissionsProp,
  },
  style,
  onChange: _onChange,
  readOnly,
  readOnlyMessage,
  disableRelativeDueDate = false,
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const [dueAt, setDueAt, dueAtRef] = useStateWithRef<string | null>(
    dueAtProp || null
  );
  const [dueAfterSeconds, setDueAfterSeconds, dueAfterSecondsRef] =
    useStateWithRef<number | null>(dueAfterSecondsProp || null);
  const [relativeRemindAt, setRelativeRemindAt, relativeRemindAtRef] =
    useStateWithRef<TimeAmount | null>(
      convertToTimeAmount(remindBeforeSecondsProp)
    );
  const [relativeAlertAt, setRelativeAlertAt, relativeAlertAtRef] =
    useStateWithRef<TimeAmount | null>(
      convertToTimeAmount(alertBeforeSecondsProp)
    );
  const [
    allowOverdueSubmissions,
    setAllowOverdueSubmissions,
    allowOverdueSubmissionsRef,
  ] = useStateWithRef<boolean>(allowOverdueSubmissionsProp);

  useEffect(() => {
    setDueAt(dueAtProp);
  }, [dueAtProp, setDueAt]);

  useEffect(() => {
    setRelativeRemindAt(convertToTimeAmount(remindBeforeSecondsProp));
  }, [remindBeforeSecondsProp, setRelativeRemindAt]);

  useEffect(() => {
    setRelativeAlertAt(convertToTimeAmount(alertBeforeSecondsProp));
  }, [alertBeforeSecondsProp, setRelativeAlertAt]);

  const onChange = useCallback(() => {
    _onChange({
      dueAt: dueAtRef.current,
      dueAfterSeconds: dueAfterSecondsRef.current,
      remindBeforeSeconds: convertToSeconds(relativeRemindAtRef.current),
      alertBeforeSeconds: convertToSeconds(relativeAlertAtRef.current),
      allowOverdueSubmissions: allowOverdueSubmissionsRef.current,
    });
  }, [
    _onChange,
    dueAtRef,
    dueAfterSecondsRef,
    relativeRemindAtRef,
    relativeAlertAtRef,
    allowOverdueSubmissionsRef,
  ]);

  const onUpdateDueDate = useCallback(
    (date: string | null) => {
      Analytics.trackEvent("Set Due Date");
      setDueAt(date);
      onChange();
    },
    [onChange, setDueAt]
  );
  const onUpdateDueAfterSeconds = useCallback(
    (seconds: number | null) => {
      Analytics.trackEvent("Set Due Date");
      setDueAfterSeconds(seconds);
      onChange();
    },
    [onChange, setDueAfterSeconds]
  );

  const onUpdateRelativeRemindAt = useCallback(
    (date: number | null) => {
      Analytics.trackEvent("Set Remind At");
      setRelativeRemindAt(date ? convertToTimeAmount(date) : null);
      onChange();
    },
    [onChange, setRelativeRemindAt]
  );

  const onUpdateRelativeAlertAt = useCallback(
    (date: number | null) => {
      Analytics.trackEvent("Set Alert At");
      setRelativeAlertAt(date ? convertToTimeAmount(date) : null);
      onChange();
    },
    [onChange, setRelativeAlertAt]
  );

  const [
    dueDateDefinitionType,
    _setDueDateDefinitionType,
    dueDateDefinitionTypeRef,
  ] = useStateWithRef<DueDateDefinitionType>(
    dueAt !== null ? "SPECIFIC" : "RELATIVE"
  );

  const initializeDueDate = useCallback(
    (dueDateDefinition: DueDateDefinitionType) => {
      if (dueDateDefinition === "SPECIFIC") {
        const initialDate = new Date();
        initialDate.setDate(initialDate.getDate() + 1);
        setDueAfterSeconds(null);
        onUpdateDueDate(initialDate.toISOString());
      } else if (dueDateDefinition === "RELATIVE") {
        setDueAt(null);
        onUpdateDueAfterSeconds(60 * 60 * 24);
      }
      _setDueDateDefinitionType(dueDateDefinition);
    },
    [
      _setDueDateDefinitionType,
      onUpdateDueAfterSeconds,
      onUpdateDueDate,
      setDueAfterSeconds,
      setDueAt,
    ]
  );

  const onUpdateDueDateDefinitionType = useCallback(
    (dueDateDefinition: DueDateDefinitionType) => {
      if (dueDateDefinitionTypeRef.current !== dueDateDefinition) {
        initializeDueDate(dueDateDefinition);
      }
    },
    [dueDateDefinitionTypeRef, initializeDueDate]
  );

  const toggleDue = useCallback(() => {
    if (readOnly) {
      snackbarContext.sendMessage(
        readOnlyMessage || "Cannot change option",
        SnackbarSeverity.WARNING
      );
      return;
    }

    if (dueAtRef.current || dueAfterSecondsRef.current) {
      Analytics.trackEvent(`Disable Due Date`);
      setDueAfterSeconds(null);
      onUpdateDueDate(null);
    } else {
      Analytics.trackEvent(`Enable Due Date`);
      initializeDueDate(disableRelativeDueDate ? "SPECIFIC" : "RELATIVE");
    }
  }, [
    disableRelativeDueDate,
    dueAfterSecondsRef,
    dueAtRef,
    initializeDueDate,
    onUpdateDueDate,
    readOnly,
    readOnlyMessage,
    setDueAfterSeconds,
    snackbarContext,
  ]);

  const toggleRemindAt = useCallback(() => {
    if (readOnly) {
      snackbarContext.sendMessage(
        readOnlyMessage || "Cannot change option",
        SnackbarSeverity.WARNING
      );
      return;
    }

    if (relativeRemindAtRef.current) {
      Analytics.trackEvent(`Enable Remind At`);
      onUpdateRelativeRemindAt(null);
    } else {
      Analytics.trackEvent(`Disable Remind At`);
      const initialRemindAt = {
        value: 1,
        unit: "hour" as TimeAmountUnit,
      };
      onUpdateRelativeRemindAt(convertToSeconds(initialRemindAt));
    }
  }, [
    onUpdateRelativeRemindAt,
    readOnly,
    readOnlyMessage,
    relativeRemindAtRef,
    snackbarContext,
  ]);

  const toggleAlertAt = useCallback(() => {
    if (readOnly) {
      snackbarContext.sendMessage(
        readOnlyMessage || "Cannot change option",
        SnackbarSeverity.WARNING
      );
      return;
    }

    if (relativeAlertAtRef.current) {
      Analytics.trackEvent(`Enable Alert At`);
      onUpdateRelativeAlertAt(null);
    } else {
      Analytics.trackEvent(`Disable Alert At`);
      const initialAlertAt = {
        value: 1,
        unit: "hour" as TimeAmountUnit,
      };
      onUpdateRelativeAlertAt(convertToSeconds(initialAlertAt));
    }
  }, [
    relativeAlertAtRef,
    onUpdateRelativeAlertAt,
    readOnly,
    readOnlyMessage,
    snackbarContext,
  ]);

  const toggleAllowOverdueSubmissions = useCallback(() => {
    if (readOnly) {
      snackbarContext.sendMessage(
        readOnlyMessage || "Cannot change option",
        SnackbarSeverity.WARNING
      );
      return;
    }
    setAllowOverdueSubmissions(!allowOverdueSubmissionsRef.current);
    onChange();
  }, [
    allowOverdueSubmissionsRef,
    onChange,
    readOnly,
    readOnlyMessage,
    setAllowOverdueSubmissions,
    snackbarContext,
  ]);

  const dueDateEnabled = Boolean(dueAt || dueAfterSeconds);
  const remindAtEnabled = Boolean(relativeRemindAt);
  const alertAtEnabled = Boolean(relativeAlertAt);

  return (
    <View style={style}>
      <ListItem
        text="Due Date"
        icon="clock"
        disabled={readOnly}
        actionComponent={
          <Switch
            switchOn={dueDateEnabled}
            onSwitch={() => toggleDue()}
            locked={readOnly}
          />
        }
      />
      {dueDateEnabled && (
        <>
          {!disableRelativeDueDate && !readOnly ? (
            <StyledInlineDropdown
              selectedOption={dueDateDefinitionType}
              options={dueDateDefinitionOptions}
              optionNoun={""}
              optionPluralNoun={""}
              loadingOptions={false}
              onSelect={onUpdateDueDateDefinitionType}
              readOnly={readOnly}
            />
          ) : null}
          {dueDateDefinitionType === "SPECIFIC" && (
            <StyledDateTimePicker
              onDone={onUpdateDueDate}
              title={"Select The Due Date"}
              time={true}
              disableReset={true}
              value={dueAt!}
              readOnly={readOnly}
            />
          )}
          {dueDateDefinitionType === "RELATIVE" && (
            <StyledRelativeTimePicker
              time={convertToTimeAmount(dueAfterSeconds!)}
              onChange={onUpdateDueAfterSeconds}
              iconName="hourglass"
              contextText="after started"
              readOnly={readOnly}
            />
          )}
          <StyledListItem
            text={"Remind Assignees"}
            icon="group"
            disabled={readOnly}
            actionComponent={
              <Switch
                switchOn={remindAtEnabled}
                onSwitch={toggleRemindAt}
                locked={readOnly}
              />
            }
          />
          {remindAtEnabled && (
            <StyledRelativeTimePicker
              time={relativeRemindAt!}
              onChange={onUpdateRelativeRemindAt}
              iconName="notification"
              readOnly={readOnly}
            />
          )}
          <StyledListItem
            text={"Alert Quest Owner & Workspace"}
            icon={"person"}
            disabled={readOnly}
            actionComponent={
              <Switch
                switchOn={alertAtEnabled}
                onSwitch={toggleAlertAt}
                locked={readOnly}
              />
            }
          />
          {alertAtEnabled && (
            <StyledRelativeTimePicker
              time={relativeAlertAt!}
              onChange={onUpdateRelativeAlertAt}
              iconName="notification-important"
              readOnly={readOnly}
            />
          )}
          <StyledListItem
            text={"Allow Submissions After Due Date"}
            icon={"locked-clock"}
            disabled={readOnly}
            actionComponent={
              <Switch
                switchOn={allowOverdueSubmissions}
                onSwitch={toggleAllowOverdueSubmissions}
                locked={readOnly}
              />
            }
          />
        </>
      )}
    </View>
  );
};

const StyledDateTimePicker = styled(DateTimePicker)`
  margin-bottom: 10px;
`;

const StyledListItem = styled(ListItem)`
  margin-bottom: 6px;
`;

const StyledRelativeTimePicker = styled(RelativeTimePicker)`
  margin-bottom: 6px;
`;

const StyledInlineDropdown = styled(InlineDropdown)`
  margin-top: 10px;
`;

const dueDateDefinitionOptions = [
  {
    label: "Relative Time",
    icon: "timer",
    value: "RELATIVE",
  },
  {
    label: "Specific Time",
    icon: "date",
    value: "SPECIFIC",
  },
] as const;

export { DueDate as default };
