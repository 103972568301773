// tslint:disable: object-literal-sort-keys

import Constants from "expo-constants";
import { Platform } from "react-native";

export interface IEnvironment {
  appBaseUrl: string;
  apiBaseUrl: string;
  auth0ClientId: string;
  auth0Scope: string;
  auth0BaseUrl: string;
  auth0Audience: string;
  segmentWebWriteKey: string;
  segmentNativeWriteKey: string;
  segmentDisableAnalytics: boolean;
  cloudinaryApiBaseUrl: string;
  cloudinaryResourceBaseUrl: string;
  slackUserListQueryParams: string; // remove when Slack groups:read scope is approved in prod
  sentryEnvironment: string;
  googleMapsApiKey: string;
  throwGuardrailErrors: boolean;
  featureFlags: {
    // TODO: FINISH_TEAMS_FEATURE - Remove if no longer needed.
    enableTeams: boolean;
  };
  logLevels: {
    navigation: "off" | "debug";
    redux: {
      selectorPerformance: "off" | "debug";
      dispatchPerformance: "off" | "debug";
    };
    trackPlayer: "off" | "debug";
    sentry: {
      breadcrumbs: "off" | "debug";
      events: "basic" | "debug";
      sdk: "off" | "debug";
    };
  };
}

const environmentVariables = Constants.expoConfig?.extra ?? {};
let devOverride_apiBaseUrl =
  Constants.expoConfig?.extra?.EXTERNAL_DEV_API_BASE_URL;
try {
  if (Platform.OS === "web" && !!devOverride_apiBaseUrl) {
    const hostname = window?.location?.hostname ?? "";

    const hostnameOverride = new URL(devOverride_apiBaseUrl)?.hostname;
    if (
      hostname === "localhost" &&
      (hostnameOverride?.includes("ngrok.io") ||
        hostnameOverride?.includes("dev.questmate.com"))
    ) {
      // This makes it possible to run the web client on http://localhost:19006 or https://...ngrok.io and still work
      // without CORS issues. This enables iOS/Android development builds to work with the local API server without having to
      // change the apiBaseUrl when switching from web to native. Also, worth noting that we cannot use the ngrok URL
      // on the frontend when linking apps because most apps require a static redirect URL which has already been set
      // to localhost:19006, and we cannot tell expo to open the web client on a different URL, so localhost:19006 is
      // here to stay.
      devOverride_apiBaseUrl = undefined;
    }
  }
} catch (e) {
  // ignore, don't want to accidentally break any other environments
  console.error("Failed to set devOverride_apiBaseUrl", e);
}

export const ENV: IEnvironment = Object.freeze({
  appBaseUrl: environmentVariables.EXPO_PUBLIC_APP_BASE_URL as string,
  apiBaseUrl:
    devOverride_apiBaseUrl ||
    (environmentVariables.EXPO_PUBLIC_API_BASE_URL as string),
  auth0ClientId: environmentVariables.EXPO_PUBLIC_AUTH_0_CLIENT_ID as string,
  auth0Scope: environmentVariables.EXPO_PUBLIC_AUTH_0_SCOPE as string,
  auth0BaseUrl: environmentVariables.EXPO_PUBLIC_AUTH_0_BASE_URL as string,
  auth0Audience: environmentVariables.EXPO_PUBLIC_AUTH_0_AUDIENCE as string,
  segmentWebWriteKey:
    environmentVariables.EXPO_PUBLIC_SEGMENT_WEB_WRITE_KEY as string,
  segmentNativeWriteKey:
    environmentVariables.EXPO_PUBLIC_SEGMENT_NATIVE_WRITE_KEY as string,
  segmentDisableAnalytics:
    "true" ===
    environmentVariables.EXPO_PUBLIC_SEGMENT_DISABLE_ANALYTICS?.trim()?.toLowerCase(),
  cloudinaryApiBaseUrl:
    environmentVariables.EXPO_PUBLIC_CLOUDINARY_API_BASE_URL as string,
  cloudinaryResourceBaseUrl:
    environmentVariables.EXPO_PUBLIC_CLOUDINARY_RESOURCE_BASE_URL as string,
  slackUserListQueryParams:
    environmentVariables.EXPO_PUBLIC_SLACK_USER_LIST_QUERY_PARAMS as string,
  sentryEnvironment:
    environmentVariables.EXPO_PUBLIC_SENTRY_ENVIRONMENT as string,
  googleMapsApiKey:
    environmentVariables.EXPO_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  throwGuardrailErrors:
    "true" ===
    environmentVariables.EXPO_PUBLIC_THROW_GUARDRAIL_ERRORS?.trim()?.toLowerCase(),
  featureFlags: {
    enableTeams:
      "true" ===
      environmentVariables.EXPO_PUBLIC_FEATURE_FLAG_ENABLE_TEAMS?.trim()?.toLowerCase(),
  },
  logLevels: {
    navigation: environmentVariables.EXPO_PUBLIC_LOG_LEVEL_NAVIGATION as
      | "off"
      | "debug",
    trackPlayer: environmentVariables.EXPO_PUBLIC_LOG_LEVEL_TRACK_PLAYER as
      | "off"
      | "debug",
    redux: {
      selectorPerformance:
        environmentVariables.EXPO_PUBLIC_LOG_LEVEL_REDUX_SELECTOR_PERFORMANCE as
          | "off"
          | "debug",
      dispatchPerformance:
        environmentVariables.EXPO_PUBLIC_LOG_LEVEL_REDUX_DISPATCH_PERFORMANCE as
          | "off"
          | "debug",
    },
    sentry: {
      breadcrumbs:
        environmentVariables.EXPO_PUBLIC_LOG_LEVEL_SENTRY_BREADCRUMBS as
          | "off"
          | "debug",
      events: environmentVariables.EXPO_PUBLIC_LOG_LEVEL_SENTRY_EVENTS as
        | "basic"
        | "debug",
      sdk: environmentVariables.EXPO_PUBLIC_LOG_LEVEL_SENTRY_SDK as
        | "off"
        | "debug",
    },
  },
});
