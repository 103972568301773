import {
  QuestPrototypeDetail,
  QuestscriptExecutionListItem,
  UserDetail,
  UserListItem,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import {
  assignmentAdded,
  assignmentsLoaded,
} from "@app/store/cache/assignments";
import { AppState } from "@app/store";
import { ArrayElement } from "@questmate/common";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { selectLoggedInUserId, userLogin, userLogout } from "@app/store/auth";
import _ from "lodash";
import {
  questscriptExecutionLoaded,
  questscriptExecutionsLoaded,
} from "@app/store/cache/questscriptExecutions";
import { workspaceLoaded } from "@app/store/cache/workspaces";
import { teamLoaded } from "@app/store/cache/teams";
import {
  questInstanceListLoaded,
  questInstanceLoaded,
} from "@app/store/cache/questInstances";
import { questStartTriggerLoaded } from "@app/store/cache/questStartTriggers";
import { isTruthy } from "@questmate/common";
import isEqual from "react-fast-compare";
import { scheduledQuestStartListLoaded } from "@app/store/cache/scheduledQuestStarts";

export type UserId = string;

export interface User {
  id: UserId;
  displayName: string;
  email?: string | null;
  phone?: string | null;
  avatarSmallUrl?: string | null;
  avatarLargeUrl?: string | null;
  isAnonymous?: boolean;
}

const userAdapter = createEntityAdapter<User>();
export const {
  selectById: selectUserById,
  selectAll: selectAllUsers,
  selectEntities: selectAllUsersById,
} = userAdapter.getSelectors<AppState>((state) => state.cache.users);

/**
 * **CAUTION**
 * This is not populated until data is loaded. If you just want to know if the user is logged in or
 * only need their ID, you should use the `selectLoggedInUserId` or `selectUserIsLoggedIn` selectors
 * from the auth slice which is persisted between restarts.
 */
export const selectLoggedInUser = createSelector(
  [(state) => selectLoggedInUserId(state), selectAllUsersById],
  (loggedInUserId, usersById) =>
    loggedInUserId ? usersById[loggedInUserId] : undefined
);

export const selectUsersWithIds = createSelector(
  [selectAllUsersById, (_state, userIds) => userIds],
  (usersById, userIds) =>
    Object.fromEntries(
      Object.entries(usersById).filter(([id, _user]) => userIds.includes(id))
    ),
  { memoizeOptions: { resultEqualityCheck: isEqual } }
);

const slice = createSlice({
  name: "cache/users",
  initialState: userAdapter.getInitialState(),
  reducers: {
    usersLoaded: (state, action: PayloadAction<APIUser[]>) =>
      userAdapter.upsertMany(state, action.payload.map(mapUser)),
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogout, (state) => userAdapter.removeAll(state))
      .addCase(userLogin, (state, action) =>
        userAdapter.upsertOne(state, mapUser(action.payload.user))
      )
      .addCase(questPrototypeLoaded, (state, action) => {
        userAdapter.upsertMany(
          state,
          action.payload.startTriggers
            .flatMap(({ startConfiguration }) => [
              ...startConfiguration.assignments.map(({ assignee }) => assignee),
              ...startConfiguration.reviewers.map(({ user }) => user),
            ])
            .filter((u) => !!u?.id)
            .map((u) => mapUser(u!))
        );

        if (action.payload.status === "ACTIVE") {
          userAdapter.upsertMany(
            state,
            action.payload.assignments.map(({ assignee }) => mapUser(assignee))
          );
        }

        if (action.payload.sharedInstance?.assignments) {
          userAdapter.upsertMany(
            state,
            action.payload.sharedInstance?.assignments
              .map((assignment) => assignment.assignee)
              .filter((u) => !!u?.id)
              .map((u) => mapUser(u!))
          );
        }
        userAdapter.upsertOne(state, mapUser(action.payload.owner));
        userAdapter.upsertOne(state, mapUser(action.payload.quest.owner));
      })
      .addCase(questInstanceLoaded, (state, action) => {
        userAdapter.upsertOne(state, mapUser(action.payload.startedByUser));
        if (action.payload.submittedByUser) {
          userAdapter.upsertOne(state, mapUser(action.payload.submittedByUser));
        }
        if (action.payload.prototype.owner) {
          userAdapter.upsertOne(state, mapUser(action.payload.prototype.owner));
        }
        userAdapter.upsertMany(
          state,
          action.payload.assignments.map(({ assignee }) => mapUser(assignee))
        );
      })
      .addCase(assignmentAdded, (state, action) =>
        userAdapter.upsertOne(state, mapUser(action.payload.assignee))
      )
      .addCase(assignmentsLoaded, (state, action) =>
        userAdapter.upsertMany(
          state,
          action.payload.assignments
            .flatMap((assignment) => [
              assignment?.formPrototype?.owner,
              assignment?.formPrototype?.quest?.owner,
            ])
            .filter((owner) => Boolean(owner?.id))
            .map(mapUser)
        )
      )
      .addCase(questStartTriggerLoaded, (state, action) => {
        userAdapter.upsertMany(state, [
          ...action.payload.startTrigger.startConfiguration.assignments.map(
            ({ assignee }) => mapUser(assignee)
          ),
          ...action.payload.startTrigger.startConfiguration.reviewers.map(
            ({ user }) => mapUser(user)
          ),
        ]);
      })
      .addCase(workspaceLoaded, (state, action) =>
        userAdapter.upsertMany(
          state,
          action.payload.memberships
            .map(({ user }) => user)
            .filter((user) => Boolean(user?.id))
            .map(mapUser)
        )
      )
      .addCase(teamLoaded, (state, action) =>
        userAdapter.upsertMany(
          state,
          action.payload.memberships
            .map(({ user }) => user)
            .filter((user) => Boolean(user?.id))
            .map(mapUser)
        )
      )
      .addCase(questscriptExecutionsLoaded, (state, action) =>
        userAdapter.upsertMany(
          state,
          action.payload
            .map(
              (questscriptExecution) =>
                (questscriptExecution as QuestscriptExecutionListItem)
                  ?.runAsUser
            )
            .filter((user) => Boolean(user?.id))
            .map(mapUser)
        )
      )
      .addCase(questInstanceListLoaded, (state, action) =>
        userAdapter.upsertMany(
          state,
          action.payload
            .flatMap((questInstance) => [
              questInstance.startedByUser,
              questInstance.submittedByUser,
              ...questInstance.assignments.map(({ assignee }) => assignee),
            ])
            .filter(isTruthy)
            .map(mapUser)
        )
      )
      .addCase(scheduledQuestStartListLoaded, (state, action) => {
        const { scheduledQuestStarts } = action.payload;
        userAdapter.upsertMany(state, [
          ...scheduledQuestStarts
            .flatMap((start) =>
              start.startConfiguration.assignments.map(
                ({ assignee }) => assignee
              )
            )
            .filter(isTruthy)
            .map(mapUser),
          ...scheduledQuestStarts
            .flatMap((start) =>
              start.startConfiguration.reviewers.map(({ user }) => user)
            )
            .filter(isTruthy)
            .map(mapUser),
        ]);
      })
      .addCase(questscriptExecutionLoaded, (state, action) => {
        if ((action.payload as QuestscriptExecutionListItem)?.runAsUser?.id) {
          userAdapter.upsertOne(
            state,
            mapUser((action.payload as QuestscriptExecutionListItem).runAsUser)
          );
        }
      });
  },
});

const reducer = slice.reducer;
export default reducer;

export const { usersLoaded } = slice.actions;

type APIUser =
  | QuestPrototypeDetail["owner"]
  | ArrayElement<QuestPrototypeDetail["assignments"]>["assignee"]
  | UserListItem
  | UserDetail
  | QuestscriptExecutionListItem["runAsUser"];

const mapUser = createDataMapper<APIUser, User>()(
  ["id", "displayName", "avatarSmallUrl", "avatarLargeUrl"],
  {
    is_anonymous: (isAnonymous) => ({ isAnonymous }),
    email_address: (email) => ({ email }),
    mobile_number: (phone) => ({ phone }),
  }
);

type Fields<
  T,
  K extends keyof Exclude<T, null | undefined>
> = T extends undefined
  ? undefined
  : T extends null
  ? null
  : Pick<Exclude<T, null | undefined>, K>;
export const selectFields = <T, K extends keyof Exclude<T, null | undefined>>(
  obj: T,
  fields: K[]
): Fields<T, K> => {
  if (obj === undefined || obj === null) {
    return obj as Fields<T, K>;
  }

  return fields.reduce((acc, field) => {
    Object.assign(acc, { [field]: _.get(obj, field) });
    return acc;
  }, {}) as Fields<T, K>;
};
