import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import { Boundary } from "@app/components/screen/boundary";
import { Platform } from "react-native";
import * as Updates from "expo-updates";
import { useAppSelector } from "@app/store";
import { createLink } from "@app/util/link.utils";
import ListItem from "@app/components/questkit/listItem";

export const SettingsScreen: React.FC = () => {
  const updateIsAvailable = useAppSelector(
    (state) => state.main.updateAvailable
  );

  return (
    <SettingsScreenWrapper>
      <SafeAreaView edges={["bottom"]}>
        <Boundary>
          <ListItem
            text="Account"
            icon="person"
            actionIcon="chevron-right"
            onPress={createLink({ screen: "Account" })}
          />
          <ListItem
            text="Apps"
            icon="cloud"
            actionIcon="chevron-right"
            onPress={createLink({ screen: "Apps" })}
          />
          {Platform.OS === "web" ? (
            /** WARNING: Do not remove this condition check, as this will result in our app from being
             *  permanently banned from the Apple App Store and Google Play Store
             */
            <ListItem
              text="Subscription & Billing"
              icon="money"
              actionIcon="chevron-right"
              onPress={createLink({ screen: "Billing" })}
            />
          ) : null}
          <ListItem
            text="Support & Feedback"
            icon="contact-support"
            actionIcon="chevron-right"
            onPress={createLink({ screen: "Support" })}
          />
          {updateIsAvailable && (
            <ListItem
              text="Install update"
              icon="download"
              actionIcon="chevron-right"
              onPress={async () => {
                await Updates.reloadAsync();
              }}
            />
          )}
        </Boundary>
      </SafeAreaView>
    </SettingsScreenWrapper>
  );
};

const SettingsScreenWrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: "center",
  },
})`
  background-color: ${({ theme }) => theme.background};
`;
