import { uuid } from "@app/util/uuid";
import * as CustomItem from "@questmate/questscript";
import produce, { Draft } from "immer";

export class EventScheduler {
  private _events: Array<Event> = [];

  get events(): ReadonlyArray<Event> {
    return produce(this._events, () => undefined);
  }

  enqueue(...events: Event[]): void {
    this.updateEvents([...this._events, ...events]);
  }

  nextBatch(): Event[] {
    const queuedEvents = this._events.filter(
      (event) => event.status === "QUEUED"
    );

    const highPriorityEvents = queuedEvents.filter(
      ({ priority }) => priority === 1
    );
    if (highPriorityEvents.length > 0) {
      return highPriorityEvents;
    }
    return queuedEvents;
  }

  modifyUnsentEvent<T extends Event>(
    eventId: string,
    updateFn: (eventDraft: Draft<T>) => void
  ): void {
    this.updateEvents(
      produce(this._events, (draft) => {
        const event = draft.find((e) => e.id === eventId);
        if (event) {
          updateFn(event as Draft<T>);
        }
      })
    );
  }

  setEventsStatus(statusFn: (event: Event) => EventStatus): void;
  setEventsStatus(events: Event[], status: EventStatus): void;
  setEventsStatus(
    eventsOrStatusFn: Event[] | ((event: Event) => EventStatus),
    status?: EventStatus
  ): void {
    if (Array.isArray(eventsOrStatusFn) && status) {
      this.updateEvents(
        produce(this._events, (draft) => {
          eventsOrStatusFn.forEach((event) => {
            const match = draft.find((e) => e.id === event.id);
            if (match) {
              match.status = status;
            }
          });
        })
      );
    } else if (typeof eventsOrStatusFn === "function") {
      this.updateEvents(
        produce(this._events, (draft) => {
          draft.forEach((event) => {
            event.status = eventsOrStatusFn(event);
          });
        })
      );
    }
  }

  private updateEvents(events: Event[]) {
    this._events = events.filter(({ status }) => status !== "SUCCESS");
    this.listeners.forEach((listener) => listener());
  }

  private listeners: Array<() => void> = [];

  subscribe(listener: () => void): void {
    this.listeners.push(listener);
  }

  unsubscribe(listener: () => void): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }
}

/**
 * Event priorities
 * 1) User interactions (buttons, etc.)
 * 2) First time loading a page of data, have to show loading state while waiting
 * 3) loading more pages of data, refreshing data. (UNUSED at this time)
 */
type EventPriority = 1 | 2 | 3;

type EventStatus = "QUEUED" | "PENDING" | "SUCCESS" | "ERROR" | "SKIPPED";
const INFLIGHT_STATUSES: EventStatus[] = ["QUEUED", "PENDING"];

export abstract class Event {
  createdAt = new Date();
  readonly id: string;
  status: EventStatus;
  protected constructor(readonly priority: EventPriority) {
    this.id = uuid();
    this.status = "QUEUED";
  }

  abstract asRawEvent(): CustomItem.CustomItemEvent;

  abstract get type(): CustomItem.CustomItemEvent["type"];

  isInFlight(): boolean {
    return INFLIGHT_STATUSES.includes(this.status);
  }
}

export class DataRequestedEvent extends Event {
  static TYPE = "DATA_REQUESTED" as const;
  constructor(readonly dataSourceId: string, readonly restartPaging: boolean) {
    super(2);
  }

  get type(): typeof DataRequestedEvent.TYPE {
    return DataRequestedEvent.TYPE;
  }

  asRawEvent(): CustomItem.DataRequestedEvent {
    return {
      id: this.id,
      type: DataRequestedEvent.TYPE,
      data: {
        dataSourceId: this.dataSourceId,
        ...(this.restartPaging ? { restartPaging: true } : {}),
      },
    };
  }
}

export class HandlerFiredEvent<T> extends Event {
  static TYPE = "HANDLER_FIRED" as const;
  constructor(readonly handlerId: string, public payload: T) {
    super(1);
  }

  asRawEvent(): CustomItem.HandlerFiredEvent {
    return {
      id: this.id,
      type: HandlerFiredEvent.TYPE,
      data: {
        handlerId: this.handlerId,
        payload: this.payload,
      },
    };
  }

  get type(): typeof HandlerFiredEvent.TYPE {
    return HandlerFiredEvent.TYPE;
  }
}
