import { useAppNavigation } from "@app/navigation/QMNavigator";
import { useState } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";

export function useScreenHasBeenFocusedAtLeastOnce() {
  const navigation = useAppNavigation();
  const [hasBeenFocused, setHasBeenFocused] = useState(navigation.isFocused());
  useEffectOnce(() => {
    if (!hasBeenFocused) {
      const focusListener = () => {
        if (navigation.isFocused()) {
          setHasBeenFocused(true);
          navigation.removeListener("focus", focusListener);
        }
      };
      navigation.addListener("focus", focusListener);
      return () => navigation.removeListener("focus", focusListener);
    }
  });
  return hasBeenFocused;
}
