import { QuestPrototypeDetail, Quests } from "@questmate/openapi-spec";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import {
  assignmentAdded,
  assignmentRemoved,
} from "@app/store/cache/assignments";
import { reviewerRemoved, reviewersAdded } from "@app/store/cache/reviewers";
import { questStartTriggerLoaded } from "@app/store/cache/questStartTriggers";
import { scheduledQuestStartListLoaded } from "@app/store/cache/scheduledQuestStarts";

type QuestStartConfiguration = Omit<
  UnionToIntersection<APIQuestStartConfiguration>,
  "assignments" | "reviewers"
> & {
  assignmentIds: string[];
  reviewerIds?: string[];
};

const questStartConfigurationAdapter =
  createEntityAdapter<QuestStartConfiguration>({});

export const {
  selectById: selectQuestStartConfigurationById,
  selectEntities: selectAllQuestStartConfigurationsById,
} = questStartConfigurationAdapter.getSelectors<AppState>(
  (state) => state.cache.questStartConfigurations
);

const slice = createSlice({
  name: "cache/questStartConfigurations",
  initialState: questStartConfigurationAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) => {
        const questPrototypeId = action.payload.id;
        return questStartConfigurationAdapter.upsertMany(
          state,
          action.payload.startTriggers.map((trigger) =>
            mapQuestStartConfiguration({
              ...trigger.startConfiguration,
              questPrototypeId,
              startTriggerId: trigger.id,
            })
          )
        );
      })
      .addCase(questStartTriggerLoaded, (state, action) => {
        const { startTrigger, questPrototypeId } = action.payload;
        return questStartConfigurationAdapter.upsertOne(
          state,
          mapQuestStartConfiguration({
            ...startTrigger.startConfiguration,
            questPrototypeId,
            startTriggerId: startTrigger.id,
          })
        );
      })
      .addCase(scheduledQuestStartListLoaded, (state, action) => {
        const { scheduledQuestStarts } = action.payload;
        return questStartConfigurationAdapter.upsertMany(
          state,
          scheduledQuestStarts.map((start) =>
            mapQuestStartConfiguration(start.startConfiguration)
          )
        );
      })
      .addCase(assignmentAdded, (state, action) => {
        const startConfigId = action.payload.startConfiguration?.id;
        if (startConfigId) {
          questStartConfigurationAdapter.updateOne(state, {
            id: startConfigId,
            changes: {
              assignmentIds: [
                ...(state.entities[startConfigId]?.assignmentIds || []),
                action.payload.id,
              ],
            },
          });
        }
      })
      .addCase(assignmentRemoved, (state, action) => {
        const startConfigId = action.payload.startConfiguration?.id;
        if (startConfigId) {
          questStartConfigurationAdapter.updateOne(state, {
            id: startConfigId,
            changes: {
              assignmentIds: (
                state.entities[startConfigId]?.assignmentIds || []
              ).filter((assignmentId) => action.payload.id !== assignmentId),
            },
          });
        }
      })
      .addCase(reviewersAdded, (state, action) => {
        action.payload.reviewers.forEach((reviewer) => {
          questStartConfigurationAdapter.updateOne(state, {
            id: reviewer.startConfiguration.id,
            changes: {
              reviewerIds: [
                ...(state.entities[reviewer.startConfiguration.id]
                  ?.reviewerIds || []),
                reviewer.id,
              ],
            },
          });
        });
      })
      .addCase(reviewerRemoved, (state, action) =>
        questStartConfigurationAdapter.updateOne(state, {
          id: action.payload.startConfiguration.id,
          changes: {
            reviewerIds: (
              state.entities[action.payload.startConfiguration.id]
                ?.reviewerIds || []
            ).filter((reviewerId) => action.payload.id !== reviewerId),
          },
        })
      )
      .addCase(userLogout, (state) =>
        questStartConfigurationAdapter.removeAll(state)
      );
  },
});

const reducer = slice.reducer;
export default reducer;

// export const {} = slice.actions;

type APIQuestStartConfiguration =
  | (QuestPrototypeDetail["startTriggers"][number]["startConfiguration"] & {
      startTriggerId: string;
      questPrototypeId: string;
    })
  | Quests.ScheduledStartsDetail.ResponseBody[number]["startConfiguration"];

const mapQuestStartConfiguration = createDataMapper<
  APIQuestStartConfiguration,
  QuestStartConfiguration
>()(
  [
    "id",
    "dueAt",
    "dueAfterSeconds",
    "allowOverdueSubmissions",
    "remindBeforeSeconds",
    "alertBeforeSeconds",
    "slideMode",
    "startTriggerId",
    "questPrototypeId",
  ],
  {
    assignments: (assignments) => ({
      assignmentIds: assignments.map(({ id }) => id!),
    }),
    reviewers: (reviewers) => ({
      reviewerIds: reviewers.map(({ id }) => id!),
    }),
  }
);
