import useSWRInfinite from "swr/infinite";
import { createQuestmateApiClient } from "@app/util/client";
import { useEffect, useMemo } from "react";
import { Method } from "axios";
import { ENV } from "@app/config/env";

const getKey = (
  pageIndex: number,
  previousPageData: GetSlackChannelsResponse
) => {
  // reached the end
  if (previousPageData && !previousPageData.response_metadata?.next_cursor) {
    return null;
  }

  // first page, we don't have `previousPageData`
  if (pageIndex === 0) {
    return [
      "get",
      `/apps/slack/api/conversations.list${ENV.slackUserListQueryParams}`,
    ];
  }

  // request the next page using the cursor of the previous response
  return [
    "get",
    `/apps/slack/api/conversations.list${ENV.slackUserListQueryParams}&cursor=${previousPageData.response_metadata?.next_cursor}`,
  ];
};

interface UseSlackChannelsResult {
  channels: GetSlackChannelsResponse["channels"];
  isValidating: boolean;
  revalidateChannels: () => void;
}

const slackApiRequest = async (
  params: [method: Method, url: string]
): Promise<GetSlackChannelsResponse> => {
  const [method, url] = params;
  const response = await createQuestmateApiClient()<
    SlackResponse<GetSlackChannelsResponse>
  >(url, {
    method,
  });

  if (response.data.ok) {
    return response.data;
  } else if (response.data.error === "ratelimited") {
    const retryAfter = response.data.retryAfter || 5;
    return new Promise((r) =>
      setTimeout(() => r(slackApiRequest([method, url])), retryAfter * 1000)
    );
  } else {
    throw response;
  }
};

export function useSlackChannels(appLinked = true): UseSlackChannelsResult {
  const {
    data,
    isValidating,
    mutate: revalidateChannels,
    size,
    setSize,
  } = useSWRInfinite<GetSlackChannelsResponse>(
    appLinked ? getKey : () => null,
    slackApiRequest,
    {
      shouldRetryOnError: true,
      loadingTimeout: 120 * 1000, // accommodate retrying when rate-limited
    }
  );
  useEffect(() => {
    // immediately provide channels from the first request,
    // but then continue to fetch more until there is nothing more to fetch
    if (data && data.length <= size) {
      const hasMore = data[data.length - 1].response_metadata?.next_cursor;
      if (hasMore) {
        void setSize(data.length + 1);
      }
    }
  }, [data, size, setSize]);

  const channels = useMemo(
    () =>
      data?.reduce(
        (acc, page) => [
          ...acc,
          ...page.channels.filter((channel) => !channel.is_archived),
        ],
        [] as GetSlackChannelsResponse["channels"]
      ) || [],
    [data]
  );

  return { channels, isValidating, revalidateChannels };
}

type SlackResponse<T extends { ok: true }> =
  | { ok: false; error: string; retryAfter?: number }
  | T;

interface GetSlackChannelsResponse {
  ok: true;
  channels: {
    id: string;
    name: string;
    name_normalized: string;
    is_channel: boolean;
    is_group: boolean;
    is_im: boolean;
    is_mpim: boolean;
    is_private: boolean;
    created: number;
    is_archived: boolean;
    is_general: boolean;
    unlinked: number;
    is_shared: boolean;
    is_org_shared: boolean;
    is_pending_ext_shared: boolean;
    pending_shared: [];
    parent_conversation: null;
    creator: string;
    is_ext_shared: boolean;
    shared_team_ids: string[];
    pending_connected_team_ids: string[];
    is_member: boolean;
    topic: string;
    purpose: string;
    previous_names: string[];
    num_members: number;
  }[];
  response_metadata: {
    next_cursor: string;
  };
}
export interface GetSlackChannelInfoResponse {
  ok: boolean;
  channel: {
    id: string;
    name: string;
    name_normalized: string;
    is_channel: boolean;
    is_group: boolean;
    is_im: boolean;
    is_mpim: boolean;
    is_private: boolean;
    created: number;
    is_archived: boolean;
    is_general: boolean;
    unlinked: number;
    is_shared: boolean;
    is_org_shared: boolean;
    is_pending_ext_shared: boolean;
    pending_shared: [];
    parent_conversation: null;
    creator: string;
    is_ext_shared: boolean;
    shared_team_ids: string[];
    pending_connected_team_ids: string[];
    is_member: boolean;
    topic: string;
    purpose: string;
    previous_names: string[];
    num_members: number;
  };
}
