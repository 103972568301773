import { store } from "../store";
import { logOut } from "@app/screens/LogoutScreen";

export const getLoggedInUserId = (autoLogout = false): string | undefined => {
  const loggedInUserId = store.getState().auth.loggedInUserId;
  if (!loggedInUserId) {
    if (autoLogout) {
      logOut({
        reason: "Tried to getLoggedInUserId but no user was logged in.",
      });
    }
    return;
  } else {
    return loggedInUserId;
  }
};
