import {
  AssignmentListItem,
  AssignmentOnQuest,
  QuestInstanceListItem,
  QuestPrototypeDetail,
  QuestStartTriggerDetail,
} from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  questPrototypeLoaded,
  selectQuestPrototypeById,
} from "@app/store/cache/questPrototypes";
import {
  selectAllUsers,
  selectAllUsersById,
  User,
  UserId,
} from "@app/store/cache/users";
import { AppState } from "@app/store";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { selectLoggedInUserId, userLogout } from "@app/store/auth";
import {
  questInstanceListLoaded,
  questInstanceLoaded,
  selectAllQuestInstancesById,
  selectQuestInstanceById,
} from "@app/store/cache/questInstances";
import { isNotUndefined, UnionToIntersection } from "@questmate/common";
import { questStartTriggerLoaded } from "@app/store/cache/questStartTriggers";
import { scheduledQuestStartListLoaded } from "@app/store/cache/scheduledQuestStarts";
import isEqual from "react-fast-compare";

type Assignment = Omit<
  UnionToIntersection<APIAssignment>,
  "assignee" | "formPrototype" | "formInstance" | "startConfiguration"
> & {
  assigneeId: UserId;
  formInstanceId?: string | null;
  formPrototypeId?: string | null;
  startConfigurationId?: string | null; // is nullable?
};

const assignmentsAdapter = createEntityAdapter<Assignment>();

export const {
  selectById: selectAssignmentById,
  selectAll: selectAllAssignments,
  selectEntities: selectAllAssignmentsById,
} = assignmentsAdapter.getSelectors<AppState>(
  (state) => state.cache.assignments
);

export const selectUserAssignments = createSelector(
  [selectAllAssignments, (state) => selectLoggedInUserId(state)],
  (assignments, userId) => {
    return assignments.filter((assignment) => assignment.assigneeId === userId);
  }
);
export const selectOpenAndInReviewUserAssignments = createSelector(
  [selectUserAssignments, (state) => selectAllQuestInstancesById(state)],
  (assignments, questInstances) =>
    assignments.filter(
      (assignment) =>
        assignment.formInstanceId &&
        (questInstances[assignment.formInstanceId]?.status === "OPEN" ||
          questInstances[assignment.formInstanceId]?.status === "IN_REVIEW")
    )
);
export const selectAssignmentsWithAssigneeByQuestPrototypeId = createSelector(
  [
    (state, questPrototypeId) =>
      selectQuestPrototypeById(state, questPrototypeId),
    selectAllAssignments,
    (state) => selectAllUsers(state),
  ],
  (questPrototype, assignments, users) => {
    return questPrototype?.assignmentIds
      ?.map((id) => {
        const assignment = assignments.find((a) => a.id === id);
        if (!assignment) {
          return undefined;
        }
        return {
          ...assignment,
          assignee: users.find((u) => u.id === assignment.assigneeId),
        };
      })
      .filter(
        (u): u is Assignment & { assignee: User | undefined } => u !== undefined
      );
  }
);
export const selectAssignmentsWithAssigneeByQuestInstanceId = createSelector(
  [
    (state, questInstanceId) => selectQuestInstanceById(state, questInstanceId),
    selectAllAssignmentsById,
    (state) => selectAllUsersById(state),
  ],
  (questPrototype, assignmentsById, usersById) => {
    return (questPrototype?.assignmentIds ?? [])
      .map((id) => {
        const assignment = assignmentsById[id];
        if (!assignment) {
          return;
        }
        return {
          ...assignment,
          assignee: usersById[assignment.assigneeId],
        };
      })
      .filter(isNotUndefined) as (Assignment & {
      assignee: User | undefined;
    })[];
  },
  { memoizeOptions: { resultEqualityCheck: isEqual } }
);
export const selectAssignmentsForStartConfigurationById = createSelector(
  [
    (_state, startConfigurationId) => startConfigurationId,
    selectAllAssignments,
  ],
  (startConfigurationId, assignments) => {
    return assignments
      .filter((a) => a.startConfigurationId === startConfigurationId)
      .reduce((acc, assignment) => {
        acc[assignment.id] = assignment;
        return acc;
      }, {} as Record<string, typeof assignments[number]>);
  },
  { memoizeOptions: { resultEqualityCheck: isEqual } }
);

const slice = createSlice({
  name: "cache/assignments",
  initialState: assignmentsAdapter.getInitialState(),
  reducers: {
    assignmentsLoaded: (
      state,
      action: PayloadAction<{
        assignments: AssignmentListItem[];
        assigneeId: string;
      }>
    ) =>
      assignmentsAdapter.upsertMany(
        state,
        action.payload.assignments.map((assignment) =>
          mapAssignment({
            ...assignment,
            assignee: {
              id: action.payload.assigneeId,
            } as AssignmentOnQuest["assignee"],
          })
        )
      ),
    assignmentAdded: (state, action: PayloadAction<AssignmentOnQuest>) =>
      assignmentsAdapter.addOne(state, mapAssignment(action.payload)),
    assignmentRemoved: (state, action: PayloadAction<AssignmentOnQuest>) =>
      assignmentsAdapter.removeOne(state, action.payload.id),
  },
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) => {
        if (action.payload.status === "ACTIVE") {
          assignmentsAdapter.upsertMany(
            state,
            action.payload.assignments.map((assignment) =>
              mapAssignment({
                ...assignment,
                formPrototype: { id: action.payload.id },
              })
            )
          );
        }
        assignmentsAdapter.upsertMany(
          state,
          action.payload.startTriggers.flatMap(({ startConfiguration }) =>
            startConfiguration.assignments.map((assignment) =>
              mapAssignment({
                ...assignment,
                startConfiguration,
              })
            )
          )
        );
        if (action.payload.sharedInstance?.assignments)
          assignmentsAdapter.upsertMany(
            state,
            action.payload.sharedInstance.assignments.flatMap((assignment) =>
              mapAssignment({
                ...assignment,
                formInstance: { id: action.payload.sharedInstance!.id },
              })
            )
          );
      })
      .addCase(questStartTriggerLoaded, (state, action) => {
        const startConfiguration =
          action.payload.startTrigger.startConfiguration;
        assignmentsAdapter.upsertMany(
          state,
          startConfiguration.assignments.map((assignment) =>
            mapAssignment({
              ...assignment,
              startConfiguration,
            })
          )
        );
      })
      .addCase(scheduledQuestStartListLoaded, (state, action) => {
        const { scheduledQuestStarts } = action.payload;
        assignmentsAdapter.upsertMany(
          state,
          scheduledQuestStarts.flatMap((start) =>
            start.startConfiguration.assignments.map((assignment) =>
              mapAssignment({
                ...assignment,
                startConfiguration: start.startConfiguration,
              })
            )
          )
        );
      })
      .addCase(questInstanceLoaded, (state, action) => {
        assignmentsAdapter.upsertMany(
          state,
          action.payload.assignments.map((assignment) => {
            return mapAssignment({
              ...assignment,
              formInstance: action.payload,
            });
          })
        );
      })
      .addCase(questInstanceListLoaded, (state, action) => {
        assignmentsAdapter.upsertMany(
          state,
          action.payload.flatMap((questInstance) => {
            return questInstance.assignments.map((assignment) =>
              mapAssignment({
                ...assignment,
                formInstance: { id: questInstance.id },
                startConfiguration: questInstance.startConfiguration,
              })
            );
          })
        );
      })
      .addCase(userLogout, (state) => assignmentsAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

export const { assignmentAdded, assignmentRemoved, assignmentsLoaded } =
  slice.actions;

type APIAssignment =
  | (QuestPrototypeDetail["assignments"][number] & {
      formPrototype: { id: string };
    })
  | (QuestPrototypeDetail["startTriggers"][number]["startConfiguration"]["assignments"][number] & {
      startConfiguration: { id: string };
    })
  | (QuestStartTriggerDetail["startConfiguration"]["assignments"][number] & {
      startConfiguration: { id: string };
    })
  | QuestInstanceListItem["assignments"][number]
  | AssignmentOnQuest
  | AssignmentListItem;

const mapAssignment = createDataMapper<APIAssignment, Assignment>()(["id"], {
  assignee: ({ id }) => ({ assigneeId: id }),
  formInstance: (formInstance) => ({
    formInstanceId: formInstance ? formInstance.id! : null,
  }),
  formPrototype: (formPrototype) => ({
    formPrototypeId: formPrototype ? formPrototype.id! : null,
  }),
  startConfiguration: (startConfiguration) => ({
    startConfigurationId: startConfiguration ? startConfiguration.id! : null,
  }),
});
