import React, { PropsWithChildren, useRef, useMemo } from "react";
import { uuid } from "@app/util/uuid";

type PromiseKeeper = {
  getPromise: <T>(promiseId: string) => Promise<T> | undefined;
  addPromise: (promise: Promise<unknown>) => string;
};

const PromiseKeeperContext = React.createContext<PromiseKeeper>({
  getPromise: () => undefined,
  addPromise: () => "",
});

export const PromiseKeeperProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const promiseMap = useRef<Record<string, Promise<unknown>>>({}).current;

  const promiseKeeper = useMemo(() => {
    return {
      getPromise<T>(promiseId: string) {
        return promiseMap[promiseId] as Promise<T> | undefined;
      },
      addPromise<T>(promise: Promise<T>) {
        const newPromiseId = uuid();
        promiseMap[newPromiseId] = promise;
        return newPromiseId;
      },
    };
  }, [promiseMap]);

  return (
    <PromiseKeeperContext.Provider value={promiseKeeper}>
      {children}
    </PromiseKeeperContext.Provider>
  );
};

export const usePromiseKeeper = (): PromiseKeeper => {
  if (React.useContext(PromiseKeeperContext) === undefined) {
    throw new Error(
      "usePromiseKeeper must be used within a PromiseKeeperProvider"
    );
  }
  return React.useContext(PromiseKeeperContext);
};
