export class PromiseTracker {
  private trackedPromises: Promise<void>[] = [];

  public allTrackedPromisesAreComplete: () => Promise<void> = async () => {
    while (this.trackedPromises.length > 0) {
      const currentPromises = [...this.trackedPromises];
      await Promise.all(currentPromises);
    }
  };

  public trackPromise: (maybePromise: Promise<unknown> | unknown) => void = (
    maybePromise
  ) => {
    const selfRemovingPromise = Promise.resolve(maybePromise)
      .then(() => undefined)
      .catch(() => undefined)
      .finally(() => {
        const index = this.trackedPromises.indexOf(selfRemovingPromise);
        if (index > -1) {
          void this.trackedPromises.splice(index, 1);
        }
      });
    this.trackedPromises.push(selfRemovingPromise);
  };
}
