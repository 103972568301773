import React, { useMemo } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { useFocusableRef } from "@app/util/focus";
import Button from "@app/components/questkit/button";
import { ButtonComponentModel } from "@app/components/item/components/custom/types";

interface CustomItemButtonComponentProps {
  component: ButtonComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemButtonComponent: React.FC<
  CustomItemButtonComponentProps
> = ({ component, readOnly }) => {
  const hasTitle = Boolean(component.title);

  const commonButtonProps = useMemo(
    () => ({
      title: component.buttonLabel || "Click Here",
      disabled: component.disabled || readOnly,
      onPress: component.onPress,
      loading: component.loading,
      success: component.success,
    }),
    [component, readOnly]
  );

  return hasTitle ? (
    <ButtonContainer>
      <TitleText size="medium">{component.title}</TitleText>
      <Button {...commonButtonProps} loadingPosition="right" />
    </ButtonContainer>
  ) : (
    <Button {...commonButtonProps} loadingPosition="center" />
  );
};

const TitleText = styled(Text)`
  flex-shrink: 1;
`;
const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
