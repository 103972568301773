import React from "react";
import TextInput, {
  QKTextInputProps,
} from "@app/components/questkit/textInput";
import { TextInput as RNTextInput } from "react-native";

const SearchBar = React.forwardRef(
  (props: QKTextInputProps, forwardRef: React.ForwardedRef<RNTextInput>) => {
    return (
      <TextInput
        ref={forwardRef}
        testID="search-bar"
        leftIcon="search"
        {...props}
      />
    );
  }
);
SearchBar.displayName = "SearchBar";

export default SearchBar;
