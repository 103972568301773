import { useCallback, useMemo, useState } from "react";

export interface HoverProps {
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return useMemo(
    () => ({
      isHovered,
      hoverProps: {
        isHovered,
        onMouseEnter,
        onMouseLeave,
      } satisfies HoverProps,
    }),
    [isHovered, onMouseEnter, onMouseLeave]
  );
}
