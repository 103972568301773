import { Text } from "@app/components/questkit/text";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { useTheme } from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import { Dimensions, View, ViewStyle } from "react-native";
import { StyledProps } from "styled-components";
import { TouchBoundary } from "@app/util/TouchBoundary";
import { Portal } from "@gorhom/portal";
import { useSideBarContext } from "@app/navigation/sideBar/SideBarProvider";
import { useEffectOnce } from "@app/util/useEffectOnce";
import Animated, {
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  SharedValue,
  useAnimatedReaction,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import QKScrollView, {
  useScrollViewController,
} from "@app/components/questkit/ScrollView";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export type DropdownOption<T = unknown> = {
  label: string;
  value: T;
  icon?: IconIdentifier;
};

type DropdownTheme = {
  primary: string;
  background: string;
  groupHeaderTextColor?: string;
};

export type OptionGroup<T = unknown> = {
  label: string;
  options: DropdownOption<T>[];
};

function isOptionGroup<T>(
  optionOrOptionGroup: OptionGroup<T> | DropdownOption<T>
): optionOrOptionGroup is OptionGroup<T> {
  return "options" in optionOrOptionGroup && !("value" in optionOrOptionGroup);
}

export type DropdownOptionListItem<T = unknown> =
  | DropdownOption<T>
  | OptionGroup<T>;

interface InlineDropdownProps<T> {
  testID?: string;
  selectedOption: T;
  options:
    | Array<DropdownOptionListItem<T>>
    | ReadonlyArray<DropdownOptionListItem<T>>;
  optionNoun: string;
  optionPluralNoun: string;
  loadingOptions: boolean;
  onSelect: (optionValue: T) => void;
  onCreate?: () => void;
  placeholderIcon?: IconIdentifier;
  themeOverride?: DropdownTheme;
  inlineStyle?: boolean;
  readOnly?: boolean;
  style?: StyledProps<ViewStyle>;
  placeholderTextOverridesMap?: Partial<PlaceholderTextProviderMap>;
}

export const InlineDropdown = <T,>({
  testID,
  selectedOption,
  options,
  loadingOptions,
  optionNoun,
  optionPluralNoun,
  onSelect,
  onCreate,
  placeholderIcon,
  themeOverride,
  style,
  readOnly = false,
  inlineStyle = false,
  placeholderTextOverridesMap,
}: InlineDropdownProps<T>): ReactElement => {
  const _theme = useTheme();
  const theme = themeOverride || _theme;

  const [isOpen, setIsOpen] = useState(false);

  const allOptions = useMemo(() => {
    return options.flatMap((optionOrOptionGroup) =>
      isOptionGroup(optionOrOptionGroup)
        ? optionOrOptionGroup.options
        : [optionOrOptionGroup]
    );
  }, [options]);

  let option: DropdownOption<T> | undefined = allOptions.find(
    ({ value }) => value === selectedOption
  );
  const optionsNotAvailable =
    loadingOptions && (!options || options.length === 0);

  if (!option) {
    const placeholderText = {
      ...defaultPlaceholderTextProviderMap,
      ...placeholderTextOverridesMap,
    }[
      optionsNotAvailable
        ? "OPTIONS_LOADING"
        : selectedOption
        ? "SELECTED_OPTION_NOT_AVAILABLE"
        : Array.isArray(options) && options.length > 0
        ? "OPTIONS_AVAILABLE"
        : "OPTIONS_NOT_AVAILABLE"
    ]({
      optionPluralNoun,
      optionNoun,
    });
    option = {
      label: placeholderText,
      value: null as T,
      icon: placeholderIcon,
    };
  }

  const onCreateNew = useCallback(() => {
    if (readOnly) {
      return;
    }
    onCreate?.();
    setIsOpen(false);
  }, [readOnly, onCreate]);
  const dropdownItems = useMemo(() => {
    const flatMap = (options || []).flatMap<DropdownItem<T>>(
      (optionOrGroup) => {
        if (isOptionGroup(optionOrGroup)) {
          return [
            {
              label: optionOrGroup.label,
              type: "GROUP_LABEL" as const,
            },
            ...optionOrGroup.options
              .map((option) => ({
                ...option,
                type: "GROUP_OPTION" as const,
              }))
              .sort((a, b) => a.label.localeCompare(b.label, undefined, {})),
          ];
        } else {
          return [{ ...optionOrGroup, type: "OPTION" as const }];
        }
      }
    );
    return (
      flatMap
        // TODO: Revisit how sorting works... is this a responsibility of the caller?
        //       What about when searching (modal dropdown only though...)
        // .filter((optionOrGroup) => isOptionGroup(optionOrGroup) || option!.value !== optionOrGroup.value)
        // .sort((a, b) => a.label.localeCompare(b.label, undefined, {}))
        .map((optionOrGroupLabel) => {
          if (optionOrGroupLabel.type === "GROUP_LABEL") {
            const { label } = optionOrGroupLabel;

            return (
              <DropdownListGroupHeader
                key={`option-group-label-${label}`}
                label={label}
                ddTheme={theme}
              />
            );
          } else {
            const { type, value, label, icon } = optionOrGroupLabel;
            return (
              <DropdownListOption
                testID={testID ? `${testID}-option-${value}` : undefined}
                label={label}
                icon={icon}
                onSelect={() => {
                  onSelect(value);
                  setIsOpen(false);
                }}
                ddTheme={theme}
                key={String(value)}
                indented={type === "GROUP_OPTION"}
              />
            );
          }
        })
    );
  }, [onSelect, options, testID, theme]);

  const dropdownContainerRef = useAnimatedRef<View>();
  const [isListMounted, setListMounted] = useState(false);
  const layout = useSharedValue({
    pageX: 0,
    pageY: 0,
    width: 0,
    height: 0,
  });

  const { drawerSlideAnimation } = useSideBarContext();
  const updateScreenPosition = useCallback(() => {
    return new Promise<void>((resolve) => {
      runOnUI(() => {
        const result = measure(dropdownContainerRef);
        if (result) {
          layout.value = result;
          runOnJS(resolve)();
        }
      })();
    });
  }, [dropdownContainerRef, layout]);

  const onPressSelectedOption = useCallback(() => {
    const newIsOpen = !isOpenToggleFixRef.current;
    if (readOnly || (newIsOpen && optionsNotAvailable)) {
      return;
    }
    void updateScreenPosition().then(() => setIsOpen(newIsOpen));
  }, [readOnly, optionsNotAvailable, updateScreenPosition]);

  const onTouchOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useAnimatedReaction(
    () => drawerSlideAnimation.value,
    () => {
      const result = measure(dropdownContainerRef);
      if (result) {
        layout.value = result;
      }
    },
    []
  );

  useEffectOnce(() => {
    const listener = Dimensions.addEventListener("change", () =>
      updateScreenPosition()
    );
    return () => {
      listener.remove();
    };
  });

  const nearestScrollViewController = useScrollViewController({
    okIfNotAvailable: true,
  });
  useEffectOnce(() => {
    const listener = () => updateScreenPosition();
    nearestScrollViewController?.addScrollOffsetListener(listener);
    return () => {
      nearestScrollViewController?.removeScrollOffsetListener(listener);
    };
  });

  const isOpenToggleFixRef = useRef(isOpen);
  return (
    <DropdownOuterContainer style={style} testID={testID}>
      <DropdownContainer
        ref={dropdownContainerRef}
        isOpen={isListMounted}
        ddTheme={theme}
        inlineStyle={inlineStyle}
        onLayout={updateScreenPosition}
      >
        <SelectedOption
          testID={testID ? `${testID}-selected-option` : undefined}
          onStartShouldSetResponderCapture={() => {
            isOpenToggleFixRef.current = isOpen;
            return true;
          }}
          onPress={onPressSelectedOption}
        >
          {option.icon ? (
            <Icon inverted={isListMounted} icon={option.icon} offsetX={-1} />
          ) : (
            <IconDummy />
          )}
          <SelectedOptionText
            isOpen={isListMounted}
            numberOfLines={1}
            ddTheme={theme}
            size={inlineStyle ? "mediumBold" : "medium"}
          >
            {option!.label}
          </SelectedOptionText>
          <DropdownOpenIndicatorContainer>
            {!readOnly && !optionsNotAvailable ? (
              <DropdownOpenIndicator
                isOpen={isListMounted}
                icon={isListMounted ? "chevron-up" : "chevron-down"}
                size={32}
                ddTheme={theme}
              />
            ) : null}
          </DropdownOpenIndicatorContainer>
        </SelectedOption>
      </DropdownContainer>
      {isOpen ? (
        <DropdownOptionsList
          inlineStyle={inlineStyle}
          theme={theme}
          parentLayout={layout}
          onTouchOutside={onTouchOutside}
          setListMounted={setListMounted}
          dropdownItems={
            <>
              {optionsNotAvailable ? null : dropdownItems}
              {onCreate ? (
                <DropdownListOption
                  label={`+ Create new ${optionNoun.toLowerCase()}`}
                  onSelect={onCreateNew}
                  ddTheme={theme}
                />
              ) : null}
            </>
          }
        />
      ) : null}
      <SpacerThatIsPresentWhenDropdownIsOpen />
    </DropdownOuterContainer>
  );
};

type DropdownOptionsListProps = {
  parentLayout: SharedValue<MeasuredDimensions>;
  inlineStyle: boolean;
  theme: DropdownTheme;
  onTouchOutside: () => void;
  setListMounted: (isListMounted: boolean) => void;
  dropdownItems: React.ReactNode;
};
export const DropdownOptionsList: React.FC<DropdownOptionsListProps> = ({
  parentLayout,
  inlineStyle,
  theme,
  onTouchOutside,
  dropdownItems,
  setListMounted,
}) => {
  const safeAreaInsets = useSafeAreaInsets();

  const containerStyle = useAnimatedStyle(() => {
    return {
      width: parentLayout.value.width - (inlineStyle ? 0 : 2),
      top:
        parentLayout.value.pageY +
        parentLayout.value.height -
        (inlineStyle ? 0 : 2),
      left: parentLayout.value.pageX + (inlineStyle ? 0 : 1),
      bottom: 4 + safeAreaInsets.bottom,
    };
  }, [inlineStyle, safeAreaInsets]);

  return (
    <Portal
      hostName={"rootPortal"}
      handleOnMount={(onDone) => {
        setListMounted(true);
        onDone();
      }}
      handleOnUnmount={(onDone) => {
        setListMounted(false);
        onDone();
      }}
    >
      <DropdownListContainer style={containerStyle}>
        <StyledOptionsScrollView
          contentContainerStyle={{
            backgroundColor: theme.primary,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
          bounces={false}
        >
          <TouchBoundary onTouchOutside={onTouchOutside}>
            {dropdownItems}
          </TouchBoundary>
        </StyledOptionsScrollView>
      </DropdownListContainer>
    </Portal>
  );
};

const StyledOptionsScrollView = styled(QKScrollView)`
  background-color: transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const DropdownListContainer = styled(Animated.View)`
  position: absolute;
`;

interface DropdownOptionProps {
  onSelect: () => void;
  label: string;
  icon?: IconIdentifier;
  ddTheme: DropdownTheme;
  indented?: boolean;
  testID?: string;
}

type DropdownItem<T> =
  | (Omit<OptionGroup<T>, "options"> & { type: "GROUP_LABEL" })
  | (DropdownOption<T> & { type: "OPTION" | "GROUP_OPTION" });

const DropdownListOption: React.FC<DropdownOptionProps> = ({
  onSelect,
  label,
  icon,
  testID,
  indented,
  ddTheme,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <DropdownOptionWrapper
      onPress={onSelect}
      testID={testID}
      isHovered={isHovered}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      indented={indented}
    >
      {icon ? <Icon inverted={true} icon={icon} offsetX={-1} /> : <IconDummy />}
      <DropdownOptionText ddTheme={ddTheme} isOpen={true}>
        {label}
      </DropdownOptionText>
    </DropdownOptionWrapper>
  );
};

const DropdownListGroupHeader: React.FC<{
  label: string;
  ddTheme: DropdownTheme;
}> = ({ label, ddTheme }) => {
  return (
    <DropdownOptionWrapper disabled={true} indented={false}>
      <IconDummy />
      <DropdownOptionText
        ddTheme={{
          background: ddTheme.groupHeaderTextColor ?? ddTheme.background,
        }}
        isOpen={true}
      >
        {label}
      </DropdownOptionText>
    </DropdownOptionWrapper>
  );
};

const DropdownContainer = styled.View<{
  ddTheme: DropdownTheme;
  isOpen?: boolean;
  inlineStyle: boolean;
}>`
  background-color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.primary : ddTheme.background};
  flex-direction: column;
  min-height: 40px;
  border-color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
  border-width: ${({ inlineStyle }) => (inlineStyle ? "0" : "1px")};
  ${({ isOpen }) =>
    isOpen
      ? `
      border-top-left-radius: 20px; 
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; 
      border-bottom-right-radius: 0;
      `
      : "border-radius: 20px;"};
`;

const SelectedOption = styled.Pressable`
  flex-direction: row;
  align-items: center;
`;

const SelectedOptionText = styled(Text)<{
  ddTheme: DropdownTheme;
  isOpen: boolean;
}>`
  color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
  flex: 1;
`;

const DropdownOptionText = styled(Text)<{
  ddTheme: { background: string };
  isOpen: boolean;
}>`
  color: ${({ ddTheme }) => ddTheme.background};
`;

const DropdownOpenIndicator = styled(Icon)<{
  ddTheme: DropdownTheme;
  isOpen: boolean;
}>`
  color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
`;

const DropdownOpenIndicatorContainer = styled.View`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const SpacerThatIsPresentWhenDropdownIsOpen = styled.View`
  height: 20px;
`;

const DropdownOuterContainer = styled.View`
  height: 50px;
  user-select: none;
  margin-bottom: 10px;
`;

const DropdownOptionWrapper = styled.Pressable<{
  isHovered?: boolean;
  indented?: boolean;
}>`
  ${({ isHovered }) => `
    opacity: ${isHovered ? 0.8 : 1}
  `};
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ indented }) =>
    indented
      ? `
    padding-left: 20px;
  `
      : ""}
`;
const IconDummy = styled.View`
  width: 18px;
`;

type PlaceholderState =
  | "OPTIONS_AVAILABLE"
  | "OPTIONS_LOADING"
  | "OPTIONS_NOT_AVAILABLE"
  | "SELECTED_OPTION_NOT_AVAILABLE";
export type PlaceholderTextProviderMap = Record<
  PlaceholderState,
  (args: { optionPluralNoun: string; optionNoun: string }) => string
>;
const defaultPlaceholderTextProviderMap = {
  OPTIONS_LOADING: ({ optionPluralNoun }) =>
    `Loading ${optionPluralNoun.toLowerCase()}...`,
  SELECTED_OPTION_NOT_AVAILABLE: ({ optionNoun }) =>
    `Selected ${optionNoun.toLowerCase()} not available`,
  OPTIONS_NOT_AVAILABLE: ({ optionPluralNoun }) =>
    `No ${optionPluralNoun.toLowerCase()} available.`,
  OPTIONS_AVAILABLE: ({ optionNoun }) => `Select ${optionNoun.toLowerCase()}`,
} satisfies PlaceholderTextProviderMap;
