import {
  GestureResponderEvent,
  Pressable,
  PressableProps,
  View,
} from "react-native";
import React from "react";
import { OnLinkPress } from "@app/util/link.utils";

/**
 * This should be closer to the realistic event type given on web it is different from native.
 */
export type PressEvent =
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | GestureResponderEvent;

type DefaultOnPress = (event: PressEvent) => void;

export type OnPress = OnLinkPress | DefaultOnPress | undefined | null;

export function extendOnPress(
  onPress: OnPress,
  beforeHook: (() => void) | undefined | null
) {
  const newOnPress = (e: PressEvent) => {
    beforeHook?.();
    onPress?.(e);
  };

  if (onPress && "linkProps" in onPress) {
    newOnPress.linkProps = onPress.linkProps;
  }
  return newOnPress;
}

export interface BasePressableProps
  extends Omit<PressableProps, "onPress">,
    React.RefAttributes<View> {
  onPress?: OnPress;
  /**
   * If true, will not pass linkProps to the Pressable component.
   * This is useful if you want to use the linkProps on a parent component and
   * are trying to avoid having illegal dom errors (not allowed to have nested anchor tags).
   */
  omitLinkProps?: boolean;
}

/**
 * This component is a wrapper around Pressable that will pass linkProps to the Pressable component.
 * Passing linkProps to Pressable makes the pressable component behave like a link. Allowing for
 * viewing the URL href before navigating and right click to open in new tab.
 */
const BasePressable: React.FC<BasePressableProps> = React.forwardRef<
  View,
  BasePressableProps
>((props, ref) => {
  return (
    <Pressable
      {...(props.omitLinkProps
        ? {}
        : (props.onPress as OnLinkPress | undefined | null)
      )?.linkProps}
      {...props}
      ref={ref}
    />
  );
});
BasePressable.displayName = "BasePressable";

export default BasePressable;
