import React, { useState } from "react";
import { apiRequest } from "@app/util/client";
import TextInput from "@app/components/questkit/textInput";
import styled from "styled-components/native";
import { View } from "react-native";
import Button from "@app/components/questkit/button";
import { ReviewResult } from "@questmate/openapi-spec";
import { usePromise } from "@app/util/usePromise";

interface ReviewDialogProps {
  questInstanceId: string;
  onSuccess: (reviewResult: ReviewResult) => void;
}

export const ReviewDialog: React.FC<ReviewDialogProps> = ({
  questInstanceId,
  onSuccess,
}) => {
  const [message, setMessage] = useState("");
  const [changesRequestedSuccessfully, setChangesRequestedSuccessfully] =
    useState(false);
  const [approvalRecordedSuccessfully, setApprovalRecordedSuccessfully] =
    useState(false);

  const { execute: onRequestChanges, isLoading: loadingChangesRequested } =
    usePromise(() =>
      apiRequest("post", `/questInstances/${questInstanceId}/reviews`, {
        result: "CHANGES_REQUESTED",
        message,
      }).then(() => {
        setChangesRequestedSuccessfully(true);
        onSuccess("CHANGES_REQUESTED");
      })
    );

  const { execute: onApprove, isLoading: loadingApprove } = usePromise(
    async () =>
      apiRequest("post", `/questInstances/${questInstanceId}/reviews`, {
        result: "APPROVED",
        message,
      }).then(() => {
        setApprovalRecordedSuccessfully(true);
        onSuccess("APPROVED");
      })
  );

  return (
    <ReviewDialogContainer>
      <TextInput
        value={message}
        onChangeText={setMessage}
        placeholder="Message (Optional)"
        multiline={true}
        autoCorrect={true}
        minNumberOfLines={4}
      />
      <StyledButton
        title="Request Changes"
        buttonType="warning"
        onPress={onRequestChanges}
        loading={loadingChangesRequested}
        success={changesRequestedSuccessfully}
        disabled={loadingApprove || changesRequestedSuccessfully}
      />
      <StyledButton
        title="Approve"
        onPress={onApprove}
        loading={loadingApprove}
        success={approvalRecordedSuccessfully}
        disabled={loadingChangesRequested || approvalRecordedSuccessfully}
      />
    </ReviewDialogContainer>
  );
};

const ReviewDialogContainer = styled(View)`
  padding: 16px;
`;
const StyledButton = styled(Button)`
  margin-top: 16px;
`;
