import React, { useCallback, useEffect, useRef } from "react";
import type { ItemRenderData } from "@app/types/itemRenderData";
import {
  ItemBaseProps,
  ItemContainerWrapper,
} from "@app/components/item/components/itemContainer";
import { SchedulerInput } from "@app/components/scheduler";
import * as Localization from "expo-localization";
import ListItem from "@app/components/questkit/listItem";
import Switch from "@app/components/questkit/switch";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import styled from "styled-components/native";

const changeSchedule = (
  item: ItemRenderData,
  schedule: string,
  timezone: string
): ItemRenderData => ({
  ...item,
  data: {
    schedule,
    timezone,
    assignToSubmitter: item.data?.assignToSubmitter,
  },
  version: item.version + 1,
});

const changeAssignToSubmitter = (
  item: ItemRenderData,
  assignToSubmitter: boolean
): ItemRenderData => ({
  ...item,
  data: {
    schedule: item.data?.schedule,
    timezone: item.data?.timezone,
    assignToSubmitter,
  },
  version: item.version + 1,
});

export const ScheduleItem: React.FC<ItemBaseProps> = (props) => {
  const { item, editMode, readOnly, onItemChange, onItemValidate } = props;
  const itemRef = useRef<ItemRenderData>();
  itemRef.current = item;
  const { schedule, timezone } = item.data;
  const questViewContext = useQuestViewContext();
  const userDefaultTimezone = Localization.useCalendars()?.[0]?.timeZone;

  useEffect(() => {
    if (!timezone && !editMode && !readOnly) {
      // initialize timezone to assignee's timezone
      onItemChange?.(changeSchedule(item, schedule, userDefaultTimezone || ""));
    }

    if (editMode && !schedule) {
      // initialize the schedule when adding an item
      onItemChange?.(changeSchedule(item, "0 9 * * 1-5", timezone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, readOnly]);

  const onChange = useCallback(
    (schedule: string, timezone: string) => {
      onItemChange?.(changeSchedule(itemRef.current!, schedule, timezone));
      onItemValidate?.();
    },
    [onItemChange, onItemValidate]
  );
  const onToggleAssignToSubmitter = useCallback(() => {
    onItemChange?.(
      changeAssignToSubmitter(
        itemRef.current!,
        !itemRef.current!.data?.assignToSubmitter
      )
    );
    onItemValidate?.();
  }, [onItemChange, onItemValidate]);

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <>
          <SchedulerInput
            schedule={schedule}
            timezone={timezone}
            onChange={onChange}
            scheduleDisabled={!editMode && readOnly}
            timezoneDisabled={editMode || readOnly}
          />
          {questViewContext.viewContext !== "RUN" &&
          item.prototype.referenceSlug === "SCHEDULE" ? (
            <StyledListItem
              text={"Assign to Quest submitter"}
              onPress={onToggleAssignToSubmitter}
              actionComponent={
                <Switch
                  switchOn={item.data.assignToSubmitter}
                  onSwitch={onToggleAssignToSubmitter}
                />
              }
            />
          ) : null}
        </>
      }
    />
  );
};

const StyledListItem = styled(ListItem)`
  margin: 12px;
`;
