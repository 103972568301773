import React from "react";

import ReactQRCode from "react-qr-code";
import styled, { useTheme } from "styled-components/native";
import { StyleProp, ViewStyle } from "react-native";

type QRCodeProps = {
  size: number;
  value: string;
  style?: StyleProp<ViewStyle>;
};
export const QRCode: React.FC<QRCodeProps> = ({ size, value, style }) => {
  const theme = useTheme();
  return (
    <QRCodeContainer size={size} style={style}>
      <QRCodeWrapper>
        <ReactQRCode size={size} value={value} fgColor={theme.primary} />
      </QRCodeWrapper>
    </QRCodeContainer>
  );
};

const QRCodeWrapper = styled.View`
  margin: 20px;
`;

const QRCodeContainer = styled.View<{ size: number }>`
  border-radius: 20px;
  width: ${({ size }) => `${size + 40}px`};
  overflow: hidden;
  ${({ theme }) =>
    theme.colorScheme === "dark" ? "" : `border: 1px solid ${theme.primary};`}
  background-color: ${({ theme }) => theme.background};
  align-items: center;
`;
