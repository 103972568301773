import React from "react";

import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";

export const PlaceholderItem: React.FC<ItemBaseProps> = ({
  editMode,
  onItemChange,
  item,
}) => {
  return (
    <ItemContainerWrapper
      editMode={editMode}
      onItemChange={onItemChange}
      item={item}
      blockNode={
        <StyledText size="medium" $warning={true}>
          It seems there&apos;s something new to see here. Please update /
          reload your app to experience some new magics!
        </StyledText>
      }
    />
  );
};

const StyledText = styled(Text)`
  margin-horizontal: 20px;
`;
