import React from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { Platform, StyleProp, ViewStyle } from "react-native";
import BasePressable from "@app/components/questkit/BasePressable";

type MultiSwitchProps<T extends string> = {
  selectedOption: T;
  options: T[];
  onChange: (selectedOption: T) => void;
  style?: StyleProp<ViewStyle>;
};

export const MultiSwitch = <T extends string>({
  selectedOption,
  options,
  onChange,
  style,
}: MultiSwitchProps<T>): React.ReactElement => {
  return (
    <MultiSwitchContainer style={[style]}>
      {options.map((option: T) => (
        <OptionContainer
          key={option}
          active={option === selectedOption}
          onPress={() => onChange(option)}
        >
          <OptionText active={option === selectedOption}>{option}</OptionText>
        </OptionContainer>
      ))}
    </MultiSwitchContainer>
  );
};

const MultiSwitchContainer = styled.View`
  flex-direction: row;
  border-radius: 10px;
  //height: 40px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.primary};
  padding: 4px;
  margin-bottom: 20px;
`;

const OptionContainer = styled(BasePressable)<{
  active: boolean;
}>`
  border-radius: 5px;
  padding-horizontal: 10px;
  padding-vertical: 4px;
  align-items: center;
  flex: 1;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.interaction.primary : "transparent"};
`;

const OptionText = styled(Text)<{
  active: boolean;
}>`
  color: ${({ theme, active }) => (active ? theme.background : theme.primary)};
  ${Platform.OS === "web" ? `user-select: none;` : ""}
`;
