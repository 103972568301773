import React, { useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import { Boundary } from "@app/components/screen/boundary";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { createLink } from "@app/util/link.utils";
import ListItem from "@app/components/questkit/listItem";
import Icon from "@app/components/icon";

export const SupportScreen: React.FC = () => {
  const navigation = useAppNavigation();
  const [showAppDebugInformation, setShowAppDebugInformation] = useState(false);
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableWithoutFeedback
            onLongPress={() => {
              setShowAppDebugInformation((show) => !show);
            }}
            onPress={
              showAppDebugInformation
                ? createLink({ screen: "AppDebugInformation" })
                : undefined
            }
          >
            <Icon icon={showAppDebugInformation ? "bug" : "NONE"} size={32} />
          </TouchableWithoutFeedback>
        );
      },
    });
  }, [showAppDebugInformation, navigation]);

  return (
    <SupportScreenWrapper>
      <SafeAreaView edges={["bottom"]}>
        <Boundary>
          <ListItem
            text="Roadmap"
            icon="calendar"
            actionIcon="chevron-right"
            onPress={createLink("https://roadmap.questmate.com")}
          />
          <ListItem
            text="Feature Requests"
            icon="lightbulb"
            actionIcon="chevron-right"
            onPress={createLink(
              "https://roadmap.questmate.com/feature-requests"
            )}
          />
          <ListItem
            text="Contact Us"
            icon="contact-support"
            actionIcon="chevron-right"
            onPress={createLink(
              "https://app.questmate.com/p/ccc6b6c9-2e44-4931-b6a8-9c67272dd6cc"
            )}
          />
          {showAppDebugInformation && (
            <ListItem
              text="App Debug Information"
              icon="bug"
              actionIcon="chevron-right"
              onPress={createLink({ screen: "AppDebugInformation" })}
            />
          )}
        </Boundary>
      </SafeAreaView>
    </SupportScreenWrapper>
  );
};

const SupportScreenWrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: "center",
  },
})`
  background-color: ${({ theme }) => theme.background};
`;
