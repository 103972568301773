import { sentry } from "@app/util/sentry";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  runOnJS,
  SharedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { Analytics } from "@app/analytics";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

enum SnackbarSeverity {
  NOTICE,
  WARNING,
}

type SnackbarMessage = {
  text: string;
  severity: SnackbarSeverity;
  duration: number;
};

interface SnackbarContext {
  sendMessage: (
    message: string,
    severity?: SnackbarSeverity,
    duration?: number
  ) => void;
  notificationAnimation: SharedValue<number>;
  message: SnackbarMessage;
  resetCurrentMessageDuration: (newDuration?: number) => void;
  closeCurrentMessage: () => void;
}

// 2.5s seems to be a good tradeoff between making sure the message is seen
// and is not blocking the header bar / navigation items for too long.
const DEFAULT_DURATION = 2500;
const NULL_MESSAGE: SnackbarMessage = {
  text: "",
  severity: SnackbarSeverity.NOTICE,
  duration: 0,
};
const SnackbarContext = React.createContext<SnackbarContext>({
  sendMessage: () => undefined,
  notificationAnimation: { value: 0 },
  message: NULL_MESSAGE,
  resetCurrentMessageDuration: () => undefined,
  closeCurrentMessage: () => undefined,
});

const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showMessage, setShowMessage, showMessageRef] = useStateWithRef(false);
  const [message, setMessage] = useState<SnackbarMessage>(NULL_MESSAGE);

  const sendMessage = useCallback(
    (
      message: string,
      severity: SnackbarSeverity = SnackbarSeverity.NOTICE,
      duration = DEFAULT_DURATION
    ) => {
      setMessage({ text: message, severity, duration });
      setShowMessage(true);
      sentry.addBreadcrumb({
        message: "Snackbar message",
        category: "ui",
        level: severity === SnackbarSeverity.NOTICE ? "info" : "warning",
        data: {
          message,
          severity,
        },
      });
      if (severity === SnackbarSeverity.WARNING) {
        Analytics.trackEvent("Show Snackbar Error Message", {
          message,
        });
      }
    },
    [setShowMessage]
  );

  // 2-step animation
  // 0 -> 1: slide down
  // 1 -> 2: fade out
  const notificationAnimation = useSharedValue(0);
  const activeMessageTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const setDelayedFadeOut = useCallback(
    (delay: number) => {
      if (activeMessageTimeoutRef.current !== null) {
        clearTimeout(activeMessageTimeoutRef.current);
      }
      activeMessageTimeoutRef.current = setTimeout(() => {
        notificationAnimation.value = 1;
        notificationAnimation.value = withTiming(
          2,
          {
            duration: 1000,
          },
          (finished) => {
            if (finished) {
              runOnJS(setShowMessage)(false);
            }
          }
        );
        activeMessageTimeoutRef.current = null;
      }, delay);
    },
    [notificationAnimation, setShowMessage]
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        // Delay animating the message to allow the child to receive the
        // new message and render it once before sliding down.
        // This fixes issues with using stale message heights and having the animation jump around.
        notificationAnimation.value = 0;
        const slideDownDuration = 250;
        notificationAnimation.value = withTiming(1, {
          duration: slideDownDuration,
        });
        setDelayedFadeOut(slideDownDuration + message.duration);
      }, 0);
    } else {
      notificationAnimation.value = 2;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage, message]);

  const closeCurrentMessage = useCallback(() => {
    setShowMessage(false);
    notificationAnimation.value = 2;
  }, [notificationAnimation, setShowMessage]);

  const resetCurrentMessageDuration = useCallback(
    (newDuration = 3000) => {
      if (!showMessageRef.current) {
        console.warn("Cannot extend current message when no message is shown.");
        return;
      }
      notificationAnimation.value = 1;

      setDelayedFadeOut(newDuration);
    },
    [notificationAnimation, setDelayedFadeOut, showMessageRef]
  );

  const context: SnackbarContext = useMemo(() => {
    return {
      sendMessage,
      notificationAnimation,
      message,
      closeCurrentMessage,
      resetCurrentMessageDuration,
    };
  }, [
    sendMessage,
    notificationAnimation,
    message,
    closeCurrentMessage,
    resetCurrentMessageDuration,
  ]);

  return (
    <SnackbarContext.Provider value={context}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider, SnackbarSeverity };
