import parsePhoneNumber from "libphonenumber-js";

export const formatIdentifier = (identifier: string): string => {
  const phoneNumber = parsePhoneNumber(identifier || "");
  if (phoneNumber) {
    return phoneNumber.formatInternational();
  } else {
    return identifier || "";
  }
};

export default formatIdentifier;
