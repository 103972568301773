import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

export interface PublicQuestSessionsState {
  sessions: Record<string, string>;
}

const INITIAL_STATE: PublicQuestSessionsState = {
  sessions: {},
};

const slice = createSlice({
  name: "publicQuestAuth",
  initialState: INITIAL_STATE,
  reducers: {
    addSessionToken: (
      state,
      action: PayloadAction<{ questInstanceId: string; sessionToken: string }>
    ) => {
      state.sessions = filterExpiredSessions(state.sessions);
      state.sessions[action.payload.questInstanceId] =
        action.payload.sessionToken;
    },
    removeSessionToken: (state, action: PayloadAction<string>) => {
      for (const [questInstanceId, sessionToken] of Object.entries(
        state.sessions
      )) {
        if (sessionToken === action.payload) {
          delete state.sessions[questInstanceId];
        }
      }
    },
  },
});

const reducer = slice.reducer;
export default reducer;

export const { addSessionToken, removeSessionToken } = slice.actions;

export function filterExpiredSessions(
  sessions: PublicQuestSessionsState["sessions"]
) {
  const currentUTCTimeInSeconds = Date.now() / 1000;

  return Object.keys(sessions).reduce((acc, key) => {
    try {
      const token = jwtDecode<{ exp: number }>(sessions[key]);
      if (token?.exp && token.exp > currentUTCTimeInSeconds) {
        // Keep valid tokens that have not yet expired.
        acc[key] = sessions[key];
      }
    } catch (_e) {
      // Ignore decode errors.
    }
    return acc;
  }, {} as PublicQuestSessionsState["sessions"]);
}
