import {
  EventScheduler,
  DataRequestedEvent,
} from "@app/components/item/components/custom/v2/EventScheduler";
import { DependentDataSource } from "@questmate/questscript";

export class DataSourceCollection {
  private dataSources = new Map<string, DataSourceFacade>();

  constructor(private readonly eventScheduler: EventScheduler) {}

  processUpdates(dataSources: Record<string, DependentDataSource>) {
    Object.entries(dataSources).forEach(([id, dataSource]) => {
      this.get(id).processUpdate(dataSource);
    });
  }

  clearErrorRetries() {
    this.dataSources.forEach((dataSourceManager) =>
      dataSourceManager.clearRetries()
    );
  }

  refreshData() {
    this.dataSources.forEach((dataSourceManager) =>
      dataSourceManager.refresh()
    );
  }

  pauseRequests() {
    this.dataSources.forEach((dataSourceManager) =>
      dataSourceManager.pauseRequests()
    );
  }

  resumeRequests() {
    this.dataSources.forEach((dataSourceManager) =>
      dataSourceManager.resumeRequests()
    );
  }

  get(dataSourceId: string): DataSourceFacade {
    if (!this.dataSources.has(dataSourceId)) {
      this.dataSources.set(
        dataSourceId,
        new DataSourceFacade(this.eventScheduler, dataSourceId)
      );
    }
    return this.dataSources.get(dataSourceId)!;
  }
}

class DataSourceFacade {
  private retriesAttempted = 0;
  private lastUpdated: string | null = null;
  private scheduledEventTimeout: NodeJS.Timeout | null = null;

  // we want to refresh data on first render
  private restartPagingOnNextEvent = true;
  private eventIdsThisSession: string[] = [];
  private isPaused = false;
  private lastUpdateReceived: DependentDataSource | null = null;

  constructor(
    private readonly eventScheduler: EventScheduler,
    readonly dataSourceId: string
  ) {}

  processUpdate(dataSource: DependentDataSource) {
    this.lastUpdateReceived = dataSource;
    if (this.isPaused) {
      return;
    }

    if (dataSource?.id !== this.dataSourceId) {
      console.error(
        "DataSourceManager received update for data source with a different id!",
        dataSource.id,
        this.dataSourceId
      );
      return;
    }

    this.lastUpdated = dataSource.lastUpdated;

    if (dataSource.error) {
      if (this.retriesAttempted < 3) {
        this.retriesAttempted++;
        if (dataSource.error.retryAfter) {
          const retryDelay = dataSource.error.retryAfter * 1000;

          this.queueDelayedEventIfNotAlreadyQueued(retryDelay);
        } else {
          this.queueEvent();
        }
      } else {
        // todo: Instead of just retrying 3 times with no delay, implement a backoff algorithm that could allow us to
        //       retry more times over a longer period of time. Would automatically resolve errors caused by a temporary
        //       problem.
        console.error(
          `Fetching data for DataSource '${this.dataSourceId}' has failed 3 times in a row. Giving up for now. Refresh to restart retries.`
        );
      }
    } else {
      this.clearRetries();

      if (dataSource.hasMoreData) {
        // either no data has been loaded yet, or we are not finished loading all pages
        this.queueEvent();
      } else if (this.restartPagingOnNextEvent) {
        this.queueEvent(true);
      } else if (dataSource.refreshInterval) {
        this.queueDelayedEventIfNotAlreadyQueued(
          dataSource.refreshInterval,
          true
        );
      }
    }
  }

  private queueDelayedEventIfNotAlreadyQueued(
    delaySeconds: number,
    restartPaging = false
  ) {
    if (this.scheduledEventTimeout === null) {
      this.scheduledEventTimeout = setTimeout(
        this.queueEvent.bind(this, restartPaging),
        delaySeconds * 1000
      );
    }
  }

  private queueEvent(restartPaging = false) {
    const alreadyAwaitingAnExistingEquivalentEvent =
      this.eventScheduler.events.some(
        (event) =>
          event.type === "DATA_REQUESTED" &&
          (event as DataRequestedEvent).dataSourceId === this.dataSourceId &&
          (event as DataRequestedEvent).isInFlight() &&
          (event as DataRequestedEvent).restartPaging === restartPaging
      );

    if (!alreadyAwaitingAnExistingEquivalentEvent) {
      const event = new DataRequestedEvent(this.dataSourceId, restartPaging);
      this.eventScheduler.enqueue(event);
      this.eventIdsThisSession.push(event.id);
    }

    this.restartPagingOnNextEvent = false;
    this.clearFutureRequests();
  }

  private clearFutureRequests() {
    if (this.scheduledEventTimeout !== null) {
      clearTimeout(this.scheduledEventTimeout);
      this.scheduledEventTimeout = null;
    }
  }

  refresh() {
    this.restartPagingOnNextEvent = true;
    this.clearFutureRequests();
  }

  clearRetries() {
    this.retriesAttempted = 0;
  }

  pauseRequests() {
    this.clearFutureRequests();
    this.isPaused = true;
  }

  resumeRequests() {
    this.isPaused = false;
    if (this.lastUpdateReceived !== null) {
      this.processUpdate(this.lastUpdateReceived);
    }
  }

  isLoading() {
    return this.eventIdsThisSession
      .map((id) => this.eventScheduler.events.find((e) => e.id === id))
      .some((event) => event?.isInFlight());
  }
}
