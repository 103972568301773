import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTheme } from "styled-components/native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import isEqual from "react-fast-compare";
import { StyleProp, ViewStyle } from "react-native";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

type ModelProps = Required<
  Pick<QKLoadingIndicatorProps, "name" | "yieldTo" | "loading">
>;
type LoadingIndicatorModel = {
  id: number;
  // shouldShow: boolean;
  props: ModelProps;
};

const idSequence = {
  lastId: 0,
};

export class LoadingIndicatorService {
  private static _instance: LoadingIndicatorService;
  static get instance(): LoadingIndicatorService {
    if (!LoadingIndicatorService._instance) {
      LoadingIndicatorService._instance = new LoadingIndicatorService();
    }
    return LoadingIndicatorService._instance;
  }

  private _loadingIndicators: LoadingIndicatorModel[] = [];
  private _subscribers: (() => void)[] = [];

  useShouldShow(name: string, yieldTo: string[], loading: boolean): boolean {
    const stableProps = useIsEqualMemo({ name, yieldTo, loading });
    const indicatorId = useMemo(
      () => this.register(stableProps),
      [stableProps]
    );
    useEffect(
      () => () => {
        this.unregister(indicatorId);
      },
      [indicatorId]
    );

    const [shouldShow, setShouldShow, shouldShowRef] = useStateWithRef(
      this.shouldShow(indicatorId)
    );

    const update = useCallback(() => {
      const b = this.shouldShow(indicatorId);
      if (b !== shouldShowRef.current) {
        setShouldShow(b);
      }
    }, [indicatorId, setShouldShow, shouldShowRef]);

    useEffect(() => this.subscribeToChanges(update), [indicatorId, update]);

    useEffect(() => {
      this.update(indicatorId, stableProps);
    }, [indicatorId, stableProps]);

    return shouldShow;
  }

  private shouldShow(id: number | null): boolean {
    if (id === null) {
      return false;
    }

    const indicator = this._loadingIndicators.find(
      (indicator) => indicator.id === id
    );
    if (!indicator) {
      return false;
    }

    /*
        rules:
        loading true --> show if none of the yieldTo are showing
        loading false --> show if 2+ components yielding to you are loading
     */

    const shouldYield = indicator.props.yieldTo.some((name) => {
      const yieldTo = this._loadingIndicators.find(
        (indicator) => indicator.props.name === name
      );
      return this.shouldShow(yieldTo?.id ?? null);
    });

    if (shouldYield) {
      return false;
    }

    if (indicator.props.loading) {
      return true;
    } else {
      const multipleDependentIndicatorsAreLoading = (
        indicatorName: string
      ): boolean => {
        const indicatorsYieldingToMe = this._loadingIndicators.filter(
          (indicator) => indicator.props.yieldTo.includes(indicatorName)
        );
        return (
          indicatorsYieldingToMe.filter(
            (indicator) =>
              indicator.props.loading ||
              multipleDependentIndicatorsAreLoading(indicator.props.name)
          ).length >= 2
        );
      };

      return multipleDependentIndicatorsAreLoading(indicator.props.name);
    }
  }

  private register(props: ModelProps): number {
    const id = idSequence.lastId++;
    this._loadingIndicators.push({ id, props });
    this._subscribers.forEach((c) => c());
    return id;
  }

  private unregister(indicatorId: number): void {
    this._loadingIndicators = this._loadingIndicators.filter(
      ({ id }) => id !== indicatorId
    );
    this._subscribers.forEach((c) => c());
  }

  private update(id: number | null, props: ModelProps): void {
    if (id === null) {
      return;
    }

    const indicator = this._loadingIndicators.find(
      (indicator) => indicator.id === id
    );
    if (!indicator) {
      console.warn(
        "Update received for unregistered loading indicator.",
        id,
        props
      );
      return;
    }

    if (!isEqual(indicator.props, props)) {
      const indicatorIndex = this._loadingIndicators.indexOf(indicator);
      this._loadingIndicators.splice(indicatorIndex, 1, {
        id,
        props,
      });
      this._subscribers.forEach((c) => c());
    }
  }

  private subscribeToChanges(callback: () => void): () => void {
    this._subscribers.push(callback);
    return () => {
      this._subscribers = this._subscribers.filter((c) => c !== callback);
    };
  }
}

type QKLoadingIndicatorProps = {
  name: string;
  yieldTo?: string[];
  loading?: boolean;
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
};

export const QKLoadingIndicator: React.FC<QKLoadingIndicatorProps> = (
  props
) => {
  const theme = useTheme();
  const {
    name,
    yieldTo = [],
    loading = true,
    size = 32,
    color = theme.action,
    style,
  } = props;

  const showIndicator = LoadingIndicatorService.instance.useShouldShow(
    name,
    yieldTo,
    loading
  );

  return showIndicator ? (
    <QKActivityIndicator size={size} color={color} style={style} />
  ) : null;
};
