import styled from "styled-components/native";
import { Image, Platform } from "react-native";
import React from "react";
import logoWhite from "@assets/icons/android-push-notification-logo.png";
import { SideBarContentScrollView } from "@app/navigation/sideBar/SideBarScrollView";
import {
  createSideBarItemRowIcon,
  SideBarItem,
} from "@app/navigation/sideBar/items/SideBarItem";
import { SideBarAssignmentsItem } from "@app/navigation/sideBar/items/SideBarAssignmentsItem";
import { SideBarQuestsItem } from "@app/navigation/sideBar/items/SideBarQuestsItem";
import { SideBarSettingsItem } from "@app/navigation/sideBar/items/SideBarSettingsItem";
import { SideBarWorkspaceSection } from "@app/navigation/sideBar/SideBarWorkspaceSection";

export const SideBarContent: React.FC = () => {
  return (
    <ContentWrapper testID="sidebar" accessibilityRole={"menu"}>
      <SideBarScrollView>
        {SideBarLogoItem}
        {SideBarHomeItem}
        <SideBarAssignmentsItem />
        <SideBarQuestsItem />
        <Spacer />
        <SideBarWorkspaceSection />
      </SideBarScrollView>
      <SideBarSettingsItem />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.sideBar.background};
  ${Platform.OS === "web" ? `user-select: none` : ``}
`;
const SideBarScrollView = styled(SideBarContentScrollView)`
  background-color: ${({ theme }) => theme.sideBar.scrollerBackground};
  padding-left: 16px;
  padding-right: 16px;
`;
const Spacer = styled.View`
  height: 50px;
`;
const LogoImage = styled(Image)`
  width: 32px;
  height: 32px;
  margin-left: 5px;
  margin-bottom: 12px;
`;

/**
 * Static SideBar Items we do not want to re-render
 */
const SideBarLogoItem = (
  <SideBarItem
    label=""
    disableActiveHighlight={true}
    screenName={"Home"}
    componentLeft={
      <LogoImage source={logoWhite as number} resizeMode={"contain"} />
    }
  />
);

const SideBarHomeItem = (
  <SideBarItem
    label={"Home"}
    screenName={"Home"}
    componentLeft={createSideBarItemRowIcon("house")}
  />
);
