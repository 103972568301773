import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MainReducerState {
  updateAvailable: boolean;
  pushNotificationsEnabled: boolean;
  subsequentStart: boolean;
}

const INITIAL_STATE = {
  updateAvailable: false,
  pushNotificationsEnabled: false,
  subsequentStart: false,
} as MainReducerState;

const slice = createSlice({
  name: "main",
  initialState: INITIAL_STATE,
  reducers: {
    setUpdateAvailable: (state, action: PayloadAction<boolean>) => {
      state.updateAvailable = action.payload;
    },
    setPushNotificationsEnabled: (state, action: PayloadAction<boolean>) => {
      state.pushNotificationsEnabled = action.payload;
    },
    setSubsequentStart: (state, action: PayloadAction<boolean>) => {
      state.subsequentStart = action.payload;
    },
  },
});

export const {
  setSubsequentStart,
  setUpdateAvailable,
  setPushNotificationsEnabled,
} = slice.actions;

const reducer = slice.reducer;
export default reducer;
