import loadResources from "@app/util/loadResources";
import { useCallback, useState } from "react";
import * as SplashScreen from "expo-splash-screen";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { sentry } from "@app/util/sentry";

export const useAppLoading = (): [
  appIsReady: boolean,
  onLayoutRootView: () => Promise<void>
] => {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffectOnce(() => {
    loadResources()
      .catch((e) => {
        const message = "Failed to load resources on initial load.";
        console.error(message, e);
        sentry.captureException(e, {
          extra: {
            message,
          },
        });
      })
      .finally(() => {
        // Tell the application to render
        setAppIsReady(true);
      });
  });

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  return [appIsReady, onLayoutRootView];
};
