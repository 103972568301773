import convertTime from "convert-time";

export type FormattedTime = string;
export type Period = "am" | "pm";

export type ValidateAndCleanTimeResult =
  | {
      valid: true;
      hour: number;
      minute: number;
    }
  | {
      valid: false;
    };

const timeValidation =
  /^(((([0-9])|([0-1][0-9])|(2[0-3]))([.:])(([0-5][0-9])|([0-5])))|(([0-9])|([0-1][0-9])|(2[0-3])))$/;
export function validateAndCleanTime(
  timeValue: string,
  period: Period
): ValidateAndCleanTimeResult {
  const newTimeValue = timeValue.trim();

  if (timeValidation.test(newTimeValue)) {
    let hour, minutes, idx;
    if (timeValue.length > 2) {
      idx = timeValue[1] === "." || timeValue[1] === ":" ? 1 : 2;
      hour = timeValue.slice(0, idx);
      minutes = timeValue.slice(idx + 1);
      if (minutes.length === 1) {
        // if they only provide a single number for the minutes, assume it is for the tens place, not the ones place
        minutes += "0";
      }
    } else {
      // if they only provide a single number, then it is the hours and the minutes are zero
      hour = timeValue;
      minutes = "00";
    }

    let hourInt = parseInt(hour);
    if (period === "am" && hourInt === 12) {
      hourInt = 0;
    } else if (period == "pm" && hourInt < 12 && hourInt > 0) {
      hourInt += 12;
    }

    return {
      valid: true,
      hour: hourInt,
      minute: parseInt(minutes),
    };
  } else {
    return {
      valid: false,
    };
  }
}

export function getTimeDisplayValue(
  hour: number,
  minute: number
): [FormattedTime, Period] {
  if ((!hour && hour !== 0) || (!minute && minute !== 0)) {
    return ["", "" as Period];
  }

  let period: Period;
  if (hour < 12) {
    period = "am";
  } else {
    period = "pm";
  }

  if (hour === 0) {
    hour = 12;
  }

  const [time] = convertTime(hour + ":" + minute, "hh.MM a")!.split(" ");

  return [time, period];
}
