import * as React from "react";
import { Platform, ScrollViewProps, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import QKScrollView from "@app/components/questkit/ScrollView";

/**
 * Inspired by react-navigation's DrawerContentScrollView
 * https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerContentScrollView.tsx
 */

export const SideBarContentScrollView: React.FC<ScrollViewProps> = ({
  style,
  children,
  ...rest
}) => {
  const insets = useSafeAreaInsets();

  return (
    <QKScrollView
      {...rest}
      contentContainerStyle={[
        {
          paddingTop: insets.top + (Platform.OS === "ios" ? 8 : 16),
          paddingStart: insets.left,
          paddingBottom: 32,
        },
      ]}
      style={[styles.container, style]}
      showsVerticalScrollIndicator={true}
    >
      {children}
    </QKScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
