import { ViewProps } from "react-native";
import React, { PropsWithChildren } from "react";
import { Text } from "@app/components/questkit";
import { QKTextProps } from "@app/components/questkit/text";
import BasePressable from "@app/components/questkit/BasePressable";
import { LinkableLocation, useLink } from "@app/util/link.utils";
import isEqual from "react-fast-compare";

interface LinkTextProps extends ViewProps {
  to: LinkableLocation;
  textStyle?: QKTextProps;
  onPressHook?: () => void;
  newTabOnWeb?: boolean;
}

const _LinkText: React.FC<PropsWithChildren<LinkTextProps>> = ({
  to,
  textStyle,
  onPressHook,
  children,
  newTabOnWeb,
  ...rest
}) => {
  const onPress = useLink(to, {
    onPressHook,
    newTabOnWeb,
  });

  return (
    <BasePressable onPress={onPress} {...rest}>
      <Text $underlined {...textStyle}>
        {children}
      </Text>
    </BasePressable>
  );
};

export const LinkText = React.memo(
  _LinkText,
  // ensure textStyle object does not trigger re-render every time
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);
LinkText.displayName = "LinkText";
