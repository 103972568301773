import React, { PropsWithChildren } from "react";
import { View } from "react-native";
import EventEmitter from "events";

export const TouchDetector = new EventEmitter();

export const TouchDetectorView: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
      onStartShouldSetResponderCapture={(e) => {
        const { pageX, pageY, timestamp } = e.nativeEvent;
        TouchDetector.emit("touch", {
          pageX,
          pageY,
          timestamp,
        } satisfies TouchDetectorTouchEvent);
        return false;
      }}
    >
      {children}
    </View>
  );
};

export interface TouchDetectorTouchEvent {
  pageX: number;
  pageY: number;
  timestamp: number;
}
