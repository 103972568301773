import { CustomItemManageView } from "@app/components/item/components/custom/edit/CustomItemManageView";
import { ItemBaseProps } from "@app/components/item/components/itemContainer";
import React, { useEffect, useRef } from "react";
import { apiRequest } from "@app/util/client";
import { CustomItemV1Response } from "@app/components/item/components/custom/types";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import { useV1View } from "@app/components/item/components/custom/v1/useV1View";
import { ItemRenderData } from "@app/types/itemRenderData";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { ItemDetail } from "@questmate/openapi-spec";

type CustomItemV1EditViewProps = Pick<
  ItemBaseProps,
  "item" | "onItemChange"
> & {
  viewMode: "EDIT" | "READ_ONLY";
  inlineNodePortalName: string;
};
export const CustomItemV1ManageView: React.FC<CustomItemV1EditViewProps> = ({
  item,
  onItemChange,
  viewMode,
  inlineNodePortalName,
}) => {
  const itemRef = useRef(item);
  itemRef.current = item;

  const renderConfigView = (configState: Record<string, unknown>) => {
    if (!scriptRef.current || scriptRef.current.trim() === "") {
      return Promise.resolve(undefined);
    }
    return executeCustom(itemRef.current.prototype.id, {
      script: scriptRef.current,
      method: "renderConfig",
      params: [configState],
    });
  };

  const renderRunViewPreview = (
    runViewPreviewState: Record<string, unknown>
  ) => {
    if (!scriptRef.current || scriptRef.current.trim() === "") {
      return Promise.resolve(undefined);
    }
    return executeCustom(itemRef.current.prototype.id, {
      script: scriptRef.current,
      method: "renderFillout",
      params: [runViewPreviewState, configViewStateRef.current],
    });
  };
  const [configViewState, setConfigViewState, configViewStateRef] =
    useSyncedState(item.data, {
      onLocalChange: (newState) => {
        onItemChange!(setItemData(itemRef.current, newState));
      },
    });

  const configView = useV1View(renderConfigView, item.data, setConfigViewState);
  const runViewPreview = useV1View(renderRunViewPreview);

  useEffect(() => {
    void runViewPreview.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configViewState]);

  useEffectOnce(() => {
    void configView.reload().then(() => runViewPreview.reload());
  });

  const [script, setScript, scriptRef] = useSyncedState(
    (itemRef.current.prototype as ItemDetail).customScript || "",
    {
      onLocalChange: (newScript) => {
        onItemChange?.(setItemScript(itemRef.current, newScript));
      },
      onParentChange: () => {
        void configView.reload().then(() => runViewPreview.reload());
      },
    }
  );

  return (
    <CustomItemManageView
      viewMode={viewMode}
      script={script}
      onChangeScript={setScript}
      requestedApps={configView.rawResponse?.requestedApps || []}
      configView={configView}
      runViewPreview={runViewPreview}
      inlineNodePortalName={inlineNodePortalName}
      isCompletionAction={false}
    />
  );
};

async function executeCustom(itemId: string, body: unknown) {
  const questId = itemId.split(":")[0];
  return apiRequest<CustomItemV1Response>(
    "POST",
    `/quests/${questId}/items/${itemId}/custom`,
    body
  );
}

function setItemData(
  currentItem: ItemRenderData,
  updatedData: Partial<ItemRenderData["data"]>
) {
  return {
    ...currentItem,
    data: updatedData,
    version: currentItem.version + 1,
  };
}

function setItemScript(currentItem: ItemRenderData, customScript: string) {
  return {
    ...currentItem,
    prototype: {
      ...currentItem.prototype,
      customScript,
    },
    version: currentItem.version + 1,
  };
}
