import mainReducer from "./main";
import authReducer from "./auth";
import publicQuestAuthReducer from "@app/store/publicQuestAuth";
import uiReducer from "@app/store/UI";
import {
  combineReducers,
  configureStore,
  Store,
  Tuple,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistAuthReducer,
  persistMainReducer,
  persistPublicQuestAuthReducer,
  persistStore,
  persistUIReducer,
} from "@app/store/persistConfiguration";
import { MutableRefObject, useCallback, useRef } from "react";
import { cacheReducer } from "@app/store/cache";
import { pollingReducer } from "@app/store/polling";
import { sentry } from "@app/util/sentry";
import { Action, Middleware, Reducer } from "redux";
import { ENV } from "@app/config/env";

const rootReducer = combineReducers({
  main: persistMainReducer(mainReducer),
  auth: persistAuthReducer(authReducer),
  publicQuestAuth: persistPublicQuestAuthReducer(publicQuestAuthReducer),
  polling: pollingReducer,
  ui: persistUIReducer(uiReducer),
  cache: cacheReducer,
});

const actionPerformanceDebuggerMiddleware: Middleware =
  (_api) => (next) => (action) => {
    const start = Date.now();
    next(action);
    const end = Date.now();
    const duration = end - start;
    if (duration > 10) {
      console.warn(
        `Action ${(action as { type: string }).type} took ${duration}ms`
      );
    }
  };

/**
 * For testing purposes
 */
export const setupStore = (preloadedState?: AppState): Store<AppState> => {
  return configureStore({
    devTools: process.env.NODE_ENV !== "production",

    middleware: (_getDefaultMiddleware) =>
      ENV.logLevels.redux.dispatchPerformance === "debug"
        ? new Tuple(actionPerformanceDebuggerMiddleware)
        : new Tuple(),
    reducer: rootReducer,
    preloadedState,
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers()
        // .concat(myEnhancer)
        .concat(
          sentry.createReduxEnhancer({
            // TODO: consider what portions of the state would be useful to send to Sentry
            // For now this would only send information on the actions that were dispatched
            stateTransformer: () => null,
            actionTransformer: (action) => {
              if (
                actionTypeStartsWith(action, [
                  "cache",
                  "publicQuestAuth",
                  "auth",
                  "persist",
                ])
              ) {
                return {
                  type: action.type,
                  payload: "redacted",
                };
              }
              return action;
            },
          })
        ),
  });
};

type ReduxPersistorActionTypePrefix = "persist";
type RootKeys = typeof rootReducer extends Reducer<infer S> ? keyof S : never;

type ActionPrefix = ReduxPersistorActionTypePrefix | RootKeys;

function actionTypeStartsWith(
  action: Action,
  actionTypePrefixes: ActionPrefix[]
): boolean {
  for (const key of actionTypePrefixes) {
    if ((action.type || "").startsWith(key)) {
      return true;
    }
  }
  return false;
}

export const store = setupStore();

export const reduxPersistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const useAppSelector_debug = (
  ...args: Parameters<TypedUseSelectorHook<AppState>>
) => {
  const stackRef = useRef<string | null>(null);
  if (stackRef.current === null) {
    stackRef.current = new Error().stack!;
  }

  const [selector, ...rest] = args;
  const debugSelector = useCallback(
    (...args: Parameters<typeof selector>) => {
      const start = Date.now();
      const result = selector(...args);
      const end = Date.now();
      if (end - start > 100) {
        console.warn(
          `Selector took too long [${end - start}ms]`,
          selector.name,
          "\nHook Stack Trace:",
          stackRef.current,
          "\nSelector Stack Trace:",
          new Error().stack
        );
      }
      return result;
    },
    [selector]
  );

  return useSelector(debugSelector, ...rest);
};

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector = (
  ENV.logLevels.redux.selectorPerformance === "debug"
    ? useAppSelector_debug
    : useSelector
) as TypedUseSelectorHook<AppState>;

export const useSelectorRef = <TSelected>(
  selector: (state: AppState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): [TSelected, MutableRefObject<TSelected>] => {
  const result = useSelector(selector, equalityFn);
  const ref = useRef(result);
  ref.current = result;
  return [result, ref];
};
