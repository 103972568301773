import { AssignmentListItem } from "@questmate/openapi-spec";

export const getRewardText = (
  rewards: AssignmentListItem["formPrototype"]["rewards"]
): string => {
  const tremendousAmounts = {} as { [key: string]: number };
  rewards
    ?.filter((reward) => reward.type.startsWith("TREMENDOUS_"))
    .forEach((reward) => {
      const currencyCode = reward.config?.currencyCode;
      const amount = reward.config?.amount;
      if (typeof amount === "number" && typeof currencyCode === "string") {
        if (tremendousAmounts[currencyCode]) {
          tremendousAmounts[currencyCode] += amount;
        } else {
          tremendousAmounts[currencyCode] = amount;
        }
      }
    });

  return Object.keys(tremendousAmounts)
    .map((currencyCode) => `${tremendousAmounts[currencyCode]} ${currencyCode}`)
    .join(", ");
};
