import React, { useCallback } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { useFocusableRef } from "@app/util/focus";
import { ShortAnswerComponentModel } from "@app/components/item/components/custom/types";
import TextInput from "@app/components/questkit/textInput";

interface CustomItemShortAnswerComponentProps {
  component: ShortAnswerComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemShortAnswerComponent: React.FC<
  CustomItemShortAnswerComponentProps
> = ({ component, readOnly }) => {
  const onChange = useCallback(
    (newValue: string) => {
      component.onChange(newValue);
    },
    [component]
  );

  return (
    <ShortAnswerContainer>
      <Text size="medium">{component.title || "Short Answer"}</Text>
      <TextInput
        onChangeText={onChange}
        value={component.value}
        placeholder={component.placeholder}
        secureTextEntry={component.secure}
        editable={!(component.readOnly || readOnly)}
        // loading={component.loading}
      />
    </ShortAnswerContainer>
  );
};

const ShortAnswerContainer = styled.View`
  display: flex;
`;
