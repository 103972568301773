import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  LayoutChangeEvent,
  StyleSheet,
} from "react-native";
import styled from "styled-components/native";

import Text from "../questkit/text";
import Icon from "../icon";
import { SnackBar } from "@app/components/snackbar";
import { SideBarSafeArea } from "@app/navigation/sideBar/SideBarSafeArea";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { EdgeInsets } from "react-native-safe-area-context/src/SafeArea.types";
import { useIsFirstRender } from "@app/util/useIsFirstRender";
import { Shadow } from "react-native-shadow-2";
import { TouchDetectorView } from "@app/util/TouchDetector";
import { PortalProvider } from "@gorhom/portal";

interface QKModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onLayoutHandler?: (event: LayoutChangeEvent) => void;
  title?: string;
  testID?: string;
  hideHeader?: boolean;
  displayMode?: ModalDisplayMode;
  onDismiss?: () => void;
}

export enum ModalDisplayMode {
  NORMAL,
  FULL_SCREEN,
}

/**
 * Delay to use after clicking a button that transitions to a loading state and success state before then closing the
 * modal.
 * The transition animations of the button can be interrupted by the modal closing and can also be too quick for
 * the user to notice it completed successfully before closing.
 */
export const MODAL_CLOSE_DELAY = 650;

const { FULL_SCREEN, NORMAL } = ModalDisplayMode;
const QKModal: React.FC<PropsWithChildren<QKModalProps>> = ({
  testID,
  showModal: _showModal,
  setShowModal,
  hideHeader,
  children,
  title,
  onLayoutHandler,
  displayMode = NORMAL,
  onDismiss,
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, _setShowModal] = useState(_showModal);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      if (_showModal) {
        _setShowModal(true);
        // try to wait until the modal animation is finished/nearly finished before showing the snackbar message
        setTimeout(() => setShowSnackbar(true), 300);
      } else {
        setShowSnackbar(false);
        // give time for snackbar to be removed before animating to close the modal
        setTimeout(() => _setShowModal(false), 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_showModal]);
  const safeAreaInsets = useSafeAreaInsets();

  const onRequestClose = useCallback(() => setShowModal(false), [setShowModal]);
  return (
    <Modal
      transparent
      onRequestClose={onRequestClose}
      onDismiss={onDismiss}
      animationType="slide"
      visible={Boolean(showModal) || _showModal}
    >
      <PortalProvider shouldAddRootHost={true} rootHostName={"rootPortal"}>
        <TouchDetectorView>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            style={{ flex: 1 }}
          >
            <StyledModalBackground>
              <SideBarSafeArea>
                <StyledSafeAreaView insets={safeAreaInsets}>
                  <ModalTopSpacer />
                  <StyledModal
                    displayMode={displayMode}
                    onLayout={onLayoutHandler}
                    testID={testID ? testID : `${title} modal`}
                  >
                    <InnerRadiusWrapper displayMode={displayMode}>
                      {!hideHeader && (
                        <ModalHeader
                          title={title}
                          setShowModal={setShowModal}
                        />
                      )}
                      <ContentWrapper>{children}</ContentWrapper>
                    </InnerRadiusWrapper>
                  </StyledModal>
                  <ModalBottomSpacer />
                </StyledSafeAreaView>
              </SideBarSafeArea>
            </StyledModalBackground>
          </KeyboardAvoidingView>
          {showSnackbar && <SnackBar />}
        </TouchDetectorView>
      </PortalProvider>
    </Modal>
  );
};

const ModalHeader = ({
  title,
  setShowModal,
}: {
  title?: string;
  setShowModal: (val: boolean) => void;
}) => {
  return (
    <ModalHeaderWrapper>
      <DummyIcon />
      {title && (
        <StyledHeaderText numberOfLines={2} size="mediumBold">
          {title}
        </StyledHeaderText>
      )}
      <Pressable onPress={() => setShowModal(false)}>
        <Icon icon="close" />
      </Pressable>
    </ModalHeaderWrapper>
  );
};
const ContentWrapper = styled.View`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledSafeAreaView = styled.View<{ insets: EdgeInsets }>`
  flex: 1;
  padding-top: ${({ insets }) => insets.top}px;
  padding-bottom: ${({ insets }) => insets.bottom}px;
  padding-left: ${({ insets }) => insets.left}px;
  padding-right: ${({ insets }) => insets.right}px;
`;

const StyledHeaderText = styled(Text)`
  flex: 1;
  text-align: center;
`;

const DummyIcon = styled.View`
  height: 40px;
  width: 40px;
`;
const ModalHeaderWrapper = styled.View`
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-horizontal: 12px;
  height: 24px;
  align-items: center;
`;

const StyledModalBackground = styled.View`
  flex: 1;
  padding: 8px;
`;

const StyledModal = styled.Pressable<{ displayMode: ModalDisplayMode }>`
  background-color: ${({ theme }) => theme.background};
  border-color: ${({ theme }) => theme.primary};
  border-width: ${StyleSheet.hairlineWidth}px;
  border-radius: 15px;
  width: 100%;
  max-height: 100%;
  align-self: center;
  ${Platform.OS === "web" ? `cursor: default` : ``}
  ${({ displayMode }) => (displayMode === FULL_SCREEN ? "height: 90%;" : "")}
  ${({ displayMode }) => (displayMode === FULL_SCREEN ? "max-width: 90%;" : "")}
  ${({ displayMode }) => (displayMode === NORMAL ? "max-width: 540px;" : "")}
`;

const InnerRadiusWrapper = styled(Shadow).attrs<{
  displayMode: ModalDisplayMode;
}>(({ displayMode }) => ({
  stretch: true,
  containerStyle: {
    flexShrink: 1,
    ...(displayMode === FULL_SCREEN ? { height: "100%" } : {}),
  },
  paintInside: true,
}))<{
  displayMode: ModalDisplayMode;
}>`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.background};
  overflow: hidden;
  flex-shrink: 1;
  ${({ displayMode }) => (displayMode === FULL_SCREEN ? "height: 100%;" : "")}
`;

const ModalTopSpacer = styled.View`
  max-height: 121px;
  flex: 1;
`;

const ModalBottomSpacer = styled.View`
  max-height: 93px;
  flex: 1;
`;

export default QKModal;
