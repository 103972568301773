import React from "react";
import { Text } from "@app/components/questkit/text";
import styled from "styled-components/native";
import Icon, { IconIdentifier } from "../icon";
import { ViewProps, ViewStyle } from "react-native";

interface HeaderTextProps {
  title: string;
  icon?: IconIdentifier;
  style?: ViewStyle;
  nativeID?: ViewProps["nativeID"];
}

const HeaderText: React.FC<HeaderTextProps> = ({
  title,
  icon,
  style,
  nativeID,
}) => {
  return (
    <HeaderTextWrap style={style}>
      {icon && <Icon icon={icon} />}
      <StyledText size="large" nativeID={nativeID}>
        {title}
      </StyledText>
    </HeaderTextWrap>
  );
};

const StyledText = styled(Text)``;
//margin-left: 4px;

export const HeaderTextWrap = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  height: 40px;
`;

export default HeaderText;
