import { QMApiError } from "@app/util/client";
import { useState, useCallback, useRef } from "react";
import _ from "lodash";

type UsePromiseResult<ARGS extends unknown[], DATA> = {
  execute: (...args: ARGS) => Promise<DATA>;
  error?: QMApiError;
  data?: DATA;
  isLoading: boolean;
};

type UsePromiseOptions = {
  keepDataOnError?: boolean;
  reThrowErrors?: boolean;
};

const defaultOptions: Required<UsePromiseOptions> = {
  keepDataOnError: false,
  reThrowErrors: true,
};

export const usePromise = <
  ARGS extends unknown[],
  RESULT,
  OPTIONS extends UsePromiseOptions
>(
  promiseFn: (...args: ARGS) => Promise<RESULT>,
  options?: OPTIONS
): UsePromiseResult<
  ARGS,
  OPTIONS["reThrowErrors"] extends false ? RESULT | undefined : RESULT
> => {
  const { keepDataOnError, reThrowErrors } = _.defaults(
    options,
    defaultOptions
  );
  const [error, setError] = useState<QMApiError>();
  const [data, setData] = useState<RESULT>();
  const [inFlightRequestCount, setInFlightRequestCount] = useState(0);
  const promiseFnRef = useRef(promiseFn);
  promiseFnRef.current = promiseFn;

  const execute = useCallback(
    (...args: ARGS) => {
      setInFlightRequestCount((count) => count + 1);
      setError(undefined);
      setData(undefined);

      return promiseFnRef
        .current(...args)
        .then((data) => {
          setData(data);
          setError(undefined);
          return data;
        })
        .catch((error) => {
          setError(error);
          if (!keepDataOnError) {
            setData(undefined);
          }
          if (reThrowErrors) {
            throw error;
          }
        })
        .finally(() => {
          setInFlightRequestCount((count) => count - 1);
        });
    },
    [keepDataOnError, reThrowErrors]
  );

  return {
    execute,
    error,
    data,
    isLoading: inFlightRequestCount > 0,
  } as UsePromiseResult<
    ARGS,
    OPTIONS["reThrowErrors"] extends false ? RESULT | undefined : RESULT
  >;
};
