type MinimumAssignmentData = {
  assignee: {
    displayName: string;
  };
};

export const buildAssigneeNotificationsString = (
  assignments: MinimumAssignmentData[]
): string => {
  const isMultiple = assignments.length !== 1;

  return assignments.length > 0
    ? ` ${
        isMultiple ? "Notifications have" : "A notification has"
      } been sent to ${assignments
        .map((assignment) => assignment.assignee.displayName)
        .join(", ")}.`
    : "";
};
