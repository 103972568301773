// Push notification setup
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Alert, AppState, Platform } from "react-native";
import { apiRequest } from "./client";
import * as Application from "expo-application";
import { store } from "@app/store";
import { setPushNotificationsEnabled } from "@app/store/main";
import { visitLink } from "@app/util/link.utils";

void Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

AppState.addEventListener("change", (nextAppState) => {
  if (nextAppState === "active") {
    if (Platform.OS === "ios") {
      void Notifications.setBadgeCountAsync(0);
    }
  }
});

const registerPushToken = async (): Promise<void> => {
  const token = await Notifications.getExpoPushTokenAsync();

  return new Promise((resolve, reject) => {
    apiRequest("post", "/internal/notifications/pushTokens", {
      token: token.data,
    })
      .then(() => {
        store.dispatch(setPushNotificationsEnabled(true));
        console.log("Successfully registered push token.");
        resolve();
      })
      .catch((error) => {
        store.dispatch(setPushNotificationsEnabled(false));
        console.log(error);
        reject();
      });
  });
};

export const unregisterPushToken = async (): Promise<void> => {
  const token = await Notifications.getExpoPushTokenAsync();
  return apiRequest("delete", "/internal/notifications/pushTokens", {
    token: token.data,
  })
    .then(() => {
      store.dispatch(setPushNotificationsEnabled(false));
      console.log("Successfully unregistered push token.");
    })
    .catch((error) => {
      console.log(error);
    });
};

if (Platform.OS === "android") {
  void Notifications.setNotificationChannelAsync("default", {
    name: "default",
    importance: Notifications.AndroidImportance.MAX,
    vibrationPattern: [0, 250, 250, 250],
    lightColor: "#4417a6",
  });
}

export const requestPermissions = async (): Promise<boolean> => {
  const { status, canAskAgain } = await Notifications.getPermissionsAsync();

  return new Promise((resolve) => {
    if (status === "granted") {
      // Permission granted, register push token on API
      registerPushToken()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          resolve(false);
        });
    } else {
      if (!canAskAgain) {
        // User denied in the past and will need to navigate to settings -> notifications -> questmate
        if (Platform.OS === "ios" && Application.applicationId) {
          visitLink(`app-settings://notification/${Application.applicationId}`);
        }
        resolve(false);
      } else {
        // Ask user for permission
        void Notifications.requestPermissionsAsync().then(
          ({ status: finalStatus }) => {
            if (finalStatus === "granted") {
              registerPushToken()
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  console.log(error);
                  resolve(false);
                });
            }
          }
        );
      }
    }
  });
};

const ensurePushPermissions = async (): Promise<void> => {
  if (Device.isDevice) {
    const { status, canAskAgain } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      const cancelButton = {
        text: "No thanks",
        onPress: async () => {
          console.log("User dismissed push notification dialog");
        },
      };
      const okButton = {
        text: "OK",
        onPress: async () => {
          void requestPermissions();
        },
      };
      if (canAskAgain) {
        Alert.alert(
          "Enable Notifications",
          "We use push notifications for new Quest Assignments & Submissions, as well as Reminders.",
          [cancelButton, okButton]
        );
      }
    } else {
      void registerPushToken();
    }
  }
};

export const isPushNotificationsEnabled = (): boolean => {
  return store.getState().main.pushNotificationsEnabled;
};

export default ensurePushPermissions;
