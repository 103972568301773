import { WebMediaItem } from "@app/types/mediaPicker";
import React, { PropsWithChildren, useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { MediaItemType } from "@questmate/common";

interface UploadWrapperWebProps extends PropsWithChildren {
  uploadFiles: (files: WebMediaItem[]) => void;
  uploadButtonId: string;
}

export const UploadWrapperWeb: React.FC<UploadWrapperWebProps> = ({
  children,
  uploadFiles,
  uploadButtonId,
}) => {
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      _fileRejections: FileRejection[],
      _event: DropEvent
    ) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const reader = new FileReader();
        const file = acceptedFiles[i];
        const mimeType = file.type;
        const mediaItemType = mimeType.startsWith("video/")
          ? MediaItemType.Video
          : mimeType.startsWith("image/")
          ? MediaItemType.Image
          : MediaItemType.Raw;

        reader.addEventListener(
          "load",
          function () {
            uploadFiles([
              {
                uri: reader.result as string,
                type: mediaItemType,
                blob: file,
                name: file.name,
              },
            ]);
          },
          false
        );

        reader.readAsDataURL(file);
      }
    },
    [uploadFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      onClick={() => {
        // do nothing
      }}
    >
      <input
        {...getInputProps()}
        style={{
          display: "none",
        }}
        id={uploadButtonId}
      ></input>
      {children}
    </div>
  );
};
