import React, { useRef } from "react";
import { Text } from "@app/components/questkit/text";
import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";
import Icon from "../icon";
import { Animated, Platform } from "react-native";
import BasePressable from "@app/components/questkit/BasePressable";

interface CardProps {
  title: string;
  balance?: string;
  value?: string;
  footerText?: string;
  onPress?: () => void;
  disabled?: boolean;
}

export const Card: React.FC<CardProps> = ({
  title,
  balance,
  value,
  footerText,
  onPress,
  disabled,
}) => {
  // The animation is often broken on mobile safari, so we deactivated it for now.
  const spinAnim = useRef(
    new Animated.Value(Platform.OS === "web" ? 1 : 0)
  ).current;

  Animated.spring(spinAnim, {
    toValue: 1,
    speed: 0.1,
    useNativeDriver: false,
    isInteraction: false,
  }).start();

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <CardWrapper
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
      disabled={disabled}
    >
      <Animated.View
        style={{
          flex: 1,
          transform: Platform.OS !== "web" ? [{ rotateY: spin }] : [],
        }}
      >
        <CardBackground
          colors={["#ff4b4b", "#ECF238"]}
          locations={[0.3, 1]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}
        />
        <CardContent>
          <Title>
            <StyledText size="large">{title}</StyledText>
          </Title>
          <ValueContent>
            {balance !== undefined && (
              <Balance>
                <StyledText>{balance}</StyledText>
              </Balance>
            )}
            {value !== undefined && (
              <StyledText size="xlarge">{value}</StyledText>
            )}
          </ValueContent>
          <Footer>
            {footerText ? (
              <StyledText numberOfLines={1}>{footerText}</StyledText>
            ) : (
              <Icons>
                <StyledIcon icon="money" />
                <StyledIcon icon="credit-card" />
                <StyledIcon icon="gift" />
                <StyledIcon icon="trophy" />
                <StyledIcon icon="shopping-cart" />
              </Icons>
            )}
          </Footer>
        </CardContent>
      </Animated.View>
    </CardWrapper>
  );
};

const CardContent = styled.View`
  padding: 14px;
  flex: 1;
`;

const Footer = styled.View`
  flex: 1;
  justify-content: flex-end;
`;

const Icons = styled.View`
  flex-direction: row;
`;
const StyledIcon = styled(Icon)`
  color: white;
`;

const ValueContent = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
`;

const Balance = styled.View`
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.primary};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const Title = styled.View`
  flex: 1;
  justify-content: flex-start;
`;

const StyledText = styled(Text)`
  color: white;
`;

const CardBackground = styled(LinearGradient)`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const CardWrapper = styled(BasePressable)`
  height: 220px;
  width: 100%;
  max-width: 380px;
`;
