import { overlapColors } from "@app/themes/Colors.utils";

const corePalette = {
  primary900: "#2D069A",
  primary800: "#4417A6",
  primary700: "#511EAD",
  primary600: "#5F26B6",
  primary500: "#682BBC",
  primary400: "#804DC6",
  primary300: "#966DD0",
  primary200: "#B498DD",
  primary100: "#D2C1EA",
  primary50: "#EDE6F7",
  yellow900: "#F17F00",
  yellow800: "#F0AA00",
  yellow700: "#EEC300",
  yellow600: "#EBDC00",
  yellow500: "#E7EE00",
  yellow400: "#ECF238",
  yellow300: "#EFF462",
  yellow200: "#F3F792",
  yellow100: "#F8FBBE",
  yellow50: "#FCFEE5",
  green900: "#009664",
  green800: "#00B684",
  green700: "#00C793",
  green600: "#00DBA5",
  green500: "#00EBB5",
  green400: "#00F3C0",
  green300: "#2AFBCD",
  green200: "#76FFDD",
  green100: "#B3FEEA",
  green50: "#E1FFF8",
  red900: "#BB0C20",
  red800: "#CA1E2C",
  red700: "#D72633",
  red600: "#E9313A",
  red500: "#F83B3B",
  red400: "#F24E54",
  red300: "#E77176",
  red200: "#F0999C",
  red100: "#FFCDD4",
  red50: "#FFEBEF",
  blue950: "#0b0320", // replace?
  // blue900: "#330ea1",
  // blue800: "#3c04cf",
  // blue700: "#4a00ff",
  // blue600: "#4d0aff",
  // blue500: "#552dff",
  // blue400: "#6554ff",
  // blue300: "#8484ff",
  // blue200: "#b2b5ff",
  // blue100: "#d4d8ff",
  // blue50: "#e8eaff",
  neutral950: "#292929",
  neutral900: "#3d3d3d",
  neutral800: "#464646",
  neutral700: "#525252",
  neutral600: "#656565",
  neutral500: "#7c7c7c",
  neutral400: "#989898",
  neutral300: "#bdbdbd",
  neutral200: "#dcdcdc",
  neutral100: "#efefef",
  white: "#FFFFFF",
} as const;

const d1_darkBlurple = overlapColors(
  corePalette.blue950,
  corePalette.primary700,
  0.16
);
const d5DarkPrimary = overlapColors(corePalette.primary900, "#000000", 0.5);
const derivedColors = {
  // #18073f
  d1_darkBlurple,
  // #230C59
  d2_darkBlurpleLurple: overlapColors(
    d1_darkBlurple,
    corePalette.primary700,
    0.16
  ),
  // #341082
  d3_blurple: overlapColors(corePalette.blue950, corePalette.primary700, 0.5),
  d4_darkPrimary20: overlapColors("#ffffff", d5DarkPrimary, 0.2),
  d4_darkPrimary30: overlapColors("#ffffff", d5DarkPrimary, 0.3),
  d4_darkPrimary40: overlapColors("#ffffff", d5DarkPrimary, 0.4),
  d5_darkPrimary: d5DarkPrimary,
  d6_green50Hover: overlapColors(corePalette.green50, "#000000", 0.02),
  d7_primary50Hover: overlapColors(corePalette.primary50, "#000000", 0.02),
  d8_primary800Hover: overlapColors(corePalette.primary800, "#000000", 0.08),
  d9_primary800_20: overlapColors(
    corePalette.neutral100,
    corePalette.primary800,
    0.12
  ),
} as const;

export const colors = {
  ...corePalette,
  ...derivedColors,
} as const;
