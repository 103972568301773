import React, { useCallback, useEffect } from "react";
import type { CompletionActionComponentProps } from "@app/components/completionAction";
import styled from "styled-components/native";
import { Card } from "@app/components/card";
import Text from "@app/components/questkit/text";
import ListItem from "@app/components/questkit/listItem";
import Amount from "@app/components/questkit/amount";
import {
  DropdownOption,
  DropdownStyle,
  DropdownWithModal,
} from "@app/components/questkit/dropdownWithModal";
import { useApp } from "@app/screens/settings/app";
import { LinkAppPrompt } from "@app/components/completionAction/LinkAppPrompt";
import RewardContainer from "@app/components/completionAction/components/RewardContainer";
import BasePressable from "@app/components/questkit/BasePressable";
import { useLink } from "@app/util/link.utils";
import { noopRequest, useRequest } from "@app/util/client/requests";
import {
  fetchTremendousCampaigns,
  fetchTremendousFundingSources,
} from "@app/util/client/requests/tremendous";

export interface TremendousCampaign {
  id: string;
  name: string;
}

export interface TremendousFundingSource {
  id: string;
  meta: {
    available_cents: number;
  };
}

export const TremendousReward = ({
  item,
  onCompletionActionChange,
  onCompletionActionTouched,
  onCompletionActionValidationContextChange,
  editMode,
}: CompletionActionComponentProps) => {
  const redeemLink = item.data.link;

  const openRedeemLink = useLink(redeemLink);

  return (
    <>
      {!editMode && item.status === "PENDING" && (
        <TremendousWrapper>
          <Card
            title={"Processing Reward..."}
            value={undefined}
            onPress={() => {
              // do nothing
            }}
            disabled={true}
          />
        </TremendousWrapper>
      )}
      {!editMode && item.status === "COMPLETED" && (
        <TremendousWrapper>
          <Card
            title={"Value Card"}
            value={`${item.data.amount || "0"} ${item.data.currencyCode}`}
            onPress={openRedeemLink}
            disabled={!redeemLink}
          />
          {redeemLink && (
            <RedeemTextPressable onPress={openRedeemLink}>
              <RedeemText>Redeem Reward</RedeemText>
            </RedeemTextPressable>
          )}
        </TremendousWrapper>
      )}
      {editMode && (
        <EditTremendousReward
          item={item}
          onCompletionActionChange={onCompletionActionChange}
          onCompletionActionTouched={onCompletionActionTouched}
          onCompletionActionValidationContextChange={
            onCompletionActionValidationContextChange
          }
        />
      )}
    </>
  );
};

const EditTremendousReward = ({
  item,
  onCompletionActionChange,
  onCompletionActionTouched,
  onCompletionActionValidationContextChange,
}: Pick<
  CompletionActionComponentProps,
  | "item"
  | "onCompletionActionChange"
  | "onCompletionActionTouched"
  | "onCompletionActionValidationContextChange"
>) => {
  const { app, link, isLinking, isLoading } = useApp("tremendous");

  useEffect(() => {
    onCompletionActionValidationContextChange?.({
      appLinked: app?.linked,
    });
  }, [onCompletionActionValidationContextChange, app?.linked]);

  const {
    data: campaignData,
    isValidating: isCampaignsLoading,
    refresh: refreshCampaigns,
  } = useRequest(app?.linked ? fetchTremendousCampaigns() : noopRequest());
  const campaigns = campaignData?.campaigns || [];

  const {
    data: fundingSourcesData,
    isValidating: isFundingSourcesLoading,
    refresh: refreshFundingSources,
  } = useRequest(app?.linked ? fetchTremendousFundingSources() : noopRequest());
  const fundingSources = fundingSourcesData?.funding_sources || [];

  const linkApp = useCallback(() => {
    void link().then((apiAppAuthService) => {
      if (apiAppAuthService.linked) {
        void refreshCampaigns();
        void refreshFundingSources();
      } else {
        onCompletionActionTouched?.();
      }
    });
  }, [
    link,
    refreshCampaigns,
    refreshFundingSources,
    onCompletionActionTouched,
  ]);

  useEffect(() => {
    if (!item.data.currencyCode) {
      onCompletionActionChange({
        ...item,
        data: {
          ...item.data,
          currencyCode: "USD",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.data.currencyCode]);

  return (
    // eslint-disable-next-line deprecation/deprecation
    <RewardContainer
      blockNode={
        app?.linked ? (
          <TremendousWrapper>
            <Card
              title={"Value Card"}
              value={`${item.data.amount || "0"} ${item.data.currencyCode}`}
            />
            <TremendousSettings>
              <DropdownWrapper>
                <DropdownWithModal
                  dropdownStyle={DropdownStyle.STANDARD}
                  placeholderIcon="money"
                  optionNoun={"Campaign"}
                  optionPluralNoun={"Campaigns"}
                  loadingOptions={isLoading || isLinking || isCampaignsLoading}
                  options={campaigns.map((campaign) => {
                    return {
                      value: campaign.id,
                      name: campaign.name,
                      icon: "money",
                    } as DropdownOption;
                  })}
                  selectedOption={item.data.campaignId}
                  setSelectedOption={(campaignId) => {
                    onCompletionActionChange({
                      ...item,
                      data: {
                        ...item.data,
                        campaignId: campaignId,
                      },
                    });
                    onCompletionActionTouched?.();
                  }}
                />
              </DropdownWrapper>

              <DropdownWrapper>
                <DropdownWithModal
                  dropdownStyle={DropdownStyle.STANDARD}
                  placeholderIcon="money"
                  optionNoun={"Funding Source"}
                  optionPluralNoun={"Funding Sources"}
                  loadingOptions={
                    isLoading || isLinking || isFundingSourcesLoading
                  }
                  options={fundingSources.map((fundingSource) => {
                    return {
                      value: fundingSource.id,
                      name: `${fundingSource.id.substring(0, 6)}.. - $${(
                        fundingSource.meta.available_cents / 100
                      ).toFixed(2)}`,
                      icon: "money",
                    } as DropdownOption;
                  })}
                  selectedOption={item.data.fundingSourceId}
                  setSelectedOption={(fundingSourceId) => {
                    onCompletionActionChange({
                      ...item,
                      data: {
                        ...item.data,
                        fundingSourceId: fundingSourceId,
                      },
                    });
                  }}
                />
              </DropdownWrapper>

              <StyledListItem
                text="Amount"
                actionComponent={
                  <Amount
                    amount={item.data.amount || 0}
                    onChangeAmount={(newAmount) => {
                      onCompletionActionChange({
                        ...item,
                        data: {
                          ...item.data,
                          amount: newAmount,
                        },
                      });
                    }}
                    minAmount={0}
                    maxAmount={999}
                  />
                }
              />
            </TremendousSettings>
          </TremendousWrapper>
        ) : (
          <LinkAppPrompt
            buttonText="Link Tremendous Account"
            infoText="To issue Rewards, you must first link your Tremendous account."
            link={linkApp}
            loading={isLinking || isLoading}
          />
        )
      }
      title="Reward"
    />
  );
};

const TremendousSettings = styled.View`
  margin-top: 16px;
  width: 100%;
  max-width: 380px;
`;

const StyledListItem = styled(ListItem)`
  margin-bottom: 16px;
`;

const DropdownWrapper = styled.View`
  margin-bottom: 16px;
`;

const TremendousWrapper = styled.View`
  align-items: center;
`;

const RedeemText = styled(Text)`
  text-decoration-line: underline;
`;

const RedeemTextPressable = styled(BasePressable)`
  flex: 1;
  align-items: center;
  margin: 16px;
`;
