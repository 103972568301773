import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserPicker, UserPickerController } from "./UserList/UserPicker";
import ListItem from "@app/components/questkit/listItem";
import Switch from "@app/components/questkit/switch";
import { selectReviewersWithUserByStartConfigurationId } from "@app/store/cache/reviewers";
import { useAppSelector } from "@app/store";
import { updateQuestReviewers } from "@app/util/client/requests/reviewers";
import { sentry } from "@app/util/sentry";
import {
  isUserEntry,
  UserListEvents,
} from "@app/components/questkit/UserList/UserList.controller";
import { Analytics } from "@app/analytics";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { StandardUserEntry } from "@app/components/questkit/UserList/StandardUserEntry";
import isEqual from "react-fast-compare";

interface ReviewerPickerProps {
  questPrototypeId: string;
  startConfigurationId: string;
  readOnly?: boolean;
  readOnlyMessage?: string;
  autoSave?: boolean;
}

export type ReviewerPickerController =
  | {
      requiresReview: true;
      userPickerRef: RefObject<UserPickerController>;
    }
  | {
      requiresReview: false;
    };

export const ReviewerPicker = React.forwardRef<
  ReviewerPickerController,
  ReviewerPickerProps
>(
  (
    {
      questPrototypeId,
      startConfigurationId,
      readOnly = false,
      readOnlyMessage,
      autoSave = false,
    },
    parentRef
  ) => {
    const snackbar = useContext(SnackbarContext);
    const reviewers = useAppSelector((state) => {
      if (!startConfigurationId) {
        console.error("Missing start configuration id in reviewer picker.");
        return [];
      }
      return selectReviewersWithUserByStartConfigurationId(
        state,
        startConfigurationId
      )!;
    }, isEqual);
    const [requiresReview, setRequiresReview] = useState<boolean>(
      reviewers.length > 0
    );

    const lastReviewerIdsFromAPI = useRef<string[]>(
      reviewers.map(({ id }) => id).sort()
    );
    useEffect(() => {
      const currentReviewerIdsFromAPI = reviewers.map(({ id }) => id).sort();
      if (
        currentReviewerIdsFromAPI.join() ===
        lastReviewerIdsFromAPI.current.join()
      ) {
        return;
      }
      lastReviewerIdsFromAPI.current = reviewers.map(({ id }) => id);

      const hasReviewers = reviewers.length > 0;
      if (hasReviewers !== requiresReview) {
        setRequiresReview(hasReviewers);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewers]);

    const userPickerRef = useRef<UserPickerController>(null);
    useImperativeHandle(
      parentRef,
      () => {
        if (requiresReview) {
          return {
            requiresReview: true as const,
            userPickerRef,
          };
        } else {
          return {
            requiresReview: false as const,
          };
        }
      },
      [requiresReview]
    );

    const userPickerController = userPickerRef.current;
    useEffect(() => {
      const addListener = (event: UserListEvents["add"]) => {
        event.entriesAdded.forEach((entry) => {
          Analytics.trackEvent("Add Reviewer", {
            userIsReviewer:
              isUserEntry(entry) && entry.userId === getLoggedInUserId(),
          });
        });
      };
      const removeListener = (event: UserListEvents["remove"]) => {
        event.entriesRemoved.forEach((entry) => {
          Analytics.trackEvent("Remove Reviewer", {
            userIsReviewer:
              isUserEntry(entry) && entry.userId === getLoggedInUserId(),
          });
        });
      };
      userPickerController?.on("add", addListener);
      userPickerController?.on("remove", removeListener);
      return () => {
        userPickerController?.off("add", addListener);
        userPickerController?.off("remove", removeListener);
      };
    }, [userPickerController]);

    const users = useMemo(
      () =>
        reviewers.map((reviewer) => ({
          userId: reviewer.user!.id,
        })),
      [reviewers]
    );

    const onSwitch = useCallback(async () => {
      if (readOnly) {
        snackbar.sendMessage(
          readOnlyMessage || "Option cannot be changed.",
          SnackbarSeverity.WARNING
        );
        return;
      }

      const nextValue = !requiresReview;

      if (!nextValue) {
        // trying to turn off reviewers
        const usersSelected = await userPickerRef.current!.flush();
        if (usersSelected.length !== 0) {
          snackbar.sendMessage(
            "Remove reviewers before disabling review.",
            SnackbarSeverity.WARNING
          );
          return;
        }
      }

      setRequiresReview(nextValue);
    }, [readOnly, readOnlyMessage, requiresReview, snackbar]);
    const onChange = useCallback(
      (userIds: string[]) => {
        if (!autoSave) {
          return;
        }

        updateQuestReviewers(
          questPrototypeId,
          reviewers,
          userIds.map((id) => ({ id }))
        )
          .then((result) => {
            if (result.errors.length > 0) {
              sentry.addBreadcrumb({
                message: "Errors saving reviewers",
                data: { errors: result.errors },
              });
              result.errors.forEach((error) => {
                if (error.action === "ADD") {
                  userPickerRef.current?.remove([{ userId: error.userId }]);
                } else if (error.action === "REMOVE") {
                  userPickerRef.current?.add([{ userId: error.userId }]);
                }
              });
              throw new Error("Failed to save reviewers");
            }
          })
          .catch((e) => {
            snackbar.sendMessage(
              `We unfortunately couldn't save your reviewers. Please try again later.`,
              SnackbarSeverity.WARNING
            );
            sentry.captureException(e);
            throw e;
          });
      },
      [autoSave, questPrototypeId, reviewers, snackbar]
    );
    return (
      <>
        <ListItem
          text="Submissions Require Review"
          icon="hand"
          actionComponent={
            <Switch
              switchOn={requiresReview}
              onSwitch={onSwitch}
              locked={readOnly}
            />
          }
        />
        {requiresReview && (
          <UserPicker
            disabled={readOnly}
            users={users}
            ref={userPickerRef}
            onChange={onChange}
            renderUserEntry={StandardUserEntry}
          />
        )}
      </>
    );
  }
);
ReviewerPicker.displayName = "ReviewerPicker";
