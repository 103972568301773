import { Apps } from "@questmate/openapi-spec";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";

type AppAuth = UnionToIntersection<APIAppAuth>;

const appAuthAdapter = createEntityAdapter<AppAuth>();

export const {
  selectById: selectAppAuthById,
  selectEntities: selectAllAppAuthsById,
  selectAll: selectAllAppAuths,
} = appAuthAdapter.getSelectors<AppState>((state) => state.cache.appAuths);

const slice = createSlice({
  name: "cache/appAuths",
  initialState: appAuthAdapter.getInitialState(),
  reducers: {
    appAuthLoaded: (
      state,
      action: PayloadAction<Apps.AuthDetail.ResponseBody>
    ) => {
      appAuthAdapter.upsertOne(state, mapAppAuth(action.payload));
    },
    appAuthListLoaded: (
      state,
      action: PayloadAction<Apps.AuthList.ResponseBody>
    ) => {
      appAuthAdapter.upsertMany(
        state,
        action.payload.map((app) => mapAppAuth(app))
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) => appAuthAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

export const { appAuthListLoaded, appAuthLoaded } = slice.actions;

type APIAppAuth =
  | Apps.AuthDetail.ResponseBody
  | Apps.AuthList.ResponseBody[number];

const mapAppAuth = createDataMapper<APIAppAuth, AppAuth>()(
  [
    "id",
    "name",
    "linked",
    "clientId",
    "authorizationUrl",
    "audience",
    "additionalAuthParams",
    "linkingStrategy",
    "scopes",
  ],
  {}
);
