import React, { useMemo } from "react";
import { useAppSelector } from "@app/store";
import { selectLoggedInUser } from "@app/store/cache/users";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import linkingConfig from "@app/navigation/linkingConfig";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { logOut } from "@app/screens/LogoutScreen";
import { Text } from "@app/components/questkit/text";
import styled from "styled-components/native";
import isEqual from "react-fast-compare";

type AccessDeniedViewProps = {
  loginId?: string;
  reload: () => void;
};
export const AccessDeniedView: React.FC<AccessDeniedViewProps> = ({
  loginId,
  reload,
}) => {
  const loggedInUser = useAppSelector(selectLoggedInUser, isEqual);

  const userIsLoggedInButLoginIdInURLIsDifferent =
    (loggedInUser?.email || loggedInUser?.phone) &&
    loginId &&
    loggedInUser.email !== loginId &&
    loggedInUser.phone !== loginId;

  const navigation = useAppNavigation();
  const rootState = navigation.getParent()?.getState() ?? navigation.getState();

  const pathBackToHere = useMemo(() => {
    const pathFromState = linkingConfig.getPathFromState(rootState);

    const urlWithoutLoginId = new URL(pathFromState, "https://www.example.com");
    urlWithoutLoginId.searchParams.delete("loginId");
    return urlWithoutLoginId.pathname + urlWithoutLoginId.search;
  }, [rootState]);

  return (
    <PlaceholderView
      testID="access-denied-view"
      text="Access Denied 😬"
      additionalInfo={
        <>
          <CenteredText size="medium">
            You are currently logged in as:
          </CenteredText>
          <Text size="mediumBold">
            {loggedInUser?.email || loggedInUser?.phone}
          </Text>
        </>
      }
      actions={[
        {
          type: "primary",
          text: `Sign in ${
            userIsLoggedInButLoginIdInURLIsDifferent
              ? `as ${loginId}`
              : "with another account"
          }`,
          onPress: () => {
            logOut({
              reason: "Access Denied. Switching to suggested account.",
              screenName: "Login",
              screenParams: {
                loginId: userIsLoggedInButLoginIdInURLIsDifferent
                  ? loginId
                  : undefined,
                url: pathBackToHere,
              },
            });
          },
        },
        ...(userIsLoggedInButLoginIdInURLIsDifferent
          ? [
              {
                type: "secondary" as const,
                text: "Sign in with another account",
                onPress: () => {
                  logOut({
                    reason: "Access Denied. Switching to another account.",
                    screenName: "Login",
                    screenParams: {
                      loginId: undefined,
                      url: pathBackToHere,
                    },
                  });
                },
              },
            ]
          : []),
        {
          type: "secondary",
          text: "Try again",
          onPress: reload,
        },
      ]}
    />
  );
};

const CenteredText = styled(Text)`
  text-align: center;
`;
