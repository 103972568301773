import React, { useCallback } from "react";
import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import MediaSlider from "@app/components/questkit/mediaSlider";
import { ItemRenderData } from "@app/types/itemRenderData";
import { MediaItem } from "@questmate/common";

const updateMediaItems = (
  item: ItemRenderData,
  mediaItems: MediaItem[]
): ItemRenderData => {
  return {
    ...item,
    data: {
      ...item.data,
      mediaItems: mediaItems,
    },
    version: item.version + 1,
  };
};

export const FileUploadItem: React.FC<ItemBaseProps> = (props) => {
  const { item, onItemChange, onItemValidate } = props;

  const onMediaItemsChange = useCallback(
    (mediaItems: MediaItem[]) => {
      onItemChange?.(updateMediaItems(item, mediaItems));
      onItemValidate?.();
    },
    [item, onItemChange, onItemValidate]
  );

  return (
    <ItemContainerWrapper
      {...props}
      blockNode={
        <MediaSlider
          readOnly={props.readOnly ?? false}
          editMode={props.editMode}
          mediaItems={props.item.data.mediaItems}
          onMediaItemsChange={onMediaItemsChange}
          accessibilityLabelledBy={`item-name-label-${props.item.prototype.id}`}
        />
      }
    />
  );
};
