import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import {
  QuestPrototypeDetail,
  TemplateDetails,
  TemplateListItem,
  WorkspaceDetails,
  WorkspaceListItem,
  WorkspaceMembershipListItem,
} from "@questmate/openapi-spec";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { userLogout } from "@app/store/auth";
import { UnionToIntersection } from "@questmate/common";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { questLoaded, questsLoaded } from "@app/store/cache/quests";
import {
  selectUsersWorkspaceIds,
  selectWorkspaceMembershipsForWorkspace,
  workspaceMemberDeleted,
  workspaceMembersAdded,
} from "@app/store/cache/workspaceMemberships";
import { selectAllUsersById } from "@app/store/cache/users";

type Workspace = Omit<
  UnionToIntersection<APIWorkspace>,
  "memberships" | "team"
> & {
  membershipIds?: string[];
  teamId?: string | null; // TODO: FINISH_TEAMS_FEATURE - Remove optional and hopefully also null at some point.
};
const workspaceAdapter = createEntityAdapter<Workspace>({});

export const {
  selectById: selectWorkspaceById,
  selectEntities: selectAllWorkspacesById,
} = workspaceAdapter.getSelectors<AppState>((state) => state.cache.workspaces);
export const selectUsersWorkspaces = createSelector(
  [(state) => selectUsersWorkspaceIds(state), selectAllWorkspacesById],
  (usersWorkspaceIds, allWorkspaces): Workspace[] => {
    return usersWorkspaceIds
      .map((id) => allWorkspaces[id])
      .filter(Boolean) as Workspace[];
  }
);

export const selectWorkspaceWithMemberships = createSelector(
  [
    (state, workspaceId) => selectWorkspaceById(state, workspaceId),
    (state, workspaceId) =>
      selectWorkspaceMembershipsForWorkspace(state, workspaceId),
    (state) => selectAllUsersById(state),
  ],
  (workspace, workspaceMemberships, usersById) => {
    if (!workspace) {
      return workspace;
    }
    return {
      ...workspace,
      memberships: workspace.membershipIds?.map((workspaceMembershipId) => {
        const workspaceMembership = workspaceMemberships.find(
          ({ id }) => id === workspaceMembershipId
        );
        if (!workspaceMembership) {
          throw new Error("Expected workspace membership to exist in cache");
        }
        return {
          ...workspaceMembership,
          id: workspaceMembershipId,
          user: usersById[workspaceMembership.userId]!,
        };
      }),
    };
  }
);

const slice = createSlice({
  name: "cache/workspaces",
  initialState: workspaceAdapter.getInitialState(),
  reducers: {
    workspacesLoaded: (
      state,
      action: PayloadAction<{ userId: string; workspaces: WorkspaceListItem[] }>
    ) =>
      workspaceAdapter.upsertMany(
        state,
        action.payload.workspaces.map(mapWorkspace)
      ),
    workspaceLoaded: (state, action: PayloadAction<WorkspaceDetails>) =>
      workspaceAdapter.setOne(state, mapWorkspace(action.payload)),
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) => workspaceAdapter.removeAll(state));
    builder.addCase(questPrototypeLoaded, (state, action) => {
      const workspace = action.payload?.quest?.workspace;
      if (!workspace) {
        return;
      }

      workspaceAdapter.upsertOne(state, mapWorkspace(workspace));
    });
    builder.addCase(questsLoaded, (state, action) =>
      workspaceAdapter.upsertMany(
        state,
        action.payload
          .filter(
            (quest): quest is TemplateListItem =>
              !!(quest as TemplateListItem).workspace
          )
          .map((quest) => mapWorkspace(quest.workspace!))
      )
    );
    builder.addCase(
      questLoaded,
      (state, action: PayloadAction<TemplateDetails>) => {
        if (!action.payload.workspace) {
          return;
        }
        workspaceAdapter.upsertOne(
          state,
          mapWorkspace(action.payload.workspace)
        );
      }
    );
    builder.addCase(
      workspaceMembersAdded,
      (
        state,
        action: PayloadAction<{
          workspaceId: string;
          memberships: WorkspaceMembershipListItem[];
        }>
      ) => {
        workspaceAdapter.updateOne(state, {
          id: action.payload.workspaceId,
          changes: {
            membershipIds: [
              ...(state.entities[action.payload.workspaceId]!.membershipIds ||
                []),
              ...action.payload.memberships.map(({ id }) => id),
            ],
          },
        });
      }
    );
    builder.addCase(
      workspaceMemberDeleted,
      (
        state,
        action: PayloadAction<{
          workspaceId: string;
          membershipId: string;
        }>
      ) => {
        workspaceAdapter.updateOne(state, {
          id: action.payload.workspaceId,
          changes: {
            membershipIds: (
              state.entities[action.payload.workspaceId]!.membershipIds || []
            ).filter(
              (membershipId) => membershipId !== action.payload.membershipId
            ),
          },
        });
      }
    );
  },
});

const reducer = slice.reducer;
export default reducer;

export const { workspacesLoaded, workspaceLoaded } = slice.actions;

type APIWorkspace =
  | Exclude<TemplateListItem["workspace"], null>
  | Exclude<TemplateDetails["workspace"], null>
  | Exclude<QuestPrototypeDetail["quest"]["workspace"], null | undefined>
  | WorkspaceListItem
  | WorkspaceDetails;
const mapWorkspace = createDataMapper<APIWorkspace, Workspace>()(
  ["id", "name"],
  {
    memberships: (memberships) => ({
      membershipIds: memberships.map(({ id }) => id),
    }),
    team: (team) => ({
      teamId: team?.id || null,
    }),
  }
);
