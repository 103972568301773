import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { IconIdentifier, ValidIconName } from "@app/components/icon";
import Text from "@app/components/questkit/text";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { HoverProps, useHover } from "@app/util/useHover";
import { ProgressBar } from "@app/components/questkit/progressBar";
import QKIcon from "@app/components/questkit/icon";
import { OnPress } from "@app/components/questkit/BasePressable";
import isEqual from "react-fast-compare";

export type RunListItemMetadata = {
  icon: IconIdentifier;
  iconBadge?: React.ReactNode;
  text: string | React.ReactNode;
};

export interface QuestRunListItemData {
  id: string;
  key: string; // used for the React key in SectionList
  name: string;
  statusPills: {
    text: string;
    color: string;
  }[];
  metadata: RunListItemMetadata[];
  progressCount: number;
  progressTotal: number;
  progressIcon?: ValidIconName;
}

export interface SubquestRunListItemData {
  metadata: RunListItemMetadata[];
  progressCount?: number;
  progressTotal?: number;
  progressIcon?: ValidIconName;
}

type QuestRunListItemProps = (
  | QuestRunListItemData
  | SubquestRunListItemData
) & {
  onPress: OnPress;
  style?: StyleProp<ViewStyle>;
};

const _QuestRunListItem: React.FC<QuestRunListItemProps> = (props) => {
  const {
    progressTotal,
    progressCount,
    progressIcon,
    metadata,
    onPress,
    style,
  } = props;

  const { hoverProps } = useHover();
  return (
    <QuestRunListItemContainer
      testID="quest-run-list-item"
      activeOpacity={0.8}
      style={style}
      onPress={onPress}
      {...hoverProps}
    >
      {"name" in props && props.name ? (
        <QuestTitleWrapper>
          <QuestTitleName>
            <NameText
              size="mediumBold"
              numberOfLines={2}
              testID="quest-name-text"
            >
              {props.name}
            </NameText>
          </QuestTitleName>
          <QuestStatusGroup>
            {props.statusPills.map((pill, index) => (
              <QuestStatusPill key={index} color={pill.color}>
                <QuestStatusText testID="quest-status-text">
                  {pill.text}
                </QuestStatusText>
              </QuestStatusPill>
            ))}
          </QuestStatusGroup>
        </QuestTitleWrapper>
      ) : (
        <EmptyTitleSpacer />
      )}
      {metadata.map(({ icon, iconBadge, text }, index) => (
        <MetadataRow key={index}>
          <MetadataIconWrapper>
            <MetadataIcon name={icon} themeType={"secondary"} />
            {iconBadge ? (
              <IconBadgeContainer>{iconBadge}</IconBadgeContainer>
            ) : null}
          </MetadataIconWrapper>
          <MetadataText>{text}</MetadataText>
        </MetadataRow>
      ))}
      {typeof progressCount === "number" &&
        typeof progressTotal === "number" && (
          <StyledProgressBar
            count={progressCount}
            total={progressTotal}
            icon={progressIcon}
            customHeight={32}
            animateOnInit={false}
          />
        )}
    </QuestRunListItemContainer>
  );
};

export const QuestRunListItem = React.memo(_QuestRunListItem, isEqual);
QuestRunListItem.displayName = "QuestRunListItem";

const QuestRunListItemContainer = styled(PressableOpacity)<HoverProps>`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  border-radius: 16px;
  background-color: ${({ theme, isHovered }) =>
    theme.questRunCard.background[isHovered ? "hover" : "normal"]};
  transition: background-color 0.15s ease-in-out;
`;

const EmptyTitleSpacer = styled.View`
  height: 8px;
`;
const MetadataIconWrapper = styled.View`
  position: relative;
  margin-right: 8px;
  height: 32px;
`;
const MetadataText = styled(Text)`
  flex-shrink: 1;
  line-height: 32px;
`;
const IconBadgeContainer = styled.View`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
const MetadataRow = styled.View`
  flex-direction: row;
  margin-top: 12px;
`;

const MetadataIcon = styled(QKIcon)`
  margin: 0;
`;

const NameText = styled(Text)`
  color: ${({ theme }) => theme.questRunCard.text};
  line-height: 28px;
`;
const StyledProgressBar = styled(ProgressBar)`
  margin-top: 12px;
`;

const QuestTitleWrapper = styled.View`
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 14px;
  justify-content: space-between;
`;

const QuestTitleName = styled.View`
  flex: 1;
`;

const QuestStatusGroup = styled.View`
  flex-direction: row;
`;
const QuestStatusPill = styled.View<{ color: string }>`
  background-color: ${({ color }) => color};
  padding: 4px 10px;
  border-radius: 16px;
  height: 28px;
  margin-left: 10px;
`;
const QuestStatusText = styled(Text).attrs({ size: "small" })``;
