import React from "react";
import { useFocusableRef } from "@app/util/focus";
import { NavigationActionComponentModel } from "@app/components/item/components/custom/types";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";
import { NavigationButton } from "@app/components/NavigationButton";

interface CustomItemNavigationActionComponentProps {
  component: NavigationActionComponentModel;
  autoFocusRef?: ReturnType<typeof useFocusableRef>;
  readOnly: boolean;
}

export const CustomItemNavigationActionComponent: React.FC<
  CustomItemNavigationActionComponentProps
> = ({ component, readOnly }) => {
  const questContext = useQuestViewContext();

  return (
    <NavigationButton
      label={component.labelText}
      to={component.to}
      autoRedirectDelay={component.autoRedirectDelay}
      autoRedirectDisabled={
        !isViewContext(questContext, "RUN") ||
        !questContext.recentlySubmittedByUser
      }
      newTabOnWeb={component.newTabOnWeb}
      disabled={readOnly || component.disabled}
    />
  );
};
