import { DayOfTheWeekRange } from "cron-parser";
import React, { useContext } from "react";
import Text from "@app/components/questkit/text";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import styled from "styled-components/native";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { Platform } from "react-native";

interface WeekdayPickerProps {
  dayOfWeek: readonly DayOfTheWeekRange[];
  onChange: (dayOfWeek: DayOfTheWeekRange[]) => void;
  disabled?: boolean;
}
export const WeekdayPicker: React.FC<WeekdayPickerProps> = ({
  dayOfWeek,
  onChange,
  disabled = false,
}) => {
  const snackbar = useContext(SnackbarContext);
  return (
    <Center>
      <DaysOfTheWeekButtonsContainer>
        {daysOfTheWeek.map((day) => {
          return (
            <DayOfWeekButtonContainer key={day.value}>
              <DayOfWeekLabel nativeID={`dow-label-${day.value}`}>
                {day.label}
              </DayOfWeekLabel>
              <CheckboxToggle
                checked={dayOfWeek.includes(day.value)}
                readOnly={disabled}
                accessibilityLabelledBy={`dow-label-${day.value}`}
                accessibilityHint={day.accessibilityLabel}
                action={() => {
                  if (dayOfWeek.includes(day.value)) {
                    const newDayOfWeek = [...dayOfWeek];
                    const indexToRemove = newDayOfWeek.indexOf(day.value);
                    newDayOfWeek.splice(indexToRemove, 1);
                    if (newDayOfWeek.length === 0) {
                      snackbar.sendMessage(
                        "At least one day must be selected",
                        SnackbarSeverity.WARNING
                      );
                      return;
                    }
                    onChange(newDayOfWeek);
                  } else {
                    onChange([...dayOfWeek, day.value]);
                  }
                }}
              />
            </DayOfWeekButtonContainer>
          );
        })}
      </DaysOfTheWeekButtonsContainer>
    </Center>
  );
};

const daysOfTheWeek: {
  value: DayOfTheWeekRange;
  accessibilityLabel: string;
  label: string;
}[] = [
  {
    value: 0,
    label: "S",
    accessibilityLabel: "Sunday",
  },
  {
    value: 1,
    label: "M",
    accessibilityLabel: "Monday",
  },
  {
    value: 2,
    label: "T",
    accessibilityLabel: "Tuesday",
  },
  {
    value: 3,
    label: "W",
    accessibilityLabel: "Wednesday",
  },
  {
    value: 4,
    label: "T",
    accessibilityLabel: "Thursday",
  },
  {
    value: 5,
    label: "F",
    accessibilityLabel: "Friday",
  },
  {
    value: 6,
    label: "S",
    accessibilityLabel: "Saturday",
  },
];

const DaysOfTheWeekButtonsContainer = styled.View`
  width: 315px;
  max-width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4px;
`;
const DayOfWeekButtonContainer = styled.View`
  display: flex;
  align-items: center;
`;
const DayOfWeekLabel = styled(Text)`
  margin-bottom: -4px;
  ${Platform.OS === "web" ? "user-select: none;" : ""}
`;

const Center = styled.View`
  width: 100%;
  display: flex;
  justify-content: center;
`;
