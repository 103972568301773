import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

export interface ReadonlyRefObject<T> {
  readonly current: T;
}

export function useStateWithRef<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>,
  ReadonlyRefObject<S | undefined>
];
export function useStateWithRef<S>(
  initialState?: S | (() => S)
): [S, Dispatch<SetStateAction<S>>, ReadonlyRefObject<S>];
export function useStateWithRef(
  initialState?: unknown
): [unknown, Dispatch<SetStateAction<unknown>>, ReadonlyRefObject<unknown>] {
  const [state, _setState] = useState(initialState);
  const ref = useRef(state);
  const setState: typeof _setState = useCallback((update) => {
    let newState;
    if (typeof update === "function") {
      newState = update(ref.current);
    } else {
      newState = update;
    }
    ref.current = newState;
    _setState(newState);
  }, []);

  return [state, setState, ref];
}
